<template>
  <a-card :bordered="false">

        <div class="table-page-search-wrapper">
            <a-row>
              <a-col :md="2" :sm="24">
                <a-button style="margin-bottom: 10px" type="primary" @click="handleAdd">新增</a-button>
              </a-col>
            </a-row>
        </div>
        <div>
          <a-table
            ref="table"
            rowKey="id"
            size="middle"
            :columns="columns"
            :dataSource="dataSource"
            :loading="loading"
            bordered
          >
            <Dict slot="allowDiferTime" slot-scope="text" :dict-code="DICT_CODE.ALLOW_DIFER_TIME" :value="text"/>
             <template slot="allowPrepay" slot-scope="text">
              {{text==0?'不允许':'允许'}}
            </template>
            <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
            </span>

          </a-table>
        </div>
        <product-fee-config-modal ref="productFeeConfigModal" @ok="modalFormOk"></product-fee-config-modal>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import { getProductFeeConfigList } from '@/webpublicapi/operation'
import Dict from '../modules/Dict'
import productFeeConfigModal from './modules/ProductFeeConfigModal'
export default {
  name: 'productItemList',
  components: { Dict,productFeeConfigModal},
  props: {
    id:{
        type: [String, Number],
        default: undefined,
    },
   appSsid:{
        type: [String, Number],
        default: undefined,
    },
  },
  data () {
    return {
      dataSource: [],
      loading: false,
      columns: [
        {
          title: '费率名称',
          align: 'center',
          dataIndex: 'feeName'
        },
        {
          title: '固定金额',
          align: 'center',
          dataIndex: 'feeAmount'
        },
        {
          title: '固定费率',
          align: 'center',
          dataIndex: 'feeRate'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      title: '操作',
      visible: false,
      screenWidth: 1200,
      model: {},
      proId: '',
    }
  },
  created () {
    this.proId = this.id;
    this.loadData()
  },
  methods: {
    loadData () {
        this.loading = true
        getProductFeeConfigList(Object.assign({productId:this.proId})).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },

    handleAdd () {
      this.$refs.productFeeConfigModal.add(this.proId,this.appSsid)
      this.$refs.productFeeConfigModal.title = '新增'
    },
    handleEdit (record) {
      this.$refs.productFeeConfigModal.edit(record)
      this.$refs.productFeeConfigModal.title = '编辑'
    },
    modalFormOk () {
      this.loadData()
    }
  },
  watch: {
    proId (val) {
      this.proId = val
    }
  }
}
</script>
<style scoped>

</style>
