import { axios } from '@/utils/request'

export function getPayoutList (parameter = {}) {
  return axios({
    url: '/platform/import/salaryList',
    method: 'post',
    data: parameter
  })
}

export function getPayoutChannelList (parameter = {}) {
  return axios({
    url: '/platform/import/importingSalaryExcelCommonPayoutChannelList',
    method: 'post',
    data: parameter
  })
}
