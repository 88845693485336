<template>
  <a-modal
    :title="title"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <div>
      <div>
        Aadhaar姓名: {{ model.aadhaarName }}
      </div>
      <div>
        申请金额：{{ model.applyAmt }}
      </div>
      <div>
        期限：{{ model.duration }}
      </div>
    </div>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
// import { addDict, editDict } from '@/webpublicapi/system'
export default {
  name: 'PendingModal',
  data () {
    return {
      value: 1,
      title: '操作',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        dictName: { rules: [{ required: true, message: '请输入Aadhaar姓名!' }] },
      }
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({})
    },
    edit (record) {
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'aadhaarName', 'applyAmt', 'duration'))
      })
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          values.aadhaarName = (values.aadhaarName || '').trim()
          const formData = Object.assign(this.model, values)
          let obj
          console.log(formData)
          // if (!this.model.id) {
          //   obj = addDict(formData)
          // } else {
          //    obj = editDict(formData)
          // }
          // obj.then((res) => {
          //   if (res.code === 1000) {
          //     that.$message.success(res.msg)
          //     that.$emit('ok')
          //   } else {
          //     that.$message.warning(res.msg)
          //   }
          // }).finally(() => {
          //   that.confirmLoading = false
          //   that.close()
          // })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
