import {axios} from "@/utils/request";

export function getCustLeavingMessage (parameter = {}) {
  return axios({
    url: '/custLeavingMessage/selectCustLeavingMessage',
    method: 'post',
    data: parameter
  })
}


export function getCustLeavingMessageDetail (parameter = {}) {
  return axios({
    url: '/custLeavingMessage/selectCustLeavingMessageDetail',
    method: 'post',
    data: parameter
  })
}


export function updateCustLeavingMessage (parameter = {}) {
  return axios({
    url: '/custLeavingMessage/updateCustLeavingMessage',
    method: 'post',
    data: parameter
  })
}