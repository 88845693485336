<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="'到期日'">
              <a-range-picker
                v-model="queryParam.lendingTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="'借款类型'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.reborrowFlag">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="0">
                  首贷
                </a-select-option>
                <a-select-option value="1">
                  复贷
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="'订单类型'">
              <dict-select
                v-model="queryParam.orderType"
                :dict-code="DICT_CODE.ORDER_TYPE"
                :placeholder="$t('select')"
              >
                <a-select-option slot="begin" :value="null">全部</a-select-option>
              </dict-select>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="12">
            <a-form-item :label="'区间'">
              <a-input :placeholder="$t('enter') + ' 区间'" v-model="queryParam.dimensionSpan"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="12">
            <a-form-item :label="'借款次数'">
              <a-input :placeholder="$t('enter') + ' 借款次数'" v-model="queryParam.loanCount"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="'维度'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.dimension">
                <a-select-option value="apply_amt">
                  借款金额
                </a-select-option>
                <a-select-option value="loan_count">
                  借款次数
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'产品类型'">
              <a-select
                style="width: 300px"
                mode="multiple"
                v-model="queryParam.appssids"
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>

              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('order.applicationDeadline')">
              <a-select mode='multiple' default-value="" style="width: 190px" v-model="queryParam.durations">
                <a-select-option v-for="item in deadlineConfigList" :key="item.itemValue">
                  {{ item.itemText }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :rowClassName="rowClassName"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>


    </div>
    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { countOrderRepayData, countPlatformLoanDataWithDate, getApplist } from '@/webpublicapi/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import exportFile from '@/webpublicapi/exportFile'
import moment from 'moment'
import vModal from '../modules/Modal';
import { getAllDeadlineConfig } from '@/webpublicapi/deadlineConfig'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'CountCollectionTaskDispense',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      appList: [],
      deadlineConfigList: [],
      // 查询条件
      queryParam: {
        "orderNo":"",
        "appssid":"",
        "mobileNo":"",
        "orderType":"",
        "lendingTime":[moment().add('days', -7), moment().add('days', -1)]
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [

        {
          title:  '维度',
          align: 'left',
          dataIndex: 'dimension'
        },
        {
          title:  '申请期限',
          align: 'left',
          dataIndex: 'duration'
        },
        {
          title: '到期单量',
          align: 'left',
          dataIndex: 'totalOrder'
        },
        {
          title: '单量占比',
          align: 'left',
          dataIndex: 'totalRate'
        },
        {
          title: '到期金额',
          align: 'left',
          dataIndex: 'totalAmt'
        },
        {
          title: '件均',
          align: 'left',
          dataIndex: 'avgAmt'
        },
        {
          title: '金额占比',
          align: 'left',
          dataIndex: 'totalAmtRate'
        },
        {
          title: '首逾',
          align: 'left',
          dataIndex: 'firstOverdueRate'
        },
        {
          title: '实时逾期',
          align: 'left',
          dataIndex: 'acOverdueRate'
        },
        {
          title: '提前结清',
          align: 'left',
          dataIndex: 'earlySettledRate'
        },
        {
          title: '正常结清',
          align: 'left',
          dataIndex: 'settledRate'
        },
        {
          title: '展期结清',
          align: 'left',
          dataIndex: 'extensionSettledRate'
        },
        {
          title: '逾期展期结清',
          align: 'left',
          dataIndex: 'overdueExtensionSettledRate'
        },
        {
          title: '逾期结清',
          align: 'left',
          dataIndex: 'overdueSettledRate'
        }
      ]
    },
  },
  created () {
    getApplist().then(res => {
      this.appList = res.data || [];
    });
    this.getDict();
    // this.loadData()
    getAllDeadlineConfig().then(res => {
      this.deadlineConfigList = res.data || [];
    })
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData()
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.form.pageNum = 1
      // }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      countOrderRepayData(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data.total
          this.dataSource = res.data || [];

          for(var i = 0 ; i < this.dataSource.length ; i ++){
            this.dataSource[i].avgAmt = (this.dataSource[i].totalAmt / this.dataSource[i].totalOrder).toFixed(2);
            this.dataSource[i].acOverdueRate = this.numFilter(this.dataSource[i].acOverdueRate)
            // this.dataSource[i].dimension = this.numFilter(this.dataSource[i].dimension)
            this.dataSource[i].earlySettledRate = this.numFilter(this.dataSource[i].earlySettledRate)
            this.dataSource[i].extensionSettledRate = this.numFilter(this.dataSource[i].extensionSettledRate)
            this.dataSource[i].firstOverdueRate = this.numFilter(this.dataSource[i].firstOverdueRate)
            this.dataSource[i].overdueExtensionSettledRate = this.numFilter(this.dataSource[i].overdueExtensionSettledRate)
            this.dataSource[i].overdueSettledRate = this.numFilter(this.dataSource[i].overdueSettledRate)
            this.dataSource[i].settledRate = this.numFilter(this.dataSource[i].settledRate)
            this.dataSource[i].totalAmtRate = this.numFilter(this.dataSource[i].totalAmtRate)
            this.dataSource[i].totalRate = this.numFilter(this.dataSource[i].totalRate)
          }

        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { lendingTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          lendingTimeStart: (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[0]) : undefined,
          lendingTimeEnd : (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[1]) : undefined,
      }
      delete data.applyDate;
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.lendingTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    rowClassName(record,index){
      var date = new Date(record.curDate);
      var day = date.getTime() / 24 / 60 / 60 / 1000 + 3;
      console.log(record.curDate , date, (day % 7) + 1);

      if((day % 7) + 1 == 7){
        return 'table_weekendColor';
      }
      return '';
    },
    // 百分比保留两位小数
    numFilter(num) {
      num = num.substring(0, num.length - 1)
      num = parseFloat(num).toFixed(2) + "%"
      return num
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
