<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter="24">
          <a-col  :md='6' :sm='8'>
            <a-form-item label="放款日期" >
              <a-range-picker
                v-model="queryParam.time"
                format="YYYY-MM-DD"
                style="width: 200px;"/>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item label="状态">
              <a-select  style='width: 190px' allowClear v-model='queryParam.status'>
                <a-select-option value="-1" >全部</a-select-option>
                <a-select-option value="00">初始化</a-select-option>
                <a-select-option value="01">成功</a-select-option>
                <a-select-option value="02">失败</a-select-option>
                <a-select-option value="03">处理中</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item label="收款账号">
              <a-input  style='width: 190px' v-model='queryParam.accountNumber'>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <!--<a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset') }}</a-button>-->
            </span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md='6' :sm='8'>
            <a-form-item label="付款渠道">
              <a-select  style='width: 190px' v-model='uploadDataParam.payChannel'>
                <a-select-option v-for="channel in payoutChannelList" :key="channel" >
                  {{ channel }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-upload
              name="file"
              :multiple="true"
              :action="'/api/web/webpublic/platform/import/importingSalaryExcelCommon'"
              :headers="getHeaders()"
              :data="uploadDataParam"
              @change="handleChangeUpload"
              :beforeUpload="beforeUpload"
            >
              <a-button style='margin-left: 8px'> <a-icon type="upload" /> 上传工资excel文件</a-button>
            </a-upload>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md='12' :sm='8'>
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                              <a-button type="primary" @click="downLoadData(1)" icon="download"
                                        style="margin-left: 8px">导出发放失败excel文件
                              </a-button>
                         </span>
            <span></span>
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                              <a-button type="primary" @click="downLoadData(2)" icon="download"
                                        style="margin-left: 8px">导出全部对账excel文件
                              </a-button>
                         </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='false'
        :loading='loading'
        bordered
        @change='handleTableChange'>
      </a-table>
      <div style='margin-top: 15px; text-align: right;'>
        <v-pagination
          v-if='total'
          :page-num='form.pageNum'
          :page-size='form.pageSize'
          :total='total'
          @change='handleTableChange' />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getPayoutList, getPayoutChannelList} from '@/webpublicapi/importSalaryCommon'
import { getHeaders } from '@/utils/request'
import moment from 'moment'
import exportFile from "@/api/exportFile";
import md5 from 'md5'
import { repayOfflineAll } from '@/api/order'

const I18N_KEY = {
  OASYSTEM: 'oaSystem.',
  FOLLOW: 'importSalary.'
}
export default {
  name: 'importCommonSalary',
  components: {},
  data() {
    return {
      visdialog: false,
      I18N_KEY,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      dataSourceTotal: [],
      departments: [],
      groupAll: [],
      normalConfigList: [],
      // 查询条件
      queryParam: {
        time: [],
        status:'',
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
      uploadDataParam:{
        payChannel: null,
        password: null
      },
      payoutChannelList:[]
    }
  },
  computed: {
    columns() {
      return [
        {
          title: 'id',
          align: 'left',
          dataIndex: 'id'
        },
        {
          title:  '姓名',
          align: 'center',
          dataIndex: 'names'
        },
        {
          title:  '收款账号',
          align: 'center',
          dataIndex: 'accountNumber'
        },
        {
          title:  '订单号',
          align: 'center',
          dataIndex: 'busNo'
        },
        {
          title:  '金额',
          align: 'center',
          dataIndex: 'amount'
        },
        {
          title:  '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: function (text) {
            if (text==='00') {
              return "初始化"
            } else if (text === '01') {
              return "成功"
            } else if (text === '02') {
              return "失败"
            } else if (text === '03') {
              return "放款中"
            }
          }
        },
        {
          title:  '创建时间',
          align: 'center',
          dataIndex: 'createdTime',
          customRender: function (text) {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
          }
        },
        {
          title:  '更新时间',
          align: 'center',
          dataIndex: 'updatedTime',
          customRender: function (text) {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
          }
        },
        {
          title:  '放款渠道',
          align: 'center',
          dataIndex: 'payChannel'
        }

      ]
    }
  },
  created() {
    this.getTime();
    this.getPayoutChannelList();
    this.loadData(1)
  },
  methods: {
    beforeUpload() {
      let l = this;
      return new Promise((resolve, reject)=> {
        l.$confirm({

          title: '请输入打款密码',
          content: h => {
            return <div>
              <div>
                验证密码：<input id = 'password' type = 'password' />
              </div>
            </div>
          },
          onOk() {
            self.loading = true;
            var password = document.getElementById("password").value;

            if(!password.trim()){
              alert('验证密码不能为空');
              reject();
              return false;
            }

            l.uploadDataParam.password = md5(password);
            resolve();
            l.loadData(1);
          },
        });
      })
    },
    getPayoutChannelList() {
      getPayoutChannelList().then((res) => {
        if (res.code === 1000) {
          this.payoutChannelList = res.data;
        } else {
          this.payoutChannelList = [];
        }
      }).catch(e => {
      })
    },
    getTime() {
      this.queryParam.time = [];
      var nowDate = new Date();
      this.queryParam.time[0]=moment(nowDate.setDate(nowDate.getDate() - 7));
      this.queryParam.time[1]=moment(new Date());

    },
    getHeaders() {
      return getHeaders();
    },
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {
      var params = this.getQueryParams()// 查询条件
      params.payChannel = this.uploadDataParam.payChannel;
      if (!params.beginTime) {
        this.$message.error("日期不能为空");
        return;
      }
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true

      getPayoutList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },

    getQueryParams() {
      var param = this.queryParam;
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      param.beginTime = (param.time && param.time.length) ? this.transformTime(param.time[0]) : undefined;
      param.endTime = (param.time && param.time.length) ? this.transformTime(param.time[1]) : undefined;

      return filterObj(param)
    },
    transformTime (time) {
      return time.format('YYYY-MM-DD')
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置搜索框的内容
    searchReset() {
      queryParam.status = "";
      this.getTime();
    },
    handleChangeUpload(info) {
      //debugger;
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        if(info.file.response.code === 1000){
          //alert('handle success!');
          this.$message.success(`${info.file.name} file uploaded successfully`);
        } else {
          alert(info.file.response.msg);
        }
      } else if (info.file.status === 'error') {
        //this.$message.error(`${info.file.name} file upload failed.`);
        alert('system error!');
      }
    },
    downLoadData(flag) {
      var params = this.getQueryParams()// 查询条件
      var beginTime = params.beginTime;
      if (!beginTime) {
        alert('请选择日期');
        return false;
      }
      const file = {
        fileUrl: '/api/web/webpublic/payChecking/exportSalaryFailExcel?startDate=' + params.beginTime + '&endDate=' +
                params.endTime + '&flag=' + flag,
        fileName: params.beginTime + '&' + params.endTime + '&' + flag +'.xlsx',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    }

  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
