<template>
  <a-card :bordered="false">
    <div class="table-search-wrapper">
      <a-row :gutter="48">
        <a-col :span="6">
          <a-input v-model="model.name" placeholder="请输入规则码"></a-input>
        </a-col>
        <a-col :span="6">
          <a-input v-model="model.intro" placeholder="请输入说明"></a-input>
        </a-col>
        <a-col :span="12">
          <a-button @click="handleSearch">查询</a-button>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        :pagination="false"
        bordered
      >
        <div slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">修改</a>
          <a-divider type="vertical"/>
          <a @click="viewFeaturesUsed(record)">使用情况</a>
        </div>
      </a-table>
    </div>
    <div class="table-page-wrapper">
      <a-pagination size="small" v-model="model.pageNum" :pageSizeOptions="pageSizeOptions" :pageSize="model.pageSize"
                    :total="model.total"
                    :show-total="total => `共 ${total} 条`"
                    show-size-changer show-quick-jumper @change="handlePageChange" @showSizeChange="handlePageChange"/>
    </div>
    <variable-used-modal ref="usedModal"></variable-used-modal>
    <variable-edit-modal ref="editModal" @success="loadData"></variable-edit-modal>
  </a-card>
</template>

<script>
import {riskFeaturesList} from '@/api/risk'
import VariableEditModal from "@/views/risk/modules/VariableEditModal";
import VariableUsedModal from "@/views/risk/modules/VariableUsedModal";

export default {
  name: 'riskVariableList',
  components: {VariableUsedModal, VariableEditModal},
  data() {
    return {
      dataSource: [],
      loading: false,
      columns: [
        {
          title: 'ID',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '规则码',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '规则默认值',
          align: 'center',
          dataIndex: 'defaultValue'
        },
        {
          title: '说明',
          align: 'center',
          dataIndex: 'intro'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: "center",
          scopedSlots: {customRender: 'action'},
        }
      ],
      title: '操作',
      visible: false,
      screenWidth: 1200,
      pageSizeOptions: ['10', '20', '30', '40'],
      model: {name: '', intro: '', pageNum: 1, pageSize: 10, total: 0}
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    handleSearch() {
      this.model.pageNum = 1
      this.loadData()
    },
    handlePageChange(pageNum, pageSize) {
      this.model.pageNum = pageNum
      this.model.pageSize = pageSize
      this.loadData()
    },

    loadData() {
      this.loading = true
      riskFeaturesList(this.model).then(({code, data, msg} = {}) => {
        if (code === 1000) {
          this.model.total = data.total
          this.dataSource = data.rows
        } else {
          this.dataSource = []
          this.model.total = 0
          this.$message.error(msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    viewFeaturesUsed(record) {
      this.$refs.usedModal.show(record.id)
    },
    handleEdit(record) {
      this.$refs.editModal.show(record)
    }
  }
}
</script>
<style scoped>
.table-search-wrapper {
  padding-bottom: 8px;
}

.table-page-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}
</style>
