<template>
  <a-card :bordered="false">
    <!-- 抽屉 -->
    <a-drawer
      title="配置列表"
      :width="screenWidth"
      @close="onClose"
      :visible="visible"
    >
      <!-- 抽屉内容的border -->
      <div
        :style="{
          padding:'10px',
          border: '1px solid #e9e9e9',
          background: '#fff',
        }">
        </div>
        <div>
          <a-table
            ref="table"
            rowKey="id"
            size="middle"
            :columns="columns"
            :dataSource="dataSource"
            :loading="loading"
            bordered
          >

            <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
              <a-divider type="vertical"/>
             
            </span>

          </a-table>
        </div>
      </div>
    </a-drawer>
    <prodict-config-edit ref="ProdictConfigEdit" @ok="modalFormOk"></prodict-config-edit>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import {getProductConfig,editProductConfig,addProductConfig} from '@/api/operation'
import { filterObj } from '@/utils/util'
import ProdictConfigEdit from './ProdictConfigEdit'

export default {
  name: 'ProdictConfig',
  components: { ProdictConfigEdit },
  data () {
    return {
      dataSource: [],
      loading: false,
      columns: [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '排序',
          align: 'center',
          dataIndex: 'sort'
        },
        {
          title: '类型',
          align: 'center',
          dataIndex: 'type'
        },
        {
          title: '配置值',
          align: 'center',
          dataIndex: 'value'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'value',
          scopedSlots: { customRender: 'action' }
        }
      ],
      queryParam: {
        dictId: '',
        dictName: '',
        itemText: '',
        status: "1"
      },
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      title: '操作',
      visible: false,
      screenWidth: 800,
      model: {},
      dictId: '',
      status: '1',
      labelCol: {
        xs: { span: 5 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 }
      },
      form: this.$form.createForm(this),
      validatorRules: {
        itemText: { rules: [{ required: true, message: '请输入名称!' }] },
        itemValue: { rules: [{ required: true, message: '请输入数据值!' }] }
      }
    }
  },
  created () {
    // 当页面初始化时,根据屏幕大小来给抽屉设置宽度
    this.resetScreenSize()
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.dictName = ''
      that.queryParam.dictCode = ''
     
    },
    loadData (arg) {
      
       this.loading = true
       const formData = Object.assign({})
       formData.productId = 7
            formData.appSsid=this.appSsid
       getProductConfig(formData).then((res)=>{
        if(res.code===1000){
          
          this.dataSource = res.data
        }else{
          
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    add (dictId) {
      this.dictId = dictId
      this.edit({})
    },
    edit (record) {
      console.dir(record)
      if (record.id) {
        this.dictId = record.id
      }
      this.queryParam = {}
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.model.dictId = this.dictId
      this.queryParam.dictId = this.dictId
      this.model.status = this.status
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'itemText', 'itemValue'))
      })
      // 当其它模块调用该模块时,调用此方法加载字典数据
      this.loadData()
    },


    handleEdit (record) {
      this.$refs.ProdictConfigEdit.edit(record)
      this.$refs.ProdictConfigEdit.title = '编辑'
    },
    handleDelete(id){
      deleteDictItem({id}).then((res) => {
        if (res.code === 1000) {
          that.$message.success(res.msg)
          this.loadData()
        } else {
          that.$message.warning(res.msg)
        }
      })
    },
    showDrawer () {
      this.visible = true
    },
    onClose () {
      this.visible = false
      this.form.resetFields()
      this.dataSource = []
    },
    // 抽屉的宽度随着屏幕大小来改变
    resetScreenSize () {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 600) {
        this.screenWidth = screenWidth
      } else {
        this.screenWidth = 600
      }
    },
    // model回调
    modalFormOk () {
      this.loadData()
    }
  }
}
</script>
<style scoped>

</style>