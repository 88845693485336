<template>
    <div>
        <a-spin :spinning="loading">
                <a-card
                    style="margin-top: 24px"
                    :title="$t(I18N_KEY.CUSTOMER + 'customerLinkInfo')"
                    type="inner">
                    <a-table
                        :columns="linkColumns"
                        :dataSource="dataSourceInfo"
                        :pagination="false"
                        bordered
                        ref="table"
                        size="middle"
                        :rowKey="(record) => record.index">
                        <span slot="tradeType" slot-scope="text" :value="text" >{{ $t(TRADETYPE[text]) }}</span>
                        <span slot="tradeStatus" slot-scope="text" :value="text" >{{ $t(TRADESTATUS[text]) }}</span>
                    </a-table>
                </a-card>
        </a-spin>
    </div>
</template>
<script>
    import { DetailList } from '@/components';
    import { getAccountTradeFlowList} from '@/webpublicapi/platformCollection'
    import { REBORROW_FLAG, SEX, CONTACT_TYPE, TRADETYPE, TRADESTATUS} from '@/constants/status';
    import Dict from '../modules/Dict';
    import imageMixin from '@/mixins/image-mixin';
    const DetailListItem = DetailList.Item
    const I18N_KEY = {
        CUSTOMER: 'customer.',
        ORDER: 'order.',
    }

    export default {
        props: {
          userId: {
                type: [String, Number],
                default: undefined,
            }
        },
        components: {
            DetailList,
            DetailListItem,
            Dict,
        },
        mixins: [imageMixin],
        data () {
            return {
                I18N_KEY,
                REBORROW_FLAG,
                SEX,
                CONTACT_TYPE,
              TRADETYPE,
              TRADESTATUS,
                loading: true,
                currentTab: '1',
                dataSourceInfo: [],
                linkColumns: [
                    {
                        title: '银行卡号',
                        dataIndex: 'bankCardNo',
                        key: 'bankCardNo',
                        align:"center"
                    },
                    {
                        title: '交易金额',
                        dataIndex: 'tradeAmount',
                        key: 'tradeAmount',
                        align:"center"
                    },
                    {
                      title: '交易状态',
                      dataIndex: 'tradeStatus',
                      key: 'tradeStatus',
                      align:"center",
                      scopedSlots: { customRender: 'tradeStatus' }
                    },
                    {
                      title: '交易类型',
                      dataIndex: 'type',
                      key: 'type',
                      align:"center",
                      scopedSlots: { customRender: 'tradeType' }
                    },
                    {
                      title: '支付编号',
                      dataIndex: 'payoutId',
                      key: 'payoutId',
                      align:"center"
                    },
                    {
                        title: '失败原因',
                        dataIndex: 'remarks',
                        key: 'remarks',
                        align:"center"
                    }
                ],

            }
        },
        computed: {
            // ...mapGetters('platformCollection', [
            //      'accountTradeFlowList',
            // ]),
           // queryAccountTradeFlow () {
           //    return [];
           //  }
        },
        created () {
            this.requestDetail(this.userId);
        },
        methods: {
            // ...mapActions('platformCollection', [
            //     'getAccountTradeFlowList',
            // ]),
            requestDetail (id) {
               getAccountTradeFlowList({ userId: id }).then(res => {
                   if(res.code===1000){
                     this.dataSourceInfo = res.data;
                   }else{
                     this.$message.error(res.msg);
                   }
                   this.loading = false
                }).catch(e => {
                  self.loading = false;
                  self.$message.error(e.message);
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .description-list .content {
        font-weight: 700;
    }
    .file-box {
        float: left;
        width: 200px;
        height: 200px;
        margin-right: 10px;
        margin: 0 8px 8px 0;

        .file-item {
            width: 100%;
            height: 100%;
            position: relative;
            border: 1px solid #d9d9d9;
            border-radius: 4px;

            .file-preview {
                padding: 8px;
                width: 182px;
                height: 142px;
            }

            .file-label {
                margin: 0;
                text-align: center;
                border-bottom: 1px solid #d9d9d9;
                padding: 8px;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
</style>