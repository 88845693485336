<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
    </div>
    <div>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        bordered
      >
        <Dict slot="allowDiferTime" slot-scope="text" :dict-code="DICT_CODE.ALLOW_DIFER_TIME" :value="text"/>
        <template slot="allowPrepay" slot-scope="text">
          {{ text == 0 ? '不允许' : '允许' }}
        </template>
        <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
            </span>

      </a-table>
    </div>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import Dict from '../modules/Dict'
import {getRiskStatisticsById} from '@/webpublicapi/risk'

export default {
  name: 'productItemList',
  components: {Dict},
  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },
    appSsid:{
      type: [String, Number],
      default: undefined,
    },
    start: {
      type: [String, Number],
      default: undefined,
    },
    end: {
      type: [String, Number],
      default: undefined,
    }
  },
  data() {
    return {
      dataSource: [],
      loading: false,
      columns: [
        {
          title: '拒绝码',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '拒绝原因',
          align: 'center',
          dataIndex: 'intro'
        },
        {
          title: '命中数',
          align: 'center',
          dataIndex: 'count'
        },
        {
          title: '拒绝占比',
          align: 'center',
          dataIndex: 'refuseRate'
        },
        {
          title: '总量占比',
          align: 'center',
          dataIndex: 'allRate'
        }
      ],
      title: '操作',
      visible: false,
      screenWidth: 1200,
      model: {},
      proId: '',
    }
  },
  created() {
    this.proId = this.id;
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      getRiskStatisticsById(Object.assign({
        strategyId: this.proId,
        appSsid:this.appSsid,
        startDate: this.start,
        endDate: this.end
      })).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },


    handleAdd() {
      this.$refs.prodictItemModal.add(this.proId, this.appSsid)
      this.$refs.prodictItemModal.title = '新增'
    },
    handleEdit(record) {
      record.allowDiferTime = record.allowDiferTime != undefined && '' + record.allowDiferTime;
      this.$refs.prodictItemModal.edit(record)
      this.$refs.prodictItemModal.title = '编辑'
    },
    handleConfigAdd() {
      this.$refs.ProdictConfigModal.add(this.proId, this.appSsid)
      this.$refs.ProdictConfigModal.title = '添加配置'
    },
    // 编辑字典数据
    editProdictConfig() {
      this.$refs.ProdictConfigList.edit(this.proId, this.appSsid)
      this.$refs.ProdictConfigList.title = '添加配置'
    },
    modalFormOk() {
      this.loadData()
    }
  },
  watch: {
    proId(val) {
      this.proId = val
    }
  }
}
</script>
<style scoped>

</style>
