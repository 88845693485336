<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="应用名称">
          <a-select default-value="" style="width: 190px" :disabled="visiblekey"
                    v-decorator="[ 'appSsid', validatorRules.appSsid]">
            <a-select-option value="01">
              NovaCash-01
            </a-select-option>
            <a-select-option value="02">
              ListoCash-02
            </a-select-option>
            <a-select-option value="03">
              MoniMoni-03
            </a-select-option>
            <a-select-option value="04">
              FusMoney-04
            </a-select-option>
            <a-select-option value="05">
              PesoPréstamos-05
            </a-select-option>
            <a-select-option value="06">
              OkCrédito-06
            </a-select-option>
            <a-select-option value="07">
              OkPréstamos-07
            </a-select-option>
            <a-select-option value="09">
              goncredito-09
            </a-select-option>
            <a-select-option value="10">
              Préstamos Tala-10
            </a-select-option>
            <a-select-option value="11">
              goncredito-11
            </a-select-option>
            <a-select-option value="12">
              EnviarDinero-12
            </a-select-option>
            <a-select-option value="13">
              自己贷超-13
            </a-select-option>
            <a-select-option value="14">
              mobi-14
            </a-select-option>
            <a-select-option value="15">
              avalcred-15
            </a-select-option>
            <a-select-option value="16">
              Créditos Celular-16
            </a-select-option>
            <a-select-option value="17">
              PesoFábrica-17
            </a-select-option>
            <a-select-option value="18">
              ListoFectivo-18
            </a-select-option>
            <a-select-option value="19">
              SuperEfectivo-19
            </a-select-option>
            <a-select-option value="20">
              TasaPréstamos-20
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="首贷是否拒绝">

          <a-select default-value="" style="width: 190px" v-decorator="[ 'firstApprovalM', validatorRules.firstApprovalM]">
            <a-select-option value="0">
              否
            </a-select-option>
            <a-select-option value="1">
              是
            </a-select-option>
          </a-select>
<!--          <a-input placeholder="请输入租户id" v-decorator="[ 'firstApprovalM', validatorRules.firstApprovalM]"/>-->
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="复贷是否拒绝">

          <a-select default-value="" style="width: 190px" v-decorator="[ 'reApprovalM', validatorRules.reApprovalM]">
            <a-select-option value="0">
              否
            </a-select-option>
            <a-select-option value="1">
              是
            </a-select-option>
          </a-select>

<!--          <a-input placeholder="请输入包名" v-decorator="[ 'reApprovalM', validatorRules.reApprovalM]"/>-->
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="是否拒绝">

          <a-select default-value="" style="width: 190px" v-decorator="[ 'allApprovalM', validatorRules.allApprovalM]">
            <a-select-option value="0">
              否
            </a-select-option>
            <a-select-option value="1">
              是
            </a-select-option>
          </a-select>

<!--          <a-input placeholder="请输入logo" v-decorator="[ 'allApprovalM',validatorRules.allApprovalM]"/>-->
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import {addAppSetting, editAppSetting} from '@/webpublicapi/system'
import DictSelect from '../../modules/DictSelect';


// duplicateCheck
export default {
  name: 'AppModal',
  components: {
    DictSelect,
  },
  mounted(){

    if(typeof FileReader=='undifined')        {
      console.log('抱歉，你的浏览器不支持 FileReader');
    }else{
      console.log('FileReader');
    }
  },
  data () {
    return {
      value: 1,
      title: '操作',
      visible: false,
      visiblekey: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        appSsid: { rules: [{ required: true, message: '请输入租户id!' }] },
        firstApprovalM: { rules: [{ required: true, message: '请输入包名!' }] },
        reApprovalM: { rules: [{ required: true, message: '请输入logoUrl!' }] },
        allApprovalM: { rules: [{ required: true, message: '请输入APP下载地址!' }] },
      }
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({})
    },
    edit (record) {
      if (record.id) {
        this.visiblekey = true
      } else {
        this.visiblekey = false
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'firstApprovalM', 'appSsid', 'reApprovalM', 'allApprovalM'))
      })
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          let obj
          console.log(formData)
          if (!this.model.id) {
            obj = addAppSetting(formData)
          } else {
             obj = editAppSetting(formData)
          }
          obj.then((res) => {
            if (res.code === 1000) {
              this.$message.success(res.msg)
              this.$emit('ok')
            } else {
              this.$message.warning(res.msg)
            }
          }).finally(() => {
            this.confirmLoading = false
            this.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },

    close () {
      this.$emit('close')
      this.visible = false
    },
  }
}
</script>
