<template>
  <a-modal
    :title="$t(I18N_KEY + 'attachment')"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-card v-if="attachments.length > 0"
              style="margin-top: 24px"
              type="inner"
              v-viewer="viewOptions">
        <div v-for="(item, index) in attachments">
          <div class="file-box">
            <div class="file-item">
              <img class="file-preview" :src="item" preview="0">
            </div>
          </div>
        </div>
      </a-card>
    </a-spin>
  </a-modal>
</template>

<script>
import imageMixin from '@/mixins/image-mixin'

const I18N_KEY = 'teleMarket.';
export default {
  name: "TelemarketAttachment",
  props: {
    attachments: {
      type: Array,
      default: [],
    },
  },
  mixins: [imageMixin],
  data () {
    return {
      I18N_KEY,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
    }
  },
  created () {
  },
  methods: {
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      this.close()
    },
    handleCancel () {
      this.close()
    },
  }
}
</script>

<style lang="less" scoped>
.file-box {
  float: left;
  width: 200px;
  height: 200px;
  margin-right: 10px;
  margin: 0 8px 8px 0;

  .file-item {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    .file-preview {
      padding: 8px;
      width: 182px;
      height: 142px;
    }

    .file-label {
      margin: 0;
      text-align: center;
      border-bottom: 1px solid #d9d9d9;
      padding: 8px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>