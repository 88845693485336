<template>
  <div>
    <div class='table-page-search-wrapper'>
      <a-form layout="inline" >
      <a-row :gutter="24">
        <a-col :md='6' :sm='8'>
          <a-form-item :label="$t(I18N_KEY.COUPON + 'taskName')">
            <a-input  v-model='form.taskName' />
          </a-form-item>
        </a-col>
        <a-col :md='6' :sm='8'>
          <a-form-item :label="$t(I18N_KEY.COUPON + 'couponName')">
            <a-input  v-model='form.couponName' />
          </a-form-item>
        </a-col>
        <a-col :md='6' :sm='8'>
          <a-form-item :label="$t(I18N_KEY.COUPON + 'state')">
            <a-select
                      v-model='form.taskStatus'
            >
              <a-select-option value='' selected>
                {{ $t(I18N_KEY.COUPON + 'all') }}
              </a-select-option>
              <a-select-option value='1'>
                {{ $t(I18N_KEY.COUPON + 'open') }}
              </a-select-option>
              <a-select-option value='2'>
                {{ $t(I18N_KEY.COUPON + 'close') }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md='6' :sm='8'>
          <a-form-item :label="'节点'">
            <a-select
              v-model="form.sendGroup"
            >
              <a-select-option value='' selected>
                {{ $t(I18N_KEY.COUPON + 'all') }}
              </a-select-option>
              <a-select-option v-for="item in sendGroupList" :key="item.id" :value="item.id + ''">
                {{ item.groupName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type='primary' @click='search()'>{{ $t(I18N_KEY.COUPON + 'search') }}</a-button>&nbsp;&nbsp;
              <a-button type="primary" @click="searchReset" icon="reload">{{ $t(I18N_KEY.COUPON + 'reset') }}</a-button>&nbsp;&nbsp;
              <a-button  type='primary' @click="viewCoupon('add')">
            <a-icon type='plus' />
            {{ $t(I18N_KEY.COUPON + 'addTask') }}
          </a-button>
            </span>
        </a-col>
        </a-row>
        <a-row >
        </a-row>

      </a-form>
    </div>

    <div class='table-page-search-wrapper'>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :loading='loading'
        bordered>
        <span slot='action' slot-scope='text, record'>
          <a @click="viewCoupon('edit', record)">
            {{ $t(I18N_KEY.COUPON + 'edit') }}
          </a>

          <a @click="execute(record)">
            {{'立即执行'}}
          </a>
        </span>
      </a-table>
    </div>
    <a-modal
      :title='modalTitle'
      v-model='visible'
      @ok='submitForm'
    >
      <a-form :form='basicForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'product')">
          <a-select
            v-model="basicForm.appSsid"
          >
            <a-select-option value=''>
              {{ $t(I18N_KEY.COUPON + 'all') }}
            </a-select-option>
            <a-select-option v-for="item in appList" :key="item.appssid" :value="item.appssid">
              {{ item.appName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'taskName')">
          <a-input v-model='basicForm.taskName' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'taskStatus')">
          <a-switch v-model='basicForm.taskStatus' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'sendingMethod')">
          <a-select
            v-model='basicForm.sendType'
          >
            <a-select-option value='1'>
              {{ $t(I18N_KEY.COUPON + 'typeOfCoupon') }}
            </a-select-option>
<!--            <a-select-option value='2'>-->
<!--              短信-->
<!--            </a-select-option>-->
          </a-select>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'couponID')">
          <a-input v-model='basicForm.couponId' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'sendingTime')">
          <a-select
            v-model='basicForm.sendDateType'
          >
            <a-select-option value='1' >
              {{ $t(I18N_KEY.COUPON + 'everyDay') }}
            </a-select-option>
            <a-select-option value='2' >
              {{ $t(I18N_KEY.COUPON + 'setTime') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="' '" v-show="basicForm.sendDateType === '1'">
          <a-time-picker
            v-model="basicForm.sendDate_time"
            format="HH:mm:ss"
            style="width: 100%;"/>
        </a-form-item>
        <a-form-item :label="' '" v-show="basicForm.sendDateType === '2'">
          <a-date-picker
            v-model="basicForm.sendDate_date"
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            style="width: 100%;"/>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'node')">
          <a-select
            v-model="basicForm.sendGroup"
          >
            <a-select-option v-for="item in sendGroupList" :key="item.id" :value="item.id + ''">
              {{ item.groupName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import { getCouponTaskList, addCouponTask, editCouponTask, listSendGroup, executeTask } from '@/webpublicapi/operation'
import moment from 'moment/moment'
import { getApplist } from '@/webpublicapi/order'
import { CollectionSignInDistribution } from '@/webpublicapi/platformCollection'


const I18N_KEY = {
  COUPON: 'coupon.',
}
export default {

  data() {
    return {
      I18N_KEY,
      visible: false,
      modalTitle: undefined,
      loading: false,
      appList: [],
      sendGroupList: [],
      dataSource: [],
      columns: [
        {
          title: this.$t(I18N_KEY.COUPON + 'taskName'),
          align: 'left',
          dataIndex: 'taskName'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'couponName'),
          align: 'left',
          dataIndex: 'couponName'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'taskNode'),
          align: 'left',
          dataIndex: 'sendGroup',
          customRender: (text) => {
            for(let i = 0 ; i < this.sendGroupList.length ; i ++){
              if(this.sendGroupList[i].id + "" === text){
                return this.sendGroupList[i].groupName
              }
            }
          }
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'sendingMethod'),
          align: 'left',
          dataIndex: 'sendType',
          customRender: (text) => {
            return  text === "1" ? this.$t(I18N_KEY.COUPON + 'typeOfCoupon') : this.$t(I18N_KEY.COUPON + 'SMS');
          }
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'sendingTime'),
          align: 'left',
          dataIndex: 'sendDate',

        },
        {
          title: this.$t(I18N_KEY.COUPON + 'state'),
          align: 'left',
          dataIndex: 'taskStatus',
          customRender: (text) => {
            return  text === "1" ? this.$t(I18N_KEY.COUPON + 'open') : this.$t(I18N_KEY.COUPON + 'close');
          }
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'updateTime'),
          align: 'left',
          dataIndex: 'updatedTime'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'operate'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      form: {
        taskName: "",
        couponName: "",
        taskStatus: "",
        sendGroup: ""
      },
      defaultBasicForm: {
        id: '',
        taskName: '',
        appSsid: '',
        taskStatus: true,
        sendType: '1',
        couponId: '',
        sendDateType: '1',
        sendDate_date: moment(),
        sendGroup: '1',
        sendDate_time: moment()
      },
      basicForm: {},
      COUPON_STATUS: {
        1: this.$t(I18N_KEY.COUPON + 'open'),
        2: this.$t(I18N_KEY.COUPON + 'close')
      }
    }
  },

  created() {
    this.basicForm = { ...this.defaultBasicForm }
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      getApplist().then(res => {
        this.appList = [];
        this.appInList = res.data || [];
        for(var i = 0 ; i < this.appInList.length ; i ++){
          if(this.appInList[i].appssid != ''){
            this.appList.push(this.appInList[i]);
          }
        }
      });
      listSendGroup().then(res => {
        this.sendGroupList = [];
        this.sendGroupListRet = res.data || [];
        for(var i = 0 ; i < this.sendGroupListRet.length ; i ++){
          if(this.sendGroupListRet[i].groupName != ''){
            this.sendGroupList.push(this.sendGroupListRet[i]);
          }
        }
      });
      this.search(this.form)
    },
    searchReset()
    {
      this.form.taskName = ""
      this.form.couponName = ""
      this.form.taskStatus = ""
      this.form.sendGroup = ""
    },

    search() {

      const form = {
        taskName : this.form.taskName === "" ? undefined : this.form.taskName,
        couponName : this.form.couponName === "" ? undefined : this.form.couponName,
        taskStatus : this.form.taskStatus === "" ? undefined : this.form.taskStatus,
        sendGroup : this.form.sendGroup === "" ? undefined : this.form.sendGroup,
      }

      this.loading = true;

      getCouponTaskList(form).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })


    },
    execute(record) {
      console.log(record);
      const self = this;
      this.$confirm({
        title: '是否确定执行任务名称: ' + record.taskName + '  taskId:' + record.id,
        content: h => {
          return
        },

        onOk() {
          self.loading = true;

          executeTask({ id: record.id }).then(res => {
            self.loadData();
          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },
    viewCoupon(mode, coupon = {}) {
      this.visible = true
      this.basicForm.id = coupon.id
      if (mode == 'edit') {

        this.basicForm = {
          //...this.defaultBasicForm,
          id: coupon.id,
          taskName: coupon.taskName,
          appSsid: coupon.appSsid,
          taskStatus: coupon.taskStatus === '1',
          sendType: coupon.sendType,
          couponId: coupon.couponId,
          sendDateType: coupon.sendDateType,
          sendDate: coupon.sendDate,
          sendGroup: coupon.sendGroup,
          sendDate_time: moment(coupon.sendDateType === '1' ? (moment().format('YYYY-MM-DD') + " " + coupon.sendDate) : coupon.sendDate),
          sendDate_date: moment(coupon.sendDateType === '1' ? (moment().format('YYYY-MM-DD') + " " + coupon.sendDate) : coupon.sendDate),
        }
      } else {
        this.basicForm = { ...this.defaultBasicForm }
      }
      this.modalTitle = mode == 'add' ? this.$t(I18N_KEY.COUPON + 'addTask') : this.$t(I18N_KEY.COUPON + 'modifyTasks')
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
    submitForm() {
      this.visible = false
      this.loading = true
      const form = {
        ...this.basicForm,
        taskStatus: this.basicForm.taskStatus ? 1 : 2,
        sendDate: this.basicForm.sendDateType == 1 ?
          ((this.basicForm.sendDate_time) ? this.transformTime(this.basicForm.sendDate_time) : undefined) :
          ((this.basicForm.sendDate_date) ? this.transformTime(this.basicForm.sendDate_date) : undefined)

      }

      if (form.id) {

        editCouponTask(form).then(res => {
          this.loading = false
          this.$message.success(this.$t(I18N_KEY.COUPON + 'success'))
          this.search(1)
        }).catch(e => {
          this.loading = false
          this.$message.error(e.message)
        })
      } else {
        form.couponMakeNum = form.couponTotalNum
        addCouponTask(form).then(res => {
          this.loading = false
          this.$message.success(this.$t(I18N_KEY.COUPON + 'success'))
          this.search(1)
        }).catch(e => {
          this.loading = false
          this.$message.error(e.message)
        })
      }
    }
  }
}
</script>
