<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="8" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'createdTime')">
              <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'handleStatus')">
              <a-select v-model="queryParam.status" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in handleStatuses" :value="item.dictValue">
                  {{ item[fieldName] }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'custId')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'custId')" v-model="queryParam.custId"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1300 }"
        :rowSelection="{ onChange: selectRowsChange, selectedRowKeys }"
        bordered>
        <span slot="action" slot-scope="text, record">
          <a @click="handle(record)" v-if="record.status==0">{{ $t('handle') }}</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          @change="handleTableChange" />
      </div>
    </div>

  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import { custComplaintBaseData,custComplaintList,custComplaintHandle } from '@/webpublicapi/custServiceWorkOrder'
import moment from 'moment';
import {mapGetters} from "vuex";
import _ from 'lodash';

const I18N_KEY = 'custServiceWorkOrder.';
export default {
  name: 'custComplaint',
  data () {
    return {
      I18N_KEY,
      visible: false,
      handleStatuses: [],
      total: 0,
      dataSource: [],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      // 查询条件
      queryParam: {
        "custId": null,
        "status": '',
        "createdTime": [],
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
      selectedRowKeys: [],
      fieldName: 'dictTextCn',
    }
  },

  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          align: 'left',
          width: 50,
          dataIndex: 'id'
        },
        {
          title: this.$t(I18N_KEY + 'custId'),
          align: 'left',
          width: 120,
          dataIndex: 'custId'
        },
        {
          title: this.$t(I18N_KEY + 'name'),
          align: 'left',
          width: 120,
          dataIndex: 'username'
        },
        {
          title: this.$t(I18N_KEY + 'custEmail'),
          align: 'left',
          width: 120,
          dataIndex: 'email'
        },
        {
          title: this.$t(I18N_KEY + 'custMobile'),
          align: 'left',
          width: 120,
          dataIndex: 'mobile'
        },
        {
          title: this.$t(I18N_KEY + 'handleStatus'),
          align: 'left',
          dataIndex: 'status',
          width: 120,
          customRender: (text, record) => {
            console.log(text)
            if(text != null && text != '') {
              for (var x in this.handleStatuses) {
                if(text == this.handleStatuses[x].dictValue) {
                  return this.handleStatuses[x][this.fieldName]
                }
              }
            }
            return ''
          }
        },
        {
          title: this.$t(I18N_KEY + 'subject'),
          align: 'left',
          width: 200,
          dataIndex: 'subject'
        },
        {
          title: this.$t(I18N_KEY + 'information'),
          align: 'left',
          dataIndex: 'information',
          width: 300
        },
        {
          title: this.$t(I18N_KEY + 'handleUser'),
          align: 'left',
          dataIndex: 'handleUser',
          width: 120,
        },
        {
          title: this.$t(I18N_KEY + 'handleTime'),
          align: 'left',
          dataIndex: 'handleTime',
          width: 160,
        },
        {
          title: this.$t(I18N_KEY + 'createTime'),
          align: 'left',
          width: 160,
          dataIndex: 'createTime'
        },
        {
          title: this.$t(I18N_KEY + 'updateTime'),
          align: 'left',
          dataIndex: 'updateTime',
          width: 160,
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData(1);
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadBaseData() {
      custComplaintBaseData({}).then((res) => {
        if (res.code === 1000) {
          this.handleStatuses = res.data.handleStatuses
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      custComplaintList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      if(this.queryParam.createdTime.length === 0) {
        data['startDate'] = ''
        data['endDate']=''
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.custId = null
      that.queryParam.status = ''
      that.queryParam.createdTime = []
    },
    handle(record){
      this.loading = true
      var param = {id:record.id}
      custComplaintHandle(param).then(res => {
        if(res.code === 1000){
          this.$message.success('success');
        } else {
          this.$message.error(res.msg);
        }
        this.loadData(this.form.pageNum);
      }).catch(e => {
        this.$message.error(e.message);
        this.loading = false
      })
    },
    selectRowsChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.originSelectedList = selectedRows;
      this.selectedList = _.cloneDeep(selectedRows);
    },
    handleStatusStyle(record) {
      var handleStatus = record.status
      switch (handleStatus) {
        case '0': return "width: 100%; color: rgb(129, 228, 189); font-weight: bold;";break;
        case '1': return "width: 100%; color: rgb(241, 199, 0); font-weight: bold;";break;
        default: return "width: 100%;";break;
      }
    },
    checkParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return '';
      }
      return p;
    },
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'dictTextEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'dictTextCn';
        } else if (lang == 'es') {
          this.fieldName = 'dictTextEs';
        } else {
          this.fieldName = 'dictTextEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>