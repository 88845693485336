<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="8" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'account')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'account')" v-model="queryParam.account"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'createdTime')">
              <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>

      </a-form>
      <!--        :scroll="{ x: 1300, y: 600}"-->
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :customRow="customRow"
        :scroll="{ x: 1300}"
        bordered>
        <template #item="text,record,index">{{$t(I18N_KEY + text)}}</template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { dayReport, flushDayReport} from '@/webpublicapi/custServiceWorkOrder';
import {mapGetters} from "vuex";
import _ from 'lodash';
import {filterObj} from "@/utils/util";
import moment from "moment";
const I18N_KEY = 'custServiceWorkOrder.';
// const indexs1 = [0,2,8,11,13,15,22,25,28,32,38,41,43,45,52,55,58];
// const indexs2 = [61,62,63,64,65,66,67,68];

export default {
  name: 'WorkOrderDayReport',
  data () {
    return {
      I18N_KEY,
      loading: false,
      dataSource: [],
      // fieldName: 'keyCnText',
      bordered: true,
      dateLabel: '',
      dateLabels: [],
      queryParam: {
        "account": undefined,
        "createdTime": undefined,
      },
    }
  },
  created() {
    // this.loadData();
  },
  methods: {
    searchQuery:_.debounce(function () {
      this.loadData()
    }, 3000),
    // searchQuery () {
    //   this.loadData()
    // },
    loadData() {
      this.loading = true
      var params = this.getQueryParams()// 查询条件

      dayReport(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data.list;
          this.dateLabels = res.data.dateLabels;
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message);
      })
    },
    getQueryParams() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.account = undefined
      that.queryParam.createdTime = undefined
    },
    // flush:_.debounce(function () {
    //   flushDayReport().then((res) => {
    //     if (res.code === 1000) {
    //       this.dataSource = res.data.list;
    //       this.dateLabel = res.data.dateLabel;
    //     } else {
    //       this.$message.error(res.msg)
    //     }
    //   }).catch(e => {
    //     this.$message.error(e.message);
    //   })
    // }, 5000),
    contains(sources, target) {
      for(var item in sources) {
        if(sources[item] === target) {
          return true;
        }
      }
      return false;
    },
    getColor(index, preColor) {
      // if(index === 31) {
      //   return "#00FFFF";
      // }
      if(index === 0) {
        return '#F4B084';
      }
      // if(this.contains(indexs2, index)) {
      //   return '#FFFF00';
      // }
      // if(this.contains(indexs1, index)) {
      //   return '#F4B084';
      // }
      return preColor;
    },
    getFont(index, preFont) {
      // if(index === 31 || index === 1 || this.contains(indexs2, index) || this.contains(indexs1, index)) {
      //   return "bolder";
      // }
      if(index === 0) {
        return "bolder";
      }
      return preFont;
    },
    customRow(record, index) {
      return {
        style: {
          'background-color':this.getColor(index, record.value1.bgColor),
          'font-weight':this.getFont(index, 'unset'),
        }
      }
    },
    createColumnHeader() {
      var headers = [];
      var first = {
        title: this.$t(I18N_KEY + 'item'),// 项目
        align: 'left',
        width: 400,
        ellipsis: true,
        fixed: 'left',
        dataIndex: 'fieldName',
        scopedSlots: {customRender: 'item'}
      };
      headers.push(first);
      for (var index1 in this.dateLabels) {
        var item = {
          title: this.dateLabels[index1],
          align: 'left',
          width: 100,
          dataIndex: 'value'+this.add(index1,1),
        };
        headers.push(item);
      }
      return headers;
    },
    add(num1,num2) {
      return Number(num1)+Number(num2);
    }
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return this.createColumnHeader();
    }
  },
  // watch: {
  //   languageStorage: {
  //     handler (lang) {
  //       if (lang == 'en-US') {
  //         this.fieldName = 'keyEnText';
  //       } else if (lang == 'zh-CN') {
  //         this.fieldName = 'keyCnText';
  //       } else if (lang == 'es') {
  //         this.fieldName = 'keyEsText';
  //       }
  //     },
  //     immediate: true,
  //   }
  // },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>