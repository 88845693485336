<template>
  <a-modal
    :title=" $t(I18N_KEY + 'assign')"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
<!--      <div :bordered="false">-->
<!--        <div class="table-page-search-wrapper">-->
          <a-form @keyup.enter.native="searchQuery">
            <a-row :gutter="24">
              <a-col :md='24' :sm='24'>
                <template>
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    :label="$t(I18N_KEY + 'registerTime')">
                    <a-range-picker format="YYYY-MM-DD" v-model:value='params.registerTime'/>
                  </a-form-item>
                </template>
              </a-col>

              <a-col :md='24' :sm='24'>
                <template>
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    :label="$t(I18N_KEY + 'type')">
                    <div :style="{ borderBottom: '1px solid #E9E9E9' }">
                      <a-checkbox
                        v-model:checked="checkAll"
                        :indeterminate="indeterminate"
                        @change='onCheckAllChange'
                      >
                        {{ $t(I18N_KEY + 'allTypes') }}
                      </a-checkbox>
                    </div>
                    <br />
                    <a-checkbox-group v-model:value='params.types'>
                      <!--                  <a-row :gutter="24" v-for='item in types' style='margin-bottom: 10px'>-->
                      <!--                    <a-col :span='12'>-->
                      <!--                      <a-checkbox :value='item.value'>{{$t(I18N_KEY + "type_" + item.label)}}</a-checkbox>-->
                      <!--                    </a-col>-->
                      <!--                    <a-col :span='12'>-->
                      <!--                      <a-input v-model:value="getIndex(item.value)" placeholder="默认为0"/>-->
                      <!--                    </a-col>-->
                      <!--                  </a-row>-->
                      <a-row :gutter="24" style='margin-bottom: 10px'>
                        <a-col :span="12">
                          <a-checkbox value="1">{{$t(I18N_KEY + "type_1")}}</a-checkbox>
                        </a-col>
                        <a-col :span="12">
                          <a-input v-model:value='params.value1' placeholder="默认为0"/>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" style='margin-bottom: 10px'>
                        <a-col :span="12">
                          <a-checkbox value="2">{{$t(I18N_KEY + "type_2")}}</a-checkbox>
                        </a-col>
                        <a-col :span="12">
                          <a-input v-model:value='params.value2' placeholder="默认为0"/>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" style='margin-bottom: 10px'>
                        <a-col :span="12">
                          <a-checkbox value="3">{{$t(I18N_KEY + "type_3")}}</a-checkbox>
                        </a-col>
                        <a-col :span="12">
                          <a-input v-model:value='params.value3' placeholder="默认为0"/>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" style='margin-bottom: 10px'>
                        <a-col :span="12">
                          <a-checkbox value="4">{{$t(I18N_KEY + "type_4")}}</a-checkbox>
                        </a-col>
                        <a-col :span="12">
                          <a-input v-model:value='params.value4' placeholder="默认为0"/>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" style='margin-bottom: 10px'>
                        <a-col :span="12">
                          <a-checkbox value="5">{{$t(I18N_KEY + "type_5")}}</a-checkbox>
                        </a-col>
                        <a-col :span="12">
                          <a-input v-model:value='params.value5' placeholder="默认为0"/>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" style='margin-bottom: 10px'>
                        <a-col :span="12">
                          <a-checkbox value="6">{{$t(I18N_KEY + "type_6")}}</a-checkbox>
                        </a-col>
                        <a-col :span="12">
                          <a-input v-model:value='params.value6' placeholder="默认为0"/>
                        </a-col>
                      </a-row>
                      <a-row :gutter="24" style='margin-bottom: 10px'>
                        <a-col :span="12">
                          <a-checkbox value="7">{{$t(I18N_KEY + "type_7")}}</a-checkbox>
                        </a-col>
                        <a-col :span="12">
                          <a-input v-model:value='params.value7' placeholder="默认为0"/>
                        </a-col>
                      </a-row>
                    </a-checkbox-group>

                  </a-form-item>
                </template>
              </a-col>

              <a-col :md='24' :sm='24'>
                <template>
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    :label="$t(I18N_KEY + 'teleMarketUser')">
                    <div :style="{ borderBottom: '1px solid #E9E9E9' }">
                      <a-checkbox
                        v-model:checked="checkAll1"
                        :indeterminate="indeterminate1"
                        @change='onCheckAllChange1'
                      >
                        {{ $t(I18N_KEY + 'allUsers') }}
                      </a-checkbox>
                    </div>
                    <br />
                    <a-checkbox-group v-model:value='params.users' :options="teleMarketUsers" />
                  </a-form-item>
                </template>
              </a-col>

              <a-col :md="24" :sm="24">
                <span style="text-align:center;overflow: hidden;" class="table-page-search-submitButtons">
                  <a-button type="primary" @click="searchQuery" icon="confirm">{{ $t('confirm') }}</a-button>
                  <!--              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>-->
                </span>
              </a-col>
            </a-row>
          </a-form>
<!--        </div>-->
<!--      </div>-->
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { filterObj } from '@/utils/util';
import { manualLoadData, teleMarketUsers, baseData } from '@/webpublicapi/teleMarket'
import moment from 'moment';
const I18N_KEY = 'teleMarket.';
export default {
  name: "AssignModal",
  props: {
    teleMarketUsers:{
      type: Array,
      default: [],
    },
    types:{
      type: Array,
      default: [],
    },
    typeValues:{
      type: Array,
      default: [],
    },
    teleMarketUserValues:{
      type: Array,
      default: [],
    }
  },
  data () {
    return {
      I18N_KEY,
      visible: false,
      checkAll: false,
      checkAll1: false,
      indeterminate: false,
      indeterminate1: false,
      params: {
        users: [],
        types: [],
        registerTime: [],
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
        value7: '',
      },
      // users:[],
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      // form: this.$form.createForm(this),
      // validatorRules:{
      //   dictType:{
      //     rules: [
      //       { required: true, message: 'Please input dict type!' },
      //     ]},
      //   dictKey:{
      //     rules: [
      //       { required: true, message: 'Please input dict key!' },
      //     ]},
      //   dictValue:{
      //     rules: [
      //       { required: true, message: 'Please input dict value!'},
      //     ]},
      //   sort:{
      //     rules: [
      //       { required: true, message: "Please input dict sort!"},
      //     ]},
      //   remark:{
      //     rules: [
      //     ]}
      // },
    }
  },
  created () {
  },
  methods: {
    // add () {
    //   this.edit({});
    // },
    // edit (record) {
    //   this.form.resetFields();
    //   this.open();
    //   this.model = Object.assign({}, record)
    //   this.model = {
    //     ...this.model,
    //     status: this.model.status != undefined ? '' + this.model.status : '0',
    //   }
    //
    //   this.$nextTick(() => {
    //     this.form.setFieldsValue(pick(this.model,'dictType', 'dictKey', 'dictValue', 'sort','status','remark'))
    //   });
    //
    // },
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    // handleOk () {
    //   console.log(88888)
    //   const that = this;
    //   // 触发表单验证
    //   this.form.validateFields((err, values) => {
    //     if (!err) {
    //       that.confirmLoading = true;
    //       let formData = {
    //         ...values,
    //       }
    //       this.$emit('ok', formData);
    //
    //     }
    //   })
    // },
    handleCancel () {
      this.close()
    },
    searchReset() {
      var that = this
      that.params.users = ''
      that.params.registerTime = undefined
      that.params.types = '';
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const { registerTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startTime: registerTime && this.transformTime(registerTime[0]),
        endTime: registerTime && this.transformTime(registerTime[1]),
      }
      // var registerTime1 = this.queryParam.registerTime;
      if(this.queryParam.registerTime.length === 0) {
        data['startTime'] = ''
        data['endTime']=''
      }
      console.log(data)
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    searchQuery () {
      this.doAssign()
    },
    doAssign() {
      // this.spinning = true
      // this.loading = true
      var params = this.getQueryParams()// 查询条件
      manualLoadData(Object.assign({}, params)).then((res) => {
        // this.spinning = false
        if (res.code === 1000) {
          this.$message.info("success")
        } else {
          this.$message.error(res.msg)
        }
        // this.loading = false
      }).catch(e => {
        // this.loading = false;
        // this.spinning = false
        this.$message.error(e.message);
      })
    },
    onCheckAllChange(){
      this.checkAll = this.checkAll?true:false;
      this.params.types = this.checkAll?this.typeValues:[];
    },
    onCheckAllChange1(){
      this.checkAll1 = this.checkAll1?true:false;
      this.params.users = this.checkAll1?this.teleMarketUserValues:[];
    },
  },
  watch: {
    "params.users"(newValue) {
      this.indeterminate1 =  !!this.params.users.length && this.params.users.length < this.teleMarketUsers.length;
      this.checkAll1 = this.params.users.length === this.teleMarketUsers.length;
    },
    "params.types"(oldValue, newValue){
      this.indeterminate = !!this.params.types.length && this.params.types.length < this.types.length;
      this.checkAll = this.params.types.length === this.types.length;
    },
  },
}
</script>

<style scoped>

</style>