<template>
  <div>
    <div style="padding: 15px">
      <a-input
        style="width: 240px; margin-right: 15px"
        :placeholder="$t('platformCollection.keyword')"
        v-model="form.keywords"
      />
      <a-button type="primary" @click="search(1)">{{ $t('search') }}</a-button>
      <a-button style="margin-left: 10px" type="primary" @click="createGroup('add')">
        <a-icon type="plus" />
        {{ $t('add') }}
      </a-button>
      <!-- <a-button style="margin-left: 10px;" type="primary" @click="batchStatus(0)"><a-icon />全部禁用</a-button>
      <a-button style="margin-left: 10px;" type="primary" @click="batchStatus(1)"><a-icon />全部启用</a-button> -->
    </div>
    <a-table :dataSource="list" :pagination="false" :loading="loading" rowKey="id" bordered @change="handleTableChange">
      <a-table-column
        :title="$t('platformCollection.group_name')"
        dataIndex="groupName"
        key="groupName"
        align="center"
      />
      <a-table-column
        :title="$t('platformCollection.group_leader')"
        dataIndex="adminUserName"
        key="adminUserName"
        align="center"
      />
      <a-table-column
        :title="$t('platformCollection.collection_stage')"
        dataIndex="collectionLevelName"
        key="collectionLevelName"
        align="center"
      />
      <a-table-column
        :title="$t('platformCollection.group_user_num')"
        dataIndex="sysGroupUserCount"
        key="sysGroupUserCount"
        align="center"
      />
      <a-table-column
        :title="$t('platformCollection.department')"
        dataIndex="departmentName"
        key="departmentName"
        align="center"
      />

      <a-table-column :title="$t('platformCollection.state')" dataIndex="status" key="status" align="center">
        <template slot-scope="text, record">
          <span>{{ text == 1 ? $t('platformCollection.enable') : $t('platformCollection.disable') }}</span>
        </template>
      </a-table-column>

      <a-table-column
        :title="$t('platformCollection.stageType')"
        dataIndex="collectionType"
        key="collectionType"
        align="center"
      >
        <template slot-scope="text, record">
          <span>{{ text === 2 ? '分期' : '非分期' }}</span>
        </template>
      </a-table-column>

      <a-table-column :title="$t('platformCollection.operation')" dataIndex="action" key="action" align="center">
        <template slot-scope="text, record">
          <span>
            <a href="javascript:;" @click="createGroup('edit', record)">{{ $t('platformCollection.edit') }}</a>
            <!-- <a-divider type='vertical' />
              <a href='javascript:;' @click='deleteCurrent(record)'>{{ $t('platformCollection.delete') }}</a>-->
            <a-divider type="vertical" />
            <a href="javascript:;" @click="openDrawer(record)">{{ $t('platformCollection.management') }}</a>
          </span>
        </template>
      </a-table-column>
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="form.pageNum"
        :page-size="form.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
    <a-modal :title="modalTitle" v-model="visible" @ok="submitUser">
      <a-form :form="basicForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item :label="$t('platformCollection.group_name')">
          <a-input v-model="basicForm.groupName" />
        </a-form-item>

        <a-form-item :label="$t('platformCollection.collection_level')">
          <a-select v-model="basicForm.collectionLevel">
            <a-select-option v-for="item in collectionLevelList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('platformCollection.group_leader')">
          <a-select v-model="basicForm.adminUserId">
            <a-select-option v-for="item in userList" :key="item.userId">
              {{ item.userName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('platformCollection.department')">
          <a-select v-model="basicForm.departmentId">
            <a-select-option v-for="item in departmentList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('platformCollection.state')">
          <a-switch v-model="basicForm.status" />
        </a-form-item>

        <a-form-item :label="$t('platformCollection.stageType')">
          <dict-select
            v-model="basicForm.collectionType"
            :dict-code="DICT_CODE.STAGE_TYPE"
            :placeholder="$t('select')"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-drawer
      :title="$t('platformCollection.management')"
      placement="right"
      :visible="drawerVisible"
      :group-id="currentGroupId"
      width="640"
      @close="closeDrawer"
    >
      <edit-group v-if="drawerVisible" :group-id="currentGroupId" />
    </a-drawer>
  </div>
</template>
<script>
import {
  getPlatformCollectionGroupList,
  addPlatformCollectionGroupList,
  deletePlatformCollectionGroupList,
  editPlatformCollectionGroupList,
  switchPlatformCollectionGroupList,
  getCollectionTeamLeaderList,
  getCollectionListDepartment,
} from '@/webpublicapi/group'

import { getProductConfig } from '@/webpublicapi/operation'

import vPagination from '../modules/pagination'
import EditGroup from './EditCollectionGroup'
import Dict from '../modules/Dict'
import DictSelect from '../modules/DictSelect'

export default {
  components: {
    Dict,
    DictSelect,
    vPagination,
    EditGroup,
  },
  data() {
    return {
      loading: false,
      modalTitle: this.$t('add'),
      currentGroupId: undefined,
      form: {
        pageNum: 1,
        pageSize: 10,
        // keywords: undefined,
      },
      visible: false,
      drawerVisible: false,
      defaultBasicForm: {
        groupName: undefined,
        collectionLevel: undefined,
        adminUserId: undefined,
        status: true,
        collectionType: undefined,
      },
      basicForm: {},
      USER_STATUS: {
        1: this.$t('platformCollection.enable'),
        2: this.$t('platformCollection.disable'),
      },
      appList: [],
      list: [],
      total: 0,
      selectedRowKeys: [],
      userList: [],
      collectionLevelList: [],
      departmentList: [],
    }
  },
  computed: {
    pagination() {
      return {
        pageSize: this.form.pageSize,
        current: this.form.pageNum,
        total: this.total,
      }
    },
    roleList() {
      return this.allRoleList || []
    },
  },
  created() {
    this.basicForm = { ...this.defaultBasicForm }
    this.search()
    getCollectionTeamLeaderList().then((res) => {
      this.userList = res.data || []
    })
    getProductConfig().then((res) => {
      this.collectionLevelList = res.data || []
    })
    getCollectionListDepartment().then((res) => {
      this.departmentList = res.data || []
    })
    this.getDict()
  },
  methods: {
    selectRowsChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.originSelectedList = selectedRows
      this.selectedList = _.cloneDeep(selectedRows)
    },
    batchStatus(status) {
      switchPlatformCollectionGroupList({ groupIds: this.selectedRowKeys, status })
        .then((res) => {
          this.loading = false
          this.$message.success(
            this.$t('platformCollection.all_n_successfully', {
              n: status == 1 ? this.$t('platformCollection.enable') : this.$t('platformCollection.disable'),
            })
          )
          this.search(1)
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.search()
    },
    changeModal(visible) {
      this.visible = visible
    },
    openDrawer(row) {
      this.currentGroupId = row.id
      this.$nextTick(() => {
        this.drawerVisible = true
      })
    },
    closeDrawer() {
      this.drawerVisible = false
    },
    getAppList() {
      getAppList({ pageNum: 1, pageSize: 100 }).then((res) => {
        if (res.code === 1000) {
          this.appList = res.data.rows || []
        }
      })
    },
    createGroup(mode, record = {}) {
      console.log(record)
      this.changeModal(true)
      this.basicForm.userId = record.id
      if (mode == 'edit') {
        this.basicForm = {
          ...this.basicForm,
          groupId: record.id,
          groupName: record.groupName,
          adminUserId: record.adminUserId,
          collectionLevel: record.collectionLevel,
          status: record.status == 1,
          departmentName: record.departmentName,
          departmentId: record.departmentId,
          collectionType: record.collectionType.toString(),
        }
      } else {
        this.basicForm = { ...this.defaultBasicForm }
      }
      this.modalTitle = mode == 'add' ? this.$t('add') : this.$t('edit')
    },
    deleteCurrent(row) {
      this.$confirm({
        title: this.$t('platformCollection.delete_current_group'),
        content: this.$t('platformCollection.delete_the_current_group'),
        onOk: () => {
          this.loading = true
          deletePlatformCollectionGroupList({ groupId: row.id })
            .then((res) => {
              this.search(1)
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        },
      })
    },
    submitUser() {
      this.changeModal(false)
      this.loading = true
      const form = {
        ...this.basicForm,
        collectionType: +this.basicForm.collectionType,
        status: this.basicForm.status ? 1 : 0,
      }
      const requestField = form.groupId ? editPlatformCollectionGroupList : addPlatformCollectionGroupList

      requestField(form)
        .then((res) => {
          this.loading = false
          this.$message.success(this.$t('success'))
          this.search(1)
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    search(pageNum) {
      if (pageNum) {
        this.form.pageNum = pageNum
      }
      this.loading = true
      getPlatformCollectionGroupList(this.form)
        .then((res) => {
          this.loading = false
          this.list = res && res.data && res.data.rows
          this.total = res && res.data && res.data.total
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
  },
}
</script>
