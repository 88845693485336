<template>
    <div>
        <a-spin :spinning="loading">
            <a-card
                :bordered="false"
                type="inner">
                <detail-list>
                    <detail-list-item :term="$t('name')" label="姓名">{{ accountInfo.userName }}</detail-list-item>
                    <detail-list-item :term="$t('phoneNo')" label="手机号">{{ accountInfo.mobile }}</detail-list-item>
                    <detail-list-item :term="$t('system.userList.role')" label="角色">{{ accountInfo.roles }}</detail-list-item>
                    <detail-list-item :term="$t('email')" label="邮箱">{{ accountInfo.email }}</detail-list-item>
                    <detail-list-item :term="$t('status')" :label="$t('platformCollection.state')"><a-badge :status="accountInfo.status == 1 ? 'success' : 'default'" />{{ $t(USER_STATUS[accountInfo.status]) }}</detail-list-item>
                    <detail-list-item :term="$t('system.userList.time')" label="创建时间">{{ accountInfo.createTime }}</detail-list-item>
                </detail-list>
            </a-card>
        </a-spin>
    </div>
</template>
<script>
    import { DetailList } from '@/components';
    // import { mapActions, mapGetters } from 'vuex';
    // import Dict from '../modules/Dict';
    const DetailListItem = DetailList.Item
    export default {
        props: {
            orderId: {
                type: [String, Number],
                default: undefined,
            },
            custInfoId: {
                type: [String, Number],
                default: undefined,
            },
        },
        components: {
            DetailList,
            DetailListItem,
        },
        data () {
            return {
                accountInfo: {},
                USER_STATUS: {
                    1: 'enable',
                    2: 'disable',
                },
                loading: false,
            }
        },
        methods: {
            add (data) {
                this.accountInfo = data;
            }
        }
    }
</script>
<style lang="less" scoped>
</style>