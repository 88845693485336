<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'createTime')">
              <a-range-picker
                v-model="queryParam.settleTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <!--<a-col :md="10" :sm="10">-->
            <!--<a-form-item :label="$t(I18N_KEY.COLLECTION + 'endTime')">-->
              <!--<a-range-picker-->
                <!--v-model="queryParam.endTime"-->
                <!--format="YYYY-MM-DD"-->
                <!--style="width: 100%;"/>-->
            <!--</a-form-item>-->
          <!--</a-col>-->
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.name')">
              <a-input
                :placeholder="$t('enter') + ' '+$t('platformCollection.name')"
                v-model="queryParam.userName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="search" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <Dict slot="followResult" slot-scope="text" :dict-code="DICT_CODE.FOLLOW_RESULT" :value="text"/>
      </a-table>


    </div>
    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { colDataGroupFollowResultAndNameP } from '@/webpublicapi/collection'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import exportFile from '@/webpublicapi/exportFile';
import CollectionDetail from './CollectionDetail';
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'CollectionFollowResultPerformance',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "userName":"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title:  this.$t('collection.userName'),
          align: 'left',
          dataIndex: 'userName'
        },
        {
          title:  this.$t('collection.createTime'),
          align: 'left',
          dataIndex: 'datadt'
        },
        // {
        //   title: this.$t('collection.followResult'),
        //   align: 'left',
        //   dataIndex: 'followResult',
        //   scopedSlots: { customRender: 'followResult' }
        // },
        {
          title: this.$t('platformCollection.answering_by_self'),
          align: 'left',
          dataIndex: 'oneData'
        },
        {
          title: this.$t('platformCollection.not_answering_by_self'),
          align: 'left',
          dataIndex: 'twoData'
        },
        {
          title: this.$t('platformCollection.no_answer'),
          align: 'left',
          dataIndex: 'threeData'
        },
        {
          title: this.$t('platformCollection.empty_wrong_number'),
          align: 'left',
          dataIndex: 'fourData'
        },
        {
          title: this.$t('platformCollection.shut_down'),
          align: 'left',
          dataIndex: 'fiveData'
        },
        {
          title: this.$t('platformCollection.stop'),
          align: 'left',
          dataIndex: 'sixData'
        },
        {
          title: this.$t('platformCollection.whatsapp_committed_to_repayment_on_time'),
          align: 'left',
          dataIndex: 'sevenData'
        },
        {
          title: this.$t('platformCollection.whatsapp_not_replied'),
          align: 'left',
          dataIndex: 'eightData'
        },
        {
          title: this.$t('platformCollection.no_whatsapp_account'),
          align: 'left',
          dataIndex: 'nineData'
        },
        {
          title: this.$t('platformCollection.to_follow_up'),
          align: 'left',
          dataIndex: 'zData'
        },
        {
          title: this.$t('collection.countResult'),
          align: 'left',
          dataIndex: 'countData'
        }
      ]
    },
  },
  created () {
    this.getDict();
    this.loadData()
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData()
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.form.pageNum = 1
      // }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      colDataGroupFollowResultAndNameP(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data.total
          this.dataSource = res.data || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const { settleTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          settleDateStart: (settleTime && settleTime.length) ? this.transformTime(settleTime[0]) : undefined,
          settleDateEnd : (settleTime && settleTime.length) ? this.transformTime(settleTime[1]) : undefined,
          endDateStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
          endDateEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.applyDate;
      var param = Object.assign({}, data)
      // param.pageNum = this.form.pageNum;
      // param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.endTime = undefined;
      that.queryParam.settleTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    downLoadData () {
      var params = this.getQueryParams('')// 查询条件
      const file = {
        fileUrl: '/api/web/platform/collectionTask/colDataGroupFollowResultAndNamePExcel?settleDateStart='
        + params.settleDateStart + '&settleDateEnd=' + params.settleDateEnd
        + '&endDateStart=' + params.endDateStart + '&endDateEnd=' + params.endDateEnd
        + '&userName=' + params.userName,
        fileName: 'followResultExcel.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
