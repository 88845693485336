<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
    </div>
    <div>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        bordered
      >
        <Dict slot="allowDiferTime" slot-scope="text" :dict-code="DICT_CODE.ALLOW_DIFER_TIME" :value="text"/>
        <template slot="allowPrepay" slot-scope="text">
          {{text==0?'不允许':'允许'}}
        </template>
        <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
            </span>

      </a-table>
    </div>
  </a-card>
</template>

<script>
  import pick from 'lodash.pick'
  import Dict from '../modules/Dict'
  import { riskFeaturesUsed } from '@/api/risk'
  export default {
    name: 'productItemList',
    components: { Dict},
    props: {
      id:{
        type: [String, Number],
        default: undefined,
      }
    },
    data () {
      return {
        dataSource: [],
        loading: false,
        columns: [
          {
            title: '策略ID',
            align: 'center',
            dataIndex: 'id'
          },
          {
            title: '策略集ID',
            align: 'center',
            dataIndex: 'strategyId'
          },
          {
            title: '特征ID',
            align: 'center',
            dataIndex: 'featuresId'
          },
          {
            title: '执行顺序',
            align: 'center',
            dataIndex: 'sortNum'
          },
          {
            title: '计算规则',
            align: 'center',
            dataIndex: 'rule'
          }
        ],
        title: '操作',
        visible: false,
        screenWidth: 1200,
        model: {},
        proId: '',
      }
    },
    created () {
      this.proId = this.id;
      this.loadData()
    },
    methods: {
      loadData () {
        this.loading = true
        console.log(this.proId)

        riskFeaturesUsed(Object.assign({featuresId:this.proId})).then((res)=>{
          if(res.code===1000){
            this.dataSource = res.data
          }else{
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.message);
        })
      },


      handleAdd () {
        this.$refs.prodictItemModal.add(this.proId,this.appSsid)
        this.$refs.prodictItemModal.title = '新增'
      },
      handleEdit (record) {
        record.allowDiferTime = record.allowDiferTime != undefined && '' + record.allowDiferTime;
        this.$refs.prodictItemModal.edit(record)
        this.$refs.prodictItemModal.title = '编辑'
      },
      handleConfigAdd () {
        this.$refs.ProdictConfigModal.add(this.proId,this.appSsid)
        this.$refs.ProdictConfigModal.title = '添加配置'
      },
      // 编辑字典数据
      editProdictConfig () {
        this.$refs.ProdictConfigList.edit(this.proId,this.appSsid)
        this.$refs.ProdictConfigList.title = '添加配置'
      },
      modalFormOk () {
        this.loadData()
      }
    },
    watch: {
      proId (val) {
        this.proId = val
      }
    }
  }
</script>
<style scoped>

</style>
