<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>

      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter='12'>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.ORDER + 'registerTime')">
              <a-range-picker
                v-model='queryParam.registerTime'
                format='YYYY-MM-DD'
                style='width: 100%;' />
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <a-form-item :label="'产品类型'">
              <a-select
                allowClear='true'
                v-model='queryParam.appssid'
              >
                <a-select-option v-for='item in appList' :key='item.appssid'>
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset') }}</a-button>
              <a-button type='primary' @click='download' icon='reload' style='margin-left: 8px'>导出</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :rowClassName='rowClassName'
        :pagination='false'
        :loading='loading'
        bordered>
      </a-table>

    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getApplist } from '@/webpublicapi/order'
import Dict from '../modules/Dict'
import DictSelect from '../modules/DictSelect'
import moment from 'moment'
import vModal from '../modules/Modal'
import { getAsInfo } from '@/webpublicapi/translate'
import exportFile from '@/api/exportFile'

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
  OPERATION: 'operation.'
}

export default {
  name: 'regUnOrderExport',
  components: {
    Dict,
    DictSelect,
    vModal
  },
  data() {
    return {
      I18N_KEY,
      toggleSearchStatus: false,
      visible: false,
      nextId: undefined,
      previousId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      appList: [],
      optTypeList: [],
      custInfoIdList: [],
      // 查询条件
      queryParam: {
        'appssid': '',
        'translateStatus': 7,
        'mobileNo': ''
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '手机号',
          align: 'left',
          dataIndex: 'mobileNo'
        }
      ]
    }
  },
  created() {
    getApplist().then(res => {
      this.appList = res.data || []
      if (this.appList != null) {
        this.appList.forEach(item => {
          if (item.appName == '全部') {
            item.appName = '请选择'
          }
        })
      }
    })
    this.getDict()
  },
  methods: {
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus
    },
    searchQuery() {
      this.loadData()
    },
    loadData(arg) {
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      this.dataSource = []
      getAsInfo(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data || []
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
      this.loading = false
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformTimeSecond(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase()
    },
    getQueryParams() {
      const { registerTime } = this.queryParam
      const data = {
        ...this.queryParam,
        startDate: this.transformTime(registerTime[0]),
        endDate: this.transformTime(registerTime[1])
      }
      delete data.applyDate
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.registerTime = undefined
      that.queryParam.appssid = '请选择'
      that.queryParam.mobileNo = undefined
      that.dataSource = []
    },
    download() {
      var params = this.getQueryParams()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/trans/exportAsRateInfoCust?appssid=' + params.appssid + '&translateStatus=7&startDate=' + params.startDate + '&endDate=' + params.endDate,
        fileName: '验证码未注册_' + params.startDate + '-' + params.endDate + ".xls",
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    changeModal(visible) {
      this.visible = visible
    },

    rowClassName(record, index) {
      var date = new Date(record.curDate)
      var day = date.getTime() / 24 / 60 / 60 / 1000 + 3

      if ((day % 7) + 1 == 7) {
        return 'table_weekendColor'
      }
      return ''
    }

  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
