import { axios } from '@/utils/request'

export function getPayoutList (parameter = {}) {
  return axios({
    url: '/platform/import/list',
    method: 'post',
    data: parameter
  })
}

export function getSysConfigList (parameter = {}) {
  return axios({
    url: '/config/list',
    method: 'post',
    data: parameter
  })
}

export function addSysConfig (parameter = {}) {
  return axios({
    url: '/config/add',
    method: 'post',
    data: parameter
  })
}

export function editSysConfig (parameter = {}) {
  return axios({
    url: '/config/update',
    method: 'post',
    data: parameter
  })
}

export function editSysConfigValue (parameter = {}) {
  return axios({
    url: '/config/updateValue',
    method: 'post',
    data: parameter
  })
}
