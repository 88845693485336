<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :md="8" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'assignTime')">
              <a-range-picker v-model="queryParam.assignTime" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>

          <a-col :md="10" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'type')">
              <a-select v-model="queryParam.type" :placeholder="$t('select')" style="width: 100%" mode="multiple" allowClear='true'>
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in types' :key='item.value'>
<!--                  {{$t(I18N_KEY + 'type_' + item.label)}}-->
                  {{item[fieldName]}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'assignStatus')">
              <a-select v-model="queryParam.status" :placeholder="$t('select')" style="width: 200px">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in statuses' :key='item'>
                  {{$t(I18N_KEY + 'assignStatus1_' + item)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData()" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1500 }"
        :rowClassName='rowClassName'
        bordered>
<!--        <template #type="text,record,index">{{$t(I18N_KEY + 'type_' + text)}}</template>-->
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          :pageSizeOptions = "pageSizeOptions"
          @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import { assignRecordList, baseData} from '@/webpublicapi/teleMarket'
import moment from 'moment';
import exportFile from '@/api/exportFile'
import {mapGetters} from "vuex";

const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketAssignRecord',
  data () {
    return {
      I18N_KEY,
      visible: false,
      country: '',
      types: [],
      statuses: ['0','1'],
      fieldName: 'textCn',
      pageSizeOptions: ['10', '20', '30', '50', '70', '90', '100'],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      view: false,
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "type": [],
        "status": '',
        "assignTime": []
      },
      loading: false,
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },

  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'batchId'),
          align: 'center',
          width: 100,
          dataIndex: 'batchId'
        },
        {
          title: this.$t(I18N_KEY + 'assignTime'),
          align: 'center',
          width: 160,
          dataIndex: 'assignTime'
        },
        {
          title: this.$t(I18N_KEY + 'type'),
          align: 'center',
          width: 200,
          dataIndex: 'type',
          customRender: (text, record) => {
            var text = text;
            if(text != undefined && text != null && text != '') {
              for(var i in this.types) {
                if(text === this.types[i].value) {
                  return this.types[i][this.fieldName]
                }
              }
            } else {
              return '';
            }
          },
          // scopedSlots: { customRender: 'type' }
        },
        {
          title: this.$t(I18N_KEY + 'assignNum'),
          align: 'center',
          width: 100,
          dataIndex: 'assignNum'
        },
        {
          title: this.$t(I18N_KEY + 'realNum'),
          align: 'center',
          width: 100,
          dataIndex: 'realNum'
        },
        {
          title: this.$t('custServiceWorkOrder.app'),
          align: 'center',
          width: 150,
          dataIndex: 'appSsid'
        },
        {
          title: this.$t(I18N_KEY + 'duration'),
          align: 'center',
          width: 100,
          dataIndex: 'duration'
        },
        {
          title: this.$t(I18N_KEY + 'teleMarketUser'),
          align: 'center',
          width: 150,
          dataIndex: 'users'
        },
        {
          title: this.$t(I18N_KEY + 'param'),
          align: 'center',
          width: 100,
          dataIndex: 'param'
        },
        {
          title: this.$t(I18N_KEY + 'groupName'),
          align: 'center',
          width: 100,
          dataIndex: 'userGroupName'
        },
        {
          title: this.$t(I18N_KEY + 'registerStartDate'),
          align: 'center',
          width: 100,
          dataIndex: 'startDate'
        },
        {
          title: this.$t(I18N_KEY + 'registerEndDate'),
          align: 'center',
          width: 100,
          dataIndex: 'endDate'
        },
        {
          title: this.$t('custServiceWorkOrder.operater'),
          align: 'center',
          width: 100,
          dataIndex: 'operator'
        }
      ]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData(1);
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadBaseData() {
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.types = res.data.types;
          this.country = res.data.country
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      assignRecordList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          console.log(res)
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    checkParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return '';
      }
      return p;
    },
    downLoadData () {
      const params = this.getQueryParams1()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/telemarket/assign/record/export?startDate=' + this.checkParam(params.startDate)
          + '&endDate=' + this.checkParam(params.endDate)
          + '&type=' + this.checkParam(params.type)
          + '&status=' + this.checkParam(params.status),
        fileName: 'teleMarketAssignRecord_'+ this.country +'.xlsx',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const { assignTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: assignTime && this.transformTime(assignTime[0]),
        endDate: assignTime && this.transformTime(assignTime[1]),
      }
      if(this.queryParam.assignTime.length === 0) {
        data['startDate'] = ''
        data['endDate']=''
      }
      console.log(data)
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },

    getQueryParams1() {
      const { assignTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: assignTime && this.transformTime(assignTime[0]),
        endDate: assignTime && this.transformTime(assignTime[1]),
      }
      if(this.queryParam.assignTime.length === 0) {
        data['startDate'] = ''
        data['endDate']=''
      }
      return Object.assign({}, data)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.assignTime = []
      that.queryParam.type = '';
      that.queryParam.status = '';
    },
    getUserLabel(user) {
      for(var item in this.teleMarketUsers) {
        var userObj = this.teleMarketUsers[item];
        if(userObj.value === user){
          return userObj.label;
        }
      }
    },
    getAppName(appSsid) {
      for(var item in this.apps) {
        var appObj = this.apps[item];
        if(appObj.appSsid === appSsid) {
          return appSsid+ '/'+appObj.appName;
        }
      }
      return appSsid;
    },
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>