<template>
  <a-modal
    :title="title"
    :width="1000"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >

    <div>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        :rowSelection="{ onChange: selectRowsChange, selectedRowKeys }"
        bordered

      >

        <Dict slot="allowDiferTime" slot-scope="text" :dict-code="DICT_CODE.ALLOW_DIFER_TIME" :value="text"/>
        <template slot="allowPrepay" slot-scope="text">
          {{ text == 0 ? '不允许' : '允许' }}
        </template>

      </a-table>
    </div>

  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import {editRiskRule, riskFeaturesList, addRiskRule} from '@/api/risk'
import _ from 'lodash'

// duplicateCheck
export default {
  name: 'riskRuleModal',
  data() {
    return {
      dataSource: [],
      loading: false,
      featuresId: undefined,
      selectedRowKeys: [],
      columns: [
        {
          title: 'ID',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '规则码',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '规则默认值',
          align: 'center',
          dataIndex: 'defaultValue'
        },
        {
          title: '说明',
          align: 'center',
          dataIndex: 'intro'
        },
        {
          title: '是否删除',
          align: 'center',
          dataIndex: 'isDel'
        }
      ],
      title: '操作',
      visible: false,
      screenWidth: 1200,
      model: {},
      proId: '',
    }
  },
  created() {
  },
  methods: {
    loadData() {
      this.loading = true;
      this.selectedRowKeys = [];
      riskFeaturesList().then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    selectRowsChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.originSelectedList = selectedRows;
      this.selectedList = _.cloneDeep(selectedRows);
    },
    add(proId) {
      this.proId = proId;
      this.edit({})
    },
    edit(record) {
      this.visible = true;
      this.loadData()
    },

    // 确定
    handleOk() {

      const formData = {idList: this.selectedRowKeys, strategyId: this.proId}
      addRiskRule(formData).then((res) => {
        if (res.code === 1000) {
          that.$message.success(res.msg)
          that.$emit('ok')
          this.close()
        } else {
          that.$message.warning(res.msg)
        }
      })

    },
    // 关闭
    handleCancel() {
      this.close()
    },
    close() {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
