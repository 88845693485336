<template>
  <div style="padding: 2px">
    <a-spin :spinning="loading">
      <a-row :gutter="24">
        <a-col :span="18">
          <a-card :bordered="false">
            <a-card
              :title="$t(I18N_KEY + 'workOrderDetail')"
              type="inner">
              <detail-list :col="twoCol">
<!--                <detail-list-item :term="$t(I18N_KEY + 'custMobile')">{{workOrder.custMobile}}</detail-list-item>-->
                <detail-list-item :term="$t(I18N_KEY + 'userMobile')">{{workOrder.userMobile}}</detail-list-item>
<!--                <detail-list-item :term="$t(I18N_KEY + 'custEmail')">{{workOrder.custEmail}}</detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'name')">{{workOrder.name}}</detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'surname')">{{workOrder.surname}}</detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'type')">{{$t(I18N_KEY + 'type_' + workOrder.type)}}</detail-list-item>-->
                <detail-list-item :term="$t(I18N_KEY + 'status')"><span v-if='workOrder.status != undefined'>{{$t(I18N_KEY + 'status_' + workOrder.status)}}</span></detail-list-item>
                <!--          <detail-list-item :term="$t(I18N_KEY + 'firstLevelQuestion')">{{$t(I18N_KEY + 'firstLevelQuestion_' + workOrder.firstLevelQuestion)}}</detail-list-item>-->
                <!--          <detail-list-item :term="$t(I18N_KEY + 'secondLevelQuestion')">{{$t(I18N_KEY + 'secondLevelQuestion_' + workOrder.firstLevelQuestion + '_' + workOrder.secondLevelQuestion)}}</detail-list-item>-->
                <detail-list-item :term="$t(I18N_KEY + 'questionV1')"><span v-if='workOrder.questionV1 != undefined'>{{$t(I18N_KEY + 'questionV1_' + workOrder.questionV1)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'complainChannel')"><span v-if='workOrder.complainChannel != undefined'>{{$t(I18N_KEY + 'complainChannel_' + workOrder.complainChannel)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'custAppeal')"><span v-if="workOrder.custAppeal != undefined">{{$t(I18N_KEY + 'custAppeal_' + workOrder.custAppeal)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'app')" >{{ handleApp(app) }}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'remark')">{{workOrder.remark}}</detail-list-item>
<!--                <detail-list-item :term="$t(I18N_KEY + 'optRemark')">{{workOrder.optRemark}}</detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'collectionUser')">{{workOrder.collectionUser}}</detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'collectionMobile')">{{workOrder.collectionMobile}}</detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'collectionDate')">{{workOrder.collectionDate}}</detail-list-item>-->
                <detail-list-item :term="$t(I18N_KEY + 'level')"><span v-if='workOrder.level != undefined'>{{$t(I18N_KEY + 'level_' + workOrder.level)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'handleStatus')"><span v-if='workOrder.handleStatus != undefined'>{{$t(I18N_KEY + 'handleStatus_' + workOrder.handleStatus)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'satisfaction')"><span v-if='workOrder.satisfaction != undefined'>{{$t(I18N_KEY + 'satisfaction_' + workOrder.satisfaction)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'backVisit')"><span v-if='workOrder.backVisit != undefined'>{{$t(I18N_KEY + 'backVisit_' + workOrder.backVisit)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'connect')"><span v-if='workOrder.connect != undefined'>{{$t(I18N_KEY + 'connect_' + workOrder.connect)}}</span></detail-list-item>
<!--                <detail-list-item :term="$t(I18N_KEY + 'orderType')"><span v-if='workOrder.orderType != undefined'>{{$t(I18N_KEY + 'orderType_' + workOrder.orderType)}}</span></detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'orderNo')">{{workOrder.orderNo}}</detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'settleStatus')" v-if='workOrder.orderType == 0'><span v-if='workOrder.settleStatus != undefined'>{{$t(I18N_KEY + 'settleStatus_' + workOrder.settleStatus)}}</span></detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'settleStatus')" v-if='workOrder.orderType == 1'><span v-if='workOrder.settleStatus != undefined'>{{$t(I18N_KEY + 'installmentSettleStatus_' + workOrder.settleStatus)}}</span></detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'approvalStatus')"><span v-if='workOrder.approvalStatus != undefined'>{{$t(I18N_KEY + 'approvalStatus_' + workOrder.approvalStatus)}}</span></detail-list-item>-->
<!--                <detail-list-item :term="$t(I18N_KEY + 'custId')">{{workOrder.custId}}</detail-list-item>-->
                <detail-list-item :term="$t(I18N_KEY + 'workFlowType')"><span v-if='workOrder.workFlowType != undefined'>{{$t(I18N_KEY + 'workFlowType_' + workOrder.workFlowType)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'decision')"><span v-if='workOrder.decision != undefined'>{{$t(I18N_KEY + 'decision_' + workOrder.decision)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'baAmount')">{{workOrder.baAmount}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'topStatus')"><span v-if='workOrder.topStatus != undefined'>{{$t(I18N_KEY + 'topStatus_' + workOrder.topStatus)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'tag')"><span v-if='workOrder.tag != undefined'>{{handleTag(workOrder.tag)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'aiType')"><span v-if='workOrder.aiType != undefined'>{{$t(I18N_KEY + 'aiType_' + workOrder.aiType)}}</span></detail-list-item>
<!--                <detail-list-item :term="$t(I18N_KEY + 'frontSource')"><span v-if='workOrder.frontSource != undefined'>{{$t(I18N_KEY + 'frontSource_' + workOrder.frontSource)}}</span></detail-list-item>-->
                <detail-list-item :term="$t(I18N_KEY + 'frontSource')"><span v-if='workOrder.frontSource != undefined'>{{frontSourceShow(workOrder.frontSource)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'resolveStatus')"><span v-if='workOrder.resolveStatus != undefined'>{{$t(I18N_KEY + 'resolveStatus_' + workOrder.resolveStatus)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'createTime')" >{{ workOrder.createTime }}</detail-list-item>
              </detail-list>
            </a-card>
            <a-card :title="$t('customer.customerDetail')" type="inner" style="margin-top: 24px">
              <detail-list :col="twoCol">
                <detail-list-item :term="$t(I18N_KEY + 'custId')">{{workOrder.custId}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'custMobile')">{{workOrder.custMobile}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'custEmail')">{{workOrder.custEmail}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'name')">{{workOrder.name}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'surname')">{{workOrder.surname}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'app')" >{{ handleApp(app) }}</detail-list-item>
              </detail-list>
            </a-card>
            <a-card :title="$t('order.orderDetail')" type="inner" style="margin-top: 24px">
              <detail-list :col="twoCol">
                <detail-list-item :term="$t(I18N_KEY + 'orderNo')">{{workOrder.orderNo}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'orderType')"><span v-if='workOrder.orderType != undefined'>{{$t(I18N_KEY + 'orderType_' + workOrder.orderType)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'approvalStatus')"><span v-if='workOrder.approvalStatus != undefined'>{{$t(I18N_KEY + 'approvalStatus_' + workOrder.approvalStatus)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'settleStatus')" v-if='workOrder.orderType == 0'><span v-if='workOrder.settleStatus != undefined'>{{$t(I18N_KEY + 'settleStatus_' + workOrder.settleStatus)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'settleStatus')" v-if='workOrder.orderType == 1'><span v-if='workOrder.settleStatus != undefined'>{{$t(I18N_KEY + 'installmentSettleStatus_' + workOrder.settleStatus)}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'app')" >{{ handleApp(app) }}</detail-list-item>
                <detail-list-item :term="$t('order.applicationTime')" >{{ workOrder.applyTime }}</detail-list-item>
                <detail-list-item :term="$t('order.startingTime')" >{{ workOrder.startDate }}</detail-list-item>
                <detail-list-item :term="$t('order.expireTime')" >{{ workOrder.endDate }}</detail-list-item>
                <detail-list-item :term="$t('order.totalPeriod')" >{{ workOrder.totalPeriod }}</detail-list-item>
                <detail-list-item :term="$t('order.applicationAmount')" >{{ workOrder.orderCapital }}</detail-list-item>
                <detail-list-item :term="$t('order.totalRepayableAmount')" >{{ workOrder.orderRepayAmount }}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'orderUnRepayAmount')" >{{ workOrder.orderUnRepayAmount }}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'collectionUser')">{{workOrder.collectionUser}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'collectionMobile')">{{workOrder.collectionMobile}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'collectionDate')">{{workOrder.collectionDate}}</detail-list-item>
              </detail-list>
            </a-card>
            <a-card v-if='repayPlans.length > 0' style="margin-top: 24px" :title="$t('order.repaymentPlan')" type="inner">
              <a-table
                ref="table"
                rowKey="id"
                size="middle"
                :columns="columns"
                :dataSource="repayPlans"
                :pagination="false"
                :loading="loading"
                bordered>
                <template #status="text,record,index">{{$t(I18N_KEY + 'settleStatus_' + text)}}</template>
              </a-table>
            </a-card>
            <a-card v-if="attachments.length > 0"
                    style="margin-top: 24px"
                    :title="$t(I18N_KEY + 'attachment')"
                    type="inner"
                    v-viewer="viewOptions">
              <div v-for="(item, index) in attachments">
                <div class="file-box">
                  <div class="file-item">
                    <img class="file-preview" :src="item" preview="0">
                    <div style='text-align: center;margin-top: 15px;' v-if='workOrder.toFinanceSwitch && workOrder.toFinance==0'><a-switch :checked-children="$t(I18N_KEY + 'toFinance')" v-model:checked="checked[index]" size='default' @change='changeSwitch(index)'/></div>
                  </div>
                </div>
              </div>
              <div style="float: right;overflow: hidden;margin-top: 23px" class="table-page-search-submitButtons" v-if='workOrder.toFinanceSwitch && workOrder.toFinance==0'>
                <a-button type="primary" @click="toFinance" icon="enter" :disabled='toFinanceDisabled'>{{$t(I18N_KEY + 'toFinance')}}</a-button>
              </div>
            </a-card>
            <a-card v-if="videoUrls.length > 0"
                    style="margin-top: 24px"
                    :title="$t(I18N_KEY + 'video')"
                    type="inner"
                    v-viewer="viewOptions">
              <div v-for="(item, index) in videoUrls">
                <div class="file-box">
                  <div class="file-item">
                    <video class="file-preview" :src="item" preview="0" @click='previewVideo(item)'/>
                  </div>
                </div>
              </div>
              <a-modal :visible='videoPreviewVisible' :footer='null' @cancel='videoPreviewCancel()'>
                <video width='100%' controls id='video' :src="previewVideoUrl">
                  {{$t(I18N_KEY + "videoPreviewNotice")}}
                </video>
              </a-modal>
            </a-card>
            <a-card v-if='viewApprove' :title="$t(I18N_KEY + 'approve')" type="inner" style="margin-top: 24px">
              <!-- 审批卡片 -->
              <a-form layout="inline" @keyup.enter.native="approve" :labelCol='labelCol' :wrapperCol='wrapperCol'>
                <a-row :gutter="24">
                  <!-- 是否回访 可选-->
                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='showBackVisitTrigger'>
                    <a-form-item :label="$t(I18N_KEY + 'backVisit')" style='width:100%'>
                      <a-select v-model="queryParam.backVisit" :placeholder="$t('select')" style="width: 100%;display: block;" @change="handleBackVisitChange">
                        <a-select-option v-for="item in backVisits" :value="item.dictValue">
                          {{ $t(I18N_KEY + 'backVisit_' + item.dictValue) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <!-- 是否接通 可选-->
                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='showConnectTrigger'>
                    <a-form-item :label="$t(I18N_KEY + 'connect')" style='width:100%'>
                      <a-select v-model="queryParam.connect" :placeholder="$t('select')" style="width: 100%;display: block;" >
                        <a-select-option v-for="item in connects" :value="item.dictValue">
                          {{ $t(I18N_KEY + 'connect_' + item.dictValue) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <!-- 催收级别 可选-->
                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='toDetailViewCollectionSelectItem'>
                    <a-form-item :label="$t(I18N_KEY + 'level')" style='width:100%' >
                      <a-select v-model="queryParam.level" :placeholder="$t('select')" :style="levelStyle">
                        <a-select-option v-for="item in levels" :value="item.dictValue">
                          {{ $t(I18N_KEY + 'level_' + item.dictValue) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <!-- 处理状态 可选-->
                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='toDetailViewCollectionSelectItem'>
                    <a-form-item :label="$t(I18N_KEY + 'handleStatus')" style='width:100%'>
                      <a-select v-model="queryParam.handleStatus" :placeholder="$t('select')" :style="handleStatusStyle" @change="handleHandleStatusChange">
                        <a-select-option v-for="item in handleStatuses" :value="item.dictValue">
                          {{ $t(I18N_KEY + 'handleStatus_' + item.dictValue) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <!-- 满意度 可选-->
                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='showSatisfactionTrigger'>
                    <a-form-item :label="$t(I18N_KEY + 'satisfaction')" style='width:100%'>
                      <a-select v-model="queryParam.satisfaction" :placeholder="$t('select')" :style="satisfactionStyle">
                        <a-select-option v-for="item in satisfactions" :value="item.dictValue">
                          {{ $t(I18N_KEY + 'satisfaction_' + item.dictValue) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :md="24" :sm="8" style='margin-top: 30px'>
                    <span style="float: right;overflow: hidden;margin-top: 23px" class="table-page-search-submitButtons">
                      <a-button type="primary" @click="save" icon="enter">{{ $t(I18N_KEY + 'save') }}</a-button>
                    </span>
                  </a-col>

                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='showOperateTypeTrigger'>
                    <a-form-item :label="$t(I18N_KEY + 'approveResult')" style='width:100%' :required='required_true'>
                      <a-select v-model="queryParam.operateType" :placeholder="$t('select')" style="width: 100%;display: block;" @change="handleChange">
                        <a-select-option v-for="item in operateTypes" :value="item">
                          {{ $t(I18N_KEY + 'operateType_' + item) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <!--                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='showNextApprovelUserTrigger'>-->
                  <!--                    <a-form-item :label="$t(I18N_KEY + 'nextApproveRole')" style='width:100%'>-->
                  <!--                      <a-input style="width: 100%;border-width: 0px" :value="$t(I18N_KEY + 'approveRole_' + this.nextApproveRole)" :disabled='disabled'>{{ $t(I18N_KEY + 'approveRole_' + this.nextApproveRole)}}</a-input>-->
                  <!--                    </a-form-item>-->
                  <!--                  </a-col>-->
                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='showNextApprovelUserTrigger'>
                    <a-form-item :label="$t(I18N_KEY + 'nextApproveUser')" style='width:100%' :required='required_true'>
                      <a-select v-model="queryParam.approvelUser" :placeholder="$t('select')" style="width: 100%;display: block;">
                        <a-select-option v-for="item in nextApproveUsers"  :value="item">
                          {{item}}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='showDecisionTrigger'>
                    <a-form-item :label="$t(I18N_KEY + 'decision')" style='width:100%' :required='required_true'>
                      <a-select v-model="queryParam.decision" :placeholder="$t('select')" style="width: 100%;display: block;" @change="handleChange1">
                        <a-select-option v-for="item in decisions"  :value="item">
                          {{$t(I18N_KEY + 'decision_' + item)}}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="8" style='margin-top: 30px' v-if='showBaAmountTrigger'>
                    <a-form-item :label="$t(I18N_KEY + 'baAmount')" style='width:100%' :required='required_true'>
                      <a-input style="width: 100%;" v-model="queryParam.baAmountStr"/>
                    </a-form-item>
                  </a-col>

                  <a-col :md="24" :sm="8" style='margin-top: 30px'>
                    <a-form-item :label='$t(I18N_KEY + "uploadImage")' style='width:100%'>
                      <a-upload :customRequest='customRequest'
                                :remove='remove'
                                :beforeUpload='beforeUpload'
                                @preview='handlePreview'
                                listType='picture-card'
                                v-model:file-list="fileList">
<!--                        <a-button v-if='fileList.length < 4'>{{ $t(I18N_KEY + "upload") }}</a-button>-->
                        <div v-if="fileList.length < 8">
                          <!--                  <a-icon type='plus'>-->
                          <!--                    <plus-outlined />-->
                          <div class='ant-upload-text'>{{ $t(I18N_KEY + "upload") }}</div>
                          <!--                  </a-icon>-->
                        </div>
                      </a-upload>
                      <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                      </a-modal>
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="8" style='margin-top: 30px'>
                    <a-form-item :label="$t(I18N_KEY + 'remark')" style='width:100%'  :required='required_remark'>
                      <a-textarea col='3' :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'remark')"  v-model="queryParam.remark"/>
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="8" style='margin-top: 30px'>
                    <span style="float: right;overflow: hidden;margin-top: 23px" class="table-page-search-submitButtons">
                      <a-button type="primary" @click="approve" icon="enter">{{ $t(I18N_KEY + 'approve') }}</a-button>
                    </span>
                  </a-col>
                </a-row>
              </a-form>
            </a-card>
          </a-card>
        </a-col>
        <a-col :span="6">
          <a-card :title="$t(I18N_KEY + 'processFlows')" :bordered="false">
            <a-card v-for='flow in processFlows' :bordered="false">
              <detail-list :col="oneCol">
                <detail-list-item :term="$t(I18N_KEY + 'operateTime')">{{flow.createTime}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'operater')"><span v-if='flow.operater == "appUser"'>{{$t(I18N_KEY + flow.operater)}}</span><span v-if='flow.operater != "appUser"'>{{flow.operater}}</span></detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'approveResult')" v-if="flow.operateType != undefined">{{$t(I18N_KEY + 'operateType_' + flow.operateType)}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'businessNode')">{{$t(I18N_KEY + 'businessNode_' + flow.businessNode)}}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY + 'decision')" v-if="flow.decision != undefined">{{$t(I18N_KEY + 'decision_' + flow.decision)}}</detail-list-item>
                <!--                <detail-list-item :term="$t(I18N_KEY + 'decision')" v-if="flow.decision != undefined">{{$t(I18N_KEY + 'decision_' + flow.decision)}}</detail-list-item>-->
                <detail-list-item :term="$t(I18N_KEY + 'remark')" v-if="flow.remark != undefined || flow.attachmentCode!= undefined">{{flow.remark}}<span v-if='flow.attachmentCode!= undefined'>({{$t(I18N_KEY + 'hasImage')}})</span></detail-list-item>
              </detail-list>
              <a-divider style="height: 1px; background-color: #e8e8e8; margin: 1px" />
            </a-card>
          </a-card>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>
<script>
import { DetailList } from '@/components';
import imageMixin from '@/mixins/image-mixin';
import {handleWorkOrder, workOrderDetail, nextApprovelData,uploadAndGet,toFinance} from '@/webpublicapi/custServiceWorkOrder';
import { upload } from '@/webpublicapi/teleMarket'
import { filterObj } from '@/utils/util'
import {mapGetters} from "vuex";
const DetailListItem = DetailList.Item
const I18N_KEY = 'custServiceWorkOrder.';

export default {
  name: 'WorkOrderDetail',
  props: {
    orderId: {
      type: [String, Number],
      default: undefined,
    },
    operateTypes:{
      type: Array,
      default: () => [],
    },
    handleStatuses:{
      type: Array,
      default: () => [],
    },
    satisfactions:{
      type: Array,
      default: () => [],
    },
    backVisits:{
      type: Array,
      default: () => [],
    },
    connects:{
      type: Array,
      default: () => [],
    },
    levels:{
      type: Array,
      default: () => [],
    },
    viewApprove:{
      type: Boolean,
      default: false,
    },
    toDetailViewCollectionSelectItem: {
      type: Boolean,
      default: false,
    },
    modelTags:{
      type: Array,
      default: () => [],
    },
    frontSources:{
      type: Array,
      default: () => [],
    },
    fieldName: {
      type: String,
      default: 'dictTextCn'
    }
  },
  components: {
    DetailList,
    DetailListItem,
  },
  mixins: [imageMixin],
  data () {
    return {
      I18N_KEY,
      loading: false,
      disabled: true,
      toFinanceDisabled: false,
      workOrder: {},
      attachments: [],
      videoUrls: [],
      attachments1: [],
      processFlows: [],
      decisions:[],
      repayPlans:[],
      checked: [],
      imgIndex:[],
      // operateTypes: [],
      fileList: [],
      imgCount: 0,
      imageLoading:[],
      app: '',
      twoCol: 2,
      oneCol: 1,
      showBackVisitTrigger: true,
      showConnectTrigger: false,
      // showLevelTrigger: false,
      // showHandleStatusTrigger: false,
      levelStyle: '',
      handleStatusStyle: '',
      satisfactionStyle: '',
      showSatisfactionTrigger: false,
      showOperateTypeTrigger: false,
      showNextApprovelUserTrigger: false,
      showDecisionTrigger: false,
      showBaAmountTrigger: false,
      tagText: '',
      queryParam: {
        operateType: '',
        attachmentCode: '',
        remark: '',
        approvelUser: '',
        decision: '',
        baAmountStr: '',
        // 新增
        backVisit: '',// 是否回访
        connect: '',// 是否接通
        level: '',// 投诉级别
        handleStatus: '',// 处理状态
        satisfaction: '',// 满意度
      },
      nextApproveRole: '',
      nextApproveUsers: [],
      handleNode: '',
      labelCol:{ span: 6 },
      wrapperCol:{ span: 12 },
      videoPreviewVisible: false,
      previewVideoUrl: '',
      previewImage: '',
      previewVisible: false,
      previewTitle: '',
      imageSizeLimit: 10,
      required_true: true,
      required_remark: false,
      fieldName: 'dictTextCn'
    }
  },
  created () {
    this.requestDetail(this.orderId);
  },
  methods: {
    requestDetail (id) {
      workOrderDetail(id).then(res => {
        this.workOrder = res.data || {};
        this.attachments = res.data.attachmentUrls || [];
        this.videoUrls = res.data.videoUrls || [];
        this.decisions = res.data.decisions || [];
        this.processFlows = res.data.processFlows.reverse() || [];
        this.handleNode = res.data.handleNode || '';
        this.required_remark = this.workOrder.status === '5' || false
        this.app = res.data.app;
        this.imageSizeLimit = res.data.imageSizeLimit || this.imageSizeLimit
        this.showOperateTypeTrigger = res.data.questionV1 != '4' || false
        this.queryParam.backVisit = res.data.backVisit || ''
        var connect = res.data.connect
        if(connect != null && connect != '' && connect != undefined) {
          this.queryParam.connect = connect
          this.showConnectTrigger = true
        }
        this.queryParam.level = res.data.level || ''
        this.queryParam.handleStatus = res.data.handleStatus || ''
        var satisfaction = res.data.satisfaction
        if(satisfaction != null && satisfaction != '' && satisfaction != undefined) {
          this.queryParam.satisfaction = satisfaction
          this.showSatisfactionTrigger = true
        }
        this.repayPlans = res.data.repayPlans || []
        for(var i in this.attachments) {
          this.checked.push(false)
        }
        this.loading = false;
      }).catch(e => {
        this.$message.error(e.message);
        this.loading = false;
      })
    },
    handleApp(app) {
      var app = app;
      return app ? app.replaceAll(',', ", "):app;
    },
    getQueryParams() {
      var attacmentCodes = '';
      if(this.fileList.length > 0) {
        for(var item in this.fileList){
          var file = this.fileList[item];
          if(attacmentCodes === '') {
            attacmentCodes = file.attCode
          } else {
            attacmentCodes = attacmentCodes + ',' + file.attCode
          }
        }
        this.queryParam.attachmentCode = attacmentCodes
      }
      const data = {
        ...this.queryParam,
        id: this.orderId,
      }
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    handleTag(tag) {
      var tagText = '';
      if(tag != null && tag != '' && tag != undefined) {
        var tags = tag.split(",")
        for(var index in tags) {
          var tag1 = tags[index]
          for(var x in this.modelTags) {
            if(this.modelTags[x].dictValue == tag1) {
              if(index == 0) {
                tagText = this.modelTags[x][this.fieldName]
              } else {
                tagText = tagText + ',' + this.modelTags[x][this.fieldName]
              }
            }
          }
        }
      }
      return tagText;
    },
    save() {
      this.loading = true
      var params = this.getQueryParams()
      params['handleType'] = 'store'
      handleWorkOrder(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.$message.info("success")
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    approve() {
      for(var item in this.imageLoading) {
        if("loading" === this.imageLoading[item]) {
          this.$message.error(this.$t(I18N_KEY + 'imageLoadingNotice'))
          return;
        }
      }
      // if(!this.checkNull(this.queryParam.operateType)) {
      if(this.showOperateTypeTrigger && !this.checkNull(this.queryParam.operateType) ) {
        this.$message.error(this.$t(I18N_KEY + 'operateTypeNotice'))
        return;
      }
      if(!this.checkNull(this.queryParam.operateType)) {
        this.queryParam.operateType = "1"// 默认为审批通过
      }
      if(this.workOrder['status'] === '5' && this.queryParam.remark == '') {// 产品处理中，必填备注
        this.$message.error(this.$t(I18N_KEY + 'productRemarkNotice'))
        return;
      }

      if(this.showNextApprovelUserTrigger && !this.checkNull(this.queryParam.approvelUser) ) {
        this.$message.error(this.$t(I18N_KEY + 'approvelUserNotice'))
        return;
      }
      if(this.showDecisionTrigger && !this.checkNull(this.queryParam.decision) ) {
        this.$message.error(this.$t(I18N_KEY + 'decisionNotice'))
        return;
      }
      if(this.showBaAmountTrigger && !this.checkNull(this.queryParam.baAmountStr) ) {
        this.$message.error(this.$t(I18N_KEY + 'baAmountNotice'))
        return;
      }
      this.loading = true
      var params = this.getQueryParams()
      params['handleType'] = 'approve'
      handleWorkOrder(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.$message.info("success")
          this.$emit('closeLoad')
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    customRequest(file){
      const form = new FormData();
      var theCount = this.imgCount;
      this.imgCount = this.imgCount + 1;
      this.imageLoading[Number(theCount)] = 'loading'
      form.append('file', file.file)
      uploadAndGet(form).then(res => {
        if (res.code === 1000) {
          file.onSuccess(res, file.file)
          file.status = 'done'
          this.imageLoading[Number(theCount)] = 'done';
          this.$message.info("success")
          file['attCode'] = res.data.attachmentCode
          this.fileList.push(file);
          // console.log(file)
        } else {
          file.onError()
          file.status = 'error'
          this.imageLoading[Number(theCount)] = 'done';
          this.$message.error(res.msg)
        }
      }).catch(e => {
        file.onError()
        file.status = 'error'
        this.imageLoading[Number(theCount)] = 'done';
        this.$message.error(e.message);
      })
    },
    remove(file) {
      // console.log(file)
      if(file.status == 'error') {
        return true;
      }
      var newList = [];
      for(var item in this.fileList){
        var theFile = this.fileList[item];
        if(theFile.attCode === file.response.data.attachmentCode) {
          continue;
        }
        newList.push(theFile);
      }
      this.fileList = newList;
      // console.log(newList)
      return true;
    },
    // beforeUpload(file) {
    //   // console.log(file)
    //   const isLt10M = file.size < this.imageSizeLimit * 1024 * 1024
    //   const isMatchType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif'
    //   if(!isLt10M) {
    //     this.$message.error(this.$t(I18N_KEY + 'imageSizeNotice_pre') + this.imageSizeLimit + this.$t(I18N_KEY + 'imageSizeNotice_suf'))
    //   }
    //   if(!isMatchType) {
    //     this.$message.error(this.$t(I18N_KEY + 'imageTypeNotice'))
    //   }
    //
    //   return isLt10M && isMatchType;
    // },
    beforeUpload(file) {
      const _this = this
      return new Promise(function(resolve, reject) {
        if(_this.checkImageType(file) && _this.checkImageSize(file)) {
          resolve()
        } else {
          reject()
        }
      })
    },
    checkImageType(file){
      if(file.type === 'image/jpeg'
        || file.type === 'image/png'
        || file.type === 'image/jpg'
        || file.type === 'image/gif') {
        return true;
      }
      this.$message.error(this.$t(I18N_KEY + 'imageTypeNotice'))
      return false;
    },
    checkImageSize(file){
      if (file.size <= this.imageSizeLimit * 1024 * 1024) {
        return true;
      }
      this.$message.error(this.$t(I18N_KEY + 'imageSizeNotice_pre') + this.imageSizeLimit + this.$t(I18N_KEY + 'imageSizeNotice_suf'))
      return false;
    },
    handlePreview(file) {
      console.log(file)
      if (!file.url && !file.preview) {
        file.preview = file.thumbUrl
        // file.preview = file.response.data.attachmentUrl
      }
      this.previewImage = file.url || file.preview;
      // this.previewImage = file.url;
      this.previewVisible = true;
      this.previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
    },
    handleCancel(){
      this.previewVisible = false;
      this.previewTitle = '';
    },
    // 根据选中的操作类型，查询对应的下级审批节点对应的角色与用户列表，如果没有则没有，否则显示，如果只有一个，那么直接选中
    handleChange(value){
      this.showNextApprovelUserTrigger = false;
      if(value === '1' && this.workOrder['curNode'] == this.handleNode) {// 审批成功-显示决策, 排除其他审批节点，只在处理节点显示决策
        this.showDecisionTrigger = true;
      } else {
        this.showDecisionTrigger = false;
        this.showBaAmountTrigger = false;
        this.queryParam.decision = '';
        this.queryParam.baAmountStr = '';
      }

      var param = {
        "id": this.orderId,
        "operateType": value
      }
      this.loading = true
      nextApprovelData(param).then(res => {
        if (res.code === 1000) {
          this.showNextApprovelUserTrigger = res.data.hasRole || false;
          this.nextApproveRole = res.data.nextApproveRole || '';
          this.nextApproveUsers = res.data.nextApproveUsers || [];
          this.queryParam.approvelUser = res.data.hostoryUser || '';
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
      this.loading = false
    },
    handleChange1(value) {
      this.queryParam.baAmountStr = '';
      if(value === '3' || value === '7' || value === '8') {
        this.showBaAmountTrigger = true;
      } else {
        this.showBaAmountTrigger = false;
      }
    },
    checkNull(param) {
      if(param == undefined || param == null || param == '') {
        return false;
      }
      return true;
    },
    // 视频
    previewVideo(videoUrl) {
      this.videoPreviewVisible = true;
      this.previewVideoUrl = videoUrl;
    },
    videoPreviewCancel() {
      this.videoPreviewVisible = false;
      this.previewVideoUrl = '';
    },
    handleBackVisitChange(backVisit) {
      if(backVisit === '1') {
        this.showConnectTrigger = true
      } else {
        this.showConnectTrigger = false
        this.queryParam.connect = ''
      }
    },
    handleHandleStatusChange(handleStatus) {
      if(handleStatus === '1') {
        this.showSatisfactionTrigger = true
      } else {
        this.showSatisfactionTrigger = false
        this.queryParam.satisfaction = ''
      }
    },
    toFinance() {
      this.loading = true
      this.toFinanceDisabled = true
      var param = {'workOrderId':this.orderId, 'imgIndex': this.imgIndex}
      toFinance(param).then((res) => {
        if (res.code === 1000) {
          this.workOrder.toFinance = '1'
          this.$message.info("success")
        } else {
          this.toFinanceDisabled = false
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.toFinanceDisabled = false
        this.$message.error(e.message);
      })
    },
    changeSwitch(index) {
      if(this.checked[index]==true) {
        this.imgIndex.push(index)
      } else {
        var newIndex = [];
        for(var m in this.imgIndex) {
          if(this.imgIndex[m] == index) {
            continue;
          }
          newIndex.push(this.imgIndex[m])
        }
        this.imgIndex = newIndex;
      }
    },
    frontSourceShow(frontSource) {
      for(var i in this.frontSources) {
        if(this.frontSources[i].dictValue == frontSource) {
          return this.frontSources[i][this.fieldName]
        }
      }
      return frontSource;
    },
  },
  watch: {
    videoPreviewVisible: function(val) {
      if(!val) {
        const video = document.getElementById("video")
        video.pause()
      }
    },
    'queryParam.level': function(val) {
      switch (val) {
        case '1':this.levelStyle = "width: 100%; color: rgb(248, 213, 184); font-weight: bold;display: block;";break;
        case '2':this.levelStyle = "width: 100%; color: rgb(192, 66, 138); font-weight: bold;display: block;";break;
        case '3':this.levelStyle = "width: 100%; color: rgb(129, 228, 189); font-weight: bold;display: block;";break;
        case '4':this.levelStyle = "width: 100%; color: rgb(240, 86, 39); font-weight: bold;display: block;";break;
        case '5':this.levelStyle = "width: 100%; color: rgb(52, 49, 72); font-weight: bold;display: block;";break;
        default:this.levelStyle = "width: 100%;display: block;";break;
      }
    },
    'queryParam.handleStatus': function(val) {
      switch (val) {
        case '0': this.handleStatusStyle = "width: 100%; color: rgb(129, 228, 189); font-weight: bold;display: block;";break;
        case '1': this.handleStatusStyle = "width: 100%; color: rgb(241, 199, 0); font-weight: bold;display: block;";this.showSatisfactionTrigger=true;break;
        default: this.handleStatusStyle = "width: 100%;display: block;";break;
      }
    },
    'queryParam.satisfaction': function(val) {
      switch (val) {
        case '0': this.satisfactionStyle = "width: 100%; color: rgb(210, 207, 196); font-weight: bold;display: block;";break;
        case '1': this.satisfactionStyle = "width: 100%; color: rgb(129, 228, 189); font-weight: bold;display: block;";break;
        case '2': this.satisfactionStyle = "width: 100%; color: rgb(241, 199, 0); font-weight: bold;display: block;";break;
        default: this.satisfactionStyle = "width: 100%;display: block;";break;
      }
    },
    'queryParam.backVisit': function(val) {
      if('1' === val) {
        this.showConnectTrigger = true
      }
    },
    // toDetailViewCollectionSelectItem: function(val) {
    //   if(val) {
    //     this.showLevelTrigger = true
    //     this.showHandleStatusTrigger = true
    //   } else {
    //     this.showLevelTrigger = false
    //     this.showHandleStatusTrigger = false
    //   }
    // }
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'dictTextEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'dictTextCn';
        } else if (lang == 'es') {
          this.fieldName = 'dictTextEs';
        } else {
          this.fieldName = 'dictTextEn';
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          align: 'left',
          // width: 100,
          dataIndex: 'id'
        },
        {
          title: this.$t('order.orderId'),
          align: 'left',
          // width: 120,
          dataIndex: 'orderId'
        },
        {
          title: this.$t('order.currentPeriod'),
          align: 'left',
          // width: 100,
          dataIndex: 'orderPeriod'
        },
        {
          title: this.$t('order.startingTime'),
          align: 'left',
          // width: 150,
          dataIndex: 'startDate'
        },
        {
          title: this.$t('order.expireTime'),
          align: 'left',
          // width: 150,
          dataIndex: 'endDate'
        },
        {
          title: this.$t('order.overdueDays'),
          align: 'left',
          // width: 100,
          dataIndex: 'overdueDays'
        },
        {
          title: this.$t(I18N_KEY + 'settleStatus'),
          align: 'left',
          dataIndex: 'settleStatus',
          // width: 130,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: this.$t(I18N_KEY + 'curCapital'),
          align: 'left',
          // width: 100,
          dataIndex: 'curCapital'
        },
        {
          title: this.$t(I18N_KEY + 'curRepayAmount'),
          align: 'left',
          // width: 100,
          dataIndex: 'curRepayAmount'
        },
        {
          title: this.$t(I18N_KEY + 'curOrderUnRepayAmount'),
          align: 'left',
          // width: 100,
          dataIndex: 'curUnRepayAmount'
        },
      ]
    },
  },
}
</script>
<style lang="less" scoped>
.file-box {
  float: left;
  width: 200px;
  height: 200px;
  margin-right: 10px;
  margin: 0 8px 8px 0;

  .file-item {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    .file-preview {
      padding: 8px;
      width: 182px;
      height: 142px;
    }

    .file-label {
      margin: 0;
      text-align: center;
      border-bottom: 1px solid #d9d9d9;
      padding: 8px;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .ant-card-body {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>