<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="6" :sm="8">
            <a-form-item label="手机号">
              <a-input  v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>


          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        size="middle"
        rowKey="taskId"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :rowSelection="{ onChange: selectRowsChange, selectedRowKeys }"
        bordered>
      </a-table>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import CollectionDetail from './CollectionDetail';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex'
import {collectionUserAccountList} from "@/api/CollectionAccountInfo";

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.'
}

export default {
  name: 'AllApprovalTask',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      confirmLoading: false,
      visible: false,
      visibleT: false,
      orderId: undefined,
      custInfoId: undefined,
      batchVisible: false,
      batchLoading: false,
      selectedRowKeys: [],
      selectedList: [],
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        mobileNo:"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      selectUser: '',
      ExamineUser: [],
      taskId: ''
    }
  },
  computed: {
    ...mapGetters('platformCollection',['listUserWithNum']),
    batchList () {
      return this.listUserWithNum || [];
    },
    columns () {
      return [
        {
          title: "手机号",
          align: 'left',
          dataIndex: 'mobile'
        },
        {
          title:  "名字",
          align: 'left',
          dataIndex: 'names'
        },
        {
          title: '姓氏',
          align: 'left',
          dataIndex: 'surnames'
        },
        {
          title:'curp',
          align: 'left',
          dataIndex: 'curp'
        },
        {
          title: 'bankCardNo',
          align: 'left',
          dataIndex: 'bankCardNo'
        },
        {
          title: 'balAmt',
          align: 'left',
          dataIndex: 'balAmt',
        },
      ]
    },
  },
  created () {
  },
  methods: {
    ...mapActions('platformCollection', ['getListUserWithNum', 'batchChangeUser']),
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 清空选中项
      this.selectedList = [];
      this.selectedRowKeys = [];
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      collectionUserAccountList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    selectRowsChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.originSelectedList = selectedRows;
      this.selectedList = _.cloneDeep(selectedRows);
    },
    openBatchDialog () {
      this.batchVisible = true;
      this.batchLoading = true;
      this.selectedList.length && this.getListUserWithNum().finally(res => {
        this.batchLoading = false;
      })
    },
    closeBatchDialog () {
      this.batchVisible = false;
      this.selectedList = _.cloneDeep(this.originSelectedList || []);
    },
    submitBatch () {
      const taskIds = this.selectedList.map(t => t.taskId);
      const userTask = this.batchList.map(t => ({ userId: t.userId, taskNum: t.taskNum ? +t.taskNum : 0 }));
      const totalNum = userTask.map(t => t.taskNum).reduce((total, num ) => (total += num), 0);
      console.log(userTask, totalNum);
      if (!userTask.length) {
        return this.closeBatchDialog();
      }
      if (isNaN(totalNum)) {
        return this.$message.error(this.$t('please_enter_a_positive_integer'));
      }
      if (totalNum > this.selectedList.length) {
        return this.$message.error(this.$t('platformCollection.cannot_be_greater_than_the_total_quantity_of_the_checked_order'));
      }
      this.closeBatchDialog();
      this.loading = true;
      this.batchChangeUser({ taskIds, userTask }).then(res => {
        this.loadData();
        this.$message.success(this.$t('success'));
      }).finally(res => {
        this.loading = false;
      });
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          endDateStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
          endDateEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
       that.queryParam.aadhaarName = ''
       that.queryParam.mobileNo = ''
       that.queryParam.endTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.orderId;
        this.custInfoId = row.custInfoId;
        this.taskId = row.taskId;
        this.changeModal(true);
    },

    onChange(e) {
      this.selectUser = e.target.value
    },
      handleCancel(e) {
        this.visibleT = false;
      },
      searchBatch () {
        this.batchLoading = true;
        this.getListUserWithNum({userName : this.queryParam.inName, groupIdIn : this.queryParam.groupIdIn}).finally(res => {
          this.batchLoading = false;
        })
      }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
 .description {
  line-height: 1.4;
  &.d_text {
    text-indent: 1em;
  }
  &.d_high_light {
    color: #fd9a38;
  }
 }
</style>
