<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>

      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter='12'>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'手机号'">
              <a-input v-model='queryParam.mobileNo'></a-input>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <a-form-item :label="'产品类型'">
              <a-select
                v-model='queryParam.appSsid'
              >
                <a-select-option v-for='item in appList' :key='item.appssid'>
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :rowClassName='rowClassName'
        :pagination='false'
        :loading='loading'
        bordered>
      </a-table>

    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getApplist } from '@/webpublicapi/order'
import Dict from '../modules/Dict'
import DictSelect from '../modules/DictSelect'
import moment from 'moment'
import vModal from '../modules/Modal'
import { getRiskOperationInfo } from '@/webpublicapi/customer'

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
  OPERATION: 'operation.'
}

export default {
  name: 'RiskPointinfo',
  components: {
    Dict,
    DictSelect,
    vModal
  },
  data() {
    return {
      visdialog: false,
      I18N_KEY,
      toggleSearchStatus: false,
      visible: false,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      appList: [],
      // 查询条件
      queryParam: {
        'appSsid': '',
        'mobileNo': ''
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '事件名称',
          align: 'left',
          dataIndex: 'optType'
        },
        {
          title: 'gaid',
          align: 'left',
          dataIndex: 'gaid'
        },
        {
          title: '经纬度',
          align: 'left',
          dataIndex: 'lbs'
        },
        {
          title: '页面',
          align: 'left',
          dataIndex: 'extVarchar1'
        },
        {
          title: '创建时间',
          align: 'left',
          dataIndex: 'createTime'
        }
      ]
    }
  },
  created() {
    getApplist().then(res => {
      this.appList = res.data || []
      if (this.appList != null) {
        this.appList.forEach(item => {
          if (item.appName == '全部') {
            item.appName = '请选择'
          }
        })
      }
    })
    this.getDict()
  },
  methods: {
    searchQuery() {
      this.loadData()
    },
    loadData() {
      this.loading = true
      var mobileNo = this.queryParam.mobileNo
      var appSsid = this.queryParam.appSsid
      this.dataSource = []
      getRiskOperationInfo(mobileNo, appSsid).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data || []
          if (this.dataSource != null) {
            // 1、个人信息，2、工作信息，3、联系人信息，4、身份信息，5、收款信息
            this.dataSource.forEach(item => {
              if (item.extVarchar1 == 0) {
                item.extVarchar1 = '无'
              } else if (item.extVarchar1 == 1) {
                item.extVarchar1 = '个人信息'
              } else if (item.extVarchar1 == 2) {
                item.extVarchar1 = '工作信息'
              } else if (item.extVarchar1 == 3) {
                item.extVarchar1 = '联系人信息'
              } else if (item.extVarchar1 == 4) {
                item.extVarchar1 = '身份信息'
              } else if (item.extVarchar1 == 5) {
                item.extVarchar1 = '收款信息'
              }
            })
          }
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
      this.loading = false
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformTimeSecond(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },

    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.appSsid = '请选择'
      that.queryParam.mobileNo = undefined
      that.dataSource = []
    },

    rowClassName(record, index) {
      var date = new Date(record.curDate)
      var day = date.getTime() / 24 / 60 / 60 / 1000 + 3

      if ((day % 7) + 1 == 7) {
        return 'table_weekendColor'
      }
      return ''
    }

  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
