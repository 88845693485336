<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
         
          <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="downLoadData('channel')" icon="download" style="margin-left: 8px">全部</a-button>
              <a-button type="primary" @click="downLoadData('branch_campaign')" icon="download" style="margin-left: 8px">{{ $t(I18N_KEY.CUSTOMER + 'campaignDataExcel') }}</a-button>
              <a-button type="primary" @click="downLoadData('branch_channel')" icon="download" style="margin-left: 8px">{{ $t(I18N_KEY.CUSTOMER + 'channelDataExcel') }}</a-button>
              <a-button type="primary" @click="downLoadData('branch_publisher')" icon="download" style="margin-left: 8px">{{ $t(I18N_KEY.CUSTOMER + 'publisherDataExcel') }}</a-button>
              <a-button type="primary" @click="downLoadData('branch_set_name')" icon="download" style="margin-left: 8px">{{ $t(I18N_KEY.CUSTOMER + 'setNameDataExcel') }}</a-button>
              <a-button type="primary" @click="downLoadData('branch_ad_name')" icon="download" style="margin-left: 8px">{{ $t(I18N_KEY.CUSTOMER + 'adnameDataExcel') }}</a-button>
              <a-button type="primary" @click="downLoadData('branch_dollar_3p')" icon="download" style="margin-left: 8px">{{ $t(I18N_KEY.CUSTOMER + 'dollar3pDataExcel') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getRepaymentReport } from '@/api/order'
import { downRepaymentReportExcel } from '@/api/order'
import exportFile from '@/api/exportFile'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
  REPAYREPORT: 'repayReport.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "examinerName":"",
        "examineDate":undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'curDate'),
          align: 'left',
          dataIndex: 'curDate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayCurCount'),
          align: 'left',
          dataIndex: 'repayCurCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'preCount'),
          align: 'left',
          dataIndex: 'preCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayNormalCount'),
          align: 'left',
          dataIndex: 'repayNormalCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'extendCount'),
          align: 'left',
          dataIndex: 'extendCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayOverCount'),
          align: 'left',
          dataIndex: 'repayOverCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'overExtendCount'),
          align: 'left',
          dataIndex: 'overExtendCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'overCount'),
          align: 'left',
          dataIndex: 'overCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'firstOverDueRate'),
          align: 'left',
          dataIndex: 'firstOverDueRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'aoverDueRate'),
          align: 'left',
          dataIndex: 'aoverDueRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'perRepayRate'),
          align: 'left',
          dataIndex: 'perRepayRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayNormalRate'),
          align: 'left',
          dataIndex: 'repayNormalRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'extendCountRate'),
          align: 'left',
          dataIndex: 'extendCountRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayOverCountRate'),
          align: 'left',
          dataIndex: 'repayOverCountRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'overExtendCountRate'),
          align: 'left',
          dataIndex: 'overExtendCountRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'colbackRate'),
          align: 'left',
          dataIndex: 'colbackRate'
        }
      ]
    }
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.form.pageNum = 1
      // }
      // this.loading = true
      // var params = this.getQueryParams()// 查询条件
      // this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value);
      // getRepaymentReport(Object.assign({}, params)).then((res)=>{
      //   if(res.code===1000){
      //     // this.total = res.data && res.data.total
      //     this.dataSource = res.data || [];
      //   }else{
      //     this.total = 0
      //     this.dataSource = []
      //     this.$message.error(res.msg)
      //   }
      //   this.loading = false
      // }).catch(e => {
      //       this.loading = false;
      //       this.$message.error(e.message);
      // })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    downLoadData (transformType) {
      const file = {
        fileUrl: '/api/web/statistics/transformingDataExcel?branchType=' + transformType,
        fileName: 'transformingDataExcel.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
          this.$message.error(e.message);
      }).finally(() => {
          this.loading = false;
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
       return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { examineDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          startDate: examineDate && this.transformTime(examineDate[0]),
          endDate: examineDate && this.transformTime(examineDate[1]),
      }
      delete data.examineDate;
      return filterObj(data)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
