<template>
  <a-modal
    :title="$t(I18N_KEY+'approve')"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'operateType')">
          <a-radio-group
            v-decorator="['operateType', validatorRules.operateType, { initialValue: '' }]">
            <span v-for="item in this.operateTypes">
              <a-radio :value=item>{{ $t(I18N_KEY + 'operateType_' + item) }}</a-radio>
            </span>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'remark')">
          <a-textarea col='3' :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'remark')" v-decorator="[ 'remark', validatorRules.remark]" />
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
const I18N_KEY = 'custServiceWorkOrder.';
export default {
  name: "HandleModal",
  props: {
    // operateTypes:{
    //   type: Array,
    //   default: () => [],
    // },
  },
  data () {
    return {
      I18N_KEY,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      workOrderId: null,
      operateTypes: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules:{
        operateType:{
          rules: [
            { required: true, message: 'Please select Operate Type!' },
          ]},
        remark:{
          rules: [
          ]}
      },
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({});
    },
    edit (record) {
      this.form.resetFields();
      this.open();
      this.model = Object.assign({}, record)
      this.model = {
        ...this.model,
        operateType: this.model.operateType != undefined ? '' + this.model.operateType : '',
      }

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'operateType','remark'))
      });

    },
    open () {
      this.visible = true;
    },
    clear(){
      this.form.resetFields();
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      // console.log(88888)
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            ...values,
            id: this.workOrderId,
          }
          this.$emit('ok', formData);

        }
      })
    },
    handleCancel () {
      this.close()
    },
  }
}
</script>

<style scoped>

</style>