<template>
  <a-modal
    :title="title === '1' ? $t('add') : $t('edit')"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'name')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'name')" v-decorator="[ 'key', validatorRules.key]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'smsTemplate')">
          <a-textarea rows="5" :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'smsTemplate')" v-decorator="[ 'smsTemplate', validatorRules.smsTemplate]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'urlType')">
          <a-select v-decorator="[ 'urlType', validatorRules.urlType]" :placeholder="$t('select') + ' ' + $t(I18N_KEY + 'urlType')" @change='changeUrlType'>
            <a-select-option v-for='item in urlTypes' :key='item.value'>
              {{item[fieldName]}}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'url')" v-if='showUrlTextarea'>
          <a-textarea rows="3" :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'url')" v-decorator="[ 'url', validatorRules.url]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'sort')">
          <a-input-number :min="0" :precision="0" v-decorator="[ 'sort', validatorRules.sort]" />
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
const I18N_KEY = 'teleMarket.';
export default {
  name: "TeleMarketSmsTemplateEdit",
  props: {
    urlTypes: {
      type: Array,
      default: () => [],
    },
    fieldName:{
      type: String,
      default: 'textCn',
    }
  },
  data () {
    return {
      I18N_KEY,
      title:"操作",
      visible: false,
      showUrlTextarea: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules:{
        key:{
          rules: [
            { required: true, message: 'Please input key!' },
          ]},
        urlType:{
          rules: [
            { required: true, message: 'Please select url type!'  },
          ]},
        url:{
          rules: [
            { required: true, message: 'Please input url!'  },
          ]},
        sort:{
          rules: [
            { required: true, message: 'Please input sort!'  },
          ]},
        smsTemplate:{
          rules: [
            { required: true, message: 'Please input sms template!'  },
          ]},
      },
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({});
    },
    edit (record) {
      this.form.resetFields();
      this.open();
      this.model = Object.assign({}, record)
      this.model = {
        ...this.model,
      }
      if(this.model.urlType == '2') {
        this.showUrlTextarea = true
      } else {
        this.showUrlTextarea = false
      }

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'key','urlType','url', 'smsTemplate', 'sort'))
      });

    },
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      // console.log(88888)
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            ...values,
          }
          this.$emit('ok', formData);

        }
      })
    },
    handleCancel () {
      this.close()
    },
    changeUrlType(urlType) {
      if(urlType == '2') {
        this.showUrlTextarea = true
      } else {
        this.showUrlTextarea = false
      }
    },
  },
}
</script>

<style scoped>

</style>