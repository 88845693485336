<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter='24'>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'手机号'">
              <a-input
                :placeholder="$t('enter') + ' 手机号' "
                v-model='queryParam.mobileNo' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'盘号'">
              <a-input
                :placeholder="$t('enter') + ' 盘号' "
                v-model='queryParam.appSsid' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'key'">
              <a-input
                :placeholder="$t('enter') + ' key' "
                v-model='queryParam.key' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        bordered>
        <span slot="action" slot-scope="text, record">
          <a-popconfirm title="确定删除吗?" @confirm="() =>handleDelete(record)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getDict, deleteDict, clearDictCache, findRedisByKey, delRedis } from '@/webpublicapi/system'
import DictModal from './modules/DictModal'
import DictItemList from './DictItemList'
export default {
  name: 'getRedis',
  components: { DictModal, DictItemList },
  data () {
    return {
      visible: false,
      dataSource: [],
      // 查询条件
      queryParam: {
        'mobileNo': undefined,
        'appSsid': undefined,
        'key': undefined
      },
      loading: false,
      // 表头
      columns: [
        {
          title: '键',
          align: 'left',
          dataIndex: 'left'
        },
        {
          title: '值',
          align: 'left',
          dataIndex: 'right'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    // this.loadData()
  },
  methods: {
    searchQuery () {
      this.loadData()
    },
    loadData () {
      this.loading = true
      var appSsid = this.queryParam.appSsid
      var mobileNo = this.queryParam.mobileNo
      var key = this.queryParam.key
      if ((key == undefined || key == '') && appSsid != undefined && mobileNo != undefined) {
        key = 'c:user:token:key:' + appSsid + ':' + mobileNo
      }
      if (key != undefined) {
        findRedisByKey(key).then(res => {
          this.dataSource = []
          if (res.code === 1000) {
            var obj = {
              left: key,
              right: res.msg
            }
            this.dataSource.push(obj)
          } else {
            this.dataSource = []
            this.$message.error(res.msg)
          }
        }).catch(e => {
          this.$message.error(e.message)
        })
      }
      this.loading = false
    },
    getQueryParams () {
      var param = Object.assign({}, this.queryParam)
      return filterObj(param)
    },
    handleDelete(record){
      console.log(record)
      delRedis(record.left).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.searchReset()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.appSsid = undefined
      that.queryParam.mobileNo = undefined
      that.queryParam.key = undefined
      this.dataSource = []
    },
    // model回调
    modalFormOk () {
      this.loadData()
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
