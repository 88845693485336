<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
              <a-row :gutter="24">
                <a-col :md="6" :sm="8">
                  <a-form-item :label="$t(I18N_KEY + 'orderNo')">
                    <a-input
                        :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderNo'))"
                        v-model="queryParam.orderNo"/>
                  </a-form-item>
                </a-col>

                <a-col :md="6" :sm="8">
                  <a-form-item :label="$t('phoneNo')">
                    <a-input
                      :placeholder="$t('enter') + ' ' + transformStringToLow($t('phoneNo'))"
                      v-model="queryParam.mobileNo"></a-input>
                  </a-form-item>
                </a-col>
              <template v-if="toggleSearchStatus">
                <a-col :md="6" :sm="8">
                  <a-form-item :label="$t('customer.curp')">
                     <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t('customer.curp'))" v-model="queryParam.curp"></a-input>
                  </a-form-item>
                </a-col>
               </template>
                <a-col :md="6" :sm="8">
                  <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                    <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
                    <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
                     <a @click="handleToggleSearch" style="margin-left: 8px">
                          {{ $t(toggleSearchStatus ? 'hide' : 'show') }}
                          <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
                        </a>
                  </span>
                </a-col>
              </a-row>
            </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>
        <span slot="action" slot-scope="text, record">
            <a @click="handleDetail(record)">{{ $t('view') }}</a>
            <a @click="handleLoan(record)">
              {{ $t(I18N_KEY + 'lending') }}
            </a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
      <pending-modal ref="modalForm"></pending-modal>
    </div>
    <v-modal
        v-model="visible"
        :title="$t(I18N_KEY + 'orderDetail')">
        <div style="padding: 20px;">
            <order-detail v-if="visible" :orderId="orderId" :custInfoId="custInfoId"/>
        </div>
    </v-modal>
  </div>
</template>    

<script>
import { filterObj } from '@/utils/util'
import { getPlatformPendingOrderlist, allowLending } from '@/api/order'
import Dict from '../modules/Dict';
import PendingModal from '../order/modules/PendingModal'
import vModal from '../modules/Modal';
import OrderDetail from '../order/OrderDetail';

const I18N_KEY = 'order.';

export default {
  name: 'PendingOrderlist',
  components: {
    vModal,
    OrderDetail,
    Dict,
    PendingModal
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "orderNo":"",
        "mobileNo":"",
        "aadhaarName":"",
        "applyAmtMin":"",
        "applyAmtMax":""
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'orderNo'),
          align: 'left',
          dataIndex: 'orderNo'
        },
        {
          title: this.$t('phoneNo'),
          align: 'left',
          dataIndex: 'mobileNo'
        },
        {
          title: this.$t('customer.names'),
          align: 'left',
          dataIndex: 'names'
        },
        {
          title: this.$t('customer.surnames'),
          align: 'left',
          dataIndex: 'surnames'
        },
        {
          title: this.$t('customer.curp'),
          align: 'left',
          dataIndex: 'curp'
        },
        {
          title: this.$t(I18N_KEY + 'applicationAmount'),
          align: 'left',
          dataIndex: 'applyAmt'
        },
        {
          title: this.$t(I18N_KEY + 'deadline'),
          align: 'left',
          dataIndex: 'duration'
        },
        {
          title: this.$t(I18N_KEY + 'applicationTime'),
          align: 'left',
          dataIndex: 'createdTime'
        },
        {
          title: this.$t(I18N_KEY + 'loanStatus'),
          align: 'left',
          dataIndex: 'loanStatus',
          scopedSlots: { customRender: 'loanStatus' }
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleLoan (record) {
      const self = this;

      var name = record.firstName + ' ' + record.middleName + ' ' + record.lastName;

      this.$confirm({
          title: self.$t('order.isLoan'),
          content: h => {
            return <div>
              <div>
                { self.$t('customer.custName') }: {name}
              </div>
              <div>
                { self.$t('order.applicationAmount') }：{ record.applyAmt }
              </div>
              <div>
                { self.$t('order.deadline') }：{ record.duration }
              </div>
            </div>
          },
          onOk() {
            self.loading = true;
            allowLending({ orderId: record.id }).then(res => {
              self.loadData();
            }).catch(e => {
              self.loading = false;
              self.$message.error(e.message);
            })
          },
      });
      // this.$refs.modalForm.title ="放款"
      // this.$refs.modalForm.edit(record)
    },
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getPlatformPendingOrderlist(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    getQueryParams () {
      var param = Object.assign({}, this.queryParam, this.isorter)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
       that.queryParam.aadhaarName = ''
      that.queryParam.applyAmtMin = ''
       that.queryParam.applyAmtMax = ''
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.id;
        this.custInfoId = row.custInfoId;
        this.changeModal(true);
    },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
