import { axios } from '@/utils/request'

export function payChannelInfoList(parameter = {}) {
  return axios({
    url: '/paychannel/list',
    method: 'post',
    data: parameter
  })
}

export function payChannelInfoOperate(parameter = {}) {
  return axios({
    url: '/paychannel/opt',
    method: 'post',
    data: parameter
  })
}

export function payChannelInfoDelete(id) {
  return axios({
    url: '/paychannel/delete/'+id,
    method: 'get'
  })
}