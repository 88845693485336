<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item :label="$t(I18N_KEY.REPAYREPORT + 'requestDate')" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
               <a-range-picker
                  v-model="queryParam.examineDate"
                  format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery('')" icon="search" style="margin-left: 8px">全部</a-button>
              <a-button type="primary" @click="searchQuery('branch_campaign')" icon="search" style="margin-left: 8px">campaign</a-button>
              <a-button type="primary" @click="searchQuery('branch_channel')" icon="search" style="margin-left: 8px">channel</a-button>
              <a-button type="primary" @click="searchQuery('branch_publisher')" icon="search" style="margin-left: 8px">publisher</a-button>
              <a-button type="primary" @click="searchQuery('branch_set_name')" icon="search" style="margin-left: 8px">set_name</a-button>
              <a-button type="primary" @click="searchQuery('branch_ad_name')" icon="search" style="margin-left: 8px">ad_name</a-button>
              <a-button type="primary" @click="searchQuery('branch_dollar_3p')" icon="search" style="margin-left: 8px">dollar_3p</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 3500, y: 500 }"
        bordered
        @change="handleTableChange">
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS">{{ text }}</Dict>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getTransformingDataList } from '@/webpublicapi/order'
import { downRepaymentReportExcel } from '@/webpublicapi/order'
import exportFile from '@/webpublicapi/exportFile'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
  REPAYREPORT: 'repayReport.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "examinerName":"",
        "examineDate":undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY.ORDER + 'branchType'),
          align: 'left',
          dataIndex: 'branchType'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'curdate'),
          align: 'left',
          dataIndex: 'date'
        },
        // {
        //   title: this.$t(I18N_KEY.ORDER + 'caseLoanName'),
        //   align: 'left',
        //   dataIndex: 'caseLoanName'
        // },
        {
          title: this.$t(I18N_KEY.ORDER + 'regCount'),
          align: 'left',
          dataIndex: 'regCount'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'cardCustCount'),
          align: 'left',
          dataIndex: 'cardCustCount'
        },
        {
          title: '实名转化率',
          align: 'left',
          dataIndex: 'verityRate'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'faceCount'),
          align: 'left',
          dataIndex: 'faceCount'
        },
        {
          title: '人脸识别转化率',
          align: 'left',
          dataIndex: 'faceRate'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'baseCount'),
          align: 'left',
          dataIndex: 'baseCount'
        },
        {
          title: '基本信息转化率',
          align: 'left',
          dataIndex: 'baseRate'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'linkManCount'),
          align: 'left',
          dataIndex: 'linkManCount'
        },
        {
          title: '联系人转化率',
          align: 'left',
          dataIndex: 'linkManRate'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'bankCardCount'),
          align: 'left',
          dataIndex: 'bankCardCount'
        },
        {
          title: '银行卡转化率',
          align: 'left',
          dataIndex: 'bankCardRate'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'applyCount'),
          align: 'left',
          dataIndex: 'applyCount'
        },
        {
          title: '注册申请转化率',
          align: 'left',
          dataIndex: 'applyRate'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'approveCount'),
          align: 'left',
          dataIndex: 'approveCount'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'loanCount'),
          align: 'left',
          dataIndex: 'loanCount'
        },
        {
          title: '放款转化率',
          align: 'left',
          dataIndex: 'loanRate'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'overdueOrder'),
          align: 'left',
          dataIndex: 'overdueOrder'
        },
        {
          title: '首逾',
          align: 'left',
          dataIndex: 'overdueRate'
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'overdueSettleOrder'),
          align: 'left',
          dataIndex: 'overdueSettleOrder'
        },
        {
          title: '逾期结清率',
          align: 'left',
          dataIndex: 'overdueSettleRate'
        }
      ]
    }
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery (arg) {

      this.loadData(1,arg)
    },
    loadData (arg,arg2) {
      // 加载数据 若传入参数1则加载第一页的内容
      console.log(arg2)
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams(arg2)// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value);
      getTransformingDataList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data && res.data.total
          this.dataSource = res.data || [];

        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    downLoadData () {
      const file = {
        fileUrl: '/api/web/statistics/repaymentReportExcel?' + this.exportParams,
        fileName: 'report.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
          this.$message.error(e.message);
      }).finally(() => {
          this.loading = false;
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
       return str && str.toLocaleLowerCase();
    },
    getQueryParams (arg) {
      const { examineDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          startDate: examineDate && this.transformTime(examineDate[0]),
          endDate: examineDate && this.transformTime(examineDate[1]),
        branchType : arg,
      }
      delete data.examineDate;
      return filterObj(data)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
