<template>
  <a-modal
    :title="title === '1' ? $t('add') : $t('edit')"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="name">
          <a-input :placeholder="$t('enter') + ' name'" v-decorator="[ 'name', validatorRules.name]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="前端页面">
          <a-select v-decorator="[ 'pageId', validatorRules.pageId]" :placeholder="$t('select') + ' 前端页面'">
            <a-select-option v-for='item in pages' :key='item.value'>
              {{item.value +  '-'+item[fieldName]}}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="用户类型">
          <a-select v-decorator="[ 'userType', validatorRules.userType]" :placeholder="$t('select') + ' 用户类型'">
            <a-select-option v-for='item in userTypes' :key='item.value'>
              {{item[fieldName]}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="模板内容">
          <a-textarea rows="8" :placeholder="$t('enter') + ' 模板内容'" v-decorator="[ 'template', validatorRules.template]" />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="状态">
          <a-radio-group
            v-decorator="['status', { initialValue: '1' }]">
            <span v-for="item in this.statuses">
              <a-radio :value=item.value>{{item[fieldName]}}</a-radio>
            </span>
          </a-radio-group>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
export default {
  name: "QuestionAnswerEdit",
  props: {
    userTypes: {
      type: Array,
      default: () => [],
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    pages: {
      type: Array,
      default: () => [],
    },
    fieldName:{
      type: String,
      default: 'textCn',
    }
  },
  data () {
    return {
      title:"操作",
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules:{
        name:{
          rules: [
            { required: true, message: 'Please input name!' },
          ]},
        pageId:{
          rules: [
            { required: true, message: 'Please select page Id!' },
          ]},
        userType:{
          rules: [
            { required: true, message: 'Please select user Type!'},
          ]},
        status:{
          rules: [
            { required: true, message: "Please select status!"},
          ]},
        template:{
          rules: [
            { required: true, message: "Please input template content!"},
          ]},
      },
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({});
    },
    edit (record) {
      this.form.resetFields();
      this.open();
      this.model = Object.assign({}, record)
      this.model = {
        ...this.model,
        status: this.model.status != undefined ? '' + this.model.status : '1',
      }

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'name', 'pageId', 'userType', 'status','template'))
      });

    },
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      console.log(88888)
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            ...values,
          }
          this.$emit('ok', formData);

        }
      })
    },
    handleCancel () {
      this.close()
    },
  }
}
</script>

<style scoped>

</style>