<template>
  <div :bordered="false">
  <a-modal
    :title="title"
    :width="1200"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="close"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-table
          ref="table"
          rowKey="id"
          size="middle"
          :columns="columns"
          :dataSource="repayPlan"
          :scroll="{ x: 2300, y: 2000 }"
          :pagination="false"
          :loading="loading"
          bordered>

<!--          <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>-->
<!--          <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>-->
<!--          <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.INSTALLMENT_SETTLE_STATUS" :value="text"/>-->
<!--          <Dict slot="overdueStatus" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>-->
              <span slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" @click="decLateFee(record)" style="margin-left: 5px">  {{$t('decLateFee')}}</a-button>
<!--          <a @click="handleLoan(record.id)" v-if='record.approvalStatus == 4'>-->
<!--            放款-->
<!--          </a>-->
<!--          <a @click="handleOrderEdit(record)" v-if='record.approvalStatus != 4'>-->
<!--            订单编辑-->
<!--          </a>-->
<!--          <a @click="handleEdit(record)" v-if='record.approvalStatus == 4'>-->
<!--            编辑还款计划-->
<!--          </a>-->
              </span>

        </a-table>

      </a-form>
    </a-spin>
  </a-modal>
  <!-- 减免滞纳金 -->
  <a-modal v-model:visible="visibleAmount" :title="$t('repaycert.enterAmount')">
    <a-row>
      <a-col :md="10" :sm="8">
        <a-input type="number" v-model="deduceAmt" />
      </a-col>
    </a-row>
    <template #footer>
      <a-button key="back" @click="handleCancelAmount">{{$t('cancel')}}</a-button>
      <a-button key="submit" type="primary" :loading="loadingAmt" @click="submitAmount()">{{$t('confirm')}}</a-button>
    </template>
  </a-modal>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { editInstallmentOrderStatusForTest,deduceInstallmentLateAmount } from '@/webpublicapi/order'
import DictSelect from '../../modules/DictSelect';
import Dict from '@/views/webpublic/modules/Dict.vue'


// duplicateCheck
export default {
  name: 'TestInstallOrderListModal',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      value: 1,
      title: '操作',
      visible: false,
      loading: false,
      visibleAmount: false,
      currRecord: {},
      deduceAmt: '',
      loadingAmt: false,
      columns: [
        {
          title: this.$t('order.totalPeriod'),
          align: 'left',
          dataIndex: 'period',
        },
        {
          title: this.$t('order.status'),
          align: 'left',
          dataIndex: 'status'
        },
        {
          title: this.$t('order.overdueDays'),
          align: 'left',
          dataIndex: 'overdueDays'
        },
        {
          title: this.$t('order.startingTime'),
          align: 'left',
          dataIndex: 'beginDate',
        },
        {
          title: this.$t('order.expireTime'),
          align: 'left',
          dataIndex: 'endDate'
        },
        {
          title: this.$t('order.capital'),
          align: 'left',
          dataIndex: 'capital'
        },
        {
          title: this.$t('order.settledCapital'),
          align: 'left',
          dataIndex: 'settledCapital'
        },
        {
          title: this.$t('order.interest'),
          align: 'left',
          dataIndex: 'interest'
        },
        {
          title: this.$t('order.settledInterest'),
          align: 'left',
          dataIndex: 'settledInterest'
        },
        {
          title: this.$t('order.serviceFee'),
          align: 'left',
          dataIndex: 'serviceFee'
        },
        {
          title: this.$t('order.settledServiceFee'),
          align: 'left',
          dataIndex: 'settledServiceFee'
        },
        {
          title: this.$t('order.lateFee'),
          align: 'left',
          dataIndex: 'lateFee'
        },
        {
          title: this.$t('order.settledLateFee'),
          align: 'left',
          dataIndex: 'settledLateFee'
        },
        {
          title: this.$t('order.otherFee'),
          align: 'left',
          dataIndex: 'otherFee'
        },
        {
          title: this.$t('order.settledOtherFee'),
          align: 'left',
          dataIndex: 'settledOtherFee'
        },
        {
          title: this.$t('order.handleFee'),
          align: 'left',
          dataIndex: 'chargeFee'
        },
        {
          title: this.$t('order.settledHandleFee'),
          align: 'left',
          dataIndex: 'settledChargeFee'
        },
        {
          title: this.$t('order.deductionFee'),
          align: 'left',
          dataIndex: 'deductionFee'
        },
        {
          title: this.$t('custServiceWorkOrder.operate'),
          dataIndex: 'action',
          width: 250,
          align: 'left',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataSource:[],
      repayPlan: [],
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
    }
  },
  created () {
  },
  methods: {
    checkBoxChange(checkedValue) {
      console.log(checkedValue)
    },
    add () {
      this.edit({})
    },
    decLateFee(record){
      this.visibleAmount = true
      this.currRecord = record
    },
    submitAmount(){
      if (!this.deduceAmt) {
        this.$message.error('amount is empty')
        return
      }
      this.loadingAmt = true
      let data = {
        planId: this.currRecord.id,
        deduceAmt: this.deduceAmt,
      }
      deduceInstallmentLateAmount(data).then((res) => {
        this.loadingAmt = false
        this.visibleAmount = false
        this.deduceAmt = ''
        if (res.code == 1000) {
          this.$message.success('Success')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleCancelAmount(){
      this.visibleAmount = false
      this.currRecord = {}
      this.deduceAmt = ''
    },
    edit (record) {
      if (record.id) {
        this.visiblekey = true
      } else {
        this.visiblekey = false
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.repayPlan = record.repayPlans;
        this.model.approvalStatus=String(this.model.approvalStatus)
        this.model.loanStatus=String(this.model.loanStatus)
        // this.model.overdueStatus=String(this.model.overdueStatus)
        this.model.settleStatus=String(this.model.settleStatus)
        this.form.setFieldsValue(pick(this.model,  'approvalStatus', 'loanStatus','settleStatus','applyAmt','interest', 'deductionFee', 'orderPeriod'))
      })
    },
    // 确定
    handleOk () {
      this.close()
      // const that = this
      // // 触发表单验证
      // this.form.validateFields((err, values) => {
      //   if (!err) {
      //     that.confirmLoading = true
      //     const formData = Object.assign(this.model, values)
      //     let obj
      //     console.log(formData)
      //     obj = editInstallmentOrderStatusForTest(formData)
      //     obj.then((res) => {
      //       if (res.code === 1000) {
      //         this.$message.success(res.msg)
      //         this.$emit('ok')
      //       } else {
      //         this.$message.warning(res.msg)
      //       }
      //     }).finally(() => {
      //       this.confirmLoading = false
      //       this.close()
      //     })
      //   }
      // })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
