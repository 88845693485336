<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.name_of_collector')">
              <a-input :placeholder="$t('enter') + ' ' + $t('platformCollection.name_of_collector')" v-model="queryParam.userName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'createTime')">
              <a-range-picker
                v-model="queryParam.endTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="10">
            <a-form-item :label="$t('platformCollection.collection_time')">
              <a-range-picker
                v-model="queryParam.settleTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.grouping')">
              <a-select
                v-model="queryParam.groupId"
              >
                <a-select-option v-for="item in groupList" :key="item.id">
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.collection_stage')">
              <a-select default-value="" style="width: 190px" v-model="queryParam.normalConfigId">
                <a-select-option value="5">
                  t0
                </a-select-option>
                <a-select-option value="6">
                  s1
                </a-select-option>
                <a-select-option value="8">
                  s2
                </a-select-option>
                <a-select-option value="9">
                  s3
                </a-select-option>
                <a-select-option value="10">
                  s4
                </a-select-option>
                <a-select-option value="11">
                  m1
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="search" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>


    </div>
    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {queryBackPerformanceForAmount} from '@/api/collection'
import { getSysGroup } from '@/api/platformCollection'
import Dict from '../../modules/Dict';
import DictSelect from '../../modules/DictSelect';
import moment from 'moment'
import vModal from '../../modules/Modal';
import exportFile from '@/api/exportFile';
import CollectionDetail from '../CollectionDetail';
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'AllApprovalTask',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      groupList: [],
      departmentList: [],
      groupOptions: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake',
                },
              ],
            },
          ],
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          children: [
            {
              value: 'nanjing',
              label: 'Nanjing',
              children: [
                {
                  value: 'zhonghuamen',
                  label: 'Zhong Hua Men',
                },
              ],
            },
          ],
        },
      ],
      // 查询条件
      queryParam: {
        "endTime":undefined,
        "settleTime":undefined,
        "userName":"",
        "normalConfigId":"",
        "groupId":"",
        "departmentId":""
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title:  this.$t('collection.userName'),
          align: 'left',
          dataIndex: 'userName'
        },
        {
          title:  this.$t('collection.userId'),
          align: 'left',
          dataIndex: 'userId'
        },
        {
          title:  this.$t('collection.groupName'),
          align: 'left',
          dataIndex: 'groupName'
        },
        {
          title: this.$t('platformCollection.recovery_amount'),
          align: 'left',
          dataIndex: 'collectAmount'
        }
      ]
    },
  },
  created () {
    this.getDict();
    // this.loadData();
    getSysGroup({"groupId": "58"}).then(res => {
      this.groupList = res.data || [];
      this.queryParam.groupId = "";
    });
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      var groupId = this.queryParam.groupId;
      if(groupId == '' || groupId == 'undefined'){
        this.$message.error(this.$t('platformCollection.please_select_a_group'));
        return;
      }

      this.loadData()
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.form.pageNum = 1
      // }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      queryBackPerformanceForAmount(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data.total
          this.dataSource = res.data || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const { settleTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          settleDateStart: (settleTime && settleTime.length) ? this.transformTime(settleTime[0]) : undefined,
          settleDateEnd : (settleTime && settleTime.length) ? this.transformTime(settleTime[1]) : undefined,
          endDateStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
          endDateEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.settleTime;
      delete data.endTime;
      var param = Object.assign({}, data)
      // param.pageNum = this.form.pageNum;
      // param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.endTime = undefined;
      that.queryParam.settleTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    downLoadData () {
      var params = this.getQueryParams('')// 查询条件
      const file = {
        fileUrl: '/api/web/collectionTask/backPerformanceExcel?settleDateStart='
        + params.settleDateStart + '&settleDateEnd=' + params.settleDateEnd
        + '&endDateStart=' + params.endDateStart + '&endDateEnd=' + params.endDateEnd + '&userName='
        + params.userName + '&groupId=' + params.groupId+  '&normalConfigId=' + params.normalConfigId + '&departmentId=' + params.departmentId,
        fileName: 'backPerformanceExcel.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    onChange(value) {
      console.log(value);
      getSysGroup({"departmentId": value}).then(res => {
        this.groupList = res.data || [];
        this.queryParam.groupId = "";
      });
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
