<template>
    <div class="wrap">
        <div class="panel-header">
            <span>{{ title }}</span>
            <a-icon type="plus" class="plus" @click="openModal"/>
        </div>
        <a-modal title="催收用户列表" v-model="visible" @ok="handleOk" @cancel="closeModal">
            <a-table
                ref="table"
                rowKey="id"
                size="middle"
                :columns="columns"
                :dataSource="userList"
                :pagination="false"
                :rowSelection="{ onChange: selectRowsChange, selectedRowKeys }"
                bordered>
            </a-table>
        </a-modal>
        <div class="panel-body">
            <div v-if="loading" style="text-align: center;">
                <a-spin style="margin: 20px;" />
            </div>
            <transition
                v-else
                v-for="item in value"
                :key="item.userId"
                name="el-fade-in">
                <li class="section">
                    <p>{{ item.userName }}</p>
                    <a-popconfirm title="是否删除" okText="是" cancelText="否" @confirm="deleteUser(item)">
                        <div style="position: absolute; top: 50%; right: 0; transform: translateY(-50%); width: 25px;">
                            <a-icon type="close" class="delete-icon"/>
                        </div>
                    </a-popconfirm>
                </li>
            </transition>
        </div>
    </div>
</template>
<script>
     export default {
        props: {
            title: {
                type: String,
                default: undefined,
            },
            value: {
                type: Array,
                default: () => [],
            },
            userList: {
                type: Array,
                default: () => [],
            },
            loading: false,
        },
        data () {
            return {
                list: [],
                visible: false,
                selectedRowKeys: [],
                selectedList: [],
                columns: [
                    {
                      title: '用户名称',
                      align: 'left',
                      dataIndex: 'userName'
                    },
                ]
            }
        },
        methods: {
            openModal () {
                this.visible = this.visible ? this.closeModal() : true;
            },
            closeModal () {
                this.visible = false;
                this.selectedList = [];
                this.selectedRowKeys = [];
            },
            selectRowsChange (selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                this.originSelectedList = selectedRows;
                this.selectedList = _.cloneDeep(selectedRows);
            },
            handleOk () {
                console.log(99, this.selectedList);
                this.$emit('confirm', this.selectedList);
                this.closeModal();
            },
            deleteUser (row) {
                this.$emit('delete', row)
            },
        }
    }
</script>
<style lang="less" scoped>
    ul,
    li {
        list-style: none;
    }

    .selector-list-box {
        overflow: hidden auto;
        margin: 0px;
        height: 256px;
    }

    .wrap {
        position: relative;
        width: 100%;
        height: 300px;

        .panel-header {
            padding: 12px 16px;
            background: #5fa2dd;
            color: #f0f0f0;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;

            .plus {
                float: right;
                font-size: 20px;

                &:hover {
                    color: #fff;
                    cursor: pointer;
                }
            }
        }

        .panel-body {
            overflow: hidden auto;
            margin: 0px;
            width: 100%;
            height: 256px;
            border: 1px solid #e5e5e5;

            .section {
                list-style: none;
                position: relative;
                font-size: 16px;
                padding: 0 10px;
                width: 100%;
                height: 40px;
                line-height: 40px;
                background: #fff;
                border-bottom: 1px solid #e9e9e9;

                &:nth-child(even) {
                    background: #f6f6f6;
                }

                &:hover {
                    background: #dee3e8;

                   .delete-icon {
                        color: #ff0000;
                   } 
                }

                .delete-icon {
                    cursor: pointer;
                }
            }
        }
    }
</style>