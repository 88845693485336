<template>
  <a-modal
    :title="title === '1' ? $t('add') : $t('edit')"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'version')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'version')" v-decorator="[ 'version', validatorRules.version]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'versionContent')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'versionContent')" v-decorator="[ 'content', validatorRules.content]" />
        </a-form-item>

<!--        <a-form-item-->
<!--          :labelCol="labelCol"-->
<!--          :wrapperCol="wrapperCol"-->
<!--          label="是否强更">-->
<!--          <a-input placeholder="请输入是否强更" v-decorator="[ 'forceUpdate', validatorRules.forceUpdate]" />-->
<!--        </a-form-item>-->

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'forceUpdate')">
          <a-radio-group
            v-decorator="['forceUpdate', { initialValue: '0' }]">
<!--            <a-radio value="0">不强更</a-radio>-->
<!--            <a-radio value="1">强更</a-radio>-->
            <span v-for="item in this.forceUpdates">
              <a-radio :value=item>{{ $t(I18N_KEY + 'forceUpdate_' + item) }}</a-radio>
            </span>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'downloadUrl')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'downloadUrl')" v-decorator="[ 'downloadUrl', validatorRules.downloadUrl]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'remark')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'remark')" v-decorator="[ 'remark', validatorRules.remark]" />
        </a-form-item>



      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
const I18N_KEY = 'custServiceWorkOrder.';
export default {
  name: "VersionModal",
  data () {
    return {
      I18N_KEY,
      title:"操作",
      visible: false,
      forceUpdates: ["0","1"],
      // roleDisabled: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules:{
        version:{
          rules: [
            { required: true, message: 'Please input version!' },
          ]},
        content:{
          rules: [
            { required: true, message: 'Please input content!' },
          ]},
        downloadUrl:{
          rules: [
            { required: true, message: 'Please input download url!'},
          ]},
        remark:{
          rules: [
          ]}
      },
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({});
    },
    edit (record) {
      this.form.resetFields();
      this.open();
      this.model = Object.assign({}, record)
      this.model = {
        ...this.model,
        forceUpdate: this.model.forceUpdate != undefined ? '' + this.model.forceUpdate : '0',
      }

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'version', 'content', 'forceUpdate', 'downloadUrl','remark'))
      });

    },
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      // console.log(88888)
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            ...values,
          }
          this.$emit('ok', formData);

        }
      })
    },
    handleCancel () {
      this.close()
    },
  }
}
</script>

<style scoped>

</style>