<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="应用名称">
          <a-input placeholder="请输入应用名称" disabled="true" v-decorator="[ 'appName']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="配置项Key">
          <a-input placeholder="请输入配置项Key" disabled="true" v-decorator="[ 'settingKey']"/>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="配置项">
          <a-input placeholder="请输入配置项" v-decorator="[ 'settingName']"/>
        </a-form-item>

<!--        <a-form-item-->
<!--          :labelCol="labelCol"-->
<!--          :wrapperCol="wrapperCol"-->
<!--          label="	配置内容">-->
<!--          <a-input placeholder="请输入配置内容" v-decorator="[ 'settingValue',validatorRules.settingValue]"/>-->
<!--        </a-form-item>-->

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="状态">
          <a-select  default-value="1" style="width: 190px"
                     v-decorator="['settingStatus']"
          >
            <a-select-option value="1">
              开启
            </a-select-option>
            <a-select-option value="0">
              关闭
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="配置内容">
          <a-textarea :rows="6" placeholder="请输入配置内容" v-decorator="[ 'settingValue', validatorRules.settingValue ]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="配置描述">
          <a-textarea :rows="6" placeholder="请输入配置描述" v-decorator="[ 'settingDesc']" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import {addCpSetting, editCpAppSetting} from '@/webpublicapi/system'
import DictSelect from '../../modules/DictSelect';


// duplicateCheck
export default {
  name: 'AppSettingModal',
  components: {
    DictSelect,
  },
  mounted(){

    if(typeof FileReader=='undifined')        {
      console.log('抱歉，你的浏览器不支持 FileReader');
    }else{
      console.log('FileReader');
    }
  },
  data () {
    return {
      value: 1,
      title: '操作',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        settingValue: { rules: [{ required: true, message: '请输入配置内容!' }] },
      }
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({})
    },
    edit (record) {
      if (record.id) {
        this.visiblekey = true
      } else {
        this.visiblekey = false
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'appName', 'settingKey', 'settingName', 'settingValue', 'settingStatus', 'settingDesc'))
      })
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          console.log(formData)
          let obj = editCpAppSetting(formData);
          obj.then((res) => {
            if (res.code === 1000) {
              this.$message.success(res.msg)
              this.$emit('ok')
            } else {
              this.$message.warning(res.msg)
            }
          }).finally(() => {
            this.confirmLoading = false
            this.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },

    close () {
      this.$emit('close')
      this.visible = false
    },
    selectLoadImg() {
      this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
    },
    changeImage() {
      let files = this.$refs.avatarInput.files;
      let that = this;
      function readAndPreview(file) {
        that.file = file
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          let reader = new FileReader();
          reader.onload = function() {
            if (that.imgData !== this.result) {
              that.imgData = this.result // 这个是base64的数据格式
            }
          };
          reader.readAsDataURL(file);
        }
      }

      if (files) {
        [].forEach.call(files, readAndPreview);
      }
    },
    upLoad() {
      let imgFile = this.file;//获取到上传的图片
      let formData = new FormData();//通过formdata上传
      formData.append('file', imgFile)
      this.axios({
        method: "post",
        url: "/web/app/upload",
        data: formData,
        core: true
      })
        .then((res) => {
          console.log(res.data);
          alert("上传成功! 地址为:"+res.data)
          this.form.setFieldsValue({
            logoUrl: res.data
          })
        })
        .catch((error) => {
          console.log(error);
        })

    },
  }
}
</script>
