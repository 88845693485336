<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter="24">
          <a-col  :md='6' :sm='8'>
            <a-form-item label="日期" >
              <a-range-picker
                v-model="queryParam.time"
                format="YYYY-MM-DD"
                style="width: 200px;"/>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref='table'
        rowKey='dataTime'
        size='middle'
        :columns='columns'
        :dataSource='dataSourceTotal'
        :pagination='false'
        :loading='loading'
        :indentSize="0"
        bordered>
      </a-table>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :showHeader="false"
        :pagination='false'
        :loading='loading'
        bordered
        @change='handleTableChange'>
      </a-table>
      <div style='margin-top: 15px; text-align: right;'>
        <v-pagination
          v-if='total'
          :page-num='form.pageNum'
          :page-size='form.pageSize'
          :total='total'
          @change='handleTableChange' />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {getPromotionReport, getPromotionReportTotal} from '@/webpublicapi/oaKpi'
import moment from 'moment'

const I18N_KEY = {
  OASYSTEM: 'oaSystem.',
  FOLLOW: 'collectorTotalFollow.'
}
export default {
  name: 'oaWorkplace',
  components: {},
  data() {
    return {
      visdialog: false,
      I18N_KEY,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      dataSourceTotal: [],
      // 查询条件
      queryParam: {
        time: []
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title:  "日期",
          align: 'left',
          dataIndex: 'dataTime',
          width:200
        },
        {
          title:  "分享链接数(PV)",
          align: 'center',
          dataIndex: 'copyUrlCount',
          width:150
        },
        {
          title:  "分享链接数(UV)",
          align: 'center',
          dataIndex: 'copyUrlCountUV',
          width:150
        },
        {
          title:  "打开链接次数",
          align: 'center',
          dataIndex: 'openUrlCount',
          width:150
        },
        {
          title:   "通过链接注册数",
          align: 'center',
          dataIndex: 'registerCount',
          width:150
        },
        {
          title:  "点击下载按钮次数",
          align: 'center',
          dataIndex: 'downloadCountUV',
          width:150
        },
        {
          title:  "链接新客户申贷数",
          align: 'center',
          dataIndex: 'applyCount',
          width:150
        },
        {
          title:  "链接新客户审核通过数",
          align: 'center',
          dataIndex: 'passCount',
          width:150
        },
        {
          title:  "链接新客户放贷成功数",
          align: 'center',
          dataIndex: 'loanCount'
        }
      ]
    }
  },
  created() {
    this.getTime();
    this.loadData(1)
  },
  methods: {
    getTime() {
      this.queryParam.time = [];
      var nowDate = new Date();
      this.queryParam.time[0]=moment(nowDate.setDate(nowDate.getDate() - 15));
      this.queryParam.time[1]=moment(new Date());

    },
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {

      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }

      var params = this.getQueryParams()// 查询条件
      if (!params.beginTime) {
        this.$message.error("账单日期不能为空");
        return;
      }
      this.loading = true

      getPromotionReportTotal(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.dataSourceTotal = [res.data.total];
        } else {
          this.dataSourceTotal = [];
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })

      getPromotionReport(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },

    getQueryParams() {
      var param = this.queryParam;
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      param.beginTime = (param.time && param.time.length) ? this.transformTime(param.time[0]) : undefined;
      param.endTime = (param.time && param.time.length) ? this.transformTime(param.time[1]) : undefined;

      return filterObj(param)
    },
    transformTime (time) {
      return time.format('YYYY-MM-DD')
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置搜索框的内容
    searchReset() {
      this.getTime();
    }
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
