<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
              <a-row :gutter="24">
                <a-col :md="6" :sm="8">
                  <a-form-item :label="$t(I18N_KEY + 'orderNo')">
                    <a-input
                        :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderNo'))"
                        v-model="queryParam.orderNo"/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="8">
                  <a-form-item :label="$t(I18N_KEY + 'orderId')">
                    <a-input
                      :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderId'))"
                      v-model="queryParam.orderId"/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="8">
                  <a-form-item :label="$t('phoneNo')">
                    <a-input
                      :placeholder="$t('enter') + ' ' + transformStringToLow($t('phoneNo'))"
                      v-model="queryParam.mobileNo"></a-input>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="8">
                  <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                    <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
                    <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{x:1000}"
        bordered
        @change="handleTableChange">
        <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>
        <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.SETTLE_STATUS" :value="text"/>
        <Dict slot="overdueStatus" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>
        <span slot="action" slot-scope="text, record">
            <a @click="handleDetail(record)">{{ $t('view') }}</a>
            <!--&#160;-->
            <!--<a @click="jumpRepayUrl(record)" style="margin-left: 8px" v-if="record.settleStatus == 0">{{ $t('repay') }}</a>-->
            <a @click="reductionAmt(record)" style="margin-left: 8px" v-if="record.settleStatus == 0 && record.overdueStatus == 1">{{ $t('decLateFee') }}</a>
            <a @click="handleRepayAll(record)" style="margin-left: 8px" v-if="record.settleStatus == 0">{{ $t('offlineRepay') }}</a>
            <a @click="extendOrder(record)" style="margin-left: 8px" v-if="record.settleStatus == 0">线下展期还款</a>
            <a @click="modifyCollector(record)" style="margin-left: 8px" v-if="record.settleStatus == 0">变更催收员</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
    <v-modal
        v-model="visible"
        :title="$t(I18N_KEY + 'orderDetail')">
        <div style="padding: 20px;">
            <order-detail v-if="visible" :orderId="orderId" :custInfoId="custInfoId"/>
        </div>
    </v-modal>

    <a-modal v-model="visibleO" title="支付链接" @ok="handleOk">
       <p>{{ ModalText }}</p>
    </a-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getModifyCollectorOrderList, paymentsLink, reductionLateFee,  repayOfflineAll, extendOffline, modifyCollector} from '@/api/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import OrderDetail from '../order/OrderDetail';
import md5 from 'md5'
const I18N_KEY = 'order.';

export default {
  name: 'LoanOrderlist',
  components: {
    Dict,
    DictSelect,
    vModal,
    OrderDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      visibleO: false,
      ModalText: '',
      orderId: undefined,
      custInfoId: undefined,
      // 分页参数
      dataSource: [],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      // 查询条件
      queryParam: {
        "orderNo":"",
        "orderId":undefined,
        "mobileNo":""
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: 'APP',
          align: 'left',
          width: '100px',
          dataIndex: 'channel'
        },
        {
          title: this.$t(I18N_KEY + 'orderNo'),
          align: 'left',
          width: '200px',
          dataIndex: 'orderNo'
        },
        {
          title: this.$t('phoneNo'),
          align: 'left',
          width: '150px',
          dataIndex: 'mobileNo'
        },
        {
          title: this.$t('customer.names'),
          align: 'left',
          width: '200px',
          dataIndex: 'names'
        },
        {
          title: this.$t('customer.surnames'),
          align: 'left',
          width: '200px',
          dataIndex: 'surnames'
        },
        {
          title: this.$t('customer.curp'),
          align: 'left',
          width: '200px',
          dataIndex: 'curp'
        },
        // {
        //   title: this.$t('customer.curp'),
        //   align: 'left',
        //   dataIndex: 'curp'
        // },
        {
          title: this.$t(I18N_KEY + 'applicationAmount'),
          align: 'left',
          width: '100px',
          dataIndex: 'applyAmt'
        },
        {
          title: this.$t(I18N_KEY + 'deadline'),
          align: 'left',
          width: '100px',
          dataIndex: 'duration'
        },
        // {
        //   title: this.$t(I18N_KEY + 'loanStatus'),
        //   align: 'left',
        //   dataIndex: 'loanStatus',
        //   scopedSlots: { customRender: 'loanStatus' }
        // },
        // {
        //   title: this.$t(I18N_KEY + 'approvalStatus'),
        //   align: 'left',
        //   dataIndex: 'approvalStatus',
        //   scopedSlots: { customRender: 'approvalStatus' }
        // },
        {
          title: this.$t(I18N_KEY + 'settlementStatus'),
          align: 'left',
          width: '100px',
          dataIndex: 'settleStatus',
          scopedSlots: { customRender: 'settleStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'overdueStatus'),
          align: 'left',
          width: '100px',
          dataIndex: 'overdueStatus',
          scopedSlots: { customRender: 'overdueStatus' }
        },
        // {
        //   title: this.$t(I18N_KEY + 'applicationTime'),
        //   align: 'left',
        //   dataIndex: 'createdTime'
        // },

          {
              title: this.$t(I18N_KEY + 'loanTime'),
              align: 'left',
            width: '100px',
              dataIndex: 'paymentTime'
          },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    // this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getModifyCollectorOrderList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const data = {
          ...this.queryParam
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
      that.queryParam.orderId = ''
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.id;
        this.custInfoId = row.custInfoId;
        this.changeModal(true);
    },
    handleOk(e) {
      this.ModalText = '';
      this.visibleO = false;
    },
    jumpRepayUrl(row){
      paymentsLink(Object.assign({}, {orderId : row.id})).then((res)=>{
        if(res.code===1000){
            this.ModalText = res.data.url;
            this.visibleO = true;
        }else{
          this.$message.error(res.msg);
        }
      this.loading = false
    }).catch(e => {
        this.$message.error(e.message);
    })
    },
    reductionAmt (record) {
      const self = this;
      var lateFee = record.lateFee;
      this.$confirm({
        title: self.$t('order.isReductionAmt'),
        content: h => {
          return <div>
                  <div>
                      滞纳金：{lateFee}
                  </div>
                  <div>
                      减免金额：<input id = 'repayAmt'></input>
                  </div>
                </div>
        },
        onOk() {
          self.loading = true;

          var repayAmt = document.getElementById("repayAmt").value;

          if(!repayAmt.trim()){
            alert('减免金额不能为空');

            self.loadData();

            return false;
          }

          if(repayAmt > lateFee){
            alert('减免金额不能大于滞纳金');

            self.loadData();

            return false;
          }

          reductionLateFee({ orderId: record.id, reductionAmt: repayAmt }).then(res => {
            self.loadData();
          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },
    handleRepayAll (record) {

      const self = this;
      this.$confirm({

        title: self.$t('order.isSettle') + '（全额）',
        content: h => {
          return <div>
                <div>
                  验证密码：<input id = 'password' type = 'password' />
                </div>
            </div>
        },
        onOk() {
          self.loading = true;
          var password = document.getElementById("password").value;

          if(!password.trim()){
            alert('验证密码不能为空');

            self.loadData();

            return false;
          }

          var md5Password = md5(password);

          repayOfflineAll({ orderId: record.id, password: md5Password}).then(res => {
            if(res.code===1000){
              self.loadData();
            }else{
              self.$message.error(res.msg);
              self.loadData();
            }

          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },
    extendOrder (record) {

      const self = this;
      this.$confirm({

        title: '确定线下展期还款？',
        content: h => {
          return <div>
          <div>
          验证密码：<input id = 'password' type = 'password' />
            </div>
            </div>
        },
        onOk() {
          self.loading = true;
          var password = document.getElementById("password").value;

          if(!password.trim()){
            alert('验证密码不能为空');

            self.loadData();

            return false;
          }

          var md5Password = md5(password);

          extendOffline({ orderId: record.id, password: md5Password}).then(res => {
            if(res.code===1000){
              self.loadData();
            }else{
              self.$message.error(res.msg);
              self.loadData();
            }

          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },
    modifyCollector (record) {

      const self = this;
      this.$confirm({

        title: '确定变更催收员？',
        content: h => {
          return <div>
          <div>
          催收员手机号：<input id = 'userMobile' />
            </div>
            </div>
        },
        onOk() {
          self.loading = true;
          var userMobile = document.getElementById("userMobile").value;

          if(!userMobile.trim()){
            alert('催收员手机号不能为空');

            self.loadData();

            return false;
          }

          modifyCollector({ orderId: record.id, userMobile: userMobile}).then(res => {
            if(res.code===1000){
              self.loadData();
            }else{
              self.$message.error(res.msg);
              self.loadData();
            }

          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
