<template>
  <a-modal :title="modal.title" :visible="modal.visible" :confirm-loading="modal.confirmLoading" :width="modal.width"
           @ok="submit"
           @cancel="hide">
    <div class="table-search-wrapper">
      <a-row :gutter="48" style="display: flex;align-items: center;">
        <a-col :span="6">
          <a-input v-model="model.name" placeholder="请输入规则码"></a-input>
        </a-col>
        <a-col :span="6">
          <a-input v-model="model.intro" placeholder="请输入说明"></a-input>
        </a-col>
        <a-col :span="12">
          <div style="display: flex;align-items: center;">已选 {{ table.rowSelection.selectedRowKeys.length }} 项
          </div>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="table.columns"
        :dataSource="dataSource"
        :loading="table.loading"
        :rowSelection="table.rowSelection"
        bordered
      >
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import {riskFeaturesList, addRiskRule} from "@/webpublicapi/risk";

export default {
  name: 'RuleUseAddModal',
  data() {
    return {
      modal: {title: '添加决策', visible: false, confirmLoading: false, width: '1000px'},
      table: {
        dataSource: [],
        loading: false,
        columns: [
          {
            title: 'ID',
            align: 'center',
            dataIndex: 'id'
          },
          {
            title: '规则码',
            align: 'center',
            dataIndex: 'name'
          },
          {
            title: '规则默认值',
            align: 'center',
            dataIndex: 'defaultValue'
          },
          {
            title: '说明',
            align: 'center',
            dataIndex: 'intro'
          },
          {
            title: '操作',
            dataIndex: 'action',
            align: "center",
            scopedSlots: {customRender: 'action'},
          }
        ],
        rowSelection: {
          selectedRowKeys: [],
          onSelect: this.onSelectRow,
          onSelectAll: this.onSelectAllRow,
          onSelectInvert: this.onSelectInvertRows
        },
      },
      model: {strategyId: undefined, name: '', intro: ''}
    }
  },
  computed: {
    dataSource() {
      return this.table.dataSource.filter(({name, intro}) => name.indexOf(this.model.name) > -1 && intro.indexOf(this.model.intro) > -1)
    }
  },
  methods: {
    show(strategyId) {
      this.model.strategyId = strategyId
      this.modal.visible = true
      this.loadData()
    },
    hide() {
      this.modal.visible = false
      this.modal.confirmLoading = false
    },
    submit() {
      addRiskRule({
        strategyId: this.model.strategyId,
        idList: this.table.rowSelection.selectedRowKeys
      }).then(({code, msg}) => {
        if (code === 1000) {
          this.hide()
          this.$emit('success')
        } else {
          return Promise.reject(msg)
        }
      }).catch((e) => {
        this.$message.error(e);
      })
    },
    loadData() {
      this.table.loading = true
      riskFeaturesList({pageNum: 1, pageSize: 999999999}).then(({code, data, msg} = {}) => {
        if (code === 1000) {
          this.table.dataSource = data.rows
          this.table.loading = false
        } else {
          this.table.dataSource = []
          return Promise.reject(msg)
        }
      }).catch(e => {
        this.table.loading = false;
        this.$message.error(e);
      })
    },
    handleSearch() {
      this.loadData()
    },
    onSelectRow({id}, selected) {
      if (selected) {
        this.table.rowSelection.selectedRowKeys.push(id)
      } else {
        this.table.rowSelection.selectedRowKeys = this.table.rowSelection.selectedRowKeys.filter(item => item !== id)
      }
    },
    onSelectAllRow(selected, selectedRows, changeRows) {
      if (selected) {
        this.table.rowSelection.selectedRowKeys = Array.from(new Set([...this.table.rowSelection.selectedRowKeys, ...selectedRows.map(({id}) => id)]))
      } else {
        changeRows = changeRows.map(({id}) => id)
        this.table.rowSelection.selectedRowKeys = this.table.rowSelection.selectedRowKeys.filter(id => !changeRows.includes(id))
      }
    }
  }
}
</script>
<style scoped>
.table-search-wrapper {
  padding: 20px;
}
</style>