<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <div class="table-operator"  style="margin-top: 5px">
        <a-button @click="flushPage" type="primary" icon="sync">{{ $t('flush') }}</a-button>
        <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('add') }}</a-button>
      </div>
      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">{{ $t('edit') }}</a>
        <a-divider type="vertical" />
        <a-dropdown>
            <a class="ant-dropdown-link">{{ $t('more') }} <a-icon type="down" /></a>
            <a-menu slot="overlay">
                <a-menu-item>
                    <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">
                        <a>{{ $t('delete') }}</a>
                    </a-popconfirm>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
      </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
      </div>
      <tele-market-sms-template-edit ref="modalForm" :url-types='urlTypes' :field-name='fieldName' @ok="modalFormOk"></tele-market-sms-template-edit>
    </div>
  </div>
</template>
<script>
import {
  baseData,
  smsTemplateList,smsTemplateOpt,smsTemplateDelete
} from '@/webpublicapi/teleMarket'
import TeleMarketSmsTemplateEdit from './modules/TeleMarketSmsTemplateEdit'
import moment from "moment";
import {mapGetters} from "vuex";
const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketSmsTemplate',
  components: {
    TeleMarketSmsTemplateEdit,
  },
  data () {
    return {
      I18N_KEY,
      loading: false,
      fieldName: 'textCn',
      visible: false,
      dataSource: [],
      urlTypes: [],
    }
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    // 表头
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          align:"center",
          dataIndex: 'id',
        },
        {
          title: this.$t(I18N_KEY + 'name'),
          align:"center",
          dataIndex: 'key',
        },
        {
          title: this.$t(I18N_KEY + 'smsTemplate'),
          align:"center",
          dataIndex: 'smsTemplate',
        },
        {
          title: this.$t(I18N_KEY + 'urlType'),
          align: 'center',
          dataIndex: 'urlType',
          customRender: (text, record) => {
            var text = text;
            if(text != undefined && text != null && text != '') {
              for(var i in this.urlTypes) {
                if(text === this.urlTypes[i].value) {
                  return this.urlTypes[i][this.fieldName]
                }
              }
            } else {
              return '';
            }
          },
        },
        {
          title: this.$t(I18N_KEY + 'url'),
          align:"center",
          dataIndex: 'url',
        },
        {
          title: this.$t(I18N_KEY + 'sort'),
          align:"center",
          dataIndex: 'sort',
        },
        {
          title: this.$t(I18N_KEY + 'operate'),
          dataIndex: 'action',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData();
  },
  methods: {
    loadBaseData(){
      this.loading = true;
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.urlTypes = res.data.urlTypes
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false;
      }).catch(e => {
        this.$message.error(e.message);
        this.loading = false;
      })
    },
    searchQuery () {
      this.loadData();
    },
    flushPage() {
      this.loadData();
    },
    modalFormOk(data) {
      this.createSeat(data);
    },
    handleAdd () {
      this.id = undefined;
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "1";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit (record) {
      this.id = record.id;
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "2";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    deleteCurrent (row) {
      this.loading = true;
      smsTemplateDelete(row.id).then(res => {
        this.$message.success(this.$t('success'));
        this.loadData();
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    createSeat (data) {
      console.log(data)
      const form = {
        ...data,
        id: this.id,
        opt: this.id ? '2' : '1'
      };
      console.log(form)
      smsTemplateOpt(form).then(res => {
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        if (res.code === 1000) {
          this.$message.success(res.msg);
          this.loadData();
        } else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    loadData () {
      this.loading = true;
      smsTemplateList().then(res => {
        this.loading = false;
        this.dataSource = res.data;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    }
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>