<template>


  <a-modal title="订单额度范围（按日分组）" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @cancel="hide" :footer="false" width="900px">
    <a-table
      :loading="loading"
      :pagination="{ pageSize: 50 }"
      :dataSource="dataSource">
      <a-table-column
        :title="this.$t('collection.ym')"
        align='left',
        dataIndex='ym'
        key="ym"
      />
      <a-table-column
        :title="this.$t('collection.ifAmtRate')"
        key="ifAmtRate"
        align='left',
        dataIndex='ifAmtRate'
      />
      <a-table-column
        :title="this.$t('collection.ifAmtFirstOverdueRate')"
        key="ifAmtFirstOverdueRate"
        align='left',
        dataIndex='ifAmtFirstOverdueRate'
      />
      <a-table-column
        :title="this.$t('collection.ifAmtUnSettleRate')"
        key="ifAmtUnSettleRate"
        align='left',
        dataIndex='ifAmtUnSettleRate'
      />
      <a-table-column
        :title="this.$t('collection.ifAmtEarlySettledRate')"
        key="ifAmtEarlySettledRate"
        align='left',
        dataIndex='ifAmtEarlySettledRate'
      />
      <a-table-column
        :title="this.$t('collection.ifAmtOverdueSettledRate')"
        key="ifAmtOverdueSettledRate"
        align='left',
        dataIndex='ifAmtOverdueSettledRate'
      />
      <a-table-column
        :title="this.$t('collection.ifAmtExtendsionSettledRate')"
        key="ifAmtExtendsionSettledRate"
        align='left',
        dataIndex='ifAmtExtendsionSettledRate'
      />
      <a-table-column
        :title="this.$t('collection.ifAmtOverdueExtendsionSettledRate')"
        key="ifAmtOverdueExtendsionSettledRate"
        align='left',
        dataIndex='ifAmtOverdueExtendsionSettledRate'
      />
      <a-table-column
        :title="this.$t('collection.ifAmtSettledRate')"
        key="ifAmtSettledRate"
        align='left',
        dataIndex='ifAmtSettledRate'
      />
    </a-table>
  </a-modal>
</template>
<script>
import { groupOrderByDay } from '@/webpublicapi/collection.js'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.'

}
export default {
  name: 'CollectionDiffUser',
  data() {
    return {
      I18N_KEY,
      modal: {
        visible: false,
        confirmLoading: false,
      },
      loading: true,
      model: {
        isEnable:0,
        normalConfigId:''
      },
      inQueryParam: {
        endTime:'',
        loanCount:'',
        phone:'',
      },
      groupList: [],
      departmentList: [],
      endTime:'',
      phone:'',
      loanCount:'',
      promise:undefined,

      list:{
        add:[],
        missing:[],
      },
      dataSource: [],
      // 查询条件
      queryParam: {
        endTime: undefined,
        loanCount: undefined,
        applyAmtStart: undefined,
        applyAmtEnd: undefined,
      },
    }
  },
  computed:{
    columns () {
      return [
        {
          title: this.$t('collection.ym'),
          align: 'left',
          dataIndex: 'ym'
        },
        {
          title:  this.$t('collection.ifAmtRate'),
          align: 'left',
          dataIndex: 'ifAmtRate'
        },
        {
          title: this.$t('collection.ifAmtFirstOverdueRate'),
          align: 'left',
          dataIndex: 'ifAmtFirstOverdueRate'
        },
        {
          title:this.$t('collection.ifAmtUnSettleRate'),
          align: 'left',
          dataIndex: 'ifAmtUnSettleRate'
        },
        {
          title: this.$t('collection.ifAmtEarlySettledRate'),
          align: 'left',
          dataIndex: 'ifAmtEarlySettledRate'
        },
        {
          title: this.$t('collection.ifAmtOverdueSettledRate'),
          align: 'left',
          dataIndex: 'ifAmtOverdueSettledRate',
        },
        {
          title: this.$t('collection.ifAmtExtendsionSettledRate'),
          align: 'left',
          dataIndex: 'ifAmtExtendsionSettledRate',
          scopedSlots: { customRender: 'ifAmtExtendsionSettledRate' }
        },
        {
          title: this.$t('collection.ifAmtOverdueExtendsionSettledRate'),
          align: 'left',
          dataIndex: 'ifAmtOverdueExtendsionSettledRate',
        },
        {
          title: this.$t('collection.ifAmtSettledRate'),
          align: 'left',
          dataIndex: 'ifAmtSettledRate',
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  methods: {
    show(value,record) {
      this.inQueryParam = {
        endTime: '',
        loanCount: '',
        phone: ''
      }
      this.searchQuery();
      this.list = {
        add:[],
        missing:[],
      }
      this.model = {...value,...record,}
      this.modal.visible = true
      this.modal.confirmLoading = true

      groupOrderByDay(Object.assign({}, this.model)).then((res)=>{
        this.loading = true
        if(res.code === 1000){
          this.dataSource = res.data || this.list;
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },

    searchQuery () {
      this.endTime =  this.inQueryParam.endTime
      this.loanCount =  this.inQueryParam.loanCount
      this.phone =  this.inQueryParam.phone
    },

    // 重置字典类型搜索框的内容
    searchReset () {
      this.inQueryParam.endTime=''
      this.inQueryParam.loanCount=''
      this.inQueryParam.phone=''
    },
    hide() {
      this.dataSource = []
      this.loading = true
      this.modal.visible = false
    }
  }
}
</script>