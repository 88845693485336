<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'orderNo')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.ORDER + 'orderNo'))" v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="10">
             <a-form-item :label="$t(I18N_KEY.COLLECTION + 'endTime')">
                <a-range-picker
                   v-model="queryParam.endTime"
                   format="YYYY-MM-DD"
                   style="width: 100%;"/>
             </a-form-item>
          </a-col>

        <template v-if="toggleSearchStatus">
          <!--<a-col :md="6" :sm="8">-->
             <!--<a-form-item :label="$t(I18N_KEY.COLLECTION + 'collectionTaskStatus')">-->
              <!--<dict-select-->
                <!--v-model="queryParam.collectionTaskStatus"-->
                <!--:dict-code="DICT_CODE.COLLECTION_TASK_STATUS"-->
                <!--:begin="[{ value: '', label: '全部', labelEn: 'All' }]"-->
                <!--:placeholder="$t('select')"-->
              <!--/>-->
            <!--</a-form-item>-->
          <!--</a-col>-->
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.CUSTOMER + 'fullName')">
               <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.CUSTOMER + 'custName'))" v-model="queryParam.aadhaarName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.CUSTOMER + 'mobile')">
                <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.CUSTOMER + 'mobile'))" v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
          </template>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
               <a @click="handleToggleSearch" style="margin-left: 8px">
                     {{ $t(toggleSearchStatus ? 'hide' : 'show') }}
                    <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
                  </a>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>
        <Dict slot="approvalTaskStatus" slot-scope="text" :dict-code="DICT_CODE.APPROVAL_TASK_STATUS" :value="text"/>
        <Dict slot="custContractCondition" slot-scope="text" :dict-code="DICT_CODE.CUST_CONTRACT_CONDITION" :value="text"/>
        <Dict slot="linkCondition" slot-scope="text" :dict-code="DICT_CODE.LINKCONDITION" :value="text"/>

        <span slot="action" slot-scope="text, record">
          <!-- slot-scope="text, record" -->
            <a @click="handleDetail(record)">{{ $t('view') }}</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getHistoryCollectionTask } from '@/webpublicapi/platformCollection'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import CollectionDetail from './CollectionDetail';
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.'

}

export default {
  name: 'AllApprovalTask',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "orderNo":"",
        "aadhaarName":"",
        "mobileNo":"",
        endTime: undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t('order.orderNo'),
          align: 'left',
          dataIndex: 'orderNo'
        },
        {
          title:  this.$t('customer.fullName'),
          align: 'left',
          dataIndex: 'aadhaarName'
        },
        {
          title: this.$t('order.overdueDays'),
          align: 'left',
          dataIndex: 'overdueDay'
        },
        {
          title:this.$t('collection.createTime'),
          align: 'left',
          dataIndex: 'createTime'
        },
        {
          title: this.$t('collection.followNum'),
          align: 'left',
          dataIndex: 'followNum'
        },
        {
          title: this.$t('collection.followUserName'),
          align: 'left',
          dataIndex: 'followUserName',
        },
        {
          title: this.$t('collection.custContractCondition'),
          align: 'left',
          dataIndex: 'followResult',
          scopedSlots: { customRender: 'custContractCondition' }
        },
        {
          title: this.$t('collection.linkCondition'),
          align: 'left',
          dataIndex: 'linkCondition',
          scopedSlots: { customRender: 'linkCondition' }
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.getDict();
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getHistoryCollectionTask(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          endDateStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
          endDateEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
       that.queryParam.aadhaarName = ''
       that.queryParam.mobileNo = ''
       that.queryParam.endTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.orderId;
        this.custInfoId = row.custInfoId;
        this.taskId = row.taskId;
        this.changeModal(true);
    },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
