<template>
  <div>
    <div style='padding: 15px;'>
      <a-input style='width: 240px; margin-right: 15px;' :placeholder="$t('platformCollection.keyword')"
               v-model='form.keywords' />
      <a-button type='primary' @click='search(1)'>{{ $t('search') }}</a-button>
      <a-button style='margin-left: 10px;' type='primary' @click="createDepartment('add')">
        <a-icon type='plus' />
        {{ $t('add') }}
      </a-button>
      <a-button style='margin-left: 10px;' type='primary' @click="searchReset()" >{{ $t('reset') }}</a-button>

    </div>
    <a-table
      :dataSource='list'
      :pagination='false'
      :loading='loading'
      rowKey='id'
      bordered
      @change='handleTableChange'>
      <a-table-column
        :title="$t('platformCollection.department')"
        dataIndex='departmentName'
        key='departmentName'
        align='center'
      />

      <a-table-column
        :title="$t('platformCollection.state')"
        dataIndex='status'
        key='status'
        align='center'
      >
        <template slot-scope='text, record'>
          <span>{{ text == 1 ? $t('platformCollection.enable') : $t('platformCollection.disable') }}</span>
        </template>
      </a-table-column>
      <a-table-column
        :title="$t('platformCollection.operation')"
        dataIndex='action'
        key='action'
        align='center'
      >
        <template slot-scope='text, record'>
          <span>
              <a href='javascript:;' @click="createDepartment('edit', record)">{{ $t('platformCollection.edit')
                }}</a>
             <!-- <a-divider type='vertical' />-->
             <!-- <a href='javascript:;' @click='deleteCurrent(record)'>{{ $t('platformCollection.delete') }}</a>-->
          </span>
        </template>
      </a-table-column>
    </a-table>
    <div style='margin-top: 15px; text-align: right;'>
      <v-pagination
        v-if='total'
        :page-num='form.pageNum'
        :page-size='form.pageSize'
        :total='total'
        @change='handleTableChange' />
    </div>
    <a-modal
      :title='modalTitle'
      v-model='visible'
      @ok='submitDepartment'
    >
      <a-form :form='basicForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
        <a-form-item :label="$t('platformCollection.department')">
          <a-input v-model='basicForm.departmentName' />
        </a-form-item>
        <a-form-item :label="$t('platformCollection.state')">
          <a-switch v-model='basicForm.status' />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {
  addPlatformCollectionGroupList,
  deletePlatformCollectionGroupList,
  editPlatformCollectionGroupList
} from '@/webpublicapi/group'

import vPagination from '../modules/pagination'
import {
  deletePlatformCollectionDepartment,
  editPlatformCollectionDepartment,
  getPlatformCollectionDepartmentList
} from '@/webpublicapi/department'

export default {
  components: {
    vPagination,
  },
  data() {
    return {
      loading: false,
      modalTitle: this.$t('add'),
      form: {
        pageNum: 1,
        pageSize: 10
        // keywords: undefined,
      },
      visible: false,
      defaultBasicForm: {
        status: true
      },
      basicForm: {},
      USER_STATUS: {
        1: this.$t('platformCollection.enable'),
        2: this.$t('platformCollection.disable')
      },
      list: [],
      total: 0,
      selectedRowKeys: [],
    }
  },
  computed: {
    pagination() {
      return {
        pageSize: this.form.pageSize,
        current: this.form.pageNum,
        total: this.total
      }
    },
    roleList() {
      return this.allRoleList || []
    }
  },
  created() {
    this.basicForm = { ...this.defaultBasicForm }
    this.search()
  },
  methods: {
    selectRowsChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.originSelectedList = selectedRows
      this.selectedList = _.cloneDeep(selectedRows)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.search()
    },
    changeModal(visible) {
      this.visible = visible
    },
    createDepartment(mode, record = {}) {
      this.changeModal(true)
      this.basicForm.id = record.id
      if (mode == 'edit') {
        this.basicForm = {
          ...this.basicForm,
          status: record.status == 1,
          departmentName: record.departmentName
        }
      } else {
        this.basicForm = { ...this.defaultBasicForm }
      }
      this.modalTitle = mode == 'add' ? this.$t('add') : this.$t('edit')
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.form.keywords = undefined;
      this.search()
    },
    deleteCurrent(row) {
      this.$confirm({
        title: this.$t('platformCollection.delete_current_department'),
        content: this.$t('platformCollection.delete_the_current_department'),
        onOk: () => {
          this.loading = true
          deletePlatformCollectionDepartment({ departmentId: row.id }).then(res => {
            this.search(1)
          }).catch(e => {
            this.loading = false
            this.$message.error(e.message)
          })
        }
      })
    },
    submitDepartment() {
      this.changeModal(false)
      this.loading = true
      const form = {
        ...this.basicForm,
        status: this.basicForm.status ? 1 : 0
      }
      const requestField = form.departmentName ? editPlatformCollectionDepartment : editPlatformCollectionDepartment

      requestField(form).then(res => {
        this.loading = false
        this.$message.success(this.$t('success'))
        this.search(1)
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },
    search(pageNum) {
      if (pageNum) {
        this.form.pageNum = pageNum
      }
      this.loading = true
      getPlatformCollectionDepartmentList(this.form).then(res => {
        this.loading = false
        this.list = res && res.data && res.data.rows
        this.total = res && res.data && res.data.total
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    }
  }
}
</script>
