<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="邮箱">
          <a-input placeholder="请输入邮箱" v-decorator="[ 'email', validatorRules.email]"/>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { editCustomer } from '@/api/customer'
// duplicateCheck
export default {
  name: 'CustomerModifyModal',
  data () {
    return {
      value: 1,
      title: '操作',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        email: { rules: [{ required: true, message: '请输入邮箱!' }] }
      }
    }
  },
  created () {
  },
  methods: {
    validateDictCode (rule, value, callback) {
      // 重复校验
      // var params = {
      //   tableName: 'sys_dict',
      //   fieldName: 'dict_code',
      //   fieldVal: value,
      //   dataId: this.model.id
      // }
      // duplicateCheck(params).then((res) => {
      //   if (res.success) {
      //     callback()
      //   } else {
      //     callback(res.message)
      //   }
      // })
      callback()
    },
    handleChange (value) {
      this.model.status = value
    },
    add () {
      this.edit({})
    },
    edit (record) {
      if (record.id) {
        this.visiblekey = true
      } else {
        this.visiblekey = false
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'email'))
      })
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          values.email = (values.email || '').trim()
          const formData = Object.assign(this.model, values)
          let obj
          console.log(formData)
          if (!this.model.id) {
            obj = addDict(formData)
          } else {
             obj = editCustomer(formData)
          }
          obj.then((res) => {
            if (res.code === 1000) {
              that.$message.success(res.msg)
              that.$emit('ok')
            } else {
              that.$message.warning(res.msg)
            }
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
