<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter="24">
          <a-col  :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.FOLLOW + 'billDate')" >
              <a-range-picker
                v-model="queryParam.time"
                format="YYYY-MM-DD"
                style="width: 200px;"/>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.FOLLOW + 'dataCaliber')">
              <a-select style='width: 190px' allowClear v-model='queryParam.statisticsType'>
                <a-select-option :value="0">{{$t(I18N_KEY.FOLLOW + 'anDate')}}</a-select-option>
                <a-select-option :value="1">{{$t(I18N_KEY.FOLLOW + 'total')}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.FOLLOW + 'hierarchyObject')">
              <a-select style='width: 190px' allowClear v-model='queryParam.statisticsLevel'>
                <a-select-option :value="0">{{$t(I18N_KEY.FOLLOW + 'collectorName')}}</a-select-option>
                <a-select-option :value="1">{{$t(I18N_KEY.FOLLOW + 'groupName')}}</a-select-option>
                <a-select-option :value="2">{{$t(I18N_KEY.FOLLOW + 'department')}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.FOLLOW + 'departmentName')">
              <a-select style='width: 190px' allowClear v-model='queryParam.departmentId'>
                <a-select-option v-for='item in departments' :value='item.id'>
                  {{ item.departmentName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.FOLLOW + 'collectionStage')">
              <a-select default-value="" style="width: 190px" v-model="queryParam.normalConfigId">
                <a-select-option v-for="item in normalConfigList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.FOLLOW + 'groupName')">
              <a-select style='width: 300px' allowClear v-model='queryParam.groupId'>
                <a-select-option v-for='item in groupAll' :value='item.id'>
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.FOLLOW + 'collectorName')">
              <a-input v-model="queryParam.userName"  style='width: 300px'></a-input>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref='table'
        rowKey='dataTime'
        size='middle'
        :columns='columns'
        :dataSource='dataSourceTotal'
        :pagination='false'
        :loading='loading'
        :indentSize="0"
        bordered>
      </a-table>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :showHeader="false"
        :columns='columns'
        :dataSource='dataSource'
        :pagination='false'
        :loading='loading'
        bordered
        @change='handleTableChange'>
      </a-table>
      <div style='margin-top: 15px; text-align: right;'>
        <v-pagination
          v-if='total'
          :page-num='form.pageNum'
          :page-size='form.pageSize'
          :total='total'
          @change='handleTableChange' />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getFirstDayCollectRate, getFirstDayCollectRateTotal } from '@/webpublicapi/oaKpi'
import { getAllGroup } from '@/webpublicapi/group'
import { getAllDepartment } from '@/webpublicapi/department'
import { getAllNormalConfig } from '@/webpublicapi/normalConfig'
import moment from 'moment'
import exportFile from "@/webpublicapi/exportFile";

const I18N_KEY = {
  OASYSTEM: 'oaSystem.',
  FOLLOW: 'collectorTotalFollow.'
}
export default {
  name: 'oaWorkplace',
  components: {},
  data() {
    return {
      visdialog: false,
      I18N_KEY,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      dataSourceTotal: [],
      departments: [],
      groupAll: [],
      normalConfigList: [],
      // 查询条件
      queryParam: {
        statisticsLevel:0,
        statisticsType:0,
        time: [],
        departmentId: '',
        groupId: '',
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title:  this.$t('collectorTotalFollow.date'),
          align: 'left',
          dataIndex: 'dataTime',
          width: 120
        },
        {
          title:  this.$t('collectorTotalFollow.department'),
          align: 'center',
          dataIndex: 'departmentName',
          width: 120
        },
        {
          title:   this.$t('collectorTotalFollow.collectionStage'),
          align: 'center',
          dataIndex: 'normalConfigName',
          width: 35
        },
        {
          title:  this.$t('collectorTotalFollow.groupName'),
          align: 'center',
          dataIndex: 'groupName',
          width: 100
        },
        {
          title:  this.$t('collectorTotalFollow.collectorName'),
          align: 'center',
          dataIndex: 'userName',
          width: 100
        },
        {
          title:  this.$t('collectorTotalFollow.orderCount'),
          align: 'left',
          dataIndex: 'orderCount',
          width: 80
        },
        {
          title:  this.$t('collectorTotalFollow.yiHuanCount'),
          align: 'left',
          dataIndex: 'yiHuanCount',
          width: 80
        },
        {
          title:  this.$t('collectorTotalFollow.yiHuanRate'),
          align: 'left',
          dataIndex: 'yiHuanRate',
          width: 100
        }
      ]
    }
  },
  created() {
    this.getTime();
    getAllDepartment().then(res => {
      if (res.code === 1000) {
        this.departments = res.data
      } else {
        this.departments = []
        this.$message.error(res.msg)
      }
    });

    getAllGroup().then(res => {
      if (res.code === 1000) {
        this.groupAll = res.data
      } else {
        this.groupAll = []
        this.$message.error(res.msg)
      }
    });

    getAllNormalConfig().then(res => {
      if (res.code === 1000) {
        this.normalConfigList = res.data
      } else {
        this.normalConfigList = []
        this.$message.error(res.msg)
      }
    });
    this.loadData(1)
  },
  methods: {
    getTime() {
      this.queryParam.time = [];
      var nowDate = new Date();
      this.queryParam.time[0]=moment(nowDate.setDate(nowDate.getDate() - 15));
      this.queryParam.time[1]=moment(new Date());

    },
    getUrl() {
      var params = this.getQueryParams()// 查询条件

      var url = '/api/web/webpublic/collectFirstDayRate/export?beginTime=' + params.beginTime

        if (params.endTime) {
          url += '&endTime=' + params.endTime;
        }
        if (params.statisticsType) {
          url += '&statisticsType=' + params.statisticsType;
        }
        if (params.statisticsLevel ) {
          url += '&statisticsLevel=' + params.statisticsLevel;
        }
        if (params.departmentId) {
          url += '&departmentId=' + params.departmentId;
        }
        if (params.normalConfigId ) {
          url += '&normalConfigId=' + params.normalConfigId;
        }
        if (params.groupId ) {
          url += '&groupId=' + params.groupId;
        }
        if (params.userName) {
          url += '&userName=' + params.userName;
        }
      return url;
    },
    downLoadData() {
      var param = this.getQueryParams();
      if (!param.beginTime) {
        this.$message.error("账单日期不能为空");
        return;
      }

      const file = {
        fileUrl: this.getUrl(),
        fileName: 'collectFirstDayRate.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {

      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      var params = this.getQueryParams()// 查询条件
      if (!params.beginTime) {
        this.$message.error("账单日期不能为空");
        return;
      }

      this.loading = true

      getFirstDayCollectRateTotal(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.dataSourceTotal = res.data
        } else {
          this.dataSourceTotal = []
          this.$message.error(res.msg)
        }
      })


      getFirstDayCollectRate(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },

    getQueryParams() {
      var param = this.queryParam;
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      param.beginTime = (param.time && param.time.length) ? this.transformTime(param.time[0]) : undefined;
      param.endTime = (param.time && param.time.length) ? this.transformTime(param.time[1]) : undefined;

      return filterObj(param)
    },
    transformTime (time) {
      return time.format('YYYY-MM-DD')
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置搜索框的内容
    searchReset() {
      this.getTime();
      this.queryParam.departmentId = ''
      this.queryParam.groupId = ''
      this.queryParam.normalConfigId = ''
      this.queryParam.userName = ''
      this.queryParam.statisticsType = 0
      this.queryParam.statisticsLevel = 0
    }
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
