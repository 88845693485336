<template>
  <a-spin :spinning="spinning">
    <div :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form @keyup.enter.native="loadBaseData">
          <a-row :gutter="24">
            <a-col :md='24' :sm='24'>
              <template>
                <a-form-item
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  :label="$t(I18N_KEY + 'type')" :required='required_true'>
                  <div :style="{ borderBottom: '1px solid #E9E9E9' }">
                    <a-checkbox
                      v-model:checked="checkAll"
                      :indeterminate="indeterminate"
                      @change='onCheckAllChange'
                    >
                      {{ $t(I18N_KEY + 'allTypes') }}
                    </a-checkbox>
                  </div>
                  <br />
                  <a-checkbox-group v-model:value="queryParam.types">
                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("1")'>
                      <a-col :span="18">
                        <a-checkbox value="1">{{$t(I18N_KEY + "type_1")}}</a-checkbox>
                      </a-col>
                    </a-row>
                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("2")'>
                      <a-col :span="18">
                        <a-checkbox value="2">{{$t(I18N_KEY + "type_2")}}</a-checkbox>
                      </a-col>
                    </a-row>
                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("3")'>
                      <a-col :span="18">
                        <a-checkbox value="3">{{$t(I18N_KEY + "type_3")}}</a-checkbox>
                      </a-col>
                    </a-row>
                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("4")'>
                      <a-col :span="18">
                        <a-checkbox value="4">{{$t(I18N_KEY + "type_4")}}</a-checkbox>
                      </a-col>
                    </a-row>
                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("5")'>
                      <a-col :span="18">
                        <a-checkbox value="5">{{$t(I18N_KEY + "type_5")}}</a-checkbox>
                      </a-col>
                    </a-row>
                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("6")'>
                      <a-col :span="18">
                        <a-checkbox value="6">{{$t(I18N_KEY + "type_6")}}</a-checkbox>
                      </a-col>
                    </a-row>
                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("10")'>
                      <a-col :span="24">
                        <a-checkbox value="10">
                          {{$t(I18N_KEY + "type_10_pre")}}
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.hours10" style='width:40px'/>
                          -
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.hours10end" style='width:40px'/>
                          {{$t(I18N_KEY + "type_10_suf")}}
                        </a-checkbox>
                      </a-col>
                    </a-row>

                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("13")'>
                      <a-col :span="24">
                        <a-checkbox value="13" @change='queryCount("13")'>
                          {{$t(I18N_KEY + "type_13_pre")}}
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays13" style='width:40px' :disabled='disabled'/>
                          -
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays13end" style='width:40px' :disabled='disabled'/>
                          {{$t(I18N_KEY + "type_13_suf")}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("14")'>
                      <a-col :span="24">
                        <a-checkbox value="14">
                          {{$t(I18N_KEY + "type_14_pre")}}
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays14" style='width:40px' :disabled='disabled'/>
                          -
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays14end" style='width:40px' :disabled='disabled'/>
                          {{$t(I18N_KEY + "type_14_suf")}}
                        </a-checkbox>
                      </a-col>
                    </a-row>

                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("7")'>
                      <a-col :span="24">
                        <a-checkbox value="7">{{$t(I18N_KEY + "type_7")}}</a-checkbox>
                      </a-col>
                    </a-row>
                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("11")'>
                      <a-col :span="24">
                        <a-checkbox value="11">
                          {{$t(I18N_KEY + "type_11_pre")}}
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays11" style='width:40px' :disabled='disabled'/>
                          -
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays11end" style='width:40px'/>
                          {{$t(I18N_KEY + "type_11_suf")}}
                        </a-checkbox>
                      </a-col>
                    </a-row>

                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("12")'>
                      <a-col :span="24">
                        <a-checkbox value="12">
                          {{$t(I18N_KEY + "type_12_pre")}}
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.minutes12" style='width:40px'/>
                          {{$t(I18N_KEY + "type_12_suf")}}
                        </a-checkbox>
                      </a-col>
                    </a-row>

                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("8")'>
                      <a-col :span="24">
                        <a-checkbox value="8">
                          {{$t(I18N_KEY + "type_8_pre")}}
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays8" style='width:40px'/>
                          -
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays8end" style='width:40px'/>
                          {{$t(I18N_KEY + "type_8_suf")}}
                        </a-checkbox>
                      </a-col>
                    </a-row>

                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("9")'>
                      <a-col :span="24">
                        <a-checkbox value="9">
                          {{$t(I18N_KEY + "type_9_pre")}}
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays9" style='width:40px'/>
                          -
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays9end" style='width:40px'/>
                          {{$t(I18N_KEY + "type_9_suf")}}
                        </a-checkbox>
                      </a-col>
                    </a-row>

                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("15")'>
                      <a-col :span="24">
                        <a-checkbox value="15">
                          {{$t(I18N_KEY + "type_15_pre")}}
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preHour15" style='width:40px' :disabled='disabled'/>
                          {{$t(I18N_KEY + "type_15_suf")}}
                        </a-checkbox>
                      </a-col>
                    </a-row>

                    <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("16")'>
                      <a-col :span="24">
                        <a-checkbox value="16">
                          {{$t(I18N_KEY + "type_16_pre")}}
                          <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preHour16" style='width:40px' :disabled='disabled'/>
                          {{$t(I18N_KEY + "type_16_suf")}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>

                </a-form-item>
              </template>
            </a-col>

            <a-col :md="24" :sm="24">
            <span style="text-align:center;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="enterForm" icon="confirm">{{ $t('confirm') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { filterObj } from '@/utils/util';
import { storeTypeConfig, baseData} from '@/webpublicapi/teleMarket'
import {mapGetters} from "vuex";

const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketTypeConfig',
  data () {
    return {
      I18N_KEY,
      required_true: true,
      spinning: false,
      checkAll: false,
      indeterminate: false,
      types:[],
      typeValues:[],
      showTypes: [],
      fieldName: 'textCn',
      disabled: true,
      queryParam: {
        types: [],
        preDays8: '',
        preDays8end: '',
        preDays9: '',
        preDays9end: '',
        hours10: '',
        hours10end: '',
        preDays11: '3',
        preDays11end: '',
        minutes12: '',
        preDays13: '1',
        preDays13end: '1',
        preDays14: '1',
        preDays14end: '1',
        preHour15: '168',
        preHour16: '168',
      },
      form:{},
      loading: false,
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    this.loadBaseData();
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
  },
  methods: {
    enterForm() {
      this.storeTypeConfig()
    },
    loadBaseData() {
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.types = res.data.types;
          var typeValues = [];
          for(var item in this.types) {
            typeValues.push(this.types[item].value)
          }
          this.typeValues = typeValues;
          this.showTypes = res.data.showTypes;
          this.fillData(res.data.typeConfigs)
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    fillData(types) {
      for(var i in types) {
        var type = types[i].type
        var param = types[i].param
        this.queryParam.types.push(type)
        if(type === '8') {
          var params = param.split('-')
          this.queryParam.preDays8 = params[0]
          this.queryParam.preDays8end = params[1]
        }
        if(type === '9') {
          var params = param.split('-')
          this.queryParam.preDays9 = params[0]
          this.queryParam.preDays9end = params[1]
        }
        if(type === '10') {
          var params = param.split('-')
          this.queryParam.hours10 = params[0]
          this.queryParam.hours10end = params[1]
        }
        if(type === '11') {
          var params = param.split('-')
          this.queryParam.preDays11 = params[0]
          this.queryParam.preDays11end = params[1]
        }
        if(type === '12') {
          this.queryParam.minutes12 = param
        }
        if(type === '13') {
          var params = param.split('-')
          this.queryParam.preDays13 = params[0]
          this.queryParam.preDays13end = params[1]
        }
        if(type === '14') {
          var params = param.split('-')
          this.queryParam.preDays14 = params[0]
          this.queryParam.preDays14end = params[1]
        }
        if(type === '15') {
          this.queryParam.preHour15 = param
        }
        if(type === '16') {
          this.queryParam.preHour16 = param
        }
      }
    },
    checkTypes() {
      if((this.queryParam.types.includes('8') && (this.queryParam.preDays8 == '' || this.queryParam.preDays8end == '' || Number(this.queryParam.preDays8) < 1 || Number(this.queryParam.preDays8end) < 1 || Number(this.queryParam.preDays8) > Number(this.queryParam.preDays8end)) )
        || (this.queryParam.types.includes('9') && (this.queryParam.preDays9 == '' || this.queryParam.preDays9end == '' || Number(this.queryParam.preDays9) < 1 || Number(this.queryParam.preDays9end) < 1 || Number(this.queryParam.preDays9) > Number(this.queryParam.preDays9end)))) {
        return this.$t(I18N_KEY+ 'earlySettleNotice');
      }
      if(this.queryParam.types.includes('10') && (this.queryParam.hours10 == '' || Number(this.queryParam.hours10) < 1 || this.queryParam.hours10end == '' || Number(this.queryParam.hours10end) < 1)) {
        return this.$t(I18N_KEY+ 'registerNoApplyNotice');
      }
      if(this.queryParam.types.includes('11') && (this.queryParam.preDays11end == '' || Number(this.queryParam.preDays11end) < 3)) {
        return this.$t(I18N_KEY+ 'settleNoConversionNotice');
      }
      if(this.queryParam.types.includes('12') && (this.queryParam.minutes12 == '' || Number(this.queryParam.minutes12) < 1)) {
        return this.$t(I18N_KEY+ 'firstSettleNoConversionNotice');
      }
      if((this.queryParam.types.includes('13') && (this.queryParam.preDays13 == '' || this.queryParam.preDays13end == '' || Number(this.queryParam.preDays13) < 1 || Number(this.queryParam.preDays13end) < 1 || Number(this.queryParam.preDays13) > Number(this.queryParam.preDays13end)) )
        || (this.queryParam.types.includes('14') && (this.queryParam.preDays14 == '' || this.queryParam.preDays14end == '' || Number(this.queryParam.preDays14) < 1 || Number(this.queryParam.preDays14end) < 1 || Number(this.queryParam.preDays14) > Number(this.queryParam.preDays14end)))) {
        return this.$t(I18N_KEY+ 'settleNoConversionNotice');
      }
      if(this.queryParam.types.includes('15') && (this.queryParam.preHour15 == '' || Number(this.queryParam.preHour15) < 1)) {
        return this.$t(I18N_KEY+ 'loanFailedNoRebindCardNotice');
      }
      if(this.queryParam.types.includes('16') && (this.queryParam.preHour16 == '' || Number(this.queryParam.preHour16) < 1)) {
        return this.$t(I18N_KEY+ 'loanFailedNoRebindCardNotice');
      }
    },
    storeTypeConfig() {
      var message = this.checkTypes();
      if(message != undefined && message != null && message != '') {
        this.$message.error(message);
        return;
      }
      this.spinning = true
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      storeTypeConfig(Object.assign({}, params)).then((res) => {
        this.spinning = false
        if (res.code === 1000) {
          this.$message.info("success")
        } else {
          var msg = res.msg;
          if(msg === 'earlySettleNotice') {
            this.$message.error(this.$t(I18N_KEY + 'earlySettleNotice'))
          } else if(msg === 'noGroupNameNotice') {
            this.$message.error(this.$t(I18N_KEY + 'noGroupNameNotice'))
          } else if(msg === 'noTypeCountNotice'){
            this.$message.error(this.$t(I18N_KEY + 'noTypeCountNotice'))
          } else if(msg === 'registerNoApplyNotice') {
            this.$message.error(this.$t(I18N_KEY + 'registerNoApplyNotice'))
          } else if(msg === 'settleNoConversionNotice') {
            this.$message.error(this.$t(I18N_KEY + 'settleNoConversionNotice'))
          } else if(msg === 'firstSettleNoConversionNotice') {
            this.$message.error(this.$t(I18N_KEY + 'firstSettleNoConversionNotice'))
          } else if(msg === 'loanFailedNoRebindCardNotice') {
            this.$message.error(this.$t(I18N_KEY + 'loanFailedNoRebindCardNotice'))
          } else {
            this.$message.error(res.msg)
          }
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.spinning = false
        this.$message.error(e.message);
      })
    },

    getQueryParams() {
      const data = {
        ...this.queryParam
      }
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.types = ''
      that.queryParam.preDays8 = ''
      that.queryParam.preDays8end = ''
      that.queryParam.preDays9 = ''
      that.queryParam.preDays9end = ''
      that.queryParam.hours10 = ''
      that.queryParam.hours10end = ''
      that.queryParam.preDays11 = '3'
      that.queryParam.preDays11end = ''
      that.queryParam.minutes12 = ''
      that.queryParam.preDays13 = '1'
      that.queryParam.preDays13end = '1'
      that.queryParam.preDays14 = '1'
      that.queryParam.preDays14end = '1'
      that.queryParam.preHour15 = '168'
      that.queryParam.preHour16 = '168'
    },
    onCheckAllChange(){
      this.checkAll = this.checkAll?true:false;
      this.queryParam.types = this.checkAll?this.typeValues:[];
    },
    hasType(type) {
      if(this.showTypes == []) {
        return true;
      }
      if(this.showTypes.length > 0) {
        for (var t in this.showTypes) {
          if(this.showTypes[t] == type) {
            return true;
          }
        }
      }
      return false;
    },
  },
  watch: {
    "queryParam.types"(oldValue, newValue){
      this.indeterminate = !!this.queryParam.types.length && this.queryParam.types.length < this.types.length;
      this.checkAll = this.queryParam.types.length === this.types.length;
    },
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>