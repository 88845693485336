<template>
  <div>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline'>
        <a-row :gutter='24'>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'用户群ID'">
              <a-input v-model='form.id' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'用户群名称'">
              <a-input v-model='form.groupName' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'用户群状态'">
              <a-select
                v-model='form.status'
              >
                <a-select-option value='' selected>
                  全部
                </a-select-option>
                <a-select-option value='1'>
                  开启
                </a-select-option>
                <a-select-option value='2'>
                  关闭
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
<!--          <a-col :md='6' :sm='8'>-->
<!--            <a-form-item :label="'用户群类型'">-->
<!--              <a-select-->
<!--                v-model='form.type'-->
<!--              >-->
<!--                <a-select-option value=''>-->
<!--                  全部-->
<!--                </a-select-option>-->
<!--                <a-select-option value='1'>-->
<!--                  优惠卷-->
<!--                </a-select-option>-->
<!--                <a-select-option value='2'>-->
<!--                  短信-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='search()'>搜索</a-button>&nbsp;&nbsp;
              <a-button type='primary' @click='searchReset' icon='reload'>重置</a-button>&nbsp;&nbsp;
              <a-button type='primary' @click="viewCoupon('add')">
            <a-icon type='plus' />
            添加用户群
          </a-button>
            </span>
          </a-col>
        </a-row>
        <a-row>
        </a-row>

      </a-form>
    </div>

    <div class='table-page-search-wrapper'>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :loading='loading'
        bordered>
        <span slot='action' slot-scope='text, record'>
          <a @click="viewCoupon('edit', record)">
            编辑
          </a>
        </span>
      </a-table>
    </div>
    <a-modal
      :title='modalTitle'
      v-model='visible'
      @ok='submitForm'
    >
      <a-form :form='basicForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
        <a-form-item :label="'用户群名称'">
          <a-input v-model='basicForm.groupName' />
        </a-form-item>
        <a-form-item :label="'用户群状态'">
          <a-switch v-model='basicForm.status' />
        </a-form-item>
<!--        <a-form-item :label="'用户群类型'">-->
<!--          <a-select-->
<!--            v-model='basicForm.type'-->
<!--          >-->
<!--            <a-select-option value='1'>-->
<!--              优惠卷-->
<!--            </a-select-option>-->
<!--            <a-select-option value='2'>-->
<!--              短信-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-item>-->
        <a-form-item :label="'用户群Sql'">
          <a-textarea v-model='basicForm.groupSql' rows='5' />
        </a-form-item>
        <a-form-item :label="'用户群描述'">
          <a-textarea v-model='basicForm.remark' />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import { listSendGroupAll, editSendGroup } from '@/webpublicapi/operation'

export default {

  data() {
    return {
      loading: false,
      visible: false,
      modalTitle: undefined,
      dataSource: [],
      columns: [
        {
          title: '用户群ID',
          align: 'left',
          dataIndex: 'id'
        },
        {
          title: '用户群名称',
          align: 'left',
          dataIndex: 'groupName'
        },
        {
          title: '状态',
          align: 'left',
          dataIndex: 'status',
          customRender: (text) => {
            return text === '1' ? '开启' : '关闭'
          }
        },
        {
          title: '类型',
          align: 'left',
          dataIndex: 'type',
          customRender: (text) => {
            return text === 1 ? '优惠卷' : '短信'
          }
        },
        {
          title: '更新时间',
          align: 'left',
          dataIndex: 'updatedTime'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      form: {
        id: '',
        groupName: '',
        status: '',
        type: '1'
      },
      defaultBasicForm: {
        id: '',
        groupName: '',
        status: true,
        type: '1',
        remark: '',
        groupSql: ''
      },
      basicForm: {}
    }
  },


  created() {
    this.basicForm = { ...this.defaultBasicForm }
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.search(this.form)
    },
    searchReset() {
      this.form.id = ''
      this.form.groupName = ''
      this.form.status = ''
      this.form.type = ''
    },
    search() {

      const form = {
        id: this.form.id === '' ? undefined : this.form.id,
        groupName: this.form.groupName === '' ? undefined : this.form.groupName,
        status: this.form.status === '' ? undefined : this.form.status,
        type: this.form.type === '' ? undefined : this.form.type
      }
      this.loading = true
      listSendGroupAll(form).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },
    viewCoupon(mode, coupon = {}) {
      this.visible = true
      this.basicForm.id = coupon.id
      if (mode == 'edit') {
        this.basicForm = {
          ...this.basicForm,
          id: coupon.id,
          groupName: coupon.groupName,
          status: coupon.status === '1',
          type: coupon.type + "",
          remark: coupon.remark,
          groupSql: coupon.groupSql
        }
      } else {
        this.basicForm = { ...this.defaultBasicForm }
      }
      this.modalTitle = mode == 'add' ? '添加用户群' : '修改用户群'
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    submitForm() {
      this.visible = false
      this.loading = true
      const form = {
        ...this.basicForm,
        status: this.basicForm.status ? 1 : 2
      }

      editSendGroup(form).then(res => {
        this.loading = false
        this.$message.success('操作成功')
        this.search(1)
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    }
  }
}
</script>
