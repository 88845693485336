import { axios } from '@/utils/request'

export function getAllDailyCollectRate (parameter = {}) {
  return axios({
    url: '/dailyCollectRate/list',
    method: 'post',
    data: parameter
  })
}

export function getCollectRate (parameter = {}) {
  return axios({
    url: '/collectRate/list',
    method: 'post',
    data: parameter
  })
}

export function getCollectRateTotal (parameter = {}) {
  return axios({
    url: '/collectRate/totalSum',
    method: 'post',
    data: parameter
  })
}

export function getFirstDayCollectRate (parameter = {}) {
  return axios({
    url: '/collectFirstDayRate/list',
    method: 'post',
    data: parameter
  })
}

export function getFirstDayCollectRateTotal (parameter = {}) {
  return axios({
    url: '/collectFirstDayRate/totalSum',
    method: 'post',
    data: parameter
  })
}

export function getPromotionReport (parameter = {}) {
  return axios({
    url: '/promotionReport/list',
    method: 'post',
    data: parameter
  })
}

export function getPromotionReportTotal (parameter = {}) {
  return axios({
    url: '/promotionReport/listTotal',
    method: 'post',
    data: parameter
  })
}