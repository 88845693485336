<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-row>
        <a-col :md="2" :sm="24">
          <a-button style="margin-bottom: 10px" type="primary" @click="handleConfigAdd">添加配置</a-button>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        bordered
      >
        <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
            </span>

      </a-table>
    </div>
    <riskRuleAddModal ref="riskRuleAddModal" @ok="modalFormOk"></riskRuleAddModal>
    <riskRuleEditModal ref="riskRuleEditModal" @ok="modalFormOk"></riskRuleEditModal>

  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import Dict from '../modules/Dict'
import {riskStrategyFeatures} from '@/webpublicapi/risk'
import riskRuleAddModal from './riskRuleAdd'
import riskRuleEditModal from './riskRuleEdit'

export default {
  name: 'productItemList',
  components: {Dict, riskRuleAddModal, riskRuleEditModal},
  props: {
    id: {
      type: [String, Number],
      default: undefined,
    }
  },
  data() {
    return {
      dataSource: [],
      loading: false,
      columns: [
        {
          title: '策略ID',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '策略集ID',
          align: 'center',
          dataIndex: 'strategyId'
        },
        {
          title: '特征ID',
          align: 'center',
          dataIndex: 'featuresId'
        },
        {
          title: '是否使用',
          align: 'center',
          dataIndex: 'isDel'
        },
        {
          title: '执行顺序',
          align: 'center',
          dataIndex: 'sortNum'
        },
        {
          title: '交集',
          align: 'center',
          dataIndex: 'andGroup'
        },
        {
          title: '计算规则',
          align: 'center',
          dataIndex: 'rule'
        }, {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: {customRender: 'action'}
        }
      ],
      title: '操作',
      visible: false,
      screenWidth: 1200,
      model: {},
      proId: '',
    }
  },
  created() {
    this.proId = this.id;

    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true


      riskStrategyFeatures(Object.assign({strategyId: this.proId})).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },


    handleEdit(record) {

      this.$refs.riskRuleEditModal.edit(record)
    },
    handleConfigAdd() {
      this.$refs.riskRuleAddModal.add(this.proId)
    },

    // 编辑字典数据
    editProdictConfig() {
      this.$refs.ProdictConfigList.edit(this.proId, this.appSsid)
      this.$refs.ProdictConfigList.title = '添加配置'
    },
    modalFormOk() {
      this.loadData()
    }
  },
  watch: {
    proId(val) {
      this.proId = val
    }
  }
}
</script>
<style scoped>

</style>
