<template>
  <a-spin :spinning="spinning">
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md='24' :sm='24'>
            <template>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                :label="$t(I18N_KEY + 'registerTime')">
                <a-range-picker v-model="queryParam.registerTime" format="YYYY-MM-DD" />
              </a-form-item>
            </template>
          </a-col>

          <a-col :md="24" :sm="24">
            <template>
              <a-form-item  :labelCol="labelCol"
                            :wrapperCol="wrapperCol"
                            :label="$t(I18N_KEY + 'app')" :required='required_true'>
                <a-select v-model="queryParam.appSsid" :placeholder="$t('select')" style="width: 300px;" mode="multiple">
                  <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                  <a-select-option v-for="item in apps" :value="item.appSsid">
                    {{ item.appSsid + '/' + item.appName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </template>
          </a-col>

<!--          <a-col :md="24" :sm="24">-->
<!--            <template>-->
<!--              <a-form-item  :labelCol="labelCol"-->
<!--                            :wrapperCol="wrapperCol"-->
<!--                            :label="$t(I18N_KEY + 'duration')">-->
<!--                <a-select v-model="queryParam.durations" :placeholder="$t('select')" style="width: 300px;" mode="multiple">-->
<!--&lt;!&ndash;                  <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>&ndash;&gt;-->
<!--                  <a-select-option v-for="item in durations" :value="item">-->
<!--                    {{ $t(I18N_KEY + "duration_" + item) }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--                <span></span>-->
<!--              </a-form-item>-->
<!--            </template>-->
<!--          </a-col>-->

          <a-col :md='24' :sm='24' v-if='showDuration'>
            <template>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                :label="$t(I18N_KEY + 'duration')">
                <div :style="{ borderBottom: '1px solid #E9E9E9' }">
                  <a-checkbox
                    v-model:checked="checkAll2"
                    :indeterminate="indeterminate2"
                    @change='onCheckAllChange2'
                  >
                    {{ $t(I18N_KEY + 'allDuration') }}
                  </a-checkbox>
                </div>
                <br />
<!--                <a-checkbox-group v-model:value="queryParam.durations" :options="durations" />-->
                <a-checkbox-group v-model:value="queryParam.durations" >
                    <span v-for="item in durations">
                      <a-checkbox :value="item.value">{{item[fieldName]}}</a-checkbox>
                    </span>
                </a-checkbox-group>
              </a-form-item>
            </template>
          </a-col>

          <a-col :md='24' :sm='24'>
            <template>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                :label="$t(I18N_KEY + 'type')" :required='required_true'>
                <div :style="{ borderBottom: '1px solid #E9E9E9' }">
                  <a-checkbox
                    v-model:checked="checkAll"
                    :indeterminate="indeterminate"
                    @change='onCheckAllChange'
                  >
                    {{ $t(I18N_KEY + 'allTypes') }}
                  </a-checkbox>
                </div>
                <br />
                <a-checkbox-group v-model:value="queryParam.types">
<!--                  <a-row :gutter="24" v-for='item in types' style='margin-bottom: 10px'>-->
<!--                    <a-col :span='12'>-->
<!--                      <a-checkbox :value='item.value'>{{$t(I18N_KEY + "type_" + item.label)}}</a-checkbox>-->
<!--                    </a-col>-->
<!--                    <a-col :span='12'>-->
<!--                      <a-input v-model:value="getIndex(item.value)" placeholder="$t(I18N_KEY + 'assignDefaultShow')"/>-->
<!--                    </a-col>-->
<!--                  </a-row>-->
                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("1")'>
                    <a-col :span="18">
                      <a-checkbox value="1" @change='queryCount("1")'>{{$t(I18N_KEY + "type_1")}}</a-checkbox>
                      <span>({{count1}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('1')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value1" :placeholder="0"/>
                    </a-col>
                  </a-row>
                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("2")'>
                    <a-col :span="18">
                      <a-checkbox value="2" @change='queryCount("2")'>{{$t(I18N_KEY + "type_2")}}</a-checkbox>
                      <span>({{count2}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('2')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value2" :placeholder="0"/>
                    </a-col>
                  </a-row>
                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("3")'>
                    <a-col :span="18">
                      <a-checkbox value="3" @change='queryCount("3")'>{{$t(I18N_KEY + "type_3")}}</a-checkbox>
                      <span>({{count3}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('3')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value3" :placeholder="0"/>
                    </a-col>
                  </a-row>
                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("4")'>
                    <a-col :span="18">
                      <a-checkbox value="4" @change='queryCount("4")'>{{$t(I18N_KEY + "type_4")}}</a-checkbox>
                      <span>({{count4}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('4')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value4" :placeholder="0"/>
                    </a-col>
                  </a-row>
                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("5")'>
                    <a-col :span="18">
                      <a-checkbox value="5" @change='queryCount("5")'>{{$t(I18N_KEY + "type_5")}}</a-checkbox>
                      <span>({{count5}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('5')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value5" :placeholder="0"/>
                    </a-col>
                  </a-row>
                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("6")'>
                    <a-col :span="18">
                      <a-checkbox value="6" @change='queryCount("6")'>{{$t(I18N_KEY + "type_6")}}</a-checkbox>
                      <span>({{count6}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('6')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value6" :placeholder="0"/>
                    </a-col>
                  </a-row>
                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("10")'>
                    <a-col :span="18">
                      <a-checkbox value="10" @change='queryCount("10")'>
                        {{$t(I18N_KEY + "type_10_pre")}}
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.hours10" style='width:40px'/>
                        -
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.hours10end" style='width:40px'/>
                        {{$t(I18N_KEY + "type_10_suf")}}
                      </a-checkbox>
                      <span>({{count10}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('10')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value10" :placeholder="0"/>
                    </a-col>
                  </a-row>

                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("13")'>
                    <a-col :span="18">
                      <a-checkbox value="13" @change='queryCount("13")'>
                        {{$t(I18N_KEY + "type_13_pre")}}
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays13" style='width:40px' :disabled='disabled'/>
                        -
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays13end" style='width:40px' :disabled='disabled'/>
                        {{$t(I18N_KEY + "type_13_suf")}}
                      </a-checkbox>
                      <span>({{count13}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('13')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value13" :placeholder="0"/>
                    </a-col>
                  </a-row>
                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("14")'>
                    <a-col :span="18">
                      <a-checkbox value="14" @change='queryCount("14")'>
                        {{$t(I18N_KEY + "type_14_pre")}}
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays14" style='width:40px' :disabled='disabled'/>
                        -
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays14end" style='width:40px' :disabled='disabled'/>
                        {{$t(I18N_KEY + "type_14_suf")}}
                      </a-checkbox>
                      <span>({{count14}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('14')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value14" :placeholder="0"/>
                    </a-col>
                  </a-row>

                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("7")'>
                    <a-col :span="18">
                      <a-checkbox value="7" @change='queryCount("7")'>{{$t(I18N_KEY + "type_7")}}</a-checkbox>
                      <span>({{count7}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('7')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value7" :placeholder="0"/>
                    </a-col>
                  </a-row>
                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("11")'>
                    <a-col :span="18">
                      <a-checkbox value="11" @change='queryCount("11")'>
                        {{$t(I18N_KEY + "type_11_pre")}}
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays11" style='width:40px' :disabled='disabled'/>
                        -
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays11end" style='width:40px'/>
                        {{$t(I18N_KEY + "type_11_suf")}}
                      </a-checkbox>
                      <span>({{count11}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('11')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value11" :placeholder="0"/>
                    </a-col>
                  </a-row>

                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("12")'>
                    <a-col :span="18">
                      <a-checkbox value="12" @change='queryCount("12")'>
                        {{$t(I18N_KEY + "type_12_pre")}}
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.minutes12" style='width:40px'/>
                        {{$t(I18N_KEY + "type_12_suf")}}
                      </a-checkbox>
                      <span>({{count12}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('12')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value12" :placeholder="0"/>
                    </a-col>
                  </a-row>

                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("8")'>
                    <a-col :span="18">
                      <a-checkbox value="8" @change='queryCount("8")'>
                        {{$t(I18N_KEY + "type_8_pre")}}
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays8" style='width:40px'/>
                        -
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays8end" style='width:40px'/>
                        {{$t(I18N_KEY + "type_8_suf")}}
                      </a-checkbox>
                      <span>({{count8}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('8')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value8" :placeholder="0"/><!--$t(I18N_KEY + 'assignDefaultShow')-->
                    </a-col>
                  </a-row>

                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("9")'>
                    <a-col :span="18">
                      <a-checkbox value="9" @change='queryCount("9")'>
                        {{$t(I18N_KEY + "type_9_pre")}}
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays9" style='width:40px'/>
                        -
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preDays9end" style='width:40px'/>
                        {{$t(I18N_KEY + "type_9_suf")}}
                      </a-checkbox>
                      <span>({{count9}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('9')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value9" :placeholder="0"/><!--$t(I18N_KEY + 'assignDefaultShow')-->
                    </a-col>
                  </a-row>

                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("15")'>
                    <a-col :span="18">
                      <a-checkbox value="15" @change='queryCount("15")'>
                        {{$t(I18N_KEY + "type_15_pre")}}
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preHour15" style='width:40px' :disabled='disabled'/>
                        {{$t(I18N_KEY + "type_15_suf")}}
                      </a-checkbox>
                      <span>({{count15}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('15')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value15" :placeholder="0"/><!--$t(I18N_KEY + 'assignDefaultShow')-->
                    </a-col>
                  </a-row>

                  <a-row :gutter="24" style='margin-bottom: 10px' v-if='hasType("16")'>
                    <a-col :span="18">
                      <a-checkbox value="16" @change='queryCount("16")'>
                        {{$t(I18N_KEY + "type_16_pre")}}
                        <a-input type="text" icon="reload" size='small' v-model:value="queryParam.preHour16" style='width:40px' :disabled='disabled'/>
                        {{$t(I18N_KEY + "type_16_suf")}}
                      </a-checkbox>
                      <span>({{count16}})</span>
                    </a-col>
                    <a-col :span="2">
                      <a-button type="text" @click="queryCount('16')" icon="reload" size='small'/>
                    </a-col>
                    <a-col :span="4">
                      <a-input v-model:value="queryParam.value16" :placeholder="0"/><!--$t(I18N_KEY + 'assignDefaultShow')-->
                    </a-col>
                  </a-row>
                </a-checkbox-group>

              </a-form-item>
            </template>
          </a-col>

          <a-col :md='24' :sm='24'>
            <template>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                :label="$t(I18N_KEY + 'teleMarketUser')" :required='required_true'>
                <div :style="{ borderBottom: '1px solid #E9E9E9' }">
                  <a-checkbox
                    v-model:checked="checkAll1"
                    :indeterminate="indeterminate1"
                    @change='onCheckAllChange1'
                  >
                    {{ $t(I18N_KEY + 'allUsers') }}
                  </a-checkbox>
                </div>
                <br />
                <a-checkbox-group v-model:value="queryParam.users" :options="teleMarketUsers" />
              </a-form-item>
            </template>
          </a-col>

          <a-col :md='24' :sm='24'>
            <template>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                :label="$t(I18N_KEY + 'createGroup')">
                <a-row :gutter="24" style='margin-bottom: 10px'>
                  <a-col :span="2">
                    <a-switch v-model:checked="queryParam.createGroup" @change='changeSwitch'/>
                  </a-col>
                  <a-col :span="10" v-if="viewGroupNameStatus">
                    <a-input v-model:value="queryParam.groupName" :placeholder="$t('enter') + ' '+ $t(I18N_KEY + 'groupName')" />
                  </a-col>
                </a-row>
              </a-form-item>
            </template>
          </a-col>

          <a-col :md="24" :sm="24">
            <span style="text-align:center;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="confirm">{{ $t('confirm') }}</a-button>
<!--              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>-->
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
  </a-spin>
</template>
<script>
import { filterObj } from '@/utils/util';
import { manualLoadData, teleMarketUsers, baseData ,dataCount} from '@/webpublicapi/teleMarket'
import moment from 'moment';
import {mapGetters} from "vuex";

const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketAssign',
  data () {
    return {
      I18N_KEY,
      viewGroupNameStatus: true,
      required_true: true,
      spinning: false,
      checkAll: false,
      checkAll1: false,
      checkAll2: false,
      showDuration: false,
      indeterminate: false,
      indeterminate1: false,
      indeterminate2: false,
      types:[],
      apps:[],
      typeValues:[],
      teleMarketUsers: [],
      teleMarketUserValues: [],
      showTypes: [],
      durations1: [],
      durations: [],
      fieldName: 'textCn',
      disabled: true,
      queryParam: {
        types: [],
        users: [],
        registerTime: [],
        appSsid: undefined,
        preDays8: '',
        preDays8end: '',
        preDays9: '',
        preDays9end: '',
        hours10: '',
        hours10end: '',
        preDays11: '3',
        preDays11end: '',
        minutes12: '',
        preDays13: '1',
        preDays13end: '1',
        preDays14: '1',
        preDays14end: '1',
        preHour15: '168',
        preHour16: '168',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
        value7: '',
        value8: '',
        value9: '',
        value10: '',
        value11: '',
        value12: '',
        value13: '',
        value14: '',
        value15: '',
        value16: '',
        createGroup: true,
        groupName: '',
        durations: [],
      },
      count1:'',
      count2:'',
      count3:'',
      count4:'',
      count5:'',
      count6:'',
      count7:'',
      count8:'',
      count9:'',
      count10:'',
      count11:'',
      count12:'',
      count13:'',
      count14:'',
      count15:'',
      count16:'',
      form:{},
      total: 0,
      dataSource: [],
      loading: false,
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    this.loadBaseData();
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
  },
  methods: {
    searchQuery () {
      this.doAssign()
    },
    loadBaseData() {
      // teleMarketUsers({}).then((res) => {
      //   if (res.code === 1000) {
      //     this.teleMarketUsers = res.data;
      //     var teleMarketUserValues = [];
      //     for(var item in this.teleMarketUsers) {
      //       teleMarketUserValues.push(this.teleMarketUsers[item].value)
      //     }
      //     this.teleMarketUserValues = teleMarketUserValues;
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // }).catch(e => {
      //   this.$message.error(e.message);
      // })
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.teleMarketUsers = res.data.systemSeats;
          var teleMarketUserValues = [];
          for(var item in this.teleMarketUsers) {
            teleMarketUserValues.push(this.teleMarketUsers[item].value)
          }
          this.teleMarketUserValues = teleMarketUserValues;
          this.types = res.data.types;
          var typeValues = [];
          for(var item in this.types) {
            typeValues.push(this.types[item].value)
          }
          this.typeValues = typeValues;
          this.apps = res.data.apps;
          // this.followStatuses = res.data.followStatuses;
          this.showTypes = res.data.showTypes;
          this.showDuration = res.data.assignDurationBoxShowSwitch;
          this.durations = res.data.assignDurations;
          if(this.durations.length > 0) {
            for(var s in this.durations) {
              this.durations1.push(this.durations[s].value);
            }
          }
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })

    },
    queryCount(type) {
      var appSsid = this.queryParam.appSsid;
      if(appSsid == undefined) {
        this.$message.error(this.$t(I18N_KEY+ 'appSsidNotice'))
        return;
      }
      if((type === '8' && (this.queryParam.preDays8 == '' || this.queryParam.preDays8end == '' || Number(this.queryParam.preDays8) < 1 || Number(this.queryParam.preDays8end) < 1 || Number(this.queryParam.preDays8) > Number(this.queryParam.preDays8end)) )
        || (type === '9' && (this.queryParam.preDays9 == '' || this.queryParam.preDays9end == '' || Number(this.queryParam.preDays9) < 1 || Number(this.queryParam.preDays9end) < 1 || Number(this.queryParam.preDays9) > Number(this.queryParam.preDays9end)))) {
        this.$message.error(this.$t(I18N_KEY+ 'earlySettleNotice'))
        return;
      }
      if(type === '10' && (this.queryParam.hours10 == '' || Number(this.queryParam.hours10) < 1 || this.queryParam.hours10end == '' || Number(this.queryParam.hours10end) < 1)) {
        this.$message.error(this.$t(I18N_KEY+ 'registerNoApplyNotice'))
        return;
      }
      if(type === '11' && (this.queryParam.preDays11end == '' || Number(this.queryParam.preDays11end) < 3)) {
        this.$message.error(this.$t(I18N_KEY+ 'settleNoConversionNotice'))
        return;
      }
      if(type === '12' && (this.queryParam.minutes12 == '' || Number(this.queryParam.minutes12) < 1)) {
        this.$message.error(this.$t(I18N_KEY+ 'firstSettleNoConversionNotice'))
        return;
      }
      if((type === '13' && (this.queryParam.preDays13 == '' || this.queryParam.preDays13end == '' || Number(this.queryParam.preDays13) < 1 || Number(this.queryParam.preDays13end) < 1 || Number(this.queryParam.preDays13) > Number(this.queryParam.preDays13end)) )
        || (type === '14' && (this.queryParam.preDays14 == '' || this.queryParam.preDays14end == '' || Number(this.queryParam.preDays14) < 1 || Number(this.queryParam.preDays14end) < 1 || Number(this.queryParam.preDays14) > Number(this.queryParam.preDays14end)))) {
        this.$message.error(this.$t(I18N_KEY+ 'settleNoConversionNotice'))
        return;
      }
      if(type === '15' && (this.queryParam.preHour15 == '' || Number(this.queryParam.preHour15) < 1)) {
        this.$message.error(this.$t(I18N_KEY+ 'loanFailedNoRebindCardNotice'))
        return;
      }
      if(type === '16' && (this.queryParam.preHour16 == '' || Number(this.queryParam.preHour16) < 1)) {
        this.$message.error(this.$t(I18N_KEY+ 'loanFailedNoRebindCardNotice'))
        return;
      }
      var param = {
        "type":type,
        "preDays8": this.queryParam.preDays8,
        "preDays8end": this.queryParam.preDays8end,
        "preDays9": this.queryParam.preDays9,
        "preDays9end": this.queryParam.preDays9end,
        "hours10": this.queryParam.hours10,
        "hours10end": this.queryParam.hours10end,
        "preDays11": this.queryParam.preDays11,
        "preDays11end": this.queryParam.preDays11end,
        "minutes12": this.queryParam.minutes12,
        "preDays13": this.queryParam.preDays13,
        "preDays13end": this.queryParam.preDays13end,
        "preDays14": this.queryParam.preDays14,
        "preDays14end": this.queryParam.preDays14end,
        "preHour15": this.queryParam.preHour15,
        "preHour16": this.queryParam.preHour16,
        "appSsid": this.queryParam.appSsid,
        "startTime": this.queryParam.registerTime && this.transformTime(this.queryParam.registerTime[0]),
        "endTime": this.queryParam.registerTime && this.transformTime(this.queryParam.registerTime[1]),
        "durations": this.queryParam.durations,
      }
      if(this.queryParam.registerTime.length === 0) {
        param['startTime'] = ''
        param['endTime']=''
      }
      dataCount(Object.assign({}, param)).then((res) => {
        if (res.code === 1000) {
          var count = res.data;
          switch (type) {
            case '1': this.count1 = count;break;
            case '2': this.count2 = count;break;
            case '3': this.count3 = count;break;
            case '4': this.count4 = count;break;
            case '5': this.count5 = count;break;
            case '6': this.count6 = count;break;
            case '7': this.count7 = count;break;
            case '8': this.count8 = count;break;
            case '9': this.count9 = count;break;
            case '10': this.count10 = count;break;
            case '11': this.count11 = count;break;
            case '12': this.count12 = count;break;
            case '13': this.count13 = count;break;
            case '14': this.count14 = count;break;
            case '15': this.count15 = count;break;
            case '16': this.count16 = count;break;
          }
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    checkGroupName() {
      if(this.queryParam.createGroup
        && (this.queryParam.groupName == undefined
          || this.queryParam.groupName == ''
          || this.queryParam.groupName == null)) {
        return true;
      }
      return false;
    },
    checkUsers() {
      if(this.queryParam.users == undefined
        || this.queryParam.users == null
        || this.queryParam.users.length <= 0) {
        return true;
      }
      return false;
    },
    doAssign() {
      if(this.checkGroupName()) {
        this.$message.error(this.$t(I18N_KEY + 'noGroupNameNotice'))
        return;
      }
      if(this.checkUsers()) {
        this.$message.error(this.$t(I18N_KEY + 'noSelectUserNotice'))
        return;
      }
      this.spinning = true
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      manualLoadData(Object.assign({}, params)).then((res) => {
        this.spinning = false
        if (res.code === 1000) {
          this.$message.info("success")
        } else {
          var msg = res.msg;
          if(msg === 'earlySettleNotice') {
            this.$message.error(this.$t(I18N_KEY + 'earlySettleNotice'))
          } else if(msg === 'noGroupNameNotice') {
            this.$message.error(this.$t(I18N_KEY + 'noGroupNameNotice'))
          } else if(msg === 'noTypeCountNotice'){
            this.$message.error(this.$t(I18N_KEY + 'noTypeCountNotice'))
          } else if(msg === 'registerNoApplyNotice') {
            this.$message.error(this.$t(I18N_KEY + 'registerNoApplyNotice'))
          } else if(msg === 'settleNoConversionNotice') {
            this.$message.error(this.$t(I18N_KEY + 'settleNoConversionNotice'))
          } else if(msg === 'firstSettleNoConversionNotice') {
            this.$message.error(this.$t(I18N_KEY + 'firstSettleNoConversionNotice'))
          } else if(msg === 'loanFailedNoRebindCardNotice') {
            this.$message.error(this.$t(I18N_KEY + 'loanFailedNoRebindCardNotice'))
          } else {
            this.$message.error(res.msg)
          }
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.spinning = false
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const { registerTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startTime: registerTime && this.transformTime(registerTime[0]),
        endTime: registerTime && this.transformTime(registerTime[1]),
      }
      // var registerTime1 = this.queryParam.registerTime;
      if(this.queryParam.registerTime.length === 0) {
        data['startTime'] = ''
        data['endTime']=''
      }
      console.log(data)
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.users = ''
      that.queryParam.registerTime = undefined
      that.queryParam.types = '';
    },
    onCheckAllChange(){
      this.checkAll = this.checkAll?true:false;
      this.queryParam.types = this.checkAll?this.typeValues:[];
    },
    onCheckAllChange1(){
      this.checkAll1 = this.checkAll1?true:false;
      this.queryParam.users = this.checkAll1?this.teleMarketUserValues:[];
    },
    onCheckAllChange2(){
      this.checkAll2 = this.checkAll2?true:false;
      this.queryParam.durations = this.checkAll2?this.durations1:[];
    },
    changeSwitch(checked, number, event){
      if(checked){
        this.viewGroupNameStatus = true;
      } else {
        this.viewGroupNameStatus = false;
      }
    },
    hasType(type) {
      if(this.showTypes == []) {
        return true;
      }
      if(this.showTypes.length > 0) {
        for (var t in this.showTypes) {
          if(this.showTypes[t] == type) {
            return true;
          }
        }
      }
      return false;
    },
  },
  watch: {
    "queryParam.durations"(oldValue, newValue){
      this.indeterminate2 = !!this.queryParam.durations.length && this.queryParam.durations.length < this.durations.length;
      this.checkAll2 = this.queryParam.durations.length === this.durations.length;
    },
    "queryParam.users"(oldValue, newValue) {
      this.indeterminate1 =  !!this.queryParam.users.length && this.queryParam.users.length < this.teleMarketUsers.length;
      this.checkAll1 = this.queryParam.users.length === this.teleMarketUsers.length;
    },
    "queryParam.types"(oldValue, newValue){
      this.indeterminate = !!this.queryParam.types.length && this.queryParam.types.length < this.types.length;
      this.checkAll = this.queryParam.types.length === this.types.length;
    },
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>