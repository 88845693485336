import {axios} from '@/utils/request'

export function getRiskStatistics(parameter = {}) {
  return axios({
    url: '/risk/riskStatistics',
    method: 'post',
    data: parameter
  })
}

export function dailyDecisionStatistics(parameter = {}) {
  return axios({
    url: '/risk/dailyDecisionStatistics',
    method: 'post',
    data: parameter
  })
}

export function getRiskStatisticsById(parameter = {}) {
  return axios({
    url: '/risk/riskStatisticsById',
    method: 'post',
    data: parameter
  })
}

export function riskStrategyList({name, intro, pageNum, pageSize} = {}) {
  return axios({
    url: '/risk/riskStrategyList',
    method: 'post',
    data: {name, intro, pageNum, pageSize}
  })
}

//查询规则：规则码，说明模糊查询
export function riskFeaturesList({name, intro, pageNum, pageSize} = {}) {
  return axios({
    url: '/risk/riskFeaturesList',
    method: 'post',
    data: {name, intro, pageNum, pageSize}
  })
}

//修改规则默认值
export function updateRiskFeature({id, defaultValue} = {}) {
  return axios({
    url: '/risk/updateRiskFeature',
    method: 'post',
    data: {id, defaultValue}
  })
}

//规则使用情况
export function riskFeaturesUsed({featuresId} = {}) {
  return axios({
    url: '/risk/riskFeaturesUsed',
    method: 'post',
    data: {featuresId}
  })
}

//基于规则批量启用禁用决策集
export function banRiskFeatures({featureId, type, idList} = {}) {
  return axios({
    url: '/risk/banRiskFeatures',
    method: 'post',
    data: {featureId, type, idList}
  })
}

//策略决策集查询：不分页前台筛选
export function riskStrategyFeatures({strategyId} = {}) {
  return axios({
    url: '/risk/riskStrategyFeatures',
    method: 'post',
    data: {strategyId}
  })
}

//编辑决策
export function editRiskRule({id, newRule} = {}) {
  return axios({
    url: '/risk/editRiskRule',
    method: 'post',
    data: {id, newRule}
  })
}

//批量添加决策
export function addRiskRule({strategyId, idList = []} = {}) {
  return axios({
    url: '/risk/addRiskRule',
    method: 'post',
    data: {strategyId, idList}
  })
}

//发布决策集
export function releaseRiskRule({strategyId} = {}) {
  return axios({
    url: '/risk/releaseRiskRule',
    method: 'post',
    data: {strategyId}
  })
}

//删除决策
export function deleteRiskRule({strategyId, idList = []} = {}) {
  return axios({
    url: '/risk/deleteRiskRule',
    method: 'post',
    data: {strategyId, idList}
  })
}

//编辑决策：决策顺序、交集标示
export function editRiskRuleAndGroup(data) {
  return axios({
    url: '/risk/editRiskRuleAndGroup',
    method: 'post',
    data
  })
}

//创建策略集
export function addRiskStrategy({name, intro} = {}) {
  return axios({
    url: '/risk/addRiskStrategy',
    method: 'post',
    data: {name, intro}
  })
}

//复制策略集
export function copyRiskStrategy({id, name, intro} = {}) {
  return axios({
    url: '/risk/copyRiskStrategy',
    method: 'post',
    data: {id, name, intro}
  })
}