<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="角色名称">
          <a-input placeholder="请输入角色名称" v-decorator="[ 'roleName', validatorRules.roleName]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="角色英文名称">
          <a-input placeholder="请输入角色英文名称" v-decorator="[ 'roleNameEn', validatorRules.roleName]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="角色类型">
          <a-radio-group
              v-decorator="['type', { initialValue: '1' }]">
              <a-radio value="1">系统角色</a-radio>
              <a-radio value="2">应用角色</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="角色编码">
          <a-input placeholder="请输入角色编码" :disabled="roleDisabled" v-decorator="[ 'roleCode', validatorRules.roleCode]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="角色描述">
          <a-textarea :rows="5" placeholder="请输入" v-decorator="[ 'roleDesc', validatorRules.roleDesc ]" />
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
  import pick from 'lodash.pick'

  export default {
    name: "RoleModal",
    data () {
      return {
        title:"操作",
        visible: false,
        roleDisabled: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        form: this.$form.createForm(this),
        validatorRules:{
          roleName:{
            rules: [
              { required: true, message: '请输入角色名称!' },
          ]},
          roleNameEn:{
            rules: [
              { required: true, message: '请输入角色英文名称!' },
          ]},
          roleCode:{
            rules: [
              { required: true, message: '请输入角色编码!'},
          ]},
          roleDesc:{
            rules: [
          ]}
        },
      }
    },
    created () {
    },
    methods: {
      add () {
        this.edit({});
      },
      edit (record) {
        this.form.resetFields();
        this.open();
        console.log(1111, pick(record,'roleName', 'roleNameEn', 'type', 'roleCode', 'roleDesc','roleCode'));
        const data = pick(record,'roleName', 'type', 'roleCode', 'roleDesc','roleCode')
        this.model = {
          ...this.model,
          ...data,
          type: data.type != undefined ? '' + data.type : '1',
        }
        
        this.$nextTick(() => {
          this.form.setFieldsValue(this.model)
        });

      },
      open () {
        this.visible = true;
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      openLoading () {
          this.confirmLoading = true;
      },
      closeLoading () {
        this.confirmLoading = false;
      },
      handleOk () {
        console.log(88888)
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let formData = {
              ...values,
            }
            this.$emit('ok', formData);

          }
        })
      },
      handleCancel () {
        this.close()
      },
      validateRoleCode(rule, value, callback){
        if(/[\u4E00-\u9FA5]/g.test(value)){
          callback("角色编码不可输入汉字!");
        }else{
          var params = {
            tableName: "sys_role",
            fieldName: "role_code",
            fieldVal: value,
            dataId: this.model.id,
          };
          duplicateCheck(params).then((res)=>{
            if(res.success){
              callback();
            }else{
              callback(res.message);
            }
          });
        }
      }

    }
  }
</script>

<style scoped>

</style>