<template>
  <div class="mainBox">
    <div class="left">Welcome Dashboard!</div>
    <div class="right">
      <div class="rightBox">
        <div class="title">Dashboard</div>
        <a-form
          id="formLogin"
          class="user-layout-login"
          ref="formLogin"
          :form="form"
          @submit="handleSubmit"
        >
          <a-form-item>
            <a-input
              size="large"
              type="text"
              :placeholder="$t('userName')"
              v-decorator="[
              'username',
              {rules: [{ required: true, message: $t('home.userNameTips') }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
            ]"
            >
              <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input
              size="large"
              type="password"
              autocomplete="false"
              :placeholder="$t('password')"
              v-decorator="[
              'password',
              {rules: [{ required: true, message: $t('home.passwordTips') }], validateTrigger: 'blur'}
            ]"
            >
              <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-item>
          <div>
            <a @click="SwitchLang">{{ $i18n.locale == lang.zhCN ? '使用中文' : 'Use English' }}</a>
          </div>
          <a-form-item style="margin-top:24px">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="login-button"
              :loading="state.loginBtn"
              :disabled="state.loginBtn"
            >{{ $t('confirm') }}</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <two-step-captcha
      v-if="requiredTwoStepCaptcha"
      :visible="stepCaptchaVisible"
      @success="stepCaptchaSuccess"
      @cancel="stepCaptchaCancel"
    ></two-step-captcha>
  </div>
</template>

<script>
// import md5 from 'md5'
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getSmsCaptcha, get2step } from '@/api/login'
import lang from '../../i18n/lang'
import i18n from '../../i18n'

export default {
  components: {
    TwoStepCaptcha
  },
  data() {
    return {
      lang,
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      redirect: undefined
    }
  },
  computed: {},
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    // this.requiredTwoStepCaptcha = true
  },
  mounted() {},
  methods: {
    ...mapActions(['Login', 'Logout']),
    ...mapActions('language', ['setLanguage']),
    SwitchLang() {
      const key = this.$i18n.locale == lang.zhCN ? lang.enUS : lang.zhCN
      this.$i18n.locale = key
      this.setLanguage(key)
    },
    // handler
    handleUsernameOrEmail(rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleTabClick(key) {
      this.customActiveKey = key
      // this.form.resetFields()
    },
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        customActiveKey,
        Login
      } = this

      state.loginBtn = true

      const validateFieldsKey = customActiveKey === 'tab1' ? ['username', 'password'] : ['mobile', 'captcha']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = {
            loginName: values.username,
            password: values.password
          }
          Login(loginParams)
            .then(res => this.loginSuccess(res))
            .catch(err => {
              this.requestFailed(err)
            })
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getCaptcha(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state
      } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = this.$message.loading('验证码发送中..', 0)
          getSmsCaptcha({ mobile: values.mobile })
            .then(res => {
              setTimeout(hide, 2500)
              this.$notification['success']({
                message: '提示',
                description: '验证码获取成功，您的验证码为：' + res.result.captcha,
                duration: 8
              })
            })
            .catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 60
              state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    stepCaptchaSuccess() {
      this.loginSuccess()
    },
    stepCaptchaCancel() {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    loginSuccess(res) {
      window.location.reload()
      const self = this
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        const message = i18n.tc('home.welcome')
        const description = `${i18n.tc(timeFix())}, ${i18n.tc('home.welcomeBack')}`
        this.$notification.success({
          message,
          description
        })
      }, 1000)
      this.isLoginError = false
    },
    requestFailed(err) {
      const self = this
      this.isLoginError = true
      this.$notification['error']({
        message: self.$t('error'),
        description: err.message || self.$t('requestFail'),
        duration: 4
      })
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0px;
  padding: 0px;
}
.ant-btn-primary{
  background: #cdaa7d !important;
  border-color: #cdaa7d !important;
}
a{
  color:#cdaa7d !important;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #cdaa7d !important;
    border-right-width: 1px !important;
}
.mainBox {
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important;
  background-image: url('../../assets/loginImg/Background_Login.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px !important;
  display: flex;
  .right {
    width: 460px;
    max-width: 100%;
    background: #fff;
    height: 100%;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .rightBox {
      width: 100%;
      .title {
        color: #cdaa7d;
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 100px;
      }
    }
  }
  .left {
    flex: 1;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;
    padding-top: 24%;
    font-size: 70px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
  }
 
}
 @media screen and (max-width: 400px) {
    .left{
      display: none;
    }
  }
.ant-form-item {
  margin-bottom: 40px;
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #cdaa7d;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
