import { render, staticRenderFns } from "./asInfoExport.vue?vue&type=template&id=630b1991&scoped=true&"
import script from "./asInfoExport.vue?vue&type=script&lang=js&"
export * from "./asInfoExport.vue?vue&type=script&lang=js&"
import style0 from "./asInfoExport.vue?vue&type=style&index=0&id=630b1991&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630b1991",
  null
  
)

export default component.exports