<template>
  <a-modal :title="modal.title" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @ok="submit"
           @cancel="hide">
    <a-card v-for="(item,index) in forms">
      <div style="display: flex;justify-content: space-between">
        <a-tag v-if="item.isDel" color="red">删除</a-tag>
        <a-tag v-else-if="item.isAdd || (item.newAndGroup && !item.andGroup && !item.rule)" color="green">新增</a-tag>
        <a-tag v-else color="blue">生效</a-tag>
        <a-icon v-if="item.isDel" type="undo" @click="()=>handleUndo(item,index)"/>
        <a-icon v-else type="close" @click="()=>handleDel(item,index)"/>
      </div>
      <a-form :form="item.form" layout="inline">
        <a-form-item v-if="item.isAdd" label="决策规则">
          <a-select :options="options.features" v-decorator="['featuresId',{rules:[{required:true,message:'请选择决策规则'}]}]"
                    style="width: 171px;"></a-select>
        </a-form-item>
        <template v-else>
          <a-form-item label="规则码">
            {{ item.featuresName }}
          </a-form-item>
          <a-form-item label="规则说明">
            {{ item.featuresIntro }}
          </a-form-item>
        </template>
        <a-form-item label="计算规则">
          <a-input
            v-decorator="['newRule',{rules:[{required:true,message:'策略说明不能为空'},{pattern:/^(?:true|false|(?:\[missing]|\[&quot;.*&quot;]|[(\[](?:-Inf|missing|-?\d+(?:.\d+)?),(?:Inf|missing|-?\d+(?:.\d+)?)[)\]])(?:U(?:\[missing]|\[&quot;.*&quot;]|[(\[](?:-Inf|missing|-?\d+(?:.\d+)?),(?:Inf|missing|-?\d+(?:.\d+)?)[)\]]))*)$/,message:'规则格式错误'}]}]"></a-input>
        </a-form-item>
        <a-form-item label="执行顺序">
          <a-input type="number" v-decorator="['newSortNum',{rules:[{required:true,message:'策略说明不能为空'}]}]"></a-input>
        </a-form-item>
        <a-form-item label="启用">
          <a-switch type="number" v-decorator="['newPick', { valuePropName: 'checked' }]"></a-switch>
        </a-form-item>
      </a-form>
    </a-card>
    <div style="display:flex;justify-content:center;padding: 8px;">
      <a-button icon="plus" @click="handleAdd">添加</a-button>
    </div>
  </a-modal>
</template>
<script>
import {addRiskStrategy, copyRiskStrategy, riskFeaturesList, editRiskRuleAndGroup} from "@/webpublicapi/risk";

export default {
  name: 'RuleUseEditModal',
  data() {
    return {
      modal: {title: '编辑决策', visible: false, confirmLoading: false},
      forms: [],
      model: {},
      options: {features: []}
    }
  },
  methods: {
    show(strategyId, list) {
      this.model = {strategyId}
      this.forms = list.map(({id, featuresName, featuresIntro, rule, sortNum, pick, andGroup, newRule, newSortNum, newPick, newIsDel, newAndGroup}) => ({
        id, featuresName, featuresIntro, isDel: newIsDel, andGroup, newAndGroup, rule,
        form: this.$form.createForm(this, {
          mapPropsToFields: () => {
            return {
              newRule: this.$form.createFormField({value: newRule === undefined ? rule : newRule}),
              newSortNum: this.$form.createFormField({value: newSortNum === undefined ? sortNum : newSortNum}),
              newPick: this.$form.createFormField({value: newPick === undefined ? pick === 0 : newPick === 0})
            }
          }
        })
      }))
      this.modal.visible = true
    },
    hide() {
      this.modal.visible = false
      this.modal.confirmLoading = false
      this.forms = []
    },
    handleAdd() {
      this.forms.push({
        isAdd: true,
        form: this.$form.createForm(this, {
          mapPropsToFields: () => {
            return {
              featuresId: this.$form.createFormField({value: undefined}),
              newRule: this.$form.createFormField({value: ''}),
              newSortNum: this.$form.createFormField({value: 50}),
              newPick: this.$form.createFormField({value: true})
            }
          }
        })
      })
    },
    handleDel(item, index) {
      if (item.isAdd) {
        this.forms.splice(index, 1)
      } else {
        item.isDel = true
      }
    },
    handleUndo(item) {
      item.isDel = false
    },
    submit() {
      this.modal.confirmLoading = true;
      Promise.all(this.forms.map(({form, id, isDel}) => new Promise((resolve, reject) => {
        form.validateFields((errors, values) => {
          if (errors) {
            reject()
          } else {
            resolve({
              id,
              strategyId: this.model.strategyId,
              newIsDel: isDel ? 1 : 0, ...values,
              newPick: values.newPick ? 0 : 1
            })
          }
        })
      }))).then((values) => editRiskRuleAndGroup(values).then(({code, msg}) => {
        if (code === 1000) {
          this.$emit('success')
          this.hide()
        } else {
          return Promise.reject(msg)
        }
      })).catch((message) => {
        this.$message.error(message)
        this.modal.confirmLoading = false;
      })
    }
  },
  created() {
    riskFeaturesList({pageNum: 1, pageSize: 999999999}).then(({code, data}) => {
      if (code === 1000) {
        this.options.features = data.rows.map(({id, intro}) => ({label: intro, value: id}))
      }
    })
  }
}
</script>