<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        bordered>
        <Dict slot="repaymentMethod" slot-scope="text" :dict-code="DICT_CODE.REPAYMENT_METHOD" :value="text"/>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">
            编辑
          </a>
        </span>
      </a-table>
    </div>
    <v-modal
        v-model="visible"
        title="产品详情">
        <div style="padding: 20px;">
            <v-product-item
              v-if="visible"
              :id="productId"
              :appSsid="appSsid"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </a-card>
</template>

<script>
import { getProductList } from '@/api/operation'
import Dict from '../modules/Dict'
import vModal from '../modules/Modal';
import vProductItem from './ProductItemList.vue'

export default {
  name: 'ProductManagement',
  components: { Dict, vModal, vProductItem },
  data () {
    return {
      visible: false,
      dataSource: [],
      loading: false,
      productId: undefined,
      visible: false,
      // 表头
      columns: [
        {
          title: '产品名称',
          align: 'left',
          dataIndex: 'productName'
        },
        {
          title: '还款方式',
          align: 'left',
          dataIndex: 'repaymentMethod',
          scopedSlots: { customRender: 'repaymentMethod' }
        },
        {
          title: '状态',
          align: 'left',
          dataIndex: 'status'
        },
         {
          title: '创建时间',
          align: 'left',
          dataIndex: 'createTime'
        },
         {
          title: '更新时间',
          align: 'left',
          dataIndex: 'updateTime'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.loading = true
      getProductList({}).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    handleEdit (record) {
      this.productId = record.id;  
      this.appSsid = record.appSsid;
      this.visible = true;
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
