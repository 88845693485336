<template>
  <a-card :bordered="false">

<!--    <div class="table-page-search-wrapper">-->
<!--      <a-form layout='inline' @keyup.enter.native='searchQuery'>-->
<!--        <a-row :gutter="24">-->
<!--          <a-col :md='8' :sm='8'>-->
<!--            <a-form-item :label="'产品类型'">-->
<!--              <a-select-->
<!--                v-model='appssid'-->
<!--              >-->
<!--&lt;!&ndash;                <a-select-option value='' selected>&ndash;&gt;-->
<!--&lt;!&ndash;                  全部&ndash;&gt;-->
<!--&lt;!&ndash;                </a-select-option>&ndash;&gt;-->
<!--                <a-select-option value='-1' selected>-->
<!--                  正常费率-->
<!--                </a-select-option>-->
<!--                <a-select-option value='-1_google'>-->
<!--                  Google费率-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-item>-->

<!--          </a-col>-->
<!--          <a-col :md='2' :sm='24'>-->
<!--              <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>-->
<!--                <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>-->
<!--              </span>-->
<!--          </a-col>-->
<!--        </a-row>-->
<!--      </a-form>-->
<!--    </div>-->
<!--    <div class="table-page-search-wrapper">-->
<!--      <a-row>-->
<!--        <a-col :md="2" :sm="24">-->
<!--          <a-button style="margin-bottom: 10px" type="primary" @click="handleAdd">新增</a-button>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--    </div>-->
    <div>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        bordered>

        <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
            </span>
      </a-table>
    </div>
    <product-fee-config-modal ref="productFeeConfigModal" @ok="modalFormOk"></product-fee-config-modal>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import {getProductFeeConfigFixedList, getProductFeeConfigList} from '@/webpublicapi/operation'
import Dict from '../modules/Dict'
import productFeeConfigModal from './modules/ProductFeeConfigModal'
export default {
  name: 'productItemList',
  components: { Dict,productFeeConfigModal},
  props: {
    id:{
        type: [String, Number],
        default: undefined,
    },
   appSsid:{
        type: [String, Number],
        default: undefined,
    },
  },
  data () {
    return {
      dataSource: [],
      loading: false,
      appssid:'-1_google',
      columns: [
        {
          title: '产品类型',
          align: 'center',
          dataIndex: 'appssid',
          customRender: function (text) {
            if (text==='-1') {
              return "正常费率"
            } else if (text === '-1_google') {
              return "Google费率"
            }
          }
        },
        {
          title: '费率名称',
          align: 'center',
          dataIndex: 'feeName'
        },
        {
          title: '固定金额',
          align: 'center',
          dataIndex: 'feeAmount'
        },
        {
          title: '固定费率',
          align: 'center',
          dataIndex: 'feeRate'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      title: '操作',
      visible: false,
      screenWidth: 1200,
      model: {},
      proId: '',
    }
  },
  created () {
    this.proId = this.id;
    this.loadData()
  },
  methods: {
    loadData () {
      this.searchQuery();
      getProductFeeConfigFixedList(Object.assign({appssid:this.appssid})).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    searchQuery() {
      this.loading = true
      getProductFeeConfigFixedList(Object.assign({appssid:this.appssid})).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    handleAdd () {
      this.$refs.productFeeConfigModal.add(this.proId,this.appSsid)
      this.$refs.productFeeConfigModal.title = '新增'
    },
    handleEdit (record) {
      this.$refs.productFeeConfigModal.edit(record)
      this.$refs.productFeeConfigModal.title = '编辑'
    },
    modalFormOk () {
      this.loadData()
    }
  },
  watch: {
    proId (val) {
      this.proId = val
    }
  }
}
</script>
<style scoped>

</style>
