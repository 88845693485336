import { render, staticRenderFns } from "./CollectionBackPerformance.vue?vue&type=template&id=3dbee9e4&scoped=true&"
import script from "./CollectionBackPerformance.vue?vue&type=script&lang=js&"
export * from "./CollectionBackPerformance.vue?vue&type=script&lang=js&"
import style0 from "./CollectionBackPerformance.vue?vue&type=style&index=0&id=3dbee9e4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbee9e4",
  null
  
)

export default component.exports