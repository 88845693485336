<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="curp">
          <a-input placeholder="请输入curp" v-decorator="[ 'curp']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="退款金额">
          <a-input placeholder="请输入退款金额" v-decorator="[ 'tradeAmt']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="收款账号">
          <a-input placeholder="请输入收款账号" v-decorator="[ 'accountNumber']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="用户姓名">
          <a-input placeholder="请输入用户姓名" v-decorator="[ 'fullName']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="银行卡类型">
          <dict-select
            v-decorator="[ 'accountType']"
            :dict-code="DICT_CODE.BANKACCOUNT_TYPE"
            :placeholder="$t('select')"
          />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="银行卡列表">
          <dict-select
            v-decorator="[ 'bankCode']"
            :dict-code="DICT_CODE.BANKNAMELIST"
            :placeholder="$t('select')"
          />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="密码">
          <a-input placeholder="请输入密码" type="password" v-decorator="[ 'password']"/>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import {refundTrade} from '@/webpublicapi/order'
import DictSelect from '../../modules/DictSelect';
import md5 from "md5";

// duplicateCheck
export default {
  name: 'RefundTradeModal',
  components: {
    DictSelect,
  },
  mounted(){

    if(typeof FileReader=='undifined')        {
      console.log('抱歉，你的浏览器不支持 FileReader');
    }else{
      console.log('FileReader');
    }
  },
  data () {
    return {
      value: 1,
      title: '操作',
      visible: false,
      visiblekey: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        appSsid: { rules: [{ required: true, message: '请输入租户id!'}
          ] },
        // firstApprovalM: { rules: [{ required: true, message: '请输入包名!' }] },
        // reApprovalM: { rules: [{ required: true, message: '请输入logoUrl!' }] },
        // allApprovalM: { rules: [{ required: true, message: '请输入APP下载地址!' }] },
      }
    }
  },
  created () {
  },
  methods: {
    add () {
      this.form.resetFields();
      this.visible = true;
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          values.password = md5(values.password);
          const formData = Object.assign(this.model, values)

          console.log(formData)

          let obj = refundTrade(formData)

          obj.then((res) => {
            if (res.code === 1000) {
              this.$message.success(res.msg)
              this.$emit('ok')
            } else {
              this.$message.warning(res.msg)
            }
          }).finally(() => {
            this.confirmLoading = false
            this.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },

    close () {
      this.$emit('close')
      this.visible = false
    },
  }
}
</script>
