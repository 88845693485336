<template>
    <div>
        <div style="margin-bottom: 50px;">
            <a-form layout='inline'>
                <a-row :gutter='24'>
                    <a-form-item label="客户Id">
                        <a-input v-model='custInfoId' />
                    </a-form-item>
                    <a-form-item><a-button type='primary' @click='search()'>
                            查询</a-button></a-form-item>
                </a-row>

            </a-form>
        </div>
        <div>
            <a-table key="id" ref="table" :columns="columns" :dataSource="dataSource" :pagination="pagination"
                :loading="isLoading" bordered @change="handleTableChange">
                <span slot="StartTime" slot-scope="text, record">
                    <p>{{ timestampToDate(record.StartTime) }}</p>
                </span>
                <span slot="data" slot-scope="text, record">
                    <p v-for="(value, key, index) in record.data" :key="index">{{ key == 'endTime' || key == 'startTime' ?
                        key + " : " + timestampToDate(value) : key +" : " + value }}</p>
                </span>
            </a-table>
        </div>
        <!-- <div class="timeLineBox">
                <div class="timeLineItem" v-for="(item, index) in timeLineData" :key="index">
                    <div class="yuan"></div>
                    <div class="contentText" v-if="item.level == 'page'">{{ item.pageName + " " + item.type + " " +
                        timestampToDate(item.StartTime) }}</div>
                    <div class="contentText" v-if="item.level == 'element'">{{ `${item.elementName} of ${item.pageName}
                        ${item.type} ${timestampToDate(item.StartTime)}` }}</div>
                    <div class="contentText" v-if="item.level == 'application'">{{ item.level + " " + item.type + " " +
                        timestampToDate(item.StartTime) }}</div>
                    <div class="contentText" v-if="item.level == 'input'">{{ `${item.elementName} of ${item.pageName}
                        ${item.type} from ${item.data.startValue}to ${item.data.endVALUE} , parse
                        ${item.data.parseTimes} ,
                        delete ${item.data.deleteTimes} times ${timestampToDate(item.StartTime)}` }}</div>
                </div>
            </div> -->


    </div>

</template>
<script>
import { getUserBehaviorsByParams } from '@/webpublicapi/userBehavior'
import moment from 'moment'
const I18N_KEY = {
    COUPON: 'coupon.',
}

export default {
    data() {
        return {
            I18N_KEY,
            custInfoId: "",//用户Id	
            timeLineData: [],
            isLoading: false,
            // 分页配置
            pagination: {
                current: 1, //默认当前叶数
                pageSize: 20, // 默认每页条数
                showSizeChanger: true, //是否可以改变 pageSize
                pageSizeOptions: ['50', '100', '200', '300', '500'], //可切换每叶数据条数
                total: 0, // 分页中显示总的数据
                showTotal: total => `共 ${total} 条`
            },

            columns: [
                {
                    title: "level",
                    align: 'left',
                    dataIndex: 'level',
                },
                {
                    title: "type",
                    align: 'left',
                    dataIndex: 'type',
                },
                {
                    title: "StartTime",
                    align: 'left',
                    dataIndex: 'StartTime',
                    scopedSlots: { customRender: 'StartTime' },
                },
                {
                    title: "pageName",
                    align: 'left',
                    dataIndex: 'pageName',
                },
                {
                    title: "elementName",
                    align: 'left',
                    dataIndex: 'elementName',
                },
                {
                    title: "data",
                    align: 'left',
                    dataIndex: 'data',
                    scopedSlots: { customRender: 'data' }
                },

            ],
            dataSource: []

        }
    },
    created() {


    },
    methods: {
        search() {
            if (!this.custInfoId) {
                this.$message.error('custInfoId不能为空')
                return
            }
            this.isLoading = true
            let json = {
                "custInfoId": this.custInfoId,
            }
            getUserBehaviorsByParams(json).then(res => {
                if (res.code == 1000) {
                    this.timeLineData = res.data
                    this.timeLineData=[...this.timeLineData].reverse()
                    this.dataSource = this.timeLineData.slice(0, this.pagination.pageSize)
                    this.pagination.total = this.timeLineData.length
                    this.isLoading = false
                } else {
                    this.$message.error(res.msg)
                }

            })
        },
        timestampToDate(timestamp) {
            return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
        },
        // 分页变化时的处理函数
        handleTableChange(pagination, filters, sorter) {
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            pager.pageSize = pagination.pageSize;
            // 更新分页参数
            this.pagination = pager;
            // 根据分页参数过滤数据
            this.dataSource = this.getDataByPage(pager);
        },
        // 根据分页参数获取数据的函数
        getDataByPage({ current, pageSize }) {
            const startIndex = (current - 1) * pageSize;
            return this.timeLineData.slice(startIndex, startIndex + pageSize);
        },

    }
}
</script>
<style>
.timeLineItem {
    position: relative;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-left: 1.5px solid #ceceec;
    padding-left: 24px;
    padding-top: 0px;
}

.yuan {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: 1px solid #1890ff;
    border-radius: 50%;
    position: absolute;
    left: -6px;
    top: -6px;
}

.contentText {
    margin-top: -10px;
}
</style>