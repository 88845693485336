<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item :label="'日期'" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-range-picker
                v-model="queryParam.dataDt"
                format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'产品类型'">
              <a-select
                v-model="queryParam.appssidList"
                mode='multiple'
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="'数据口径'">
              <a-select default-value="" style="width: 190px" v-model="queryParam.dataType">
                <a-select-option value="1">
                  注册当天
                </a-select-option>
                <a-select-option value="2">
                  注册近3天
                </a-select-option>
                <a-select-option value="3">
                  注册近7天
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="downLoadData" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        size="middle"
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="ipagination"
        :scroll="{ x: 2300, y: 2000 }"
        bordered
        @change="handleTableChange"
        @expand="extpand"
      >

        <a-table
          slot="expandedRowRender"
          slot-scope="text"
          ref="table"
          size="middle"
          :columns="innerColumns"
          :data-source="text.dataDetails"
          :loading="loading"
          :pagination="false"
          bordered>

        </a-table>

      </a-table>
    </div>
    <RefundTradeModal ref="modalForm" @ok="modalFormOk"></RefundTradeModal>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getAppAllCount, getApplist, getConvertData } from '@/webpublicapi/order'
import Dict from '../modules/Dict';
import { REFUND_TRADE_STATUS } from '@/constants/status';
import RefundTradeModal from './modules/RefundTradeModal'
import moment from "moment";
import exportFile from "@/webpublicapi/exportFile";
export default {
  name: 'AppList',
  components: {
    RefundTradeModal,
    Dict
  },
  data () {
    return {
      visible: false,
      REFUND_TRADE_STATUS,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },

      headers: {
        // authorization: 'authorization-text',
      },

      dataSource: [],
      innerData: [],
      appList: [],
      // 查询条件
      queryParam: {
        dataDt: [moment().add('days', -60), moment().add('days', 0)],
        appssidList: []
      },
      loading: false,
      // 表头
      columns: [
        {
          title: '日期',
          align: 'left',
          dataIndex: 'dataDt',
        },
        {
          title: 'APP',
          align: 'left',
          dataIndex: 'appName',
        },
        {
          title: '盘号',
          align: 'left',
          dataIndex: 'appSsid',
        },
        {
          title: '安装',
          align: 'left',
          dataIndex: 'installs',
        },
        {
          title: '发送注册OTP号码数',
          align: 'left',
          dataIndex: 'optCount'
        },
        {
          title: '注册',
          align: 'left',
          dataIndex: 'regCount'
        },
        {
          title: '基本信息',
          align: 'left',
          dataIndex: 'basicCount'
        },
        // {
        //   title: '工作信息',
        //   align: 'left',
        //   dataIndex: 'workCount'
        // },
        {
          title: '联系人',
          align: 'left',
          dataIndex: 'linkmanCount'
        },
        {
          title: '大JSON',
          align: 'left',
          dataIndex: 'jsonCount'
        },
        {
          title: '身份证照片',
          align: 'left',
          dataIndex: 'idimageCount'
        },
        {
          title: '人脸照片',
          align: 'left',
          dataIndex: 'faceCount'
        },
        {
          title: '身份证信息',
          align: 'left',
          dataIndex: 'idnoCount'
        },
        {
          title: '收款信息',
          align: 'left',
          dataIndex: 'bankcardCount'
        },
        {
          title: '申请',
          align: 'left',
          dataIndex: 'applyCount'
        },
        {
          title: '过件',
          align: 'left',
          dataIndex: 'accessCount'
        },
        {
          title: '放款',
          align: 'left',
          dataIndex: 'loanCount'
        },
        {
          title: '发短信率',
          align: 'left',
          dataIndex: 'installsOptRate'
        },
        {
          title: '注册率',
          align: 'left',
          dataIndex: 'optRegRate'
        },
        {
          title: '基本信息率',
          align: 'left',
          dataIndex: 'regBasicRate'
        },
        // {
        //   title: '工作信息率',
        //   align: 'left',
        //   dataIndex: 'basicWorkRate'
        // },
        {
          title: '联系人率',
          align: 'left',
          dataIndex: 'workLinkmanRate'
        },
        {
          title: 'JSON率',
          align: 'left',
          dataIndex: 'linkedManJsonRate'
        },
        {
          title: '身份证照片率',
          align: 'left',
          dataIndex: 'jsonIdimageRate'
        },
        {
          title: '人脸照片率',
          align: 'left',
          dataIndex: 'idimageFaceRate'
        },
        {
          title: '身份证信息率',
          align: 'left',
          dataIndex: 'faceidNoRate'
        },
        {
          title: '收款信息率',
          align: 'left',
          dataIndex: 'idNoBankcardRate'
        },
        {
          title: '申请率',
          align: 'left',
          dataIndex: 'bankcardApplyRate'
        },
        {
          title: '过件率',
          align: 'left',
          dataIndex: 'applyAccessRate'
        },
        {
          title: '放款率',
          align: 'left',
          dataIndex: 'accessLoanRate'
        },
        {
          title: '注册-绑卡转化率',
          align: 'left',
          dataIndex: 'regBankCardRate'
        },
        {
          title: '注册-申请转化率',
          align: 'left',
          dataIndex: 'regApplyRate'
        },
        {
          title: '申请-放款转化率',
          align: 'left',
          dataIndex: 'applyLoanRate'
        },
        {
          title: '注册-放款转化率',
          align: 'left',
          dataIndex: 'regLoanRate'
        }
      ],

      innerColumns: [
        {
          title: '分类',
          align: 'left',
          dataIndex: 'appVersion',
        },
        {
          title: '安装',
          align: 'left',
          dataIndex: 'installs',
        },
        {
          title: '注册',
          align: 'left',
          dataIndex: 'regCount'
        },
        {
          title: '基本信息',
          align: 'left',
          dataIndex: 'basicCount'
        },
        // {
        //   title: '工作信息',
        //   align: 'left',
        //   dataIndex: 'workCount'
        // },
        {
          title: '联系人',
          align: 'left',
          dataIndex: 'linkmanCount'
        },
        {
          title: '大JSON',
          align: 'left',
          dataIndex: 'jsonCount'
        },
        {
          title: '身份证照片',
          align: 'left',
          dataIndex: 'idimageCount'
        },
        {
          title: '人脸照片',
          align: 'left',
          dataIndex: 'faceCount'
        },
        {
          title: '身份证信息',
          align: 'left',
          dataIndex: 'idnoCount'
        },
        {
          title: '收款信息',
          align: 'left',
          dataIndex: 'bankcardCount'
        },
        {
          title: '申请',
          align: 'left',
          dataIndex: 'applyCount'
        },
        {
          title: '过件',
          align: 'left',
          dataIndex: 'accessCount'
        },
        {
          title: '放款',
          align: 'left',
          dataIndex: 'loanCount'
        },
        {
          title: '发短信率',
          align: 'left',
          dataIndex: 'installsOptRate'
        },
        {
          title: '注册率',
          align: 'left',
          dataIndex: 'optRegRate'
        },
        {
          title: '基本信息率',
          align: 'left',
          dataIndex: 'regBasicRate'
        },
        // {
        //   title: '工作信息率',
        //   align: 'left',
        //   dataIndex: 'basicWorkRate'
        // },
        {
          title: '联系人率',
          align: 'left',
          dataIndex: 'workLinkmanRate'
        },
        {
          title: 'JSON率',
          align: 'left',
          dataIndex: 'linkedManJsonRate'
        },
        {
          title: '身份证照片率',
          align: 'left',
          dataIndex: 'jsonIdimageRate'
        },
        {
          title: '人脸照片率',
          align: 'left',
          dataIndex: 'idimageFaceRate'
        },
        {
          title: '身份证信息率',
          align: 'left',
          dataIndex: 'faceidNoRate'
        },
        {
          title: '收款信息率',
          align: 'left',
          dataIndex: 'idNoBankcardRate'
        },
        {
          title: '申请率',
          align: 'left',
          dataIndex: 'bankcardApplyRate'
        },
        {
          title: '过件率',
          align: 'left',
          dataIndex: 'applyAccessRate'
        },
        {
          title: '放款率',
          align: 'left',
          dataIndex: 'accessLoanRate'
        },
        {
          title: '注册-绑卡转化率',
          align: 'left',
          dataIndex: 'regBankCardRate'
        },
        {
          title: '注册-申请转化率',
          align: 'left',
          dataIndex: 'regApplyRate'
        },
        {
          title: '申请-放款转化率',
          align: 'left',
          dataIndex: 'applyLoanRate'
        },
        {
          title: '注册-放款转化率',
          align: 'left',
          dataIndex: 'regLoanRate'
        }
      ],

      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    this.loadData(1);
    getApplist().then(res => {
      this.appList = res.data || [];
    })
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getConvertData(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.ipagination.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.ipagination.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    getQueryParams () {
      const { dataDt } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: dataDt && this.transformTime(dataDt[0]),
        endDate: dataDt && this.transformTime(dataDt[1]),
      }
      delete data.dataDt;
      data.pageNum = this.ipagination.current
      data.pageSize = this.ipagination.pageSize
      return filterObj(data)
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    handleTableChange (pagination, filters, sorter) {
      // TODO 筛选
      // if (Object.keys(sorter).length > 0) {
      //   this.isorter.column = sorter.field
      //   this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      // }
      this.ipagination = pagination
      this.loadData()
    },
    extpand (expanded, record) {
      // if(expanded){
      //   this.innerData = record.dataDetails;
      //
      //   console.log("------------->" + record);
      // } else {
      //   this.innerData = [];
      //   console.log("-------------> im close");
      // }

    },
    handleAdd () {
      this.$refs.modalForm.add()
      // this.$refs.modalForm.title = '新增'
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.appSsid = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk () {
      this.loadData()
    },
    handleChangeUpload(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    getUrl() {
      var params = this.getQueryParams()// 查询条件
      var url = '/api/web/webpublic/statistics/convertDataExcel?startDate='+ params.startDate + '&endDate=' + params.endDate;

      if (params.appssidList) {
        url += '&appssidList=' + params.appssidList;
      }
      if (params.dataType) {
        url += '&dataType=' + params.dataType
      }
      return url;
    },
    downLoadData () {
      const file = {
        fileUrl: this.getUrl(),
        fileName: 'convertDataExcel.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    downLoadData2 (arg2) {
      var params = this.getQueryParams(arg2)// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/statistics/appAllCountExcel2?startDate=' + params.startDate + '&endDate=' + params.endDate + '&appssidList=' + params.appssidList,
        fileName: 'countLoanDataWithDateExcelColor.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
