import { axios } from '@/utils/request'

export function getPlatformCollectionGroupList (parameter = {}) {
  return axios({
    url: '/group/list',
    method: 'post',
    data: parameter
  })
}
export function getPlatformCollectionGroupListMy (parameter = {}) {
  return axios({
    url: '/group/listMy',
    method: 'post',
    data: parameter
  })
}
export function addPlatformCollectionGroupList (parameter = {}) {
  return axios({
    url: '/group/add',
    method: 'post',
    data: parameter
  })
}
export function deletePlatformCollectionGroupList (parameter = {}) {
  return axios({
    url: '/group/delete',
    method: 'post',
    data: parameter
  })
}
export function editPlatformCollectionGroupList (parameter = {}) {
  return axios({
    url: '/group/edit',
    method: 'post',
    data: parameter
  })
}
export function switchPlatformCollectionGroupList (parameter = {}) {
  return axios({
    url: '/group/switch',
    method: 'post',
    data: parameter
  })
}
export function getPlatformCollectionGroupUserList (parameter = {}) {
  return axios({
    url: '/groupUser/list',
    method: 'post',
    data: parameter
  })
}
export function addPlatformCollectionGroupUserList (parameter = {}) {
  return axios({
    url: '/groupUser/add',
    method: 'post',
    data: parameter
  })
}
export function editPlatformCollectionGroupUserList (parameter = {}) {
  return axios({
    url: '/groupUser/edit',
    method: 'post',
    data: parameter
  })
}
export function deletePlatformCollectionGroupUserList (parameter = {}) {
  return axios({
    url: '/groupUser/delete',
    method: 'post',
    data: parameter
  })
}
export function switchPlatformCollectionGroupUserList (parameter = {}) {
  return axios({
    url: '/groupUser/switch',
    method: 'post',
    data: parameter
  })
}
export function getCollectionTeamLeaderList (parameter = {}) {
  return axios({
    url: '/group/listCollectionTeamLeader',
    method: 'post',
    data: parameter
  })
}
export function getCollectionTeamUserList (parameter = {}) {
  return axios({
    url: '/group/listCollectionUser',
    method: 'post',
    data: parameter
  })
}
export function getCollectionListDepartment (parameter = {}) {
  return axios({
    url: '/group/listDepartment',
    method: 'post',
    data: parameter
  })
}

export function getGroupByDeptId (parameter) {
  return axios({
    url: '/group/selectAllGroupByDeptId',
    method: 'post',
    params: {
      departmentId: parameter
    }
  })
}

export function getAllGroup (parameter) {
  return axios({
    url: '/group/selectAllGroup',
    method: 'post'
  })
}