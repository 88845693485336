<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item :label="$t(I18N_KEY.REPAYREPORT + 'requestDate')" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
               <a-range-picker
                  v-model="queryParam.examineDate"
                  format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
         
          <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery('')" icon="search" style="margin-left: 8px">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData('')" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 3500, y: 1000 }"
        bordered
        @change="handleTableChange">
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS">{{ text }}</Dict>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { operationInfoForCust } from '@/api/customer'
// import { downRepaymentReportExcel } from '@/api/customer'
import exportFile from '@/api/exportFile'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
const I18N_KEY = {
  REPAYREPORT: 'repayReport.',
  CUSTOPERATIONCOUNT: 'custOperationCount.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "examinerName":"",
        "examineDate":undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'curDate'),
          align: 'left',
          dataIndex: 'date',
          width: 100,
          fixed: 'left'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'FIRST_OPEN'),
          align: 'left',
          dataIndex: 'firstOpen'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_ACCOUNT_CODE'),
          align: 'left',
          dataIndex: 'clickAccountCode'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_ACCOUNT_LOGIN'),
          align: 'left',
          dataIndex: 'clickAccountLogin'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_COLUMN_APPLY'),
          align: 'left',
          dataIndex: 'clickColumnApply'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_HOME_REAPPLY'),
          align: 'left',
          dataIndex: 'clickHomeReapply'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_TAB_HOME'),
          align: 'left',
          dataIndex: 'clickTabHome'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_TAB_MY_LOAN'),
          align: 'left',
          dataIndex: 'clickTabMyLoan'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_TAB_ACCOUNT'),
          align: 'left',
          dataIndex: 'clickTabAccount'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_P_PICTURE_SUBMIT'),
          align: 'left',
          dataIndex: 'clickRong360PPictureSubmit'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_A_PICTURE_SUBMIT_1'),
          align: 'left',
          dataIndex: 'clickRong360APictureSubmit1'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_A_PICTURE_SUBMIT_2'),
          align: 'left',
          dataIndex: 'clickRong360APictureSubmit2'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_ID_CARD_SUBMIT_1'),
          align: 'left',
          dataIndex: 'clickRong360IdCardSubmit1'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_ID_CARD_SUBMIT_2'),
          align: 'left',
          dataIndex: 'clickRong360IdCardSubmit2'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_FACE_RECOGNITION_SUBMIT'),
          align: 'left',
          dataIndex: 'clickRong360FaceRecognitionSubmit'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_BASIC_DATA_SUBMIT'),
          align: 'left',
          dataIndex: 'clickRong360BasicDataSubmit'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_EMERGENCY_CONTACT'),
          align: 'left',
          dataIndex: 'clickRong360EmergencyContact'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_OTHER_CONTACT'),
          align: 'left',
          dataIndex: 'clickRong360OtherContact'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_CONTACT_INFORMATION_SUBMIT'),
          align: 'left',
          dataIndex: 'clickRong360ContactInformationSubmit'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_BANK_CARD_SUBMIT'),
          align: 'left',
          dataIndex: 'clickRong360BankCardSubmit'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_APPLY'),
          align: 'left',
          dataIndex: 'clickRong360Apply'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_REAPPLY'),
          align: 'left',
          dataIndex: 'clickRong360Reapply'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_SUBMIT'),
          align: 'left',
          dataIndex: 'clickRong360Submit'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_RESUBMIT'),
          align: 'left',
          dataIndex: 'clickRong360Resubmit'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_CONTRACT'),
          align: 'left',
          dataIndex: 'clickRong360Contract'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_VIEW_ORDER'),
          align: 'left',
          dataIndex: 'clickViewOrder'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_ACCOUNT_NORMAL'),
          align: 'left',
          dataIndex: 'clickAccountNormal'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_MY_LOAN_NORMAL'),
          align: 'left',
          dataIndex: 'clickMyLoanNormal'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'ORDER_REPAID'),
          align: 'left',
          dataIndex: 'orderRepaid'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_ACCOUNT_SETTINGS'),
          align: 'left',
          dataIndex: 'clickAccountSettings'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_ACCOUNT_BANK'),
          align: 'left',
          dataIndex: 'clickAccountBank'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_CHANGE_PICTURE'),
          align: 'left',
          dataIndex: 'clickRong360ChangePicture'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_CHANGE_BASEINFO'),
          align: 'left',
          dataIndex: 'clickRong360ChangeBaseinfo'
        },
        {
          title: this.$t(I18N_KEY.CUSTOPERATIONCOUNT + 'CLICK_RONG360_CHANGE_CONTACT'),
          align: 'left',
          dataIndex: 'clickRong360ChangeContact'
        }
      ]
    }
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery (arg) {
      
      this.loadData(1,arg)
    },
    loadData (arg,arg2) {
      // 加载数据 若传入参数1则加载第一页的内容
      console.log(arg2)
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams(arg2)// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value);
      operationInfoForCust(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    downLoadData (arg2) {
      var params = this.getQueryParams(arg2)// 查询条件
      const file = {
        fileUrl: '/api/web/statistics/operationInfoForCustExcel?endDate=' + params.endDate + '&startDate=' + params.startDate + '&allFlag=' + params.allFlag,
        fileName: 'operationInfoForCustExcel.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
          this.$message.error(e.message);
      }).finally(() => {
          this.loading = false;
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
       return str && str.toLocaleLowerCase();
    },
    getQueryParams (arg) {
      const { examineDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          startDate: examineDate && this.transformTime(examineDate[0]),
          endDate: examineDate && this.transformTime(examineDate[1]),
          allFlag: '1',
          loanCount: arg,
      }
      delete data.examineDate;
      return filterObj(data)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
