<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="'统计日期'">
              <a-range-picker
                v-model="queryParam.endTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.collection_stage')">
              <a-select default-value="" style="width: 190px" v-model="queryParam.normalConfigId">
                <a-select-option v-for="item in normalConfigList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery(1)" icon="search">{{'查询'}}</a-button>
<!--              <a-button type="primary" @click="searchQuery(2)" icon="search"  style="margin-left: 8px">{{ '周排名' }}</a-button>-->
<!--              <a-button type="primary" @click="searchQuery(3)" icon="search"  style="margin-left: 8px">{{ '月排名' }}</a-button>-->
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>


    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {getCollectionRank} from '@/webpublicapi/collection'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import exportFile from '@/webpublicapi/exportFile'
import moment from 'moment'
import vModal from '../modules/Modal';
import { getAllNormalConfig } from '@/webpublicapi/normalConfig'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'reborrowCount',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      appList: [],
      normalConfigList: [],
      // 查询条件
      queryParam: {
        "appssid":"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [

        {
          title: '序号',
          align: 'left',
          dataIndex: 'rank'
        },
        {
          title: 'id',
          align: 'left',
          dataIndex: 'userId'
        },
        {
          title: '账号',
          align: 'left',
          dataIndex: 'mobileNo'
        },
        {
          title: '姓名',
          align: 'left',
          dataIndex: 'collectionName'
        },
        {
          title: '金额',
          align: 'left',
          dataIndex: 'sumTradeAmount'
        }
      ]
    },
  },
  created () {
    getAllNormalConfig().then(res => {
      this.normalConfigList = res.data || [];
    })
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery (arg) {
      this.loadData(arg)
    },
    loadData (arg) {

      this.loading = true
      var params = this.getQueryParams(arg)// 查询条件
      getCollectionRank(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.dataSource = res.data || [];
          var result = res.data || [];
          for(var i = 0 ; i < result.length ; i ++){
            result[i].rank = (i + 1);
          }
          this.dataSource = result;
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams (arg) {
      const { endTime } = this.queryParam;
      const data = {
          ...this.queryParam,
        beginDate: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
        endDate : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,

      }

      delete data.endTime;
      data.type = arg;
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.lendingTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
