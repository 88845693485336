<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="费率名称">
          <a-input placeholder="请输入费率名称" v-decorator="[ 'feeName', validatorRules.feeName]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="固定金额">
          <a-input placeholder="请输入固定金额" v-decorator="[ 'feeAmount', validatorRules.feeAmount]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="固定费率">
          <a-input placeholder="请输入固定费率" v-decorator="[ 'feeRate', validatorRules.feeRate]"/>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { addProductFeeConfig, editProductFeeConfig } from '@/webpublicapi/operation'
import DictRadioGroup from '../../modules/DictRadioGroup';
// duplicateCheck
export default {
  name: 'DictModal',
  components:{
    DictRadioGroup
  },
  data () {
    return {
      value: 1,
      title: '操作',
      allowPrepay:1,
      productId:'',
      visibleCheck: true,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        feeName: { rules: [{ required: true, message: '请输入费率名称!' }] },
        feeAmount: { rules: [{ required: true, message: '请输入固定金额!' }] },
        feeRate: { rules: [{ required: true, message: '请输入固定费率!' }] }
      }

    }
  },
  created () {
  },
  methods: {
    add (proId,ssId) {
      this.productId = proId
      this.appSsid=ssId
      this.edit({})
    },
    edit (record) {
      this.form.resetFields()
      this.model = Object.assign({}, record)
      if (record.id) {
        this.allowPrepay = record.allowPrepay
        this.visibleCheck = (record.allowPrepay == 1)
      }
      this.visible = true;
      const data = pick(this.model, 'feeName', 'feeAmount', 'feeRate');
      data.allowPrepay = !!data.allowPrepay;
      this.$nextTick(() => {
        this.form.setFieldsValue(data);
      })
    },
    onChose (checked) {
      if (checked) {
        this.allowPrepay = 1
        this.visibleCheck = true
      } else {
        this.allowPrepay = 0
        this.visibleCheck = false
      }
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          formData.allowPrepay = +formData.allowPrepay ? 1 : 0;
          let obj
          if (!that.model.id) {
            formData.productId = this.productId
            formData.appSsid=this.appSsid
            obj = addProductFeeConfig(formData)
          } else {
             obj = editProductFeeConfig(formData)
          }
          obj.then((res) => {
            if (res.code === 1000) {
              that.$message.success(res.msg)
              that.$emit('ok')
              that.close()
            } else {
              that.$message.warning(res.msg)
            }
          }).finally(() => {
            that.confirmLoading = false
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
