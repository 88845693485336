import { axios } from '@/utils/request'

export function getProductList(parameter = {}) {
  return axios({
    url: '/installmentProduct/list',
    method: 'post',
    data: parameter
  })
}

export function getProductItemList(parameter = {}) {
  return axios({
    url: '/installmentProduct/detail/list',
    method: 'post',
    data: parameter
  })
}

export function addProductItem(parameter = {}) {
  return axios({
    url: '/installmentProduct/detail/add',
    method: 'post',
    data: parameter
  })
}

export function editProductItem(parameter = {}) {
  return axios({
    url: '/installmentProduct/detail/edit',
    method: 'post',
    data: parameter
  })
}

export function addProduct(parameter = {}) {
  return axios({
    url: '/installmentProduct/add',
    method: 'post',
    data: parameter
  })
}

export function editProduct(parameter = {}) {
  return axios({
    url: '/installmentProduct/update',
    method: 'post',
    data: parameter
  })
}

