<template>
  <a-drawer
    :title="title"
    :width="drawerWidth"
    @close="handleCancel"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :wrapStyle="{height: 'calc(100% - 20px)',overflow: 'auto',paddingBottom: '20px'}"
  >
    <div :style="{width: '100%',border: '1px solid #e9e9e9',padding: '10px 16px',background: '#fff',}">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item :label="$t(I18N_KEY + 'menu') + ' ' +  $t(I18N_KEY + 'type')" :labelCol="labelCol" :wrapperCol="wrapperCol" >
          <a-radio-group @change="onChangeMenuType" v-decorator="['menuType',{ initialValue: localMenuType}]">
            <a-radio :disabled="onlyBtn" :value="0">{{ $t(I18N_KEY + 'mainSub') }}</a-radio>
            <a-radio :disabled="onlyBtn" :value="1">{{ $t(I18N_KEY + 'submenu') }}</a-radio>
            <a-radio :value="2">{{ $t(I18N_KEY + 'buttonPermission') }}</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'nameCn')"
          hasFeedback >
          <a-input :placeholder="$t('enter')" v-decorator="[ 'nameCn', validatorRules.nameCn]" :readOnly="disableSubmit"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'nameEn')"
          hasFeedback >
          <a-input :placeholder="$t('enter')" v-decorator="[ 'nameEn', validatorRules.nameEn]" :readOnly="disableSubmit"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'nameEs')"
          hasFeedback >
          <a-input :placeholder="$t('enter')" v-decorator="[ 'nameEs', validatorRules.nameEs]" :readOnly="disableSubmit"/>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'nameAr')"
          hasFeedback >
          <a-input :placeholder="$t('enter')" v-decorator="[ 'nameAr', validatorRules.nameAr]" :readOnly="disableSubmit"/>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'nameFr')"
          hasFeedback >
          <a-input :placeholder="$t('enter')" v-decorator="[ 'nameFr', validatorRules.nameFr]" :readOnly="disableSubmit"/>
        </a-form-item>

        <a-form-item
          v-if="localMenuType == 2"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'menuCode')"
          hasFeedback >
          <a-input :placeholder="$t('enter')" v-decorator="[ 'permission', validatorRules.permission]" :readOnly="disableSubmit"/>
        </a-form-item>


        <a-form-item
          v-show="localMenuType!=0"
          :label="$t(I18N_KEY + 'preMenu')"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol">
          <a-tree-select
            style="width:100%"
            :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }"
            :treeData="menuTree"
            v-decorator="[ 'parentId', validatorRules.parentId]"
            :placeholder="$t('select')"
            :disabled="disableSubmit"
            @change="handleParentIdChange">
          </a-tree-select>
        </a-form-item>

        <a-form-item
          v-show="localMenuType != 2"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'menuUrl')">
          <a-input :placeholder="$t('enter')"  v-decorator="[ 'menuPath',validatorRules.url]" :readOnly="disableSubmit"/>
        </a-form-item>

        <a-form-item
          v-show="localMenuType != 2"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'menuComponent')">
          <a-input :placeholder="$t('enter')"  v-decorator="[ 'component',validatorRules.component]" :readOnly="disableSubmit"/>
        </a-form-item>

        <a-form-item
          v-show="localMenuType != 2"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'icon')">
          <a-input placeholder="点击右侧按钮选择图标" v-model="model.menuIcon" allowClear>
            <a-icon slot="addonAfter" type="setting" @click="selectIcons" />
          </a-input>
        </a-form-item>

        <a-form-item
          v-show="localMenuType != 2"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t('sort')">
          <a-input-number :placeholder="$t('enter')" style="width: 200px" v-decorator="[ 'menuSort',validatorRules.sortNo]" :readOnly="disableSubmit"/>
        </a-form-item>

        <a-form-item
          v-show="localMenuType != 2"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t('show') + '/' + $t('hide')">
          <a-switch :checkedChildren="$t('show')" :unCheckedChildren="$t('hide')" v-decorator="[ 'hidden', { initialValue: true, valuePropName: 'checked' }]"/>
        </a-form-item>
        <!--update_end author:wuxianquan date:20190908 for:增加组件，外链打开方式可选 -->


      </a-form>

      <!-- 选择图标 -->
      <icons @choose="handleIconChoose" @close="handleIconCancel" :iconChooseVisible="iconChooseVisible"></icons>
    </a-spin>
      <a-row :style="{textAlign:'right'}">
        <a-button :style="{marginRight: '8px'}" @click="handleCancel">
          {{ $t('cancel') }}
        </a-button>
        <a-button :disabled="disableSubmit" @click="handleOk" type="primary">{{ $t('confirm') }}</a-button>
      </a-row>
    </div>
  </a-drawer>
</template>

<script>
  // import {addPermission,editPermission,queryTreeList} from '@/webpublicapi/api'
  import Icons from './icon/Icons'
  import pick from 'lodash.pick'
  const I18N_KEY = 'system.menuList.';

  export default {
    name: "PermissionModal",
    props: {
      menuTree: {
        type: Array,
        default: [],
      },
      onlyBtn: {
        type: Boolean,
        default: false
      }
    },
    components: {Icons},
    data () {
      return {
        I18N_KEY,
        drawerWidth:700,
        title:"操作",
        visible: false,
        disableSubmit:false,
        model: {
          menuIcon: undefined,
        },
        localMenuType: 0,
        alwaysShow:false,//表单元素-聚合路由
        menuHidden:false,//表单元素-隐藏路由
        routeSwitch:true, //是否路由菜单
        /*update_begin author:wuxianquan date:20190908 for:定义变量，初始值代表内部打开*/
        internalOrExternal:false,//菜单打开方式
        /*update_end author:wuxianquan date:20190908 for:定义变量，初始值代表内部打开*/
        isKeepalive:true, //是否缓存路由
        show:true,//根据菜单类型，动态显示隐藏表单元素
        menuLabel:'菜单名称',
        isRequrie:true,  // 是否需要验证
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },

        confirmLoading: false,
        form: this.$form.createForm(this),

        iconChooseVisible: false,
        validateStatus:""
      }
    },
    computed:{
      validatorRules () {
        return {
          nameCn:{rules: [{ required: true, message: this.$t('enter') + ' ' + this.$t(I18N_KEY + 'nameCn') }]},
          nameEn:{rules: [{ required: true, message: this.$t('enter') + ' ' + this.$t(I18N_KEY + 'nameEn') }]},
          nameEs:{rules: [{ required: true, message: this.$t('enter') + ' ' + this.$t(I18N_KEY + 'nameEs') }]},
          nameAr:{rules: [{ required: true, message: this.$t('enter') + ' ' + this.$t(I18N_KEY + 'nameAr') }]},
          nameFr:{rules: [{ required: true, message: this.$t('enter') + ' ' + this.$t(I18N_KEY + 'nameFr') }]},
          permission:{rules: [{ required: this.localMenuType == 2, message: this.$t('enter') + ' ' + this.$t(I18N_KEY + 'menuCode') }]},
          parentId:{rules: [{ required: this.localMenuType != 0, message: this.$t('enter') + ' ' + this.$t(I18N_KEY + 'preMenu') }]},
          url:{rules: [{ required: this.localMenuType != 2, message: this.$t('enter') + ' ' + this.$t(I18N_KEY + 'menuUrl') }]},
          // component:{rules: [{ required: this.localMenuType != 2, message: this.$t('enter') + ' ' + this.$t(I18N_KEY + 'menuComponent') }]},
          sortNo:{initialValue:1.0},
        }
      }
    },
    created () {
      this.initDictConfig();
    },
    methods: {
      add () {
        // 默认值
        this.edit({hidden: 0, tempMenuType: 0});
        this.localMenuType = 0;
      },
      edit (record) {
        this.resetScreenSize(); // 调用此方法,根据屏幕宽度自适应调整抽屉的宽度
        this.form.resetFields();
        this.visible = true;
        let fieldsVal = pick(record,'nameCn','nameEn','nameEs','nameAr','nameFr','menuPath', 'component','tempMenuType', 'menuSort','hidden', 'permission');
        this.model.menuIcon = record.menuIcon;
        fieldsVal.hidden = record.hidden == 0;
        fieldsVal.menuType =  fieldsVal.tempMenuType;
        // delete fieldsVal.tempMenuType;
        if (record.parentId != 0) {
          fieldsVal.parentId = record.parentId && '' + record.parentId;
        }
        this.$nextTick(() => {
          this.form.setFieldsValue(fieldsVal)
        });
      },
      close () {
        this.$emit('close');
        this.disableSubmit = false;
        this.visible = false;
      },
      openLoading () {
          this.confirmLoading = true;
      },
      closeLoading () {
        this.confirmLoading = false;
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.form.validateFields((err, values) => {
          if (!err) {
            let formData = {
              ...this.model,
              ...values,
              menuType: this.localMenuType != 2 ? 1 : 2,
              parentId: this.localMenuType == 0 ? '0': values.parentId,
              hidden: !+values.hidden,
            };

            this.$emit('ok', formData);
          }
        })
      },
      handleCancel () {
        this.close()
      },
      validateNumber(rule, value, callback){
        if(!value || new RegExp(/^[0-9]*[1-9][0-9]*$/).test(value)){
          callback();
        }else{
          callback("请输入正整数!");
        }
      },
      onChangeMenuType(e) {
        this.localMenuType = e.target.value
        // if(e.target.value == 0){
        //   this.form.setFieldsValue({ parentId: '0' })
        // }
      },
      selectIcons(){
        this.iconChooseVisible = true
      },
      handleIconCancel () {
        this.iconChooseVisible = false
      },
      handleIconChoose (value) {
        this.model.menuIcon = value;
        this.iconChooseVisible = false
      },
      // 根据屏幕变化,设置抽屉尺寸
      resetScreenSize(){
        let screenWidth = document.body.clientWidth;
        if(screenWidth < 500){
          this.drawerWidth = screenWidth;
        }else{
          this.drawerWidth = 700;
        }
      },
      initDictConfig() {
      },
      handleParentIdChange(value, label, extra){
        if(!value){
          this.validateStatus="error"
        }else{
          this.validateStatus="success"
        }
      }
    }
  }
</script>

<style scoped>

</style>