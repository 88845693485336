<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.RISK + 'mobile')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.RISK + 'mobile'))" v-model="queryParam.mobile"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.RISK + 'bankAccountNo')">
               <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.RISK + 'bankAccountNo'))" v-model="queryParam.bankAccountNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.RISK + 'ifscCode')">
                <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.RISK + 'ifscCode'))" v-model="queryParam.ifscCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('check') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { checkBankCard} from '@/webpublicapi/customer'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex'

const I18N_KEY = {
  RISK: 'risk.'
}

export default {
  name: 'BankcardCheck',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      confirmLoading: false,
      visible: false,
      visibleT: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      batchVisible: false,
      batchLoading: false,
      selectedRowKeys: [],
      selectedList: [],
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "bankAccountNo":"",
        "ifscCode":"",
        "mobile":"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      selectUser: '',
      ExamineUser: [],
      taskId: ''
    }
  },
  computed: {
    columns () {
      return [
      ]
    }
  },
  created () {
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      this.loading = true
      var params = this.getQueryParams()// 查询条件

      checkBankCard(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.$message.info(res.data.msg)
        }else{
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },

    getQueryParams () {
      const { applyDate } = this.queryParam;
      const data = {
          ...this.queryParam,
      }
      delete data.applyDate;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      return filterObj(param)
    },

    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
       that.queryParam.aadhaarName = ''
       that.queryParam.mobileNo = ''
      that.loadData(this.form.pageNum)
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
 .description {
  line-height: 1.4;
  &.d_text {
    text-indent: 1em;
  }
  &.d_high_light {
    color: #fd9a38;
  }
 }
</style>
