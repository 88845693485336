<template>
  <a-card :bordered="false">
    <a-spin :spinning="loading">
      <div class="table-page-search-wrapper">
        <a-form layout="inline" @keyup.enter.native="searchQuery">
          <a-row :gutter="12">
            <a-col :span="6">
              <a-form-item :label="$t(I18N_KEY.REPAYREPORT + 'requestDate')" :labelCol="{span: 6}"
                           :wrapperCol="{span: 14, offset: 1}">
                <a-date-picker
                  v-model="queryParam.examineDate"
                  style="width: 200px;"/>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item :label="$t(I18N_KEY.REPAYREPORT + 'appSsid')" :labelCol="{span: 6}"
                           :wrapperCol="{span: 14, offset: 1}">
                <a-select v-model="queryParam.appSsid" allow-clear placeholder="请选择应用" :options="options.app"
                          style="width: 200px;"></a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search" style="margin-left: 8px">{{
                  $t('search')
                }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{
                  $t('reset')
                }}</a-button>
            </span>
            </a-col>
          </a-row>
        </a-form>
        <div id="container"></div>
      </div>
    </a-spin>
  </a-card>
</template>

<script>
import {filterObj} from '@/utils/util'
import {dailyDecisionStatistics} from '@/webpublicapi/risk'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment';
import riskStatisticsDetail from './riskStatisticsDetail.vue'
import {getAppList} from "@/webpublicapi/system";

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
  REPAYREPORT: 'repayReport.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
    riskStatisticsDetail
  },
  data() {
    return {
      I18N_KEY,
      loading: false,
      queryParam: {
        "appSsid": "",
        "examineDate": moment.utc().add(-6, 'hours'),
      },
      options: {app: []},
      chart: null
    }
  },
  created() {
    getAppList({pageNum: 1, pageSize: 999999999}).then(({code, data}) => {
      if (code === 1000) {
        this.options.app = data.rows.map(({appName, appSsid}) => ({label: appName, value: appSsid}))
      } else {
        throw new Error('')
      }
    })
    this.loadData()
  },
  methods: {
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery() {
      this.queryParam.examineDate = this.queryParam.examineDate || moment.utc().add(-6, 'hours')
      this.loadData()
    },
    renderChart(data) {
      this.chart && this.chart.destroy();
      data = data.reduce((prev, curr) => {
        curr.decisionResult = curr.decisionResult === 'true' ? true : curr.decisionResult === 'false' ? false : null;
        prev[Number(curr.decisionTime) * 6 + (curr.decisionResult == null ? 2 : curr.decisionResult ? 1 : 0) * 2 + (curr.decisionType) * 1] = curr;
        return prev
      }, [{
        decisionTime: '00',
        decisionCount: 0,
        decisionType: 1,
        decisionResult: false
      }, {
        decisionTime: '00',
        decisionCount: 0,
        decisionType: 0,
        decisionResult: false
      }, {
        decisionTime: '00',
        decisionCount: 0,
        decisionType: 1,
        decisionResult: true
      }, {
        decisionTime: '00',
        decisionCount: 0,
        decisionType: 0,
        decisionResult: true
      }, {
        decisionTime: '00',
        decisionCount: 0,
        decisionType: 1,
        decisionResult: null
      }, {
        decisionTime: '00',
        decisionCount: 0,
        decisionType: 0,
        decisionResult: null
      }]).filter(item => item)
      const source = data.map(item => {
        const arr = data.filter(f => f.decisionTime === item.decisionTime)
        const max = arr.reduce((prev, curr) => curr.decisionType > prev ? curr.decisionType : prev, 0)
        const firstTotal = arr.reduce((prev, curr) => prev + (curr.decisionType === 0 ? curr.decisionCount : 0), 0)
        const reTotal = arr.reduce((prev, curr) => prev + (curr.decisionType === 1 ? curr.decisionCount : 0), 0)
        const total = arr.reduce((prev, curr) => prev + curr.decisionCount, 0)
        const firstPass = arr.reduce((prev, curr) => prev + (curr.decisionResult && curr.decisionType === 0 ? curr.decisionCount : 0), 0)
        const rePass = arr.reduce((prev, curr) => prev + (curr.decisionResult && curr.decisionType === 1 ? curr.decisionCount : 0), 0)
        const pass = arr.reduce((prev, curr) => prev + (curr.decisionResult ? curr.decisionCount : 0), 0)
        const firstRevivePass = arr.reduce((prev, curr) => prev + (curr.decisionResult === null && curr.decisionType === 0 ? curr.decisionCount : 0), 0)
        return {
          type: ['首贷', '复贷'][item.decisionType],
          time: item.decisionTime,
          '首贷通过率': firstTotal === 0 ? 0 : Math.round(10000 * firstPass / firstTotal) / 100,
          '首贷捞回率': firstTotal === 0 ? 0 : Math.round(10000 * firstRevivePass / firstTotal) / 100,
          '复贷通过率': reTotal === 0 ? 0 : Math.round(10000 * rePass / reTotal) / 100,
          '首贷': item.decisionType === 0 ? `${firstPass}/${firstTotal - firstPass}/${firstTotal}` : undefined,
          '复贷': item.decisionType === 1 ? `${rePass}/${reTotal - rePass}/${reTotal}` : undefined,
          '整体': item.decisionType === max ? `${pass}/${total - pass}/${total}` : undefined,
          '时段决策量': item.decisionType === 0 ? firstTotal : reTotal,
        }
      })
      console.log(source)
      this.chart = new G2.Chart({
        container: 'container',
        forceFit: true,
        background: '#eeeeee',
        width: 1000,
        height: 618,
        padding: 70
      });
      this.chart.source(source, {
        '时段决策量': {
          min: 0
        },
        '首贷通过率': {
          min: 0,
          max: 100
        },
        '复贷通过率': {
          min: 0,
          max: 100
        },
        '首贷捞回率': {
          min: 0,
          max: 100
        },
      });
      this.chart.legend({
        custom: true,
        allowAllCanceled: true,
        items: [
          {value: '首贷通过率', marker: {symbol: 'hyphen', stroke: 'lime', radius: 5, lineWidth: 3}},
          {value: '复贷通过率', marker: {symbol: 'hyphen', stroke: '#fdae6b', radius: 5, lineWidth: 3}},
          {value: '首贷捞回率', marker: {symbol: 'hyphen', stroke: 'OrangeRed ', radius: 5, lineWidth: 3}},
          {value: '首贷', marker: {symbol: 'square', fill: '#0a7aca', radius: 5}},
          {value: '复贷', marker: {symbol: 'square', fill: '#8ed1ff', radius: 5}},
        ],
      });
      this.chart.interval()
        .position('time*时段决策量')
        .color('type', ['lime', '#fdae6b']).adjust([{
        type: 'dodge',
        marginRatio: 0
      }]).label('时段决策量', {
        offset: 12,
        textStyle: {
          fill: '#000000',
        }
      }).tooltip('首贷*复贷*整体');
      this.chart.point()
        .position('time*首贷通过率')
        .color('lime')
        .label('time*首贷通过率', (time, pass) => {
          return `${pass}%`;
        }, {
          textStyle: {
            fill: 'black',
          }
        })
        .tooltip('首贷通过率', (pass) => {
          return `${pass}%`;
        })
        .size(3)
        .shape('circle');
      this.chart.line()
        .position('time*首贷通过率')
        .color('lime')
        .size(3)
        .shape('smooth');
      this.chart.point()
        .position('time*复贷通过率')
        .color('#fdae6b')
        .label('复贷通过率', (pass) => {
          return `${pass}%`;
        }, {
          textStyle: {
            fill: 'black',
          }
        })
        .size(3)
        .shape('circle');
      this.chart.line()
        .position('time*复贷通过率')
        .color('#fdae6b')
        .size(3)
        .shape('smooth');
      this.chart.point()
        .position('time*首贷捞回率')
        .color('OrangeRed')
        .label('time*首贷捞回率', (time, pass) => {
          return `${pass}%`;
        }, {
          textStyle: {
            fill: 'OrangeRed',
          }
        })
        .tooltip('首贷捞回率', (pass) => {
          return `${pass}%`;
        })
        .size(3)
        .shape('circle');
      this.chart.line()
        .position('time*首贷捞回率')
        .color('OrangeRed')
        .size(3)
        .shape('smooth');
      this.chart.render();
    },
    loadData() {
      this.loading = true;
      dailyDecisionStatistics(this.getQueryParams()).then((res) => {
        if (res.code === 1000) {
          this.renderChart(res.data)
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    getQueryParams() {
      const {examineDate} = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: examineDate && this.transformTime(moment(examineDate)),
        endDate: examineDate && this.transformTime(moment(examineDate).add(1, 'days')),
      }
      delete data.examineDate;
      return filterObj(data)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    }
  },
  beforeDestroy() {
    this.chart.destroy()
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
