<template>
  <div :bordered="false">

    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item :label="'日期'" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-range-picker
                v-model="queryParam.dataDt"
                format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">

              <a-button type="primary" @click="downLoadData('2')" icon="download" style="margin-left: 8px">导出bind放款数据</a-button>
              <br/> <br/>
              <a-button type="primary" @click="downLoadData('1')" icon="download" style="margin-left: 8px">导出bind还款数据</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import moment from "moment";
import exportFile from "@/api/exportFile";
export default {
  name: 'AppList',
  components: {
  },
  data () {
    return {
      visible: false,
      headers: {
        // authorization: 'authorization-text',
      },
      // 查询条件
      queryParam: {
        dataDt: undefined,
      }
    }
  },
  created () {

  },
  methods: {
    getQueryParams () {
      const { dataDt } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: dataDt && this.transformTime(dataDt[0]),
        endDate: dataDt && this.transformTime(dataDt[1]),
      }
      delete data.dataDt;

      return filterObj(data)
    },

    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },

    downLoadData (arg2) {
      var params = this.getQueryParams()// 查询条件
      var startDate = params.startDate;
      var endDate = params.endDate;
      if(!startDate || !endDate){
        alert('请选择日期');
        return false;
      }
      const file = {
        fileUrl: '/api/web/webpublic/payChecking/exportBindPayExcel?startDate=' + params.startDate + '&endDate=' + 
                params.endDate + '&type=' + arg2,
        fileName: arg2 + '_' +  params.startDate + '_' + params.endDate + '.xlsx',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
