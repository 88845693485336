<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'orderNo')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.ORDER + 'orderNo'))" v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        size="middle"
        rowKey="taskId"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <Dict slot="followResult" slot-scope="text" :dict-code="DICT_CODE.FOLLOW_RESULT" :value="text"/>
        <Dict slot="repaymentIntention" slot-scope="text" :dict-code="DICT_CODE.REAPYMENT_INTENTION" :value="text"/>
        <Dict slot="collectionTaskStatus" slot-scope="text" :dict-code="DICT_CODE.COLLECTION_TASK_STATUS" :value="text"/>

        <span slot="action" slot-scope="text, record">
            <a @click="approvelReduction(record, '0')">{{ $t('apply_access') }}</a>

            <a @click="approvelReduction(record, '1')" style="margin-left: 8px" >{{ $t('apply_refuse') }}</a>
         </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>




    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { queryReductionList, approvelReduction, modCollectionTaskUser } from '@/api/collection'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex'

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.'
}

export default {
  name: 'CollectionReductionTask',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      confirmLoading: false,
      visible: false,
      visibleT: false,
      orderId: undefined,
      custInfoId: undefined,
      batchVisible: false,
      batchLoading: false,
      selectedRowKeys: [],
      selectedList: [],
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "orderNo":"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      selectUser: '',
      ExamineUser: [],
      taskId: ''
    }
  },
  computed: {
    ...mapGetters('collection',['listUserWithNum']),
    batchList () {
      return this.listUserWithNum || [];
    },
    columns () {
      return [
        {
          title: this.$t('order.orderNo'),
          align: 'left',
          dataIndex: 'order_no'
        },
        {
          title:  this.$t('customer.custName'),
          align: 'left',
          dataIndex: 'aadhaar_name'
        },
        {
          title: this.$t('collection.applyReductionAmt'),
          align: 'left',
          dataIndex: 'apply_reduction_amt'
        },
        {
          title:this.$t('collection.balFee'),
          align: 'left',
          dataIndex: 'balFee'
        },
        {
          title:this.$t('platformCollection.application_time'),
          align: 'left',
          dataIndex: 'create_time'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
    batchColumns () {
      return [
        {
          title: this.$t('name'),
          align: 'left',
          dataIndex: 'userName'
        },
        {
          title: this.$t('order.followingUpOrder'),
          align: 'left',
          dataIndex: 'undoNum'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    this.getDict();
    this.loadData(1)
  },
  methods: {
    ...mapActions('collection', ['getListUserWithNum', 'batchChangeUser']),
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 清空选中项
      this.selectedList = [];
      this.selectedRowKeys = [];
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      queryReductionList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    selectRowsChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.originSelectedList = selectedRows;
      this.selectedList = _.cloneDeep(selectedRows);
    },
    openBatchDialog () {
      this.batchVisible = true;
      this.batchLoading = true;
      this.selectedList.length && this.getListUserWithNum().finally(res => {
        this.batchLoading = false;
      })
    },
    closeBatchDialog () {
      this.batchVisible = false;
      this.selectedList = _.cloneDeep(this.originSelectedList || []);
    },
    submitBatch () {
      const taskIds = this.selectedList.map(t => t.taskId);
      const userTask = this.batchList.map(t => ({ userId: t.userId, taskNum: t.taskNum ? +t.taskNum : 0 }));
      const totalNum = userTask.map(t => t.taskNum).reduce((total, num ) => (total += num), 0);
      console.log(userTask, totalNum);
      if (!userTask.length) {
        return this.closeBatchDialog();
      }
      if (isNaN(totalNum)) {
        return this.$message.error(this.$t('please_enter_a_positive_integer'));
      }
      if (totalNum > this.selectedList.length) {
        return this.$message.error(this.$t('platformCollection.cannot_be_greater_than_the_total_quantity_of_the_checked_order'));
      }
      this.closeBatchDialog();
      this.loading = true;
      this.batchChangeUser({ taskIds, userTask }).then(res => {
        this.loadData();
        this.$message.success(this.$t('success'));
      }).finally(res => {
        this.loading = false;
      });
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          endDateStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
          endDateEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    onChange(e) {
      this.selectUser = e.target.value
    },
    handleOk(e) {
       if(this.selectUser!=''){
        this.confirmLoading = true;
        modCollectionTaskUser({userId:this.selectUser,taskId:this.taskId}).then((res)=>{
          if(res.code===1000){
            this.visibleT = false;
            this.confirmLoading = false;
            this.loadData();
          }else{
            this.confirmLoading = false;
            this.$message.error(res.msg)
          }
        }).catch(e => {
           this.confirmLoading = false;
            this.$message.error(e.message);
      })
       }else{
         this.$message.error(this.$t('platformCollection.please_select_a_credit_examiner'))
       }
    },
      handleCancel(e) {
        this.visibleT = false;
      },
    approvelReduction(e, type) {

      const self = this;
      if('0' == type){
        var lateFee = e.balFee;
        var decAmt = e.apply_reduction_amt;
        this.$confirm({
          title: self.$t('order.isReductionAmt'),
          content: h => {
            return <div>
            <div>
              {$t('platformCollection.late_fee')}：{lateFee}
          </div>
            <div>
              {$t('platformCollection.deduction_amount')}：<input id = 'repayAmt' value = {decAmt}></input>
              </div>
              </div>
          },
          onOk() {
            self.loading = true;

            var repayAmt = document.getElementById("repayAmt").value;

            if(!repayAmt.trim()){
              alert(this.$t('platformCollection.deduction_amount_cannot_be_blank'));

              self.loadData();

              return false;
            }

            if(repayAmt > lateFee){
              alert(this.$t('platformCollection.the_deduction_amount_cannot_be_greater_than_the_overdue_fine'));

              self.loadData();

              return false;
            }

            approvelReduction({reductionId:e.id,type:type, repayAmt:repayAmt}).then(res => {
              self.loadData();
            }).catch(e => {
              self.loading = false;
              self.$message.error(e.message);
            })
          },
        });
      } else if('1' == type){
        this.$confirm({
          title: self.$t('order.isRefuseReductionAmt'),
          content: h => {
            return ''
          },
          onOk() {
            self.loading = true;

            approvelReduction({reductionId:e.id,type:type}).then(res => {
              self.loadData();
            }).catch(e => {
              self.loading = false;
              self.$message.error(e.message);
            })
          },
        });
      }



      // this.confirmLoading = true;
      // approvelReduction({reductionId:e.id,type:type}).then((res)=>{
      //   if(res.code===1000){
      //     this.visibleT = false;
      //     this.confirmLoading = false;
      //     this.loadData();
      //   }else{
      //     this.confirmLoading = false;
      //     this.$message.error(res.msg)
      //   }
      // }).catch(e => {
      //   this.confirmLoading = false;
      //   this.$message.error(e.message);
      // })
    },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
 .description {
  line-height: 1.4;
  &.d_text {
    text-indent: 1em;
  }
  &.d_high_light {
    color: #fd9a38;
  }
 }
</style>
