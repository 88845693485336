// 用户行为分析
import api from './index'
import { axios } from '@/utils/request'
import moment from 'moment/moment'

export function getUserBehaviorsByParams (parameter) {
  return axios({
    url: '/trans/getUserBehaviorsByParams',
    method: 'post',
    data: parameter
  })
}