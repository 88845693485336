<template>
  <div>
    <!--      :scroll="{ x: 1300, y: 600}"-->
    <a-button type="primary" @click="downLoadData" icon="arrowDown" style="margin-bottom: 20px">{{ $t('exportFile') }}</a-button>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :customRow="customRow"
      :scroll="{ x: 1300}"
      bordered>
      <template #item="text,record,index">{{$t(I18N_KEY + text)}}</template>
    </a-table>
  </div>
</template>
<script>
import { weekReport} from '@/webpublicapi/custServiceWorkOrder';
import {mapGetters} from "vuex";
import exportFile from '@/webpublicapi/exportFile'
const I18N_KEY = 'custServiceWorkOrder.';
// const indexs1 = [0,2,8,11,13,15,22,25,28,32,38,41,43,45,52,55,58];
// const indexs2 = [61,62,63,64,65,66,67,68];

export default {
  name: 'WorkOrderWeekReport',
  data () {
    return {
      I18N_KEY,
      dataSource: [],
      // fieldName: 'keyCnText',
      bordered: true,
      lastWeekDateLabel: '',
      lastTwoWeekDateLabel: '',
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      weekReport().then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data.list;
          this.lastWeekDateLabel = res.data.lastWeekDateLabel;
          this.lastTwoWeekDateLabel = res.data.lastTwoWeekDateLabel;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    contains(sources, target) {
      for(var item in sources) {
        if(sources[item] === target) {
          return true;
        }
      }
      return false;
    },
    getColor(index, preColor) {
      // if(index === 31) {
      //   return "#00FFFF";
      // }
      if(index === 0) {
        return '#F4B084';
      }
      // if(this.contains(indexs2, index)) {
      //   return '#FFFF00';
      // }
      // if(this.contains(indexs1, index)) {
      //   return '#F4B084';
      // }
      return preColor;
    },
    getFont(index, preFont) {
      // if(index === 31 || index === 1 || this.contains(indexs2, index) || this.contains(indexs1, index)) {
      //   return "bolder";
      // }
      if(index === 0) {
        return "bolder";
      }
      return preFont;
    },
    customRow(record, index) {
      return {
        style: {
          'background-color':this.getColor(index, record.value1.bgColor),
          'font-weight':this.getFont(index, 'unset'),
        }
      }
    },
    downLoadData () {
      const file = {
        fileUrl: '/api/web/webpublic/custService/report/weekReport/export',
        fileName: 'CustomerServiceWeekReport.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'item'),// 项目
          align: 'left',
          width: 400,
          dataIndex: 'fieldName',
          scopedSlots: {customRender: 'item'}
        },
        {
          title: this.lastTwoWeekDateLabel,// 值
          align: 'left',
          width: 100,
          dataIndex: 'value1',
          customRender: (text, record) => {
            var text = text;
            return text.substring(0, text.indexOf('('))
          }
        },
        {
          title: this.lastWeekDateLabel,// 值
          align: 'left',
          width: 100,
          dataIndex: 'value2'
        },
        {
          title: this.$t(I18N_KEY + 'proportion'),// 占比
          align: 'left',
          width: 100,
          dataIndex: 'value3'
        },
        {
          title: this.$t(I18N_KEY + 'rate'),// 比例
          align: 'left',
          width: 100,
          dataIndex: 'rate'
        }
      ]
    }
  },
  // watch: {
  //   languageStorage: {
  //     handler (lang) {
  //       if (lang == 'en-US') {
  //         this.fieldName = 'keyEnText';
  //       } else if (lang == 'zh-CN') {
  //         this.fieldName = 'keyCnText';
  //       } else if (lang == 'es') {
  //         this.fieldName = 'keyEsText';
  //       }
  //     },
  //     immediate: true,
  //   }
  // },
}
</script>