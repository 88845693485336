<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="策略ID">
          <a-input placeholder="请输入策略ID" v-decorator="[ 'id', validatorRules.id]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="策略集ID">
          <a-input placeholder="请输入策略集ID" v-decorator="[ 'strategyId', validatorRules.strategyId]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="特征ID">
          <a-input placeholder="请输入特征ID" v-decorator="[ 'featuresId', validatorRules.featuresId]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="执行顺序">
          <a-input placeholder="请输入执行顺序" v-decorator="[ 'sortNum', validatorRules.sortNum]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="交集标识">
          <a-input placeholder="交集标识:id1_id2_id3" v-decorator="[ 'andGroup']"/>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="计算规则">
          <a-input placeholder="计算规则:true,false,[-Inf,0)U(0,Inf]U[missing]"
                   v-decorator="[ 'rule', validatorRules.rule]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="有效">
          <a-input placeholder="0:有效,1:删除" v-decorator="[ 'isDel', validatorRules.isDel]"/>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import {editRiskRule} from '@/webpublicapi/risk'

// duplicateCheck
export default {
  name: 'DictModal',
  data() {
    return {
      value: 1,
      title: '操作',
      allowPrepay: 1,
      productId: '',
      visibleCheck: true,
      visible: false,
      model: {},
      labelCol: {
        xs: {span: 24},
        sm: {span: 5}
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16}
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        id: {rules: [{required: true, message: '请输入策略ID!'}]},
        strategyId: {
          rules: [{required: true, message: '请输入策略集ID!'}]
        },
        featuresId: {
          rules: [{required: true, message: '请输入特征ID!'}]
        },
        sortNum: {rules: [{required: true, message: '请输入执行顺序!'}]},
        rule: {rules: [{required: true, message: '请输入计算规则!'}]},
        isDel: {rules: [{required: true, message: '请输入有效标识!'}]},


      }

    }
  },
  created() {
  },
  methods: {
    add(proId, ssId) {
      this.productId = proId
      this.appSsid = ssId
      this.edit({})
    },
    edit(record) {
      this.form.resetFields()
      this.model = Object.assign({}, record)
      if (record.id) {
        this.allowPrepay = record.allowPrepay
        this.visibleCheck = (record.allowPrepay == 1)
      }
      this.visible = true;
      const data = pick(this.model, 'id', 'strategyId', 'featuresId', 'sortNum', 'andGroup', 'rule', 'isDel');
      data.allowPrepay = !!data.allowPrepay;
      this.$nextTick(() => {
        this.form.setFieldsValue(data);
      })
    },
    onChose(checked) {
      if (checked) {
        this.allowPrepay = 1
        this.visibleCheck = true
      } else {
        this.allowPrepay = 0
        this.visibleCheck = false
      }
    },
    // 确定
    handleOk() {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          formData.allowPrepay = +formData.allowPrepay ? 1 : 0;
          let obj = editRiskRule(formData)

          obj.then((res) => {
            if (res.code === 1000) {
              that.$message.success(res.msg)
              that.$emit('ok')
            } else {
              that.$message.warning(res.msg)
            }
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
        }
      })
    },
    // 关闭
    handleCancel() {
      this.close()
    },
    close() {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
