<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="'到期日'">
              <a-range-picker
                v-model="queryParam.endTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'费率'">
              <a-input :placeholder="$t('enter') + ' 费率'" v-model="queryParam.serviceFee"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item :label="'产品类型'">
              <a-select
                v-model="queryParam.appssid"
                @change="onChange"
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="'期限'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.duration">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="7">
                  7天
                </a-select-option>
                <a-select-option value="10">
                  10天
                </a-select-option>
                <a-select-option value="14">
                  14天
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        bordered
        @change="handleTableChange">
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import {getApplist, getOrderAllCount} from '@/api/order'
import Dict from '../modules/Dict';
import { REFUND_TRADE_STATUS } from '../../constants/status';
import RefundTradeModal from './modules/RefundTradeModal'
import moment from "moment";

export default {
  name: 'AppList',
  components: {
    RefundTradeModal,
    Dict
  },
  data () {
    return {
      visible: false,
      REFUND_TRADE_STATUS,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },

      headers: {
        // authorization: 'authorization-text',
      },

      dataSource: [],
      appList: [],
      // 查询条件
      queryParam: {
        accountNumber: '',
        appssid:'',
        serviceFee:''
      },
      loading: false,
      // 表头
      columns: [
        // {
        //   title: '到期日',
        //   align: 'left',
        //   dataIndex: 'endDate'
        // },
        {
          title: '到期日订单数',
          align: 'left',
          dataIndex: 'endOrderCount'
        },
        {
          title: '到期未结清订单数',
          align: 'left',
          dataIndex: 'endUnSettleOrderCount'
        },
        {
          title: '坏账率（%）',
          align: 'left',
          dataIndex: 'badRate'
        },
        {
          title: '坏账率（%金额）',
          align: 'left',
          dataIndex: 'badAmtRate'
        },
        {
          title: '首贷到期日订单数',
          align: 'left',
          dataIndex: 'firstEndOrderCount'
        },
        {
          title: '首贷到期未结清订单数',
          align: 'left',
          dataIndex: 'firstEndUnSettleOrderCount'
        },
        {
          title: '首贷坏账率（%）',
          align: 'left',
          dataIndex: 'firstBadRate'
        },
        {
          title: '首贷坏账率（%金额）',
          align: 'left',
          dataIndex: 'firstBadAmtRate'
        },
        {
          title: '复贷到期日订单数',
          align: 'left',
          dataIndex: 'reEndOrderCount'
        },
        {
          title: '复贷到期未结清订单数',
          align: 'left',
          dataIndex: 'reEndUnSettleOrderCount'
        },
        {
          title: '复贷坏账率（%）',
          align: 'left',
          dataIndex: 'reBadRate'
        },
        {
          title: '复贷坏账率（%金额）',
          align: 'left',
          dataIndex: 'reBadAmtRate'
        }
      ],
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    // this.loadData(1)
    getApplist().then(res => {
      this.appList = res.data || [];
    })
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getOrderAllCount(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data;
          if(this.dataSource != null){
            for(var i = 0 ; i < this.dataSource.length ; i++){
                this.dataSource[i].endOrderCount = this.dataSource[i].endOrderCount + "(" + this.dataSource[i].endOrderAmt + ")";
                this.dataSource[i].endUnSettleOrderCount = this.dataSource[i].endUnSettleOrderCount + "(" + this.dataSource[i].endUnSettleOrderAmt + ")";
                this.dataSource[i].firstEndOrderCount = this.dataSource[i].firstEndOrderCount + "(" + this.dataSource[i].firstEndOrderAmt + ")";
                this.dataSource[i].firstEndUnSettleOrderCount = this.dataSource[i].firstEndUnSettleOrderCount + "(" + this.dataSource[i].firstEndUnSettleOrderAmt + ")";
                this.dataSource[i].reEndOrderCount = this.dataSource[i].reEndOrderCount + "(" + this.dataSource[i].reEndOrderAmt + ")";
                this.dataSource[i].reEndUnSettleOrderCount = this.dataSource[i].reEndUnSettleOrderCount + "(" + this.dataSource[i].reEndUnSettleOrderAmt + ")";
              }
          }
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        endTimeStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
        endTimeEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange (pagination, filters, sorter) {
      // TODO 筛选
      // if (Object.keys(sorter).length > 0) {
      //   this.isorter.column = sorter.field
      //   this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      // }
      this.ipagination = pagination
      this.loadData()
    },
    handleAdd () {
      this.$refs.modalForm.add()
      // this.$refs.modalForm.title = '新增'
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.appSsid = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk () {
      this.loadData()
    },
    handleChangeUpload(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
