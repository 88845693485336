<template>
	<div>
        <a-form layout="inline" @keyup.enter.native="loadData(1)">
            <a-row :gutter="24">
                <a-col :md="6" :sm="8">
                    <a-form-item label="名称" :labelCol="{span: 5}" :wrapperCol="{span: 18, offset: 1}">
                        <a-input placeholder="请输入名称查询" v-model="form.keywords"></a-input>
                    </a-form-item>
                </a-col>
                <span style="float: left; margin-top: 5px; display: block; margin-bottom: 24px;white-space: nowrap; overflow: hidden;" class="table-page-search-submitButtons">
                    <a-col :md="6" :sm="24">
                        <a-button type="primary" @click="loadData(1)">{{ $t('search') }}</a-button>
                        <a-button style="margin-left: 8px" @click="searchReset">{{ $t('reset') }}</a-button>
                    </a-col>
                </span>
            </a-row>
        </a-form>
        <div class="table-operator"  style="margin-top: 5px">
            <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
        </div>
        <a-table
            :columns="columns"
            :dataSource="dataSource"
            :pagination="false"
            :loading="loading"
            ref="table"
            size="middle"
            rowKey="id"
            bordered
            @change="handleTableChange">
            <span slot="action" slot-scope="text, record">
                <a @click="handleEdit(record)">{{ $t('edit') }}</a>

                <a-divider type="vertical" />
                <a-dropdown>
                    <a class="ant-dropdown-link">
                    {{ $t('more') }} <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item>
                            <a @click="handlePerssion(record)">授权</a>
                        </a-menu-item>
                        <a-menu-item>
                            <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">
                                <a>{{ $t('delete') }}</a>
                            </a-popconfirm>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </span>
        </a-table>
        <div style="margin-top: 15px; text-align: right;">
            <v-pagination
                v-if="total"
                :page-num="form.pageNum"
                :page-size="form.pageSize"
                :total="total"
                @change="handleTableChange" />
        </div>
        <role-modal ref="modalForm" @ok="modalFormOk"></role-modal>
        <user-role-modal :menu-tree="menuTree" ref="modalUserRole" @ok="roleModalFormOk"></user-role-modal>
	</div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import RoleModal from '../modules/RoleModal'
    import UserRoleModal from '../modules/UserRoleModal'

    const I18N_KEY = 'system.roleList.';
    export default {
        components: {
            RoleModal,
            UserRoleModal,
        },
        data () {
            return {
                I18N_KEY,
                loading: false,
                form: {
                    pageNum: 1,
                    pageSize: 10,
                    keywords: undefined,
                },
                defaultBasicForm: {
                    roleName: undefined,
                    roleCode: undefined,
                    roleDesc: undefined,
                },
                basicForm: {},
                USER_STATUS: {
                    1: '启用',
                    2: '禁用',
                },
                ROLE_TYPE: {
                    1: '系统角色',
                    2: '应用角色',
                },
                modalTitle: '',
                visible: false,
                dataSource: [],
                menuList: [],
            }
        },
        computed: {
            ...mapGetters('system', [
                'roleList',
            ]),
            ...mapGetters({
                mainMenu: 'menus',
            }),
            menuTree () {
                return this.transformMenuListToTree(this.menuList);
            },
            // 表头
            columns () {
                return [
                    {
                        title: this.$t(I18N_KEY + 'index'),
                        dataIndex: '',
                        key:'rowIndex',
                        width:60,
                        align:"center",
                        customRender:function (t,r,index) {
                          return parseInt(index)+1;
                        }
                    },

                    {
                        title: this.$t(I18N_KEY + 'name'),
                        align:"center",
                        dataIndex: 'roleName'
                    },
                    {
                        title: this.$t(I18N_KEY + 'code'),
                        align:"center",
                        dataIndex: 'roleCode'
                    },
                    {
                        title: this.$t(I18N_KEY + 'type'),
                        dataIndex: 'type',
                        align:"center",
                        customRender: (text, rrecord, index) => {
                          return this.ROLE_TYPE[text];
                        }
                    },
                    {
                        title: this.$t(I18N_KEY + 'description'),
                        align:"center",
                        dataIndex: 'roledesc'
                    },
                    {
                        title: '操作',
                        dataIndex: 'action',
                        align:"center",
                        scopedSlots: { customRender: 'action' },
                    }]
            },
            total () {
                return this.roleList && this.roleList.total || 0;
            },
        },
        watch: {
            roleList (data) {
                const list = (data && data.rows) || []
                list.length && (this.dataSource = list);
            }
        },
        created () {
            window.xx = this;
            this.basicForm = { ...this.defaultBasicForm };
            this.loadData();
        },
        methods: {
            ...mapActions('system', [
                'getRoleList',
                'addRole',
                'editRole',
                'getMenuListTree',
                'getRolePermission',
                'setRolePermission',
            ]),
            handleTableChange (pageNum, pageSize) {
                this.form.pageNum = pageNum;
                this.form.pageSize = pageSize;
                this.loadData();
            },
            transformMenuListToTree (list) {
                return list.map(item => {
                    return {
                        ...item,
                        value: '' + item.id,
                        title: item.nameCn,
                        key: '' + item.id,
                        children: this.transformMenuListToTree(item.children || [])
                    }
                })
            },
            modalFormOk(data) {
                this.createRole(data);
            },
            roleModalFormOk (data) {
                data = {
                    menuIds: data,
                }
                this.setRole(data);
                this.$refs.modalUserRole.close();
            },
            handleAdd () {
                this.roleId = undefined;
                this.$refs.modalForm.add();
                this.$refs.modalForm.title = "新增";
                this.$refs.modalForm.disableSubmit = false;
            },
            handleEdit (record) {
                this.roleId = record.roleId;
                this.$refs.modalForm.edit(record);
                this.$refs.modalForm.title = "编辑";
                this.$refs.modalForm.disableSubmit = false;
            },
            handlePerssion (record) {
                this.roleId = record.roleId;
                if (this.menuList && this.menuList.length) {
                    this.$refs.modalUserRole.open();
                    this.getRolePermission({ roleId: record.roleId }).then(res => {
                        const roleIds = res && res.data;
                        this.$refs.modalUserRole.setChecked(this.menuList, roleIds)
                    })
                } else {
                    this.$refs.modalUserRole.open();
                    this.$refs.modalUserRole.openLoading();
                    this.requestMenuListAndRolePermission(record.roleId).then(res => {
                        const menu = (res[0] && res[0].data || []);
                        const roleIds = res[1] && res[1].data;
                        this.menuList = menu;
                        this.$refs.modalUserRole.setChecked(menu, roleIds)
                        this.$refs.modalUserRole.closeLoading();
                    }).catch(e => {
                        this.$refs.modalUserRole.closeLoading();
                        this.$message.error(e.message);
                    })
                }
            },
            changeModal (visible) {
                this.visible = visible;
            },
            requestMenuListAndRolePermission (id) {
                return Promise.all([
                    this.getMenuListTree(),
                    this.getRolePermission({ roleId: id }),
                ])
            },
            deleteCurrent (row) {
                this.loading = true;
                this.deleteUser({ userId: row.roleId }).then(res => {
                    this.$message.success(this.$t('success'));
                    this.loadData(1);
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            },
            createRole (data) {
                const form = {
                    ...data,
                    roleId: this.roleId,
                };
                const requestField = this.roleId ? 'editRole' : 'addRole'
                this[requestField](form).then(res => {
                    this.$refs.modalForm.closeLoading();
                    this.$refs.modalForm.close();
                    this.$message.success('添加成功');
                    this.loadData(1);
                }).catch(e => {
                    this.$refs.modalForm.closeLoading();
                    this.$message.error(e.message);
                })
            },
            setRole (data) {
                const form = {
                    ...data,
                    roleId: this.roleId,
                };
                this.loading = true;
                this.setRolePermission(form).then(res => {
                    this.$message.success('添加成功');
                    this.loadData(1);
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                })
            },
            searchReset () {
                this.form = {
                    keywords: undefined,
                    pageNum: 1,
                    pageSize: 10,
                }
            },
            loadData (pageNum) {
                if(pageNum) { this.form.pageNum = pageNum; }
                this.loading = true;
                this.getRoleList(this.form).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }
        }
    }
</script>
<style scoped>
  @import '../../assets/less/common.less';
</style>
