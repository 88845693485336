<template>
    <div :bordered="false">

        <div class="table-page-search-wrapper">

            <a-form layout="inline" @keyup.enter.native="searchQuery">
                <a-row :gutter="12">
                    <a-col :span="12">
                        <a-form-item :label="'日期'" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
                            <!--单日期组件-->
                            <a-date-picker
                                    v-model="queryParam.dataDt"
                                    format="YYYY-MM-DD"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <!-- 图片上传组件 -->
                <div class="btn_select" @click="selectLoadTxt">
                    <div>
                        <input type="file" name="file" @change="changeTxtFile" ref="txtfileadd">
                    </div>
                </div>
                <br/><br/>
                <div style="display: flex; width: 100%">
                    <button type="submit" style="margin-right: 100px;width: 10%;height:100%;" class="btn_submit"
                            @click="uploadTxtContent">上传原始rapipago文件
                    </button>

                    <a-row>
                        <a-col>
                          <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                              <a-button type="primary" @click="downLoadData()" icon="download" 
                            style="margin-left: 8px">导出转换后的excel文件
                              </a-button>
                         </span>
                        </a-col>
                    </a-row>
                </div>
            </a-form>
        </div>
    </div>
</template>

<script>
    import {filterObj} from '@/utils/util'
    import moment from "moment";
    import exportFile from "@/api/exportFile";
    import Vue from 'vue'
    import JsonViewer from "vue-json-viewer";
    // Vue.use(JsonViewer)
    import CodeDiff from "vue-code-diff";
    import {axios} from '@/utils/request'
    import 'vue-json-viewer/style.css';

    export default {
        name: 'AppList',
        components: {},
        data() {
            return {
                visible: false,
                txtVisdialog: true,
                filex: "",
                headers: {
                    // authorization: 'authorization-text',
                },
                // 查询条件
                queryParam: {
                    dataDt: undefined,
                }
            }
        },
        created() {

        },
        methods: {
            getQueryParams() {
                const {dataDt} = this.queryParam;
                const data = {
                    ...this.queryParam,
                    startDate: dataDt && this.transformTime(dataDt)
                }
                delete data.dataDt;

                return filterObj(data)
            },
            changeTxtFile() {
                this.filex = this.$refs.txtfileadd.files[0]
            },
            transformTime(time) {
                return moment(time).format('YYYY-MM-DD')
            },

            selectLoadTxt() {
                this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
            },

            uploadTxtContent() {
                let txtFile = this.filex;//获取到上传的文件
                if (!txtFile) {
                    alert('请选择要上传的文件');
                    return false;
                }
                let formData = new FormData();//通过formdata上传
                formData.append('file', txtFile)
                var params = this.getQueryParams()// 查询条件
                var startDate = params.startDate;
                if (!startDate) {
                    alert('请选择日期');
                    return false;
                }
                formData.append('startDate', startDate)
                this.axios({
                    header: {
                        "Content-Type": "multipart/form-data",
                    },
                    method: "post",
                    url: "/payChecking/rapipagoCheckFile",
                    data: formData,
                    core: true
                })
                    .then((res) => {
                        if (res.code === 1000) {
                            this.$refs.txtfileadd.value = ''; // 清空file文件内容
                            this.filex = '';
                            this.$message.success(this.$t('success'))
                        } else {
                            this.$refs.txtfileadd.value = ''; // 清空file文件内容
                            this.filex = '';
                            this.$message.error(res.msg)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })

            },
            downLoadData() {
                var params = this.getQueryParams()// 查询条件
                var startDate = params.startDate;
                if (!startDate) {
                    alert('请选择日期');
                    return false;
                }
                const file = {
                    fileUrl: '/api/web/webpublic/payChecking/exportRapipagoCheckFile?startDate=' + params.startDate,
                    fileName: params.startDate + '-success.xlsx',
                }
                this.loading = true;
                exportFile(file).catch(e => {
                    this.$message.error(e.message);
                }).finally(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    @import "~@/assets/less/common.less";
</style>
