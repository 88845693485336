import api from './index'
import { axios } from '@/utils/request'

export function getCustomerList (parameter = {}) {
  return axios({
    url: '/customer/list',
    method: 'post',
    data: parameter
  })
}

export function getCustomerQryList (parameter = {}) {
  return axios({
    url: '/customer/infoQryList',
    method: 'post',
    data: parameter
  })
}
export function deleteJsonData (parameter = {}) {
  return axios({
    url: '/customer/deleteJSONdata',
    method: 'post',
    data: parameter
  })
}

export function editCustomer (parameter = {}) {
  return axios({
    url: '/customer/edit',
    method: 'post',
    data: parameter
  })
}

export function getCustomerDetail (parameter = {}) {
  return axios({
    url: '/customer/detail',
    method: 'post',
    data: parameter
  })
}

export function checkBankCard (parameter = {}) {
  return axios({
    url: '/risk/bankCheck',
    method: 'post',
    data: parameter
  })
}

export function operationInfoForCust (parameter = {}) {
  return axios({
    url: '/statistics/operationInfoForCust',
    method: 'post',
    data: parameter
  })
}

export function addWritelist(parameter = {}) {
  return axios({
    url: '/customer/addWritelist',
    method: 'post',
    data: parameter
  })
}

export function removeSmsCount(parameter = {}) {
  return axios({
    url: '/customer/removeSmsCount',
    method: 'post',
    data: parameter
  })
}


export function getCustLeavingMessage (parameter = {}) {
  return axios({
    url: '/customer/listCustComplain',
    method: 'post',
    data: parameter
  })
}


export function getCustLeavingMessageDetail (parameter = {}) {
  return axios({
    url: '/customer/viewCustComplain',
    method: 'post',
    data: parameter
  })


}

export function clearGoogleAccount (parameter = {}) {
  return axios({
    url: '/executeJob',
    method: 'post',
    data: parameter
  })


}

export function updateCustLeavingMessage (parameter = {}) {
  return axios({
    url: '/customer/replyCustComplain',
    method: 'post',
    data: parameter
  })
}

export function getCustById (custInfoId) {
  return axios({
    url: '/customer/selectById?custInfoId=' + custInfoId,
    method: 'get'
  })
}

export function getCustOrderDetail (appSsid, mobileNo) {
  return axios({
    url: '/customer/custOrderDetail?appSsid=' + appSsid + '&mobileNo=' + mobileNo,
    method: 'post',
  })
}

export function getRiskOperationInfo(mobileNo, appSsid) {
  return axios({
    url: '/trans/getRiskPointByParams?mobileNo=' + mobileNo + '&appSsid=' + appSsid,
    method: 'post'
  })
}



