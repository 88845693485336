<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col  :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.REPAYREPORT + 'requestDate')" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
               <a-range-picker
                  v-model="queryParam.examineDate"
                  format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery('', '')" icon="search" style="margin-left: 8px">{{ $t('search') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :rowClassName="rowClassName"
        :dataSource="dataSource"
        :pagination="false"
        :scroll="{x:1000}"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS">{{ text }}</Dict>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import {getApplist, getRepaymentYYReportForBeginDate} from '@/webpublicapi/order'
import exportFile from '@/webpublicapi/exportFile'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
  REPAYREPORT: 'repayReport.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      appList: [],
      dataSource: [],
      // 查询条件
      queryParam: {
        "examinerName":"",
        "appssid":"",
        "duration":"",
        "examineDate":undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'curDate'),
          align: 'left',
          width: '100px',
          dataIndex: 'curDate'
        },
        {
          title: '到期笔数',
          align: 'left',
          width: '100px',
          dataIndex: 'repayCurCount'
        },
        {
          title: '新户到期',
          align: 'left',
          width: '100px',
          dataIndex: 'repayCurCountNew'
        },
        {
          title: '老户到期',
          align: 'left',
          width: '100px',
          dataIndex: 'repayCurCountOld'
        },
        {
          title: '首逾',
          align: 'left',
          width: '100px',
          dataIndex: 'firstOverDueRate'
        },
        {
          title: '新户首逾',
          align: 'left',
          width: '100px',
          dataIndex: 'firstOverDueRateNew'
        },
        {
          title: '老户首逾',
          align: 'left',
          width: '100px',
          dataIndex: 'firstOverDueRateOld'
        },
        {
          title: '逾期',
          align: 'left',
          width: '100px',
          dataIndex: 'overTotalCount'
        },
        {
          title: '新户逾期',
          align: 'left',
          width: '100px',
          dataIndex: 'overTotalCountNew'
        },
        {
          title: '老户逾期',
          align: 'left',
          width: '100px',
          dataIndex: 'overTotalCountOld'
        },
        {
          title: 'T0催回率',
          align: 'left',
          width: '100px',
          dataIndex: 'T0colbackRate'
        },
        {
          title: 'T0旧户催回率',
          align: 'left',
          width: '110px',
          dataIndex: 'T0colbackRateOld'
        },
        {
          title: 'T0新户催回率',
          align: 'left',
          width: '110px',
          dataIndex: 'T0colbackRateNew'
        },
        ,
        {
          title: '整体催回率',
          align: 'left',
          width: '100px',
          dataIndex: 'colbackRate'
        },
        {
          title: '新户整体催回率',
          align: 'left',
          width: '115px',
          dataIndex: 'colbackRateNew'
        },
        {
          title: '老户整体催回率',
          align: 'left',
          width: '115px',
          dataIndex: 'colbackRateOld'
        },
        {
          title: 'S1催回率',
          align: 'left',
          width: '100px',
          dataIndex: 'S1colbackRate'
        },
        {
          title: 'S1新户催回率',
          align: 'left',
          width: '110px',
          dataIndex: 'S1colbackRateNew'
        },
        {
          title: 'S1老户催回率',
          align: 'left',
          width: '110px',
          dataIndex: 'S1colbackRateOld'
        },
        {
          title: 'S2催回率',
          align: 'left',
          width: '100px',
          dataIndex: 'S2colbackRate'
        },
        {
          title: 'S2新户催回率',
          align: 'left',
          width: '110px',
          dataIndex: 'S2colbackRateNew'
        },
        {
          title: 'S2老户催回率',
          align: 'left',
          width: '110px',
          dataIndex: 'S2colbackRateOld'
        },
        {
          title: 'S3催回率',
          align: 'left',
          width: '100px',
          dataIndex: 'S3colbackRate'
        },
        {
          title: 'S3新户催回率',
          align: 'left',
          width: '110px',
          dataIndex: 'S3colbackRateNew'
        },
        {
          title: 'S3老户催回率',
          align: 'left',
          width: '110px',
          dataIndex: 'S3colbackRateOld'
        }
      ]
    }
  },
  created () {
    this.loadData(1, '', '');
    getApplist().then(res => {
      this.appList = res.data || [];
    })
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery (arg, arg2) {

      this.loadData(1, arg, arg2)
    },
    loadData (arg,arg2,arg3) {
      // 加载数据 若传入参数1则加载第一页的内容
      console.log(arg2)
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams(arg2, arg3)// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value);
      getRepaymentYYReportForBeginDate(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    downLoadData () {
      const file = {
        fileUrl: '/api/web/statistics/platform/repaymentReportExcel?' + this.exportParams,
        fileName: 'report.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
          this.$message.error(e.message);
      }).finally(() => {
          this.loading = false;
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
       return str && str.toLocaleLowerCase();
    },
    getQueryParams (arg, arg3) {
      const { examineDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          startDate: examineDate && this.transformTime(examineDate[0]),
          endDate: examineDate && this.transformTime(examineDate[1]),
          loanCount: arg,
          // orderType: arg3,
      }
      delete data.examineDate;
      return filterObj(data)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    },
    rowClassName(record,index){
      var date = new Date(record.curDate);
      var day = date.getTime() / 24 / 60 / 60 / 1000 + 3;
      console.log(record.curDate , date, (day % 7) + 1);

      if((day % 7) + 1 == 7){
        return 'table_weekendColor';
      }
      return '';
    }
  }
}
</script>

<style lang="less" scoped>
 @import "~@/assets/less/common.less";

</style>
