<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">

          <a-col :span="12">
            <a-form-item :label="$t(I18N_KEY.REPAYREPORT + 'requestDate')" :labelCol="{span: 6}"
                         :wrapperCol="{span: 14, offset: 1}">
              <a-range-picker
                v-model="queryParam.examineDate"
                showTime
                style="width: 522px;"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item :label="$t(I18N_KEY.REPAYREPORT + 'appSsid')" :labelCol="{span: 6}"
                         :wrapperCol="{span: 14, offset: 1}">
              <a-select v-model="queryParam.appSsid" allow-clear placeholder="请选择应用" :options="options.app"
                        style="width: 200px;"></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery('')" icon="search" style="margin-left: 8px">{{
                  $t('search')
                }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{
                  $t('reset')
                }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        ref="table"
        size="middle"
        rowKey="id">
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">
            查看
          </a>
        </span>
      </a-table>

    </div>
    <a-modal
      v-model="visible"
      :title="`${strategyName}${start}到${end}的通过率详情`"
      width="800px">
      <div style="padding: 20px;">
        <riskStatisticsDetail
          v-if="visible"
          :name="strategyName"
          :id="strategyId"
          :appSsid="appSsid"
          :start="start"
          :end="end"
          @close="visible = false;"/>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import {filterObj} from '@/utils/util'
import {getRiskStatistics} from '@/webpublicapi/risk'
import exportFile from '@/webpublicapi/exportFile'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment';
import riskStatisticsDetail from './riskStatisticsDetail.vue'
import {getAppList} from "@/webpublicapi/system";

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
  REPAYREPORT: 'repayReport.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
    riskStatisticsDetail
  },
  data() {
    return {
      I18N_KEY,
      toggleSearchStatus: false,
      strategyId: undefined,
      appSsid:undefined,
      strategyName: '',
      start: '',
      end: '',
      visible: false,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "appSsid": "",
        "examineDate": [moment().startOf('day'), moment().add('days',1).startOf('day')],
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: {span: 8},
        sm: {span: 5}
      },
      wrapperCol: {
        xs: {span: 16},
        sm: {span: 19}
      },
      options:{app:[]}
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '策略id',
          align: 'left',
          dataIndex: 'id'
        },
        {
          title: '策略名称',
          align: 'left',
          dataIndex: 'name'
        },
        {
          title: '总数',
          align: 'left',
          dataIndex: 'count'
        },
        {
          title: '通过数',
          align: 'left',
          dataIndex: 'passCount'
        },

        {
          title: '通过率',
          align: 'left',
          dataIndex: 'passRate'
        },

        {
          title: '操作',
          dataIndex: 'action',
          align: "center",
          scopedSlots: {customRender: 'action'},
        }
      ]
    }
  },
  created() {
    getAppList({pageNum: 1, pageSize: 999999999}).then(({code, data}) => {
      if (code === 1000) {
        this.options.app = data.rows.map(({appName, appSsid}) => ({label: appName, value: appSsid}))
      } else {
        throw new Error('')
      }
    })
    this.loadData(1)
  },
  methods: {
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery(arg) {

      this.loadData(1, arg)
    },
    loadData(arg, arg2) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }

      this.loading = true
      var params = this.getQueryParams(arg2)// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value);

      getRiskStatistics(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          // this.total = res.data && res.data.total
          this.dataSource = res.data || [];
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    downLoadData() {
      const file = {
        fileUrl: '/api/web/statistics/repaymentReportExcel?' + this.exportParams,
        fileName: 'report.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    transformStringToLow(str) {

      return str && str.toLocaleLowerCase();
    },
    getQueryParams(arg) {
      const {examineDate} = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: examineDate && this.transformTime(examineDate[0]),
        endDate: examineDate && this.transformTime(examineDate[1]),
        loanCount: arg,
      }

      delete data.examineDate;
      return filterObj(data)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    }, handleEdit(record) {
      this.strategyId = record.id;
      this.strategyName = record.name;
      const {examineDate} = this.queryParam;
      this.start = examineDate && this.transformTime(examineDate[0]);
      this.appSsid = this.queryParam.appSsid;
      this.end = examineDate && this.transformTime(examineDate[1]);
      this.visible = true;
    }

  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
