<template>
  <a-modal :title="modal.title" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @ok="submit"
           @cancel="hide">
    <a-form :form="form">
      <a-form-item label="策略标识">
        <a-input v-decorator="['name',{rules:[{required:true,message:'策略标识不能为空'}]}]"></a-input>
      </a-form-item>
      <a-form-item label="策略说明">
        <a-input v-decorator="['intro',{rules:[{required:true,message:'策略说明不能为空'}]}]"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import {addRiskStrategy, copyRiskStrategy} from "@/api/risk";

export default {
  name: 'RuleEditModal',
  data() {
    return {
      modal: {title: '', visible: false, confirmLoading: false},
      form: this.$form.createForm(this),
      model: {id: undefined}
    }
  },
  methods: {
    showAdd() {
      this.modal.visible = true
      this.modal.title = '创建策略'
    },
    showCopy(id) {
      this.modal.visible = true
      this.modal.title = '拷贝策略'
      this.model.id = id
    },
    hide() {
      this.modal = {title: '', visible: false, confirmLoading: false}
      this.form.resetFields()
      this.model = {id: undefined}
    },
    submit() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.modal.confirmLoading = true;
          (this.model.id ? copyRiskStrategy(Object.assign({}, this.model, values)) : addRiskStrategy(Object.assign({}, values))).then(({code}) => {
            if (code === 1000) {
              this.$message.success(this.$t('success'))
              this.hide()
              this.$emit('success')
            } else {
              return Promise.reject('')
            }
          }).catch(() => {
            this.$message.error('操作失败')
            this.modal.confirmLoading = false
          })
        }
      })
    }
  }
}
</script>