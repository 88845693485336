<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="6" :sm="8">
            <a-form-item label="统计时间">
              <a-date-picker
                v-model="queryParam.endTime"
                :allowClear = false
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.department')">
              <a-select
                v-model="queryParam.departmentId"
                @change="onChangeOne"
              >
                <a-select-option v-for="item in departmentList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.grouping')">
              <a-select
                v-model="queryParam.groupId"
              >
                <a-select-option v-for="item in groupList" :key="item.id">
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>


        <span slot="action" slot-scope="text, record">
              <a @click="dealDiffUser(record)">详情</a>
            </span>
      </a-table>

      <collection-diff-user ref="CollectionDiffUser"/>

    </div>

  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getColUserDealCountV2, getSysGroup } from '@/api/platformCollection'
import { getCollectionListDepartment } from '@/api/group'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import CollectionDetail from './CollectionDetail';
import CollectionDiffUser from './modules/CollectionDiffUser';
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'AllApprovalTask',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
    CollectionDiffUser,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      groupList: [],
      departmentList: [],
      // 查询条件
      queryParam: {
        "departmentId":"",
        "groupId":"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [

        {
          title:  '催收阶段名称',
          align: 'left',
          dataIndex: 'normalConfigName'
        },
        {
          title:  '统计数值',
          align: 'left',
          dataIndex: 'countNum'
        },
        {
          title:  '前一天统计数值',
          align: 'left',
          dataIndex: 'beforeOneCountNum'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.getDict();
    this.loadData();
    getSysGroup().then(res => {
      this.groupList = res.data || [];
    });
    getCollectionListDepartment().then(res => {
      this.departmentList = res.data || [];
    });
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData()
    },
    loadData (arg) {
      this.loading = true
      var params = this.getQueryParams()// 查询条件

      getColUserDealCountV2(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    dealDiffUser (record) {
      var params = this.getQueryParams()
      this.$refs.CollectionDiffUser.show(params,record)
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
          ...this.queryParam,
         endTime : this.transformTime(endTime) === null ? undefined:this.transformTime(endTime)
      }
     // delete data.endTime;
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.endTime = undefined;
      that.queryParam.departmentId = '';
      that.queryParam.groupId = '';
      that.loadData()
    },
    changeModal (visible) {
        this.visible = visible;
    },
    onChangeOne(value) {
      getSysGroup({"departmentId": value}).then(res => {
        this.groupList = res.data || [];
        this.queryParam.groupId = "";
      });
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
