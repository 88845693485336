<template>
  <a-modal title="编辑" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @ok="submit" @cancel="hide">
    <a-form :form="form">
      <a-form-item label="默认值">
        <a-input
          v-decorator="['defaultValue',{rules:[{required:true,message:'规则不能为空'},{pattern:/^(?:true|false|(?:\[missing]|\[&quot;.*&quot;]|[(\[](?:-Inf|missing|-?\d+(?:.\d+)?),(?:Inf|missing|-?\d+(?:.\d+)?)[)\]])(?:U(?:\[missing]|\[&quot;.*&quot;]|[(\[](?:-Inf|missing|-?\d+(?:.\d+)?),(?:Inf|missing|-?\d+(?:.\d+)?)[)\]]))*)$/,message:'规则格式错误'}]}]"></a-input>
      </a-form-item>
    </a-form>
    <div>
      true 真值（必须独立使用）<br/>
      false 假值（必须独立使用）<br/>
      [missing] 空值<br/>
      ["some string with"] 单个字符串<br/>
      [-Inf,Inf] 正负无穷，开闭空间均可<br/>
      [missing,missing] 空值等价正负无穷<br/>
      [float,float] 闭区间<br/>
      (float,float) 开区间<br/>
      多个区间使用U取并，如：[-Inf,1)U[2,missing)
    </div>
  </a-modal>
</template>
<script>
import {updateRiskFeature} from "@/webpublicapi/risk";

export default {
  name: 'VariableEditModal',
  data() {
    return {
      modal: {
        visible: false,
        confirmLoading: false,
      },
      form: this.$form.createForm(this),
      model: {id: undefined}
    }
  },
  methods: {
    show({id, defaultValue} = {}) {
      if (!id) {
        this.$message.error('ID错误')
        return
      }
      this.form = this.$form.createForm(this, {
        mapPropsToFields: () => {
          return {
            defaultValue: this.$form.createFormField({value: defaultValue}),
          }
        }
      })
      this.modal.visible = true
      this.model = {id}
    },
    hide() {
      this.modal.visible = false
      this.model = {id: undefined}
    },
    submit() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.modal.confirmLoading = true
          updateRiskFeature(Object.assign({}, this.model, values)).then(() => {
            this.$message.success(this.$t('success'))
            this.$emit('success')
            this.hide()
          }).catch(({message}) => {
            this.$message.error(message)
          }).finally(() => {
            this.modal.confirmLoading = false
          })
        }
      })
    }
  }
}
</script>