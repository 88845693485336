<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('custServiceWorkOrder.dataType')">
              <a-select v-model="queryParam.dataType" :placeholder="$t('select')" style="width: 100%;" @change="handleDataTypeChange(queryParam.dataType)">
                <a-select-option v-for="item in dataTypes" :value="item">
                  {{ $t('custServiceWorkOrder.dataType_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :md="6" :sm="8" >
              <a-form-item :label="$t(I18N_KEY + 'orderInfoId')">
                <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'orderInfoId')" v-model="queryParam.orderInfoId"></a-input>
              </a-form-item>
            </a-col>
          </template>
          <template v-if="!toggleSearchStatus">
            <a-col :md="6" :sm="8" >
              <a-form-item :label="$t('custServiceWorkOrder.topNum')">
                <a-input :placeholder="$t('enter') + ' ' + $t('custServiceWorkOrder.topNum')" v-model="queryParam.topNum"></a-input>
              </a-form-item>
            </a-col>
          </template>
          <template v-if="!toggleSearchStatus">
            <a-col :md="6" :sm="8" >
              <a-form-item :label="$t('custServiceWorkOrder.lastId')">
                <a-input :placeholder="$t('enter') + ' ' + $t('custServiceWorkOrder.lastId')" v-model="queryParam.lastId"></a-input>
              </a-form-item>
            </a-col>
          </template>
          <template v-if="!toggleSearchStatus">
            <a-col :md="6" :sm="8" >
              <a-form-item :label="$t('custServiceWorkOrder.limitNum')">
                <a-input :placeholder="$t('enter') + ' ' + $t('custServiceWorkOrder.limitNum')" v-model="queryParam.limitNum"></a-input>
              </a-form-item>
            </a-col>
          </template>
          <template>
            <a-col :md="8" :sm="12">
              <a-form-item :label="$t(I18N_KEY + 'createdTime')">
                <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
              <a-button type="primary" @click="downLoadDataWithImage" icon="download" style="margin-left: 8px">{{ $t('custServiceWorkOrder.exportWithImage') }}</a-button>
            </span>
          </a-col>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <span slot="action" slot-scope="text, record">
          <a @click="handleDetail(record)" v-if='record.attachments != null && record.attachments != undefined'>{{ $t('view') }}</a>
        </span>
      </a-table>
            <div style="margin-top: 15px; text-align: right;">
              <v-pagination
                v-if="total"
                :page-num="form.pageNum"
                :page-size="form.pageSize"
                :total="total"
                @change="handleTableChange" />
            </div>
    </div>
    <collection-complaint-attachment ref="attachment" :attachments='attachments' @ok="modalFormOk"></collection-complaint-attachment>
  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import { userStatistics, manualLoadData, dataList, teleMarketUsers, baseData } from '@/webpublicapi/teleMarket'
import moment from 'moment';
import vModal from '../modules/Modal';
import {mapGetters} from "vuex";
import { collectionComplaints } from '@/webpublicapi/collection'
import CollectionComplaintAttachment from '@/views/webpublic/collection/CollectionComplaintAttachment.vue'
import exportFile from '@/webpublicapi/exportFile'

const I18N_KEY = 'collection.';
export default {
  name: 'collectionComplaint',
  components: {
    CollectionComplaintAttachment,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:true,
      attachments: [],
      dataTypes: ["commonData", "top10CollctionUserData"],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "orderInfoId": null,
        "createdTime": [],
        "dataType": 'commonData',
        "limitNum": null,
        "topNum": 10,
        "lastId": 0,
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },

  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: this.$t(I18N_KEY + 'orderInfoId'),
          align: 'center',
          dataIndex: 'orderInfoId'
        },
        {
          title: this.$t('custServiceWorkOrder.orderNo'),
          align: 'center',
          dataIndex: 'orderNo'
        },
        {
          title: this.$t(I18N_KEY + 'remark'),
          align: 'center',
          ellipsis: true,
          dataIndex: 'remark'
        },
        {
          title: this.$t(I18N_KEY + 'createdTime'),
          align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    // this.loadBaseData();
    this.loadData(1);
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      collectionComplaints(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      if(this.queryParam.createdTime.length === 0) {
        data['startDate'] = ''
        data['endDate']=''
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.orderInfoId = null
      that.queryParam.createdTime = []
      that.queryParam.dataType = 'commonData'
      that.queryParam.limitNum = null
      that.queryParam.topNum = 10
      that.queryParam.lastId = 0
      that.toggleSearchStatus = true
    },
    handleDetail(record){
      this.attachments = record.attachmentUrl;
      this.$refs.attachment.open();
    },
    modalFormOk(){
      this.$refs.attachment.closeLoading();
      this.$refs.attachment.close();
    },
    getQueryParams1() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      if(this.queryParam.createdTime.length === 0) {
        data['startDate'] = ''
        data['endDate']=''
      }
      return Object.assign({}, data)
    },
    downLoadData () {
      const params = this.getQueryParams1()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/collectionComplaint/list/export?startDate=' + this.checkParam(params.startDate)
          + '&endDate=' + this.checkParam(params.endDate)
          + '&orderInfoId=' + this.checkParam(params.orderInfoId)
          + '&dataType=' + this.checkParam(params.dataType)
          + '&isExportImage=' + false,
        fileName: 'CollectionComplaint.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    downLoadDataWithImage () {
      const params = this.getQueryParams1()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/collectionComplaint/list/export?startDate=' + this.checkParam(params.startDate)
          + '&endDate=' + this.checkParam(params.endDate)
          + '&orderInfoId=' + this.checkParam(params.orderInfoId)
          + '&dataType=' + this.checkParam(params.dataType)
          + '&topNum=' + this.checkParam(params.topNum)
          + '&lastId=' + this.checkParam(params.lastId)
          + '&limitNum=' + this.checkParam(params.limitNum)
          + '&isExportImage=' + true,
        fileName: 'CollectionComplaint.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    checkParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return '';
      }
      return p;
    },
    handleDataTypeChange(select) {
      if (select === ''||select==='commonData') {
        this.toggleSearchStatus = true;
        this.queryParam.topNum = 10
        this.queryParam.limitNum = null
        this.queryParam.lastId = 0
      } else {
        this.toggleSearchStatus = false;
        this.queryParam.orderInfoId = null
      }
    },
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>