<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item :label="'进件日期'">
              <a-range-picker
                v-model="queryParam.endTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { findLoanStatusCount } from '@/webpublicapi/order'
import vModal from '../modules/Modal';
import moment from 'moment'

const I18N_KEY = 'order.';
export default {
  name: 'loanStatusLoanCount',
  components: {
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visibleRepayUrlCard: false,
      repayUrl: '',
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        endTime: [moment().add('days', -7), moment().add('days', 0)],
      },
      loading: false,
      // 表头
      isorter: {
        column: 'd',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: '进件日期',
          align: 'left',
          dataIndex: 'date'
        },
        {
          title: '未放款+放款中+放款失败',
          align: 'left',
          dataIndex: 'c'
        },
        {
          title: '未放款（首贷/复贷）',
          align: 'left',
          dataIndex: 'loanStatus0'
        },
        {
          title: '放款中（首贷/复贷）',
          align: 'left',
          dataIndex: 'loanStatus3'
        },
        {
          title: '放款失败（首贷/复贷）',
          align: 'left',
          dataIndex: 'loanStatus2'
        }
      ]
    },
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      findLoanStatusCount(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
        endDate : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
    },
    changeModal (visible) {
        this.visible = visible;
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
