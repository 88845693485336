import { axios } from '@/utils/request'
import {mockRepaymentReport, useMock} from "@/api/_mock";

export function getFinancialList (parameter = {}) {
  return axios({
    url: '/statistics/financialList',
    method: 'post',
    data: parameter
  })
}

export function getPvList (parameter = {}) {
  return axios({
    url: '/statistics/pvList',
    method: 'post',
    data: parameter
  })
}

export function getUvList (parameter = {}) {
  return axios({
    url: '/statistics/uvList',
    method: 'post',
    data: parameter
  })
}