<template>
  <a-modal :title="modal.title" :visible="modal.visible" :confirm-loading="modal.confirmLoading" :width="modal.width"
           @cancel="hide">
    <a-row :gutter="48" style="display: flex;align-items: center;padding-bottom: 8px;">
      <a-col :span="6">
        <a-input v-model="model.name" placeholder="请输入规则码"></a-input>
      </a-col>
      <a-col :span="6">
        <a-input v-model="model.intro" placeholder="请输入说明"></a-input>
      </a-col>
      <a-col :span="12">
        <div style="display: flex;align-items: center;">
          已选 {{ table.rowSelection.selectedRowKeys.length }} 项
        </div>
      </a-col>
    </a-row>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="table.columns"
      :dataSource="dataSource"
      :rowSelection="table.rowSelection"
      :loading="table.loading"
      bordered
    >
      <a-switch slot="pick" slot-scope="text" :checked="text===0"></a-switch>
    </a-table>
    <div slot="footer">
      <a-button type="primary" style="margin: 8px;" @click="banPick('pick')">启用</a-button>
      <a-button type="danger" style="margin: 8px;" @click="banPick('ban')">禁用</a-button>
      <a-button type="primary" style="margin: 8px;" @click="banPick('pickAll')">全部启用</a-button>
      <a-button type="danger" style="margin: 8px;" @click="banPick('banAll')">全部禁用</a-button>
    </div>
  </a-modal>
</template>
<script>
import {riskFeaturesUsed, banRiskFeatures} from "@/webpublicapi/risk";

export default {
  name: 'VariableUsedModal',
  data() {
    return {
      modal: {
        title: '使用详情',
        visible: false,
        width: '1200px',
      },
      table: {
        dataSource: [],
        loading: false,
        columns: [
          {
            title: '策略标识',
            align: 'center',
            dataIndex: 'strategyName'
          },
          {
            title: '策略说明',
            align: 'center',
            dataIndex: 'strategyIntro'
          },
          {
            title: '计算规则',
            align: 'center',
            dataIndex: 'rule'
          },
          {
            title: '启用',
            dataIndex: 'pick',
            align: "center",
            scopedSlots: {customRender: 'pick'}
          }
        ],
        rowSelection: {
          selectedRowKeys: [],
          onSelect: this.onSelectRow,
          onSelectAll: this.onSelectAllRow,
          onSelectInvert: this.onSelectInvertRows
        },
      },
      model: {featuresId: undefined, name: '', intro: ''},
    }
  },
  computed: {
    dataSource() {
      return this.table.dataSource.filter(({strategyName, strategyIntro}) => strategyName.indexOf(this.model.name) > -1 && strategyIntro.indexOf(this.model.intro) > -1)
    }
  },
  methods: {
    show(id) {
      this.table.dataSource = []
      if (!id) {
        this.$message.error('ID错误')
        return
      }
      this.modal.visible = true
      this.model.featuresId = id
      this.loadData()
    },
    hide() {
      this.modal.visible = false
      this.modal.model = {featuresId: undefined}
    },
    banPick(type) {
      if (['ban', 'pick'].includes(type) && this.table.rowSelection.selectedRowKeys.length === 0) return this.$message.error('请选择操作条目')
      this.modal.confirmLoading = true
      banRiskFeatures({
        featureId: this.model.featuresId, ...({
          ban: {type: 1, idList: this.table.rowSelection.selectedRowKeys},
          banAll: {type: 1, idList: []},
          pick: {type: 0, idList: this.table.rowSelection.selectedRowKeys},
          pickAll: {type: 0, idList: []}
        })[type]
      }).then(({code, msg}) => {
        if (code === 1000) {
          this.loadData()
          this.modal.confirmLoading = false
        } else {
          return Promise.reject(msg)
        }
      }).catch((e) => {
        this.$message.error(e)
        this.modal.confirmLoading = false
      })
    },
    loadData() {
      this.table.loading = true
      riskFeaturesUsed(this.model).then(({code, data, msg}) => {
        if (code === 1000) {
          this.table.dataSource = data
        } else {
          this.table.dataSource = []
          this.$message.error(msg)
        }
        this.table.loading = false
      }).catch(e => {
        this.table.loading = false;
        this.$message.error(e.message);
      })
    },
    onSelectRow({id}, selected) {
      if (selected) {
        this.table.rowSelection.selectedRowKeys.push(id)
      } else {
        this.table.rowSelection.selectedRowKeys = this.table.rowSelection.selectedRowKeys.filter(item => item !== id)
      }
    },
    onSelectAllRow(selected, selectedRows, changeRows) {
      if (selected) {
        this.table.rowSelection.selectedRowKeys = Array.from(new Set([...this.table.rowSelection.selectedRowKeys, ...selectedRows.map(({id}) => id)]))
      } else {
        changeRows = changeRows.map(({id}) => id)
        this.table.rowSelection.selectedRowKeys = this.table.rowSelection.selectedRowKeys.filter(id => !changeRows.includes(id))
      }
    }
  }
}
</script>