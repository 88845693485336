<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="6">
            <a-form-item :label="$t(I18N_KEY + 'userGroupName')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'userGroupName')" v-model="queryParam.name" :allowClear='allowClear'></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="6">
            <a-form-item :label="$t(I18N_KEY + 'userGroupStatus')">
              <a-select v-model="queryParam.status" :placeholder="$t('select')" style="width: 100%" :allowClear='allowClear'>
                <a-select-option v-for='item in statuses' :key='item.value'>
                  {{item[fieldName]}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
      <div class="table-operator"  style="margin-top: 5px">
        <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('add') }}</a-button>
      </div>
      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1800 }"
        bordered>
        <span slot="status" slot-scope="text, record">
          <a-switch :checked-children="statusText('1')" :un-checked-children="statusText('0')" :checked="text==1" @change='record.status=(text==1?0:1);changeStatus(record.id)'/>
        </span>
        <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">{{ $t('edit') }}</a>
<!--        <a-divider type="vertical" />-->
<!--        <a-dropdown>-->
<!--          <a class="ant-dropdown-link">-->
<!--          {{ $t('more') }} <a-icon type="down" />-->
<!--          </a>-->
<!--          <a-menu slot="overlay">-->
<!--            <a-menu-item>-->
<!--              <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">-->
<!--                <a>{{ $t('delete') }}</a>-->
<!--              </a-popconfirm>-->
<!--            </a-menu-item>-->
<!--          </a-menu>-->
<!--        </a-dropdown>-->
      </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="queryParam.pageNum"
          :page-size="queryParam.pageSize"
          :total="total"
          @change="handleTableChange" />
      </div>
      <tele-market-user-group-edit-modal ref="modalForm" :tele-market-users='teleMarketUsers' :statuses='statuses' :field-name='fieldName' @ok="modalFormOk"></tele-market-user-group-edit-modal>
    </div>
  </div>
</template>
<script>
import { userGroupList, userGroupOperate, userGroupDelete, userGroupBaseData,changeStatus } from '@/webpublicapi/teleMarket'
import TeleMarketUserGroupEditModal from './modules/TeleMarketUserGroupEditModal'
import {mapGetters} from "vuex";
const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketAssignConfig',
  components: {
    TeleMarketUserGroupEditModal,
  },
  data () {
    return {
      I18N_KEY,
      loading: false,
      allowClear: true,
      total: 0,
      teleMarketUsers: [],
      statuses: [],
      fieldName: 'textCn',
      // createdTime: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        name: null,
        status: null,
      },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    // 表头
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          dataIndex: 'id',
          width:60,
          align:"center",
        },
        {
          title: this.$t(I18N_KEY + 'userGroupName'),
          dataIndex: 'name',
          width:120,
          align:"center",
        },
        {
          title: this.$t(I18N_KEY + 'userGroupName_zh'),
          dataIndex: 'nameZh',
          width:120,
          align:"center",
        },
        {
          title: this.$t(I18N_KEY + 'userGroupName_en'),
          dataIndex: 'nameEn',
          width:120,
          align:"center",
        },
        {
          title: this.$t(I18N_KEY + 'userGroupName_es'),
          dataIndex: 'nameEs',
          width:120,
          align:"center",
        },
        {
          title: this.$t(I18N_KEY + 'userGroupName_fr'),
          dataIndex: 'nameFr',
          width:120,
          align:"center",
        },
        // {
        //   title: this.$t(I18N_KEY + 'userGroupStatus'),
        //   align: 'center',
        //   dataIndex: 'status',
        //   width:100,
        //   customRender: (text, record) => {
        //     var text = text;
        //     if(text != undefined && text != null && text != '') {
        //       for(var i in this.statuses) {
        //         if(text === this.statuses[i].value) {
        //           return this.statuses[i][this.fieldName]
        //         }
        //       }
        //     } else {
        //       return '';
        //     }
        //   },
        // },
        {
          title: this.$t(I18N_KEY + 'userGroupStatus'),
          align: 'center',
          dataIndex: 'status',
          width:100,
          scopedSlots: { customRender: 'status' },
        },
        {
          title: this.$t(I18N_KEY + 'countPre'),
          align:"center",
          dataIndex: 'countPre',
          width:80,
        },
        {
          title: this.$t(I18N_KEY + 'teleMarketUser'),
          align:"center",
          dataIndex: 'users',
          customRender: (text, record) => {
            var text = text;
            var result = ""
            if(text != undefined && text != null && text != '') {
              for(var s in text) {
                for(var p in this.teleMarketUsers) {
                  if(text[s] === this.teleMarketUsers[p].mobile) {
                    result = result + this.teleMarketUsers[p].seat+','
                    break;
                  }
                }
              }
              if(result.endsWith(',')) {
                result = result.substring(0, result.length-1);
              }
            }
            return result;
          },
        },
        {
          title: this.$t(I18N_KEY + 'createUser'),
          align:"center",
          dataIndex: 'createUser',
        },
        {
          title: this.$t(I18N_KEY + 'createTime'),
          align:"center",
          width:170,
          dataIndex: 'createTime',
        },
        {
          title: this.$t(I18N_KEY + 'updateUser'),
          align:"center",
          dataIndex: 'updateUser',
        },
        {
          title: this.$t(I18N_KEY + 'updateTime'),
          align:"center",
          width:170,
          dataIndex: 'updateTime',
        },
        {
          title: this.$t(I18N_KEY + 'operate'),
          dataIndex: 'action',
          align:"center",
          width:130,
          fixed: "right",
          scopedSlots: { customRender: 'action' },
        }]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData('');
  },
  methods: {
    loadBaseData(){
      userGroupBaseData({}).then((res) => {
        if (res.code === 1000) {
          this.teleMarketUsers = res.data.seats
          this.statuses = res.data.commonStatuses
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    searchQuery () {
      this.loadData(1)
    },
    handleTableChange (pageNum, pageSize) {
      this.queryParam.pageNum = pageNum;
      this.queryParam.pageSize = pageSize;
      this.loadData();
    },
    modalFormOk(data) {
      this.createSeat(data);
    },
    handleAdd () {
      this.versionId = undefined;
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "1";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit (record) {
      this.versionId = record.id;
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "2";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    deleteCurrent (row) {
      this.loading = true;
      userGroupDelete(row.id).then(res => {
        this.$message.success(this.$t('success'));
        this.loadBaseData();
        this.loadData(1);
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    createSeat (data) {
      console.log(data)
      const form = {
        ...data,
        id: this.versionId,
        opt: this.versionId ? '2' : '1',
      };
      // const requestField = this.versionId ? '2' : '1'
      userGroupOperate(form).then(res => {
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        if (res.code === 1000) {
          this.$message.success(res.msg);
          this.loadBaseData();
          this.loadData(1);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    searchReset () {
      this.createdTime = []
      this.queryParam.pageNum=1
      this.queryParam.pageSize=10
      this.queryParam.name=null
      this.queryParam.status=null
    },
    loadData (pageNum) {
      if(pageNum) { this.queryParam.pageNum = pageNum; }
      this.loading = true;
      userGroupList(this.queryParam).then(res => {
        this.loading = false;
        this.total = res.data.total
        this.dataSource = res.data.rows;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    statusText(status) {
      for(var i in this.statuses) {
        if(status === this.statuses[i].value) {
          return this.statuses[i][this.fieldName]
        }
      }
    },
    changeStatus(id) {
      this.loading = true;
      var model = {
        id: id
      }
      changeStatus(model).then(res => {
        this.loading = false;
        this.$message.info(res.msg);
        this.loadData(this.queryParam.pageNum)
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
