<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
              <a-row :gutter="24">
                <a-col :md="6" :sm="8">
                  <a-form-item :label="$t(I18N_KEY + 'orderNo')">
                    <a-input
                        :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderNo'))"
                        v-model="queryParam.orderNo"/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="8">
                  <a-form-item :label="$t(I18N_KEY + 'orderId')">
                    <a-input
                      :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderId'))"
                      v-model="queryParam.orderId"/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="8">
                  <a-form-item :label="$t('phoneNo')">
                    <a-input
                      :placeholder="$t('enter') + ' ' + transformStringToLow($t('phoneNo'))"
                      v-model="queryParam.mobileNo"></a-input>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="8">
                  <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                    <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
                    <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{x:1000}"
        bordered
        @change="handleTableChange">
        <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>
        <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.SETTLE_STATUS" :value="text"/>
        <Dict slot="overdueStatus" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>
        <Dict slot="orderType" slot-scope="text" :dict-code="DICT_CODE.ORDER_TYPE" :value="text"/>
        <span slot="action" slot-scope="text, record">
<!--          <a @click="reductionAmt(record, 2)" style="margin-left: 8px" v-if="record.settleStatus == 0 && record.orderType == 1">{{ $t('decCapitalFee') }}</a>-->
          <a @click="reductionAmt(record, 3)" style="margin-left: 8px" v-if="record.settleStatus == 0 && record.orderType == 1">{{ $t('decCapitalAndLateFee') }}</a><br/>
          <a @click="reductionAmt(record, 4)" style="margin-left: 8px" v-if="record.settleStatus == 0 && record.orderType == 1">{{ $t('clearDeductionFee') }}</a>

        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { reductionCapitalFee, reductionCapitalFeeList } from
    '@/webpublicapi/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import OrderDetail from '../order/OrderDetail';
const I18N_KEY = 'order.';

export default {
  name: 'LoanOrderlist',
  components: {
    Dict,
    DictSelect,
    vModal,
    OrderDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      visibleO: false,
      ModalText: '',
      orderId: undefined,
      custInfoId: undefined,
      // 分页参数
      dataSource: [],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      // 查询条件
      queryParam: {
        "orderNo":"",
        "orderId":undefined,
        "mobileNo":"",
        "aadhaarName":"",
        "applyDate":undefined,
        "paymentDate":undefined,
        "settleDate":undefined,
        "endDate":undefined
        // "email":""
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: 'APP',
          align: 'left',
          width: '100px',
          dataIndex: 'channel'
        },
        {
          title: this.$t(I18N_KEY + 'applicationAmount'),
          align: 'left',
          width: '100px',
          dataIndex: 'applyAmt'
        },
        {
          title: this.$t(I18N_KEY + 'settlementStatus'),
          align: 'left',
          width: '100px',
          dataIndex: 'settleStatus',
          scopedSlots: { customRender: 'settleStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'orderType'),
          align: 'left',
          width: '100px',
          dataIndex: 'orderType',
          scopedSlots: { customRender: 'orderType' }
        },
        {
          title: this.$t(I18N_KEY + 'lateFee'),
          align: 'left',
          width: '100px',
          dataIndex: 'lateFee'
        },
        {
          title: this.$t(I18N_KEY + 'hadDeductionFee'),
          align: 'left',
          width: '100px',
          dataIndex: 'deductionFee'
        },
        {
          title: this.$t(I18N_KEY + 'orderNo'),
          align: 'left',
          width: '200px',
          dataIndex: 'orderNo'
        },
        {
          title: this.$t('phoneNo'),
          align: 'left',
          width: '150px',
          dataIndex: 'mobileNo'
        },
        {
          title: this.$t(I18N_KEY + 'operationUserName'),
          align: 'left',
          width: '150px',
          dataIndex: 'userName'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 280,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    // this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }

      this.loading = true
      var params = this.getQueryParams()// 查询条件
      reductionCapitalFeeList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { applyDate, paymentDate, settleDate, endDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          applyDateStart: applyDate && this.transformTime(applyDate[0]),
          applyDateEnd: applyDate && this.transformTime(applyDate[1]),
          paymentDateStart:paymentDate && this.transformTime(paymentDate[0]),
          paymentDateEnd:paymentDate && this.transformTime(paymentDate[1]),
          endDateStart:endDate && this.transformTime(endDate[0]),
          endDateEnd:endDate && this.transformTime(endDate[1]),
          settleDateStart:settleDate && this.transformTime(settleDate[0]),
          settleDateEnd:settleDate && this.transformTime(settleDate[1])
      }
      delete data.applyDate;
      delete data.paymentDate;
      delete data.endDate;
      delete data.settleDate;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
       that.queryParam.aadhaarName = ''
      that.queryParam.applyDate = undefined;
      that.queryParam.paymentDate = undefined;
      that.queryParam.endDate = undefined;
      that.queryParam.settleDate = undefined;
      that.loadData(this.form.pageNum)
    },
    reductionAmt (record, actionType) {
      const self = this;
      reductionCapitalFee({ orderId: record.id, reductionType: actionType }).then(res => {
        if(res.code===1000){
          self.loadData();
          self.$message.success(res.msg)
        }else{
          self.$message.error(res.msg);
          self.loadData();
        }
      }).catch(e => {
        self.loading = false;
        self.$message.error(e.message);
      })
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
