<template>
    <div class="customerListBox">
        <!-- 查询区域 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline" @keyup.enter.native="searchQuery">
            <a-row :gutter="24">
              <a-col :md="6" :sm="8">
                <a-form-item :label="$t(I18N_KEY + 'mobile')">
                  <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'mobile'))" v-model="queryParam.mobileNo"></a-input>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="12">
                <a-form-item :label="$t(I18N_KEY + 'curp')">
                  <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'curp'))" v-model="queryParam.curp"></a-input>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="12">
                <a-form-item :label="$t(I18N_KEY + 'email')">
                  <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'email'))" v-model="queryParam.email"></a-input>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="8">
                <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                  <a-button type="primary" @click="loadData(1)" icon="search">{{ $t('search') }}</a-button>
                  <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
                </span>
              </a-col>

            </a-row>
          </a-form>
        </div>
        <a-table
            :columns="columns"
            :dataSource="dataSource"
            :pagination="false"
            :loading="loading"
            bordered
            ref="table"
            size="middle"
            rowKey="id">
            <span slot="action" slot-scope="text, record">
                <a @click="handleEdit(record)" v-if="record.allowEdit">编辑</a>
            </span>
        </a-table>
        <div style="margin-top: 15px; text-align: right;">
            <v-pagination
                v-if="total"
                :page-num="form.pageNum"
                :page-size="form.pageSize"
                :total="total"
                @change="handleTableChange" />
        </div>
        <customer-modify-modal ref="customerModifyModal" @ok="modalFormOk"></customer-modify-modal>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment'
    import vModal from '../modules/Modal';
    import CustomerModifyModal from './modules/CustomerModifyModal'
    import CustomerDetail from './CustomerDetail';
    import Dict from '../modules/Dict';
    import DictSelect from '../modules/DictSelect';

    const I18N_KEY = 'customer.';
    export default {
        components: {
            Dict,
            DictSelect,
            vModal,
            CustomerModifyModal
        },
        data () {
            return {
                moment,
                I18N_KEY,
                loading: false,
                customerId: undefined,
                form: {
                    pageNum: 1,
                    pageSize: 10,
                },
                defaultQueryParam: {
                    aadhaarNo: undefined,
                    mobileNo: undefined,
                    panNo: undefined,
                    createdTime: undefined,
                    beginDate: undefined,
                    endDate: undefined,
                },
                queryParam: {},
                visible: false,
                toggleSearchStatus: false,
            }
        },
        computed: {
            ...mapGetters('customer', [
                'customerQryList',
            ]),
            // 表头
            columns () {
                return [
                    {
                        title: this.$t(I18N_KEY + 'mobile'),
                        align:"center",
                        dataIndex: 'mobileNo'
                    },
                    {
                        title: this.$t(I18N_KEY + 'firstName'),
                        align:"center",
                        dataIndex: 'names'
                    },
                    {
                        title: this.$t(I18N_KEY + 'middleName'),
                        align:"center",
                        dataIndex: 'surnames'
                    },
                    {
                      title: this.$t(I18N_KEY + 'thirdRepresent'),
                      align:"center",
                      dataIndex: 'thirdRepresent'
                    },
                    {
                      title: this.$t(I18N_KEY + 'curp'),
                      align:"center",
                      dataIndex: 'curp'
                    },
                    {
                      title: this.$t(I18N_KEY + 'email'),
                      align:"center",
                      dataIndex: 'email'
                    },
                    {
                      title: this.$t(I18N_KEY + 'createTime'),
                      align:"center",
                      dataIndex: 'createdTime'
                    },
                    {
                      title: this.$t(I18N_KEY + 'appName'),
                      align:"center",
                      dataIndex: 'appName'
                    },
                     {
                       title: '操作',
                       dataIndex: 'action',
                       align: 'center',
                       width: '300px',
                       scopedSlots: { customRender: 'action' }
                     }]
            },
            dataSource(){
              return (this.customerQryList && this.customerQryList.rows) || [];
            },
            total () {
                return this.customerQryList && this.customerQryList.total || 0;
            },
            pagination () {
                return {
                    pageSize: this.form.pageSize,
                    current: this.form.pageNum,
                    total: this.total,
                    showTotal: function(total) {
                      return "共" + total + "条";
                    }
                }
            },
            element () {
                return document.querySelector('.aaa');
            }
        },
        watch: {
            roleList (data) {
                const list = (data && data.rows) || []
                list.length && (this.dataSource = list);
            }
        },
        created () {
            window.xx = this;
            this.getDict();
            this.basicForm = { ...this.defaultBasicForm };
            this.queryParam = { ...this.defaultQueryParam };
            this.loadData();
        },
        methods: {
            ...mapActions('customer', [
                'getCustomerQryList'
            ]),
            modalContainer () {
                return document.querySelector('.customerListBox')
            },
            handleTableChange (pageNum, pageSize) {
                this.form.pageNum = pageNum;
                this.form.pageSize = pageSize;
                this.loadData();
            },
            handleToggleSearch(){
                this.toggleSearchStatus = !this.toggleSearchStatus;
            },
            transformMenuListToTree (list) {
                return list.map(item => {
                    return {
                        ...item,
                        value: '' + item.id,
                        title: item.nameCn,
                        key: '' + item.id,
                        children: this.transformMenuListToTree(item.children || [])
                    }
                })
            },
            transformStringToLow (str) {
                return str && str.toLocaleLowerCase();
            },
            changeModal (visible) {
                this.visible = visible;
            },
            requestMenuListAndRolePermission (id) {
                return Promise.all([
                    this.getMenuListTree(),
                    this.getRolePermission({ roleId: id }),
                ])
            },
            searchReset () {
                this.form = {
                    keywords: undefined,
                    pageNum: 1,
                    pageSize: 10,
                }
                this.queryParam = { ...this.defaultQueryParam };
                this.loadData(1);
            },
            transformTime (time) {
                return moment(time).format('YYYY-MM-DD')
            },
            handleEdit (record) {
              this.$refs.customerModifyModal.edit(record)
              this.$refs.customerModifyModal.title = '编辑'
            },
            // model回调
            modalFormOk () {
              this.loadData()
            },
            loadData (pageNum) {
                if(pageNum) { this.form.pageNum = pageNum; }
                const { createdTime } = this.queryParam;
                const data = {
                    ...this.form,
                    ...this.queryParam,
                    beginDate: createdTime && this.transformTime(createdTime[0]),
                    endDate: createdTime && this.transformTime(createdTime[1]),
                }
                delete data.createdTime;
                this.loading = true;
                this.getCustomerQryList(data).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }
        }
    }
</script>
<style scoped>
  @import '../../assets/less/common.less';
</style>
