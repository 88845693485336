<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery">
      <a-row :gutter="24">
        <a-col :md="6" :sm="8">
          <a-form-item :label="$t(I18N_KEY + 'id')">
            <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'id')" v-model="form.dictId"></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item :label="$t(I18N_KEY + 'dictType')">
            <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'dictType')" v-model="form.dictType"></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item :label="$t(I18N_KEY + 'dictKey')">
            <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'dictKey')" v-model="form.dictKey"></a-input>
          </a-form-item>
        </a-col>
        <span style="float: left; margin-top: 5px; display: block; margin-bottom: 24px;white-space: nowrap; overflow: hidden;" class="table-page-search-submitButtons">
            <a-col :md="6" :sm="24">
                <a-button type="primary" @click="searchQuery">{{ $t('search') }}</a-button>
                <a-button style="margin-left: 8px" @click="searchReset">{{ $t('reset') }}</a-button>
            </a-col>
        </span>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :loading="loading"
      bordered>
      <template #status="text,record,index">{{$t(I18N_KEY + 'dictStatus_' + text)}}</template>
    </a-table>
    <div style="margin-top: 15px; text-align: right;">
      <v-pagination
        v-if="total"
        :page-num="form.pageNum"
        :page-size="form.pageSize"
        :total="total"
        @change="handleTableChange" />
    </div>
  </div>
</template>
<script>
import { dictOptLogList} from '@/webpublicapi/custServiceWorkOrder';

const I18N_KEY = 'custServiceWorkOrder.';
export default {
  data () {
    return {
      I18N_KEY,
      loading: false,
      total: 0,
      form: {
        pageNum: 1,
        pageSize: 10,
        dictType: undefined,
        dictKey: undefined,
        dictId: undefined,
      },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    // 表头
    columns () {
      return [
        // {
        //   title: this.$t(I18N_KEY + 'id'),
        //   dataIndex: 'id',
        //   width:60,
        //   align:"center",
        // },
        {
          title: this.$t(I18N_KEY + 'operate'),
          align:"center",
          dataIndex: 'opt',
        },
        {
          title: this.$t(I18N_KEY + 'id'),
          align:"center",
          dataIndex: 'dictId',
        },
        {
          title: this.$t(I18N_KEY + 'dictType'),
          align:"center",
          dataIndex: 'dictType',
        },
        {
          title: this.$t(I18N_KEY + 'dictKey'),
          align:"center",
          dataIndex: 'dictKey',
        },
        {
          title: this.$t(I18N_KEY + 'dictValue'),
          align:"center",
          dataIndex: 'dictValue',
          ellipsis: true,
        },
        {
          title: this.$t(I18N_KEY + 'dictStatus'),
          dataIndex: 'status',
          align:"center",
          scopedSlots: {customRender: 'status'}
          // customRender: (text, record, index) => {
          //   return this.DICT_STATUS[text];
          // },
        },
        {
          title: this.$t(I18N_KEY + 'dictSort'),
          align:"center",
          dataIndex: 'sort',
        },
        {
          title: this.$t(I18N_KEY + 'remark'),
          align:"center",
          dataIndex: 'remark',
        },
        {
          title: this.$t(I18N_KEY + 'dictTextCn'),
          align:"center",
          dataIndex: 'dictTextCn',
        },
        {
          title: this.$t(I18N_KEY + 'dictTextEn'),
          align:"center",
          dataIndex: 'dictTextEn',
        },
        {
          title: this.$t(I18N_KEY + 'dictTextEs'),
          align:"center",
          dataIndex: 'dictTextEs',
        },
        {
          title: this.$t(I18N_KEY + 'dictTextFr'),
          align:"center",
          dataIndex: 'dictTextFr',
        },
        {
          title: this.$t('custServiceWorkOrder.operater'),
          align:"center",
          dataIndex: 'optUser',
        },
        {
          title: this.$t(I18N_KEY + 'createTime'),
          align:"center",
          dataIndex: 'createTime',
        }
      ]
    },
  },
  created () {
    this.loadData('');
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    changeModal (visible) {
      this.visible = visible;
    },
    searchReset () {
      this.form = {
        dictType: undefined,
        dictKey: undefined,
        dictId: undefined,
        pageNum: 1,
        pageSize: 10,
      }
    },
    loadData (pageNum) {
      if(pageNum) { this.form.pageNum = pageNum; }
      this.loading = true;
      dictOptLogList(this.form).then(res => {
        this.loading = false;
        this.total = res.data.total
        this.dataSource = res.data.rows;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    }
  }
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
