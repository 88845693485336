<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item :label="'日期'" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-range-picker
                v-model="queryParam.dataDt"
                format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
<!--              <a-button type="primary" @click="downLoadData('')" icon="download" style="margin-left: 8px">导出</a-button>-->
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        bordered
        @change="handleTableChange">
      </a-table>

    </div>
    <RefundTradeModal ref="modalForm" @ok="modalFormOk"></RefundTradeModal>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import {getFinancialList} from '@/webpublicapi/statistics'
import Dict from '../modules/Dict';
import RefundTradeModal from './modules/RefundTradeModal'
import moment from "moment";
import exportFile from "@/webpublicapi/exportFile";
export default {
  name: 'AppList',
  components: {
    RefundTradeModal,
    Dict
  },
  data () {
    return {
      visible: false,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },

      headers: {
        // authorization: 'authorization-text',
      },

      dataSource: [],
      // 查询条件
      queryParam: {
        dataDt: undefined,
      },
      loading: false,
      // 表头
      columns: [
        {
          title: '日期',
          align: 'left',
          dataIndex: 'curDate'
        },
        {
          title: '首逾',
          align: 'left',
          dataIndex: 'firstOverdueRate'
        },
        {
          title: '新户首逾',
          align: 'left',
          dataIndex: 'newFirstOverdueRate'
        },
        {
          title: '老户首逾',
          align: 'left',
          dataIndex: 'oldFirstOverdueRate'
        },
        {
          title: '在贷余额',
          align: 'left',
          dataIndex: 'balAmt'
        },
        {
          title: '较昨日增加',
          align: 'left',
          dataIndex: 'increaseBalAmt'
        },
        {
          title: '当日放款金额',
          align: 'left',
          dataIndex: 'paymentAmt'
        },
        {
          title: '放款笔数',
          align: 'left',
          width: '100px',
          dataIndex: 'paymentCount'
        },
        {
          title: '新户放款笔数',
          align: 'left',
          width: '100px',
          dataIndex: 'newPaymentCount'
        },
        {
          title: '老户放款笔数',
          align: 'left',
          width: '100px',
          dataIndex: 'oldPaymentCount'
        },
        {
          title: '当日回款金额',
          align: 'left',
          width: '150px',
          dataIndex: 'repayAmt'
        },
        {
          title: '今日赚钱',
          align: 'left',
          width: '150px',
          dataIndex: 'profitAmount'
        },


        {
          title: '当日到期期次数',
          align: 'left',
          dataIndex: 'remarks'
        }
      ],
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    this.loadData(1)
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getFinancialList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.ipagination.total = res.data.total
          this.dataSource = res.data.rows

          for(var i = 0; i < this.dataSource.length ; i ++){
            this.dataSource[i].firstOverdueRate = (this.dataSource[i].firstOverdueRate * 100).toFixed(2);
            this.dataSource[i].newFirstOverdueRate = (this.dataSource[i].newFirstOverdueRate * 100).toFixed(2);
            this.dataSource[i].oldFirstOverdueRate = (this.dataSource[i].oldFirstOverdueRate * 100).toFixed(2);

            this.dataSource[i].profitAmount = (this.dataSource[i].repayAmt - this.dataSource[i].paymentAmt).toFixed(2);
          }

        }else{
          this.ipagination.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    getQueryParams () {
      const { dataDt } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: dataDt && this.transformTime(dataDt[0]),
        endDate: dataDt && this.transformTime(dataDt[1]),
        installmentType: "1",
      }
      delete data.dataDt;
      data.pageNum = this.ipagination.current
      data.pageSize = this.ipagination.pageSize
      return filterObj(data)
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    handleTableChange (pagination, filters, sorter) {
      // TODO 筛选
      // if (Object.keys(sorter).length > 0) {
      //   this.isorter.column = sorter.field
      //   this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      // }
      this.ipagination = pagination
      this.loadData()
    },
    handleAdd () {
      this.$refs.modalForm.add()
      // this.$refs.modalForm.title = '新增'
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.appSsid = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk () {
      this.loadData()
    },
    handleChangeUpload(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    // downLoadData (arg2) {
    //   var params = this.getQueryParams(arg2)// 查询条件
    //   const file = {
    //     fileUrl: '/api/web/statistics/appAllCountForDateExcel?startDate=' + params.startDate + '&endDate=' + params.endDate,
    //     fileName: 'appAllCountExcel.xls',
    //   }
    //   this.loading = true;
    //   exportFile(file).catch(e => {
    //     this.$message.error(e.message);
    //   }).finally(() => {
    //     this.loading = false;
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
