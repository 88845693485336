<template>
  <div>
<!--    <a-form layout="inline" @keyup.enter.native="searchQuery">-->
<!--      <a-row :gutter="24">-->
<!--        <span style="float: left; margin-top: 5px; display: block; margin-bottom: 24px;white-space: nowrap; overflow: hidden;" class="table-page-search-submitButtons">-->
<!--            <a-col :md="6" :sm="24">-->
<!--                <a-button type="primary" @click="searchQuery">{{ $t('search') }}</a-button>-->
<!--            </a-col>-->
<!--        </span>-->
<!--      </a-row>-->
<!--    </a-form>-->
    <div class="table-operator"  style="margin-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('add') }}</a-button>
    </div>
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :loading="loading"
      bordered>
      <template #forceUpdate="text,record,index">{{$t(I18N_KEY + 'forceUpdate_' + text)}}</template>
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">{{ $t('edit') }}</a>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link">
          {{ $t('more') }} <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">
                <a>{{ $t('delete') }}</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right;">
      <v-pagination
        v-if="total"
        :page-num="form.pageNum"
        :page-size="form.pageSize"
        :total="total"
        @change="handleTableChange" />
    </div>
    <version-modal ref="modalForm" @ok="modalFormOk"></version-modal>
  </div>
</template>
<script>
import { versionList, versionOperate, versionDelete} from '@/webpublicapi/custServiceWorkOrder';
import VersionModal from './modules/VersionModal'

const I18N_KEY = 'custServiceWorkOrder.';
export default {
  components: {
    VersionModal,
  },
  data () {
    return {
      I18N_KEY,
      loading: false,
      total: 0,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      // FORCE_UPDATE: {
      //   0: '不强更',
      //   1: '强更',
      // },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    // 表头
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          dataIndex: 'id',
          width:60,
          align:"center",
        },
        {
          title: this.$t(I18N_KEY + 'version'),
          align:"center",
          dataIndex: 'version',
        },
        {
          title: this.$t(I18N_KEY + 'versionContent'),
          align:"center",
          dataIndex: 'content',
        },
        {
          title: this.$t(I18N_KEY + 'forceUpdate'),
          align:"center",
          dataIndex: 'forceUpdate',
          scopedSlots: {customRender: 'forceUpdate'}
          // customRender: (text, record, index) => {
          //   return this.FORCE_UPDATE[text];
          // }
        },
        {
          title: this.$t(I18N_KEY + 'downloadUrl'),
          align:"center",
          dataIndex: 'downloadUrl',
        },
        {
          title: this.$t(I18N_KEY + 'remark'),
          align:"center",
          dataIndex: 'remark',
        },
        {
          title: this.$t(I18N_KEY + 'createTime'),
          align:"center",
          dataIndex: 'createTime',
        },
        {
          title: this.$t(I18N_KEY + 'updateTime'),
          align:"center",
          dataIndex: 'updateTime',
        },
        {
          title: this.$t(I18N_KEY + 'operate'),
          dataIndex: 'action',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }]
    },
  },
  created () {
    this.loadData('');
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    modalFormOk(data) {
      this.createDict(data);
    },
    handleAdd () {
      this.versionId = undefined;
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "1";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit (record) {
      this.versionId = record.id;
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "2";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    deleteCurrent (row) {
      this.loading = true;
      versionDelete(row.id).then(res => {
        this.$message.success(this.$t('success'));
        this.loadData(1);
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    createDict (data) {
      console.log(data)
      const form = {
        ...data,
        id: this.versionId,
        opt: this.versionId ? '2' : '1',
      };
      // const requestField = this.versionId ? '2' : '1'
      versionOperate(form).then(res => {
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        this.$message.success('添加成功');
        this.loadData(1);
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    searchReset () {
      this.form = {
        pageNum: 1,
        pageSize: 10,
      }
    },
    loadData (pageNum) {
      if(pageNum) { this.form.pageNum = pageNum; }
      this.loading = true;
      versionList(this.form).then(res => {
        this.loading = false;
        this.total = res.data.total
        this.dataSource = res.data.rows;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    }
  }
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
