<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>

      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter='12'>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'手机号'">
              <a-input v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.ORDER + 'registerTime')">
              <a-date-picker
                v-model='queryParam.registerTime'
                format='YYYY-MM-DD'
                style='width: 100%;' />
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <a-form-item :label="'产品类型'">
              <a-select
                allowClear='true'
                v-model='queryParam.appssid'
              >
                <a-select-option v-for='item in appList' :key='item.appssid'>
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <a-form-item :label="'未转化类型'">
              <a-select allowClear='true' default-value='' style='width: 200px' v-model='queryParam.translateStatus'>
                <a-select-option :value=0>
                  注册用户
                </a-select-option>
                <a-select-option :value=1>
                  注册未转化个人信息
                </a-select-option>
                <a-select-option :value=2>
                  个人信息未转化联系人信息
                </a-select-option>
                <a-select-option :value=3>
                  联系人信息未转化身份信息
                </a-select-option>
                <a-select-option :value=4>
                  身份信息未转化银行信息
                </a-select-option>
                <a-select-option :value=5>
                  银行信息未转化订单
                </a-select-option>
                <a-select-option :value=6>
                  注册未申贷
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-modal v-model='visdialog' title='客户详情' width='1000px'>
        <a-form :loading='loading' :form='custInfoForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
          <a-form-item>
            <a-button type='primary' @click='handleDetail(previousId)'>上一个</a-button>
            <a-button type='primary' @click='handleDetail(nextId)'>下一个</a-button>
          </a-form-item>
          <a-form-item :label="'id'">
            <div>{{ custInfoForm.id }}</div>
          </a-form-item>
          <a-form-item :label="'应用'">
            <div>{{ custInfoForm.appSsid }}</div>
          </a-form-item>
          <a-form-item :label="'生日'">
            <div>{{ custInfoForm.birthday }}</div>
          </a-form-item>
          <a-form-item :label="'年龄'">
            <div>{{ custInfoForm.age }}</div>
          </a-form-item>
          <a-form-item :label="'性别'">
            <div>{{ custInfoForm.sex }}</div>
          </a-form-item>
          <a-form-item :label="'手机号'">
            <div>{{ custInfoForm.mobileNo }}</div>
          </a-form-item>
          <a-form-item :label="'邮箱'">
            <div>{{ custInfoForm.email }}</div>
          </a-form-item>
          <a-form-item :label="'渠道'">
            <div>{{ custInfoForm.channel }}</div>
          </a-form-item>
          <a-form-item :label="'人脸分数'">
            <div>{{ custInfoForm.faceSimilarity }}</div>
          </a-form-item>
          <a-form-item :label="'人脸结果'">
            <div>{{ custInfoForm.faceVerified }}</div>
          </a-form-item>
          <a-form-item :label="'gaid'">
            <div>{{ custInfoForm.gaid }}</div>
          </a-form-item>
          <a-form-item :label="'deviceId'">
            <div>{{ custInfoForm.deviceId }}</div>
          </a-form-item>
          <a-form-item :label="'老户标识'">
            <div>{{ custInfoForm.reborrowFlag }}</div>
          </a-form-item>
          <a-form-item :label="'创建时间'">
            <div>{{ custInfoForm.createdTime }}</div>
          </a-form-item>
          <a-form-item :label="'更新时间'">
            <div>{{ custInfoForm.updatedTime }}</div>
          </a-form-item>
          <a-form-item :label="'打点'">
            <div v-for='(item, index) in optTypeList' :key='index'>
              {{ item.createdAt }}
              {{ item.optType }}
              手机品牌:{{ item.equipmentBrand }}
              手机型号:{{ item.equipmentType }}
              系统版本:{{ item.osVersion }}
              app版本:{{ item.versionName }}
              数据:{{ item.data }}
            </div>
          </a-form-item>
        </a-form>
      </a-modal>

      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='countRateColumns'
        :dataSource='countRateList'
        :rowClassName='rowClassName'
        :pagination='false'
        :loading='loading'
        bordered>
      </a-table>

      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :rowClassName='rowClassName'
        :pagination='false'
        :loading='loading'
        bordered>
        <span slot='action' slot-scope='text, record'>
          <a @click='handleDetail(record.id, record.appSsid)'>
            查看
          </a>
        </span>
      </a-table>

    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getApplist } from '@/webpublicapi/order'
import Dict from '../modules/Dict'
import DictSelect from '../modules/DictSelect'
import moment from 'moment'
import vModal from '../modules/Modal'
import { getAsCountRate, getAsInfo, getOptType } from '@/webpublicapi/translate'
import { getCustById } from '@/webpublicapi/customer'
import { getPartConversionRate } from '@/webpublicapi/home'

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
  OPERATION: 'operation.'
}

export default {
  name: 'asinfo',
  components: {
    Dict,
    DictSelect,
    vModal
  },
  data() {
    return {
      visdialog: false,
      I18N_KEY,
      toggleSearchStatus: false,
      visible: false,
      nextId: undefined,
      previousId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10
      },
      custInfoForm: {
        age: '',
        appSsid: '',
        birthday: '',
        channel: '',
        createdTime: '',
        curp: '',
        deviceId: '',
        email: '',
        faceSimilarity: '',
        faceVerified: '',
        gaid: '',
        id: '',
        mobileNo: '',
        names: '',
        reborrowFlag: '',
        sex: '',
        surnames: '',
        updatedTime: ''
      },
      total: 0,
      dataSource: [],
      appList: [],
      optTypeList: [],
      custInfoIdList: [],
      countRateList: [],
      // 查询条件
      queryParam: {
        'appssid': '',
        'translateStatus': '',
        'mobileNo': ''
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    countRateColumns() {
      return [
        {
          title: '未转化原因',
          align: 'left',
          dataIndex: 'transName'
        },
        {
          title: '数量',
          align: 'left',
          dataIndex: 'transCount'
        },
        {
          title: '比率',
          align: 'left',
          dataIndex: 'transRate'
        }
      ]
    },
    columns() {
      return [
        {
          title: 'id',
          align: 'left',
          dataIndex: 'id'
        },
        {
          title: '手机号',
          align: 'left',
          dataIndex: 'mobileNo'
        },
        {
          title: '年龄',
          align: 'left',
          dataIndex: 'age'
        },
        {
          title: '性别',
          align: 'left',
          dataIndex: 'sex'
        },
        {
          title: '生日',
          align: 'left',
          dataIndex: 'birthday'
        },
        {
          title: '渠道',
          align: 'left',
          dataIndex: 'channel'
        },
        {
          title: '邮箱',
          align: 'left',
          dataIndex: 'email'
        },
        {
          title: '老户标识',
          align: 'left',
          dataIndex: 'reborrowFlag'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created() {
    getApplist().then(res => {
      this.appList = res.data || []
      if (this.appList != null) {
        this.appList.forEach(item => {
          if (item.appName == '全部') {
            item.appName = '请选择'
          }
        })
      }
    })
    this.getDict()
  },
  methods: {
    handleDetail(id, appssid) {
      if (id == undefined) {
        this.$message.error('没有这条数据！')
      } else {
        this.visdialog = false
        this.loading = true
        getCustById(id).then(res => {
          this.custInfoForm = res.data
          this.custInfoForm.birthday = this.transformTime(this.custInfoForm.birthday)
          this.custInfoForm.createdTime = this.transformTimeSecond(this.custInfoForm.createdTime)
          this.custInfoForm.updatedTime = this.transformTimeSecond(this.custInfoForm.updatedTime)
          if (this.custInfoForm.sex == 0) {
            this.custInfoForm.sex = '保密'
          } else if (this.custInfoForm.sex == 1) {
            this.custInfoForm.sex = '男'
          } else if (this.custInfoForm.sex == 2) {
            this.custInfoForm.sex = '女'
          }
          if (this.custInfoForm.reborrowFlag == 0) {
            this.custInfoForm.reborrowFlag = '否'
          } else {
            this.custInfoForm.reborrowFlag = '是'
          }
          if (this.custInfoForm.faceVerified == 0) {
            this.custInfoForm.faceVerified = '未通过'
          } else {
            this.custInfoForm.faceVerified = '通过'
          }
        }).catch(e => {
          this.$message.error(e.message)
        })
        getOptType(id, appssid).then(res => {
          this.optTypeList = res.data
          if (this.optTypeList != null) {
            this.optTypeList.forEach(item => {
              item.createdAt = this.transformTimeSecond(item.createdAt)

              /*if (item.optType == 'SERVICE_INSTALL') {
                item.optType = '安装（归因）'
              } else if (item.optType.indexOf('SYSTEN_INSTALL') != -1) {
                item.optType = item.optType.replace('SYSTEN_INSTALL', '安装（通过监听INSTALL_REFERRER的Receiver实现）')
              } else if (item.optType.indexOf('SYSTEM_INIT_OPEN') != -1) {
                item.optType = item.optType.replace('SYSTEM_INIT_OPEN', '打开APP')
              } else if (item.optType.indexOf('SYSTEM_HIDE') != -1) {
                item.optType = item.optType.replace('SYSTEM_HIDE', '隐藏APP')
              } else if (item.optType.indexOf('SYSTEM_CLOSE') != -1) {
                item.optType = item.optType.replace('SYSTEM_CLOSE', '关闭APP（通过Service和监听唤起任务列表实现）')
              } else if (item.optType == 'CLICK_USER_AGREEMENT') {
                item.optType = '用户协议页点击同意按钮'
              } else if (item.optType == 'CLICK_PERMISSION_AGREE') {
                item.optType = '权限文案页同意'
              } else if (item.optType == 'SYSTEM_PERMISSION_RESULT') {
                item.optType = '权限文案页授权结果'
              } else if (item.optType == 'CLICK_PERMISSION_SKIP') {
                item.optType = '权限文案页跳过'
              } else if (item.optType == 'CLICK_SEND_OTP') {
                item.optType = '点击发送验证码（包括自动发送）'
              } else if (item.optType == 'SYSTEM_LOGIN_BACK') {
                item.optType = '验证码页与登录页分开时，点击登录页返回按钮'
              } else if (item.optType == 'CLICK_LOGIN') {
                item.optType = '点击登录'
              } else if (item.optType == 'SERVICE_SEND_OTP') {
                item.optType = '发送验证码成功'
              } else if (item.optType == 'FIRST_REGISTER_SUCCESS') {
                item.optType = '注册成功'
              } else if (item.optType == 'SERVICE_FIRST_REGISTER') {
                item.optType = '注册成功'
              } else if (item.optType == 'SERVICE_LOGIN') {
                item.optType = '登录成功（含注册）'
              } else if (item.optType == 'CLICK_LOGOUT') {
                item.optType = '点击退出登录按钮'
              } else if (item.optType == 'CLICK_INDEX_APPLY') {
                item.optType = '点击申请按钮'
              } else if (item.optType == 'CLICK_FIRST_INDEX_APPLY') {
                item.optType = '主产品首贷点击申请按钮'
              } else if (item.optType == 'SYSTEM_BASIC_INF_ENTER') {
                item.optType = '进入个人信息页'
              } else if (item.optType == 'SYSTEM_BASIC_INF_LOAD') {
                item.optType = '个人信息页数据加载完成'
              } else if (item.optType == 'CLICK_BASIC_INF_BOX') {
                item.optType = '页面无回显个人信息时，进入页面（与其他信息页合并时改为点击首个输入框）'
              } else if (item.optType == 'CLICK_BASIC_INF_NP') {
                item.optType = '主产品首贷页面无回显个人信息时，用户填写信息后，点击提交并通过验证（与其他信息页合并时改为点击下一步首个输入框）'
              } else if (item.optType == 'CLICK_BASIC_INF_SUBMIT') {
                item.optType = '点击个人信息页提交按钮'
              } else if (item.optType == 'SYSTEM_BASIC_INF_BACK') {
                item.optType = '个人信息页返回上一页'
              } else if (item.optType == 'SERVICE_SAVE_BASIC_INFO') {
                item.optType = '保存个人信息'
              } else if (item.optType == 'SERVICE_FIRST_SAVE_BASIC_INFO') {
                item.optType = '主产品首次保存个人信息'
              } else if (item.optType == 'SYSTEM_WORK_INF_ENTER') {
                item.optType = '进入工作信息页'
              } else if (item.optType == 'SYSTEM_WORK_INF_LOAD') {
                item.optType = '工作信息页数据加载完成'
              } else if (item.optType == 'CLICK_WORK_INF_BOX') {
                item.optType = '页面无回显工作信息时，进入页面（与其他信息页合并时改为点击首个输入框）'
              } else if (item.optType == 'CLICK_WORK_INF_NP') {
                item.optType = '主产品首贷页面无回显工作信息时，用户填写信息后，点击提交并通过验证（与其他信息页合并时改为点击下一步首个输入框）'
              } else if (item.optType == 'CLICK_WORK_INF_SUBMIT') {
                item.optType = '点击工作信息页提交按钮'
              } else if (item.optType == 'SYSTEM_WORK_INF_BACK') {
                item.optType = '工作信息页返回上一页'
              } else if (item.optType == 'SERVICE_SAVE_WORK_INFO') {
                item.optType = '保存工作信息'
              } else if (item.optType == 'SERVICE_FIRST_SAVE_WORK_INFO') {
                item.optType = '主产品首次保存工作信息'
              } else if (item.optType == 'SYSTEM_CONTACT_INF_ENTER') {
                item.optType = '进入联系人信息页'
              } else if (item.optType == 'SYSTEM_CONTACT_INF_LOAD') {
                item.optType = '联系人信息页数据加载完成'
              } else if (item.optType == 'CLICK_CONTACT_INF_BOX') {
                item.optType = '页面无回显联系人信息时，进入页面（与其他信息页合并时改为点击首个输入框）'
              } else if (item.optType == 'CLICK_CONTACT_INF_NP') {
                item.optType = '主产品首贷页面无回显联系人信息时，用户填写信息后，点击提交并通过验证（与其他信息页合并时改为点击下一步首个输入框）'
              } else if (item.optType == 'CLICK_CONTACT_INF_SUBMIT') {
                item.optType = '点击联系人页提交按钮'
              } else if (item.optType == 'CLICK_CONTACT_AGREE') {
                item.optType = '点击同意联系人抓取授权'
              } else if (item.optType == 'CLICK_CONTACT_CANCEL') {
                item.optType = '点击拒绝联系人抓取授权'
              } else if (item.optType == 'SYSTEM_CONTACT_RESULT') {
                item.optType = '联系人抓取授权结果'
              } else if (item.optType == 'CLICK_DEVICE_AGREE') {
                item.optType = '同意数据抓取授权'
              } else if (item.optType == 'CLICK_DEVICE_CANCEL') {
                item.optType = '拒绝数据抓取授权'
              } else if (item.optType == 'SYSTEM_DEVICE_RESULT') {
                item.optType = '数据抓取授权结果'
              } else if (item.optType == 'SYSTEM_DEVICE_INFO_NP') {
                item.optType = '主产品首贷页面无回显联系人信息时，完成数据抓取授权（全部通过时）'
              } else if (item.optType == 'SYSTEM_DEVICE_INFO_UPLOAD') {
                item.optType = '开始上传设备数据'
              } else if (item.optType == 'SYSTEM_DEVICE_INFO_SUCCESS') {
                item.optType = '完成上传设备数据'
              } else if (item.optType == 'SYSTEM_CONTACT_INF_BACK') {
                item.optType = '联系人信息页返回上一页'
              } else if (item.optType == 'CLICK_CONTACT_INF_SUBMIT_T') {
                item.optType = '更新联系人信息（已存在且变化时）'
              } else if (item.optType == 'SERVICE_SAVE_CONTACT_INFO') {
                item.optType = '保存联系人信息'
              } else if (item.optType == 'SERVICE_FIRST_SAVE_CONTACT_INFO') {
                item.optType = '主产品首次保存联系人信息'
              } else if (item.optType == 'SERVICE_SAVE_DEVICE_INFO') {
                item.optType = '提交设备数据成功'
              } else if (item.optType == 'SERVICE_FIRST_SAVE_DEVICE_INFO') {
                item.optType = '主产品首次提交设备数据成功'
              } else if (item.optType == 'SYSTEM_ID_INF_ENTER') {
                item.optType = '进入身份信息页'
              } else if (item.optType == 'SYSTEM_ID_INF_LOAD') {
                item.optType = '身份信息页数据加载完成'
              } else if (item.optType == 'SYSTEM_ID_PICTURE_LOAD') {
                item.optType = '身份照片数据加载完成'
              } else if (item.optType == 'SYSTEM_CAMERA_RESULT') {
                item.optType = '拍照授权结果'
              } else if (item.optType == 'CLICK_ID_PICTURE_SUBMIT_1') {
                item.optType = '点击正面'
              } else if (item.optType == 'ID_PICTURE_SUBMIT_1_T') {
                item.optType = '正面拍照成功'
              } else if (item.optType == 'ID_PICTURE_SUBMIT_1_F') {
                item.optType = '正面拍照失败'
              } else if (item.optType == 'CLICK_ID_PICTURE_SUBMIT_2') {
                item.optType = '点击反面'
              } else if (item.optType == 'ID_PICTURE_SUBMIT_2_T') {
                item.optType = '反面拍照成功'
              } else if (item.optType == 'ID_PICTURE_SUBMIT_2_F') {
                item.optType = '反面拍照失败'
              } else if (item.optType == 'ID_PICTURE_UPLOAD') {
                item.optType = '开始上传身份证'
              } else if (item.optType == 'ID_PICTURE_UPLOAD_NP') {
                item.optType = '主产品首贷页面无身份证照片回显时，用户选择照片后，上传身份证'
              } else if (item.optType == 'CLICK_ID_FACE_SUBMIT') {
                item.optType = '点击人脸'
              } else if (item.optType == 'ID_FACE_SUBMIT_T') {
                item.optType = '人脸拍照成功'
              } else if (item.optType == 'ID_FACE_SUBMIT_F') {
                item.optType = '人脸拍照失败'
              } else if (item.optType == 'ID_FACE_UPLOAD_NP') {
                item.optType = '主产品首贷页面无人脸照片回显时，用户选择照片后，上传人脸'
              } else if (item.optType == 'CLICK_ID_INF_SUBMIT') {
                item.optType = '点击身份信息页提交按钮'
              } else if (item.optType == 'CLICK_ID_INF_SUBMIT_NP') {
                item.optType = '主产品首贷页面无回显身份信息时，用户填写信息后，点击提交并通过验证'
              } else if (item.optType == 'SYSTEM_ID_INF_BACK') {
                item.optType = '身份信息页返回上一页'
              } else if (item.optType == 'SERVICE_UPLOAD_IDENTITY_CARD') {
                item.optType = '保存身份证正反面成功'
              } else if (item.optType == 'SERVICE_FIRST_UPLOAD_IDENTITY_CARD') {
                item.optType = '主产品首次保存身份证正反面成功'
              } else if (item.optType == 'SERVICE_UPLOAD_FACE') {
                item.optType = '保存人脸成功'
              } else if (item.optType == 'SERVICE_FIRST_UPLOAD_FACE') {
                item.optType = '主产品首次保存人脸成功'
              } else if (item.optType == 'SERVICE_SAVE_IDENTITY_INFO') {
                item.optType = '保存身份信息'
              } else if (item.optType == 'SERVICE_FIRST_SAVE_IDENTITY_INFO') {
                item.optType = '主产品首次保存身份信息'
              } else if (item.optType == 'SYSTEM_BANK_CARD_ENTER') {
                item.optType = '进入银行卡信息页'
              } else if (item.optType == 'SYSTEM_BANK_CARD_LOAD') {
                item.optType = '银行卡信息页数据加载完成'
              } else if (item.optType == 'CLICK_BANK_CARD_BOX') {
                item.optType = '页面无回显银行卡信息时，进入页面（与其他信息页合并时改为点击首个输入框）'
              } else if (item.optType == 'CLICK_BANK_CARD_NP') {
                item.optType = '页面无回显银行卡信息时，用户填写信息后，点击提交并通过验证（与其他信息页合并时改为点击下一步首个输入框）'
              } else if (item.optType == 'CLICK_BANK_UPDATE_SUBMIT') {
                item.optType = '放款失败时，点击银行卡更新页提交按钮'
              } else if (item.optType == 'CLICK_BANK_CARD_SUBMIT') {
                item.optType = '点击银行卡页提交按钮'
              } else if (item.optType == 'SYSTEM_BANK_CARD_BACK') {
                item.optType = '银行卡页返回上一页'
              } else if (item.optType == 'CLICK_BANK_CARD_SUBMIT_T') {
                item.optType = '更新银行卡信息（已存在且变化时）'
              } else if (item.optType == 'SERVICE_SAVE_BANK_CARD_INFO') {
                item.optType = '保存银行卡信息'
              } else if (item.optType == 'SERVICE_FIRST_SAVE_BANK_CARD_INFO') {
                item.optType = '主产品首次保存银行卡信息'
              } else if (item.optType == 'SERVICE_FIRST_COMPLETE_INFO') {
                item.optType = '主产品首次完成主产品资料填写'
              } else if (item.optType == 'SYSTEM_LOADING_ENTER') {
                item.optType = '进入等待页'
              } else if (item.optType == 'SYSTEM_LOADING_NEXT') {
                item.optType = '等待页跳转下一页'
              } else if (item.optType == 'SYSTEM_LOAN_ENTER') {
                item.optType = '进入金额期限页'
              } else if (item.optType == 'SYSTEM_LOAN_LOAD') {
                item.optType = '金额期限页数据加载完成'
              } else if (item.optType == 'CLICK_LOAN_AMOUNT') {
                item.optType = '金额期限页修改金额（含自动选择）'
              } else if (item.optType == 'CLICK_LOAN_TERM') {
                item.optType = '金额期限页修改期限（含自动选择）'
              } else if (item.optType == 'SYSTEM_LOCATION_RESULT') {
                item.optType = '定位授权结果'
              } else if (item.optType == 'CLICK_LOAN_SUBMIT') {
                item.optType = '金额期限页点击确认按钮'
              } else if (item.optType == 'SYSTEM_LOAN_BACK') {
                item.optType = '金额期限页返回上一页'
              } else if (item.optType == 'SERVICE_PRE_LOAN_SUCCESS') {
                item.optType = '预申请成功'
              } else if (item.optType == 'CLICK_CONFIRM_SUBMIT') {
                item.optType = '贷款确认页点击确认按钮'
              } else if (item.optType == 'CLICK_CONFIRM_BACK') {
                item.optType = '贷款确认页返回上一页（取消）'
              } else if (item.optType == 'FIRST_LOAN_SUCCESS') {
                item.optType = '主产品首贷申请成功'
              } else if (item.optType == 'SERVICE_LOAN_SUCCESS') {
                item.optType = '申请成功'
              } else if (item.optType == 'SERVICE_FIRST_LOAN_SUCCESS') {
                item.optType = '主产品首贷申请成功'
              } else if (item.optType == 'SERVICE_FIRST_RISK_SUCCESS') {
                item.optType = '主产品首贷风控审核通过'
              } else if (item.optType == 'SERVICE_FIRST_RISK_FAILUD') {
                item.optType = '主产品首贷风控审核拒绝'
              } else if (item.optType == 'SERVICE_FIRST_LOAN_FINISH') {
                item.optType = '主产品首贷放款成功（带申请金额）'
              } else if (item.optType == 'SERVICE_FIRST_START_OVERDUE') {
                item.optType = '主产品首贷首次逾期'
              } else if (item.optType == 'SERVICE_FIRST_START_BAD_DEBT') {
                item.optType = '主产品首贷首次坏账（逾期90天）'
              }*/
            })
          }
          this.loading = false
          this.visdialog = true
        }).catch(e => {
          this.loading = false
          this.$message.error(e.message)
        })
      }
      for (let i = 0; i < this.custInfoIdList.length; i++) {
        if (this.custInfoIdList[i] == id) {
          this.nextId = this.custInfoIdList[i + 1]
          this.previousId = this.custInfoIdList[i - 1]
        }
      }
    },
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus
    },
    searchQuery() {
      this.loadData()
    },
    loadData(arg) {
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getAsInfo(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data || []
          if (this.dataSource != null) {
            this.dataSource.forEach(item => {
              this.custInfoIdList.push(item.id)
              item.birthday = this.transformTime(item.birthday)
              item.createdTime = this.transformTime(item.createdTime)
              item.updatedTime = this.transformTime(item.updatedTime)
              if (item.sex == 0) {
                item.sex = '保密'
              } else if (item.sex == 1) {
                item.sex = '男'
              } else if (item.sex == 2) {
                item.sex = '女'
              }
              if (item.reborrowFlag == 0) {
                item.reborrowFlag = '否'
              } else {
                item.reborrowFlag = '是'
              }
              if (item.faceVerified == 0) {
                item.faceVerified = '未通过'
              } else {
                item.faceVerified = '通过'
              }
            })
          }
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
      getAsCountRate(Object.assign({}, params)).then(res => {
        this.countRateList = res.data
        if (this.countRateList != null) {
          this.countRateList.forEach(item => {
            item.transRate = item.transRate + '%'
          })
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformTimeSecond(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase()
    },
    getQueryParams() {
      const { registerTime } = this.queryParam
      const data = {
        ...this.queryParam,
        startDate: this.transformTime(registerTime),
        endDate: this.transformTime(registerTime)
      }
      delete data.applyDate
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.registerTime = undefined
      that.queryParam.appssid = '请选择'
      that.queryParam.translateStatus = undefined
      that.queryParam.mobileNo = undefined
      that.dataSource = []
      that.countRateList = []
      that.optTypeList = []
    },
    changeModal(visible) {
      this.visible = visible
    },

    rowClassName(record, index) {
      var date = new Date(record.curDate)
      var day = date.getTime() / 24 / 60 / 60 / 1000 + 3

      if ((day % 7) + 1 == 7) {
        return 'table_weekendColor'
      }
      return ''
    }

  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
