<template>
  <div>
    <!-- <div class='table-page-search-wrapper'>
      <a-form layout='inline'>
        <a-row :gutter='24'>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COUPON + 'templateID')">
              <a-input v-model='form.id' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COUPON + 'templateName')">
              <a-input v-model='form.templateName' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COUPON + 'type')">
              <a-select
                v-model='form.type'
              >
                <a-select-option value='' selected>
                  {{ $t(I18N_KEY.COUPON + 'all') }}
                </a-select-option>
                <a-select-option value='1'>
                  {{ $t(I18N_KEY.COUPON + 'SMS') }}
                </a-select-option>
                <a-select-option value='2'>
                  {{ $t(I18N_KEY.COUPON + 'voice') }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='search()'>{{ $t(I18N_KEY.COUPON + 'search') }}</a-button>&nbsp;&nbsp;
              <a-button type='primary' @click='searchReset' icon='reload'>{{ $t(I18N_KEY.COUPON + 'reset') }}</a-button>&nbsp;&nbsp;
              <a-button type='primary' @click="viewCoupon('add')">
            <a-icon type='plus' />
            {{ $t(I18N_KEY.COUPON + 'addTemplate') }}
          </a-button>
            </span>
          </a-col>
        </a-row>
        <a-row>
        </a-row>

      </a-form>
    </div> -->

    <div class='table-page-search-wrapper'>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :loading='loading'
        bordered>
        <!-- <span slot='action' slot-scope='text, record'>
          <a @click="viewCoupon('edit', record)">
            {{ $t(I18N_KEY.COUPON + 'edit') }}
          </a>
        </span> -->
      </a-table>
    </div>
    <a-modal
      :title='modalTitle'
      v-model='visible'
      @ok='submitForm'
    >
      <a-form :form='basicForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'templateName')">
          <a-input v-model='basicForm.templateName' />
        </a-form-item>
<!--        <a-form-item :label="'模板代码'">-->
<!--          <a-input v-model='basicForm.templateCode' />-->
<!--        </a-form-item>-->

        <a-form-item :label="$t(I18N_KEY.COUPON + 'templateType')">
          <a-select
            v-model='basicForm.type'
          >
            <a-select-option value='1'>
              {{ $t(I18N_KEY.COUPON + 'SMS') }}
            </a-select-option>
            <a-select-option value='2'>
              {{ $t(I18N_KEY.COUPON + 'voice') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'templateContent')">
          <a-textarea v-model='basicForm.content' rows = 5 />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'templateRemarks')">
          <a-textarea v-model='basicForm.remark' />
        </a-form-item>

      </a-form>
    </a-modal>
  </div>
</template>
<script>

import { getTemplateList, addOrEditTemplate } from '@/webpublicapi/operation'


const I18N_KEY = {
  COUPON: 'coupon.',
}
export default {

  data() {
    return {
      I18N_KEY,
      visible: false,
      modalTitle: undefined,

      dataSource: [],
      columns: [
        {
          title: this.$t(I18N_KEY.COUPON + 'templateID'),
          align: 'left',
          dataIndex: 'id'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'templateName'),
          align: 'left',
          dataIndex: 'templateName'
        },
        // {
        //   title: '模板代码',
        //   align: 'left',
        //   dataIndex: 'templateCode'
        // },
        {
          title: this.$t(I18N_KEY.COUPON + 'type'),
          align: 'left',
          dataIndex: 'type',
          customRender: (text) => {
            return text === 1 ? this.$t(I18N_KEY.COUPON + 'SMS') : this.$t(I18N_KEY.COUPON + 'voice')
          }
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'templateContent'),
          align: 'left',
          dataIndex: 'content',
          customRender: (text) => {
            if(text.length > 100)
               text = text.substring(0,100) + "..."
            return text
          }
        },
        // {
        //   title: this.$t(I18N_KEY.COUPON + 'updateTime'),
        //   align: 'left',
        //   dataIndex: 'updatedTime'
        // },
        // {
        //   title: this.$t(I18N_KEY.COUPON + 'operate'),
        //   dataIndex: 'action',
        //   align: 'center',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      form: {
        id: '',
        templateName: '',
        type: ''
      },
      defaultBasicForm: {
        id: '',
        templateName: '',
        // templateCode: '',
        content: '',
        type: '1',
        remark: ''
      },
      basicForm: {}
    }
  },
  created() {
    this.basicForm = { ...this.defaultBasicForm }
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.search(this.form)
    },
    searchReset() {
      this.form.id = ''
      this.form.templateName = ''
      this.form.type = ''
    },
    search() {

      const form = {
        id: this.form.id === '' ? undefined : this.form.id,
        templateName: this.form.templateName === '' ? undefined : this.form.templateName,
        type: this.form.type === '' ? undefined : this.form.type
      }

      getTemplateList(form).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })


    },
    viewCoupon(mode, coupon = {}) {
      this.visible = true
      this.basicForm.id = coupon.id
      if (mode == 'edit') {
        this.basicForm = {
          ...this.basicForm,
          id: coupon.id,
          templateName: coupon.templateName,
          // templateCode: coupon.templateCode,
          type: coupon.type + "",
          content: coupon.content,
          remark: coupon.remark
        }
      } else {
        this.basicForm = { ...this.defaultBasicForm }
      }
      this.modalTitle = mode == 'add' ? this.$t(I18N_KEY.COUPON + 'addTemplate') : this.$t(I18N_KEY.COUPON + 'modifyTemplate')
    },
    submitForm() {
      this.visible = false
      this.loading = true
      const form = {
        ...this.basicForm
      }

      addOrEditTemplate(form).then(res => {
        this.loading = false
        this.$message.success(this.$t(I18N_KEY.COUPON + 'success'))
        this.search(1)
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    }
  }
}
</script>
