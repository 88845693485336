<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="策略集id">
          <a-input-number  placeholder="请输入策略集id" :min="0" :precision="0" v-decorator="['strategyId', validatorRules.strategyId]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="类型">
          <a-select default-value="" style="width: 190px" v-decorator="[ 'type', validatorRules.type]">
            <a-select-option value="0">
              首贷
            </a-select-option>
            <a-select-option value="1">
              复贷
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="比例">
          <a-input-number  placeholder="请输入比例" :min="0" :precision="0" v-decorator="['scale', validatorRules.scale]"/>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { addDictItem, editDictItem } from '@/webpublicapi/system'

export default {
  name: 'DictItemModal',
  data () {
    return {
      title: '操作',
      visible: false,
      visibleCheck: true,
      model: {},
      dictId: '',
      status: 1,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        strategyId: { rules: [{ required: true, message: '请输入策略id!' }] },
        type: { rules: [{ required: true, message: '请输入类型!' }] },
        scale: { rules: [{ required: true, message: '请输入比例!' }] }
      }
    }
  },
  created () {
  },
  methods: {
    add (dictId) {
      this.dictId = dictId
      this.edit({})
    },
    edit (record) {
      if (record.id) {
        this.dictId = record.dictId
        this.visibleCheck = (record.status == 1)
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.model.dictId = this.dictId
      this.model.status = this.status
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'itemText','itemTextEn','itemTextEs', 'itemValue', 'description', 'sortOrder'))
      })
    },
    onChose (checked) {
      if (checked) {
        this.status = 1
        this.visibleCheck = true
      } else {
        this.status = 0
        this.visibleCheck = false
      }
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          values.itemText = (values.itemText || '').trim()
          values.itemValue = (values.itemValue || '').trim()
          values.description = (values.description || '').trim()
          const formData = Object.assign(this.model, values)
          formData.status = this.status
          let obj
          if (!this.model.id) {
            obj = addDictItem(formData)
          } else {
            obj = editDictItem(formData)
          }
          obj.then((res) => {
            if (res.code === 1000) {
              that.$message.success(res.msg)
              that.$emit('ok')
            } else {
              that.$message.warning(res.msg)
            }
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
