<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="日期">
              <a-range-picker
                v-model="queryParam.endTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.name_of_collector')">
              <a-input :placeholder="$t('enter') + ' ' + $t('platformCollection.name_of_collector')"
                       v-model="queryParam.userName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'催收员账号'">
              <a-input :placeholder="$t('enter') + ' 催收员账号'" v-model="queryParam.sysmobile"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.department')">
              <a-select
                v-model="queryParam.departmentId"
                @change="onChangeOne"
                :allowClear="true"
              >
                <a-select-option v-for="item in departmentList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.grouping')">
              <a-select
                v-model="queryParam.groupId"
                :allowClear="true"
              >
                <a-select-option v-for="item in groupList" :key="item.id">
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.collection_stage')">
              <a-select default-value="" style="width: 190px" v-model="queryParam.normalConfigId">
                <a-select-option v-for="item in normalConfigList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{
                  $t('reset')
                }}</a-button>
              <a-button type="primary" @click="downLoadData('')" icon="download" style="margin-left: 8px">导出</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>


    </div>
  </div>
</template>

<script>
import {filterObj} from '@/utils/util'
import {optDetail} from '@/api/platformCollection'
import moment from 'moment'
import vModal from '../modules/Modal';
import CollectionDetail from './CollectionDetail';
import exportFile from "@/webpublicapi/exportFile";
import {getSysGroup} from "@/webpublicapi/platformCollection";
import {getCollectionListDepartment} from '@/webpublicapi/group'
import {getAllNormalConfig} from "@/webpublicapi/normalConfig";

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  components: {
    vModal,
    CollectionDetail,
  },
  data() {
    return {
      I18N_KEY,
      toggleSearchStatus: false,
      visible: false,
      total: 0,
      dataSource: [],
      groupList: [],
      departmentList: [],
      normalConfigList: [],
      // 查询条件
      queryParam: {
        "groupByParam": "",
        "groupId": "",
        "mobileNo": "",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '日期',
          align: 'left',
          dataIndex: 'create_timeStr'
        },
        {
          title: '催收员账号',
          align: 'left',
          dataIndex: 'mobile',
        },
        {
          title: '催收员',
          align: 'left',
          dataIndex: 'userName'
        },
        // {
        //   title: '催收员编号',
        //   align: 'left',
        //   dataIndex: 'userId'
        // },
        {
          title: '催收分组',
          align: 'left',
          dataIndex: 'groupName'
        },
        {
          title: '总点击次数',
          align: 'left',
          dataIndex: 'all_click',
          sorter: function (a, b) {
            const pre = a.all_click
            const next = b.all_click

            return pre - next
          },
        },
        {
          title: '点击任务数',
          align: 'left',
          dataIndex: 'order_click',
          sorter: function (a, b) {
            const pre = a.order_click
            const next = b.order_click

            return pre - next
          },
        },
        {
          title: 'h7',
          align: 'left',
          dataIndex: 'h7',
          sorter: function (a, b) {
            const pre = a.h7
            const next = b.h7

            return pre - next
          },
          // sorter: true
        },
        {
          title: 'h8',
          align: 'left',
          dataIndex: 'h8',
          sorter: function (a, b) {
            const pre = a.h8
            const next = b.h8

            return pre - next
          },
        },
        {
          title: 'h9',
          align: 'left',
          dataIndex: 'h9',
          sorter: function (a, b) {
            const pre = a.h9
            const next = b.h9

            return pre - next
          },
        },
        {
          title: 'h10',
          align: 'left',
          dataIndex: 'h10',
          sorter: function (a, b) {
            const pre = a.h10
            const next = b.h10

            return pre - next
          },
        },
        {
          title: 'h11',
          align: 'left',
          dataIndex: 'h11',
          sorter: function (a, b) {
            const pre = a.h11
            const next = b.h11

            return pre - next
          },
        },
        {
          title: 'h12',
          align: 'left',
          dataIndex: 'h12',
          sorter: function (a, b) {
            const pre = a.h12
            const next = b.h12

            return pre - next
          },
        },
        {
          title: 'h13',
          align: 'left',
          dataIndex: 'h13',
          sorter: function (a, b) {
            const pre = a.h13
            const next = b.h13

            return pre - next
          },
        },
        {
          title: 'h14',
          align: 'left',
          dataIndex: 'h14',
          sorter: function (a, b) {
            const pre = a.h14
            const next = b.h14

            return pre - next
          },
        },
        {
          title: 'h15',
          align: 'left',
          dataIndex: 'h15',
          sorter: function (a, b) {
            const pre = a.h15
            const next = b.h15

            return pre - next
          },
        },
        {
          title: 'h16',
          align: 'left',
          dataIndex: 'h16',
          sorter: function (a, b) {
            const pre = a.h16
            const next = b.h16

            return pre - next
          },
        },
        {
          title: 'h17',
          align: 'left',
          dataIndex: 'h17',
          sorter: function (a, b) {
            const pre = a.h17
            const next = b.h17

            return pre - next
          },
        },
        {
          title: 'h18',
          align: 'left',
          dataIndex: 'h18',
          sorter: function (a, b) {
            const pre = a.h18
            const next = b.h18

            return pre - next
          },
        },
        {
          title: 'h19',
          align: 'left',
          dataIndex: 'h19',
          sorter: function (a, b) {
            const pre = a.h19
            const next = b.h19

            return pre - next
          },
        },
        {
          title: 'h20',
          align: 'left',
          dataIndex: 'h20',
          sorter: function (a, b) {
            const pre = a.h20
            const next = b.h20

            return pre - next
          },
        },
        {
          title: 'h21',
          align: 'left',
          dataIndex: 'h21',
          sorter: function (a, b) {
            const pre = a.h21
            const next = b.h21

            return pre - next
          },
        },
        {
          title: 'h22',
          align: 'left',
          dataIndex: 'h22',
          sorter: function (a, b) {
            const pre = a.h22
            const next = b.h22

            return pre - next
          },
        },
        {
          title: 'h23',
          align: 'left',
          dataIndex: 'h23',
          sorter: function (a, b) {
            const pre = a.h23
            const next = b.h23

            return pre - next
          },
        },
      ]
    },
  },
  created() {
    this.getDict();
    // this.loadData();
    getSysGroup().then(res => {
      this.groupList = res.data || [];
    });
    getCollectionListDepartment().then(res => {
      this.departmentList = res.data || [];
    });
    getAllNormalConfig().then(res => {
      this.normalConfigList.push({
        "name": "全部",
        "id": "",
        "value": ""
      })
      res.data.forEach(item => {
        this.normalConfigList.push(item);
      })
    });
  },
  methods: {
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery() {
      this.loadData()
    },
    loadData(arg) {
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      optDetail(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          // this.total = res.data.total
          this.dataSource = res.data || [];
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },

    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const {endTime} = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
        endDate: (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)
      // param.pageNum = this.form.pageNum;
      // param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.endTime = undefined;
      that.queryParam.groupList = undefined;
      that.queryParam.departmentList = undefined;
      that.queryParam.normalConfigList = undefined;
      that.queryParam.userName = "";
      that.queryParam.mobileNo = "";
      // that.loadData(this.form.pageNum)
    },
    changeModal(visible) {
      this.visible = visible;
    },
    onChangeOne(value) {
      console.log(value);
      getSysGroup({"departmentId": value}).then(res => {
        this.groupList = res.data || [];
        this.queryParam.groupId = "";
      });
    },
    downLoadData() {
      var params = this.getQueryParams()// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value) + '';
      const file = {
        fileUrl: '/api/web/webpublic/collectionLog/optDetailExcel?' + this.exportParams.replaceAll(",", "&"),
        fileName: 'report.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
