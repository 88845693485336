<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery">
      <a-row :gutter="24">
        <a-col :md="6" :sm="8">
          <a-form-item label="渠道类型">
            <a-input placeholder="请输入渠道类型" v-model="form.channelType"></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item label="渠道">
            <a-input placeholder="请输入渠道" v-model="form.payChannel"></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item label="渠道名称">
            <a-input placeholder="请输入渠道名称" v-model="form.payChannelName"></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item label="包ID">
            <a-input placeholder="请输入包ID" v-model="form.appSsid"></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item :label="'跳Web浏览器开关'">
            <a-select default-value="" style="width: 120px" v-model="form.browserOpen">
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option value="1">
                开启
              </a-select-option>
              <a-select-option value="0">
                关闭
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <span style="float: left; margin-top: 5px; display: block; margin-bottom: 24px;white-space: nowrap; overflow: hidden;" class="table-page-search-submitButtons">
            <a-col :md="6" :sm="24">
                <a-button type="primary" @click="searchQuery">{{ $t('search') }}</a-button>
                <a-button style="margin-left: 8px" @click="searchReset">{{ $t('reset') }}</a-button>
            </a-col>
        </span>
      </a-row>
    </a-form>
    <div class="table-operator"  style="margin-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
    </div>
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :loading="loading"
      bordered>
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">{{ $t('edit') }}</a>
        <a-divider type="vertical" />
        <a-dropdown>
            <a class="ant-dropdown-link">{{ $t('more') }} <a-icon type="down" /></a>
            <a-menu slot="overlay">
                <a-menu-item>
                    <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">
                        <a>{{ $t('delete') }}</a>
                    </a-popconfirm>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right;">
      <v-pagination
        v-if="total"
        :page-num="form.pageNum"
        :page-size="form.pageSize"
        :total="total"
        @change="handleTableChange" />
    </div>
    <pay-channel-info-modal ref="modalForm" @ok="modalFormOk"></pay-channel-info-modal>
  </div>
</template>
<script>
import { payChannelInfoList, payChannelInfoOperate,payChannelInfoDelete} from '@/webpublicapi/pay';
import payChannelInfoModal from './modules/payChannelInfoModal'

// const I18N_KEY = 'custServiceWorkOrder.';
export default {
  components: {
    payChannelInfoModal,
  },
  data () {
    return {
      // I18N_KEY,
      loading: false,
      total: 0,
      form: {
        pageNum: 1,
        pageSize: 10,
        payChannelName: undefined,
        payChannel: undefined,
        channelType: undefined,
        appSsid: undefined,
        browserOpen: undefined,
      },
      OPEN_STATUS: {
        0: '关闭',
        1: '开启',
      },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    // 表头
    columns () {
      return [
        {
          title: 'id',
          dataIndex: 'id',
          width:60,
          align:"center",
        },
        {
          title: 'Banner路径',
          align:"center",
          dataIndex: 'payBannerUrl',
        },
        {
          title: '渠道名称',
          align:"center",
          dataIndex: 'payChannelName',
        },
        {
          title: '渠道',
          align:"center",
          dataIndex: 'payChannel',
        },
        {
          title: '包ID',
          align:"center",
          dataIndex: 'appSsid',
        },
        {
          title: '跳Web浏览器开关',
          dataIndex: 'browserOpen',
          align:"center",
          customRender: (text, record, index) => {
            return this.OPEN_STATUS[text];
          },
        },
        {
          title: '排序',
          align:"center",
          dataIndex: 'sort',
        },
        {
          title: '渠道类型',
          align:"center",
          dataIndex: 'channelType',
        },
        {
          title: '创建时间',
          align:"center",
          dataIndex: 'createTime',
        },
        {
          title: '更新时间',
          align:"center",
          dataIndex: 'updateTime',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }
      ]
    },
  },
  created () {
    this.loadData('');
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    modalFormOk(data) {
      this.createInfo(data);
    },
    handleAdd () {
      this.infoId = undefined;
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "新增";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit (record) {
      this.infoId = record.id;
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "编辑";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    deleteCurrent (row) {
      this.loading = true;
      payChannelInfoDelete(row.id).then(res => {
        this.$message.success(this.$t('success'));
        this.loadData(1);
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    createInfo (data) {
      console.log(data)
      const form = {
        ...data,
        id: this.infoId,
        opt: this.infoId ? '2' : '1',
      };
      payChannelInfoOperate(form).then(res => {
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        this.$message.success('添加成功');
        this.loadData(1);
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    searchReset () {
      this.form = {
        payChannelName: undefined,
        payChannel: undefined,
        channelType: undefined,
        appSsid: undefined,
        browserOpen: undefined,
        pageNum: 1,
        pageSize: 10,
      }
    },
    loadData (pageNum) {
      if(pageNum) { this.form.pageNum = pageNum; }
      this.loading = true;
      payChannelInfoList(this.form).then(res => {
        this.loading = false;
        this.total = res.data.total
        this.dataSource = res.data.rows;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    }
  }
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
