<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="应用名称">
          <a-input placeholder="请输入应用名称" v-decorator="[ 'appName', validatorRules.appName]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="租户id">
          <a-input placeholder="请输入租户id" v-decorator="[ 'appSsid', validatorRules.appSsid]"/>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="包名">
          <a-input placeholder="请输入包名" v-decorator="[ 'appPackageName', validatorRules.appPackageName]"/>
        </a-form-item>

        <!-- 图片上传组件 -->
        <div class="btn_select" @click="selectLoadImg">
          <div>
            <input type="file" name="file" accept="image/*" @change="changeImage()" ref="avatarInput">
          </div>
        </div>
        <button type="submit" class="btn_submit" @click="upLoad">上传图片</button>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="logo">
          <a-input placeholder="请输入logo" v-decorator="[ 'logoUrl',validatorRules.logoUrl]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="APP下载地址">
          <a-input placeholder="请输入APP下载地址" v-decorator="[ 'appDownUrl',validatorRules.appDownUrl]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="催收类型">
          <dict-select
              v-decorator="[ 'collectorType']"
              :dict-code="DICT_CODE.COLLECTOR_TYPE"
              :placeholder="$t('select')"
            />
        </a-form-item>

         <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="showUrl">
          <a-input placeholder="请输入showUrl" v-decorator="[ 'showUrl']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="APP版本">
          <a-input placeholder="请输入APP版本" v-decorator="[ 'appVersion']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="是否被推荐">
          <a-input placeholder="请输入是否被推荐" v-decorator="[ 'vipProductViewFlag']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="客服电话">
          <a-input placeholder="请输入客服电话" v-decorator="[ 'appMobile']"/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import { addApp, editApp,uploadImage } from '@/api/system'
import DictSelect from '../../modules/DictSelect';


// duplicateCheck
export default {
  name: 'AppModal',
  components: {
    DictSelect,
  },
  mounted(){

    if(typeof FileReader=='undifined')        {
      console.log('抱歉，你的浏览器不支持 FileReader');
    }else{
      console.log('FileReader');
    }
  },
  data () {
    return {
      value: 1,
      title: '操作',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        appName: { rules: [{ required: true, message: '请输入应用名称!' }] },
        appSsid: { rules: [{ required: true, message: '请输入租户id!' }] },
        appPackageName: { rules: [{ required: true, message: '请输入包名!' }] },
        logoUrl: { rules: [{ required: true, message: '请输入logoUrl!' }] },
        appDownUrl: { rules: [{ required: true, message: '请输入APP下载地址!' }] },
      }
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({})
    },
    edit (record) {
      if (record.id) {
        this.visiblekey = true
      } else {
        this.visiblekey = false
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'appName', 'appSsid', 'appPackageName', 'logoUrl', 'collectorType', 'showUrl','appVersion','appDownUrl',
        'vipProductViewFlag','appMobile'))
      })
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          let obj
          console.log(formData)
          if (!this.model.id) {
            obj = addApp(formData)
          } else {
             obj = editApp(formData)
          }
          obj.then((res) => {
            if (res.code === 1000) {
              this.$message.success(res.msg)
              this.$emit('ok')
            } else {
              this.$message.warning(res.msg)
            }
          }).finally(() => {
            this.confirmLoading = false
            this.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },

    close () {
      this.$emit('close')
      this.visible = false
    },
    selectLoadImg() {
      this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
    },
    changeImage() {
      let files = this.$refs.avatarInput.files;
      let that = this;
      function readAndPreview(file) {
        that.file = file
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          let reader = new FileReader();
          reader.onload = function() {
            if (that.imgData !== this.result) {
              that.imgData = this.result // 这个是base64的数据格式
            }
          };
          reader.readAsDataURL(file);
        }
      }

      if (files) {
        [].forEach.call(files, readAndPreview);
      }
    },
    upLoad() {
      let imgFile = this.file;//获取到上传的图片
      let formData = new FormData();//通过formdata上传
      formData.append('file', imgFile)
      this.axios({
        method: "post",
        url: "/web/app/upload",
        data: formData,
        core: true
      })
        .then((res) => {
          console.log(res.data);
          alert("上传成功! 地址为:"+res.data)
          this.form.setFieldsValue({
            logoUrl: res.data
          })
        })
        .catch((error) => {
          console.log(error);
        })

    },
  }
}
</script>
