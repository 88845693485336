<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="排序">
          <a-input placeholder="值越小越靠前" v-decorator="[ 'sort', validatorRules.sort]"/>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="类型">
          <dict-radio-group
              v-decorator="['type', validatorRules.type]"
              :dict-code="DICT_CODE.PRODICT_CONFIG_TYPE"
          />
        </a-form-item>
         <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="配置名">
          <a-input placeholder="请输入配置名" v-decorator="[ 'name', validatorRules.name]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="配置内容">
          <a-input placeholder="请输入配置内容" v-decorator="[ 'value', validatorRules.value]"/>
        </a-form-item>


      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import {addProductConfig } from '@/webpublicapi/installmentProductManager'
import DictRadioGroup from '../../modules/DictRadioGroup';
// duplicateCheck
export default {
  name: 'installmentProductConfigModal',
  components:{
    DictRadioGroup
  },
  data () {
    return {
      value: 1,
      title: '操作',
      productId:'',
      visibleCheck: true,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        sort: { rules: [{ required: true, message: '请输入排序!' }] },
        type: { rules: [{ required: true, message: '请输入类型!' }] },
        name: { rules: [{ required: true, message: '请输入配置名!' }] },
        value: { rules: [{ required: true, message: '请输入配置内容!' }] },
      }

    }
  },
  created () {
  },
  methods: {
    add (proId,ssId) {
      this.productId = proId
      this.appSsid=ssId
      this.edit({})
    },
    edit (record) {
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true;
      const data = pick(this.model, 'sort', 'type', 'name','value');
      this.$nextTick(() => {
        this.form.setFieldsValue(data);
      })
    },
    onChose (checked) {
      if (checked) {

        this.visibleCheck = true
      } else {

        this.visibleCheck = false
      }
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          let obj
          if (!that.model.id) {
            formData.productId = this.productId
            formData.appSsid=this.appSsid
            obj = addProductConfig(formData)
          } else {
             obj = editProductItem(formData)
          }
          obj.then((res) => {
            if (res.code === 1000) {
              that.$message.success(res.msg)
              that.$emit('ok')
            } else {
              that.$message.warning(res.msg)
            }
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
