<template>
    <div>
        <a-radio-group
            v-model="propsModel.value"
            @change="handleChange">
            <a-radio
                v-for="item in dictOptions"
                :value="item.itemValue">
                {{ item[fieldKey] }}
            </a-radio>
        </a-radio-group>
    </div>
</template>
<script>
    import dict from './dict-mixin-extend';
    export default {
        mixins: [dict],
        props: {
            dictCode: {
                type: String,
                default: undefined,
            },
            value: {
                type: [String, Number],
                default: undefined,
            },
            size: {
                type: String,
                default: undefined,
            },
            begin: {
                type: Array,
                default: () => [],
            },
            end: {
                type: Array,
                default: () => [],
            }
        },
        computed: {
            propsModel () {
                return { ...this.$props }
            },
            dictOptions () {
                const { dictCode } = this;
                const list = [...this.transformList(this.begin), ...this.getDictList(dictCode), ...this.transformList(this.end)];

                return list;
            },
        },
        methods: {
            transformList (list) {
                list.forEach(item => {
                    item.itemValue = item.value;
                    item.itemTextEn = item.labelEn;
                    item.itemText = item.label;
                })

                return list;
            },
            handleChange (val) {
                this.$emit('input', val);
                this.$emit('change', val);
            },
        }
    }
</script>
<style lang="less" scoped>
    
</style>
