<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="7" :sm="8">
            <a-form-item label="应用名称" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-input placeholder="请输入应用名称" v-model="queryParam.appName"></a-input>
            </a-form-item>
          </a-col>
            <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <div class="table-operator" style="border-top: 5px">
        <a-button @click="handleAdd" type="primary" icon="plus">添加</a-button>
      </div>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :scroll="{x:1000}"
        bordered
        @change="handleTableChange">
        <Dict slot="collectorType" slot-scope="text" :dict-code="DICT_CODE. COLLECTOR_TYPE" :value="text"/>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">
            编辑
          </a>
          <a-divider type="vertical"/>
          <a @click="editSubApp(record)">新增子产品</a>
          <a-divider type="vertical"/>
          <a @click="editUser(record)">员工管理</a>
<!--          <a-divider type="vertical"/>-->
<!--          <a @click="editDictItem(record)"><a-icon type="setting"/> 字典配置</a>-->
          <a-divider type="vertical"/>
          <a-popconfirm title="确定删除吗?" @confirm="() =>handleDelete(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>

    </div>
    <app-modal ref="modalForm" @ok="modalFormOk"></app-modal>
    <employee ref="employee"></employee>
    <AppStrategyidSetting ref="appStrategyidSetting"></AppStrategyidSetting>
    <sub-app-list ref='subAppList'></sub-app-list>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getAppList, deleteApp, getDurationsByAppParentId } from '@/api/system'
import Dict from '../modules/Dict';
import AppModal from './modules/AppModal'
import Employee from './Employee'
import AppStrategyidSetting from './modules/AppStrategyidSetting'
import SubAppList from './SubAppList'

export default {
  name: 'AppList',
  components: {
    AppModal,
    SubAppList,
    Employee,
    AppStrategyidSetting,
    Dict
  },
  data () {
    return {
      form: this.$form.createForm(this),
      model: {},
      visible: false,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      dataSource: [],
      // 查询条件
      queryParam: {
        appName: '',
      },
      loading: false,
      // 表头
      columns: [
        {
          title: '应用名称',
          align: 'left',
          width: '100px',
          dataIndex: 'appName'
        },
        {
          title: '租户id',
          align: 'left',
          width: '100px',
          dataIndex: 'appSsid'
        },
        {
          title: '包名',
          align: 'left',
          width: '100px',
          dataIndex: 'appPackageName'
        },
        {
          title: 'logo',
          align: 'left',
          width: '400px',
          dataIndex: 'logoUrl'
        },
        {
          title: '催收类型',
          align: 'left',
          dataIndex: 'collectorType',
          width: '100px',
          scopedSlots: { customRender: 'collectorType' },
        },
        {
          title: 'APP版本',
          align: 'left',
          dataIndex: 'appVersion',
          width: '100px',
        },
        {
          title: 'APP下载地址',
          align: 'left',
          dataIndex: 'appDownUrl',
          width: '100px',
        },{
          title: '是否被推荐',
          align: 'left',
          dataIndex: 'vipProductViewFlag',
          width: '100px',
        },{
          title: '客服电话',
          align: 'left',
          dataIndex: 'appMobile',
          width: '100px',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '300px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    this.loadData(1)
  },
  methods: {
    //获取此应用下产品的期限列表
    getDurationsByAppParentId(parentId) {
      getDurationsByAppParentId(parentId).then(res => {
        this.curDurations += res.data.toString()
      })
    },

    //显示模态框
    showModal(record) {
      this.parentId = record.id
      this.getDurationsByAppParentId(this.parentId)
      this.visible = true;
    },
    //关闭模态框
    handleCancel() {
      this.close()
    },

    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getAppList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.ipagination.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.ipagination.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    getQueryParams () {
      var param = Object.assign({}, this.queryParam, this.isorter)
      param.pageNum = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    handleTableChange (pagination, filters, sorter) {
      // TODO 筛选
      // if (Object.keys(sorter).length > 0) {
      //   this.isorter.column = sorter.field
      //   this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      // }
      this.ipagination = pagination
      this.loadData()
    },
    handleAdd () {
      this.$refs.modalForm.add()
      this.$refs.modalForm.title = '新增'
    },
    handleEdit (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleDelete(id){
      deleteApp({id}).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.loadData()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    editUser (record) {
      this.$refs.employee.edit(record)
    },
    editSubApp (record) {
      this.$refs.subAppList.edit(record)
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.appName = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk () {
      this.loadData()
    } ,
    // 编辑
    editDictItem (record) {
      this.$refs.appStrategyidSetting.edit(record)
    },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
