<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'type')">
              <a-select v-model="queryParam.type" :placeholder="$t('select')" style="width: 100%">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in types' :key='item.value'>
<!--                  {{$t(I18N_KEY + 'type_' + item.label)}}-->
                  {{item[fieldName]}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

<!--          <template>-->
<!--            <a-col :md="8" :sm="12">-->
<!--              <a-form-item :label="$t(I18N_KEY + 'dataDate')">-->
<!--                <a-range-picker v-model="createdTime" format="YYYY-MM-DD" />-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--          </template>-->

          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
<!--              <a-button type="primary" v-if="view" @click="downLoadData" icon="search" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>-->
            </span>
          </a-col>

        </a-row>
      </a-form>
    <div class="table-operator"  style="margin-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('add') }}</a-button>
    </div>
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :loading="loading"
      bordered>
<!--      <template #type="text,record,index">{{$t(I18N_KEY + 'type_' + text)}}</template>-->
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">{{ $t('edit') }}</a>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link">
          {{ $t('more') }} <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">
                <a>{{ $t('delete') }}</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right;">
      <v-pagination
        v-if="total"
        :page-num="queryParam.pageNum"
        :page-size="queryParam.pageSize"
        :total="total"
        @change="handleTableChange" />
    </div>
      <assign-config-modal ref="modalForm" :tele-market-users='teleMarketUsers' :auto-assign-types='autoAssignTypes' :apps='apps' :types='types' @ok="modalFormOk"></assign-config-modal>
    </div>
  </div>
</template>
<script>
import { assignConfigList, assignConfigOperate, assignConfigDelete, baseData } from '@/webpublicapi/teleMarket'
import AssignConfigModal from './modules/AssignConfigModal'
import moment from "moment";
import {mapGetters} from "vuex";
const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketAssignConfig',
  components: {
    AssignConfigModal,
  },
  data () {
    return {
      I18N_KEY,
      loading: false,
      total: 0,
      teleMarketUsers: [],
      types: [],
      autoAssignTypes: [],
      apps: [],
      fieldName: 'textCn',
      // createdTime: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        type: null,
        // startDate: null,
        // endDate: null,
      },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    // 表头
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          dataIndex: 'id',
          width:60,
          align:"center",
        },
        {
          title: this.$t(I18N_KEY + 'type'),
          align: 'center',
          dataIndex: 'type',
          customRender: (text, record) => {
            var text = text;
            if(text != undefined && text != null && text != '') {
              for(var i in this.types) {
                if(text === this.types[i].value) {
                  return this.types[i][this.fieldName]
                }
              }
            } else {
              return '';
            }
          },
          // scopedSlots: { customRender: 'type' }
        },
        {
          title: this.$t(I18N_KEY + 'param'),
          align:"center",
          dataIndex: 'param',
        },
        {
          title: this.$t(I18N_KEY + 'teleMarketUser'),
          align:"center",
          dataIndex: 'users',
          customRender: (text, record) => {
            var text = text;
            var result = "";
            if(text != undefined && text != null && text != '') {
              var users = text.split(',')
              for(var i in users) {
                var user = users[i]
                for(var x in this.teleMarketUsers) {
                  if(user === this.teleMarketUsers[x].value) {
                    result = result + this.teleMarketUsers[x].label + ','
                    break
                  }
                }
              }
            }
            if(result.endsWith(',')) {
              result = result.substring(0, result.length-1)
            }
            return result;
          }
        },
        {
          title: this.$t(I18N_KEY + 'app'),
          align:"center",
          dataIndex: 'appSsids',
        },
        {
          title: this.$t(I18N_KEY + 'assignTime'),
          align:"center",
          dataIndex: 'assignDate',
        },
        {
          title: this.$t(I18N_KEY + 'startTime'),
          align:"center",
          dataIndex: 'startTime',
        },
        {
          title: this.$t(I18N_KEY + 'endTime'),
          align:"center",
          dataIndex: 'endTime',
        },
        {
          title: this.$t(I18N_KEY + 'createTime'),
          align:"center",
          dataIndex: 'createTime',
        },
        // {
        //   title: this.$t(I18N_KEY + 'createUser'),
        //   align:"center",
        //   dataIndex: 'createUser',
        // },
        {
          title: this.$t(I18N_KEY + 'updateTime'),
          align:"center",
          dataIndex: 'updateTime',
        },
        // {
        //   title: this.$t(I18N_KEY + 'updateUser'),
        //   align:"center",
        //   dataIndex: 'updateUser',
        // },
        {
          title: this.$t(I18N_KEY + 'operate'),
          dataIndex: 'action',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData('');
  },
  methods: {
    loadBaseData(){
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.types = res.data.types
          this.teleMarketUsers = res.data.systemSeats
          this.autoAssignTypes = res.data.autoAssignTypes
          this.apps = res.data.apps
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    searchQuery () {
      this.loadData(1)
    },
    handleTableChange (pageNum, pageSize) {
      this.queryParam.pageNum = pageNum;
      this.queryParam.pageSize = pageSize;
      this.loadData();
    },
    modalFormOk(data) {
      this.createSeat(data);
    },
    handleAdd () {
      this.versionId = undefined;
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "1";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit (record) {
      this.versionId = record.id;
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "2";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    deleteCurrent (row) {
      this.loading = true;
      assignConfigDelete(row.id).then(res => {
        this.$message.success(this.$t('success'));
        this.loadData(1);
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    createSeat (data) {
      console.log(data)
      const form = {
        ...data,
        id: this.versionId,
        opt: this.versionId ? '2' : '1',
      };
      // const requestField = this.versionId ? '2' : '1'
      assignConfigOperate(form).then(res => {
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        if (res.code === 1000) {
          this.$message.success(res.msg);
          this.loadData(1);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    searchReset () {
      this.createdTime = [],
      this.queryParam.pageNum=1
      this.queryParam.pageSize=10
      this.queryParam.type=null
      // this.queryParam.startDate=null
      // this.queryParam.endDate=null
    },
    loadData (pageNum) {
      if(pageNum) { this.queryParam.pageNum = pageNum; }
      this.loading = true;
      // if(this.createdTime != null && this.createdTime != []) {
      //   this.queryParam.startDate = this.createdTime && this.transformTime(this.createdTime[0]);
      //   this.queryParam.endDate = this.createdTime && this.transformTime(this.createdTime[1]);
      // } else {
      //   this.queryParam.startDate = null;
      //   this.queryParam.endDate = null;
      // }
      assignConfigList(this.queryParam).then(res => {
        this.loading = false;
        this.total = res.data.total
        this.dataSource = res.data.rows;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    }
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
