<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item :label="'日期'" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-range-picker v-model="queryParam.dataDt" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'产品类型'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.productType">
                <a-select-option value=""> 全部 </a-select-option>
                <a-select-option value="1"> 主包产品 </a-select-option>
                <a-select-option value="2"> 多贷产品 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'分期类型'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.pageType">
                <a-select-option value="">
                  初始
                </a-select-option>
                <a-select-option value="1">
                  全部
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="downLoadData('')" icon="download" style="margin-left: 8px"
                >导出</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        bordered
        :rowClassName="(record) => isWeekDay(record.datadt)"
        @change="handleTableChange"
      >
      </a-table>
    </div>
    <RefundTradeModal ref="modalForm" @ok="modalFormOk"></RefundTradeModal>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getAppAllCountForDate } from '@/webpublicapi/order'
import Dict from '../modules/Dict'
import { REFUND_TRADE_STATUS } from '@/constants/status'
import RefundTradeModal from './modules/RefundTradeModal'
import moment from 'moment'
import exportFile from '@/webpublicapi/exportFile'
export default {
  name: 'AppList',
  components: {
    RefundTradeModal,
    Dict,
  },
  data() {
    return {
      visible: false,
      REFUND_TRADE_STATUS,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      columnsInit: [
        {
          title: '日期',
          align: 'left',
          dataIndex: 'datadt'
        },
        {
          title: '花费',
          align: 'left',
          dataIndex: 'fee'
        },
        {
          title: '安装',
          align: 'left',
          dataIndex: 'installs'
        },
        {
          title: '注册',
          align: 'left',
          dataIndex: 'regCount'
        },
        {
          title: '申贷',
          align: 'left',
          dataIndex: 'subCount'
        },
        {
          title: '通过',
          align: 'left',
          dataIndex: 'approvalCount'
        },
        {
          title: '放款',
          align: 'left',
          dataIndex: 'loanCount'
        },
        {
          title: '安装-注册转化率',
          align: 'left',
          dataIndex: 'irRate'
        },
        {
          title: '注册-申请转化率',
          align: 'left',
          dataIndex: 'rsRate'
        },
        {
          title: '申请-通过转化率',
          align: 'left',
          dataIndex: 'saRate'
        },
        {
          title: '申请-放款转化率',
          align: 'left',
          dataIndex: 'slRate'
        },
        {
          title: '通过-放款转化率',
          align: 'left',
          dataIndex: 'alRate'
        },
        {
          title: '安装单价',
          align: 'left',
          dataIndex: 'installFee'
        },
        {
          title: '注册单价',
          align: 'left',
          dataIndex: 'regFee'
        },
        {
          title: '申贷单价',
          align: 'left',
          dataIndex: 'subFee'
        },
        {
          title: '通过单价',
          align: 'left',
          dataIndex: 'approvalFee'
        },
        {
          title: '放款单价',
          align: 'left',
          dataIndex: 'loanFee'
        }
      ],
      headers: {
        // authorization: 'authorization-text',
      },

      dataSource: [],
      // 查询条件
      queryParam: {
        dataDt: [moment().add('days', -60), moment().add('days', 0)],
      },
      loading: false,
      // 表头
      columns: [
        {
          title: '日期',
          align: 'left',
          dataIndex: 'datadt',
        },
        {
          title: '花费',
          align: 'left',
          dataIndex: 'fee',
        },
        {
          title: '安装',
          align: 'left',
          dataIndex: 'installs',
        },
        {
          title: '注册',
          align: 'left',
          dataIndex: 'regCount',
        },
        {
          title: '申贷',
          align: 'left',
          dataIndex: 'subCount',
        },
        {
          title: '通过',
          align: 'left',
          dataIndex: 'approvalCount',
        },
        {
          title: '放款',
          align: 'left',
          dataIndex: 'loanCount',
        },
        {
          title: '安装-注册转化率',
          align: 'left',
          dataIndex: 'irRate',
        },
        {
          title: '注册-申请转化率',
          align: 'left',
          dataIndex: 'rsRate',
        },
        {
          title: '申请-通过转化率',
          align: 'left',
          dataIndex: 'saRate',
        },
        {
          title: '申请-放款转化率',
          align: 'left',
          dataIndex: 'slRate',
        },
        {
          title: '通过-放款转化率',
          align: 'left',
          dataIndex: 'alRate',
        },
        {
          title: '安装单价',
          align: 'left',
          dataIndex: 'installFee',
        },
        {
          title: '注册单价',
          align: 'left',
          dataIndex: 'regFee',
        },
        {
          title: '申贷单价',
          align: 'left',
          dataIndex: 'subFee',
        },
        {
          title: '通过单价',
          align: 'left',
          dataIndex: 'approvalFee',
        },
        {
          title: '放款单价',
          align: 'left',
          dataIndex: 'loanFee',
        },
      ],
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 },
      },
    }
  },
  created() {
    this.loadData(1)
  },
  methods: {
    isWeekDay(day) {
      const date = new Date(day)
      return date.getDay() % 6 === 0 ? 'table_weekendColor' : ''
    },
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getAppAllCountForDate(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){

          this.columns = [];
          var col = res.data.columns

          for(var i = 0 ; i < this.columnsInit.length ; i++){
            this.columns.push(this.columnsInit[i]);
          }

          for(var i = 0 ; i < col.length ; i++){
            this.columns.push(col[i]);
          }

          this.ipagination.total = res.data.total
          this.dataSource = res.data.rows

            // for(var i = 0 ; i < this.dataSource.length ; i++){
            //   this.dataSource[i].fee = (this.dataSource[i].fee * 0.049).toFixed(2);
            // this.dataSource[i].installFee = (this.dataSource[i].fee / this.dataSource[i].installs).toFixed(2);
            // this.dataSource[i].subFee = (this.dataSource[i].fee / this.dataSource[i].subCount).toFixed(2);
            // this.dataSource[i].loanFee = (this.dataSource[i].fee / this.dataSource[i].loanCount).toFixed(2);
            // }
          } else {
            this.ipagination.total = 0
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    getQueryParams() {
      const { dataDt } = this.queryParam
      const data = {
        ...this.queryParam,
        startDate: dataDt && this.transformTime(dataDt[0]),
        endDate: dataDt && this.transformTime(dataDt[1]),
      }
      delete data.dataDt
      data.pageNum = this.ipagination.current
      data.pageSize = this.ipagination.pageSize
      return filterObj(data)
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    handleTableChange(pagination, filters, sorter) {
      // TODO 筛选
      // if (Object.keys(sorter).length > 0) {
      //   this.isorter.column = sorter.field
      //   this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      // }
      this.ipagination = pagination
      this.loadData()
    },
    handleAdd() {
      this.$refs.modalForm.add()
      // this.$refs.modalForm.title = '新增'
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.appSsid = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk() {
      this.loadData()
    },
    handleChangeUpload(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    downLoadData(arg2) {
      var params = this.getQueryParams(arg2) // 查询条件
      const file = {
        fileUrl:
          '/api/web/webpublic/statistics/appAllCountForDateExcel?startDate=' +
          params.startDate +
          '&endDate=' +
          params.endDate +
          '&productType=' +
          params.productType,
        fileName: 'appAllCountExcel.xls',
      }
      this.loading = true
      exportFile(file)
        .catch((e) => {
          this.$message.error(e.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';
</style>
