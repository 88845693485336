<template>
  <a-card :bordered="false">
    <div class="table-search-wrapper">
      <a-row :gutter="48">
        <a-col :span="6">
          <a-input v-model="model.name" placeholder="请输入策略码"></a-input>
        </a-col>
        <a-col :span="6">
          <a-input v-model="model.intro" placeholder="请输入说明"></a-input>
        </a-col>
        <a-col :span="12">
          <a-button @click="handleSearch" style="margin-right: 8px;">查询</a-button>
          <a-button type="primary" @click="handleAdd" style="margin-right: 8px;">创建</a-button>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="table.columns"
        :dataSource="table.dataSource"
        :loading="table.loading"
        :pagination="false"
        bordered
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleCopy(record)">复制</a>
          <a-divider type="vertical"/>
          <a @click="viewFeaturesUsed(record)">决策列表</a>
        </span>
      </a-table>
    </div>
    <div class="table-page-wrapper">
      <a-pagination size="small" v-model="table.pageNum" :pageSizeOptions="table.pageSizeOptions"
                    :pageSize="table.pageSize"
                    :total="table.total"
                    :show-total="total => `共 ${total} 条`"
                    show-size-changer show-quick-jumper @change="handlePageChange" @showSizeChange="handlePageChange"/>
    </div>
    <rule-edit-modal ref="editModal" @success="loadData"></rule-edit-modal>
    <rule-use-modal ref="useModal"></rule-use-modal>
  </a-card>
</template>

<script>
import {riskStrategyList} from '@/webpublicapi/risk'
import riskRuleDetail from './riskRuleDetail.vue'
import RuleEditModal from "@/views/webpublic/risk/modules/RuleEditModal";
import RuleUseModal from "@/views/webpublic/risk/modules/RuleUseModal";

export default {
  name: 'riskVariableList',
  components: {RuleUseModal, RuleEditModal, riskRuleDetail},
  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },
    start: {
      type: [String, Number],
      default: undefined,
    },
    end: {
      type: [String, Number],
      default: undefined,
    }
  },
  data() {
    return {
      table: {
        dataSource: [],
        columns: [
          {
            title: '策略集ID',
            align: 'center',
            dataIndex: 'id'
          },
          {
            title: '助记符',
            align: 'center',
            dataIndex: 'name'
          },
          {
            title: '名称或说明',
            align: 'center',
            dataIndex: 'intro'
          },
          {
            title: '操作',
            dataIndex: 'action',
            align: "center",
            scopedSlots: {customRender: 'action'},
          }
        ],
        pageSizeOptions: ['10', '20', '30', '40'],
        pageNum: 1, pageSize: 10, total: 0, loading: false,
      },
      model: {
        name: '', intro: ''
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    handleSearch() {
      this.table.pageNum = 1
      this.loadData()
    },
    handlePageChange(pageNum, pageSize) {
      this.table.pageNum = pageNum
      this.table.pageSize = pageSize
      this.loadData()
    },
    loadData() {
      this.table.loading = true
      riskStrategyList({
        ...this.model,
        pageSize: this.table.pageSize,
        pageNum: this.table.pageNum
      }).then(({code, data, msg}) => {
        if (code === 1000) {
          this.table.dataSource = data.rows
          this.table.total = data.total
        } else {
          this.table.dataSource = []
          this.table.total = 0
          this.$message.error(msg)
        }
        this.table.loading = false
      }).catch(e => {
        this.table.loading = false;
        this.$message.error(e.message);
      })
    },
    handlePickChange() {

    },
    viewFeaturesUsed(record) {
      this.$refs.useModal.show(record.id)
    },
    handleAdd() {
      this.$refs.editModal.showAdd()
    },
    handleCopy(record) {
      this.$refs.editModal.showCopy(record.id)
    }
  }
}
</script>
<style scoped>
.table-search-wrapper {
  padding-bottom: 8px;
}

.table-page-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}
</style>
