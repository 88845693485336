<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="'到期日'">
              <a-range-picker
                v-model="queryParam.endTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        bordered
        @change="handleTableChange">
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import {getOrderAllCount, getOrderCountByLoanCount} from '@/webpublicapi/order'
import Dict from '../modules/Dict';
import { REFUND_TRADE_STATUS } from '@/constants/status';
import RefundTradeModal from './modules/RefundTradeModal'
import moment from "moment";

export default {
  name: 'AppList',
  components: {
    RefundTradeModal,
    Dict
  },
  data () {
    return {
      visible: false,
      REFUND_TRADE_STATUS,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },

      headers: {
        // authorization: 'authorization-text',
      },

      dataSource: [],
      // 查询条件
      queryParam: {
        accountNumber: '',
      },
      loading: false,
      // 表头
      columns: [
        {
          title: 'loan count',
          align: 'left',
          dataIndex: 'loanCount'
        },
        {
          title: '到期客户数',
          align: 'left',
          dataIndex: 'totalEndCount'
        },
        {
          title: '结清人数',
          align: 'left',
          dataIndex: 'settleCount'
        },
        {
          title: '复贷申请人数',
          align: 'left',
          dataIndex: 'conversionApplyCount'
        },
        {
          title: '复贷人数',
          align: 'left',
          dataIndex: 'conversionCount'
        },
        {
          title: '复贷人数(3天内)',
          align: 'left',
          dataIndex: 'conversionApplyCount3'
        },
        {
          title: '复贷人数(7天内)',
          align: 'left',
          dataIndex: 'conversionApplyCount7'
        },
        {
          title: '复贷人数(14天内)',
          align: 'left',
          dataIndex: 'conversionApplyCount14'
        },
        {
          title: '复贷人数(30天内)',
          align: 'left',
          dataIndex: 'conversionApplyCount30'
        },
        {
          title: '坏账率',
          align: 'left',
          dataIndex: 'badRate'
        },
        {
          title: '复借率（到期人数为分母）',
          align: 'left',
          dataIndex: 'reborrowEndRate'
        },
        {
          title: '复借率（结清人数为分母）',
          align: 'left',
          dataIndex: 'reborrowSettleRate'
        }
      ],
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    // this.loadData(1)
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getOrderCountByLoanCount(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        endTimeStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
        endTimeEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange (pagination, filters, sorter) {
      // TODO 筛选
      // if (Object.keys(sorter).length > 0) {
      //   this.isorter.column = sorter.field
      //   this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      // }
      this.ipagination = pagination
      this.loadData()
    },
    handleAdd () {
      this.$refs.modalForm.add()
      // this.$refs.modalForm.title = '新增'
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.appSsid = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk () {
      this.loadData()
    },
    handleChangeUpload(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
