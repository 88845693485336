<template>
  <a-modal
    :title="title === '1' ? $t('add') : $t('edit')"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'userGroupName')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'userGroupName')" v-decorator="[ 'name', validatorRules.name]" />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'userGroupName_zh')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'userGroupName_zh')" v-decorator="[ 'nameZh', validatorRules.nameZh]" />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'userGroupName_en')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'userGroupName_en')" v-decorator="[ 'nameEn', validatorRules.nameEn]" />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'userGroupName_es')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'userGroupName_es')" v-decorator="[ 'nameEs', validatorRules.nameEs]" />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'userGroupName_fr')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'userGroupName_fr')" v-decorator="[ 'nameFr', validatorRules.nameFr]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'countPre')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'countPre')" v-decorator="[ 'countPre', validatorRules.countPre]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'teleMarketUser')">
          <a-select v-decorator="[ 'users', validatorRules.users]" :placeholder="$t('select') + ' ' + $t(I18N_KEY + 'teleMarketUser')" mode="multiple">
            <a-select-option v-for='item in teleMarketUsers' :key='item.mobile'>
              {{item.seat+'('+item.groupId+')'}}
            </a-select-option>
          </a-select>
        </a-form-item>


        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'userGroupStatus')">
          <a-radio-group
            v-decorator="['status', { initialValue: '1' }]">
            <span v-for="item in this.statuses">
              <a-radio :value=item.value>{{item[fieldName]}}</a-radio>
            </span>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
const I18N_KEY = 'teleMarket.';
export default {
  name: "TeleMarketUserGroupEditModal",
  props: {
    teleMarketUsers: {
      type: Array,
      default: () => [],
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    fieldName:{
      type: String,
      default: 'textCn',
    }
  },
  data () {
    return {
      I18N_KEY,
      title:"操作",
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules:{
        name:{
          rules: [
            { required: true, message: 'Please input name!' },
          ]},
        nameZh:{
          rules: [
            { required: true, message: 'Please input nameZh!' },
          ]},
        nameEn:{
          rules: [
            { required: true, message: 'Please input nameEn!' },
          ]},
        nameEs:{
          rules: [
            { required: true, message: 'Please input nameEs!' },
          ]},
        nameFr:{
          rules: [
            { required: true, message: 'Please input nameFr!' },
          ]},
        status:{
          rules: [
            { required: true, message: 'Please select status!' },
          ]},
        users:{
          rules: [
            { required: true, message: 'Please select users!' },
          ]},
        countPre:{
          rules: [
            { required: true, message: 'Please input count!' },
          ]},
      },
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({});
    },
    edit (record) {
      this.form.resetFields();
      this.open();
      this.model = Object.assign({}, record)
      this.model = {
        ...this.model
      }

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'name','nameZh','nameEn','nameEs','nameFr','status', 'countPre', 'users'))
      });

    },
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      // console.log(88888)
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            ...values,
          }
          this.$emit('ok', formData);

        }
      })
    },
    handleCancel () {
      this.close()
    },
  }
}
</script>

<style scoped>

</style>