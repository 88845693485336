<template>
    <div>
        <div style="padding: 15px;">
          <a-form layout="inline">
            <a-row :gutter="18">
              <a-col :md="6" :sm="8">
                <a-input style="width: 240px; margin-right: 8px;" placeholder="keyword" v-model="form.keywords"/>
              </a-col>

              <a-col :md="6" :sm="8">
                <a-form-item :label="$t('platformCollection.grouping')">
                  <a-select
                    v-model="form.groupIdP" style="width: 200px; margin-right: 8px;"
                  >
                    <a-select-option v-for="item in groupListOut" :key="item.id">
                      {{ item.groupName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="8">
                <a-form-item :label="$t('platformCollection.state')">
                  <a-select default-value=""
                            style="width: 120px" v-model="form.userstatus">
                    <a-select-option value="">
                      {{ $t('platformCollection.all') }}
                    </a-select-option>
                    <a-select-option value="1">
                      {{ $t('platformCollection.enable') }}
                    </a-select-option>
                    <a-select-option value="2">
                      {{ $t('platformCollection.disable') }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="8">
              <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                <a-button type="primary" @click="search(1)">{{ $t('search') }}</a-button>
                <a-button style="margin-left: 10px;" type="primary" @click="createUser('add')"><a-icon type="plus" />{{ $t('add') }}</a-button>
              </span>
              </a-col>
            </a-row>
          </a-form>

        </div>
        <a-table
            :dataSource="list"
            :pagination="false"
            :loading="loading"
            bordered
            @change="handleTableChange">
            <a-table-column
                :title="$t(I18N_KEY + 'index')"
                dataIndex="index"
                key="index"
                align="center"
            />
            <a-table-column
                :title="$t(I18N_KEY + 'name')"
                dataIndex="userName"
                key="userName"
                align="center"
            />
            <a-table-column
                :title="$t('platformCollection.account')"
                dataIndex="mobile"
                key="mobile"
                align="center"
            />
            <!--<a-table-column-->
                <!--:title="$t(I18N_KEY + 'mail')"-->
                <!--dataIndex="email"-->
                <!--key="email"-->
                <!--align="center"-->
            <!--/>-->
            <a-table-column
                :title="$t(I18N_KEY + 'time')"
                dataIndex="createTime"
                key="createTime"
                align="center"
            />
            <a-table-column
                :title="$t(I18N_KEY + 'status')"
                dataIndex="status"
                key="status"
                align="center"
            >
                <template slot-scope="text, record">
                    <a-badge :status="text == 1 ? 'success' : 'default'" />
                    <span>{{ USER_STATUS[text] }}</span>
                </template>
            </a-table-column>
            <a-table-column
                :title="$t(I18N_KEY + 'action')"
                dataIndex="action"
                key="action"
                align="center"
            >
                <template slot-scope="text, record">
                    <span>
                        <a href="javascript:;" @click="createUser('edit', record)">{{ $t(I18N_KEY + 'edit') }}</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" @click="deleteCurrent(record)">{{ $t(I18N_KEY + 'delete') }}</a>
                    </span>
                </template>
            </a-table-column>
        </a-table>
        <div style="margin-top: 15px; text-align: right;">
            <v-pagination
                v-if="total"
                :page-num="form.pageNum"
                :page-size="form.pageSize"
                :total="total"
                @change="handleTableChange" />
        </div>
        <a-modal
            :title="modalTitle"
            v-model="visible"
            @ok="submitUser"
        >
            <a-form :form="basicForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                <a-form-item :label="$t(I18N_KEY + 'name')">
                  <a-input v-model="basicForm.userName" />
                </a-form-item>

                <a-form-item :label="$t('platformCollection.account')">
                  <a-input v-model="basicForm.mobile" />
                </a-form-item>
                <!--<a-form-item :label="$t(I18N_KEY + 'mail')">-->
                  <!--<a-input v-model="basicForm.email" />-->
                <!--</a-form-item>-->
                <a-form-item :label="$t(I18N_KEY + 'password')">
                  <a-input v-model="basicForm.password" />
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'status')">
                  <a-switch v-model="basicForm.status" />
                </a-form-item>
                <!--<a-form-item :label="$t(I18N_KEY + 'role')">-->
                    <!--<a-select-->
                        <!--mode="multiple"-->
                        <!--v-model="basicForm.roleIds"-->
                    <!--&gt;-->
                        <!--<a-select-option v-for="item in roleList" :key="item.id">-->
                            <!--{{ item.roleName }}-->
                        <!--</a-select-option>-->
                    <!--</a-select>-->
                <!--</a-form-item>-->
                <a-form-item :label="$t('platformCollection.user_grouping')">
                  <a-select
                    mode="multiple"
                    v-model="basicForm.groupIds"
                  >
                    <a-select-option v-for="item in groupList" :key="item.id">
                      {{ item.groupName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import { getAppList } from '@/webpublicapi/system';
    import { getSysGroup } from '@/webpublicapi/platformCollection'
    import vPagination from '../modules/pagination';

    const I18N_KEY = 'system.userList.';
    export default {
        components: {
            vPagination,
        },
        data () {
            return {
                I18N_KEY,
                visible: false,
                modalTitle: undefined,
                loading: false,
                form: {
                    pageNum: 1,
                    pageSize: 10,
                    keywords: undefined,
                    groupIdP: '',
                },
                defaultBasicForm: {
                    userName: undefined,
                    roleIds: [13],
                    subApps: [],
                    mobile: undefined,
                    email: undefined,
                    password: undefined,
                    status: true,
                    groupIds: [],
                    userstatus: ''
                },
                basicForm: {},
                USER_STATUS: {
                    1: this.$t('platformCollection.enable'),
                    2: this.$t('platformCollection.disable'),
                },
                appList: [],
                groupList: [],
                groupListOut:[],

            }
        },
        computed: {
            ...mapGetters('system', [
                'colUserList',
                'userList',
                'allRoleList',
                'sysGroup',

            ]),
            list () {
                const list = (this.colUserList && this.colUserList.rows) || [];
                return list.map((item, index) => {
                    return {
                        index: index + 1,
                        ...item,
                    }
                });
            },
            total () {
                return this.colUserList && this.colUserList.total;
            },
            pagination () {
                return {
                    pageSize: this.form.pageSize,
                    current: this.form.pageNum,
                    total: this.total,
                }
            },
            roleList () {
                return this.allRoleList || [];
            },
        },
        created () {
            window.xx = this;
            this.basicForm = { ...this.defaultBasicForm };
            this.search();
            this.getAllRoleList();
            this.getAppList();
            this.getSysGroup();
            this.getSysGroupOut();
        },
        methods: {
            ...mapActions('system', [
                'getColUserList',
                'addColUser',
                'editColUser',
                'deleteUser',
                'getAllRoleList',
                'getSysGroup',
            ]),
            handleTableChange (pageNum, pageSize) {
                this.form.pageNum = pageNum;
                this.form.pageSize = pageSize;
                this.search();
            },
            changeModal (visible) {
                this.visible = visible;
            },
            getAppList () {
                getAppList({ pageNum: 1, pageSize: 100 }).then((res) => {
                    if(res.code===1000){
                      this.appList = res.data.rows || [];
                    }
                });
            },
            getSysGroup () {
              getSysGroup({}).then((res) => {
                if(res.code===1000){
                  this.groupList = res.data || [];
                }
              });
            },
          getSysGroupOut () {
            getSysGroup({}).then((res) => {
              if(res.code===1000){
                this.groupListOut = res.data || [];
                var nogroup = {};
                nogroup.id = 0;
                nogroup.groupName = this.$t('platformCollection.ungrouped');
                this.groupListOut.push(nogroup);
                var allgroup = {};
                allgroup.id = -1;
                allgroup.groupName = this.$t('platformCollection.all');
                this.groupListOut.push(allgroup);
              }
            });
          }
            ,
            createUser (mode, user = {}) {
                this.changeModal(true);
                this.basicForm.userId = user.id;
                if (mode == 'edit') {
                    this.basicForm = {
                        ...this.basicForm,
                        userName: user.userName,
                        mobile: user.mobile,
                        email: user.email,
                        password: user.password,
                        status: user.status == 1,
                        roleIds: [13],
                        groupIds: user.groupIds,
                    }
                } else {
                    this.basicForm = { ...this.defaultBasicForm };
                }
                this.modalTitle = mode == 'add' ? this.$t('platformCollection.add_user') : this.$t('platformCollection.modify_user')
            },
            deleteCurrent (row) {
                this.$confirm({
                    title: this.$t('platformCollection.delete_user'),
                    content: this.$t('platformCollection.delete_current_user'),
                    onOk: () => {
                        this.loading = true;
                        this.deleteUser({ id: userId.id }).then(res => {
                            this.search(1);
                        }).catch(e => {
                            this.loading = false;
                            this.$message.error(e.message);
                        });
                    }
                });
            },
            submitUser () {
                this.changeModal(false);
                this.loading = true;
                const form = {
                    ...this.basicForm,
                    status: this.basicForm.status ? 1 : 2,
                };
                const requestField = form.userId ? 'editColUser' : 'addColUser';

                this[requestField](form).then(res => {
                    this.loading = false;
                    this.$message.success(this.$t('success'));
                    this.search(1);
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                })
            },
            search (pageNum) {
                if(pageNum) { this.form.pageNum = pageNum; }
                this.loading = true;
                this.getColUserList(this.form).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            },
        }
    }
</script>
