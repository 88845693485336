<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="Banner路径">
          <a-input placeholder="请输入Banner路径" v-decorator="[ 'payBannerUrl', validatorRules.payBannerUrl]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="渠道名称">
          <a-input placeholder="请输入渠道名称" v-decorator="[ 'payChannelName', validatorRules.payChannelName]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="渠道">
          <a-input placeholder="请输入渠道" v-decorator="[ 'payChannel', validatorRules.payChannel]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="排序">
          <a-input placeholder="请输入排序" v-decorator="[ 'sort', validatorRules.sort]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="包ID">
          <a-input placeholder="请输入appSsid" v-decorator="[ 'appSsid', validatorRules.appSsid]" />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="渠道类型">
          <a-input placeholder="请输入渠道类型" v-decorator="[ 'channelType', validatorRules.channelType]" />
        </a-form-item>


        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="跳Web浏览器开关">
          <a-radio-group
            v-decorator="['browserOpen', { initialValue: '0' }]">
            <a-radio value="0">关闭</a-radio>
            <a-radio value="1">开启</a-radio>
          </a-radio-group>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

export default {
  name: "payChannelInfoModal",
  data () {
    return {
      title:"操作",
      visible: false,
      // roleDisabled: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules:{
        payBannerUrl:{
          rules: [
            { required: true, message: '请输入Banner路径!' },
          ]},
        payChannelName:{
          rules: [
            { required: true, message: '请输入渠道名称!' },
          ]},
        payChannel:{
          rules: [
            { required: true, message: '请输入渠道!'},
          ]},
        appSsid:{
          rules: [
            { required: true, message: '请输入appSsid!'},
          ]},
        channelType:{
          rules: [
            { required: true, message: '请输入渠道类型!'},
          ]},
        sort:{
          rules: [
            { required: true, message: '请输入排序!'},
          ]},
      },
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({});
    },
    edit (record) {
      this.form.resetFields();
      this.open();
      this.model = Object.assign({}, record)
      this.model = {
        ...this.model,
        status: this.model.browserOpen != undefined ? '' + this.model.browserOpen : '1',
      }

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'payBannerUrl', 'payChannelName', 'payChannel', 'sort','appSsid','channelType','browserOpen'))
      });

    },
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      console.log(88888)
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            ...values,
          }
          this.$emit('ok', formData);

        }
      })
    },
    handleCancel () {
      this.close()
    },
  }
}
</script>

<style scoped>

</style>