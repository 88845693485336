<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row>
          <a-col :md='8' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.CUSTTRANS + 'requestDate')" :labelCol='{span: 6}'
                         :wrapperCol='{span: 14, offset: 1}'>
              <a-range-picker
                v-model='queryParam.examineDate'
                format='YYYY-MM-DD' />
            </a-form-item>
          </a-col>
          <a-col :md='8' :sm='8'>
            <a-form-item :label="'产品类型'">
              <a-select mode='multiple'
                        v-model='queryParam.appSsids'
              >
                <a-select-option v-for='item in appList' :key='item.appssid'>
                  {{ item.appName }}
                </a-select-option>
              </a-select>

            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
              <a-button type='primary' @click='download' icon='export' style='margin-left: 8px'>{{ $t('exportFile')
                }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='false'
        :loading='loading'
        bordered
        @change='handleTableChange'>
      </a-table>
      <div style='margin-top: 15px; text-align: right;'>
        <v-pagination
          v-if='total'
          :page-num='form.pageNum'
          :page-size='form.pageSize'
          :total='total'
          @change='handleTableChange' />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import moment from 'moment'
import { getApplist } from '@/api/order'
import { getReportDay } from '@/webpublicapi/custTrans'
import exportFile from '@/api/exportFile'

const I18N_KEY = {
  OASYSTEM: 'oaSystem.',
  CUSTTRANS: 'custTrans.'
}
export default {
  name: 'register',
  components: {},
  data() {
    return {
      I18N_KEY,
      visible: false,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      appList: [],
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      oaWorkplaceNames: [],
      // 查询条件
      queryParam: {
        'examineDate': [moment().add('days', -15), moment().add('days', 0)],
        'appSsids': '',
        'type': 2
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('custTrans.requestDate'),
          align: 'left',
          dataIndex: 'statisticDate'
        },
        {
          title: 'APP',
          align: 'left',
          dataIndex: 'appSsid'
        },
        {
          title: this.$t('custTrans.loginSubMobileDistinctNum'),
          align: 'left',
          dataIndex: 'subMobileDistinctNum'
        },
        {
          title: this.$t('custTrans.loginSubMobileNum'),
          align: 'left',
          dataIndex: 'subMobileNum'
        },
        {
          title: this.$t('custTrans.avgSubNum'),
          align: 'left',
          dataIndex: 'avgSubNum'
        },
        {
          title: this.$t('custTrans.loginSubValidMobileDistinctNum'),
          align: 'left',
          dataIndex: 'subValidMobileDistinctNum'
        },
        {
          title: this.$t('custTrans.subValidMobileProportion'),
          align: 'left',
          dataIndex: 'subValidMobileProportion'
        },
        {
          title: this.$t('custTrans.sendOtpNum'),
          align: 'left',
          dataIndex: 'sendOtpNum'
        },
        {
          title: this.$t('custTrans.loginSendOtpMobileDistinctNum'),
          align: 'left',
          dataIndex: 'sendOtpMobileDistinctNum'
        },
        {
          title: this.$t('custTrans.avgSendNumEveryMobile'),
          align: 'left',
          dataIndex: 'avgSendNumEveryMobile'
        },
        {
          title: this.$t('custTrans.loginOperateSucceedNum'),
          align: 'left',
          dataIndex: 'operateSucceedNum'
        },
        {
          title: this.$t('custTrans.loginOperateFailNum'),
          align: 'left',
          dataIndex: 'operateFailNum'
        },
        {
          title: this.$t('custTrans.unLoginNum'),
          align: 'left',
          dataIndex: 'unLoginNum'
        },
        {
          title: this.$t('custTrans.lockOtpNum'),
          align: 'left',
          dataIndex: 'lockOtpNum'
        },
        {
          title: this.$t('custTrans.loginLockOperateNum'),
          align: 'left',
          dataIndex: 'lockOperateNum'
        },
        {
          title: this.$t('custTrans.loginSuccessRateByTime'),
          align: 'left',
          dataIndex: 'successRateByTime'
        },
        {
          title: this.$t('custTrans.loginSuccessRateByMobile'),
          align: 'left',
          dataIndex: 'successRateByMobile'
        },
      ]
    }
  },
  created() {
    getApplist().then(res => {
      this.appList = res.data || []
    })
    this.loadData(1)
  },
  methods: {
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      // this.loading = true
      var params = this.getQueryParams()// 查询条件
      getReportDay(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
          this.dataSource.forEach(item => {
            if (item.id == 0) {
              item.statisticDate = '总计'
            } else {
              item.statisticDate = this.transformTime(item.statisticDate)
            }
            item.transRate = (Math.round(item.transRate * 10000) / 100).toFixed(2) + '%'
            item.avgSubNum = (Math.round(item.avgSubNum * 100) / 100).toFixed(2)
            item.avgSendNumEveryMobile = (Math.round(item.avgSendNumEveryMobile * 100) / 100).toFixed(2)
            item.subValidMobileProportion = (Math.round(item.subValidMobileProportion * 10000) / 100).toFixed(2) + '%'
            item.successRateByTime = (Math.round(item.successRateByTime * 10000) / 100).toFixed(2) + '%'
            item.successRateByMobile = (Math.round(item.successRateByMobile * 10000) / 100).toFixed(2) + '%'
          })
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    getQueryParams() {
      const { examineDate } = this.queryParam
      const data = {
        ...this.queryParam,
        startDate: examineDate && this.transformTime(examineDate[0]),
        endDate: examineDate && this.transformTime(examineDate[1])
      }
      delete data.examineDate
      data.pageNum = this.ipagination.current
      data.pageSize = this.ipagination.pageSize
      return filterObj(data)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.examineDate = [moment().add('days', -15), moment().add('days', -1)]
      that.queryParam.appSsids = ''
      this.loadData()
    },
    download() {
      var params = this.getQueryParams()// 查询条件
      var fileUrl = ''
      if (params.appSsids === undefined) {
        fileUrl = '/api/web/webpublic/reportDay/exportLogin?startDate=' + params.startDate + '&endDate=' + params.endDate
      } else {
        fileUrl = '/api/web/webpublic/reportDay/exportLogin?appSsids=' + params.appSsids + '&startDate=' + params.startDate + '&endDate=' + params.endDate
      }
      const file = {
        fileUrl: fileUrl,
        // fileUrl: '/api/web/webpublic/reportDay/exportRegister?appSsids=' + params.appSsids + '&startDate=' + params.startDate + '&endDate=' + params.endDate,
        fileName: '登录监控_' + params.startDate + '_' + params.endDate + '.xls'
      }
      this.loading = true
      exportFile(file).catch(e => {
        this.$message.error(e.message)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
