import { render, staticRenderFns } from "./CollectionGroupPerformanceLock.vue?vue&type=template&id=77bd4b6c&scoped=true&"
import script from "./CollectionGroupPerformanceLock.vue?vue&type=script&lang=js&"
export * from "./CollectionGroupPerformanceLock.vue?vue&type=script&lang=js&"
import style0 from "./CollectionGroupPerformanceLock.vue?vue&type=style&index=0&id=77bd4b6c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77bd4b6c",
  null
  
)

export default component.exports