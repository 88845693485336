<template>
    <a-modal
        v-model="propsModel.value"
        :title="propsModel.title"
        :maskClosable="false"
        :footer="null"
        width="100%"
        :bodyStyle="bodyStyle"
        :getContainer="getContainer"
        style="top: 0; padding: 0;"
        @afterClose="handleClose"
        @ok="handleConfirm"
        @cancel="handleCancel"
        :okText="$t('confirm')"
        :cancelText="$t('cancel')">
        <slot></slot>
    </a-modal>
</template>
<script>
    export default {
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: undefined,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            getContainer: {
                type: Function,
                default: undefined,
            },
        },
        data () {
            return {
                drawerWidth: 700,
            }
        },
        computed: {
            propsModel () {
                return { ...this.$props};
            },
            bodyStyle () {
                return {
                    padding: 0,
                    width: '100%',
                    height: (this.drawerWidth || 0) + 'px',
                    overflowY: 'auto',
                }
            }
        },
        watch: {
            value (val) {
                this.update(val);
            },
        },
        created () {
            window.addEventListener('resize', this.resetScreenSize);
            this.resetScreenSize();
        },
        methods: {
            handleConfirm (e) {
                this.$emit('ok', e);
                this.handleClose();
            },
            handleCancel (e) {
                this.$emit('cancel', e);
                this.handleClose();
            },
            handleClose () {
                this.$emit('close');
                this.update(false);
            },
            update (value) {
                this.$emit('input', value)
            },
            // 根据屏幕变化,设置抽屉尺寸
            resetScreenSize(){
                const screenHeight = document.body.clientHeight;
                // const footerHeight = this.footer ? 135 : 80;
                const footerHeight = 55;
                this.drawerWidth = screenHeight - footerHeight;
            },
        }
    }
</script>
<style lang="less" scoped>
</style>
