<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'orderInfoId')" >
              <a-input
                :placeholder="$t('enter') + ' '+$t('collection.orderInfoId')"
                v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'operationUserMobile')" >
              <a-input
                :placeholder="$t('enter') + ' '+$t('collection.operationUserMobile')"
                v-model="queryParam.operationUserMobile"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'createTime')">
              <a-range-picker
                v-model="queryParam.createTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="search" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :pagination="ipagination"
        :loading='loading'
        bordered
        @change="handleTableChange">
      </a-table>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import moment from 'moment'
import { getListByParams } from '@/webpublicapi/collection'
import { getApplist } from '@/api/order'
import exportFile from '@/webpublicapi/exportFile'
import { getAllSysUser } from '@/webpublicapi/system'

const I18N_KEY = {
  COLLECTION: 'collection.',
  ORDER: 'order.',
}
export default {
  name: 'CollectionRecord',
  components: {},
  data() {
    return {
      I18N_KEY,
      visible: false,
      appList: [],
      sysUserList : [],
      total: 0,
      dataSource: [],
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      oaWorkplaceNames: [],
      createDateStart: undefined,
      createDateEnd: undefined,
      // 查询条件
      queryParam: {
        'orderNo': undefined,
        'operationUserMobile': undefined,
        'createTime': [moment().add('days', -15), moment().add('days', 0)],
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('collection.operationUserMobile'),
          align: 'left',
          dataIndex: 'operationUserMobile'
        },
        {
          title: this.$t('collection.userName'),
          align: 'left',
          dataIndex: 'userName'
        },
        {
          title: this.$t('order.overdueDay'),
          align: 'left',
          dataIndex: 'overdueDay'
        },
        {
          title: this.$t('collection.followNum'),
          align: 'left',
          dataIndex: 'followNum'
        },
        {
          title: this.$t('collection.repaymentIntention'),
          align: 'left',
          dataIndex: 'repaymentIntention'
        },
        {
          title: this.$t('collection.followResult'),
          align: 'left',
          dataIndex: 'followResult'
        },
        {
          title: this.$t('collection.followTime'),
          align: 'left',
          dataIndex: 'followTimeStr'
        },
        {
          title: this.$t('collection.collectionTaskStatus'),
          align: 'left',
          dataIndex: 'collectionTaskStatus'
        },
        {
          title: 'APP',
          align: 'left',
          dataIndex: 'appSsid'
        },
        {
          title: this.$t('collection.createTime'),
          align: 'left',
          dataIndex: 'createTimeStr'
        },
        {
          title: this.$t('collection.linkCondition'),
          align: 'left',
          dataIndex: 'contactsSituation'
        },
        {
          title: this.$t('remark'),
          align: 'left',
          dataIndex: 'remark'
        },
      ]
    }
  },
  created() {
    this.loadData()
    getApplist().then(res => {
      this.appList = res.data || [];
    })
    getAllSysUser().then(res => {
      this.sysUserList = res.data || [];
    })
  },
  methods: {
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getListByParams(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.ipagination.total = res.data.total
          this.dataSource = res.data.list
          if (this.dataSource.length > 0) {
            this.dataSource.forEach(item => {
              this.appList.forEach(app => {
                if (app.appssid === item.appSsid) {
                  item.appSsid = app.appName
                }
              })
              this.sysUserList.forEach(user => {
                if (user.id === item.userId) {
                  item.operationUserMobile = user.mobile
                }
              })
              // 0 待处理 1 待跟进 2 已完成
              if (item.collectionTaskStatus == 0) {
                item.collectionTaskStatus = '待处理'
              } else if (item.collectionTaskStatus == 1) {
                item.collectionTaskStatus = '待跟进'
              } else if (item.collectionTaskStatus == 2) {
                item.collectionTaskStatus = '已完成'
              }
              switch (item.followResult) {
                case 0:
                  item.followResult = this.$t('collection.waitingForCollection')
                  break
                case 1:
                  item.followResult = this.$t('collection.answeredByThemSelf')
                  break
                case 2:
                  item.followResult = this.$t('collection.notInPerson')
                  break
                case 3:
                  item.followResult = this.$t('collection.noAnswer')
                  break
                case 4:
                  item.followResult = this.$t('collection.emptyNumber')
                  break
                case 5:
                  item.followResult = this.$t('collection.turnedOff')
                  break
                case 6:
                  item.followResult = this.$t('collection.unavailable')
                  break
                case 7:
                  item.followResult = this.$t('collection.whatsappPromisesToPayBackOnTime')
                  break
                case 8:
                  item.followResult = this.$t('collection.WhatsappDidNotReply')
                  break
                case 9:
                  item.followResult = this.$t('collection.NoTieneCuentaDeWhatsapp')
                  break
              }
              switch (item.repaymentIntention) {
                case 0:
                  item.repaymentIntention = this.$t('collection.cantJudgeItForNow')
                  break
                case 1:
                  item.repaymentIntention = this.$t('collection.haveAWishAndCanReturnTheLoan')
                  break
                case 2:
                  item.repaymentIntention = this.$t('collection.haveAWishButCantReturnTheLoan')
                  break
                case 3:
                  item.repaymentIntention = this.$t('collection.canReturnTheLoanButRefuseToPayIt')
                  break
                case 4:
                  item.repaymentIntention = this.$t('collection.canReturnTheLoanButWantToConsult')
                  break
              }
              switch (item.contactsSituation) {
                case 1:
                  item.contactsSituation = this.$t('collection.LaPersonaDeContactoPuedeComunicarleAlClienteParaElReembolso')
                  break
                case 2:
                  item.contactsSituation = this.$t('collection.ElContactoNoPuedeComunicarse')
                  break
                case 3:
                  item.contactsSituation = this.$t('collection.LaInformaciónDelContactoEsFalsa')
                  break
              }
            })
          }
          this.loading = false
        } else {
          this.ipagination.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
          this.loading = false
        }
      }).catch(e => {
        this.$message.error(e.message)
        this.loading = false
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    transformDate(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    getQueryParams() {
      const { createTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        createDateStart: (createTime && createTime.length) ? this.transformDate(createTime[0]) : '',
        createDateEnd : (createTime && createTime.length) ? this.transformDate(createTime[1]) : '',
      }
      this.createDateStart = data.createDateStart
      this.createDateEnd = data.createDateEnd
      data.pageNum = this.ipagination.current
      data.pageSize = this.ipagination.pageSize
      delete data.createTime;
      return filterObj(data)
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      this.queryParam.orderNo = undefined
      this.queryParam.operationUserMobile = undefined
      this.queryParam.createTime = [moment().add('days', -15), moment().add('days', 0)]
      this.dataSource = []
      this.loading = false
    },
    downLoadData () {
      const file = {
        fileUrl: '/api/web/webpublic/collectionLog/exportByParams?createDateStart=' + this.createDateStart + '&createDateEnd=' + this.createDateEnd,
        fileName: '催收催记' + '.xls',
      }
      if (this.queryParam.operationUserMobile != undefined) {
        file.fileUrl = file.fileUrl + '&operationUserMobile=' + this.queryParam.operationUserMobile
      }
      if (this.queryParam.orderNo != undefined) {
        file.fileUrl = file.fileUrl + '&orderNo=' + this.queryParam.orderNo
      }
      console.log(file)
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    handleTableChange (pagination, filters, sorter) {
      this.ipagination = pagination
      this.loadData()
    },
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
