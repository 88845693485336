<template>
  <a-modal :title="edit?'修改':'新增'" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @ok="submit()" @cancel="hide">

    <a-row v-if="edit === true">
      <a-col>
        是否开启: <a-switch :checked="model.isEnable == 1" @change="model.isEnable = model.isEnable == 1 ? 0:1"></a-switch>
      </a-col>
      <a-col>
        任务限制数量: <a-input-number v-model="model.limitNum" ></a-input-number>
      </a-col>
    </a-row>

    <a-row v-if="edit === false">
      <a-col>
        催收级别: <a-input-number v-model="model.normalConfigId" ></a-input-number>
      </a-col>
      <a-col>
        是否开启: <a-switch v-model="model.isEnable"></a-switch>
      </a-col>
      <a-col>
        任务限制数量: <a-input-number v-model="model.limitNum" ></a-input-number>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
  import {saveAndUpdateTaskLimitConfig} from '@/webpublicapi/taskLimitConfig'
  export default {
    name: 'AddOrUpdateModal',
    data() {
      return {
        edit:false,
        modal: {
          visible: false,
          confirmLoading: false,
        },
        form: this.$form.createForm(this),
        model: {
          isEnable:0,
          limitNum:'',
          normalConfigId:''
        },
      }
    },
    methods: {
      show(value,edit) {
        this.edit = edit;
        this.model = {...value}
        this.modal.visible = true
      },
      hide() {
        this.modal.visible = false
        this.model = {normalConfigId: undefined}
      },
      submit() {
        saveAndUpdateTaskLimitConfig({normalConfigId:this.model.normalConfigId,isEnable:this.model.isEnable==1?1:0,limitNum:this.model.limitNum}).then(res => {
          this.loading = false;
          this.total = res && res.data && res.data.total;
          this.hide()
          this.$emit("refresh")
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.message);
        });
      }
    }
  }
</script>