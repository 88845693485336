<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.name_of_collector')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t('platformCollection.name_of_collector'))" v-model="queryParam.userName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="10">
            <a-form-item :label="'日期'">
              <a-range-picker
                v-model="queryParam.decisionTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.department')">
              <a-select
                v-model="queryParam.departmentId"
                @change="onChangeOne"
              >
                <a-select-option v-for="item in departmentList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.grouping')">
              <a-select
                v-model="queryParam.groupId"
              >
                <a-select-option v-for="item in groupList" :key="item.id">
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!--<a-col :md="10" :sm="10">-->
             <!--<a-form-item :label="$t(I18N_KEY.COLLECTION + 'endTime')">-->
                <!--<a-range-picker-->
                   <!--v-model="queryParam.endTime"-->
                   <!--format="YYYY-MM-DD"-->
                   <!--style="width: 100%;"/>-->
             <!--</a-form-item>-->
          <!--</a-col>-->
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
               <!--<a @click="handleToggleSearch" style="margin-left: 8px">-->
                     <!--{{ $t(toggleSearchStatus ? 'hide' : 'show') }}-->
                    <!--<a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>-->
                  <!--</a>-->
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        size="middle"
        rowKey="taskId"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <Dict slot="followResult" slot-scope="text" :dict-code="DICT_CODE.FOLLOW_RESULT" :value="text"/>
        <Dict slot="repaymentIntention" slot-scope="text" :dict-code="DICT_CODE.REAPYMENT_INTENTION" :value="text"/>
        <Dict slot="collectionTaskStatus" slot-scope="text" :dict-code="DICT_CODE.COLLECTION_TASK_STATUS" :value="text"/>

        <span slot="action" slot-scope="text, record">
          <!-- slot-scope="text, record" -->
            <a @click="handleDetail(record)">{{ $t('view') }}</a>
              <a-divider type="vertical"/>
            <a v-if="record.collectionTaskStatus == '0'||record.collectionTaskStatus == '1'" @click="handleUser(record)">{{ $t(I18N_KEY.COLLECTION + 'transferOrder') }}</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { findCollectionModelList} from '@/webpublicapi/collection'
import { getSysGroup } from '@/webpublicapi/platformCollection'
import { getCollectionListDepartment } from '@/webpublicapi/group'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import CollectionDetail from './CollectionDetail';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex'

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.'
}

export default {
  name: 'AllApprovalTask',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      confirmLoading: false,
      visible: false,
      visibleT: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      batchVisible: false,
      batchLoading: false,
      selectedRowKeys: [],
      selectedList: [],
      groupList: [],
      departmentList: [],
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "userName":"",
        "groupId":""
        // "groupId":"",
        // endTime: undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      selectUser: '',
      ExamineUser: [],
      taskId: ''
    }
  },
  computed: {
    ...mapGetters('collection',['listUserWithNum']),
    batchList () {
      return this.listUserWithNum || [];
    },
    columns () {
      return [
        {
          title: '催收员',
          align: 'left',
          dataIndex: 'userName'
        },
        {
          title: '催收分组',
          align: 'left',
          dataIndex: 'groupName'
        },
        {
          title: '分析时间',
          align: 'left',
          dataIndex: 'decisionTime'
        },
        {
          title: '跟进中任务数',
          align: 'left',
          dataIndex: 'taskSize'
        },
        {
          title: '当日呼出通话总次数',
          align: 'left',
          dataIndex: 'callTime'
        },
        {
          title: '当日呼出有效通话总次数',
          align: 'left',
          dataIndex: 'callValidTime'
        },
        {
          title: '当日呼出通话总时长',
          align: 'left',
          dataIndex: 'callDuration'
        },
        {
          title: '客户本人通话总次数',
          align: 'left',
          dataIndex: 'custCallTime'
        },
        {
          title: '客户本人通话总时长',
          align: 'left',
          dataIndex: 'custCallDuration'
        },
        {
          title: '客户联系人A通话总次数',
          align: 'left',
          dataIndex: 'custCallTimeLinka'
        },
        {
          title: '客户联系人B通话总次数',
          align: 'left',
          dataIndex: 'custCallTimeLinkb'
        },
        {
          title: '客户联系人A通话总时长',
          align: 'left',
          dataIndex: 'custCallDurationLinka'
        },
        {
          title: '客户联系人B通话总时长',
          align: 'left',
          dataIndex: 'custCallDurationLinkb'
        }
      ]
    },
    batchColumns () {
      return [
        {
          title: this.$t('name'),
          align: 'left',
          dataIndex: 'userName'
        },
        {
          title: this.$t('order.followingUpOrder'),
          align: 'left',
          dataIndex: 'undoNum'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    this.getDict();
    this.loadData(1);
    getSysGroup().then(res => {
      this.groupList = res.data || [];
    });
    getCollectionListDepartment().then(res => {
      this.departmentList = res.data || [];
    });
  },
  methods: {
    ...mapActions('collection', ['getListUserWithNum', 'batchChangeUser']),
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 清空选中项
      this.selectedList = [];
      this.selectedRowKeys = [];
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      findCollectionModelList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    selectRowsChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.originSelectedList = selectedRows;
      this.selectedList = _.cloneDeep(selectedRows);
    },
    openBatchDialog () {
      this.batchVisible = true;
      this.batchLoading = true;
      this.selectedList.length && this.getListUserWithNum().finally(res => {
        this.batchLoading = false;
      })
    },
    closeBatchDialog () {
      this.batchVisible = false;
      this.selectedList = _.cloneDeep(this.originSelectedList || []);
    },
    submitBatch () {
      const taskIds = this.selectedList.map(t => t.taskId);
      const userTask = this.batchList.map(t => ({ userId: t.userId, taskNum: t.taskNum ? +t.taskNum : 0 }));
      const totalNum = userTask.map(t => t.taskNum).reduce((total, num ) => (total += num), 0);
      console.log(userTask, totalNum);
      if (!userTask.length) {
        return this.closeBatchDialog();
      }
      if (isNaN(totalNum)) {
        return this.$message.error(this.$t('please_enter_a_positive_integer'));
      }
      if (totalNum > this.selectedList.length) {
        return this.$message.error(this.$t('platformCollection.cannot_be_greater_than_the_total_quantity_of_the_checked_order'));
      }
      this.closeBatchDialog();
      this.loading = true;
      this.batchChangeUser({ taskIds, userTask }).then(res => {
        this.loadData();
        this.$message.success(this.$t('success'));
      }).finally(res => {
        this.loading = false;
      });
    },
    getQueryParams () {
      const { decisionTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          decisionTimeStart: (decisionTime && decisionTime.length) ? this.transformTime(decisionTime[0]) : undefined,
          decisionTimeEnd : (decisionTime && decisionTime.length) ? this.transformTime(decisionTime[1]) : undefined,
      }
      delete data.decisionTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.userName = '';
      that.queryParam.departmentId = '';
      that.queryParam.groupId = '';
      that.queryParam.decisionTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.orderId;
        this.custInfoId = row.custInfoId;
        this.taskId = row.taskId;
        this.changeModal(true);
    },
    handleUser(row){
      this.selectUser = ''
      this.taskId = row.taskId
      this.confirmLoading = true;
      if(this.ExamineUser.length ==0){
        getCollectionUser().then((res)=>{
          if(res.code===1000){
            this.ExamineUser = res.data
          }else{
            this.ExamineUser = []
            this.$message.error(res.msg)
          }
        this.confirmLoading = false;
        }).catch(e => {
            this.confirmLoading = false;
            this.$message.error(e.message);
        })
      }else{
        this.confirmLoading = false;
      }
      this.visibleT = true
    },
    onChange(e) {
      this.selectUser = e.target.value
    },
    handleOk(e) {
       if(this.selectUser!=''){
        this.confirmLoading = true;
        modCollectionTaskUser({userId:this.selectUser,taskId:this.taskId}).then((res)=>{
          if(res.code===1000){
            this.visibleT = false;
            this.confirmLoading = false;
            this.loadData();
          }else{
            this.confirmLoading = false;
            this.$message.error(res.msg)
          }
        }).catch(e => {
           this.confirmLoading = false;
            this.$message.error(e.message);
      })
       }else{
         this.$message.error(this.$t('platformCollection.please_select_a_credit_examiner'))
       }
      },
      handleCancel(e) {
        this.visibleT = false;
      },
      onChangeOne(value) {
        console.log(value);
        getSysGroup({"departmentId": value}).then(res => {
          this.groupList = res.data || [];
          this.queryParam.groupId = "";
        });
      }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
 .description {
  line-height: 1.4;
  &.d_text {
    text-indent: 1em;
  }
  &.d_high_light {
    color: #fd9a38;
  }
 }
</style>
