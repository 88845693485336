<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="期限">
          <a-input placeholder="请输入期限" v-decorator="[ 'duration', validatorRules.duration]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="最大金额">
          <a-input placeholder="请输入最大金额" v-decorator="[ 'maxAmount', validatorRules.maxAmount]"/>
        </a-form-item>

         <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="最小金额">
          <a-input placeholder="请输入最小金额" v-decorator="[ 'minAmount', validatorRules.minAmount]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="递增金额">
          <a-input placeholder="请输入递增金额" v-decorator="[ 'incrAmount', validatorRules.incrAmount]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="增长额度">
          <a-input placeholder="请输入增长额度" v-decorator="[ 'incrQuota', validatorRules.incrQuota]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="允许展期时间">
          <dict-radio-group
              v-decorator="['allowDiferTime', validatorRules.allowDiferTime]"
              :dict-code="DICT_CODE.ALLOW_DIFER_TIME"
          />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="是否允许提前还款">
            <a-switch checkedChildren="允许" unCheckedChildren="不允许" v-decorator="[ 'allowPrepay', { initialValue: false, valuePropName: 'checked' }]"/>
        </a-form-item>

         <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="服务费率">
          <a-input placeholder="请输入服务费率" v-decorator="[ 'serviceFeeRate', validatorRules.serviceFeeRate]"/>
        </a-form-item>

         <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="展期服务费">
          <a-input placeholder="请输入展期服务费" v-decorator="[ 'diferFeeRate', validatorRules.diferFeeRate]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="逾期费率">
          <a-input placeholder="请输入逾期费率" v-decorator="[ 'overdueRate', validatorRules.overdueRate]"/>
        </a-form-item>


      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { addProductItem, editProductItem } from '@/api/operation'
import DictRadioGroup from '../../modules/DictRadioGroup';
// duplicateCheck
export default {
  name: 'DictModal',
  components:{
    DictRadioGroup
  },
  data () {
    return {
      value: 1,
      title: '操作',
      allowPrepay:1,
      productId:'',
      visibleCheck: true,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        duration: { rules: [{ required: true, message: '请输入期限!' }] },
        maxAmount: {
          rules: [{ required: true, message: '请输入最大金额!' }]
        },
        minAmount: { rules: [{ required: true, message: '请输入最小金额!' }] },
        incrAmount: { rules: [{ required: true, message: '请输入递增金额!' }] },
        incrQuota: { rules: [{ required: true, message: '请输入增长额度!' }] },
        allowDiferTime: { rules: [{ required: true, message: '请输入允许展期时间!' }] },
        // allowPrepay: { rules: [{ required: true, message: '请输入是否允许提前还款!' }] },
        serviceFeeRate: { rules: [{ required: true, message: '请输入服务费率!' }] },
        diferFeeRate: { rules: [{ required: true, message: '请输入展期服务费!' }] },
        overdueRate: { rules: [{ required: true, message: '请输入逾期费率!' }] }
      }
      
    }
  },
  created () {
  },
  methods: {
    add (proId,ssId) {
      this.productId = proId
      this.appSsid=ssId
      this.edit({})
    },
    edit (record) {
      this.form.resetFields()
      this.model = Object.assign({}, record)
      if (record.id) {
        this.allowPrepay = record.allowPrepay
        this.visibleCheck = (record.allowPrepay == 1)
      }
      this.visible = true;
      const data = pick(this.model, 'duration', 'maxAmount', 'minAmount','incrAmount','incrQuota','allowDiferTime', 'allowPrepay', 'serviceFeeRate','diferFeeRate','overdueRate');
      data.allowPrepay = !!data.allowPrepay;
      this.$nextTick(() => {
        this.form.setFieldsValue(data);
      })
    },
    onChose (checked) {
      if (checked) {
        this.allowPrepay = 1
        this.visibleCheck = true
      } else {
        this.allowPrepay = 0
        this.visibleCheck = false
      }
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          formData.allowPrepay = +formData.allowPrepay ? 1 : 0;
          let obj
          if (!that.model.id) {
            formData.productId = this.productId
            formData.appSsid=this.appSsid
            obj = addProductItem(formData)
          } else {
             obj = editProductItem(formData)
          }
          obj.then((res) => {
            if (res.code === 1000) {
              that.$message.success(res.msg)
              that.$emit('ok')
            } else {
              that.$message.warning(res.msg)
            }
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
