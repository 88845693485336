import api from './index'
import { axios } from '@/utils/request'

export function login (parameter) {
  return axios({
    url: '/login',
    method: 'post',
    data: parameter
  })
}

export function logout () {
  return axios({
    url: '/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function modifyPassword (payload = {}) {
  return axios({
    url: '/user/modifyPwd',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: payload
  })
}

export function getPermissionsRoutes (parameter = {}) {
  return axios({
    url: '/user/routes',
    method: 'post',
    data: parameter
  })
}
