<template>
  <a-card :bordered="false">
    <!-- 抽屉 -->
    <a-drawer
      title="策略集列表"
      :width="screenWidth"
      @close="onClose"
      :visible="visible"
    >
      <!-- 抽屉内容的border -->
      <div
        :style="{
          padding:'10px',
          border: '1px solid #e9e9e9',
          background: '#fff',
        }">

        <div class="table-page-search-wrapper">
          <a-form layout="inline" :form="form" @keyup.enter.native="searchQuery">
            <a-row :gutter="10">
              <a-col :md="8" :sm="12">
                <a-form-item label="名称">
                  <a-input style="width: 120px;" placeholder="请输入名称" v-model="queryParam.itemText"></a-input>
                </a-form-item>
              </a-col>
              <a-col :md="9" :sm="24">
                <a-form-item :label="$t('platformCollection.state')" style="width: 170px" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-select
                    placeholder="请选择"
                    v-model="queryParam.status"
                  >
                    <a-select-option value="1">正常</a-select-option>
                    <a-select-option value="0">禁用</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="7" :sm="24">
                <span style="float: left;" class="table-page-search-submitButtons">
                  <a-button type="primary" @click="searchQuery">搜索</a-button>
                  <a-button type="primary" @click="searchReset" style="margin-left: 8px">重置</a-button>
                </span>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="2" :sm="24">
                <a-button style="margin-bottom: 10px" type="primary" @click="handleAdd">新增</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div>
          <a-table
            ref="table"
            rowKey="id"
            size="middle"
            :columns="columns"
            :dataSource="dataSource"
            :pagination="ipagination"
            :loading="loading"
            bordered
            @change="handleTableChange"
          >

            <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
              <a-divider type="vertical"/>
              <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                <a>删除</a>
              </a-popconfirm>
            </span>

          </a-table>
        </div>
      </div>
    </a-drawer>
    <AppStrategyidAdd ref="modalForm" @ok="modalFormOk"></AppStrategyidAdd>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import { getDictItem, deleteDictItem, getAppStrategyidList, addAppStrategyid} from '@/api/system'
import { filterObj } from '@/utils/util'
import AppStrategyidAdd from './AppStrategyidAdd'

export default {
  name: 'DictItemList',
  components: { AppStrategyidAdd },
  data () {
    return {
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      dataSource: [],
      loading: false,
      columns: [
        {
          title: '策略ID',
          align: 'center',
          dataIndex: 'strategyid'
        },
        {
          title: '分配比例',
          align: 'center',
          dataIndex: 'scale'
        },
        {
          title: '类型',
          align: 'center',
          dataIndex: 'type'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      queryParam: {
        appSsid:'',
        dictId: '',
        dictName: '',
        itemText: '',
        status: "1"
      },
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      title: '操作',
      visible: false,
      screenWidth: 800,
      model: {},
      dictId: '',
      appSsid:'',
      status: '1',
      labelCol: {
        xs: { span: 5 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 }
      },
      form: this.$form.createForm(this),
      validatorRules: {
        itemText: { rules: [{ required: true, message: '请输入名称!' }] },
        itemValue: { rules: [{ required: true, message: '请输入数据值!' }] }
      }
    }
  },
  created () {
    // 当页面初始化时,根据屏幕大小来给抽屉设置宽度
    this.resetScreenSize()
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.dictName = ''
      that.queryParam.dictCode = ''
      that.loadData(this.ipagination.current)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
       this.loading = true
       var params = this.getQueryParams()// 查询条件
      getAppStrategyidList(Object.assign({},params)).then((res)=>{
        if(res.code===1000){
          this.ipagination.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.ipagination.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    handleTableChange (pagination, filters, sorter) {
      // TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    add (dictId) {
      this.dictId = dictId
      this.edit({})
    },
    edit (record) {
      console.dir(record)
      if (record.id) {
        this.appSsid = record.appSsid
      }
      this.queryParam = {}
      this.form.resetFields()
      this.model = Object.assign({}, record)
      // this.model.dictId = this.dictId
      this.queryParam.appSsid = this.appSsid
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model))
      })
      // 当其它模块调用该模块时,调用此方法加载字典数据
      this.loadData()
    },

    getQueryParams () {
      var param = Object.assign({}, this.queryParam)
      param.pageNum = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },

    // 添加字典数据
    handleAdd () {
      this.$refs.modalForm.add(this.dictId)
      this.$refs.modalForm.title = '新增'
    },
    handleEdit (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleDelete(id){
      deleteDictItem({id}).then((res) => {
        if (res.code === 1000) {
          that.$message.success(res.msg)
          this.loadData()
        } else {
          that.$message.warning(res.msg)
        }
      })
    },
    showDrawer () {
      this.visible = true
    },
    onClose () {
      this.visible = false
      this.form.resetFields()
      this.dataSource = []
    },
    // 抽屉的宽度随着屏幕大小来改变
    resetScreenSize () {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 600) {
        this.screenWidth = screenWidth
      } else {
        this.screenWidth = 600
      }
    },
    // model回调
    modalFormOk () {
      this.loadData()
    }
  }
}
</script>
<style scoped>

</style>
