<template>
    <div class="approvalDetail" style="position: relative;">
        <a-spin :spinning="loading">
                <a-card
                    style="margin-top: 24px"
                    :title="$t(I18N_KEY.CUSTOMER + 'customerImageInfo')"
                    type="inner"
                    v-viewer="viewOptions">
                    <div v-for="(item, index) in imageInfos" :key="item.imgType">
                        <div class="file-box">
                            <div class="file-item">
                                <p class="file-label">{{ FILE_TYPE[item.imgType] }}</p>
                                <img class="file-preview" :src="item.imgUrl" preview="0">
                            </div>
                        </div>
                    </div>
                </a-card>
          <a-card
               :title="$t(I18N_KEY.ORDER + 'orderInfo')"
                type="inner"
                style="margin-top: 24px;">
                <detail-list>
                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'orderNo')" >{{ orderInfo.orderNo }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'applicationAmount')">{{ orderInfo.applyAmt && orderInfo.applyAmt + 'RS'}}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'applicationDeadline')">{{ orderInfo.applyDuration && orderInfo.applyDuration + 'Days' }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'AadhaarName')">{{ basicInfo.aadhaarName }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'AadhaarNo')">{{ orderInfo.aadhaarNo }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'mobile')">{{ orderInfo.mobileNo }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'panName')">{{ basicInfo.panName }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'panNo')">{{ basicInfo.panNo }}</detail-list-item>
                    <detail-list-item :term="$t('sex')">{{ $t(SEX[basicInfo.sex]) }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankAccountName')">{{ bankCardInfo.bankAccountName }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankAccountNo')">{{ bankCardInfo.bankAccountNo }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'ifscCode')">{{ bankCardInfo.ifscCode }}</detail-list-item>
                    <detail-list-item :term="$t('email')">{{ basicInfo.email }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'createTime')">{{ orderInfo.submitTime }}</detail-list-item>
                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'loanNumber')" >{{ orderInfo.loanCount }}</detail-list-item>
                </detail-list>
            </a-card>
            <a-card
               :title="$t(I18N_KEY.ORDER + 'examineCustomerInfo')"
                type="inner"
                style="margin-top: 24px;">
                <detail-list>
                      <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'mobile')">{{ basicInfo.mobileNo }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'age')">{{ basicInfo.age }}</detail-list-item>
                      <detail-list-item :term="$t('birthday')">{{ basicInfo.birthday }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'AadhaarName')">{{ basicInfo.aadhaarName }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'panName')">{{ basicInfo.panName }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankAccountName')">{{ basicInfo.panName }}</detail-list-item>
                      <!--<detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'epochScore')">{{ basicInfo.epochScore }}</detail-list-item>-->
                      <!--<detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'modelScore')">{{ basicInfo.modelScore }}</detail-list-item>-->
                      <detail-list-item :term="$t(I18N_KEY.ORDER + 'sxCreditScore')">{{ orderInfo.sxCreditScore }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY.ORDER + 'sxCheatScore')">{{ orderInfo.sxCheatSccore }}</detail-list-item>
                </detail-list>
            </a-card>
            <a-card
                :title="$t(I18N_KEY.ORDER + 'examineAddressInfo')"
                type="inner"
                style="margin-top: 24px;">
                <detail-list>
                      <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'residentialAddress')">{{ detailInfo.residentialAddress }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'workingAddress')">{{ detailInfo.workAddress }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY.ORDER + 'location')">{{ orderInfo.lbs }}</detail-list-item>
                </detail-list>
                <a-card
                    style="margin-top: 24px"
                    :title="$t(I18N_KEY.CUSTOMER + 'customerLinkInfo')"
                    type="inner">
                    <a-table
                        :columns="linkColumns"
                        :dataSource="linkmanInfos"
                        :pagination="false"
                        bordered
                        ref="table"
                        size="middle"
                        :rowKey="(record) => record.index">
                        <span slot="contactType" slot-scope="text" :value="text" >{{ $t(CONTACT_TYPE[text]) }}</span>
                        <Dict slot="relationType" slot-scope="text" :dict-code="DICT_CODE.REALTIONSHIP" :value="text" />
                    </a-table>
                </a-card>
                <a-card
                    type="inner">
                         <a-tabs v-model="currentTab">
                             <a-tab-pane :tab="$t(I18N_KEY.ORDER + 'approvalRecord')" key="1" forceRender>
                                <a-table
                                    :columns="examineColumns"
                                    :dataSource="examineSource"
                                    :pagination="false"
                                    bordered
                                    ref="table"
                                    size="middle">
                                    <Dict slot="approveTaskStatus" slot-scope="text" :dict-code="DICT_CODE.APPROVAL_TASK_STATUS" :value="text" />
                                    <Dict slot="approveStatus" slot-scope="text" :dict-code="DICT_CODE.APPROVAL_STATUS" :value="text" />
                                </a-table>
                            </a-tab-pane>
                         </a-tabs>
                </a-card>
            </a-card>
        </a-spin>
        <div
            v-if="auditDrawer && !loading"
            :class="{ 'setting-drawer-handle': true, active: visible }" @click="handelDrawer(!visible)">
            <a-icon type="double-left" v-if="!visible"/>
            <a-icon type="double-right" v-if="visible"/>
        </div>
        <a-drawer
            v-if="auditDrawer"
            width="600"
            placement="right"
            title="审批"
            :visible="visible"
            :maskClosable="false"
            :getContainer="drawContainer"
            :wrapStyle="{height: 'calc(100% - 20px)',overflow: 'auto',paddingBottom: '20px'}"
            @close="closeDrawer"
        >
        <div class="audit-form">
            <a-form :form="basicForm" layout="vertical">
                
                <a-form-item :label="$t(I18N_KEY.APPROVAL + 'q1')">
                    <a-radio-group
                        v-decorator="['Q_cust_phone_get', { initialValue: '1' }]">
                        <a-radio value="1">{{$t(I18N_KEY.APPROVAL + 'yes')}}</a-radio>
                        <a-radio value="2">{{$t(I18N_KEY.APPROVAL + 'noresponse')}}</a-radio>
                        <a-radio value="3">{{$t(I18N_KEY.APPROVAL + 'invalidnumber')}}</a-radio>
                        <a-radio value="4">{{$t(I18N_KEY.APPROVAL + 'switchoff')}}</a-radio>
                        <a-radio value="0">{{$t(I18N_KEY.APPROVAL + 'reject')}}</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY.APPROVAL + 'q2')">
                    <a-radio-group
                        v-decorator="['Q_cust_info_confirm', { initialValue: '1' }]">
                        <a-radio value="1">{{$t(I18N_KEY.APPROVAL + 'yes')}}</a-radio>
                        <a-radio value="0">{{$t(I18N_KEY.APPROVAL + 'no')}}</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item  :label="$t(I18N_KEY.APPROVAL + 'q3')">
                    <a-radio-group
                        v-decorator="['Q_cust_phone_read', { initialValue: '1' }]">
                        <a-radio value="1">{{$t(I18N_KEY.APPROVAL + 'yes')}}</a-radio>
                        <a-radio value="0">{{$t(I18N_KEY.APPROVAL + 'no')}}</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item  :label="$t(I18N_KEY.APPROVAL + 'q4')">
                    <a-radio-group
                        v-decorator="['Q_cust_Birth_read', { initialValue: '1' }]">
                        <a-radio value="1">{{$t(I18N_KEY.APPROVAL + 'yes')}}</a-radio>
                        <a-radio value="0">{{$t(I18N_KEY.APPROVAL + 'no')}}</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item  :label="$t(I18N_KEY.APPROVAL + 'q5')">
                    <a-radio-group
                        v-decorator="['Q_cust_duration_amt_question', { initialValue: '1' }]">
                        <a-radio value="1">{{$t(I18N_KEY.APPROVAL + 'yes')}}</a-radio>
                        <a-radio value="0">{{$t(I18N_KEY.APPROVAL + 'no')}}</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY.APPROVAL + 'q6')">
                    <a-radio-group
                        v-decorator="['Q_contact_phone_read', { initialValue: '1' }]">
                        <a-radio value="1">{{$t(I18N_KEY.APPROVAL + 'yes')}}</a-radio>
                        <a-radio value="2">{{$t(I18N_KEY.APPROVAL + 'noresponse')}}</a-radio>
                        <a-radio value="3">{{$t(I18N_KEY.APPROVAL + 'invalidnumber')}}</a-radio>
                        <a-radio value="4">{{$t(I18N_KEY.APPROVAL + 'switchoff')}}</a-radio>
                        <a-radio value="0">{{$t(I18N_KEY.APPROVAL + 'reject')}}</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY.APPROVAL + 'q7')">
                    <a-radio-group
                        v-decorator="['Q_cust_contact_know', { initialValue: '1' }]">
                        <a-radio value="1">{{$t(I18N_KEY.APPROVAL + 'yes')}}</a-radio>
                        <a-radio value="0">{{$t(I18N_KEY.APPROVAL + 'no')}}</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY.ORDER + 'approvalRemark')">
                  <a-textarea
                    v-decorator="['remark']"
                    :rows="4"/>
                </a-form-item>
            </a-form>
        </div>
        <div class="footer">
            <a-button style="marginRight: 8px" @click="closeDrawer">
              {{ $t('cancel') }}
            </a-button>
            <a-button :loading="confirmLoading" @click="submitAuditForm" type="primary">
              {{ $t('confirm') }}
            </a-button>
          </div>
        </a-drawer>
    </div>
</template>
<script>
    import { DetailList } from '@/components';
    import { mapActions, mapGetters } from 'vuex';
    import { REBORROW_FLAG, SEX, CONTACT_TYPE } from '../../constants/status';
    import Dict from '../modules/Dict';
    import imageMixin from '../../mixins/image-mixin';
    const DetailListItem = DetailList.Item
    const I18N_KEY = {
        CUSTOMER: 'customer.',
        ORDER: 'order.',
        APPROVAL:"approval.",
    }

    export default {
        props: {
            orderId: {
                type: [String, Number],
                default: undefined,
            },
            custInfoId: {
                type: [String, Number],
                default: undefined,
            },
            taskId: {
                type: [String, Number],
                default: undefined,
            },
            auditDrawer: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            DetailList,
            DetailListItem,
            Dict,
        },
        mixins: [imageMixin],
        data () {
            return {
                I18N_KEY,
                REBORROW_FLAG,
                SEX,
                CONTACT_TYPE,
                visible: false,
                loading: true,
                confirmLoading: false,
                basicForm: this.$form.createForm(this),
                listItem: {},
                openDrawerCount: 0,
                currentTab: '1',
                linkColumns: [
                    {
                        title: this.$t(I18N_KEY.CUSTOMER + 'contactType'),
                        dataIndex: 'contactType',
                        key: 'contactType',
                        align:"center",
                        scopedSlots: { customRender: 'contactType' }
                    },
                    {
                        title: this.$t(I18N_KEY.CUSTOMER + 'contactRelationship'),
                        dataIndex: 'relationType',
                        key: 'relationType',
                        align:"center",
                        scopedSlots: { customRender: 'relationType' }
                    },
                    {
                        title: this.$t(I18N_KEY.CUSTOMER + 'contactName'),
                        dataIndex: 'contactName',
                        key: 'contactName',
                        align:"center"
                    },
                    {
                        title: this.$t(I18N_KEY.CUSTOMER + 'contactPhone'),
                        dataIndex: 'contactPhone',
                        key: 'contactPhone',
                        align:"center"
                    },
                ],

                examineColumns: [
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalStaff'),
                        dataIndex: 'examineUserName',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalTime'),
                        dataIndex: 'examineTime',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalTaskStatus'),
                        dataIndex: 'approveTaskStatus',
                        align:"center",
                        scopedSlots: { customRender: 'approveTaskStatus' }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalStatus'),
                        dataIndex: 'approveStatus',
                        align:"center",
                        scopedSlots: { customRender: 'approveStatus' }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalRemark'),
                        dataIndex: 'comment',
                        align:"center",
                    },
                ],
                FILE_TYPE: {
                    'aadhaar_front': this.$t(I18N_KEY.CUSTOMER + 'aadhaarFront'),
                    'aadhaar_back': this.$t(I18N_KEY.CUSTOMER + 'aadhaarBack'),
                    'pan_front': this.$t(I18N_KEY.CUSTOMER + 'panFront'),
                    'face_photo_list': this.$t(I18N_KEY.CUSTOMER + 'facePhotoList'),
                },
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 20,
                },
            }
        },
        computed: {
            ...mapGetters('order', [
                'orderDetail',
                'examineLogList',
            ]),
            ...mapGetters('customer', [
                'customerDetail',
            ]),
            basicInfo () {
                return (this.customerDetail && this.customerDetail.basicInfo) || {};
            },
            detailInfo () {
                return (this.customerDetail && this.customerDetail.detailInfo) || {};
            },
            linkmanInfos () {
                return (this.customerDetail && this.customerDetail.linkmanInfos) || [];
            },
            aadhaarInfo () {
                return (this.customerDetail && this.customerDetail.aadhaarInfo) || {};
            },
            panInfo () {
                return (this.customerDetail && this.customerDetail.panInfo) || {};
            },
            imageInfos () {
                return (this.customerDetail && this.customerDetail.imageInfos) || [];
            },
            bankCardInfo () {
                return (this.customerDetail && this.customerDetail.bankCardInfo) || [];
            },
            orderInfo () {
                return (this.orderDetail || {}).orderInfo || {};
            },
            examineSource () {
                return this.examineLogList || [];
            }
        },
        mounted () {
            window.aa = this;
            this.requestDetail(this.orderId);
        },
        methods: {
            ...mapActions('order', [
                'getOrderDetail',
                'getExamineLogList',
            ]),
            ...mapActions('approval', [
                'auditApproval',
                'getApprovalListItem',
            ]),
            ...mapActions('customer', [
                'getCustomerDetail',
            ]),
            drawContainer () {
                return document.querySelector('.ant-modal-wrap')
            },
            handelDrawer (visible) {
                if (visible) {
                    this.openDrawerCount++;
                    this.openDrawer();
                    this.openDrawerCount < 2 && this.$nextTick(() =>{
                        this.basicForm.setFieldsValue(this.listItem);
                    })
                } else {
                    this.closeDrawer();
                }
            },
            openDrawer () {
                this.visible = true;
            },
            closeDrawer () {
                this.visible = false;
                // this.basicForm.resetFields();
            },
            submitAuditForm (status) {
                this.basicForm.validateFields((err, values) => {
                    if (!err) {
                        this.confirmLoading = true;
                        const { remark } = values;
                        delete values.remark;
                        const items = Object.entries(values).map(item => {
                            return {
                                itemCode: item[0],
                                itemResult: item[1],
                            }
                        })
                        const data = {
                            taskId: this.taskId,
                            remark,
                            items,
                        }
                        this.auditApproval(data).then(res => {
                            this.$message.success(this.$t('success'));
                            this.closeParentModal();
                        }).catch(e => {
                            this.$message.error(e.message);
                        }).finally(() =>{
                            this.confirmLoading = false;
                        })
                    }
                })
            },
            closeParentModal () {
                this.$emit('close');
            },
            requestDetail (id) {
                this.getExamineLogList({ orderId: id });
                Promise.all([
                    this.getOrderDetail({ id }),
                    this.getCustomerDetail({ id: this.custInfoId }),
                    this.getApprovalListItem({ taskId: this.taskId }).then(res => {
                        const { remark, items } = (res && res.data) || {};
                        const form = (items || []).reduce((obj, item) => {
                            obj[item.itemCode] = item.itemResult;
                            return obj;
                        }, {})
                        this.listItem = { remark, ...form };
                    }),
                ]).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.$message.error(e.message);
                    this.loading = false;
                })
            },
        }
    }
</script>
<style lang="less" scoped>

    .tag {
        border-style: dashed;
        background: #fff;
    }
    
    .title {
        font-size: 14px;
        color: rgba(0,0,0,.65);
        font-weight: 400;
        margin-bottom: 12px;
    }
    .file-box {
        float: left;
        width: 200px;
        height: 200px;
        margin-right: 10px;
        margin: 0 8px 8px 0;

        .file-item {
            width: 100%;
            height: 100%;
            position: relative;
            border: 1px solid #d9d9d9;
            border-radius: 4px;

            .file-preview {
                padding: 8px;
                width: 182px;
                height: 142px;
            }

            .file-label {
                margin: 0;
                text-align: center;
                border-bottom: 1px solid #d9d9d9;
                padding: 8px;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }

    .setting-drawer-handle {
        position: fixed;
        top: 240px;
        background: #1890ff;
        width: 32px;
        height: 32px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        pointer-events: auto;
        z-index: 1301;
        text-align: center;
        font-size: 16px;
        border-radius: 4px 0 0 4px;
        transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);

        &.active {
            transform: translateX(-600px);
            // right: 500px;
        }

        i {
          color: rgb(255, 255, 255);
          font-size: 20px;
        }
    }

    .audit-form {
        width: 100%;
        padding: 10px;
        border: 1px solid #e9e9e9;
        background: #fff;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        left: 0;
        background: #fff;
        border-radius: 0 0 4px 4px;
    }
    /deep/ .description-list .content {
        font-weight: 700;
    }
</style>