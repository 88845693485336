<template>
    <div>{{ formatValue }}</div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import dict from './dict-mixin-extend';
    export default {
        mixins: [dict],
        props: {
            dictCode: {
                type: String,
                default: undefined,
            },
            value: {
                type: [String, Number],
                default: undefined,
            }
        },
        computed: {
            formatValue () {
                return this.getDictItemName(this.dictCode, this.value)
            }
        },
        // render (createElement) {
        //     this.$slots.default.forEach(item => {
        //         item.text = this.getDictItemName(this.dictCode, item.text, fieldKey)
        //     })
        //     return createElement(
        //         'div',
        //         [this.$slots['default']]
        //     )
        // }
    }
</script>