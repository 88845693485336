<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="6">
            <a-form-item label="注册日期" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
             <a-range-picker 
              :default-value="[moment().startOf('day').subtract(6, 'd'), moment().endOf('day')]"
              v-model="queryParam.examineDate"
              format="YYYY-MM-DD"
             />
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-form-item label="campaign" :labelCol="{ span: 6 }" >
              <a-checkbox @change="(val) => queryParam.campaign = val.target.checked ? 1: 0"> </a-checkbox>
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-form-item label="adName" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-checkbox @change="(val) => queryParam.adName = val.target.checked ? 1: 0 ">  </a-checkbox>
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item label="medieSource" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-checkbox @change="(val) => queryParam.medieSource = val.target.checked ? 1: 0 ">  </a-checkbox>
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-form-item label="包" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-checkbox @change="(val) => queryParam.appSsidGroup = val.target.checked ? 1: 0 ">  </a-checkbox>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item :label="'产品类型'">
              <a-select mode='multiple'
                        v-model='queryParam.appssids'
              >
                <a-select-option v-for='item in appList' :key='item.appssid'>
                  {{ item.appName }}
                </a-select-option>
              </a-select>

            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
      </a-table>
    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { appAllCountForChannel, getApplist } from '@/webpublicapi/order'
import Dict from '../modules/Dict'
import DictSelect from '../modules/DictSelect'
import moment from 'moment'
// moment().startOf('day').subtract(6, 'd'), moment().endOf('day'

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
  REPAYREPORT: 'repayReport.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
  },
  data() {
    return {
      moment,
      I18N_KEY,
      visible: false,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        appSsidGroup: '',
        campaign: '',
        adName: '',
        MediaSource: '',
        examinerName: '',
        examineDate: undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 },
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t(I18N_KEY.ORDER + 'curdate'),
          align: 'left',
          dataIndex: 'datadt',
          width: 80,
        },
        {
          title: '产品',
          align: 'left',
          dataIndex: 'appSsid',
          width: 100,
        },
        {
          title: 'campaign',
          align: 'left',
          dataIndex: 'campaign',
          width: 100,
        },
        {
          title: 'adName',
          align: 'left',
          dataIndex: 'adName',
          width: 100,
        },
        {
          title: 'mediaSource',
          align: 'left',
          dataIndex: 'verityRate',
          width: 100
        },
        {
          title: '注册数',
          align: 'left',
          dataIndex: 'regCount',
          width: 100
        },
        {
          title: '申请数',
          align: 'left',
          dataIndex: 'applyCount',
          width: 100
        },
        {
          title: '放款数',
          align: 'left',
          dataIndex: 'loanCount',
          width: 100
        },
        {
          title: '注册-申请转化率',
          align: 'left',
          dataIndex: 'rsRate',
          width: 100
        },
        {
          title: '申请-放款转化率',
          align: 'left',
          dataIndex: 'slRate',
          width: 100
        },
      ]
    },
  },
  created() {
    getApplist().then(res => {
      this.appList = res.data || [];
    })
    this.loadData(1)
    this.queryParam.examineDate = [this.moment().startOf('day').subtract(6, 'd'), this.moment().endOf('day')]
  },
  methods: {
    searchQuery(arg) {
      this.loadData(1, arg)
    },
    loadData(arg, arg2) {
      // 加载数据 若传入参数1则加载第一页的内容
      console.log(arg2)
      if (arg === 1) {
        this.form.pageNum = 1
      }
      console.log(this)
      this.loading = true
      var params = this.getQueryParams(arg2) // 查询条件
      appAllCountForChannel(Object.assign({}, params))
        .then((res) => {
          if (res.code === 1000) {
            // this.total = res.data && res.data.total
            this.dataSource = res.data || []
          } else {
            this.total = 0
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase()
    },
    getQueryParams(arg) {
      const { examineDate } = this.queryParam
      console.log('------')
      const data = {
        ...this.queryParam,
        startDate: examineDate && this.transformTime(examineDate[0]),
        endDate: examineDate && this.transformTime(examineDate[1]),

      }
      delete data.examineDate
      return filterObj(data)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined
      that.loadData(this.form.pageNum)
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';
</style>
