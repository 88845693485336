<template>
  <a-card :bordered="false">

        <div class="table-page-search-wrapper">
            <a-row>
              <a-col :md="2" :sm="24">
                <a-button style="margin-bottom: 10px" type="primary" @click="handleAdd">新增</a-button>
              </a-col>
<!--              <a-col :md="2" :sm="24">-->
<!--                <a-button style="margin-bottom: 10px" type="primary" @click="handleConfigAdd">添加配置</a-button>-->
<!--              </a-col>-->
<!--              <a-col :md="2" :sm="24">-->
<!--                <a-button style="margin-bottom: 10px" type="primary" @click="editProdictConfig">修改配置</a-button>-->
<!--              </a-col>-->


            </a-row>
        </div>
        <div>
          <a-table
            ref="table"
            rowKey="id"
            size="middle"
            :columns="columns"
            :dataSource="dataSource"
            :loading="loading"
            bordered
          >
            <Dict slot="allowDiferTime" slot-scope="text" :dict-code="DICT_CODE.ALLOW_DIFER_TIME" :value="text"/>
             <template slot="allowPrepay" slot-scope="text">
              {{text==0?'不允许':'允许'}}
            </template>
            <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
            </span>

          </a-table>
        </div>
         <prodict-item-modal ref="prodictItemModal" @ok="modalFormOk"></prodict-item-modal>
          <prodict-config-modal ref="ProdictConfigModal" @ok="modalFormOk"></prodict-config-modal>
          <prodict-config-list ref="ProdictConfigList"></prodict-config-list>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import { getProductItemList } from '@/webpublicapi/operation'
import Dict from '../modules/Dict'
import ProdictItemModal from './modules/ProdictItemModal'
import ProdictConfigModal from './modules/ProdictConfigModal'
import ProdictConfigList from './modules/ProdictConfigList'
export default {
  name: 'productItemList',
  components: { Dict,ProdictItemModal,ProdictConfigModal,ProdictConfigList},
  props: {
    id:{
        type: [String, Number],
        default: undefined,
    },
   appSsid:{
        type: [String, Number],
        default: undefined,
    },
  },
  data () {
    return {
      dataSource: [],
      loading: false,
      columns: [
        {
          title: '期限',
          align: 'center',
          dataIndex: 'duration'
        },
        {
          title: '最大金额',
          align: 'center',
          dataIndex: 'maxAmount'
        },
        {
          title: '最小金额',
          align: 'center',
          dataIndex: 'minAmount'
        },
        {
          title: '递增金额',
          align: 'center',
          dataIndex: 'incrAmount'
        },
        {
          title: '增长额度',
          align: 'center',
          dataIndex: 'incrQuota'
        },
        {
          title: '允许展期时间',
          align: 'center',
          dataIndex: 'allowDiferTime',
          scopedSlots: { customRender: 'allowDiferTime' }
        },
         {
          title: '是否允许提前还款',
          align: 'center',
          dataIndex: 'allowPrepay',
           scopedSlots: { customRender: 'allowPrepay' }
        },
         {
          title: '服务费率',
          align: 'center',
          dataIndex: 'serviceFeeRate'
        },
         {
          title: '展期服务费',
          align: 'center',
          dataIndex: 'diferFeeRate'
        },
         {
          title: '逾期费率',
          align: 'center',
          dataIndex: 'overdueRate'
        },
        {
          title: '日利率',
          align: 'center',
          dataIndex: 'dayInterestRate'
        },
        {
          title: '税费（google账号）',
          align: 'center',
          dataIndex: 'ivaFee'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: function (text) {
            if (text==='0') {
              return "无效"
            } else if (text==='1') {
              return "有效"
            } else {
              return "";
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      title: '操作',
      visible: false,
      screenWidth: 1200,
      model: {},
      proId: '',
    }
  },
  created () {
    this.proId = this.id;
    this.loadData()
  },
  methods: {
    loadData () {
       this.loading = true
       getProductItemList(Object.assign({productId:this.proId})).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },


    handleAdd () {
      this.$refs.prodictItemModal.add(this.proId,this.appSsid)
      this.$refs.prodictItemModal.title = '新增'
    },
    handleEdit (record) {
      record.allowDiferTime = record.allowDiferTime != undefined && '' + record.allowDiferTime;
      this.$refs.prodictItemModal.edit(record)
      this.$refs.prodictItemModal.title = '编辑'
    },
     handleConfigAdd () {
      this.$refs.ProdictConfigModal.add(this.proId,this.appSsid)
      this.$refs.ProdictConfigModal.title = '添加配置'
    },
    // 编辑字典数据
    editProdictConfig () {
     this.$refs.ProdictConfigList.edit(this.proId,this.appSsid)
      this.$refs.ProdictConfigList.title = '添加配置'
    },
    modalFormOk () {
      this.loadData()
    }
  },
  watch: {
    proId (val) {
      this.proId = val
    }
  }
}
</script>
<style scoped>

</style>
