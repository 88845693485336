<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'loanTime')">
              <a-range-picker
                v-model="queryParam.lendingTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'custType')">
              <a-select default-value="" style="width: 120px" v-model="queryParam.custType">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="1">
                  新户
                </a-select-option>
                <a-select-option value="2">
                  老户
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'产品类型'">
              <a-select
                v-model="queryParam.appssid"
                @change="onChange"
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="'订单类型'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.orderType">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="0">
                  正常订单
                </a-select-option>
                <a-select-option value="1">
                  展期订单
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData('')" icon="download" style="margin-left: 8px">导出</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>


    </div>
    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {countLoanDataWithDateForApp, getApplist} from '@/api/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import exportFile from '@/api/exportFile'
import moment from 'moment'
import vModal from '../modules/Modal';
import {getCollectionListDepartment} from "@/api/group";
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  // name: 'CountCollectionTaskDispense',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      appList: [],
      // 查询条件
      queryParam: {
        "orderNo":"",
        "appssid":"",
        "mobileNo":"",
        "orderType":"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        
        {
          title:  '放款日期',
          align: 'left',
          dataIndex: 'paymentTime'
        },
        {
          title:  'appssid',
          align: 'left',
          dataIndex: 'appSsid'
        },
        {
          title:  'app名称',
          align: 'left',
          dataIndex: 'appName'
        },
        {
          title: '放款笔数',
          align: 'left',
          dataIndex: 'loanCount'
        },
        {
          title: '新户笔数',
          align: 'left',
          dataIndex: 'newLoanCount'
        },
        {
          title: '新户件均',
          align: 'left',
          dataIndex: 'jianjunNew'
        },
        {
          title: '老户笔数',
          align: 'left',
          dataIndex: 'oldLoanCount'
        },
        {
          title: '老户件均',
          align: 'left',
          dataIndex: 'jianjunOld'
        },
        {
          title: '老户占比',
          align: 'left',
          dataIndex: 'noRate'
        },
        {
          title: '放款本金',
          align: 'left',
          dataIndex: 'paymentAmt'
        },
        {
          title: '新户放款本金',
          align: 'left',
          dataIndex: 'newPaymentAmt'
        },
        {
          title: '老户放款本金',
          align: 'left',
          dataIndex: 'oldPaymentAmt'
        },
        {
          title: '放款合同额',
          align: 'left',
          dataIndex: 'applyAmt'
        },
        {
          title: '新户合同额',
          align: 'left',
          dataIndex: 'newApplyAmt'
        },
        {
          title: '老户合同额',
          align: 'left',
          dataIndex: 'oldApplyAmt'
        }
      ]
    },
  },
  created () {
    this.getDict();
    getApplist().then(res => {
      this.appList = res.data || [];
    })
    this.loadData();
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData()
    },
    loadData (arg) {
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      countLoanDataWithDateForApp(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data.total
          this.dataSource = res.data || [];
          if(this.dataSource.length > 0){
            for(var i = 0 ; i < this.dataSource.length ; i ++){
              var dataO = this.dataSource[i];

              dataO.jianjunOld = (dataO.oldApplyAmt / dataO.oldLoanCount).toFixed(1);
              dataO.jianjunNew = (dataO.newApplyAmt / dataO.newLoanCount).toFixed(1);

            }
          }
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { lendingTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          lendingTimeStart: (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[0]) : undefined,
          lendingTimeEnd : (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[1]) : undefined,
      }
      delete data.applyDate;
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.lendingTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    downLoadData (arg2) {
      var params = this.getQueryParams(arg2)// 查询条件
      const file = {
        fileUrl: '/api/web/platform/order/countLoanDataWithDateForAppExcel?lendingTimeStart=' + params.lendingTimeStart + '&lendingTimeEnd=' + params.lendingTimeEnd + '&custType=' + params.custType,
        fileName: 'countLoanDataWithDateExcel.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    }
    
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
