import { axios } from '@/utils/request'

// 下载模板接口： https://web.inxchile.com/api/web/webpublic/statistics/downloadApmFile
export function downloadTemplate() {
  return axios({
    url: '/statistics/downloadApmFile',
    method: 'POST',
    responseType: 'blob',
  })
}

// 上传接口
// https://web.inxchile.com/api/web/webpublic/statistics/uploadApmFile
// 文件类型参数名字： file
export function uploadFile(data) {
  return axios({
    url: '/statistics/uploadApmFile',
    method: 'POST',
    data,
    responseType: 'blob',
    downloadFile: 'true',
  })
}
