<template>
    <div>
        <div class="table-operator">
            <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('add') }}</a-button>
        </div>
        <a-table
            :columns="columns"
            :pagination="false"
            :dataSource="dataSource"
            :loading="loading"
            :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
            rowKey="id"
            size="middle"
            @expand="expandSubmenu">
            <span slot="icon" slot-scope="text, record"><a-icon :type="text" /></span>
            <span slot="action" slot-scope="text, record">
                <a @click="handleEdit(record)">{{ $t('edit') }}</a>
                <a-divider type="vertical"/>
                <a-popconfirm v-if="record.menuType == 2" :title="$t('confirmDelete')" @confirm="() => handleDelete(record.id)">
                    <a>{{ $t('delete') }}</a>
                </a-popconfirm>
                <a-dropdown v-if="record.menuType == 1">
                    <a class="ant-dropdown-link">
                      {{ $t('more') }} <a-icon type="down"/>
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item>
                            <a href="javascript:;" @click="handleAddSub(record)">{{ $t(I18N_KEY + 'add') + '' + $t(I18N_KEY + 'submenu') }}</a>
                        </a-menu-item>
                        <a-menu-item>
                            <a-popconfirm :title="$t('confirmDelete')" @confirm="() => handleDelete(record.id)">
                                <a>{{ $t('delete') }}</a>
                            </a-popconfirm>
                      </a-menu-item>
                    </a-menu>
              </a-dropdown>
            </span>
        </a-table>
        <permission-modal
            ref="modalForm"
            :menu-tree="menuTree"
            :only-btn="onlyBtn"
            @ok="modalFormOk"></permission-modal>
    </div>
</template>
<script>
    const I18N_KEY = 'system.menuList.';
    import { mapActions, mapGetters } from 'vuex';
    import lang from '@/i18n/lang/'
    import PermissionModal from '../modules/PermissionModal';

    export default {
        components: {
            PermissionModal,
        },
        data () {
            return {
                I18N_KEY,
                loading: false,
                dataSource: [],
                /* table选中keys*/
                selectedRowKeys: [],
                /* table选中records*/
                selectionRows: [],
                menuForm: {
                    parentId: undefined,
                },
                onlyBtn: false,
            }
        },
        computed: {
            ...mapGetters('system', [
                'menuListTree',
            ]),
            ...mapGetters('language', ['languageStorage']),
            menuList () {
                const formatList = (list) => {
                    return list.map(item => {
                        return {
                            ...item,
                            children: item.children && item.children.length ? formatList(item.children) : undefined,
                        }
                    })
                }
                return formatList(this.menuListTree || []);

            },
            menuTree () {
                return this.transformMenuListToTree(this.menuList);
            },
            columns () {
                return [
                    {
                        title: this.$t(I18N_KEY + 'menuName'),
                        dataIndex: 'name',
                        key: 'name',
                        customRender: (text, record, index) => {
                            return this.languageStorage == lang.zhCN ? record.nameCn : record.nameEn
                        }
                    }, {
                        title: this.$t(I18N_KEY + 'type'),
                        dataIndex: 'menuType',
                        key: 'menuType',
                        align: 'center',
                        customRender: function(text) {
                            if (text == 1) {
                                return '菜单'
                            } else if (text == 2) {
                                return '按钮/权限'
                            } else {
                                return text
                            }
                        }
                    }, {
                        title: this.$t(I18N_KEY + 'icon'),
                        dataIndex: 'menuIcon',
                        key: 'menuIcon',
                        align: 'center',
                        scopedSlots: { customRender: 'icon' }
                    }, {
                        title: this.$t(I18N_KEY + 'menuUrl'),
                        dataIndex: 'menuPath',
                        key: 'menuPath',
                        align: 'center',
                    }, {
                        title: this.$t(I18N_KEY + 'sort'),
                        dataIndex: 'menuSort',
                        key: 'menuSort',
                        align: 'center',
                    }, {
                        title: this.$t(I18N_KEY + 'action'),
                        dataIndex: 'action',
                        scopedSlots: { customRender: 'action' },
                        align: 'center',
                        width: 150,
                        align: 'center',
                    }
                ]
            }
        },
        watch: {
            menuList (list) {
                list && list.length && (this.dataSource = list);
            }
        },
        created () {
            window.xx = this;
            this.loadData();
        },
        methods: {
            ...mapActions('system', [
                'getMenuListTree',
                'addMenu',
                'editMenu',
                'deleteMenu',
            ]),
            transformMenuListToTree (list) {
                return list.map(item => {
                    return {
                        ...item,
                        value: '' + item.id,
                        label: item.nameCn,
                        key: '' + item.id,
                        children: item.children && item.children.length && this.transformMenuListToTree(item.children)
                    }
                })
            },
            expandSubmenu(expanded, record){
            },
            onSelectChange(selectedRowKeys, selectionRows) {
                console.log(110, selectionRows, selectedRowKeys)
                this.selectedRowKeys = selectedRowKeys;
                this.selectionRows = selectionRows;
            },
            modalFormOk(data) {
                console.log('xxxxx', data);
                // 新增/修改 成功时，重载列表
                this.submitMenu(data);
            },
            handleAdd () {
                this.menuId = undefined;
                this.$refs.modalForm.add();
                this.$refs.modalForm.title = "新增";
                this.$refs.modalForm.disableSubmit = false;
            },
            handleEdit (record) {
                this.menuId = record.id;
                this.onlyBtn = record.menuType == 2;
                record.tempMenuType = record.menuType == 2 ? 2 : record.parentId == '0' ? 0 : 1;
                // record.parentId
                this.$refs.modalForm.localMenuType = record.tempMenuType;
                this.$refs.modalForm.edit(record);
                this.$refs.modalForm.title = "编辑";
                this.$refs.modalForm.disableSubmit = false;
            },
            handleDataRule(record) {
                this.$refs.PermissionDataRuleList.edit(record)
            },
            handleAddSub(record) {
                const data = {
                    menuType: 1,
                    tempMenuType: 1,
                    parentId: '' + record.id,
                    hidden: 0
                }
                this.$refs.modalForm.localMenuType = 1;
                this.$refs.modalForm.title = "添加子菜单";
                this.$refs.modalForm.disableSubmit = false;
                this.$refs.modalForm.edit(data);
            },
            handleDelete (id) {
                this.loading = true;
                this.deleteMenu({ menuId: id }).then(res => {
                    this.loadData();
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                })
            },
            submitMenu (data) {
                this.$refs.modalForm.openLoading();
                data.id = this.menuId;
                const requestField = this.menuId ? 'editMenu' : 'addMenu';
                this[requestField](data).then(res => {
                    this.$refs.modalForm.closeLoading();
                    this.$refs.modalForm.close();
                    this.$message.success('添加成功');
                    this.loadData();
                }).catch(e => {
                    this.$refs.modalForm.closeLoading();
                    this.$message.error(e.message);
                })
            },
            loadData() {
                this.loading = true;
                this.getMenuListTree().then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                })
            }
        }
    }
</script>
<style scoped>
  @import '~@/assets/less/common.less';
</style>
