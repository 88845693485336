<template>
  <div>
    <!--    <a-form layout="inline" @keyup.enter.native="searchQuery">-->
    <!--      <a-row :gutter="24">-->
    <!--        <span style="float: left; margin-top: 5px; display: block; margin-bottom: 24px;white-space: nowrap; overflow: hidden;" class="table-page-search-submitButtons">-->
    <!--            <a-col :md="6" :sm="24">-->
    <!--                <a-button type="primary" @click="searchQuery">{{ $t('search') }}</a-button>-->
    <!--            </a-col>-->
    <!--        </span>-->
    <!--      </a-row>-->
    <!--    </a-form>-->
    <div class="table-operator"  style="margin-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('add') }}</a-button>
    </div>
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :loading="loading"
      bordered>
      <template #status="text,record,index">{{$t(I18N_KEY + 'seatStatus_' + text)}}</template>
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">{{ $t('edit') }}</a>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link">
          {{ $t('more') }} <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">
                <a>{{ $t('delete') }}</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm :title="$t(I18N_KEY + 'confirmUnbind')" @confirm="() => unbindClousSeat(record)">
                <a>{{ $t(I18N_KEY + 'unbindClousSeat') }}</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right;">
      <v-pagination
        v-if="total"
        :page-num="form.pageNum"
        :page-size="form.pageSize"
        :total="total"
        @change="handleTableChange" />
    </div>
    <seat-modal ref="modalForm" :tele-market-users='teleMarketUsers' :seat-types='seatTypes' @ok="modalFormOk"></seat-modal>
  </div>
</template>
<script>
import { seatList, seatOperate, seatDelete,teleMarketUsers, unbindClousSeat,baseData} from '@/webpublicapi/teleMarket';
import SeatModal from './modules/SeatModal'

const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketSeat',
  components: {
    SeatModal,
  },
  data () {
    return {
      I18N_KEY,
      loading: false,
      total: 0,
      teleMarketUsers: [],
      seatTypes: [],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      // FORCE_UPDATE: {
      //   0: '不强更',
      //   1: '强更',
      // },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    // 表头
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          dataIndex: 'id',
          width:60,
          align:"center",
        },
        {
          title: this.$t(I18N_KEY + 'seatType'),
          align:"center",
          dataIndex: 'type',
        },
        {
          title: this.$t(I18N_KEY + 'seatMobile'),
          align:"center",
          dataIndex: 'mobile',
        },
        {
          title: this.$t(I18N_KEY + 'seat'),
          align:"center",
          dataIndex: 'seat',
        },
        {
          title: this.$t(I18N_KEY + 'cloudCallSeat'),
          align:"center",
          dataIndex: 'callUsername',
        },
        {
          title: this.$t(I18N_KEY + 'cloudCallSeatPassword'),
          align:"center",
          dataIndex: 'callPassword',
        },
        // {
        //   title: this.$t(I18N_KEY + 'nxAccount'),
        //   align:"center",
        //   dataIndex: 'nxAccount',
        // },
        // {
        //   title: this.$t(I18N_KEY + 'nxPassword'),
        //   align:"center",
        //   dataIndex: 'nxPassword',
        // },
        {
          title: this.$t(I18N_KEY + 'seatStatus'),
          align:"center",
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: this.$t(I18N_KEY + 'createTime'),
          align:"center",
          dataIndex: 'createTime',
        },
        {
          title: this.$t(I18N_KEY + 'updateTime'),
          align:"center",
          dataIndex: 'updateTime',
        },
        {
          title: this.$t(I18N_KEY + 'operate'),
          dataIndex: 'action',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData('');
  },
  methods: {
    loadBaseData(){
      teleMarketUsers({}).then((res) => {
        if (res.code === 1000) {
          this.teleMarketUsers = res.data;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.seatTypes = res.data.seatTypes;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    searchQuery () {
      this.loadData(1)
    },
    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    modalFormOk(data) {
      this.createSeat(data);
    },
    handleAdd () {
      this.versionId = undefined;
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "1";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit (record) {
      this.versionId = record.id;
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "2";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    deleteCurrent (row) {
      this.loading = true;
      seatDelete(row.id).then(res => {
        this.$message.success(this.$t('success'));
        this.loadData(1);
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    unbindClousSeat(row) {
      this.loading = true;
      unbindClousSeat(row.mobile).then(res => {
        this.$message.success(this.$t('success'));
        this.loadData(this.form.pageNum);
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    createSeat (data) {
      console.log(data)
      const form = {
        ...data,
        id: this.versionId,
        opt: this.versionId ? '2' : '1',
      };
      // const requestField = this.versionId ? '2' : '1'
      seatOperate(form).then(res => {
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        if (res.code === 1000) {
          this.$message.success(res.msg);
          this.loadData(1);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    searchReset () {
      this.form = {
        pageNum: 1,
        pageSize: 10,
      }
    },
    loadData (pageNum) {
      if(pageNum) { this.form.pageNum = pageNum; }
      this.loading = true;
      seatList(this.form).then(res => {
        this.loading = false;
        this.total = res.data.total
        this.dataSource = res.data.rows;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    }
  }
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
