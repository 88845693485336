<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
            <a-col :md="8" :sm="12">
              <a-form-item :label="$t(I18N_KEY + 'createdTime')">
                <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'complainChannel')">
              <a-select v-model="queryParam.channel" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in complainChannels" :value="item.dictValue">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'complainChannel_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>



          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="reload" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1300 }"
        bordered>
        <template #channel="text,record,index"><span v-if='text == "total"'>total</span><span v-if='text != "total" && text != undefined'>{{$t(I18N_KEY + 'complainChannel_' + text)}}</span></template>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import { baseDataForCollectionComplaintReport, collectionComplaintReport } from '@/webpublicapi/custServiceWorkOrder'
import vModal from "@/views/webpublic/modules/Modal";
import {mapGetters} from "vuex";
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import _ from 'lodash';
import moment from 'moment';
import exportFile from '@/webpublicapi/exportFile'
const I18N_KEY = 'custServiceWorkOrder.';
export default {
  name: 'CollectionComplaintReport',
  components: {
    vModal,
    QuestionCircleOutlined,
  },
  data () {
    return {
      I18N_KEY,
      form: {
        pageNum: 1,
        pageSize: 100,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "createdTime": [],
        "channel": '',
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
      complainChannels: [],
    }
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns() {
      return [
        {
          title: this.$t(I18N_KEY + 'complainChannel'),
          align: 'left',
          width: 120,
          dataIndex: 'channel',
          scopedSlots: {customRender: 'channel'}
        },
        {
          title: this.$t(I18N_KEY + 'complaintNum'),
          align: 'left',
          width: 120,
          dataIndex: 'complaintNum'
        },
        {
          title: this.$t(I18N_KEY + 'backVisitNum'),
          align: 'left',
          width: 120,
          dataIndex: 'backVisitNum'
        },
        {
          title: this.$t(I18N_KEY + 'connectNum'),
          align: 'left',
          width: 120,
          dataIndex: 'connectNum'
        },
        {
          title: this.$t(I18N_KEY + 'connectRate'),
          align: 'left',
          width: 120,
          dataIndex: 'connectRate'
        },
        {
          title: this.$t(I18N_KEY + 'satisfactionNum'),
          align: 'left',
          width: 120,
          dataIndex: 'satisfactionNum'
        },
        {
          title: this.$t(I18N_KEY + 'satisfactionRate'),
          align: 'left',
          width: 120,
          dataIndex: 'satisfactionRate'
        },
        {
          title: this.$t(I18N_KEY + 'coverRate'),
          align: 'left',
          width: 120,
          dataIndex: 'coverRate'
        },
        // {
        //   title: this.$t('action'),
        //   dataIndex: 'action',
        //   align: 'center',
        //   fixed: 'right',
        //   width: 150,
        //   scopedSlots: {customRender: 'action'}
        // }
      ]
    },
  },
  created () {
    this.loadBaseData();
    // this.loadData(1);
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadBaseData() {
      baseDataForCollectionComplaintReport({}).then((res) => {
        if (res.code === 1000) {
          this.complainChannels = res.data.complainChannels
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      collectionComplaintReport(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      if(this.queryParam.createdTime.length === 0) {
        data['startDate'] = ''
        data['endDate']=''
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    searchReset() {
      var that = this
      that.queryParam.createdTime = []
      that.queryParam.channel = ''
    },
    downLoadData () {
      const params = this.getQueryParams()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/custService/report/collectionComplaintReport/export?startDate=' + this.checkParam(params.startDate) + '&endDate=' + this.checkParam(params.endDate) + '&channel=' + this.checkParam(params.channel),
        fileName: 'CollectionComplaintReport.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    checkParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return '';
      }
      return p;
    },
  },
}
</script>