<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'endTime')">
              <a-range-picker
                v-model="queryParam.endTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'ifLoanCount')">
              <a-select default-value="" style="width: 120px" v-model="queryParam.loanCount">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="00">
                  首贷
                </a-select-option>
                <a-select-option value="01">
                  复贷
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'起始金额'">
              <a-input :placeholder="$t('enter') + '起始金额'" v-model="queryParam.applyAmtStart"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'终止金额'">
              <a-input :placeholder="$t('enter') + '终止金额'" v-model="queryParam.applyAmtEnd"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>
        <Dict slot="approvalTaskStatus" slot-scope="text" :dict-code="DICT_CODE.APPROVAL_TASK_STATUS" :value="text"/>
        <Dict slot="custContractCondition" slot-scope="text" :dict-code="DICT_CODE.CUST_CONTRACT_CONDITION" :value="text"/>
        <Dict slot="linkCondition" slot-scope="text" :dict-code="DICT_CODE.LINKCONDITION" :value="text"/>
        <span slot="action" slot-scope="text, record">
          <!-- slot-scope="text, record" -->
            <a @click="handleDetail(record)">{{ $t('view') }}</a>
        </span>
      </a-table>
    </div>
    <group-order-by-day ref="GroupOrderByDay"/>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { groupOrderByMonth } from '@/webpublicapi/collection'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import CollectionDetail from './CollectionDetail';
import GroupOrderByDay from './modules/GroupOrderByDay';

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.'

}

export default {
  name: 'AllApprovalTask',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
    GroupOrderByDay,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        endTime: [moment().add('days', -30), moment().add('days', -1)],
        loanCount: undefined,
        applyAmtStart: undefined,
        applyAmtEnd: undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t('collection.ym'),
          align: 'left',
          dataIndex: 'ym'
        },
        {
          title:  this.$t('collection.ifAmtRate'),
          align: 'left',
          dataIndex: 'ifAmtRate'
        },
        {
          title: this.$t('collection.ifAmtFirstOverdueRate'),
          align: 'left',
          dataIndex: 'ifAmtFirstOverdueRate'
        },
        {
          title:this.$t('collection.ifAmtUnSettleRate'),
          align: 'left',
          dataIndex: 'ifAmtUnSettleRate'
        },
        {
          title: this.$t('collection.ifAmtEarlySettledRate'),
          align: 'left',
          dataIndex: 'ifAmtEarlySettledRate'
        },
        {
          title: this.$t('collection.ifAmtOverdueSettledRate'),
          align: 'left',
          dataIndex: 'ifAmtOverdueSettledRate',
        },
        {
          title: this.$t('collection.ifAmtExtendsionSettledRate'),
          align: 'left',
          dataIndex: 'ifAmtExtendsionSettledRate',
          scopedSlots: { customRender: 'ifAmtExtendsionSettledRate' }
        },
        {
          title: this.$t('collection.ifAmtOverdueExtendsionSettledRate'),
          align: 'left',
          dataIndex: 'ifAmtOverdueExtendsionSettledRate',
        },
        {
          title: this.$t('collection.ifAmtSettledRate'),
          align: 'left',
          dataIndex: 'ifAmtSettledRate',
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.getDict();
    // this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      groupOrderByMonth(Object.assign({}, params)).then((res)=>{
        console.log(res)
        if(res.code===1000){
          this.dataSource = res.data || [];

          for(var i = 0 ; i < this.dataSource.length ; i++){
            var dataIn = this.dataSource[i];
            dataIn.rate = (dataIn.reborrowCount8 / dataIn.countSettleOrder).toFixed(2);
          }
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        dateStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
        dateEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.endTime = undefined;
      that.queryParam.loanCount = undefined;
      that.queryParam.applyAmtStart = undefined;
      that.queryParam.applyAmtEnd = undefined;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    handleDetail (row) {
      var params = this.getQueryParams();
      row.loanCount = params.loanCount;
      row.dateStart  = params.dateStart;
      row.applyAmtStart = params.applyAmtStart;
      row.applyAmtEnd = params.applyAmtEnd;
      this.$refs.GroupOrderByDay.show(params,row)
    },
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
