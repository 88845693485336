import { axios } from '@/utils/request'

export function collectionUserAccountList (parameter = {}) {
  return axios({
    url: '/collectionUserAccountInfo/collectionUserAccountList',
    method: 'post',
    data: parameter
  })
}

export function repayCertWorkOrderList (parameter = {}) {
  return axios({
    url:'/repayCertWorkOrder/list',
    method: 'post',
    data: parameter
  })
}


export function updateRepayCertWorkOrder (data = {}) {
  return axios({
    url:'/repayCertWorkOrder/update',
    method: 'post',
    data
  })
}

export function createPayOrder(data = {}) {
    return axios({
        url: 'repayCertWorkOrder/createPayOrder',
        method: 'post',
        data
    })
}
export function createPayOrderV2(data = {}) {
  return axios({
      url: '/order/createPayOrderV2',
      method: 'post',
      data
  })
}
// webpublic/order/createPayOrderV2