<template>
  <div>
    <a-spin :spinning="loading">
      <a-form layout="inline" @keyup.enter.native="loadData">
        <a-form-item>
          <a-range-picker
            v-model="queryParam.date"
            :disabled-date="disabledDate"
            @calendarChange="handleChange"
            @blur="handleBlur"
            format="YYYY-MM-DD"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item>
          <a-select
            default-value="5"
            style="width: 190px"
            v-model="queryParam.normalConfigId"
            :options="[
              { value: '5', label: 't0' },
              { value: '6', label: 's1' },
              { value: '8', label: 's2' },
              { value: '9', label: 's3' },
              { value: '10', label: 's4' },
              { value: '11', label: 'm1' },
            ]"
          />
        </a-form-item>

        <a-form-item :label="$t('platformCollection.department')">
          <a-select style="width: 190px" v-model="queryParam.departmentId" @change="onChangeOne">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option v-for="item in departmentList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="$t('platformCollection.grouping')">
          <a-select style="width: 190px" v-model="queryParam.groupId" @change="onChangeTwo">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option v-for="item in groupList" :key="item.id">
              {{ item.groupName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('platformCollection.name_of_collector')">
          <a-select style="width: 190px" v-model="queryParam.userId">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option v-for="item in userList" :key="item.id">
              {{ item.userName }}
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.mobile }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('逾期天数')">
          <a-input v-model="queryParam.overdueDays" />
        </a-form-item >
        <a-form-item :label="$t('催收员账号')">
          <a-input v-model="queryParam.collectionNumber" />
        </a-form-item >
        <a-form-item :label="$t('platformCollection.collection_stage')">
          <a-select :default-value=this.defaultNormalConfig.name style="width: 190px" v-model="queryParam.normalConfigId">
            <a-select-option v-for="item in normalConfigList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="table-page-search-submitButtons">
          <a-button type="primary" @click="loadData(1)" icon="search">{{ $t('search') }}</a-button>
          <a-button type="primary" @click="downLoadData" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
    <div class="tableBox" style="margin-top:30px; margin-bottom:30px">
          <a-table :pagination="false" :dataSource="dataSource" :columns="columns" bordered>
           <template #footer><div class="totalBox">
            <div><h4>当日催回订单数:</h4><p>{{totalObj.dayCount}}</p></div>
            <div><h4>当日催回金额汇总:</h4><p>{{totalObj.dayAmount}}</p></div>
            <div><h4>当日展期笔数汇总:</h4><p>{{totalObj.dayExCount}}</p></div>
            <div><h4>当日展期金额汇总:</h4><p>{{totalObj.dayExAmount}}</p></div>
          </div></template>
            </a-table>

    </div>
    <div style="width：100%; display:flex;justify-content: end;">
    <a-pagination
      show-size-changer
      :current="queryParam.pageNum"
      :pageSize="queryParam.pageSize"
      :total="queryParam.total"
      @change="pageChange"
      @showSizeChange="onShowSizeChange"
    />
    </div>
  </div>
</template>
<script>
import { collectionCountByDay , collectionCountTotalByDay} from '@/webpublicapi/order'
import { getCollectionListDepartment } from '@/webpublicapi/group'
import { getSysGroup, getColUserListByGroupId } from '@/webpublicapi/platformCollection'
import moment from 'moment'
import {mapActions} from "vuex";
import exportFile from '@/webpublicapi/exportFile'
import { filterObj } from '@/utils/util'
import { getAllNormalConfig } from '@/webpublicapi/normalConfig'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}
export default {
    name: 'CollectionCountByDay',
  data() {
    return {
      I18N_KEY,
      loading: false,
      queryParam: {
        date: [moment().add('days', -7), moment().add('days', -1)],
        normalConfigId: '',
        departmentId: '',
        groupId: '',
        userId: '',
        overdueDays:'',
        pageNum:1,
        pageSize:10,
        total:0,
        dateStart:"",
        dateEnd:"",
        collectionNumber: undefined,
      },
      selected: null,
      groupList: [],
      departmentList: [],
      userList: [],
      normalConfigList: [],
      defaultNormalConfig: undefined,
      dataSource: [
        ],
      columns: [
          {
            title: '日期',
            dataIndex: 'dSettleTime',
            key: 'dSettleTime',
          },
          {
            title: '账龄',
            dataIndex: 'normalConfigName',
            key: 'normalConfigName',
          },
          {
            title: '部门',
            dataIndex: 'departmentName',
            key: 'departmentName',
          },
          {
            title: '小组',
            dataIndex: 'groupName',
            key: 'groupName',
          },
          {
            title: '催收员账号',
            dataIndex: 'userAccount',
            key: 'userAccount',
          },
          {
            title: '当日催回订单数',
            dataIndex: 'dayCount',
            key: 'dayCount',
          },
          {
            title: '当日催回金额',
            dataIndex: 'dayAmount',
            key: 'dayAmount',
          },
           {
            title: '当日展期笔数',
            dataIndex: 'dayExCount',
            key: 'dayExCount',
          },
          {
            title: '当日展期金额',
            dataIndex: 'dayExAmount',
            key: 'dayExAmount',
          },
        ],
      totalObj:{},
    }
  },
  created() {
    // 催收员姓名
    getCollectionListDepartment().then((res) => {
      this.departmentList = res.data || []
      // this.loadData()
    })
    getAllNormalConfig().then(res => {
      this.normalConfigList = res.data || [];
      this.normalConfigList.forEach(item => {
        if (item.name === 't0') {
          this.defaultNormalConfig = item
          this.queryParam.normalConfigId = item.id
        }
      })
    })
  },
  mounted(){

  },
  methods: {
    tableMethod(){

    },
    handleChange([start, end]) {
      if (start && !end) {
        this.selected = start
      } else {
        this.selected = null
      }
    },
    handleBlur() {
      this.selected = null
    },
    disabledDate(current) {
      if (!this.selected) return false
      return current.diff(this.selected, 'days') > 30 || current.diff(this.selected, 'days') < -30
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    pageChange(page, pageSize){
      this.queryParam.pageNum=page
      this.queryParam.pageSize=pageSize
      this.loadData()
    },
    onShowSizeChange(page, pageSize){
      this.queryParam.pageNum=page
      this.queryParam.pageSize=pageSize
      this.loadData()
    },
    loadData(pageNu) {
      if (pageNu) {
        this.queryParam.pageNum = pageNu;
      }
      const date = this.queryParam.date
      if (!date[0] || !date[0]) return this.$message.warning('请选择起止日期')
      if (!this.queryParam.normalConfigId) return this.$message.warning('请选择阶段')
      this.queryParam.dateStart=this.transformTime(date[0]),
      this.queryParam.dateEnd=this.transformTime(date[1]),
      this.loading = true
    collectionCountByDay(this.queryParam).then(res=>{
        this.dataSource=res.data.list.map((item,index)=>{
          item.key=index
          return item
      })
      this.queryParam.pageNum=res.data.pageNum
      this.queryParam.pageSize=res.data.pageSize
      this.queryParam.total=res.data.total
      this.loading = false
    })
    let obj=JSON.parse(JSON.stringify(this.queryParam))
    delete obj.pageNum
    delete obj.pageSize
      collectionCountTotalByDay(obj).then(res=>{
         this.totalObj=res.data
    })
    },
    onChangeOne(value) {
      console.log(value)
      getSysGroup({ departmentId: value }).then((res) => {
        this.groupList = res.data || []
        this.queryParam.groupId = ''
      })
    },
    onChangeTwo(value) {
      getColUserListByGroupId({ groupId: value }).then((res) => {
        this.userList = res.data || []
        this.queryParam.userId = ''
      })
    },
    getQueryParams () {
      const { date } = this.queryParam;
      const data = {
        ...this.queryParam,
        dateStart: (date && date.length) ? this.transformTime(date[0]) : '',
        dateEnd : (date && date.length) ? this.transformTime(date[1]) : '',
      }
      delete data.date;
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    downLoadData () {
      var params = this.getQueryParams()// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value) + '';
      // console.log(this.exportParams.replaceAll(",", "&"))
      const file = {
        fileUrl: '/api/web/webpublic/collectionReplaceTask/collectionCountByDayExcel?' + this.exportParams.replaceAll(",", "&"),
        fileName: 'report.xls',
      }
      // console.log(file.fileUrl)

      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    }
  },
}
</script>
<style scoped>
.totalBox{
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.totalBox div{
  margin-right: 50px;
  display: flex;
}
.totalBox h4 , .totalBox p{
  /* font-weight: 600; */
}
</style>

