import dictMixin from '../../mixins/dict-mixin';
const getDictItemName = dictMixin.methods.getDictItemName;

import { mapGetters } from 'vuex';

export default {
    data () {
        return {
            fieldKey: 'itemText'
        }
    },
    computed: {
        ...mapGetters('language', ['languageStorage']),
    },
    watch: {
        languageStorage: {
            handler (lang) {
                if (lang == 'en-US') {
                    this.fieldKey = 'itemTextEn';
                } else if (lang == 'zh-CN') {
                    this.fieldKey = 'itemText';
                }
            },
            immediate: true,
        }
    },
    created () {
        this.getDict();
    },
    methods: {
        getDictItemName (type, value) {
            return getDictItemName.call(this, type, value, this.fieldKey);
        }
    },
}