<template>
    <div>
        <div style="padding: 15px;">
            <div style="margin-right: 10px; font-size: 16px; font-weight: 500; display: inline-block;">Keyword:</div>
            <a-input style="width: 240px; margin-right: 15px;" placeholder="" v-model="form.keywords" />
            <div style="margin-right: 10px; font-size: 16px; font-weight: 500; display: inline-block;">Role:</div>
            <a-select mode="multiple" v-model="roleIdsArr"  style="width: 240px; margin-right: 15px;">
                <a-select-option v-for="item in roleList" :key="item.id">
                    {{ item.roleName }}
                </a-select-option>
            </a-select>
            <a-button type="primary" @click="search(1)">{{ $t('search') }}</a-button>

            <a-button style="margin-left: 10px;" type="primary" @click="createUser('add')"><a-icon type="plus" />{{
                $t('add') }}</a-button>
        </div>
        <a-table :dataSource="list" :pagination="false" :loading="loading" bordered @change="handleTableChange">
            <a-table-column :title="$t(I18N_KEY + 'index')" dataIndex="index" key="index" align="center" />
            <a-table-column :title="$t(I18N_KEY + 'name')" dataIndex="userName" key="userName" align="center" />
            <a-table-column :title="$t(I18N_KEY + 'phone')" dataIndex="mobile" key="mobile" align="center" />
            <a-table-column :title="$t(I18N_KEY + 'mail')" dataIndex="email" key="email" align="center" />
            <a-table-column :title="$t(I18N_KEY + 'time')" dataIndex="createTime" key="createTime" align="center" />
            <a-table-column :title="$t(I18N_KEY + 'status')" dataIndex="status" key="status" align="center">
                <template slot-scope="text, record">
                    <a-badge :status="text == 1 ? 'success' : 'default'" />
                    <span>{{ USER_STATUS[text] }}</span>
                </template>
            </a-table-column>
            <a-table-column :title="$t(I18N_KEY + 'action')" dataIndex="action" key="action" align="center">
                <template slot-scope="text, record">
                    <span>
                        <a href="javascript:;" @click="createUser('edit', record)">{{ $t(I18N_KEY + 'edit') }}</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" @click="deleteCurrent(record)">{{ $t(I18N_KEY + 'delete') }}</a>
                    </span>
                </template>
            </a-table-column>
        </a-table>
        <div style="margin-top: 15px; text-align: right;">
            <v-pagination v-if="total" :page-num="form.pageNum" :page-size="form.pageSize" :total="total"
                @change="handleTableChange" />
        </div>
        <a-modal :title="modalTitle" v-model="visible" @ok="submitUser">
            <a-form :form="basicForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                <a-form-item :label="$t(I18N_KEY + 'name')">
                    <a-input v-model="basicForm.userName" />
                </a-form-item>

                <a-form-item :label="$t(I18N_KEY + 'phone')">
                    <a-input v-model="basicForm.mobile" />
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'mail')">
                    <a-input v-model="basicForm.email" />
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'password')">
                    <a-input v-model="basicForm.password" />
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'status')">
                    <a-switch v-model="basicForm.status" />
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'role')">
                    <a-select mode="multiple" v-model="basicForm.roleIds">
                        <a-select-option v-for="item in roleList" :key="item.id">
                            {{ item.roleName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'app')">
                    <a-select mode="multiple" v-model="basicForm.subApps">
                        <a-select-option v-for="item in appList" :key="item.id">
                            {{ item.appName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { getAppList } from '@/webpublicapi/system'
import vPagination from '../modules/pagination';

const I18N_KEY = 'system.userList.';
export default {
    components: {
        vPagination,
    },
    data() {
        return {
            I18N_KEY,
            visible: false,
            modalTitle: undefined,
            loading: false,
            roleIdsArr:[],
            form: {
                pageNum: 1,
                pageSize: 10,
                keywords: undefined,
                roleIds:[],
            },
            defaultBasicForm: {
                userName: undefined,
                roleIds: [],
                subApps: [],
                mobile: undefined,
                email: undefined,
                password: undefined,
                status: true,
            },
            basicForm: {},
            USER_STATUS: {
                1: '启用',
                2: '禁用',
            },
            appList: [],
        }
    },
    computed: {
        ...mapGetters('system', [
            'userList',
            'allRoleList',
        ]),
        list() {
            const list = (this.userList && this.userList.rows) || [];
            return list.map((item, index) => {
                return {
                    index: index + 1,
                    ...item,
                }
            });
        },
        total() {
            return this.userList && this.userList.total;
        },
        pagination() {
            return {
                pageSize: this.form.pageSize,
                current: this.form.pageNum,
                total: this.total,
            }
        },
        roleList() {
            return this.allRoleList || [];
        },
    },
    created() {
        window.xx = this;
        this.basicForm = { ...this.defaultBasicForm };
        this.search();
        this.getAllRoleList();
        this.getAppList();
    },
    methods: {
        ...mapActions('system', [
            'getUserList',
            'addUser',
            'editUser',
            'deleteUser',
            'getAllRoleList',
        ]),
        handleTableChange(pageNum, pageSize) {
            this.form.pageNum = pageNum;
            this.form.pageSize = pageSize;
            this.search();
        },
        changeModal(visible) {
            this.visible = visible;
        },
        getAppList() {
            getAppList({ pageNum: 1, pageSize: 100 }).then((res) => {
                if (res.code === 1000) {
                    this.appList = res.data.rows || [];
                }
            });
        },
        createUser(mode, user = {}) {
            this.changeModal(true);
            this.basicForm.userId = user.id;
            if (mode == 'edit') {
                this.basicForm = {
                    ...this.basicForm,
                    userName: user.userName,
                    mobile: user.mobile,
                    email: user.email,
                    password: user.password,
                    status: user.status == 1,
                    roleIds: user.roleIds || [],
                }
            } else {
                this.basicForm = { ...this.defaultBasicForm };
            }
            this.modalTitle = mode == 'add' ? '添加用户' : '修改用户'
        },
        deleteCurrent(row) {
            this.$confirm({
                title: '删除用户',
                content: '是否删除当前用户',
                onOk: () => {
                    this.loading = true;
                    this.deleteUser({ id: userId.id }).then(res => {
                        this.search(1);
                    }).catch(e => {
                        this.loading = false;
                        this.$message.error(e.message);
                    });
                }
            });
        },
        submitUser() {
            this.changeModal(false);
            this.loading = true;
            const form = {
                ...this.basicForm,
                status: this.basicForm.status ? 1 : 2,
            };
            const requestField = form.userId ? 'editUser' : 'addUser';

            this[requestField](form).then(res => {
                this.loading = false;
                this.$message.success(this.$t('success'));
                this.search(1);
            }).catch(e => {
                this.loading = false;
                this.$message.error(e.message);
            })
        },
        search(pageNum) {
            if (pageNum) { this.form.pageNum = pageNum; }
            this.loading = true;
            this.form.roleIds=this.roleIdsArr.join(",")
            this.getUserList(this.form).then(res => {
                this.loading = false;
            }).catch(e => {
                this.loading = false;
                this.$message.error(e.message);
            });
        },
    }
}
</script>
