<template>
    <div>
        <div style="padding: 15px;">
            <!-- <a-input style="width: 240px; margin-right: 15px;" placeholder="关键字" v-model="form.keywords"/>
            <a-button type="primary" @click="handleSearch(1)">{{ $t('search') }}</a-button> -->
            <a-button  type="primary" @click="createGroup('edit')"><a-icon type="plus" />{{ $t('add') }}</a-button>
        </div>
        <a-table
            :dataSource="list"
            :pagination="false"
            :loading="loading"
            bordered
            @change="handleTableChange">
            <a-table-column
                :title="$t('platformCollection.user_name')"
                dataIndex="userName"
                key="userName"
                align="center"
            />
            <a-table-column
                :title="$t('platformCollection.state')"
                dataIndex="status"
                key="status"
                align="center"
            >
                <template slot-scope="text, record">
                    <!-- <span>{{ text == 1 ? '启用' : '禁用' }}</span> -->
                    <a-switch
                        :checked-children="$t('platformCollection.enable')"
                        :un-checked-children="$t('platformCollection.disable')"
                        :checked="text == 1"
                        @change="onChange(record)" />
                </template>
            </a-table-column>
            <a-table-column
                :title="$t('platformCollection.operation')"
                dataIndex="action"
                key="action"
                align="center"
            >
                <template slot-scope="text, record">
                    <span>
                        <a href="javascript:;" @click="deleteCurrent(record)">{{ $t('platformCollection.delete') }}</a>
                    </span>
                </template>
            </a-table-column>
        </a-table>
        <div style="margin-top: 15px; text-align: right;">
            <v-pagination
                v-if="total"
                :page-num="form.pageNum"
                :page-size="form.pageSize"
                :total="total"
                @change="handleTableChange" />
        </div>
        <a-modal
            :title="modalTitle"
            v-model="visible"
            @ok="submitUser"
        >
            <a-form :form="basicForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                <a-form-item :label="$t('platformCollection.user')">
                    <a-select
                        v-model="basicForm.userId"
                    >
                        <a-select-option v-for="item in userList" :key="item.userId">
                            {{ item.userName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>


                <!-- <a-form-item label="催收级别">
                  <a-input v-model="basicForm.collectionLevel" />
                </a-form-item> -->
                <!-- <a-form-item label="状态">
                  <a-switch v-model="basicForm.status" />
                </a-form-item> -->
            </a-form>
        </a-modal>
    </div>
</template>
<script>
    import {
        getPlatformCollectionGroupUserList,
        addPlatformCollectionGroupUserList,
        editPlatformCollectionGroupUserList,
        deletePlatformCollectionGroupUserList,
        switchPlatformCollectionGroupUserList,
        getCollectionTeamUserList,
    } from '@/api/group';
    export default {
        props: {
            groupId: {
                type: [String, Number],
                default: undefined,
            }
        },
        data () {
            return {
                modalTitle: this.$t('add'),
                visible: false,
                form: {
                    pageNum: 1,
                    pageSize: 10,
                },
                defaultBasicForm: {
                    userId: undefined,
                    groupId: undefined,
                },
                basicForm: {},
                list: [],
                total: 0,
                loading: false,
                userList: [],
            }
        },
        watch: {
            groupId: {
                immediate: true,
                handler (val) {
                    if (this.groupId) {
                        this.form.groupId = this.groupId;
                        this.handleSearch();
                    }
                },
            }
        },
        mounted () {
            this.basicForm = { ...this.defaultBasicForm };
            getCollectionTeamUserList().then(res => {
                this.userList = res.data || [];
            });
        },
        methods: {
            handleTableChange (pageNum, pageSize) {
                this.form.pageNum = pageNum;
                this.form.pageSize = pageSize;
                this.handleSearch();
            },
            changeModal (visible) {
                this.visible = visible;
            },
            getAppList () {
                getAppList({ pageNum: 1, pageSize: 100 }).then((res) => {
                    if(res.code===1000){
                      this.appList = res.data.rows || [];
                    }
                });
            },
            onChange (record) {
                this.loading = true;
                switchPlatformCollectionGroupUserList({ id: record.id, status: record.status == 1 ? 0 : 1  }).then(res => {
                    record.status = record.status == 1 ? 0 : 1;
                    this.$message.success(this.$t('success'));
                    this.handleSearch(1);
                }).catch(e => {
                    this.$message.error(e.message);
                }).finally(res => {
                    this.loading = false;
                })
            },
            createGroup (mode, user = {}) {
                this.changeModal(true);
                this.basicForm = {
                    ...this.basicForm,
                    groupId: this.groupId,
                }
                this.modalTitle = mode == 'add' ? this.$t('add') : this.$t('edit')
            },
            deleteCurrent (row) {
                console.log(99, row)
                this.$confirm({
                    title: this.$t('platformCollection.delete_current_user'),
                    content: this.$t('platformCollection.delete_the_current_user'),
                    onOk: () => {
                        this.loading = true;
                        deletePlatformCollectionGroupUserList({ id: row.id }).then(res => {
                            this.handleSearch(1);
                        }).catch(e => {
                            this.loading = false;
                            this.$message.error(e.message);
                        });
                    }
                });
            },
            submitUser () {
                this.changeModal(false);
                this.loading = true;
                const form = {
                    ...this.basicForm,
                };

                addPlatformCollectionGroupUserList(form).then(res => {
                    this.loading = false;
                    this.$message.success(this.$t('success'));
                    this.handleSearch(1);
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                })
            },
            handleSearch (pageNum) {
                if(pageNum) { this.form.pageNum = pageNum; }
                this.loading = true;
                getPlatformCollectionGroupUserList(this.form).then(res => {
                    this.loading = false;
                    this.list = res && res.data && res.data.rows;
                    this.total = res && res.data && res.data.total;
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            },
        }
    }
</script>