<template>
  <a-card :bordered="false">
    <div :bordered='false'>
      <div class='table-page-search-wrapper'>
        <a-form layout='inline' @keyup.enter.native='searchQuery'>
          <a-row :gutter="24">
            <a-col :md='6' :sm='8'>
              <a-form-item label="配置key">
                <a-input
                  placeholder="模糊查找" v-model="queryParam.configKey"/>
              </a-form-item>
            </a-col>
            <a-col :md='6' :sm='8'>
              <a-form-item label="配置名称">
                <a-input
                  placeholder="模糊查找" v-model="queryParam.configName"/>
              </a-form-item>
            </a-col>
            <a-col :md='6' :sm='8'>
              <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
                <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
                <a-button style="margin-left: 10px" type="primary" @click="addConfig">新增</a-button>
                <a-popover title="取值描述（具体需要与后端沟通）">
                  <template #content>
                    <p>盘号：优先取对应盘号配置，没有，就取-1的盘号配置</p>
                    <p>语言：优先取对应语言配置，没有，就取-1的语言配置</p>
                  </template>
                  <a-button style="margin-left: 10px">功能说明</a-button>
                </a-popover>
              </span>
            </a-col>
          </a-row>
        </a-form>


        <a-table
          ref='table'
          size='middle'
          rowKey="id"
          :columns='columns'
          :dataSource='dataSource'
          :pagination='false'
          :loading='loading'
          bordered>
            <span slot="inputText" slot-scope="text, record">
              <a-textarea
                :placeholder="record.configComment"
                v-if="record.configType===0"
                v-model:value="record.configValue"
                :maxlength="2000"
                showCount
                autosize/>
              <a-input-number v-if="record.configType===5" v-model:value="record.configValue" style="width:100%" />
              <a-radio-group v-if="record.configType===10" button-style="solid" v-model:value="record.configValue">
                <a-radio-button v-for="expression in record.configTypeExpressionList" :value="expression.v">{{ expression.n }}</a-radio-button>
              </a-radio-group>

              <a-checkbox-group v-if="record.configType===20" v-model:value="record.configValue" @change="checkBoxChange">
                <a-checkbox v-for="expression in record.configTypeExpressionList" :value="expression.v">{{ expression.n }}</a-checkbox>
              </a-checkbox-group>

            </span>
            <spen slot="action" slot-scope="text, record">
              <a-button style="margin-left: 10px" type="primary" @click="updateConfigValue(record)">保存</a-button>
              <a  style="margin-left: 10px" @click="updateConfig(record)">
                修改
              </a>
            </spen>

        </a-table>
      </div>
    </div>
    <sys-config-modal ref="sysConfigModal" @ok="modalFormOk" ></sys-config-modal>
  </a-card>
</template>

<script>
import {getSysConfigList, editSysConfigValue} from '@/webpublicapi/importPayoutOrder';
import sysConfigModal from "./modules/SysConfigModal";

export default {
  name: 'sysConfigCenter',
  components: {sysConfigModal},
  data() {
    return {
      visible: false,
      form: {
      },
      dataSource: [],
      // 查询条件
      queryParam: {
      },
      loading: false,
      columns: [
        {
          title: '配置名称',
          align: 'left',
          dataIndex: 'configName'
        },
        {
          title: '盘号',
          align: 'left',
          dataIndex: 'appSsid'
        },
        {
          title: '语言',
          align: 'left',
          dataIndex: 'languageMark'
        },
        {
          title: '配置key',
          align: 'left',
          dataIndex: 'configKey'
        },
        {
          title: '注释',
          align: 'left',
          dataIndex: 'configComment'
        },
        {
          title: 'inputText',
          align: 'left',
          dataIndex: 'inputText',
          // width: 800,
          scopedSlots: { customRender: 'inputText' }
        },
        {
          title: 'action',
          align: 'left',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  computed: {
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    checkBoxChange(checkedValue) {
      console.log(checkedValue)
    },
    loadData() {

      this.loading = true

      getSysConfigList(Object.assign({}, this.queryParam)).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data;
          for (let i = 0; i < this.dataSource.length; i++) {
            if (this.dataSource[i].configType===20 && this.dataSource[i].configValue) {
              this.dataSource[i].configValue = JSON.parse(this.dataSource[i].configValue);
            }
          }
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },
    addConfig() {
      this.$refs.sysConfigModal.add();
      this.$refs.sysConfigModal.title = '新增配置';
    },
    updateConfig(record) {
      this.$refs.sysConfigModal.edit(record);
      this.$refs.sysConfigModal.title = '修改配置';
    },
    modalFormOk () {
      this.loadData();
    },
    updateConfigValue(record) {
      let obj = editSysConfigValue(record);
      obj.then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.$emit('ok')
        } else {
          this.$message.warning(res.msg)
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    }

  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
