<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="16" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'teleMarketUser')">
              <template>
                <a-select
                  v-model:value="queryParam.user"
                  show-search
                  :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'teleMarketUser')"
                  style="width: 100%"
                  mode="multiple"
                  :allowClear='allowClear'
                >
                  <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                  <a-select-option v-for="item in teleMarketUsers" :value="item.value">
                    {{item.label }}
                  </a-select-option>
                </a-select>
              </template>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'loanType')">
              <a-select v-model="queryParam.loanType" :placeholder="$t('select')" style="width: 100%" mode="multiple" :allowClear='allowClear'>
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in loanTypes' :key='item.label'>
                  <!--                  {{$t(I18N_KEY + 'loanType_' + item.label)}}-->
                  {{item[fieldName]}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :md="16" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'type')">
              <a-select v-model="queryParam.type" :placeholder="$t('select')" style="width: 100%" mode="multiple" :allowClear='allowClear'>
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in types' :key='item.value'>
                  <!--                  {{$t(I18N_KEY + 'type_' + item.label)}}-->
                  {{item[fieldName]}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'days')">
              <a-select v-model="queryParam.days" :placeholder="$t('select')" @change="handleDaysChange(queryParam.days)" style="width: 100%;">
                <a-select-option v-for="item in days" :value="item">
                  {{$t(I18N_KEY + 'days_' + item)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :md="8" :sm="12">
              <a-form-item :label="$t(I18N_KEY + 'createdTime')">
                <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
        <a-row :gutter='24'>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="search" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 5000 }"
        bordered>
      </a-table>
    </div>
  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import { userReport, baseData } from '@/webpublicapi/teleMarket'
import moment from 'moment';
import vModal from '../modules/Modal';
import {mapGetters} from "vuex";
import exportFile from "@/webpublicapi/exportFile";
const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketUserReport',
  components: {
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      toggleSearchStatus1:false,
      teleMarketUsers: [],
      days: ['today','yesterday','last3days','last7days','last30days','other'],
      allowClear: true,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      loanTypes: [],
      types: [],
      // 查询条件
      queryParam: {
        "user": [],
        "createdTime": undefined,
        "days": '',
        "loanType": [],
        "type": []
      },
      loading: false,
      fieldName: 'textCn',
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },

  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'teleMarketUser'),
          align: 'center',
          dataIndex: 'user',
          width: '120',
          customRender: (text, record) => {
            var text = text;
            return this.getUserLabel(text);
          }
        },
        {
          title: this.$t(I18N_KEY + 'countPre'),
          align: 'center',
          width: '100',
          dataIndex: 'assignNum'
        },
        {
          title: this.$t(I18N_KEY + 'callNum'),
          align: 'center',
          width: '100',
          dataIndex: 'callNum'
        },
        {
          title: this.$t(I18N_KEY + 'callRate'),
          align: 'center',
          width: '100',
          dataIndex: 'callRate'
        },
        {
          title: this.$t(I18N_KEY + 'connectNum'),
          align: 'center',
          width: '100',
          dataIndex: 'connectNum'
        },
        {
          title: this.$t(I18N_KEY + 'connectRate'),
          align: 'center',
          width: '100',
          dataIndex: 'connectRate'
        },
        {
          title: this.$t(I18N_KEY + 'convertNumT0'),
          align: 'center',
          width: '100',
          dataIndex: 't0ConvertNum'
        },
        {
          title: this.$t(I18N_KEY + 'convertRateT0'),
          align: 'center',
          width: '100',
          dataIndex: 't0ConvertRate',
          // scopedSlots: {customRender: 'convertRate'}
        },
        {
          title: this.$t(I18N_KEY + 'passNumT0'),
          align: 'center',
          width: '100',
          dataIndex: 't0PassNum',
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'passRateT0'),
          align: 'center',
          width: '100',
          dataIndex: 't0PassRate',
          // scopedSlots: {customRender: 'convertRate'}
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },

        {
          title: this.$t(I18N_KEY + 'convertNumT1'),
          align: 'center',
          width: '100',
          dataIndex: 't1ConvertNum'
        },
        {
          title: this.$t(I18N_KEY + 'convertRateT1'),
          align: 'center',
          width: '100',
          dataIndex: 't1ConvertRate',
          // scopedSlots: {customRender: 'convertRate'}
        },
        {
          title: this.$t(I18N_KEY + 'passNumT1'),
          align: 'center',
          width: '100',
          dataIndex: 't1PassNum',
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'passRateT1'),
          align: 'center',
          width: '100',
          dataIndex: 't1PassRate',
          // scopedSlots: {customRender: 'convertRate'}
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },

        {
          title: this.$t(I18N_KEY + 'convertNumT2'),
          align: 'center',
          width: '100',
          dataIndex: 't2ConvertNum'
        },
        {
          title: this.$t(I18N_KEY + 'convertRateT2'),
          align: 'center',
          width: '100',
          dataIndex: 't2ConvertRate',
          // scopedSlots: {customRender: 'convertRate'}
        },
        {
          title: this.$t(I18N_KEY + 'passNumT2'),
          align: 'center',
          width: '100',
          dataIndex: 't2PassNum',
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'passRateT2'),
          align: 'center',
          width: '100',
          dataIndex: 't2PassRate',
          // scopedSlots: {customRender: 'convertRate'}
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },

        {
          title: this.$t(I18N_KEY + 'convertNumT3'),
          align: 'center',
          width: '100',
          dataIndex: 't3ConvertNum'
        },
        {
          title: this.$t(I18N_KEY + 'convertRateT3'),
          align: 'center',
          width: '100',
          dataIndex: 't3ConvertRate',
          // scopedSlots: {customRender: 'convertRate'}
        },
        {
          title: this.$t(I18N_KEY + 'passNumT3'),
          align: 'center',
          width: '100',
          dataIndex: 't3PassNum',
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'passRateT3'),
          align: 'center',
          width: '100',
          dataIndex: 't3PassRate',
          // scopedSlots: {customRender: 'convertRate'}
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },

        {
          title: this.$t(I18N_KEY + 'convertNumT4'),
          align: 'center',
          width: '100',
          dataIndex: 't4ConvertNum'
        },
        {
          title: this.$t(I18N_KEY + 'convertRateT4'),
          align: 'center',
          width: '100',
          dataIndex: 't4ConvertRate',
          // scopedSlots: {customRender: 'convertRate'}
        },
        {
          title: this.$t(I18N_KEY + 'passNumT4'),
          align: 'center',
          width: '100',
          dataIndex: 't4PassNum',
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'passRateT4'),
          align: 'center',
          width: '100',
          dataIndex: 't4PassRate',
          // scopedSlots: {customRender: 'convertRate'}
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },

        {
          title: this.$t(I18N_KEY + 'convertNumT5'),
          align: 'center',
          dataIndex: 't5ConvertNum'
        },
        {
          title: this.$t(I18N_KEY + 'convertRateT5'),
          align: 'center',
          width: '100',
          dataIndex: 't5ConvertRate',
          // scopedSlots: {customRender: 'convertRate'}
        },
        {
          title: this.$t(I18N_KEY + 'passNumT5'),
          align: 'center',
          width: '100',
          dataIndex: 't5PassNum',
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'passRateT5'),
          align: 'center',
          width: '100',
          dataIndex: 't5PassRate',
          // scopedSlots: {customRender: 'convertRate'}
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },

        {
          title: this.$t(I18N_KEY + 'convertNumT6'),
          align: 'center',
          width: '100',
          dataIndex: 't6ConvertNum'
        },
        {
          title: this.$t(I18N_KEY + 'convertRateT6'),
          align: 'center',
          width: '100',
          dataIndex: 't6ConvertRate',
          // scopedSlots: {customRender: 'convertRate'}
        },
        {
          title: this.$t(I18N_KEY + 'passNumT6'),
          align: 'center',
          width: '100',
          dataIndex: 't6PassNum',
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'passRateT6'),
          align: 'center',
          width: '100',
          dataIndex: 't6PassRate',
          // scopedSlots: {customRender: 'convertRate'}
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },

        {
          title: this.$t(I18N_KEY + 'convertNumT7'),
          align: 'center',
          width: '100',
          dataIndex: 't7ConvertNum'
        },
        {
          title: this.$t(I18N_KEY + 'convertRateT7'),
          align: 'center',
          width: '100',
          dataIndex: 't7ConvertRate',
          // scopedSlots: {customRender: 'convertRate'}
        },
        {
          title: this.$t(I18N_KEY + 'passNumT7'),
          align: 'center',
          width: '100',
          dataIndex: 't7PassNum',
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'passRateT7'),
          align: 'center',
          width: '100',
          dataIndex: 't7PassRate',
          // scopedSlots: {customRender: 'convertRate'}
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
      ]
    },
  },
  created () {
    this.loadBaseData();
    // this.loadData(1);
  },
  methods: {
    searchQuery () {
      this.loadData()
    },
    loadBaseData() {
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.teleMarketUsers = res.data.seats;
          this.types = res.data.types;
          this.loanTypes = res.data.loanTypes;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    loadData() {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.form.pageNum = 1
      // }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      userReport(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          // this.total = res.data.total
          this.dataSource = res.data
        } else {
          // this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    getUserLabel(user) {
      for(var item in this.teleMarketUsers) {
        var userObj = this.teleMarketUsers[item];
        if(userObj.value === user){
          return userObj.label;
        }
      }
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      var param = Object.assign({}, data)
      // param.pageNum = this.form.pageNum
      // param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    // handleTableChange(pageNum, pageSize) {
    //   this.form.pageNum = pageNum;
    //   this.form.pageSize = pageSize;
    //   this.loadData();
    // },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.user = []
      that.queryParam.createdTime = undefined
      that.queryParam.days = '';
      that.queryParam.type = [];
      that.queryParam.loanType = [];

      this.toggleSearchStatus = false;
      this.toggleSearchStatus1 = false;
    },
    changeModal(visible) {
      this.visible = visible;
    },
    handleDetail(row) {
      this.orderId = row.id;
      this.changeModal(true);
    },
    handleDaysChange(select) {
      if (select === 'other') {
        this.toggleSearchStatus = true;
      } else {
        this.toggleSearchStatus = false;
      }
    },
    checkParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return '';
      }
      return p;
    },
    checkArrayParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return [];
      }
      return p;
    },
    checkType(type) {
      if(type == '8' || type == '9' || type == '23' || type == '24' || type == '15' || type == '16' || type == '25' || type == '26') {
        return true
      }
      return false;
    },
    downLoadData () {
      const params = this.getQueryParams()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/telemarket/report/userReport/export?startDate=' + this.checkParam(params.startDate) + '&endDate=' + this.checkParam(params.endDate) + '&user=' + this.checkArrayParam(params.user) + '&type=' + this.checkArrayParam(params.type) + '&loanType=' + this.checkArrayParam(params.loanType) + '&days=' + this.checkParam(params.days),
        fileName: 'teleMarketUserReport.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>