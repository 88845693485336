<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="8" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'createTime')">
              <a-date-picker v-model="queryParam.createTime" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="loadData" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="resetForm" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :customRow="customRow"
        :scroll="{ x: 1300}"
        bordered>
        <template #item="text,record,index">{{$t(I18N_KEY + 'tag_' + text)}}</template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { modelTagWeekReport} from '@/webpublicapi/custServiceWorkOrder';
import {mapGetters} from "vuex";
import exportFile from '@/webpublicapi/exportFile'
import moment from 'moment';
const I18N_KEY = 'custServiceWorkOrder.';
// const indexs1 = [0,2,8,11,13,15,22,25,28,32,38,41,43,45,52,55,58];
// const indexs2 = [61,62,63,64,65,66,67,68];

export default {
  name: 'WorkOrderWeekReport',
  data () {
    return {
      I18N_KEY,
      dataSource: [],
      queryParam: {
        createTime: '',
      },
      // fieldName: 'keyCnText',
      bordered: true,
      lastWeekDateLabel: '',
      lastTwoWeekDateLabel: '',
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const { createTime } = this.queryParam.createTime;
      const data = {
        createTime: createTime && this.transformTime(createTime),
      }
      modelTagWeekReport(this.queryParam).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data.list;
          this.lastWeekDateLabel = res.data.lastWeekDateLabel;
          this.lastTwoWeekDateLabel = res.data.lastTwoWeekDateLabel;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    contains(sources, target) {
      for(var item in sources) {
        if(sources[item] === target) {
          return true;
        }
      }
      return false;
    },
    resetForm() {
      this.queryParam.createTime = ''
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    getColor(index, preColor) {
      // if(index === 31) {
      //   return "#00FFFF";
      // }
      if(index === 0) {
        return '#F4B084';
      }
      // if(this.contains(indexs2, index)) {
      //   return '#FFFF00';
      // }
      // if(this.contains(indexs1, index)) {
      //   return '#F4B084';
      // }
      return preColor;
    },
    getFont(index, preFont) {
      // if(index === 31 || index === 1 || this.contains(indexs2, index) || this.contains(indexs1, index)) {
      //   return "bolder";
      // }
      if(index === 0) {
        return "bolder";
      }
      return preFont;
    },
    customRow(record, index) {
      return {
        style: {
          'background-color':this.getColor(index, record.value1.bgColor),
          'font-weight':this.getFont(index, 'unset'),
        }
      }
    },
    downLoadData () {
      var createTime = this.queryParam.createTime;
      createTime = createTime && this.transformTime(createTime)
      const file = {
        fileUrl: '/api/web/webpublic/custService/report/modelTagWeekReport/export?createTime='+createTime,
        fileName: 'CustomerServiceModelTagWeekReport.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'item'),// 项目
          align: 'left',
          width: 400,
          dataIndex: 'fieldName',
          scopedSlots: {customRender: 'item'}
        },
        {
          title: this.lastTwoWeekDateLabel,// 值
          align: 'left',
          width: 100,
          dataIndex: 'value1',
          // customRender: (text, record) => {
          //   var text = text;
          //   return text.substring(0, text.indexOf('('))
          // }
        },
        {
          title: this.lastWeekDateLabel,// 值
          align: 'left',
          width: 100,
          dataIndex: 'value2'
        },
        {
          title: this.$t(I18N_KEY + 'proportion'),// 占比
          align: 'left',
          width: 100,
          dataIndex: 'value3'
        },
        {
          title: this.$t(I18N_KEY + 'rate'),// 比例
          align: 'left',
          width: 100,
          dataIndex: 'rate'
        }
      ]
    }
  },
  // watch: {
  //   languageStorage: {
  //     handler (lang) {
  //       if (lang == 'en-US') {
  //         this.fieldName = 'keyEnText';
  //       } else if (lang == 'zh-CN') {
  //         this.fieldName = 'keyCnText';
  //       } else if (lang == 'es') {
  //         this.fieldName = 'keyEsText';
  //       }
  //     },
  //     immediate: true,
  //   }
  // },
}
</script>