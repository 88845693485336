<template>
  <div>
    <a-spin :spinning='loading'>
      <a-form layout='inline' @keyup.enter.native='loadData'>
        <a-col :span="12">
        <a-form-item :label="'日期'">
          <a-range-picker
            v-model='queryParam.date'
            :disabled-date='disabledDate'
            @calendarChange='handleChange'
            @blur='handleBlur'
            format='YYYY-MM-DD'
            style='width: 100%'
          />
        </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item :label="$t(I18N_KEY.ORDER + 'custType')">
            <a-select default-value="" style="width: 120px" v-model="queryParam.custType">
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option value="1">
                新户
              </a-select-option>
              <a-select-option value="2">
                老户
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="'订单类型'">
            <a-select default-value="" style="width: 120px" v-model="queryParam.orderType">
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option value="1">
                展期订单
              </a-select-option>
              <a-select-option value="0">
                正常订单
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label='统计纬度'>
            <a-select style='width: 190px' v-model='statisticalDimension' @change='onStatisticalDimensionChanged'>
              <a-select-option key='backRate'>案件个数</a-select-option>
              <a-select-option key='backAmtRate'>案件金额</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="'产品类型'">
            <a-select
              style="width: 300px"
              mode="multiple"
              v-model="queryParam.appssids"
            >
              <a-select-option v-for="item in appList" :key="item.appssid">
                {{ item.appName }}
              </a-select-option>

            </a-select>
          </a-form-item>
        </a-col>

        <a-form-item class='table-page-search-submitButtons'>
          <a-button type='primary' @click='loadData' icon='search'>{{ $t('search') }}</a-button>
        </a-form-item>

      </a-form>
    </a-spin>
    <div id='container'></div>
    <div class='tableBox' v-show='tableShow'>
      <div class='buttonRow'>
        <a-button type='primary' @click='downLoadData()'>导出</a-button>
      </div>
      <a-table
        id='tablex'
        :columns='tablecolums'
        :data-source='tableData'
        bordered
        :scroll='{ x: 2000, y: 300 }'
        :pagination='false'
      ></a-table>
    </div>
  </div>
</template>
<script>
import G2 from '@antv/g2'
// import echarts from "@/utils/echarts"
import { collectionCountByHour, orderCountByHour } from '@/webpublicapi/collection'
import {getApplist} from '@/webpublicapi/order'
import moment from 'moment'
import { filterObj } from '@/utils/util'
import exportFile from '@/webpublicapi/exportFile'

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.'
}
export default {
  data() {
    return {
      I18N_KEY,
      loading: false,
      statisticalDimension: 'backRate',
      queryParam: {
        date: [moment().add('days', -7), moment().add('days', -1)],
        "appssids":undefined,
      },
      selected: null,
      chart: null,
      appList: [],
      tableData: [
        {
          key: 'yesterday',
          name: 'yesterday'
        },
        {
          key: 'today',
          name: 'today'
        },

        {
          key: 'three',
          name: 'three'
        }
      ],
      tablecolums: [
        {
          title: '催收阶段-部门-组-人',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          fixed: 'left'
        }
      ],
      obj: {},
      tableShow: false
    }
  },
  created() {
    getApplist().then(res => {
      this.appList = res.data || [];
    });
  },
  methods: {
    downLoadData() {
      var params = this.getQueryParams()// 查询条件
      this. handleAppssids(params)
      params['fileName'] = 'downLoadorderCountByHourExcel'
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value) + '';
      const date = params.date
      const file = {
        fileUrl: `/api/web/webpublic/collectionReplaceTask/downLoadorderCountByHour?`+ this.exportParams.replaceAll(",", "&"),
        fileName: 'downLoadorderCountByHourExcel.xls'
      }
      this.loading = true
      exportFile(file)
        .catch((e) => {
          this.$message.error(e.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getQueryParams () {
      const { date } = this.queryParam;
      const data = {
        ...this.queryParam,
        dateStart: date && this.transformTime(date[0]),
        dateEnd: date && this.transformTime(date[1]),
        // orderType: arg3,
      }
      delete data.date;
      return filterObj(data)
    },
    handleAppssids(params) {
      var appssidsValue = params['appssids'];
      if(appssidsValue != null && appssidsValue != '' && appssidsValue != undefined && appssidsValue.length > 0) {
        var apps = appssidsValue[0];
        for(var item in appssidsValue) {
          if(item != '0') {
            apps = apps + '_' + appssidsValue[item];
          }
        }
        params['appssids'] = apps;
      }
      return params;
    },
    onStatisticalDimensionChanged() {
      this.renderChart()
      this.tableMethod()
    },
    renderChart() {
      if (!this.chartData) return
      if (!this.chart) {
        this.chart = new G2.Chart({
          container: 'container',
          forceFit: true,
          height: 500,
          padding: [32, 32, 32, 32]
        })
      } else {
        this.chart.clear()
      }
      const chart = this.chart
      chart.scale({
        curdate: {
          alias: '时段',
          sync: true
        },
        ...(this.statisticalDimension === 'backRate' ? {
          backRate: {
            alias: '案件催回率',
            sync: true,
            formatter: (i) => `${i}%`
          }
        } : {
          backAmtRate: {
            alias: '金额催回率',
            sync: true,
            formatter: (i) => `${i}%`
          }
        })
      })
      this.chartData.forEach((item, index) => {
        Object.keys(item).forEach((key, _index) => {
          const list = new Array(24).fill(null).map((x, i) => {
            return {
              curHour: `${i < 9 ? '0' : ''}${i + 1}`,
              curdate: key
            }
          })
          item[key].forEach((item) => {
            item['curdate'] = key
            ;(item['curHour'] = `${Number(item['curHour']) < 10 ? '0' : ''}${Number(item['curHour'])}`),
              (list[Number(item['curHour']) - 1] = item)
          })
          const view = chart.view()
          !!index && view.axis(false)
          view.source(list)
          view
            .line()
            .position('curHour*' + this.statisticalDimension)
            .shape('dv')
            .color(['red', 'green', 'blue'][_index % 3])
            .tooltip(`curdate*${this.statisticalDimension}*curHour`, (curdate, statisticalDimension, curHour) => {
              return {
                // axis:curHour,
                title: 'hour:' + curHour,
                name: curdate === 'three' ? 'customize' : curdate,
                value: statisticalDimension
              }
            })
        })
      }, [])
      chart.render()
    },
    tableMethod() {
      if (!this.chartData) return
      for (let i = 0; i < 24; i++) {
        if (!this.chartData[0].today[i]) {
          let obj = {
            curHour: i + 1,
            backRate: '-',
            backAmtRate: '-'
          }
          this.chartData[0].today[i] = obj
        }
      }
      ;(this.tablecolums = [
        {
          title: '催收阶段-部门-组-人',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          fixed: 'left'
        }
      ]),
        (this.tableData = [
          {
            key: 'yesterday',
            name: 'yesterday'
          },
          {
            key: 'today',
            name: 'today'
          },

          {
            key: 'three',
            name: 'customize'
          }
        ]),
        this.chartData.forEach((item, index) => {
          item.yesterday.forEach((item2, index2) => {
            let obj = {
              title: item2.curHour,
              dataIndex: item2.curHour,
              key: item2.curHour,
              width: 100
            }
            this.tablecolums.push(obj)
            this.tableData[0][item2.curHour] = item2[this.statisticalDimension] + '%'
          })
          item.today.forEach((item3) => {
            this.tableData[1][item3.curHour] = item3[this.statisticalDimension] + '%'
          })
          item.three.forEach((item4) => {
            this.tableData[2][item4.curHour] = item4[this.statisticalDimension] + '%'
          })
        })
    },
    handleChange([start, end]) {
      if (start && !end) {
        this.selected = start
      } else {
        this.selected = null
      }
    },
    handleBlur() {
      this.selected = null
    },
    disabledDate(current) {
      if (!this.selected) return false
      return current.diff(this.selected, 'days') > 6 || current.diff(this.selected, 'days') < -6
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    loadData() {
      const date = this.queryParam.date
      if (!date[0] || !date[0]) return this.$message.warning('请选择起止日期')
      this.loading = true


      orderCountByHour({
        dateStart: this.transformTime(date[0]),
        dateEnd: this.transformTime(date[1]),
        appssids: this.queryParam.appssids,
        custType: this.queryParam.custType,
        orderType: this.queryParam.orderType,

      })
        .then((res) => {
          this.chartData = res.data
          this.renderChart()
          this.tableShow = true
          this.tableMethod()
          this.loading = false
        })
        .catch((_) => (this.loading = false))
    }
  }
}
</script>
