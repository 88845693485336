<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="产品名称">
          <a-input placeholder="产品名称" v-decorator="['productName', validatorRules.productName]" />
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="产品类型">
          <a-select showSearch v-decorator="['appssid', validatorRules.appssid]">
            <a-select-option v-for="item in appList" :key="item.appssid">
              {{ item.appName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="前收后收">
          <a-select v-decorator="['receiptTimeFlag', validatorRules.receiptTimeFlag]">
            <a-select-option :key="'0'">前收</a-select-option>
            <a-select-option :key="'1'">后收</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="状态">
          <a-select v-decorator="['status', validatorRules.status]">
            <a-select-option :key="'0'">失效</a-select-option>
            <a-select-option :key="'1'">有效</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { addProduct, editProduct } from '@/webpublicapi/installmentProductManager'
import { getApplist } from '@/webpublicapi/order'
// duplicateCheck
export default {
  name: 'installmentProductModal',

  data() {
    return {
      value: 1,
      title: '操作',
      type: '0',
      allowPrepay: 1,
      productId: '',
      visibleCheck: true,
      visible: false,
      model: {},
      appList: [],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        productName: { rules: [{ required: true, message: '请输入产品名称' }] },
        receiptTimeFlag: { rules: [{ required: true, message: '请选择前收后收' }] },
        status: { rules: [{ required: true, message: '请选择状态' }] },
        appssid: { rules: [{ required: true, message: '请选择产品类型' }] },
      },
    }
  },
  created() {},
  methods: {
    add(params) {
      this.edit({ type: params.type })
    },
    edit(record) {
      this.type = record.type
      getApplist().then((res) => {
        this.appList = res.data || []
      })
      this.form.resetFields()
      this.model = Object.assign({}, record)
      if (record.id) {
        this.allowPrepay = record.allowPrepay
        this.visibleCheck = record.allowPrepay == 1
      }
      this.visible = true
      const data = pick(this.model, 'productName', 'appssid', 'receiptTimeFlag', 'status', 'type')
      data.allowPrepay = !!data.allowPrepay
      this.$nextTick(() => {
        this.form.setFieldsValue(data)
      })
    },
    onChose(checked) {
      if (checked) {
        this.allowPrepay = 1
        this.visibleCheck = true
      } else {
        this.allowPrepay = 0
        this.visibleCheck = false
      }
    },
    // 确定
    handleOk() {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          formData.allowPrepay = +formData.allowPrepay ? 1 : 0
          formData.type = that.type
          let obj
          if (!that.model.id) {
            formData.productId = this.productId
            obj = addProduct(formData)
          } else {
            obj = editProduct(formData)
          }
          obj
            .then((res) => {
              if (res.code === 1000) {
                that.$message.success(res.msg)
                that.$emit('ok')
              } else {
                that.$message.warning(res.msg)
              }
            })
            .finally(() => {
              that.confirmLoading = false
              that.close()
            })
        }
      })
    },
    // 关闭
    handleCancel() {
      this.close()
    },
    close() {
      this.$emit('close')
      this.visible = false
    },
  },
}
</script>
