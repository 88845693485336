import { render, staticRenderFns } from "./CollectionReductionTask.vue?vue&type=template&id=3e6f4d58&scoped=true&"
import script from "./CollectionReductionTask.vue?vue&type=script&lang=js&"
export * from "./CollectionReductionTask.vue?vue&type=script&lang=js&"
import style0 from "./CollectionReductionTask.vue?vue&type=style&index=0&id=3e6f4d58&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e6f4d58",
  null
  
)

export default component.exports