<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter="24">
          <a-col  :md='6' :sm='8'>
            <a-form-item label="放款日期" >
              <a-range-picker
                v-model="queryParam.time"
                format="YYYY-MM-DD"
                style="width: 200px;"/>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item label="支付方式">
              <a-select style='width: 190px' allowClear v-model='queryParam.payChannel' @change="payChannelChange">
                <a-select-option v-for="item in payChannelRes" :value="item.payChannel">{{item.payChannel}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset') }}</a-button>
              <a-upload
                name="file"
                :multiple="true"
                :action="'/api/web/webpublic/platform/import/importingCommon'"
                :headers="getHeaders()"
                :data="uploadDataParam"
                @change="handleChangeUpload"
              >
                <a-button style='margin-left: 8px'> <a-icon type="upload" /> Click to Upload </a-button>
              </a-upload>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='false'
        :loading='loading'
        bordered
        @change='handleTableChange'>
      </a-table>
      <div style='margin-top: 15px; text-align: right;'>
        <v-pagination
          v-if='total'
          :page-num='form.pageNum'
          :page-size='form.pageSize'
          :total='total'
          @change='handleTableChange' />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getPayoutList } from '@/webpublicapi/importPayoutOrder'
import { getHeaders } from '@/utils/request'
import moment from 'moment'

const I18N_KEY = {
  OASYSTEM: 'oaSystem.',
  FOLLOW: 'importPayoutOrder.'
}
export default {
  name: 'importPayoutOrder',
  components: {},
  data() {
    return {
      visdialog: false,
      I18N_KEY,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      dataSourceTotal: [],
      departments: [],
      groupAll: [],
      normalConfigList: [],
      // 查询条件
      queryParam: {
        time: [],
        payChannel:'',
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
      uploadDataParam:{},
      payChannelRes: [
        {
          payChannel:'monnet',
          data:{payOutIdIndex:4,statusIndex:25,sheetIndex:0,payOutTimeIndex:22,payChannel:'monnet',successMarkMsg:'BANCO_EXITO',failedMarkMsg:'BANCO_RECHAZO'}
        }
      ]
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '订单id',
          align: 'left',
          dataIndex: 'orderInfoId'
        },
        {
          title:  '放款id',
          align: 'center',
          dataIndex: 'payOutId'
        },
        {
          title:  '支付方式',
          align: 'center',
          dataIndex: 'payChannel'
        },
        {
          title:  '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: function (text) {
            if (text==='00') {
              return "初始化"
            } else if (text === '01') {
              return "成功"
            } else if (text === '02') {
              return "失败"
            } else if (text === '03') {
              return "放款中"
            } else if (text === '999') {
              return "系统内部异常"
            }
          }
        },
        {
          title:  '创建时间',
          align: 'center',
          dataIndex: 'createdTime'
        },
        {
          title:  '更新时间',
          align: 'center',
          dataIndex: 'updatedTime'
        }

      ]
    }
  },
  created() {
    this.getTime();
    this.loadData(1)
  },
  methods: {
    payChannelChange(value) {
      var isIn = false;
      for (const payChannelInfo of this.payChannelRes) {
        if (payChannelInfo.payChannel === value) {
            this.uploadDataParam = payChannelInfo.data;
            isIn = true;
        }
      }
      if (!isIn) {
        this.uploadDataParam = {};
      }
    },
    getTime() {
      this.queryParam.time = [];
      var nowDate = new Date();
      this.queryParam.time[0]=moment(nowDate.setDate(nowDate.getDate() - 7));
      this.queryParam.time[1]=moment(new Date());

    },
    getHeaders() {
      return getHeaders();
    },
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {
      var params = this.getQueryParams()// 查询条件
      if (!params.beginTime) {
        this.$message.error("放款日期不能为空");
        return;
      }
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true

      getPayoutList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },

    getQueryParams() {
      var param = this.queryParam;
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      param.beginTime = (param.time && param.time.length) ? this.transformTime(param.time[0]) : undefined;
      param.endTime = (param.time && param.time.length) ? this.transformTime(param.time[1]) : undefined;

      return filterObj(param)
    },
    transformTime (time) {
      return time.format('YYYY-MM-DD')
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置搜索框的内容
    searchReset() {
      this.getTime();
    },
    handleChangeUpload(info) {
      //debugger;
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        if(info.file.response.code === 1000){
          //alert('handle success!');
          this.$message.success(`${info.file.name} file uploaded successfully`);
        } else {
          alert(info.file.response.msg);
        }
      } else if (info.file.status === 'error') {
        //this.$message.error(`${info.file.name} file upload failed.`);
        alert('system error!');
      }
    }
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
