<template>
  <a-drawer
    :title="title"
    :maskClosable="true"
    width=650
    placement="right"
    :closable="true"
    @ok="handleOk"
    @close="close"
    :visible="visible"
    style="height: calc(100% - 55px);overflow: auto;padding-bottom: 53px;">


      <a-spin :spinning="confirmLoading" > 
        <a-form>
          <a-form-item label='所拥有的权限'>
            <a-tree
              checkable
              :treeData="menuTree"
              @expand="onExpand"
              @select="onTreeNodeSelect"
              @check="onCheck"
              v-model="checkedKeys"
              :selectedKeys="selectedKeys"
              :expandedKeys="expandedKeysss"
              :checkStrictly="false">
              <span slot="hasDatarule" slot-scope="{slotTitle,ruleFlag}">
                {{ slotTitle }}<a-icon v-if="ruleFlag" type="align-left" style="margin-left:5px;color: red;"></a-icon>
              </span>
            </a-tree>
          </a-form-item>
        </a-form>
      </a-spin>


      <div class="drawer-bootom-button">
        <!-- <a-dropdown style="float: left" :trigger="['click']" placement="topCenter">
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="switchCheckStrictly(1)">父子关联</a-menu-item>
            <a-menu-item key="2" @click="switchCheckStrictly(2)">取消关联</a-menu-item>
            <a-menu-item key="3" @click="checkALL">全部勾选</a-menu-item>
            <a-menu-item key="4" @click="cancelCheckALL">取消全选</a-menu-item>
            <a-menu-item key="5" @click="expandAll">展开所有</a-menu-item>
            <a-menu-item key="6" @click="closeAll">合并所有</a-menu-item>
          </a-menu>
          <a-button>
            树操作 <a-icon type="up" />
          </a-button>
        </a-dropdown> -->
        <a-popconfirm title="确定放弃编辑？" @confirm="close" okText="确定" cancelText="取消">
          <a-button style="margin-right: .8rem">取消</a-button>
        </a-popconfirm>
        <a-button @click="handleOk" type="primary" :loading="loading">提交</a-button>
      </div>
    <!-- <role-datarule-modal ref="datarule"></role-datarule-modal> -->

  </a-drawer>

</template>
<script>
  // import RoleDataruleModal from './RoleDataruleModal.vue'

  export default {
    name: "RoleModal",
    components:{
      // RoleDataruleModal
    },
    props: {
      menuTree: {
        default: Array,
        default: [],
      }
    },
    data(){
      return {
        roleId:"",
        treeData: [],
        defaultCheckedKeys:[],
        checkedKeys:[],
        halfCheckedKeys: [],
        expandedKeysss:[],
        allTreeKeys:[],
        autoExpandParent: true,
        checkStrictly: true,
        title:"角色权限配置",
        visible: false,
        loading: false,
        confirmLoading: false,
        selectedKeys:[]
      }
    },
    methods: {
      onTreeNodeSelect(id){
        // if(id && id.length>0){
        //   this.selectedKeys = id
        // }
        // this.$refs.datarule.show(this.selectedKeys[0],this.roleId)
      },
      onCheck (o, e) {
        this.halfCheckedKeys = e.halfCheckedKeys;
        // if(this.checkStrictly){
        //   this.checkedKeys = o.checked;
        // }else{
        //   this.checkedKeys = o
        // }
      },
      show(roleId){
        this.roleId = roleId
        this.open()
      },
      openLoading () {
          this.confirmLoading = true;
      },
      closeLoading () {
        this.confirmLoading = false;
      },
      open () {
        this.visible = true;
      },
      close () {
        this.reset()
        this.$emit('close');
        this.visible = false;
      },
      setChecked (menuList, roleIds) {
          const parentIds = menuList.map(item => item.id);
          this.checkedKeys = roleIds.filter(item => !parentIds.includes(item)).map(String);
          this.halfCheckedKeys = roleIds.filter(item => parentIds.includes(item)).map(String);
          this.expandedKeysss = roleIds.map(String);
      },
      onExpand(expandedKeys){
        this.expandedKeysss = expandedKeys;
        this.autoExpandParent = false
      },
      reset () {
        this.expandedKeysss = []
        this.checkedKeys = []
        this.halfCheckedKeys = []
        this.defaultCheckedKeys = []
        this.loading = false
      },
      expandAll () {
        this.expandedKeysss = this.allTreeKeys
      },
      closeAll () {
        this.expandedKeysss = []
      },
      checkALL () {
        this.checkedKeys = this.allTreeKeys
      },
      cancelCheckALL () {
        //this.checkedKeys = this.defaultCheckedKeys
        this.checkedKeys = []
      },
      switchCheckStrictly (v) {
        if(v==1){
          this.checkStrictly = false
        }else if(v==2){
          this.checkStrictly = true
        }
      },
      handleCancel () {
        this.close()
      },
      handleOk () {
        const checked = [ ...this.checkedKeys, ...this.halfCheckedKeys ];
        this.$emit('ok', checked);
      },
    },
  watch: {
  }
  }

</script>
<style lang="less" scoped>
  .drawer-bootom-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 2px 2px;
  }
</style>