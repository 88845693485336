<template>
  <a-modal title='客户回复详情' :visible='visible' @cancel='hide' :footer='false'>
    <a-comment v-for='item in dataSource'
               :author="custLeaving.custName + ' ' + custLeaving.mobileNo"
               :content='item.message'
               :datetime='item.createdTime | date'
    >
      <a-comment v-if='item.repay'
                 author='you'
                 :content='item.repay'
                 :datetime='item.replyTime | date'
      />
    </a-comment>
    <a-form-item v-if='current.replyStatus === 0'>
      <a-textarea :rows='4' v-model='value' />
    </a-form-item>
    <a-form-item v-if='current.replyStatus === 0'>
      <a-button html-type='submit' :loading='submitting' type='primary' @click='submit'>
        Add Comment
      </a-button>
    </a-form-item>
  </a-modal>
</template>
<script>
import { getCustLeavingMessageDetail, updateCustLeavingMessage } from '@/api/custleaving'
import { Comment } from 'ant-design-vue'
import moment from 'moment'

export default {
  name: 'CustLeavingDetailModal',
  components: { AComment: Comment },
  data() {
    return {
      visible: false,
      custLeaving: {},
      dataSource: [],
      value: '',
      current: {},
      submitting: false
    }
  },
  filters: {
    date(value) {
      return moment(value).format('yyyy-MM-DD HH:mm:ss')
    }
  },
  methods: {
    reset() {
      this.dataSource = []
      this.value = ''
      this.submitting = false
    },
    show(record) {
      this.reset()
      console.log(record)
      this.custLeaving = { ...record }
      this.visible = true
      getCustLeavingMessageDetail(Object.assign({}, { leavingMessageId: this.custLeaving.leavingMessageId })).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
          this.current = this.dataSource[this.dataSource.length - 1]
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
    },
    hide() {
      this.visible = false
      this.reset()
    },
    submit() {
      if(this.value == null || this.value == ''){
        alert("回复不能为空！");
        return;
      }
      this.submitting = true
      updateCustLeavingMessage({ repay: this.value, id: this.current.id }).then(res => {
        if (res.code === 1000) {
          this.$message.success('success')
          this.$emit('submit')
          this.hide()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
    }
  }
}
</script>