<template>
    <div>
        <a-spin :spinning="loading">
<!--            <a-card-->
<!--                :title="$t(I18N_KEY.ORDER + 'orderInfo')"-->
<!--                type="inner"-->
<!--                style="margin-top: 24px;">-->
<!--                <detail-list>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'orderNo')" label="订单编号">{{ orderInfo.orderNo }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'firstName')" label="first name">{{ orderInfo.firstName }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'middleName')" label="middle name">{{ orderInfo.middleName }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'lastName')" label="last name">{{ orderInfo.lastName }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'applicationAmount')" label="申请金额">{{ '$'+orderInfo.applyAmt }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'applicationDeadline')" label="申请期限">{{ orderInfo.applyDuration && orderInfo.applyDuration + 'Days' }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'submissionTime')" label="提交时间">{{ orderInfo.submitTime }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'approvalStatus')" label="审批状态">-->
<!--                        <Dict-->
<!--                            :dict-code="DICT_CODE.ORDER_STATUS"-->
<!--                            :value="orderInfo.approvalStatus"/>-->
<!--                    </detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'loanStatus')" label="放款状态">-->
<!--                        <Dict-->
<!--                            :dict-code="DICT_CODE.LOAN_STATUS"-->
<!--                            :value="orderInfo.loanStatus"/>-->
<!--                    </detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'loanAmount')" label="放款金额" v-if="orderInfo.paymentAmt">{{ '$'+orderInfo.paymentAmt }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'loanTime')" label="放款时间" v-if="orderInfo.paymentTime">{{ orderInfo.paymentTime }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'settlementStatus')" label="结清状态">-->
<!--                        <Dict-->
<!--                            :dict-code="DICT_CODE.SETTLE_STATUS"-->
<!--                            :value="orderInfo.settleStatus"/>-->
<!--                    </detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'settlementTime')" label="结清时间" v-if="orderInfo.settleTime">{{ orderInfo.settleTime }}</detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'overdueStatus')" label="逾期状态">-->
<!--                        <Dict-->
<!--                            :dict-code="DICT_CODE.OVERDUE_STATUS"-->
<!--                            :value="orderInfo.overdueStatus"/>-->
<!--                    </detail-list-item>-->
<!--                    &lt;!&ndash;<detail-list-item :term="$t(I18N_KEY.ORDER + 'loanPurpose')" label="借款用途">&ndash;&gt;-->
<!--                        &lt;!&ndash;<Dict&ndash;&gt;-->
<!--                            &lt;!&ndash;:dict-code="DICT_CODE.LOAN_PURPOSE"&ndash;&gt;-->
<!--                            &lt;!&ndash;:value="orderInfo.loanPurpose"/>&ndash;&gt;-->
<!--                    &lt;!&ndash;</detail-list-item>&ndash;&gt;-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'orderType')" label="订单类型">-->
<!--                        <Dict-->
<!--                            :dict-code="DICT_CODE.ORDER_TYPE"-->
<!--                            :value="orderInfo.orderType"/>-->
<!--                    </detail-list-item>-->
<!--                    <detail-list-item :term="$t(I18N_KEY.ORDER + 'loanNumber')" label="借款次数">{{ orderInfo.loanCount }}</detail-list-item>-->
<!--                  <detail-list-item :term="$t(I18N_KEY.ORDER + 'channel')" label="渠道">{{ orderInfo.channel }}</detail-list-item>-->
<!--                  <detail-list-item :term="$t(I18N_KEY.ORDER + 'productName')" label="产品名称">{{ orderInfo.productName }}</detail-list-item>-->
<!--                </detail-list>-->
<!--            </a-card>-->
            <!--<a-card-->
                <!--style="margin-top: 24px"-->
                <!--:title="$t(I18N_KEY.CUSTOMER + 'customerRiskInfo')"-->
                <!--type="inner">-->
                <!--<detail-list>-->
                    <!--<detail-list-item :term="$t(I18N_KEY.ORDER + 'sxCreditScore')">{{ orderInfo.sxCreditScore }}</detail-list-item>-->
                    <!--<detail-list-item :term="$t(I18N_KEY.ORDER + 'sxCheatScore')">{{ orderInfo.sxCheatSccore }}</detail-list-item>-->
                <!--</detail-list>-->
            <!--</a-card>-->

<!--          <a-card-->
<!--            :title="$t(I18N_KEY.ORDER + 'examineCustomerInfo')"-->
<!--            type="inner"-->
<!--            style="margin-top: 24px;">-->
<!--            <detail-list>-->
<!--              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'mobile')">{{ basicInfo.mobileNo }}</detail-list-item>-->
<!--              <detail-list-item :term="$t('email')">{{ basicInfo.email }}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'age')">{{ basicInfo.age }}</detail-list-item>-->
<!--              <detail-list-item :term="$t('sex')">{{ $t(SEX[basicInfo.sex]) }}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'stpAccount')">{{ basicInfo.stpAccount }}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'fullName')">{{ basicInfo.fullName }}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankName')">{{ bankCardInfo.bankName }}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankAccountNo')">{{ bankCardInfo.bankAccountNo }}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'residentialAddress')">{{ detailInfo.residentialAddress }}</detail-list-item>-->
<!--           </detail-list>-->
<!--          </a-card>-->

<!--                 <a-card-->
<!--                    style="margin-top: 24px"-->
<!--                    :title="$t(I18N_KEY.CUSTOMER + 'customerDetailInfo')"-->
<!--                    type="inner">-->
<!--                     <detail-list>-->
<!--                        <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'maritalStatus')" label="婚姻状况"><Dict :dict-code="DICT_CODE.MARITAL_STATUS" :value="detailInfo.maritalStatus"/></detail-list-item>-->
<!--                        <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'educationLevel')" label="教育程度"><Dict :dict-code="DICT_CODE.EDUCATION" :value="detailInfo.education"/></detail-list-item>-->
<!--                        <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'personalMonthlyIncome')" label="个人月收入"><Dict :dict-code="DICT_CODE.MONTHLY_INCOME" :value="detailInfo.monthlyIncomeScope"/></detail-list-item>-->
<!--                        <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'residentialAddressPIN')" label="居住地址PIN">{{ detailInfo.residentialAddressPinCode }}</detail-list-item>-->
<!--                       <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'workType')" label="工作类型"><Dict :dict-code="DICT_CODE.WORK_TYPE" :value="detailInfo.workType"/></detail-list-item>-->
<!--                       <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'companyName')" label="公司名称" v-if="detailInfo.companyName">{{ detailInfo.companyName }}</detail-list-item>-->
<!--                       <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'workYear')" label="工作年限" v-if="detailInfo.workYears"><Dict :dict-code="DICT_CODE.WORK_YEARS" :value="detailInfo.workYears"/></detail-list-item>-->
<!--                       <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'payMethod')" label="发薪方式1" v-if="detailInfo.payMethed">{{detailInfo.payMethed}}</detail-list-item>-->
<!--                       <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'payMethod')" label="发薪方式2" v-if="detailInfo.payMethed1">{{detailInfo.payMethed1}}</detail-list-item>-->
<!--                       <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'residentialAddress')" label="居住地址">{{ detailInfo.residentialAddress }}</detail-list-item>-->
<!--                       <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'workingAddress')" label="工作地址" v-if="detailInfo.workAddress">{{ detailInfo.workAddress }}</detail-list-item>-->
<!--                       <detail-list-item :term="$t(I18N_KEY.ORDER + 'location')" label="定位地址" v-if="orderInfo.lbs">{{ orderInfo.lbs }}</detail-list-item>-->
<!--                     </detail-list>-->
<!--                 </a-card>-->
                 <a-card
                    style="margin-top: 24px;"
                    type="inner">
                         <a-tabs v-model="currentTab" @change="onTabChange">
                            <a-tab-pane :tab="$t(I18N_KEY.ORDER + 'repaymentPlan')" key="1">
                                <a-table
                                    :columns="repayPlanColumns"
                                    :dataSource="repayPlanSource"
                                    :pagination="false"
                                    bordered
                                    ref="table"
                                    size="middle"
                                    :rowKey="(record) => record.index">
                                    <Dict slot="status" slot-scope="text" :dict-code="DICT_CODE.SETTLE_STATUS" :value="text" />
                                </a-table>
                            </a-tab-pane>
                            <a-tab-pane :tab="$t(I18N_KEY.ORDER + 'approvalRecord')" key="2" forceRender>
                                <a-table
                                    :columns="examineColumns"
                                    :dataSource="examineSource"
                                    :pagination="false"
                                    bordered
                                    ref="table"
                                    size="middle"
                                    :rowKey="(record) => record.index">
                                    <Dict slot="approveTaskStatus" slot-scope="text" :dict-code="DICT_CODE.APPROVAL_TASK_STATUS" :value="text" />
                                    <Dict slot="approveStatus" slot-scope="text" :dict-code="DICT_CODE.APPROVAL_STATUS" :value="text" />
                                </a-table>
                            </a-tab-pane>
                            <a-tab-pane :tab="$t(I18N_KEY.ORDER + 'collectionRecord')" key="3">
                                <a-table
                                    :columns="collectionColumns"
                                    :dataSource="[]"
                                    :pagination="false"
                                    bordered
                                    ref="table"
                                    size="middle"
                                    :rowKey="(record) => record.index">
                                </a-table>
                            </a-tab-pane>
                           <a-tab-pane :tab="$t(I18N_KEY.ORDER + 'repaymentRecord')" key="4">
                             <a-table
                               :columns="repayRecordColumns"
                               :dataSource="repayRecordSource"
                               :pagination="false"
                               bordered
                               ref="table"
                               size="middle"
                               :rowKey="(record) => record.index">
                             </a-table>
                           </a-tab-pane>
                        </a-tabs>
                </a-card>
            </a-card>
        </a-spin>
    </div>
</template>
<script>
    import { DetailList } from '@/components';
    import { mapActions, mapGetters } from 'vuex';
    import {REBORROW_FLAG, SEX, CONTACT_TYPE, ORDER_TYPE_CN, CURP_TYPE, COLLECT_TYPE} from '../../constants/status';
    import Dict from '../modules/Dict';
    import imageMixin from '../../mixins/image-mixin';
    const DetailListItem = DetailList.Item
    const I18N_KEY = {
        CUSTOMER: 'customer.',
        ORDER: 'order.',
    }

    export default {
        props: {
            orderId: {
                type: [String, Number],
                default: undefined,
            },
            custInfoId: {
                type: [String, Number],
                default: undefined,
            },
        },
        components: {
            DetailList,
            DetailListItem,
            Dict,
        },
        mixins: [imageMixin],
        data () {
            return {
                I18N_KEY,
                REBORROW_FLAG,
                SEX,
                CONTACT_TYPE,
                ORDER_TYPE_CN,
                CURP_TYPE,
                COLLECT_TYPE,
                loading: true,
                currentTab: '1',
                repayPlanColumns: [
                    {
                        title: this.$t(I18N_KEY.ORDER + 'totalPeriod'),
                        dataIndex: 'totalPeriod',
                        align:"center",
                        customRender: (text, record, index) => {
                            record.index = index;
                            return text;
                        }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'currentPeriod'),
                        dataIndex: 'period',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'startingTime'),
                        dataIndex: 'beginDate',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'expireTime'),
                        dataIndex: 'endDate',
                        align: 'center',
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'totalRepayableAmount'),
                        dataIndex: 'totalAmt',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'principalPayable'),
                        dataIndex: 'capital',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'overdueDays'),
                        dataIndex: 'overdueDays',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'forfeitPenalty'),
                        dataIndex: 'lateFee',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'status'),
                        dataIndex: 'status',
                        align:"center",
                    },
                ],
                collectionColumns: [
                    {
                        title: this.$t(I18N_KEY.ORDER + 'collectionStaff'),
                        dataIndex: 'examineUserName',
                        align:"center",
                        customRender: (text, record, index) => {
                            record.index = index;
                            return text;
                        }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'collectionTime'),
                        dataIndex: 'examineTime',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'collectionTaskStatus'),
                        dataIndex: 'approveTaskStatus',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'collectionStatus'),
                        dataIndex: 'approveStatus',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'collectionRemark'),
                        dataIndex: 'comment',
                        align:"center",
                    },
                ],
                examineColumns: [
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalStaff'),
                        dataIndex: 'examineUserName',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalTime'),
                        dataIndex: 'examineTime',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalTaskStatus'),
                        dataIndex: 'approveTaskStatus',
                        align:"center",
                        scopedSlots: { customRender: 'approveTaskStatus' }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalStatus'),
                        dataIndex: 'approveStatus',
                        align:"center",
                        scopedSlots: { customRender: 'approveStatus' }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalRemark'),
                        dataIndex: 'comment',
                        align:"center",
                    },
                ],
              repayRecordColumns: [
                {
                  title: this.$t(I18N_KEY.ORDER + 'payOrderNo'),
                  dataIndex: 'payOrderNo',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'payType'),
                  dataIndex: 'payType',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'amount'),
                  dataIndex: 'amount',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'payoutStatus'),
                  dataIndex: 'status',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'payAmount'),
                  dataIndex: 'payAmount',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'payRecipt'),
                  dataIndex: 'payRecipt',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'description'),
                  dataIndex: 'description',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'errorDescription'),
                  dataIndex: 'errorDescription',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'recordTime'),
                  dataIndex: 'recordTime',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'updatedTime'),
                  dataIndex: 'updatedTime',
                  align:"center",
                }
              ],

            }
        },
        computed: {
            ...mapGetters('order', [
                'repayPlanList',
                'examineLogList',
                'repayRecordList'
            ]),
            repayPlanSource () {
                return this.repayPlanList || [];
            },
            examineSource () {
                return this.examineLogList || [];
            },
            repayRecordSource () {
              return this.repayRecordList || [];
            }
        },
        created () {
            this.requestDetail(this.orderId);
        },
        methods: {
            ...mapActions('order', [
                'getRepayPlanList',
                'getExamineLogList',
                'getRepayRecordList',
            ]),
            changeTab (tab) {
                this.active = tab;
                this.$router.push({
                    name: this.$route.name,
                    query: {
                        tab,
                    }
                })
            },
            activeTab () {
                this.active = this.$route.query.tab ? this.$route.query.tab : '1';
            },
            onTabChange (key) {
                this.currentTab = key;
            },
            requestDetail (id) {
                this.getRepayPlanList({ orderId: id });
                this.getExamineLogList({ orderId: id });
                this.getRepayRecordList({ orderId: id });
                Promise.all([
                ]).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.$message.error(e.message);
                    this.loading = false;
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .description-list .content {
        font-weight: 700;
    }
    .file-box {
        float: left;
        width: 200px;
        height: 200px;
        margin-right: 10px;
        margin: 0 8px 8px 0;

        .file-item {
            width: 100%;
            height: 100%;
            position: relative;
            border: 1px solid #d9d9d9;
            border-radius: 4px;

            .file-preview {
                padding: 8px;
                width: 182px;
                height: 142px;
            }

            .file-label {
                margin: 0;
                text-align: center;
                border-bottom: 1px solid #d9d9d9;
                padding: 8px;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
</style>