<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">

          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'settleTime')">
              <a-range-picker
                v-model="queryParam.settleTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="search" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>


    </div>
    <v-modal
      v-model="visible"
      :title="$t('order.collectionDetail')">
      <div style="padding: 20px;">
        <collection-detail
          v-if="visible"
          :orderId="orderId"
          :custInfoId="custInfoId"
          :taskId="taskId"
          :auditDrawer="false"
          @close="visible = false;"/>
      </div>
    </v-modal>
  </div>
</template>

<script>
  import { filterObj } from '@/utils/util'
  import { queryBackPerformance } from '@/webpublicapi/collection'
  import { getCollectionListDepartment } from '@/webpublicapi/group'
  import { getSysGroup } from '@/webpublicapi/platformCollection'
  import Dict from '../modules/Dict';
  import DictSelect from '../modules/DictSelect';
  import moment from 'moment'
  import vModal from '../modules/Modal';
  import exportFile from '@/webpublicapi/exportFile';
  import CollectionDetail from './CollectionDetail';
  const I18N_KEY = {
    ORDER: 'order.',
    CUSTOMER: 'customer.',
    COLLECTION: 'collection.',
  }

  export default {
    name: 'AllApprovalTask',
    components: {
      Dict,
      DictSelect,
      vModal,
      CollectionDetail,
    },
    data () {
      return {
        I18N_KEY,
        toggleSearchStatus:false,
        visible: false,
        orderId: undefined,
        custInfoId: undefined,
        taskId: undefined,
        exportParams: '',
        // 分页参数
        form: {
          pageNum: 1,
          pageSize: 10,
        },
        total: 0,
        dataSource: [],
        groupList: [],
        departmentList: [],
        groupOptions: [
          {
            value: 'zhejiang',
            label: 'Zhejiang',
            children: [
              {
                value: 'hangzhou',
                label: 'Hangzhou',
                children: [
                  {
                    value: 'xihu',
                    label: 'West Lake',
                  },
                ],
              },
            ],
          },
          {
            value: 'jiangsu',
            label: 'Jiangsu',
            children: [
              {
                value: 'nanjing',
                label: 'Nanjing',
                children: [
                  {
                    value: 'zhonghuamen',
                    label: 'Zhong Hua Men',
                  },
                ],
              },
            ],
          },
        ],
        // 查询条件
        queryParam: {
          "endTime":undefined,
          "settleTime":undefined,
          "userName":"",
          "normalConfigId":"",
          "sysmobile":"",
          "groupId":""
        },
        loading: false,
        isorter: {
          column: 'createTime',
          order: 'desc'
        },
        labelCol: {
          xs: { span: 8 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 16 },
          sm: { span: 19 }
        }
      }
    },
    computed: {
      columns () {
        return [
        ]
      },
    },
    created () {
    },
    methods: {
      handleToggleSearch(){
        this.toggleSearchStatus = !this.toggleSearchStatus;
      },
      searchQuery () {
        this.loadData()
      },
      loadData (arg) {
        // 加载数据 若传入参数1则加载第一页的内容
        // if (arg === 1) {
        //   this.form.pageNum = 1
        // }
        this.loading = true
        var params = this.getQueryParams()// 查询条件
        queryBackPerformance(Object.assign({}, params)).then((res)=>{
          if(res.code===1000){
            // this.total = res.data.total
            this.dataSource = res.data || [];
          }else{
            this.total = 0
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.message);
        })
      },
      transformTime (time) {
        return moment(time).format('YYYY-MM-DD')
      },
      transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
      },
      getQueryParams () {
        const { settleTime } = this.queryParam;
        const data = {
          ...this.queryParam,
          beginDate: (settleTime && settleTime.length) ? this.transformTime(settleTime[0]) : undefined,
          endDate : (settleTime && settleTime.length) ? this.transformTime(settleTime[1]) : undefined,
        }
        delete data.settleTime;
        var param = Object.assign({}, data)
        return filterObj(param)
      },
      handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
      },
      // 重置字典类型搜索框的内容
      searchReset () {
        var that = this
        that.queryParam.endTime = undefined;
        that.queryParam.settleTime = undefined;
        that.loadData(this.form.pageNum)
      },
      changeModal (visible) {
        this.visible = visible;
      },
      downLoadData () {
        var params = this.getQueryParams('')// 查询条件
        const file = {
          fileUrl: '/api/web/collectionAccountTradeFlow/excelPerformance?beginDate='
            + params.beginDate + '&endDate=' + params.endDate,
          fileName:  params.endDate+'.xls',
        }
        this.loading = true;
        exportFile(file).catch(e => {
          this.$message.error(e.message);
        }).finally(() => {
          this.loading = false;
        })
      },
      onChange(value) {
        console.log(value);
        getSysGroup({"departmentId": value}).then(res => {
          this.groupList = res.data || [];
          this.queryParam.groupId = "";
        });
      }

    }
  }
</script>
<style lang="less" scoped>
  @import "~@/assets/less/common.less";
</style>
