<template>
    <div class="customerListBox">
        <!-- 查询区域 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline" @keyup.enter.native="searchQuery">
            <a-row :gutter="24">
              <a-col :md="6" :sm="8">
                <a-form-item :label="$t(I18N_KEY + 'mobile')">
                  <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'mobile'))" v-model="queryParam.mobileNo"></a-input>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="8">
                <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                  <a-button type="primary" @click="loadData(1)" icon="search">{{ $t('search') }}</a-button>
                  <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
                  <a-button type="primary" @click="exeClearGoogle" icon="reload" style="margin-left: 8px">重置谷歌账号</a-button>
                </span>
              </a-col>

            </a-row>
          </a-form>
        </div>
        <a-table
            :columns="columns"
            :dataSource="dataSource"
            :pagination="false"
            :loading="loading"
            bordered
            ref="table"
            size="middle"
            rowKey="id">
            <span slot="action" slot-scope="text, record">
<!--                <a @click="handleJsonView(record)">{{ $t('view') }}</a>-->
<!--                <br/>-->
                <a @click="handleJsonView(record)" >大json</a>
                <!-- <a @click="handleDetail2(record)">{{ 'xxx' }}</a> -->
            </span>
        </a-table>
        <div style="margin-top: 15px; text-align: right;">
            <v-pagination
                v-if="total"
                :page-num="form.pageNum"
                :page-size="form.pageSize"
                :total="total"
                @change="handleTableChange" />
        </div>
        <v-modal
            v-model="visible"
            :title="$t(I18N_KEY + 'customerDetail')">
            <div style="padding: 20px;">
                <customer-detail v-if="visible" :id="customerId" ref="customerDetail"/>
            </div>
        </v-modal>
         <TestJSONListModal ref="testJSONListModal" @ok="modalFormOk"></TestJSONListModal>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment'
    import vModal from '../modules/Modal';
    import CustomerModal from './modules/CustomerModal'
    import TestJSONListModal from './modules/TestJSONListModal'
    import { clearGoogleAccount } from '@/webpublicapi/customer'
    import CustomerDetail from './CustomerDetail';
    import Dict from '../modules/Dict';
    import DictSelect from '../modules/DictSelect';
    import TestOrderModal from '@/views/webpublic/platformOrder/modules/TestOrderModal.vue'

    const I18N_KEY = 'customer.';
    export default {
        components: {
          TestOrderModal,
            Dict,
            DictSelect,
            vModal,
            CustomerDetail,
            CustomerModal,
          TestJSONListModal
        },
        data () {
            return {
                moment,
                I18N_KEY,
                loading: false,
                customerId: undefined,
                form: {
                    pageNum: 1,
                    pageSize: 10,
                },
                defaultQueryParam: {
                    aadhaarNo: undefined,
                    mobileNo: undefined,
                    panNo: undefined,
                    createdTime: undefined,
                    beginDate: undefined,
                    endDate: undefined,
                },
                queryParam: {
                  mobileNo: ''
                },
                visible: false,
                toggleSearchStatus: false,
            }
        },
        computed: {
            ...mapGetters('customer', [
                'testCustomerList',
            ]),
            // 表头
            columns () {
                return [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        key:'id',
                        width:60,
                        align:"center"
                    },
                    {
                        title: this.$t(I18N_KEY + 'mobile'),
                        align:"center",
                        dataIndex: 'mobileNo'
                    },
                    {
                        title: this.$t(I18N_KEY + 'names'),
                        align:"center",
                        dataIndex: 'names'
                    },
                    {
                        title: this.$t(I18N_KEY + 'surnames'),
                        align:"center",
                        dataIndex: 'surnames'
                    },
                  {
                    title: 'appssid',
                    align:"center",
                    dataIndex: 'appssid'
                  },

                    {
                      title: this.$t(I18N_KEY + 'curp'),
                      align:"center",
                      dataIndex: 'curp'
                    },
                    {
                      title: this.$t(I18N_KEY + 'createTime'),
                      align:"center",
                      dataIndex: 'createdTime'
                    },
                    {
                        title: this.$t('action'),
                        dataIndex: 'action',
                        align:"center",
                        scopedSlots: { customRender: 'action' },
                    }]
            },
            dataSource(){
              return (this.testCustomerList && this.testCustomerList.rows) || [];
            },
            total () {
                return this.testCustomerList && this.testCustomerList.total || 0;
            },
            pagination () {
                return {
                    pageSize: this.form.pageSize,
                    current: this.form.pageNum,
                    total: this.total,
                    showTotal: function(total) {
                      return "共" + total + "条";
                    }
                }
            },
            element () {
                return document.querySelector('.aaa');
            }
        },
        watch: {
            roleList (data) {
                const list = (data && data.rows) || []
                list.length && (this.dataSource = list);
            }
        },
        created () {
            window.xx = this;
            this.getDict();
            this.basicForm = { ...this.defaultBasicForm };
            this.queryParam = { ...this.defaultQueryParam };
            // this.loadData();
        },
        methods: {
            ...mapActions('customer', [
                'getTestCustomerList'
            ]),
            modalContainer () {
                return document.querySelector('.customerListBox')
            },
            handleTableChange (pageNum, pageSize) {
                this.form.pageNum = pageNum;
                this.form.pageSize = pageSize;
                this.loadData();
            },
            handleToggleSearch(){
                this.toggleSearchStatus = !this.toggleSearchStatus;
            },
          handleJsonView (record) {
            this.$refs.testJSONListModal.edit(record)
            this.$refs.testJSONListModal.title = '展示'
          },
            transformMenuListToTree (list) {
                return list.map(item => {
                    return {
                        ...item,
                        value: '' + item.id,
                        title: item.nameCn,
                        key: '' + item.id,
                        children: this.transformMenuListToTree(item.children || [])
                    }
                })
            },
            transformStringToLow (str) {
                return str && str.toLocaleLowerCase();
            },
            changeModal (visible) {
                this.visible = visible;
            },
            requestMenuListAndRolePermission (id) {
                return Promise.all([
                    this.getMenuListTree(),
                    this.getRolePermission({ roleId: id }),
                ])
            },
            searchReset () {
                this.form = {
                    keywords: undefined,
                    pageNum: 1,
                    pageSize: 10,
                }
                this.queryParam = { ...this.defaultQueryParam };
                this.loadData(1);
            },
          // model回调
          modalFormOk () {
            this.loadData()
          },

            handleDetail (row) {
                this.customerId = row.id;
                this.changeModal(true);
            },
            transformTime (time) {
                return moment(time).format('YYYY-MM-DD')
            },
            exeClearGoogle () {
              this.loading = true;
              const data = {
                jobName: 'deleteTestDataHandler',
                params: ''
              }
            clearGoogleAccount(data).then(res => {
              this.loading = false;
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.message);
            });

          },
            loadData (pageNum) {
                if(pageNum) { this.form.pageNum = pageNum; }
                const { createdTime } = this.queryParam;
                const data = {
                    ...this.form,
                    ...this.queryParam,
                    beginDate: createdTime && this.transformTime(createdTime[0]),
                    endDate: createdTime && this.transformTime(createdTime[1]),
                }
                delete data.createdTime;
                this.loading = true;
                this.getTestCustomerList(data).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }
        }
    }
</script>
<style scoped>
  @import '~@/assets/less/common.less';
</style>
