import axios from 'axios';
import cookie from 'vue-cookie';

/**
 *
 * @param {object} blob 二进制文件流
 * @param {String} filename 文件名称
 */
const exportFile = (blob, filename) => {
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE
        window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
        const URL = window.URL || window.webkitURL
        const downloadUrl = URL.createObjectURL(blob)

        if (filename) {
            // use HTML5 a[download] attribute to specify filename
            const a = document.createElement('a')
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
                window.location = downloadUrl
            } else {
                let DOMA = a;
                a.href = downloadUrl
                a.target = '_blank'
                a.download = filename
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(DOMA)
            }
        } else {
            window.location = downloadUrl
        }
        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl)
        }, 100)
    }
}

/**
* 导出文件
* @param {Object} file [description]
* @param {string} file.fileUrl 文件路劲
* @param {string} file.fileName 文件名称
* @return {[type]}      [description]
*/
export default function (file) {
    return axios.get(file && file.fileUrl, {
        headers: {
            'client-id': '2ef739e2-4593-4108-bbf6-b869ee66bbe7',
            'token': cookie.get('token'),
        },
        responseType: 'blob'
    }).then(res => {
        exportFile(res && res.data, file.fileName)
        return res
    })
}
