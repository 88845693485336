<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="7" :sm="8">
            <a-form-item label="手机号" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-input placeholder="请输入手机号" v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'产品类型'">
              <a-select
                v-model="queryParam.appssid"
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'消息类型'">
              <a-select
                v-model="queryParam.messageType"
              >
                <a-select-option v-for="item in messageTypeList" :key="item.messageType">
                  {{ item.messageTypeName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <span slot="action" slot-scope="text, record">
          <a @click="editDictItem(record)"><a-icon type="setting"/>查看明细</a>
        </span>
      </a-table>

    </div>
    <cust-leaving-detail ref="custLeavingDetail" @submit='searchQuery'></cust-leaving-detail>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { deleteDict, clearDictCache, getRedisDictCache} from '@/api/system'
import CustLeavingDetail from './CustleavingDetail'
import { getApplist} from '@/api/order'
import {getCustLeavingMessage} from "@/api/custleaving";

export default {
  name: 'DictList',
  components: { CustLeavingDetail },
  data () {
    return {
      visible: false,

      // 分页参数dict
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      dataSource: [],
      messageTypeList:[],
      appList:[],
      // 查询条件
      queryParam: {
        dictCode: '',
        dictName: ''
      },
      loading: false,
      // 表头
      columns: [
        {
          title: '用户手机号',
          align: 'left',
          dataIndex: 'mobileNo'
        },
        {
          title: '用户姓名',
          align: 'left',
          dataIndex: 'custName'
        },
        {
          title: '问题类型',
          align: 'left',
          dataIndex: 'messageTypeDetail'
        },
        {
          title: '提问时间',
          align: 'left',
          dataIndex: 'sendTime'
        },
        {
          title: '处理时间',
          align: 'left',
          dataIndex: 'replyTime'
        },
        {
          title: '状态',
          align: 'left',
          dataIndex: 'status'
        },
        {
          title: '是否处理',
          align: 'left',
          dataIndex: 'isProcess'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    getApplist().then(res => {
      this.appList = res.data || [];
    })
    this.loadData(1)
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getCustLeavingMessage(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.ipagination.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.ipagination.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    getQueryParams () {
      var param = Object.assign({}, this.queryParam, this.isorter)
      param.pageNum = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    handleTableChange (pagination, filters, sorter) {
      // TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    handleAdd () {
      this.$refs.modalForm.add()
      this.$refs.modalForm.title = '新增'
    },
    handleEdit (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleDelete(id){
      deleteDict({id}).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.loadData()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    clearCache(id){
      clearDictCache({id}).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
        }else{
          this.$message.warning(res.msg)
        }
      })
    },
    getRedisCache(id){
      getRedisDictCache({id}).then((res) => {
        const h = this.$createElement;
        if (res.code === 1000) {
          this.$info({
            title: 'redis里的值',
            content: h('div', {}, [
              h('p', res.data.list == undefined?'redis中list是空的':'list====>'+res.data.list),
              h('p', res.data.map ==  undefined?'redis中map是空的':'map====>'+res.data.map),
            ]),
            onOk() {},
          });
          console.log(res.data)
        }
      })
    },
    // 取消选择
    cancelDict () {
      //this.dict = ''
      this.visible = false
      this.loadData()
    },
    // 编辑字典数据
    editDictItem (record) {
      this.$refs.custLeavingDetail.show(record)
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.dictName = ''
      that.queryParam.dictCode = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk () {
      this.loadData()
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
