<template>
  <div>
    <a-spin :spinning='loading'>
      <a-form layout='inline' @keyup.enter.native='loadData'>
        <a-row :gutter="24">
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'createdTime')" style='width:100%' name='date'>
              <a-range-picker
                v-model='defaultDate'
                @calendarChange='handleChange'
                @blur='handleBlur'
                format='YYYY-MM-DD'
                style='width: 300px'
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'source')" style='width:100%' name='source'>
              <a-select v-model="queryParam.source" :placeholder="$t('select')" style='width: 300px'>
                <a-select-option value=''>{{ $t('teleMarket.all')}}</a-select-option>
                <a-select-option v-for="item in sources" :value="item">
                  {{ $t(I18N_KEY + 'source_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'workFlowType')" style='width:100%' name='workFlowType'>
              <a-select v-model="queryParam.workFlowType" :placeholder="$t('select')" style='width: 300px'>
                <a-select-option value=''>{{ $t('teleMarket.all')}}</a-select-option>
                <a-select-option v-for="item in allWorkFlowTypes" :value="item">
                  {{ $t(I18N_KEY + 'workFlowType_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'questionV1')" style='width:100%' name='questionV1'>
              <a-select v-model="queryParam.questionV1" :placeholder="$t('select')" style='width: 300px'>
                <a-select-option value=''>{{ $t('teleMarket.all')}}</a-select-option>
                <a-select-option v-for="item in questionV1s" :value="item.dictValue">
                  {{ $t(I18N_KEY + 'questionV1_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'tag')">
              <a-select v-model="queryParam.tagQuery" :placeholder="$t('select')" style='width: 300px'>
                <a-select-option value=''>{{ $t('teleMarket.all')}}</a-select-option>
                <a-select-option v-for="item in modelTags" :value="item.dictValue">
                  {{ item[fieldName] }}
<!--                  {{ $t(I18N_KEY + 'tag_' + item) }}-->
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <!-- 这个条件做图表需要，如果做表格则不需要，因为要直接全部显示的 -->
            <a-form-item :label="$t(I18N_KEY + 'settleStatus')">
              <a-select style='width: 300px' v-model='queryParam.settleStatus' @change='handleSettleStatusChange'>
                <a-select-option value=''>{{ $t('teleMarket.all')}}</a-select-option>
                <a-select-option value='no'>{{ $t(I18N_KEY + 'settleStatus_no')}}</a-select-option>
                <a-select-option v-for='item in settleStatuses' :key='item'>
                  {{ $t(I18N_KEY + 'settleStatus_' + item)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        <a-form-item class='table-page-search-submitButtons'>
          <a-button type='primary' @click='loadData' icon='search'>{{ $t('search') }}</a-button>
        </a-form-item>
        </a-row>
      </a-form>
    </a-spin>
    <div id='container'></div>
<!--    <div class='tableBox' v-show='tableShow'>-->
<!--      <div class='buttonRow'>-->
<!--        <a-button type='primary' @click='downLoadData()'>导出</a-button>-->
<!--      </div>-->
<!--      <a-table id='tablex' :columns='tablecolums' :data-source='tableData' bordered :scroll='{ x: 2000, y: 300 }'-->
<!--               :pagination='false'></a-table>-->
<!--    </div>-->
  </div>
</template>
<script>
import G2 from '@antv/g2'
import moment from 'moment'
import { baseData, settleStatusReport } from '@/webpublicapi/custServiceWorkOrder'
import { getCollectionListDepartment } from '@/webpublicapi/group'
import { getAllNormalConfig } from '@/webpublicapi/normalConfig'
import {mapGetters} from "vuex";
const I18N_KEY = 'custServiceWorkOrder.';

export default {
  data() {
    return {
      obj: {},
      // tableShow: false,
      I18N_KEY,
      loading: false,
      // statisticalDimension: 'backRate',
      // defaultNormalConfig: undefined,
      defaultDate: [moment().add('days', -14), moment().add('days', -1)],
      settleStatuses: [0,1,2,3,4,5,6],
      questionV1s: [],
      allWorkFlowTypes: [],
      sources: [],
      modelTags: [],
      chartData: [],
      queryParam: {
        startDate: '',
        endDate: '',
        settleStatus: '',
        source: '',
        questionV1: '',
        tagQuery: '',
        workFlowType: '',
      },
      selected: null,
      chart: null,
      // groupList: [],
      // departmentList: [],
      // userList: [],
      // normalConfigList: [],
      tableData: [
        {
          key: 'yesterday',
          name: 'yesterday'
        },
        {
          key: 'today',
          name: 'today'
        },
        {
          key: 'three',
          name: 'three'
        }
      ],
      tablecolums: [
        {
          title: '催收阶段-部门-组-人',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          fixed: 'left'
        }
      ],
      fieldName: 'dictTextCn'
    }
  },
  created() {
    this.loadBaseData();
    // this.loadData();
  },
  methods: {
    loadBaseData() {
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.questionV1s = res.data.questionV1s
          this.allWorkFlowTypes = res.data.allWorkFlowTypes
          this.modelTags = res.data.tags
          this.sources = res.data.sources
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    loadData() {
      if(this.defaultDate != null && this.defaultDate != []) {
        this.queryParam.startDate = this.defaultDate[0];
        this.queryParam.endDate = this.defaultDate[1];
      } else {
        this.queryParam.startDate = '';
        this.queryParam.endDate = '';
      }
      this.loading = true
      settleStatusReport(this.queryParam).then((res) => {
        console.log(this.queryParam.settleStatus)
        this.chartData = res.data.result
        this.renderChart()
        this.loading = false
      }).catch((_) => (this.loading = false))
    },

    // 图表方法
    renderChart() {
      console.log(this.chartData)
      if (!this.chartData) return
      if (!this.chart) {
        this.chart = new G2.Chart({
          container: 'container',
          forceFit: true,
          height: 500,
          padding: [32, 32, 32, 32]
        })
      } else {
        this.chart.clear()
      }
      const chart = this.chart
      chart.source(this.chartData, {
        date: {
          type: 'time',
          alias: '时间',
          sync: true
        },
        num: {
          type: 'linear',
          alias: '工单数',
          sync: true
        },
        // settleStatus: {
        //   type: 'identity',
        //   alias: '结清状态',
        //   label: {
        //     formatter: function formatter(val) {
        //       return this.$t(I18N_KEY+"settleStatus_"+val);
        //     }
        //   },
        //   sync: true
        //
        // }
      });
      chart.tooltip({
        crosshairs: {
          type: 'line'
        },
        // itemTpl: '<li data-index={index}><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>this.$t(I18N_KEY + "settleStatus_" + {name}): {value}</li>', // tooltip 每项记录的默认模板
      });
      chart.line().position('date*num').color('settleStatus');
      chart.point().position('date*num').color('settleStatus').size(4).shape('circle').style({
        stroke: '#fff',
        lineWidth: 1
      });
      chart.render();
    },
    handleChange([start, end]) {
      if (start && !end) {
        this.selected = start
      } else {
        this.selected = null
      }
    },
    handleBlur() {
      this.selected = null
    },
    disabledDate(current) {
      if (!this.selected) return false
      return current.diff(this.selected, 'days') > 6 || current.diff(this.selected, 'days') < -6
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    handleSettleStatusChange() {
      this.loadData()
    },



  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'dictTextEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'dictTextCn';
        } else if (lang == 'es') {
          this.fieldName = 'dictTextEs';
        } else {
          this.fieldName = 'dictTextEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>