<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item :label="$t(I18N_KEY.REPAYREPORT + 'requestDate')" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
               <a-range-picker
                  v-model="queryParam.examineDate"
                  format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'产品类型'">
              <a-select
                v-model="queryParam.appssid"
                @change="onChange"
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="'期限'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.duration">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="7">
                  7天
                </a-select-option>
                <a-select-option value="14">
                  14天
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery('', '')" icon="search" style="margin-left: 8px">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchQuery('00', '')" icon="search" style="margin-left: 8px">首贷</a-button>
              <a-button type="primary" @click="searchQuery('01', '')" icon="search" style="margin-left: 8px">复贷</a-button>

               <a-button type="primary" @click="searchQuery('', '0')" icon="search" style="margin-left: 8px">正常订单</a-button>
              <a-button type="primary" @click="searchQuery('', '1')" icon="search" style="margin-left: 8px">展期订单</a-button>

              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :rowClassName="rowClassName"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS">{{ text }}</Dict>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getPlatformRepaymentReport, getApplist } from '@/api/order'
import exportFile from '@/api/exportFile'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
  REPAYREPORT: 'repayReport.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      appList: [],
      // 查询条件
      queryParam: {
        "examinerName":"",
        "appssid":"",
        "duration":"",
        "examineDate":undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'curDate'),
          align: 'left',
          dataIndex: 'curDate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayCurCount'),
          align: 'left',
          dataIndex: 'repayCurCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'preCount'),
          align: 'left',
          dataIndex: 'preCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayNormalCount'),
          align: 'left',
          dataIndex: 'repayNormalCount'
        },
       {
         title: this.$t(I18N_KEY.REPAYREPORT + 'extendCount'),
         align: 'left',
         dataIndex: 'extendCount'
       },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayOverCount'),
          align: 'left',
          dataIndex: 'repayOverCount'
        },
       {
         title: this.$t(I18N_KEY.REPAYREPORT + 'overExtendCount'),
         align: 'left',
         dataIndex: 'overExtendCount'
       },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'overCount'),
          align: 'left',
          dataIndex: 'overCount'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'firstOverDueRate'),
          align: 'left',
          dataIndex: 'firstOverDueRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'aoverDueRate'),
          align: 'left',
          dataIndex: 'aoverDueRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'perRepayRate'),
          align: 'left',
          dataIndex: 'perRepayRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayNormalRate'),
          align: 'left',
          dataIndex: 'repayNormalRate'
        },
       {
         title: this.$t(I18N_KEY.REPAYREPORT + 'extendCountRate'),
         align: 'left',
         dataIndex: 'extendCountRate'
       },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'repayOverCountRate'),
          align: 'left',
          dataIndex: 'repayOverCountRate'
        },
       {
         title: this.$t(I18N_KEY.REPAYREPORT + 'overExtendCountRate'),
         align: 'left',
         dataIndex: 'overExtendCountRate'
       },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'colbackRate'),
          align: 'left',
          dataIndex: 'colbackRate'
        }
      ]
    }
  },
  created () {
    getApplist().then(res => {
      this.appList = res.data || [];
    })
    this.loadData(1, '', '')
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery (arg, arg2) {
      
      this.loadData(1, arg, arg2)
    },
    loadData (arg,arg2,arg3) {
      // 加载数据 若传入参数1则加载第一页的内容
      console.log(arg2)
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams(arg2, arg3)// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value);
      getPlatformRepaymentReport(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data && res.data.total
          this.dataSource = res.data || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    downLoadData () {
      const file = {
        fileUrl: '/api/web/statistics/platform/repaymentReportExcel?' + this.exportParams,
        fileName: 'report.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
          this.$message.error(e.message);
      }).finally(() => {
          this.loading = false;
      })
    },
    transformStringToLow (str) {
       return str && str.toLocaleLowerCase();
    },
    getQueryParams (arg, arg3) {
      const { examineDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          startDate: examineDate && this.transformTime(examineDate[0]),
          endDate: examineDate && this.transformTime(examineDate[1]),
          loanCount: arg,
          orderType: arg3,
      }
      delete data.examineDate;
      return filterObj(data)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    },
    rowClassName(record,index){
      var date = new Date(record.curDate);
      var day = date.getTime() / 24 / 60 / 60 / 1000 + 3;
      console.log(record.curDate , date, (day % 7) + 1);

      if((day % 7) + 1 == 7){
        return 'table_weekendColor';
      }
      return '';
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
