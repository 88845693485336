<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
     <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'orderNo')">
              <a-input
                  :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderNo'))"
                  v-model="queryParam.orderNo"/>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('phoneNo')">
              <a-input
                :placeholder="$t('enter') + ' ' + transformStringToLow($t('phoneNo'))"
                v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
        <template v-if="toggleSearchStatus">
          <!--<a-col :md="8" :sm="12">-->
            <!--<a-form-item :label="$t('customer.AadhaarName')">-->
               <!--<a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t('customer.AadhaarName'))" v-model="queryParam.aadhaarName"></a-input>-->
            <!--</a-form-item>-->
          <!--</a-col>-->
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'overdueDays')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'overdueDays'))" v-model="queryParam.overdueDays"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'applicationTime')" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
               <a-range-picker
                  v-model="queryParam.applyDate"
                  format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
           <a-col :md="12" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'loanTime')" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
               <a-range-picker
                  v-model="queryParam.paymentDate"
                  format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          </template>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
               <a @click="handleToggleSearch" style="margin-left: 8px">
                    {{ $t(toggleSearchStatus ? 'hide' : 'show') }}
                    <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
                  </a>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <span slot="action" slot-scope="text, record">
            <a @click="handleDetail(record)">{{ $t('view') }}</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
    <v-modal
        v-model="visible"
        :title="$t(I18N_KEY + 'orderDetail')">
        <div style="padding: 20px;">
            <order-detail v-if="visible" :orderId="orderId" :custInfoId="custInfoId"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getOverdueList } from '@/webpublicapi/order'
import moment from 'moment'
import vModal from '../modules/Modal';
import OrderDetail from './OrderDetail';
const I18N_KEY = 'order.';

export default {
  name: 'Overduelist',
  components: {
    vModal,
    OrderDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "orderNo":"",
        "mobileNo":"",
        "aadhaarName":"",
        "applyDate":undefined,
        "paymentDate":undefined,
        "overdueNum":"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'orderNo'),
          align: 'left',
          dataIndex: 'orderNo'
        },
        {
          title: this.$t('phoneNo'),
          align: 'left',
          dataIndex: 'mobileNo'
        },
        // {
        //   title: this.$t('customer.AadhaarName'),
        //   align: 'left',
        //   dataIndex: 'aadhaarName'
        // },
        {
          title: this.$t(I18N_KEY + 'applicationAmount'),
          align: 'left',
          dataIndex: 'applyAmt'
        },
        {
          title: this.$t(I18N_KEY + 'deadline'),
          align: 'left',
          dataIndex: 'duration'
        },
        {
          title: this.$t(I18N_KEY + 'loanTime'),
          align: 'left',
          dataIndex: 'paymentTime'
        },
        {
          title: this.$t(I18N_KEY + 'overdueDays'),
          align: 'left',
          dataIndex: 'overdueDays'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getOverdueList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
     transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    getQueryParams () {
       const { applyDate,paymentDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          applyDateStart: applyDate && this.transformTime(applyDate[0]),
          applyDateEnd: applyDate && this.transformTime(applyDate[1]),
          paymentDateStart:paymentDate && this.transformTime(paymentDate[0]),
          paymentDateEnd:paymentDate && this.transformTime(paymentDate[1])
      }
      delete data.applyDate;
      delete data.paymentDate;
      var param = Object.assign({}, data, this.isorter)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
       that.queryParam.aadhaarName = ''
      that.queryParam.applyAmtMin = ''
       that.queryParam.applyAmtMax = ''
      that.queryParam.overdueDays = ''
      that.queryParam.applyDate = undefined;
      that.queryParam.paymentDate = undefined;
      that.queryParam.overdueNum = ''
      that.loadData(this.form.pageNum);
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.id;
        this.custInfoId = row.custInfoId;
        this.changeModal(true);
    },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
