<template>
  <a-modal
  :width="modal.width"
  :visible="visible"
  :confirmLoading="confirmLoading"
  :bodyStyle="bodyStyle"
  @ok="handleOk"
  @cancel="handleCancel"
  cancelText="关闭"
  wrapClassName="ant-modal-cust-warp"
  style="top: 0; padding: 0;">
    <template slot="title">
      <div style="width: 100%;height:20px;padding-right:32px;">
        <div style="float: left;">{{ title }}</div>
        <div style="float: right;">
          <a-button
            icon="fullscreen"
            style="width:56px;height:100%;border:0"
            @click="handleClickToggleFullScreen"/>
        </div>
      </div>
    </template>
      <a-spin :spinning="confirmLoading">
        <a-card :bordered="false">
          <detail-list>
            <detail-list-item term="UID">1000001</detail-list-item>
            <detail-list-item term="Aadhaar卡姓名"></detail-list-item>
            <detail-list-item term="PAN卡号">BQUPP3026L</detail-list-item>
            <detail-list-item term="PAN卡name">JAVAJI PARTHASARADHI</detail-list-item>
            <detail-list-item term="手机号">8158982662</detail-list-item>
          </detail-list>
          <a-card style="margin-top: 24px" title="客户详细信息">
            <detail-list>
              <detail-list-item term="个人月收入">付晓晓</detail-list-item>
              <detail-list-item term="出生日期">32943898021309809423</detail-list-item>
              <detail-list-item term="孩子数量">3321944288191034921</detail-list-item>
              <detail-list-item term="教育程度">18112345678</detail-list-item>
              <detail-list-item term="工作类型">浙江省杭州市西湖区黄姑山路工专路交叉路口</detail-list-item>
            </detail-list>
          </a-card>
          <a-card style="margin-top: 24px"  title="客户联系人信息">
            <detail-list>
              <detail-list-item term="紧急联系人1姓名">付晓晓</detail-list-item>
              <detail-list-item term="紧急联系人1手机号">18677876567</detail-list-item>
              <detail-list-item term="紧急联系人1关系">父子</detail-list-item>
              <detail-list-item term="紧急联系人2姓名 ">张三</detail-list-item>
              <detail-list-item term="紧急联系人2手机号">18677876567</detail-list-item>
              <detail-list-item term="紧急联系人2关系 ">母子</detail-list-item>
            </detail-list>
          </a-card>
           <a-card style="margin-top: 24px"  title="客户信息">
             <div v-for="(fileDetail,index) in dataSource[0].fileDetails" :key="index">
                  <div style="float: left;width:200px;height:200px;margin-right: 10px;margin: 0 8px 8px 0;">
                    <div
                      style="width: 100%;height: 100%;position: relative;padding: 8px;border: 1px solid #d9d9d9;border-radius: 4px;">
                      <p style="text-align: center;font-weight:500;font-size: 16px;">{{dataSource[0].fileName[index]}}</p>
                      <img style="width: 184px;height: 142px;" :src="fileDetail.imgUrl" :preview="dataSource[0].key">
                    </div>
                  </div>
                </div>
          </a-card>
        </a-card>
    </a-spin>
    

  </a-modal>
  
</template>

<script>
import { DetailList } from '@/components'

const DetailListItem = DetailList.Item
export default {
    components: {
      DetailList,
      DetailListItem
    },
      data() {
          return {
            title: '客户详情',
            visible: false,
            confirmLoading: false,
            disableSubmit: false,
            modal: {
              width: '100%',
              style: { top: '20px' },
              fullScreen: true
            },
            dataSource: [
              {
                key: 1,
                fileDetails: [
                  {
                    imgUrl: 'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=889120611,3801177793&fm=27&gp=0.jpg'
                  },
                  {
                    imgUrl: 'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2445468140,2491956848&fm=27&gp=0.jpg'
                  },
                  {
                    imgUrl: 'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=889120611,3801177793&fm=27&gp=0.jpg'
                  },
                  {
                    imgUrl: 'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2445468140,2491956848&fm=27&gp=0.jpg'
                  }
                ],
                fileName:[
                  'Aadhaar卡正面',
                  'Aadhaar卡反面',
                  '活体检测照片',
                  'PAN卡照片'
                ]
              }
            ]
          };
      },
      computed: {
        bodyStyle () {
            return {
                padding: 0,
                width: '100%',
                height: 577 + 'px',
                overflowY: 'auto',
            }
        }
      },
      methods:{
        handleOk(){
          this.close()  
        },
        handleCancel(){
          this.close()
        },
        edit(){
          this.visible =true
        },
        close () {
          this.$emit('close')
          this.visible = false
        },
         /** 切换全屏显示 */
      handleClickToggleFullScreen() {
        let mode = !this.modal.fullScreen
        if (mode) {
          this.modal.width = '100%'
          this.modal.style.top = '20px'
        } else {
          this.modal.width = '1200px'
          this.modal.style.top = '50px'
        }
        this.modal.fullScreen = mode
      }
      }
    }
</script>
<style lang='less' scoped>
</style>