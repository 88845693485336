<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-button
              type="primary"
              icon="download"
              @click="onDownloadTemplate"
              :loading="downloading"
              style="margin-left: 20px"
            >模板下载</a-button>
            <a-button type="primary" icon="upload" @click="visible = true" :loading="loading">上传</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-modal
      title="导入"
      :visible="visible"
      :confirm-loading="loading"
      @ok="onUploadFile"
      @cancel="visible = false"
      :width="500"
    >
      <a-form>
        <a-form-item>
          <a-upload name="file" :multiple="true" :before-upload="beforeUpload" :remove="(file = null)">
            <a-button> <a-icon type="upload" /> 选择文件 </a-button>
          </a-upload>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import downloadFile, { exportFile } from '@/webpublicapi/exportFile'
import { uploadFile } from '@/api/ampUpload'

export default {
  data() {
    return {
      file: null,
      visible: false,
      loading: false,
      downloading: false,
    }
  },
  created() {},
  methods: {
    beforeUpload(file) {
      this.file = file
      return false
    },
    onDownloadTemplate() {
      const file = {
        fileUrl: '/api/web/webpublic/statistics/downloadApmFile',
        fileName: 'cesuan_template.xls',
      }
      this.downloading = true
      downloadFile(file)
        .catch((e) => {
          this.$message.error(e.message)
        })
        .finally(() => {
          this.downloading = false
        })
    },
    onUploadFile() {
      if (!this.file) {
        this.$message.error('请选择文件')
        return
      }

      this.loading = true

      let formData = new FormData() //通过formdata上传
      formData.append('file', this.file)

      uploadFile(formData)
        .then((res) => {
          if (res) {
            this.$message.success('上传成功')
            this.visible = false

            exportFile(res.data, res.fileName)
          } else {
            this.$message.error(res.msg)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
