<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter='24'>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'手机号'">
              <a-input
                :placeholder="$t('enter') + ' 手机号' "
                v-model='queryParam.mobileNo' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="'盘号'">
              <a-input
                :placeholder="$t('enter') + ' 盘号' "
                v-model='queryParam.appSsid' />
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='handleDetail' icon='edit' style='margin-left: 8px'>{{ $t('edit') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='false'
        :loading='loading'
        bordered>
      </a-table>

      <a-modal v-model='visdialog' title='休改订单逾期天数' @ok='handleOk' width='1000px'>
        <a-form :loading='loading' :form='orderInfoForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
          <a-form-item :label="'订单ID'">
            <a-input disabled='disabled' v-model='orderInfoForm.id'></a-input>
          </a-form-item>
          <a-form-item :label="'逾期天数'">
            <a-input v-model='orderInfoForm.overdueDays'></a-input>
          </a-form-item>
        </a-form>
      </a-modal>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { getCustOrderDetail } from '@/webpublicapi/customer'
import { updateOverdueDaysById } from '@/webpublicapi/order'
import config from '@/config/common.json'

const I18N_KEY = 'order.'

export default {
  name: 'CustExtOrder',
  data() {
    return {
      visdialog: false,
      I18N_KEY,
      // 分页参数
      dataSource: [],
      // 查询条件
      queryParam: {
        'mobileNo': undefined,
        'appSsid': undefined
      },
      orderInfoForm: {
        id: undefined,
        overdueDays: undefined
      },
      loading: false,
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '列名',
          align: 'left',
          width: '150px',
          dataIndex: 'left'
        },
        {
          title: '值',
          align: 'left',
          width: '150px',
          dataIndex: 'right'
        }
      ]
    }
  },
  created() {
    // this.loadData()
  },
  methods: {
    searchQuery() {
      this.loadData()
    },
    loadData() {
      this.loading = true
      var appSsid = this.queryParam.appSsid
      var mobileNo = this.queryParam.mobileNo
      getCustOrderDetail(appSsid, mobileNo).then((res) => {
        if (res.code === 1000) {
          this.dataSource = []
          for (let i in res.data) {
            if (i.indexOf('time') != -1 || i.indexOf('birthday') != -1) {
              res.data[i] = this.transformTime(res.data[i])
            }
            if (i == 'order_info.id') {
              this.orderInfoForm.id = res.data[i]
            }
            if (i == 'order_info.overdue_days') {
              this.orderInfoForm.overdueDays = res.data[i]
            }
            let dictElement = config.dict[i];
            if(process.env.VUE_APP_CONFIG == "jn"){
              dictElement = config.jn_dict[i];
            } else if(process.env.VUE_APP_CONFIG == "zaf"){
              dictElement = config.dict_zaf[i];
            }
            if (dictElement){
              res.data[i] = res.data[i] + (/^\d+$/.test(res.data[i]) ? "(参数正确)":"(参数错误)")
            }

            let trans = config.trans[i];
            if(process.env.VUE_APP_CONFIG == "zaf"){
                trans = config.trans_zaf[i];
            }

            if (trans){
              let obj = {
                left: trans,
                right: res.data[i]
              }
              this.dataSource.push(obj)
            }
          }
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.appSsid = ''
      that.queryParam.mobileNo = ''
      this.orderInfoForm.id =undefined,
      this.orderInfoForm.overdueDays =undefined,
      this.dataSource = []
    },
    handleDetail() {
      if (this.orderInfoForm.id == undefined) {
        this.$message.error('请查询订单！')
      } else {
        this.visdialog = true
        this.loading = false
      }
    },
    handleOk() {
      updateOverdueDaysById(this.orderInfoForm.id, this.orderInfoForm.overdueDays).then(res => {
        if (res.code === 1000) {
          this.$message.success(this.$t('success'))
          this.orderInfoForm = {
            id: undefined,
            overdueDays: undefined
          }
          this.visdialog = false
          this.loadData()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
