<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="4" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'orderId')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderId'))" v-model="queryParam.orderId"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'orderNo')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderNo'))" v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('phoneNo')">
              <a-input
                :placeholder="$t('enter') + ' ' + transformStringToLow($t('phoneNo'))"
                v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
             <a-form-item :label="$t('customer.curp')">
               <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t('customer.curp'))" v-model="queryParam.curp"></a-input>
             </a-form-item>
           </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t('appName')">
              <a-select
                mode="multiple"
                v-model="queryParam.appssids"
                @change="onChange" >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'settlementStatus')">
              <dict-select
                v-model="queryParam.settleStatus"
                :dict-code="DICT_CODE.SETTLE_STATUS"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')">
              </dict-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>
        <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.SETTLE_STATUS" :value="text"/>
        <Dict slot="overdueStatus" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>
        <span slot="action" slot-scope="text, record">
          <!-- slot-scope="text, record" -->
            <a @click="handleDetail(record)">{{ $t('view') }}</a>
            <a @click="createRepayOrder(record)" style="margin-left: 8px" v-if="record.settleStatus == 0 && record.loanStatus == 1">{{ $t('createRepayOrder') }}</a>
            <a @click="createRepayUrl(record)" style="margin-left: 8px" v-if="record.settleStatus == 0 && record.loanStatus == 1">{{ $t('createRepayUrl') }}</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
    <v-modal
        v-model="visible"
        :title="$t(I18N_KEY + 'orderDetail')">
        <div style="padding: 20px;">
            <order-info-qry-detail v-if="visible" :orderId="orderId" :custInfoId="custInfoId"/>
        </div>
    </v-modal>
    <a-modal
      v-model:visible="visibleRepayUrlCard"
      :title="$t('createRepayUrl')"
      ok-text="确认"
      cancel-text="取消"
      @ok="hideModal"
    >
      <p>{{ repayUrl }}</p>
      <p><a-button type="primary" @click='copy(repayUrl)'>{{$t('teleMarket.copy')}}</a-button></p>
    </a-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getOrderInfoQryList,createRepayOrderFunc,getApplist,createRepayUrlFunc } from '@/api/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import vModal from '../modules/Modal';
import OrderInfoQryDetail from './OrderInfoQryDetail';
import moment from 'moment'

const I18N_KEY = 'order.';
export default {
  name: 'OrderQryInfo',
  components: {
    Dict,
    DictSelect,
    vModal,
    OrderInfoQryDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      toggleSearchStatus:false,
      visibleRepayUrlCard: false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      appList: [],
      // 查询条件
      queryParam: {
        "orderId":"",
        "orderNo":"",
        "mobileNo":"",
        "aadhaarName":"",
        "createdTime":undefined,
        "loanStatus": '',
        "approvalStatus": '',
        "settleStatus": '',
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'orderId'),
          align: 'left',
          dataIndex: 'id'
        },
        {
          title: this.$t(I18N_KEY + 'orderNo'),
          align: 'left',
          dataIndex: 'orderNo'
        },
        {
          title: this.$t('phoneNo'),
          align: 'left',
          dataIndex: 'mobileNo'
        },
        {
          title: this.$t('customer.names'),
          align: 'left',
          dataIndex: 'names'
        },
        {
          title: this.$t('customer.surnames'),
          align: 'left',
          dataIndex: 'surnames'
        },
        // {
        //  title: this.$t('customer.curpType'),
        //  align: 'left',
        //  dataIndex: 'curpType'
        // },
        {
          title: this.$t('customer.curp'),
          align: 'left',
          dataIndex: 'curp'
        },
        {
          title: this.$t(I18N_KEY + 'applicationAmount'),
          align: 'left',
          dataIndex: 'applyAmt'
        },
        {
          title: this.$t(I18N_KEY + 'deadline'),
          align: 'left',
          dataIndex: 'duration'
        },
        {
          title: this.$t(I18N_KEY + 'loanStatus'),
          align: 'left',
          dataIndex: 'loanStatus',
          scopedSlots: { customRender: 'loanStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'approvalStatus'),
          align: 'left',
          dataIndex: 'approvalStatus',
          scopedSlots: { customRender: 'approvalStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'settlementStatus'),
          align: 'left',
          dataIndex: 'settleStatus',
          scopedSlots: { customRender: 'settleStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'overdueStatus'),
          align: 'left',
          dataIndex: 'overdueStatus',
          scopedSlots: { customRender: 'overdueStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'appName'),
          align: 'left',
          dataIndex: 'appName'
        },
        {
          title: this.$t(I18N_KEY + 'applicationTime'),
          align: 'left',
          dataIndex: 'createdTime'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.loadData(1);
    getApplist().then(res => {
      this.appList = res.data || [];
    })
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getOrderInfoQryList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { createdTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          applyDateStart: createdTime && this.transformTime(createdTime[0]),
          applyDateEnd: createdTime && this.transformTime(createdTime[1]),
      }
      delete data.createdTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderId = ''
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
       that.queryParam.aadhaarName = ''
       that.queryParam.createdTime = undefined;
      that.queryParam.loanStatus = ''
      that.queryParam.approvalStatus = ''
      that.queryParam.settleStatus = ''
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.id;
        this.custInfoId = row.custInfoId;
        this.changeModal(true);
    },
    createRepayOrder (record) {
      const self = this;
      var applyAmt = record.applyAmt;
      this.$confirm({
        title: self.$t('order.createRepayOrderTitle'),
        content: h => {
          return <div>
                  <div>
                      Normal Or Extend：<select id = 'payTypeSelect'>
                        <option value=''>Select</option>
                        <option value='all'>Normal</option>
                        <option value='difer'>Extend</option>
                      </select>
                  </div>
                  <div>
                      PayCompany：<select id = 'payCompany'>
                        <option value=''>Select</option>
                        <option value='Payvalida_Trade'>Payvalida_Trade</option>
                      </select>
                  </div>
                  <div>
                      Verification Code：<input id = 'verifyCode'></input>
                  </div>
                  <div>
                      Amount：<input id = 'repayOrderAmt'></input>
                  </div>
                </div>
        },
        onOk() {
          self.loading = true;
          var payType = document.getElementById("payTypeSelect").value.trim();
          var payCompany = document.getElementById("payCompany").value.trim();
          var verifyCode = document.getElementById("verifyCode").value.trim();
          var repayAmt = document.getElementById("repayOrderAmt").value.trim();

          if(!payType || !payCompany || !verifyCode || !repayAmt){
            alert('Required fields are empty');

            self.loadData();

            return false;
          }

          if(repayAmt <= 0){
            alert('Amount needs to be greater than 0');

            self.loadData();

            return false;
          }

          createRepayOrderFunc({ orderId:record.id, payType:payType, payCompany:payCompany, verifyCode:verifyCode, repayAmt:repayAmt}).then(res => {
            if(1000 != res.code){
              alert(res.msg);
            }
            self.loadData();
          }).catch(e => {
            self.loading = false;
            //self.$message.error(e.message);
            alert(e.message);
            self.loadData();
          })
        },
      });
    },
//   createRepayUrl(record){
      //生成支付链接
//      createRepayUrlFunc({ orderId:record.id}).then(res => {
//        if(1000 != res.code){
//          alert(res.msg);
//          return;
//        }else{
//          const self = this;
//          this.$confirm({
//              title: self.$t('order.createRepayUrlTitle'),
//              content: h => {
//                return res.data.url;
//              },
//              onOk() {}
//            });
//        }
//      }).catch(e => {
//        self.loading = false;
//        //self.$message.error(e.message);
//        alert(e.message);
//        //self.loadData();
//        return;
//      })
//
//    }
  createRepayUrl(record) {
    createRepayUrlFunc({ orderId:record.id}).then(res => {
      if(1000 != res.code){
        alert(res.msg);
        return;
      }else{
        this.repayUrl = res.data.url;
        this.visibleRepayUrlCard = true;
      }
    }).catch(e => {
      self.loading = false;
      this.$message.error(e.message);
      return;
    })
  },
  hideModal(){
    this.visibleRepayUrlCard = false
    this.repayUrl = ''
  },
  copy(e) { //复制方法
    let input = document.createElement('input')
    input.value = e
    document.body.appendChild(input)
    input.select()
    document.execCommand("copy")
    document.body.removeChild(input)
    this.$message.info("copy success");
  },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
