import { axios } from '@/utils/request'
import { mockAsRateInfo, useMock } from '@/webpublicapi/_mock'

export function getAsInfo (parameter = {}) {
  return axios({
    url: '/trans/asRateInfoCustList',
    method: 'post',
    data: parameter
  })
}

export function exportAsInfo (parameter = {}) {
  return axios({
    url: '/trans/exportAsRateInfoCust',
    method: 'post',
    data: parameter
  })
}

export function getOptType (id, appssid) {
  return axios({
    url: '/trans/getOptTypeById?custInfoId=' + id + "&appssid=" + appssid,
    method: 'post',
  })
}

export function getAsCountRate (parameter = {}) {
  return axios({
    url: '/statistics/asRateCount',
    method: 'post',
    data: parameter
  })
}