<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="6">
            <a-form-item :label="'产品类型'">
              <a-select
                v-model="queryParam.appssid"
                show-search
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
            <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
<!--              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>-->
            </span>
          </a-col>
        </a-row>
      </a-form>

      <div class="table-operator" style="border-top: 5px">
        <a-button @click="handleAdd" type="primary" icon="plus">添加</a-button>
      </div>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        :scroll="{x:1000}"
        :pagination="false"
        bordered
        @change="handleTableChange">
        <Dict slot="status" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">
            编辑
          </a>
        </span>
      </a-table>

    </div>
    <AppSettingCPModal ref="modalForm" @ok="modalFormOk"></AppSettingCPModal>
    <AppSettingCPModalAdd ref="modalForm1" @ok="modalFormOk1"></AppSettingCPModalAdd>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { deleteApp, getAppSettingCpList } from '@/webpublicapi/system'
import Dict from '../modules/Dict';
import AppSettingCPModal from './modules/AppSettingCPModal'
import AppSettingCPModalAdd from './modules/AppSettingCPModalAdd'

import Employee from './Employee'
import AppStrategyidSetting from './modules/AppStrategyidSetting'
import {getApplist} from "@/webpublicapi/order";
export default {
  name: 'AppList',
  components: {
    AppSettingCPModal,
    AppSettingCPModalAdd,
    Employee,
    AppStrategyidSetting,
    Dict
  },
  data () {
    return {
      visible: false,
      dataSource: [],
      appList: [],
      STATUS_TYPE: {
        1: '开启',
        0: '关闭',
      },
      // 查询条件
      queryParam: {
        appName: '',
      },
      loading: false,
      // 表头
      columns: [
        {
          title: 'appSsid',
          align: 'left',
          width: '100px',
          dataIndex: 'appssid'
        },
        {
          title: '产品名称',
          align: 'left',
          width: '100px',
          dataIndex: 'appName'
        },
        {
          title: '配置项Key',
          align: 'left',
          width: '100px',
          dataIndex: 'settingKey'
        },
        {
          title: '配置项',
          align: 'left',
          width: '100px',
          dataIndex: 'settingName'
        },
        {
          title: '配置内容',
          align: 'left',
          width: '500px',
          ellipsis: "auto",
          dataIndex: 'settingValue'
        },
        {
          title: '状态',
          align: 'left',
          width: '50px',
          ellipsis: "auto",
          dataIndex: 'settingStatus',
          customRender: (text, record, index) => {
            return this.STATUS_TYPE[text];
          }
        },
        {
          title: '描述',
          align: 'left',
          width: '500px',
          ellipsis: "auto",
          dataIndex: 'settingDesc'
        },
        {
          title: '操作人',
          align: 'left',
          width: '100px',
          dataIndex: 'operationUser'
        },
        {
          title: '修改时间',
          align: 'left',
          width: '100px',
          dataIndex: 'operationTime'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '50px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    // this.loadData(1)
    getApplist().then(res => {
      this.appList = [];
      this.appInList = res.data || [];
      for(var i = 0 ; i < this.appInList.length ; i ++){
        if(this.appInList[i].appssid != ''){
          this.appList.push(this.appInList[i]);
        }
      }
    });
  },
  methods: {
    searchQuery () {
      this.loadData()
    },
    loadData () {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.ipagination.current = 1
      // }
      // if(!this.queryParam.appssid.trim()){
      //   this.$message.error("产品类型不能为空！")
      //   return;
      // }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getAppSettingCpList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    getQueryParams () {
      var param = Object.assign({}, this.queryParam, this.isorter)
      return filterObj(param)
    },
    handleTableChange (pagination, filters, sorter) {
      this.loadData()
    },
    handleAdd () {
      this.$refs.modalForm1.add()
      this.$refs.modalForm1.title = '新增'
    },
    handleEdit (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleDelete(id){
      deleteApp({id}).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.loadData()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    editUser (record) {
      this.$refs.employee.edit(record)
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.appName = ''
      that.loadData()
    },
    // model回调
    modalFormOk () {
      if(this.queryParam.appssid.trim()){
        this.loadData();
      }

    } ,
    // model回调
    modalFormOk1 () {
      if(this.queryParam.appssid.trim()){
        this.loadData();
      }
    } ,
    // 编辑
    editDictItem (record) {
      this.$refs.appStrategyidSetting.edit(record)
    },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
