<template>
    <div class="customerListBox">
        <!-- 查询区域 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline" @keyup.enter.native="searchQuery">
            <a-row :gutter="24">
              <a-col :md="6" :sm="8">
                <a-form-item :label="$t(I18N_KEY + 'mobile')">
                  <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'mobile'))" v-model="queryParam.mobileNo"></a-input>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="8">
                <a-form-item :label="$t(I18N_KEY + 'curp')">
                  <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'curp'))" v-model="queryParam.idNo"></a-input>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="8">
                <a-form-item :label="$t(I18N_KEY + 'description')">
                  <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'description'))" v-model="queryParam.description"></a-input>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="8">
                <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                  <a-button type="primary" @click="addBlack" icon="plus">{{ '添加黑名单' }}</a-button>
                </span>
              </a-col>

            </a-row>
          </a-form>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import {userBlackList} from '@/webpublicapi/system'

    import moment from 'moment'
    import vModal from '../modules/Modal';
    import CustomerModal from './modules/CustomerModal'
    import CustomerDetail from './CustomerDetail';
    import Dict from '../modules/Dict';
    import DictSelect from '../modules/DictSelect';
    import {getApplist} from "@/webpublicapi/order";

    const I18N_KEY = 'customer.';
    export default {
        components: {
            Dict,
            DictSelect,
            vModal,
            CustomerDetail,
            CustomerModal
        },
        data () {
            return {
                moment,
                I18N_KEY,
                loading: false,
                customerId: undefined,
                appList: [],
                form: {
                    pageNum: 1,
                    pageSize: 10,
                },
                defaultQueryParam: {
                    aadhaarNo: undefined,
                    mobileNo: undefined,
                    panNo: undefined,
                    createdTime: undefined,
                    beginDate: undefined,
                    endDate: undefined,
                },
                queryParam: {
                  mobileNo: "",
                  idNo: "",
                  description: "",
                },
                visible: false,
                toggleSearchStatus: false,
            }
        },
        computed: {
            ...mapGetters('customer', [
                'customerList'
            ]),
            element () {
                return document.querySelector('.aaa');
            }
        },
        watch: {
            roleList (data) {
                const list = (data && data.rows) || []
                list.length && (this.dataSource = list);
            }
        },
        created () {
            window.xx = this;
            this.getDict();
            this.basicForm = { ...this.defaultBasicForm };
            this.queryParam = { ...this.defaultQueryParam };
          getApplist().then(res => {
            this.appList = [];
            this.appInList = res.data || [];
            for(var i = 0 ; i < this.appInList.length ; i ++){
              if(this.appInList[i].appssid != ''){
                this.appList.push(this.appInList[i]);
              }
            }
          });
        },
        methods: {
            ...mapActions('customer', [
                'getCustomerList'

            ]),
            modalContainer () {
                return document.querySelector('.customerListBox')
            },
            handleTableChange (pageNum, pageSize) {
                this.form.pageNum = pageNum;
                this.form.pageSize = pageSize;
                this.loadData();
            },
            handleToggleSearch(){
                this.toggleSearchStatus = !this.toggleSearchStatus;
            },
            transformMenuListToTree (list) {
                return list.map(item => {
                    return {
                        ...item,
                        value: '' + item.id,
                        title: item.nameCn,
                        key: '' + item.id,
                        children: this.transformMenuListToTree(item.children || [])
                    }
                })
            },
            transformStringToLow (str) {
                return str && str.toLocaleLowerCase();
            },
            changeModal (visible) {
                this.visible = visible;
            },
            requestMenuListAndRolePermission (id) {
                return Promise.all([
                    this.getMenuListTree(),
                    this.getRolePermission({ roleId: id }),
                ])
            },
            searchReset () {
                this.form = {
                    keywords: undefined,
                    pageNum: 1,
                    pageSize: 10,
                }
                this.queryParam = { ...this.defaultQueryParam };
                this.loadData(1);
            },
            handleDetail (row) {
                this.customerId = row.id;
                this.changeModal(true);
            },
            addBlack (row) {
              var mobileNo = this.queryParam.mobileNo;
              var idNo = this.queryParam.idNo;
              var description = this.queryParam.description;
              userBlackList({mobile: mobileNo, idNo: idNo, description: description}).then(res => {
                this.loading = false;
                var code = res.code;
                if(1000 == code){
                  this.$message.info("添加成功");
                }else{
                  this.$message.error(res.msg);
                }
              }).catch(e => {
                this.loading = false;
                this.$message.error(e.message);
              });
            },
            transformTime (time) {
                return moment(time).format('YYYY-MM-DD')
            },
            loadData (pageNum) {
                if(pageNum) { this.form.pageNum = pageNum; }
                const { createdTime } = this.queryParam;
                const data = {
                    ...this.form,
                    ...this.queryParam,
                    beginDate: createdTime && this.transformTime(createdTime[0]),
                    endDate: createdTime && this.transformTime(createdTime[1]),
                }
                delete data.createdTime;
                this.loading = true;
                this.getCustomerList(data).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }
        }
    }
</script>
<style scoped>
  @import '~@/assets/less/common.less';
</style>
