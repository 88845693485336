<template>
  <a-modal
    :title="title === '1' ? $t('add') : $t('edit')"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'type')" v-if="title === '1'">
          <a-select v-decorator="[ 'types', validatorRules.types]" :placeholder="$t('select') + ' ' + $t(I18N_KEY + 'type')" mode="multiple">
            <a-select-option v-for='item in autoAssignTypes' :key='item.value'>
<!--              {{$t(I18N_KEY + 'type_' + item.label)}}-->
              {{item[fieldName]}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'type')" v-if="title != '1'">
          <a-select v-decorator="[ 'type', validatorRules.type]" :placeholder="$t('select') + ' ' + $t(I18N_KEY + 'type')">
            <a-select-option v-for='item in types' :key='item.value'>
<!--              {{$t(I18N_KEY + 'type_' + item.label)}}-->
              {{item[fieldName]}}
            </a-select-option>
          </a-select>
        </a-form-item>
<!--        <a-form-item-->
<!--          :labelCol="labelCol"-->
<!--          :wrapperCol="wrapperCol"-->
<!--          :label="$t(I18N_KEY + 'param')">-->
<!--          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'param')" v-decorator="[ 'param', validatorRules.param]" />-->
<!--        </a-form-item>-->
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'teleMarketUser')">
          <a-select v-decorator="[ 'user', validatorRules.user]" :placeholder="$t('select') + ' ' + $t(I18N_KEY + 'teleMarketUser')" mode="multiple">
            <a-select-option v-for='item in teleMarketUsers' :key='item.value'>
              {{item.label}}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'app')">
          <a-select v-decorator="[ 'appSsid', validatorRules.appSsid]" :placeholder="$t('select') + ' ' + $t(I18N_KEY + 'app')" mode="multiple">
            <a-select-option v-for='item in apps' :key='item.appSsid'>
              {{ item.appSsid + '/' + item.appName }}
            </a-select-option>
          </a-select>
        </a-form-item>


        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'assignTime')" v-if="title === '1'">
          <a-date-picker
            v-decorator="[ 'assignDate', validatorRules.assignDate]"
            showTime
            format="YYYY-MM-DD"/>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'assignTime')" v-if="title != '1'">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'assignTime')" v-decorator="[ 'assignDate', validatorRules.assignDate]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'startTime')">
          <a-time-picker valueFormat="HH:mm:ss" v-decorator="[ 'startTime', validatorRules.startTime]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'endTime')">
          <a-time-picker valueFormat="HH:mm:ss" v-decorator="[ 'endTime', validatorRules.endTime]"/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
const I18N_KEY = 'teleMarket.';
export default {
  name: "AssignConfigModal",
  props: {
    teleMarketUsers: {
      type: Array,
      default: () => [],
    },
    autoAssignTypes: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
    apps: {
      type: Array,
      default: () => [],
    },
    fieldName:{
      type: String,
      default: 'textCn',
    }
  },
  data () {
    return {
      I18N_KEY,
      title:"操作",
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules:{
        types:{
          rules: [
            { required: true, message: 'Please select type!' },
          ]},
        type:{
          rules: [
            { required: true, message: 'Please select type!' },
          ]},
        user:{
          rules: [
            { required: true, message: 'Please select user!' },
          ]},
        appSsid:{
          rules: [
            { required: false },
          ]},
        param:{
          rules: [
            { required: false },
          ]},
        assignDate:{
          rules: [
            { required: true, message: 'Please select assign date!' },
          ]},
        startTime:{
          rules: [
            { required: true, message: 'Please select start time!'  },
          ]},
        endTime:{
          rules: [
            { required: true, message: 'Please select end time!'  },
          ]},
      },
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({});
    },
    edit (record) {
      this.form.resetFields();
      this.open();
      this.model = Object.assign({}, record)
      this.model = {
        ...this.model
      }

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'types','type', 'param', 'user', 'appSsid', 'assignDate','startTime','endTime'))
      });

    },
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      // console.log(88888)
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            ...values,
          }
          this.$emit('ok', formData);

        }
      })
    },
    handleCancel () {
      this.close()
    },
  }
}
</script>

<style scoped>

</style>