<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter="24">
          <a-col  :md='6' :sm='8'>
            <a-form-item label="日期" >
              <a-range-picker
                v-model="queryParam.time"
                format="YYYY-MM-DD"
                style="width: 230px;"/>
            </a-form-item>
          </a-col>

          <a-col :md='6' :sm='8'>
            <a-form-item label="部门">
              <a-select style='width: 190px' allowClear v-model='queryParam.departmentId'>
                <a-select-option v-for='item in departments' :value='item.id'>
                  {{ item.departmentName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="催收阶段">
              <a-select default-value="" style="width: 80px" v-model="queryParam.normalConfigId">
                <a-select-option v-for="item in normalConfigList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item label="小组">
              <a-select style='width: 120px' allowClear v-model='queryParam.groupId'>
                <a-select-option v-for='item in groupAll' :value='item.id'>
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item label="催收员">
              <a-input v-model="queryParam.userName"  style='width: 170px'></a-input>
            </a-form-item>
          </a-col>
          <a-col :md='6' sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="exportData" icon="download" style="margin-left: 8px">导出</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

        <a-table
          :columns="columns"
          :data-source="dataSource"
          bordered
          :pagination="false"
          size="middle"
          :scroll="{ x: 'calc(300px + 90%)' }"
        />

      <div style='margin-top: 15px; text-align: right;'>
        <v-pagination
          v-if='total'
          :page-num='form.pageNum'
          :page-size='form.pageSize'
          :total='total'
          @change='handleTableChange' />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getAllGroup } from '@/webpublicapi/group'
import { getAllDepartment } from '@/webpublicapi/department'
import { getAllNormalConfig } from '@/webpublicapi/normalConfig'
import moment from 'moment'
import exportFile from "@/webpublicapi/exportFile";
import { exportCallReportList, getCallReportList } from '@/webpublicapi/callReport'
import { defineComponent } from 'vue';


const I18N_KEY = {
  OASYSTEM: 'oaSystem.',
  FOLLOW: 'collectorTotalFollow.'
}


export default{
  name: 'oaWorkplace',
  components: {},
  data() {
    return {

       columns : [{
        title: '日期',
        dataIndex: 'dateTime',
        key: 'dateTime',
        width: 100,
        fixed: 'left',
       },{
         title: '小组',
         dataIndex: 'groupName',
         key: 'groupName',
         width: 100,
         fixed: 'left',
       },{
         title: '阶段',
         dataIndex: 'normalConfigName',
         key: 'normalConfigName',
         width: 50,
         fixed: 'left',
       },{
           title: '催收员id',
           dataIndex: 'collectorId',
           key: 'collectorId',
           width: 70,
           fixed: 'left',
         },
         {
           title: '催收员',
           dataIndex: 'collectorName',
           key: 'collectorName',
           width: 100,
           fixed: 'left',
         },
         {
           title: '总拨打',
           children: [{
             title: '拨打(人/次/分钟)',
             dataIndex: 'totalCall',
             key: 'totalCall',
             width: 90,
           }, {
             title: '人均拨打(次/分钟)',
             dataIndex: 'totalAvgCall',
             key: 'totalAvgCall',
             width: 80,
           }, {
             title: '有效拨打(人/次/分钟)',
             dataIndex: 'totalValidCall',
             key: 'totalValidCall',
             width: 80,
           }],
         },
         {
           title: '本人',
           className: 'custColor',
           children: [{
             title: '拨打(人/次/分钟)',
             dataIndex: 'custCall',
             key: 'custCall',
             width: 90,
             className: 'custColor',
           }, {
             title: '人均拨打(次/分钟)',
             dataIndex: 'custAvgCall',
             key: 'custAvgCall',
             width: 80,
             className: 'custColor',
           }, {
             title: '有效拨打(人/次/分钟)',
             dataIndex: 'custValidCall',
             key: 'custValidCall',
             width: 80,
             className: 'custColor',
           }],
         },
         {
           title: '紧急联系人',
           className: 'emergencyColor',
           children: [{
             title: '拨打(人/次/分钟)',
             dataIndex: 'emergencyCall',
             key: 'emergencyCall',
             width: 90,
             className: 'emergencyColor',
           }, {
             title: '人均拨打(次/分钟)',
             dataIndex: 'emergencyAvgCall',
             key: 'emergencyAvgCall',
             width: 80,
             className: 'emergencyColor',
           }, {
             title: '有效拨打(人/次/分钟)',
             dataIndex: 'emergencyValidCall',
             key: 'emergencyValidCall',
             width: 80,
             className: 'emergencyColor',
           }],
         },
         {
           title: '通讯录',
           children: [{
             title: '拨打(人/次/分钟)',
             dataIndex: 'bookCall',
             key: 'bookCall',
             width: 90,
           }, {
             title: '人均拨打(次/分钟)',
             dataIndex: 'bookAvgCall',
             key: 'bookAvgCall',
             width: 80,
           }, {
             title: '有效拨打(人/次/分钟)',
             dataIndex: 'bookValidCall',
             key: 'bookValidCall',
             width: 80,
           }],
         }


      ],

      visdialog: false,
      I18N_KEY,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      dataSourceTotal: [],
      departments: [],
      groupAll: [],
      normalConfigList: [],
      // 查询条件
      queryParam: {
        time: [],
        departmentId: '',
        groupId: '',
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created() {
    this.getTime();
    getAllDepartment().then(res => {
      if (res.code === 1000) {
        this.departments = res.data
      } else {
        this.departments = []
        this.$message.error(res.msg)
      }
    });

    getAllGroup().then(res => {
      if (res.code === 1000) {
        this.groupAll = res.data
      } else {
        this.groupAll = []
        this.$message.error(res.msg)
      }
    });

    getAllNormalConfig().then(res => {
      if (res.code === 1000) {
        this.normalConfigList = res.data
      } else {
        this.normalConfigList = []
        this.$message.error(res.msg)
      }
    });
    this.loadData(1)
  },
  methods: {
    getTime() {
      this.queryParam.time = [];
      var nowDate = new Date();
      this.queryParam.time[0]=moment(nowDate.setDate(nowDate.getDate() - 15));
      this.queryParam.time[1]=moment(new Date());

    },
    getUrl() {
      var params = this.getQueryParams()// 查询条件

      var url = '/api/web/webpublic/collectorCallCountReport/export?beginTime=' + params.beginTime

        if (params.endTime) {
          url += '&endTime=' + params.endTime;
        }
        if (params.departmentId) {
          url += '&departmentId=' + params.departmentId;
        }
        if (params.normalConfigId ) {
          url += '&normalConfigId=' + params.normalConfigId;
        }
        if (params.groupId ) {
          url += '&groupId=' + params.groupId;
        }
        if (params.userName) {
          url += '&userName=' + params.userName;
        }
      return url;
    },
    exportData() {
      var param = this.getQueryParams();
      if (!param.beginTime) {
        this.$message.error("日期不能为空");
        return;
      }

      const file = {
        fileUrl: this.getUrl(),
        fileName: 'collectorCallReport.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {
      var params = this.getQueryParams()// 查询条件
      if (!params.beginTime) {
        this.$message.error("日期不能为空");
        return;
      }
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true

      getCallReportList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },

    getQueryParams() {
      var param = this.queryParam;
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      param.beginTime = (param.time && param.time.length) ? this.transformTime(param.time[0]) : undefined;
      param.endTime = (param.time && param.time.length) ? this.transformTime(param.time[1]) : undefined;

      return filterObj(param)
    },
    transformTime (time) {
      return time.format('YYYY-MM-DD')
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置搜索框的内容
    searchReset() {
      this.getTime();
      this.queryParam.departmentId = ''
      this.queryParam.groupId = ''
      this.queryParam.normalConfigId = ''
      this.queryParam.userName = ''
      console.log(this.queryParam);
    }
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";

::v-deep .emergencyColor{
  color: blue;
  //background-color: #facd91 !important;
}
::v-deep .custColor{
  color: red;
}
</style>
