import { render, staticRenderFns } from "./ApprovalPerformance.vue?vue&type=template&id=6592d150&scoped=true&"
import script from "./ApprovalPerformance.vue?vue&type=script&lang=js&"
export * from "./ApprovalPerformance.vue?vue&type=script&lang=js&"
import style0 from "./ApprovalPerformance.vue?vue&type=style&index=0&id=6592d150&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6592d150",
  null
  
)

export default component.exports