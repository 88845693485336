<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
<!--          <a-col :md="6" :sm="8">-->
<!--            <a-form-item :label="$t(I18N_KEY + 'teleMarketUser')">-->
<!--              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'teleMarketUser')" v-model="queryParam.user"></a-input>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'teleMarketUser')">
              <template>
<!--                <a-select v-model="queryParam.appSsid" :placeholder="$t('select')" style="width: 300px;">-->
                <a-select
                  v-model:value="queryParam.user"
                  show-search
                  :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'teleMarketUser')"
                  style="width: 200px"
                >
                  <a-select-option value="">
                    {{ $t(I18N_KEY+'all') }}
                  </a-select-option>
                  <a-select-option v-for="item in teleMarketUsers" :value="item.value">
                    {{item.label }}
                  </a-select-option>
                </a-select>
              </template>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'days')">
              <a-select v-model="queryParam.days" :placeholder="$t('select')" @change="handleDaysChange(queryParam.days)" style="width: 100%;">
                <a-select-option v-for="item in days" :value="item">
                  {{$t(I18N_KEY + 'days_' + item)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :md="8" :sm="12">
              <a-form-item :label="$t(I18N_KEY + 'createdTime')">
                <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
<!--        <span slot="action" slot-scope="text, record">-->
<!--          <a @click="transfer(record)">{{ $t(I18N_KEY + 'transfer') }}</a>-->
<!--          <a-divider type="vertical"/>-->
<!--          <a @click="handleDetail(record)">{{ $t('view') }}</a>-->
<!--        </span>-->
      </a-table>
<!--      <div style="margin-top: 15px; text-align: right;">-->
<!--        <v-pagination-->
<!--          v-if="total"-->
<!--          :page-num="form.pageNum"-->
<!--          :page-size="form.pageSize"-->
<!--          :total="total"-->
<!--          @change="handleTableChange" />-->
<!--      </div>-->
    </div>
<!--    <v-modal-->
<!--      v-model="visible"-->
<!--      :title="$t(I18N_KEY + 'assignModal')">-->
<!--      <div style="padding: 20px;">-->
<!--        <assign-modal ref="modalForm" @ok="modalFormOk"/>-->
<!--      </div>-->
<!--    </v-modal>-->
  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import { userStatistics, manualLoadData, dataList, teleMarketUsers, baseData } from '@/webpublicapi/teleMarket'
import moment from 'moment';
import vModal from '../modules/Modal';
import {mapGetters} from "vuex";
// import AssignModal from './modules/AssignModal'
import { SelectTypes } from 'ant-design-vue/es/select';
import { defineComponent, ref } from 'vue';
import { versionOperate } from '@/webpublicapi/custServiceWorkOrder'

const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketUserStatistics',
  components: {
    vModal,
    // AssignModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      toggleSearchStatus1:false,
      // visible: false,
      // fieldKey: 'dictCnText',
      // types: [],// 工单类型
      // statuses: [],// 工单状态
      // firstLevelQuestions: [],// 一级问题
      // secondLevelQuestions: [],// 二级问题
      // questionV1s: [],// 问题标签V1
      // custAppeals: [],// 客户诉求
      // complainChannels: [],// 客诉渠道
      // canPasses: [],// 是否转让
      // apps: [],// 包列表
      teleMarketUsers: [],
      days: ['today','yesterday','last3days','last7days','last30days','other'],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "user": '',
        "createdTime": undefined,
        "days": '',
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },

  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'teleMarketUser'),
          align: 'center',
          dataIndex: 'user',
          customRender: (text, record) => {
            var text = text;
            return this.getUserLabel(text);
          }
        },
        // {
        //   title: this.$t(I18N_KEY + 'account'),
        //   align: 'center',
        //   dataIndex: 'account'
        // },
        {
          title: this.$t(I18N_KEY + 'completed'),
          align: 'center',
          dataIndex: 'completedCount'
        },
        {
          title: this.$t(I18N_KEY + 'donotFollowUp'),
          align: 'center',
          dataIndex: 'donotFollowUpCount'
        },
        {
          title: this.$t(I18N_KEY + 'followUping'),
          align: 'center',
          dataIndex: 'followUpingCount'
        },
        // {
        //   title: this.$t('action'),
        //   dataIndex: 'action',
        //   align: 'center',
        //   fixed: 'right',
        //   width: 120,
        //   scopedSlots: { customRender: 'action' }
        // }
      ]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData(1);
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadBaseData() {
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.teleMarketUsers = res.data.seats;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      userStatistics(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    getUserLabel(user) {
      for(var item in this.teleMarketUsers) {
        var userObj = this.teleMarketUsers[item];
        if(userObj.value === user){
          return userObj.label;
        }
      }
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.user = ''
      that.queryParam.createdTime = undefined
      that.queryParam.days = '';
      this.toggleSearchStatus = false;
      this.toggleSearchStatus1 = false;
    },
    changeModal(visible) {
      this.visible = visible;
    },
    handleDetail(row) {
      this.orderId = row.id;
      this.changeModal(true);
    },
    transfer(record) {

    },
    // handle(record) {
    //   const self = this;
    //   this.$confirm({
    //     title: this.$t(I18N_KEY + 'inputOptRemark'),// 请输入操作备注
    //     content: h => {
    //       return <div>
    //         <div>
    //           Remark ：<input id='optRemark' type='text'/>
    //         </div>
    //       </div>
    //     },
    //     onOk() {
    //       self.loading = true;
    //       var optRemark = document.getElementById("optRemark").value;
    //
    //       if (!optRemark.trim()) {
    //         alert(this.$t(I18N_KEY + 'optRemarkMustNotNull'));// 操作备注不能为空
    //         self.loadData();
    //         return false;
    //       }
    //       handleWorkOrder({id: record.id, optRemark: optRemark}).then(res => {
    //         if (res.code === 1000) {
    //           self.loadData();
    //         } else {
    //           self.$message.error(res.msg);
    //           self.loadData();
    //         }
    //
    //       }).catch(e => {
    //         self.loading = false;
    //         self.$message.error(e.message);
    //       })
    //     },
    //   });
    // },
    handleDaysChange(select) {
      if (select === 'other') {
        this.toggleSearchStatus = true;
      } else {
        this.toggleSearchStatus = false;
      }
    },
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>