<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.name_of_collector')">
              <a-input :placeholder="$t('enter') + ' ' + $t('platformCollection.name_of_collector')" v-model="queryParam.userName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'催收员账号'">
              <a-input :placeholder="$t('enter') + ' 催收员账号'" v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'operationTime')">
              <a-range-picker
                v-model="queryParam.endTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.mobile_of_operation')">
              <a-input :placeholder="$t('enter') + ' ' + $t('platformCollection.mobile_of_operation')" v-model="queryParam.operationMobileNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('platformCollection.operationType')">
              <a-select
                default-value=" "
                style="width: 190px"
                v-model="queryParam.operationType"
                :options="[
                { value: '', label: '全部' },
                { value: '1', label: '启用' },
                { value: '2', label: '删除组' },
                { value: '3', label: '变更组' },
                { value: '4', label: '禁用' },
                { value: '5', label: '添加组' },
                { value: '14', label: '批量任务变更' },
              ]"
              />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          @change="handleTableChange" />
      </div>

    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { collectionOperationRecordList } from '@/webpublicapi/platformCollection'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
const I18N_KEY = {
  COLLECTION: 'collection.',
}

export default {
  name: 'ColOperationRecord',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "endTime" : [moment(),moment()],
        "mobileNo":"",
        "operationMobileNo" : "",
        "userName": "",
        "operationType":""
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title:  this.$t('collection.userId'),
          align: 'left',
          dataIndex: 'userId'
        },
        {
          title:  '催收员账号',
          align: 'left',
          dataIndex: 'mobile'
        },
        {
          title:  this.$t('collection.userName'),
          align: 'left',
          dataIndex: 'userName'
        },
        {
          title:  this.$t('collection.operationType'),
          align: 'left',
          dataIndex: 'operationType',
          customRender: (text, row) => {
            return row.remark
          },
        },
        // {
        //   title: this.$t('collection.taskIdOld'),
        //   align: 'left',
        //   dataIndex: 'oldTaskId'
        // },
        // {
        //   title: this.$t('collection.taskIdNew'),
        //   align: 'left',
        //   dataIndex: 'newTaskId'
        // },
        {
          title: '变更前组',
          align: 'left',
          dataIndex: 'oldGroupName'
        },
        {
          title: '变更后组',
          align: 'left',
          dataIndex: 'newGroupName'
        },
        {
          title: this.$t('collection.operationUserName'),
          align: 'left',
          dataIndex: 'operationUserName'
        },
        {
          title: this.$t('collection.operationUserMobile'),
          align: 'left',
          dataIndex: 'operationUserMobile'
        },
        {
          title: this.$t('collection.operationTime'),
          align: 'left',
          dataIndex: 'operationTime'
        },

      ]
    },
  },
  created () {
    this.getDict();
    // this.loadData();
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData()
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件

      collectionOperationRecordList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          operationStartTime: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
          operationEndTime : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.endTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
