<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <!-- <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'orderNo')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.ORDER + 'orderNo'))" v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col> -->
          <a-col :span="6">
            <a-form-item :label="$t(I18N_KEY.APPROVAL + 'examinerName')">
               <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.APPROVAL + 'examinerName'))" v-model="queryParam.examinerName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t(I18N_KEY.APPROVAL + 'examineTime')" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
               <a-range-picker
                  v-model="queryParam.examineDate"
                  format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
         
          <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS">{{ text }}</Dict>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getPerformance } from '@/api/approval'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
}
export default {
  name: 'AllApprovalTask',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "examinerName":"",
        "examineDate":undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY.APPROVAL + 'examinerName'),
          align: 'left',
          dataIndex: 'examinerName'
        },
        {
          title: this.$t(I18N_KEY.APPROVAL + 'approvalTotal'),
          align: 'left',
          dataIndex: 'totalNum'
        },
        {
          title: this.$t(I18N_KEY.APPROVAL + 'approvalNum'),
          align: 'left',
          dataIndex: 'approvalNum'
        },
        {
          title: this.$t(I18N_KEY.APPROVAL + 'ApprovalRejectNum'),
          align: 'left',
          dataIndex: 'rejectNum'
        },
        {
          title: this.$t(I18N_KEY.APPROVAL + 'ApprovalCancleNum'),
          align: 'left',
          dataIndex: 'cancleNum'
        },
        {
          title: this.$t(I18N_KEY.APPROVAL + 'approvalRate'),
          align: 'left',
          dataIndex: 'approvalRate',
          customRender: (text) => {
            return (text || text == 0) && text + '%';
          }
        },
        {
          title: this.$t(I18N_KEY.APPROVAL + 'overdueNum'),
          align: 'left',
          dataIndex: 'overdueNum'
        },
        {
          title: this.$t(I18N_KEY.APPROVAL + 'overdueRate'),
          align: 'left',
          dataIndex: 'overdueRate',
          customRender: (text) => {
            return (text || text == 0) && text + '%';
          }
        },
      ]
    }
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getPerformance(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data && res.data.total
          this.dataSource = res.data || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
       return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { examineDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          examineDateBegin: examineDate && this.transformTime(examineDate[0]),
          examineDateEnd: examineDate && this.transformTime(examineDate[1]),
      }
      delete data.examineDate;
      return filterObj(data)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
