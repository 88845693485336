<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
              <a-row :gutter="24">
                <a-col :md="6" :sm="8">
                  <a-form-item :label="'催收员账号'">
                    <a-input
                        :placeholder="$t('enter') + ' 催收员账号'"
                        v-model="queryParam.mobile"/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="8">
                  <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                    <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
                    <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>

                  </span>
                </a-col>
              </a-row>
            </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <span slot="action" slot-scope="text, record">
            <a @click="handleDetail(record)">{{ $t('view') }}</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
    <v-modal
        v-model="visible"
        :title="$t(I18N_KEY + 'orderDetail')">
        <div style="padding: 20px;">
            <AccountFlowDetail v-if="visible" :userId="userId"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { queryAccountList } from '@/webpublicapi/platformCollection'
import { getPlatformLoanOrderList, paymentsLink, reductionLateFee,  repayOfflineAll, extendOffline} from '@/webpublicapi/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import AccountFlowDetail from '../platformCollection/AccountFlowDetail';
import md5 from 'md5'
const I18N_KEY = 'order.';

export default {
  name: 'LoanOrderlist',
  components: {
    Dict,
    DictSelect,
    vModal,
    AccountFlowDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      visibleO: false,
      ModalText: '',
      userId: undefined,
      // 分页参数
      dataSource: [],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      // 查询条件
      queryParam: {
        "mobile":"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: '催收员账号',
          align: 'left',
          dataIndex: 'mobile'
        },
        {
          title: 'firstName',
          align: 'left',
          dataIndex: 'firstName'
        },
        {
          title: 'middleName',
          align: 'left',
          dataIndex: 'middleName'
        },
        {
          title: 'lastName',
          align: 'left',
          dataIndex: 'lastName'
        },
        {
          title: '账户余额',
          align: 'left',
          dataIndex: 'balAmt'
        },
        {
          title: 'curp',
          align: 'left',
          dataIndex: 'curp'
        },
        {
          title: '银行卡号',
          align: 'left',
          dataIndex: 'bankCardNo'
        },
        {
          title: '手机号',
          align: 'left',
          dataIndex: 'phoneNo'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      queryAccountList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { applyDate, paymentDate, settleDate, endDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          applyDateStart: applyDate && this.transformTime(applyDate[0]),
          applyDateEnd: applyDate && this.transformTime(applyDate[1]),
          paymentDateStart:paymentDate && this.transformTime(paymentDate[0]),
          paymentDateEnd:paymentDate && this.transformTime(paymentDate[1]),
          endDateStart:endDate && this.transformTime(endDate[0]),
          endDateEnd:endDate && this.transformTime(endDate[1]),
          settleDateStart:settleDate && this.transformTime(settleDate[0]),
          settleDateEnd:settleDate && this.transformTime(settleDate[1])
      }
      delete data.applyDate;
      delete data.paymentDate;
      delete data.endDate;
      delete data.settleDate;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
       that.queryParam.aadhaarName = ''
      that.queryParam.applyDate = undefined;
      that.queryParam.paymentDate = undefined;
      that.queryParam.endDate = undefined;
      that.queryParam.settleDate = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.userId = row.userId;
        this.changeModal(true);
    },
    handleOk(e) {
      this.ModalText = '';
      this.visibleO = false;
    },
    jumpRepayUrl(row){
      paymentsLink(Object.assign({}, {orderId : row.id})).then((res)=>{
        if(res.code===1000){
            this.ModalText = res.data.url;
            this.visibleO = true;
        }else{
          this.$message.error(res.msg);
        }
      this.loading = false
    }).catch(e => {
        this.$message.error(e.message);
    })
    },
    reductionAmt (record) {
      const self = this;
      var lateFee = record.lateFee;
      this.$confirm({
        title: self.$t('order.isReductionAmt'),
        content: h => {
          return <div>
                  <div>
                      滞纳金：{lateFee}
                  </div>
                  <div>
                      减免金额：<input id = 'repayAmt'></input>
                  </div>
                </div>
        },
        onOk() {
          self.loading = true;

          var repayAmt = document.getElementById("repayAmt").value;

          if(!repayAmt.trim()){
            alert('减免金额不能为空');

            self.loadData();

            return false;
          }

          if(repayAmt > lateFee){
            alert('减免金额不能大于滞纳金');

            self.loadData();

            return false;
          }

          reductionLateFee({ orderId: record.id, reductionAmt: repayAmt }).then(res => {
            self.loadData();
          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },
    handleRepayAll (record) {

      const self = this;
      this.$confirm({

        title: self.$t('order.isSettle') + '（全额）',
        content: h => {
          return <div>
                <div>
                  验证密码：<input id = 'password' type = 'password' />
                </div>
            </div>
        },
        onOk() {
          self.loading = true;
          var password = document.getElementById("password").value;

          if(!password.trim()){
            alert('验证密码不能为空');

            self.loadData();

            return false;
          }

          var md5Password = md5(password);

          repayOfflineAll({ orderId: record.id, password: md5Password}).then(res => {
            if(res.code===1000){
              self.loadData();
            }else{
              self.$message.error(res.msg);
              self.loadData();
            }

          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },
    extendOrder (record) {

      const self = this;
      this.$confirm({

        title: '确定线下展期还款？',
        content: h => {
          return <div>
          <div>
          验证密码：<input id = 'password' type = 'password' />
            </div>
            </div>
        },
        onOk() {
          self.loading = true;
          var password = document.getElementById("password").value;

          if(!password.trim()){
            alert('验证密码不能为空');

            self.loadData();

            return false;
          }

          var md5Password = md5(password);

          extendOffline({ orderId: record.id, password: md5Password}).then(res => {
            if(res.code===1000){
              self.loadData();
            }else{
              self.$message.error(res.msg);
              self.loadData();
            }

          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
