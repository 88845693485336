import { axios } from '@/utils/request'


export function getAllDepartment (parameter = {}) {
  return axios({
    url: '/department/selectAllDepartment',
    method: 'post',
    data: parameter
  })
}

export function getPlatformCollectionDepartmentList (parameter = {}) {
  return axios({
    url: '/department/list',
    method: 'post',
    data: parameter
  })
}

export function deletePlatformCollectionDepartment (parameter = {}) {
  return axios({
    url: '/department/delete',
    method: 'post',
    data: parameter
  })
}

export function editPlatformCollectionDepartment (parameter = {}) {
  return axios({
    url: '/department/edit',
    method: 'post',
    data: parameter
  })
}