<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item label="name">
              <a-input :placeholder="$t('enter') + ' name'" v-model="form.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="前端页面">
              <a-select v-model="form.pageId" :placeholder="$t('select')" style="width: 100%;" :allowClear='allowClear'>
                <a-select-option v-for="item in pages" :value="item.value">
                    {{ item.value + '-' +item[fieldName] }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="状态">
              <a-select v-model="form.status" :placeholder="$t('select')" style="width: 100%;" :allowClear='allowClear'>
                <a-select-option v-for="item in statuses" :value="item.value">
                  {{ item[fieldName] }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="新老户">
              <a-select v-model="form.userType" :placeholder="$t('select')" style="width: 100%;" :allowClear='allowClear'>
                <a-select-option v-for="item in userTypes" :value="item.value">
                  {{ item[fieldName] }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <span style="float: left; margin-top: 5px; display: block; margin-bottom: 24px;white-space: nowrap; overflow: hidden;" class="table-page-search-submitButtons">
              <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="searchQuery">{{ $t('search') }}</a-button>
                  <a-button style="margin-left: 8px" @click="searchReset">{{ $t('reset') }}</a-button>
              </a-col>
          </span>
        </a-row>
      </a-form>
      <div class="table-operator"  style="margin-top: 5px">
        <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('add') }}</a-button>
      </div>
      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">{{ $t('edit') }}</a>
          <a-divider type="vertical" />
          <a-dropdown>
              <a class="ant-dropdown-link">{{ $t('more') }} <a-icon type="down" /></a>
              <a-menu slot="overlay">
                  <a-menu-item>
                      <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">
                          <a>{{ $t('delete') }}</a>
                      </a-popconfirm>
                  </a-menu-item>
              </a-menu>
          </a-dropdown>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          @change="handleTableChange" />
      </div>
      <question-answer-edit ref="modalForm" :pages='pages' :field-name='fieldName' :user-types='userTypes' :statuses='statuses' @ok="modalFormOk"></question-answer-edit>
    </div>
  </div>
</template>
<script>
import { questionAnswerList, questionAnswerOperate, questionAnswerDelete,questionAnswerBaseData} from '@/webpublicapi/operation';
import QuestionAnswerEdit from './modules/QuestionAnswerEdit'
import {mapGetters} from "vuex";
export default {
  components: {
    QuestionAnswerEdit,
  },
  data () {
    return {
      loading: false,
      total: 0,
      userTypes: [],
      statuses: [],
      pages: [],
      fieldName: 'textCn',
      allowClear: true,
      form: {
        pageNum: 1,
        pageSize: 10,
        name: null,
        status: null,
        userType: null,
        pageId: null,
      },
      // DICT_STATUS: {
      //   0: '启用',
      //   1: '禁用',
      // },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    // 表头
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 100,
          align:"center",
        },
        {
          title: 'name',
          align:"center",
          width: 200,
          dataIndex: 'name',
        },
        {
          title: '前端页面',
          align:"center",
          width: 200,
          dataIndex: 'pageId',
          customRender: (text, record, index) => {
            for (var item in this.pages) {
              if(this.pages[item].value === text) {
                return text+'-'+this.pages[item][this.fieldName]
              }
            }
            return text
          },
        },
        {
          title: '状态',
          align:"center",
          width: 100,
          dataIndex: 'status',
          customRender: (text, record, index) => {
            for (var item in this.statuses) {
              if(this.statuses[item].value === text) {
                return this.statuses[item][this.fieldName]
              }
            }
            return text
          },
        },
        {
          title: '用户类型',
          dataIndex: 'userType',
          width: 100,
          align:"center",
          customRender: (text, record, index) => {
            for (var item in this.userTypes) {
              if(this.userTypes[item].value === text) {
                return this.userTypes[item][this.fieldName]
              }
            }
            return text
          },
        },
        {
          title: '模板内容',
          align:"center",
          dataIndex: 'template',
          ellipsis: true,
        },
        {
          title: '创建时间',
          align:"center",
          width: 160,
          dataIndex: 'createTime',
        },
        {
          title: '更新时间',
          align:"center",
          width: 160,
          dataIndex: 'updateTime',
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          fixed: 'right',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }
      ]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData('');
  },
  methods: {
    loadBaseData() {
      questionAnswerBaseData({}).then((res) => {
        if (res.code === 1000) {
          this.userTypes = res.data.userTypes;
          this.statuses = res.data.commonStatuses;
          this.pages = res.data.pages;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    searchQuery () {
      this.loadData(1)
    },
    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    modalFormOk(data) {
      this.createDict(data);
    },
    handleAdd () {
      this.dictId = undefined;
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "1";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit (record) {
      this.dictId = record.id;
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "2";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    deleteCurrent (row) {
      this.loading = true;
      questionAnswerDelete(row.id).then(res => {
        this.$message.success(this.$t('success'));
        this.loadData(1);
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    createDict (data) {
      console.log(data)
      const form = {
        ...data,
        id: this.dictId,
        opt: this.dictId ? '2' : '1',
      };
      questionAnswerOperate(form).then(res => {
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        this.$message.success('success');
        this.loadData(1);
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    searchReset () {
      this.form = {
        dictType: undefined,
        dictKey: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10,
      }
    },
    loadData (pageNum) {
      if(pageNum) { this.form.pageNum = pageNum; }
      this.loading = true;
      questionAnswerList(this.form).then(res => {
        this.loading = false;
        this.total = res.data.total
        this.dataSource = res.data.rows;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    }
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
