<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-spin :spinning="spinning">
        <a-form layout="inline" @keyup.enter.native="searchQuery">


          <a-row :gutter="12">
            <a-col :md="6" :sm="8">
              <a-form-item :label="'类型'">
                <a-select default-value="" style="width: 120px" v-model="queryParam.type">
                  <a-select-option value="1"> 单期按组 </a-select-option>
                  <a-select-option value="2"> 单期按人 </a-select-option>
                  <a-select-option value="3"> 分期按组 </a-select-option>
                  <a-select-option value="4"> 分期按人 </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="'组ID或催收员ID以逗号隔开'" :labelCol="{ span: 2 }" :wrapperCol="{ span: 2, offset: 1 }">
                <a-textarea v-model="queryParam.ugIds" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-col :span="12">
              <a-form-item :label="'订单'" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                <a-textarea v-model="queryParam.orderIds" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-col :span="12">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="fenPre" icon="search">预分提交</a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
      >
      </a-table>
    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import moment from 'moment'
import {
  appointDistributionTaskWeb,
  appointTaskQueryWeb,
} from '@/webpublicapi/platformCollection'

export default {
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      dataSource: [],
      loading: false,
      headers: {
        // authorization: 'authorization-text',
      },
      // 查询条件
      queryParam: {
        type: undefined,
        orderIds: undefined,
        ugIds: undefined,
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '催收员',
          align: 'left',
          dataIndex: 'user_id',
        },
        {
          title: '数量',
          align: 'left',
          dataIndex: 'c',
        },
      ]
    },
  },
  created() {
  },
  methods: {
    getQueryParams() {
      const data = {
        ...this.queryParam,
      }
      return filterObj(data)
    },
    searchQuery() {
      this.loadData()
    },
    fenPre() {
      this.spinning = true
      var params = this.getQueryParams() // 查询条件
      appointDistributionTaskWeb(Object.assign({}, params))
        .then((res) => {
          if (res.code === 1000) {
            this.$message.info('分配成功')
          } else {
            this.$message.error(res.msg)
          }
          this.spinning = false
        })
        .catch((e) => {
          this.spinning = false
          this.$message.error(e.message)
        })
    },
    loadData() {
      this.loading = true
      var params = this.getQueryParams() // 查询条件

      appointTaskQueryWeb(Object.assign({}, params))
        .then((res) => {
          if (res.code === 1000) {
            this.dataSource = res.data
          } else {
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';
</style>
