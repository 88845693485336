import { axios } from '@/utils/request'

export function collectionUserAccountList (parameter = {}) {
  return axios({
    url: '/collectionUserAccountInfo/collectionUserAccountList',
    method: 'post',
    data: parameter
  })
}

