<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>
        <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.SETTLE_STATUS" :value="text"/>
        <Dict slot="overdueStatus" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>
        <span slot="action" slot-scope="text, record">
          <!-- slot-scope="text, record" -->
            <a @click="handleDownload(record)">{{ $t('download') }}</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getLoanOrderExcelNameList } from '@/api/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import vModal from '../modules/Modal';
import moment from 'moment'

const I18N_KEY = 'order.';
import exportFile from "@/api/exportFile";
export default {
  name: 'LoanOrderExcelDownLoad',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'fileName'),
          align: 'left',
          dataIndex: 'fileName'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getLoanOrderExcelNameList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { createdTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          applyDateStart: createdTime && this.transformTime(createdTime[0]),
          applyDateEnd: createdTime && this.transformTime(createdTime[1]),
      }
      delete data.createdTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDownload (row) {
        this.fileName = row.fileName;

        const file = {
          fileUrl: '/api/web/platform/order/exportLoanOrderFile?fileName=' + this.fileName,
          fileName: this.fileName,
        }
        this.loading = true;
        exportFile(file).catch(e => {
          this.$message.error(e.message);
        }).finally(() => {
          this.loading = false;
        })
    },
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
