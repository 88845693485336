<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <!-- <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'orderNo')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.ORDER + 'orderNo'))" v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col> -->
          <!--<a-col :span="6">-->
            <!--<a-form-item :label="$t(I18N_KEY.APPROVAL + 'examinerName')">-->
               <!--<a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.APPROVAL + 'examinerName'))" v-model="queryParam.examinerName"></a-input>-->
            <!--</a-form-item>-->
          <!--</a-col>-->
          <a-col :span="12">
            <a-form-item :label="$t(I18N_KEY.REPAYREPORT + 'requestDate')" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
               <a-range-picker
                  v-model="queryParam.examineDate"
                  format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item :label="'产品类型'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.appssid">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="01">
                  01
                </a-select-option>
                <a-select-option value="02">
                  02
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchQuery('00')" icon="search" style="margin-left: 8px">首贷</a-button>
              <a-button type="primary" @click="searchQuery('01')" icon="search" style="margin-left: 8px">复贷</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="search" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS">{{ text }}</Dict>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getRepaymentReportRate } from '@/api/order'
import { downRepaymentReportExcel } from '@/api/order'
import exportFile from '@/api/exportFile'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
  REPAYREPORT: 'repayReport.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "examinerName":"",
        "examineDate":undefined,
        "appssid":"",
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'curDate'),
          align: 'left',
          dataIndex: 'curDate'
        },
        // {
        //   title: '应还笔数',
        //   align: 'left',
        //   dataIndex: 'allSettleCount'
        // },
        {
          title: '首逾',
          align: 'left',
          dataIndex: 'firstOverRate'
        },
        {
          title: '实时逾期',
          align: 'left',
          dataIndex: 'acOverRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'colbackRate'),
          align: 'left',
          dataIndex: 'colbackRate'
        },
        // {
        //   title: this.$t(I18N_KEY.REPAYREPORT + 'repayCurInCount'),
        //   align: 'left',
        //   dataIndex: 'repayCurCount'
        // },
        {
          title: 'T0催回率',
          align: 'left',
          dataIndex: 'T0colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T1colbackRate'),
          align: 'left',
          dataIndex: 'T1colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T2colbackRate'),
          align: 'left',
          dataIndex: 'T2colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T3colbackRate'),
          align: 'left',
          dataIndex: 'T3colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T3OvercolbackRate'),
          align: 'left',
          dataIndex: 'T3OvercolbackRate'
        },

        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T4colbackRate'),
          align: 'left',
          dataIndex: 'T4colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T5colbackRate'),
          align: 'left',
          dataIndex: 'T5colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T6colbackRate'),
          align: 'left',
          dataIndex: 'T6colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T7colbackRate'),
          align: 'left',
          dataIndex: 'T7colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T8colbackRate'),
          align: 'left',
          dataIndex: 'T8colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T9colbackRate'),
          align: 'left',
          dataIndex: 'T9colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T10colbackRate'),
          align: 'left',
          dataIndex: 'T10colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T11colbackRate'),
          align: 'left',
          dataIndex: 'T11colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T12colbackRate'),
          align: 'left',
          dataIndex: 'T12colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T13colbackRate'),
          align: 'left',
          dataIndex: 'T13colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T14colbackRate'),
          align: 'left',
          dataIndex: 'T14colbackRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'T14OverbackRate'),
          align: 'left',
          dataIndex: 'T14OvercolbackRate'
        }
      ]
    }
  },
  created () {
    this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery (arg) {
      this.loadData(1, arg)
    },
    loadData (arg,arg2) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams(arg2)// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value);
      getRepaymentReportRate(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data && res.data.total
          this.dataSource = res.data || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    downLoadData () {
      const file = {
        fileUrl: '/api/web/statistics/repaymentReportRateExcel?' + this.exportParams,
        fileName: 'report.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
          this.$message.error(e.message);
      }).finally(() => {
          this.loading = false;
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
       return str && str.toLocaleLowerCase();
    },
    getQueryParams (arg) {
      const { examineDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          startDate: examineDate && this.transformTime(examineDate[0]),
          endDate: examineDate && this.transformTime(examineDate[1]),
          loanCount: arg
      }
      delete data.examineDate;
      return filterObj(data)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
