<template>
  <div :bordered='false'>
    <div class='table-page-search-wrapper'>
      <a-form layout='inline' @keyup.enter.native='searchQuery'>
        <a-row :gutter="24">
          <a-col  :md='6' :sm='8'>
            <a-form-item :label="$t('oaSystem.busiDate')" >
              <a-date-picker
                v-model="queryParam.busiDate"
                format="YYYY-MM-DD"
                style="width: 200px;"/>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t('oaSystem.departmentName')">
              <a-select style='width: 190px' allowClear v-model='queryParam.departmentId' @change="getGroup">
                <a-select-option v-for='item in departments' :value='item.id'>
                  {{ item.departmentName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <a-form-item :label="$t('oaSystem.group')">
              <a-select style='width: 300px' allowClear v-model='queryParam.groupId'>
                <a-select-option v-for='item in groups' :value='item.id'>
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md='6' :sm='8'>
            <span style='float: left;overflow: hidden;' class='table-page-search-submitButtons'>
              <a-button type='primary' @click='searchQuery' icon='search'>{{ $t('search') }}</a-button>
              <a-button type='primary' @click='searchReset' icon='reload' style='margin-left: 8px'>{{ $t('reset')
                }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :pagination='false'
        :loading='loading'
        bordered
        @change='handleTableChange'>
      </a-table>
      <div style='margin-top: 15px; text-align: right;'>
        <v-pagination
          v-if='total'
          :page-num='form.pageNum'
          :page-size='form.pageSize'
          :total='total'
          @change='handleTableChange' />
      </div>
    </div>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getAllDailyCollectRate } from '@/webpublicapi/oaKpi'
import { getGroupByDeptId, getAllGroup } from '@/webpublicapi/group'
import { getAllDepartment } from '@/webpublicapi/department'
import { getAllNormalConfig } from '@/webpublicapi/normalConfig'
import moment from 'moment'

const I18N_KEY = {
  OASYSTEM: 'oaSystem.'
}
export default {
  name: 'oaWorkplace',
  components: {},
  data() {
    return {
      visdialog: false,
      I18N_KEY,
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataSource: [],
      departments: [],
      groups: [],
      groupAll: [],
      normalConfigList: [],
      // 查询条件
      queryParam: {
        busiDate: '',
        departmentId: '',
        groupId: '',
      },
      loading: false,
      // 表头
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('oaSystem.departmentName'),
          align: 'left',
          dataIndex: 'departmentId'
        },
        {
          title: this.$t('oaSystem.group'),
          align: 'left',
          dataIndex: 'groupId'
        },
        {
          title: this.$t('oaSystem.totalCollectNum'),
          align: 'left',
          dataIndex: 'totalCollectNum'
        },
        {
          title: this.$t('oaSystem.totalCollectAmt'),
          align: 'left',
          dataIndex: 'totalCollectAmt'
        },
        {
          title: this.$t('oaSystem.collectStage'),
          align: 'left',
          dataIndex: 'collectStage'
        },
        {
          title: this.$t('oaSystem.basicKpi'),
          align: 'left',
          dataIndex: 'basicKpi'
        },
        {
          title: this.$t('oaSystem.kpiDiff'),
          align: 'left',
          dataIndex: 'kpiDiff'
        },
        {
          title: this.$t('oaSystem.taskNumRate'),
          align: 'left',
          dataIndex: 'taskNumRate'
        },
        {
          title: this.$t('oaSystem.taskAmtRate'),
          align: 'left',
          dataIndex: 'taskAmtRate'
        }
      ]
    }
  },
  created() {
    this.loadData(1)
  },
  methods: {
    searchQuery() {
      this.queryParam.busiDate =  moment(this.queryParam.busiDate).format("YYYY-MM-DD")
      this.loadData(1)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件

      getAllDepartment().then(res => {
        if (res.code === 1000) {
          this.departments = res.data
        } else {
          this.departments = []
          this.$message.error(res.msg)
        }
      })

      getAllGroup().then(res => {
        if (res.code === 1000) {
          this.groupAll = res.data
        } else {
          this.groupAll = []
          this.$message.error(res.msg)
        }
      })

      getAllNormalConfig().then(res => {
        if (res.code === 1000) {
          this.normalConfigList = res.data
        } else {
          this.normalConfigList = []
          this.$message.error(res.msg)
        }
      })

      getAllDailyCollectRate(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
          for (var i = 0; i < this.dataSource.length; i++) {
            this.dataSource[i].taskNumRate = (this.dataSource[i].taskNumRate * 100).toFixed(2) + '%'
            this.dataSource[i].taskAmtRate = (this.dataSource[i].taskAmtRate * 100).toFixed(2) + '%'
            this.dataSource[i].totalCollectAmt = this.dataSource[i].totalCollectAmt.toFixed(2)
            this.dataSource[i].basicKpi = '55%'
            this.dataSource[i].kpiDiff = '20%'

            this.departments.forEach(item => {
              if (item.id == this.dataSource[i].departmentId) {
                this.dataSource[i].departmentId = item.departmentName
              }
            })

            this.normalConfigList.forEach(item => {
              if (item.id == this.dataSource[i].collectStage) {
                this.dataSource[i].collectStage = item.name
              }
            })

            this.groupAll.forEach(item => {
              if (item.id == this.dataSource[i].groupId) {
                this.dataSource[i].groupId = item.groupName
              }
            })
          }
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
    },

    getQueryParams() {
      const data = {
        ...this.queryParam
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.busiDate = ''
      that.queryParam.departmentId = ''
      that.queryParam.groupId = ''
      this.loadData()
    },
    getGroup() {
      if (this.queryParam.departmentId == undefined) {
        this.groups = []
        this.queryParam.departmentId = ''
        this.queryParam.groupId = ''
      } else {
        getGroupByDeptId(this.queryParam.departmentId).then(res => {
          if (res.code === 1000) {
            this.queryParam.groupId = ''
            this.groups = res.data
          } else {
            this.groups = []
            this.$message.error(res.msg)
          }
        })
      }
    }
  }
}
</script>
<style lang='less' scoped>
@import "~@/assets/less/common.less";
</style>
