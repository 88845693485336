<template>
  <div>
    <div class='table-page-search-wrapper'>
      <a-form layout="inline" >
      <a-row :gutter="24">
        <a-col :md='6' :sm='8'>
          <a-form-item :label="$t(I18N_KEY.COUPON + 'couponID')">
            <a-input  v-model='form.id' />
          </a-form-item>
        </a-col>
        <a-col :md='6' :sm='8'>
          <a-form-item :label="$t(I18N_KEY.COUPON + 'couponName')">
            <a-input  v-model='form.couponName' />
          </a-form-item>
        </a-col>
        <a-col :md='6' :sm='8'>
          <a-form-item :label="$t(I18N_KEY.COUPON + 'couponStatus')">
            <a-select
                      v-model='form.couponStatus'
            >
              <a-select-option value='' selected>
                {{ $t(I18N_KEY.COUPON + 'all') }}
              </a-select-option>
              <a-select-option value='1'>
                {{ $t(I18N_KEY.COUPON + 'open') }}
              </a-select-option>
              <a-select-option value='2'>
                {{ $t(I18N_KEY.COUPON + 'close') }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md='6' :sm='8'>
          <a-form-item :label="$t(I18N_KEY.COUPON + 'couponType')">
            <a-select
                      v-model='form.couponType'
            >
              <a-select-option value='' >
                {{ $t(I18N_KEY.COUPON + 'all') }}
              </a-select-option>
              <a-select-option value='1'>
                {{ $t(I18N_KEY.COUPON + 'amountRaisingCoupon') }}
              </a-select-option>
              <a-select-option value='2'>
                {{ $t(I18N_KEY.COUPON + 'coupon') }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type='primary' @click='loadData()'> {{ $t(I18N_KEY.COUPON + 'search')  }}</a-button>&nbsp;&nbsp;
              <a-button type="primary" @click="searchReset" icon="reload"> {{ $t(I18N_KEY.COUPON + 'reset')  }}</a-button>&nbsp;&nbsp;
              <a-button  type='primary' @click="viewCoupon('add')">
            <a-icon type='plus' />
             {{ $t(I18N_KEY.COUPON + 'addCoupon')  }}
          </a-button>
            </span>
        </a-col>
        </a-row>
        <a-row >
        </a-row>

      </a-form>
    </div>

    <div class='table-page-search-wrapper'>
      <a-table
        ref='table'
        rowKey='id'
        size='middle'
        :columns='columns'
        :dataSource='dataSource'
        :loading='loading'
        bordered>
        <span slot='action' slot-scope='text, record'>
          <a @click="viewCoupon('edit', record)">
            {{ $t(I18N_KEY.COUPON + 'edit')  }}
          </a>
        </span>
      </a-table>
    </div>
    <a-modal
      :title='modalTitle'
      v-model='visible'
      @ok='submitForm'
    >
      <a-form :form='basicForm' :label-col='{ span: 5 }' :wrapper-col='{ span: 18 }'>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'couponName')">
          <a-input v-model='basicForm.couponName' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'couponAmount')">
          <a-input v-model='basicForm.couponAmount' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'couponRestrictions')">
          <a-input v-model='basicForm.couponLimit' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'couponStatus')">
          <a-switch v-model='basicForm.couponStatus' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'couponType')">
          <a-select
            v-model='basicForm.couponType'
          >
            <a-select-option value='1'>
              {{ $t(I18N_KEY.COUPON + 'amountRaisingCoupon') }}
            </a-select-option>
            <a-select-option value='2'>
              {{ $t(I18N_KEY.COUPON + 'coupon') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'sendType')">
          <a-select
            v-model='basicForm.sendType'
          >
            <a-select-option value='0'>
              {{ $t(I18N_KEY.COUPON + 'taskDesc') }}
            </a-select-option>
            <a-select-option value='1'>
              {{ $t(I18N_KEY.COUPON + 'loanDesc') }}
            </a-select-option>
            <a-select-option value='2'>
              {{ $t(I18N_KEY.COUPON + 'loanNoLimitDesc') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'couponDescription')">
          <a-textarea v-model='basicForm.description' />
        </a-form-item>

        <a-form-item :label="$t(I18N_KEY.COUPON + 'couponEffectiveDate')">
          <a-range-picker
            v-model='basicForm.endDate'
            format='YYYY-MM-DD'

            style='width: 100%;' />
        </a-form-item>

        <a-form-item :label="$t(I18N_KEY.COUPON + 'numberOfDaysInEffectForUsers')">
          <a-input v-model='basicForm.validDays' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'numberOfCoupons')" >
          <a-input v-model='basicForm.couponTotalNum' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'numberOfRemainingCoupons')" v-show='couponMakeNumVisible'>
          <a-input v-model='basicForm.couponMakeNum' />
        </a-form-item>
        <a-form-item :label="$t(I18N_KEY.COUPON + 'oaJexlId')">
          <a-input v-model='basicForm.oaJexlId' />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import { getCouponList, addCoupon, editCoupon } from '@/webpublicapi/operation'
import moment from 'moment/moment'


const I18N_KEY = {
  COUPON: 'coupon.',

}
export default {

  data() {
    return {
      I18N_KEY,
      loading: false,
      visible: false,
      modalTitle: undefined,
      couponMakeNumVisible: false,
      dataSource: [],
      columns: [
        {
          title: this.$t(I18N_KEY.COUPON + 'couponID'),
          align: 'left',
          dataIndex: 'id'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'couponName'),
          align: 'left',
          dataIndex: 'couponName'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'numberOfCoupons'),
          align: 'left',
          dataIndex: 'couponTotalNum'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'numberOfRemainingCoupons'),
          align: 'left',
          dataIndex: 'couponMakeNum'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'sendType'),
          align: 'left',
          dataIndex: 'sendType',
          customRender: (text) => {
            if(text === "0"){
               return this.$t(I18N_KEY.COUPON + 'taskDesc')
            }
            if(text === "1"){
              return this.$t(I18N_KEY.COUPON + 'loanDesc')
            }
            if(text === "2"){
              return this.$t(I18N_KEY.COUPON + 'loanNoLimitDesc')
            }
            return this.$t(I18N_KEY.COUPON + 'taskDesc')
          }
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'oaJexlId'),
          align: 'left',
          dataIndex: 'jexlId'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'couponStatus'),
          align: 'left',
          dataIndex: 'couponStatus',
          customRender: (text) => {
            return  text === "1" ? this.$t(I18N_KEY.COUPON + 'open') : this.$t(I18N_KEY.COUPON + 'close');
          }
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'updateTime'),
          align: 'left',
          dataIndex: 'updatedTime'
        },
        {
          title: this.$t(I18N_KEY.COUPON + 'operate'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      form: {
        id: "",
        couponName: "",
        couponStatus: "",
        couponType: ""
      },
      defaultBasicForm: {
        id: '',
        couponName: '',
        couponAmount: '',
        couponLimit: '',
        sendType: '',
        oaJexlId: '',
        couponStatus: true,
        couponType: '1',
        description: '',
        validDays: '',
        couponTotalNum: '',
        couponMakeNum: '',
        endDate: [moment().add('days', 1), moment().add('days', 8)]
      },
      basicForm: {},
      COUPON_STATUS: {
        1: this.$t(I18N_KEY.COUPON + 'open'),
        2: this.$t(I18N_KEY.COUPON + 'close')
      }
    }
  },

  // created () {
  //   window.xx = this;
  //   this.basicForm = { ...this.defaultBasicForm };
  //   this.search();
  //   this.getAllRoleList();
  //   this.getAppList();
  // },
  created() {
    this.basicForm = { ...this.defaultBasicForm }
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.search(this.form)
    },
    searchReset()
    {
      this.form.id = ""
      this.form.couponName = ""
      this.form.couponStatus = ""
      this.form.couponType = ""
    },
    search() {
      const form = {
        id : this.form.id === "" ? undefined : this.form.id,
        couponName : this.form.couponName === "" ? undefined : this.form.couponName,
        couponStatus : this.form.couponStatus === "" ? undefined : this.form.couponStatus,
        couponType : this.form.couponType === "" ? undefined : this.form.couponType,
      }

      getCouponList(form).then((res) => {
        if (res.code === 1000) {
          this.dataSource = res.data
        } else {
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })


    },
    viewCoupon(mode, coupon = {}) {
      this.visible = true
      this.basicForm.id = coupon.id
      if (mode == 'edit') {
        this.couponMakeNumVisible = true
        this.basicForm = {
          ...this.basicForm,
          id: coupon.id,
          couponName: coupon.couponName,
          couponAmount: coupon.couponAmount,
          couponLimit: coupon.couponLimit,
          couponStatus: coupon.couponStatus === '1',
          couponType: coupon.couponType,
          description: coupon.description,
          validDays: coupon.validDays,
          sendType: coupon.sendType,
          oaJexlId: coupon.jexlId,
          couponTotalNum: coupon.couponTotalNum,
          couponMakeNum: coupon.couponMakeNum,
          endDate: [moment(coupon.validStartDate, 'YYYY-MM-DD'), moment(coupon.validEndDate, 'YYYY-MM-DD')]
        }
      } else {
        this.couponMakeNumVisible = false
        this.basicForm = { ...this.defaultBasicForm }
      }
      this.modalTitle = mode == 'add' ? this.$t(I18N_KEY.COUPON + 'addCoupon') : this.$t(I18N_KEY.COUPON + 'modifyCoupon')
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    submitForm() {
      this.visible = false
      this.loading = true
      const form = {
        ...this.basicForm,
        couponStatus: this.basicForm.couponStatus ? 1 : 2,
        validStartDate: (this.basicForm.endDate && this.basicForm.endDate.length) ? this.transformTime(this.basicForm.endDate[0]) : undefined,
        validEndDate: (this.basicForm.endDate && this.basicForm.endDate.length) ? this.transformTime(this.basicForm.endDate[1]) : undefined
      }

      if (form.id) {

        editCoupon(form).then(res => {
          this.loading = false
          this.$message.success(this.$t(I18N_KEY.COUPON + 'success'))
          this.search(1)
        }).catch(e => {
          this.loading = false
          this.$message.error(e.message)
        })
      } else {
        form.couponMakeNum = form.couponTotalNum
        addCoupon(form).then(res => {
          if(res.code != 1000){
            this.loading = false
            this.$message.error(res.msg)
            this.search(1)
            return;
          }
          this.loading = false
          this.$message.success(this.$t(I18N_KEY.COUPON + 'success'))
          this.search(1)
        }).catch(e => {
          this.loading = false
          this.$message.error(e.message)
        })
      }
    }
  }
}
</script>
