<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
<!--      <a-form layout="inline" @keyup.enter.native="searchQuery">-->
<!--        <a-row :gutter="12">-->
<!--          <a-col :md="7" :sm="8">-->
<!--            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">-->
<!--              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>-->
<!--            </span>-->
<!--          </a-col>-->
<!--        </a-row>-->
<!--      </a-form>-->

      <div class="table-operator" style="border-top: 5px">
        <a-upload
          name="file"
          :multiple="true"
          :action="baseURL + '/web/platform/order/cashOutResultImport'"
          :headers="getHeaders()"
          @change="handleChangeUpload"
        >
          <a-button> <a-icon type="upload" /> Payvalida放款结果上传 </a-button>
        </a-upload>
      </div>

      <div class="table-operator" style="border-top: 5px">
              <a-upload
                name="file"
                :multiple="true"
                :action="baseURL + '/web/platform/order/monnetCashOutResultImport'"
                :headers="getHeaders()"
                @change="handleChangeUpload"
              >
                <a-button> <a-icon type="upload" /> Monnet放款结果上传 </a-button>
              </a-upload>
            </div>

<!--      <a-table-->
<!--        ref="table"-->
<!--        rowKey="id"-->
<!--        size="middle"-->
<!--        :columns="columns"-->
<!--        :dataSource="dataSource"-->
<!--        :pagination="ipagination"-->
<!--        :loading="loading"-->
<!--        bordered-->
<!--        @change="handleTableChange">-->
<!--      </a-table>-->

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getHeaders } from '@/utils/request'
// import {getBusFlowList} from '@/api/busflow'
import Dict from '../modules/Dict';
import { REFUND_TRADE_STATUS } from '../../constants/status';
// import RefundTradeModal from './modules/RefundTradeModal'
export default {
  name: 'AppList',
  components: {
    // RefundTradeModal,
    Dict
  },
  data () {
    return {
      visible: false,
      REFUND_TRADE_STATUS,
      baseURL: process.env.VUE_APP_API_BASE_URL,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },

      headers: {
        // authorization: 'authorization-text',
      },

      dataSource: [],
      // 查询条件
      queryParam: {
        appSsid: '',
      },
      loading: false,
      // 表头
      columns: [
        {
          title: '日期',
          align: 'left',
          dataIndex: 'date'
        },
        {
          title: '流量费',
          align: 'left',
          dataIndex: 'flowFee'
        },
        {
          title: 'appssid',
          align: 'left',
          dataIndex: 'appSsid'
        }
      ],
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  created () {
    // this.loadData(1)
  },
  methods: {
  getHeaders() {
  return getHeaders();
  },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.ipagination.current = 1
      // }
      // this.loading = true
      // var params = this.getQueryParams()// 查询条件
      // getBusFlowList(Object.assign({}, params)).then((res)=>{
      //   if(res.code===1000){
      //     this.ipagination.total = res.data.total
      //     this.dataSource = res.data.rows
      //   }else{
      //     this.ipagination.total = 0
      //     this.dataSource = []
      //     this.$message.error(res.msg)
      //   }
      //   this.loading = false
      // }).catch(e => {
      //   this.loading = false;
      //   this.$message.error(e.message);
      // })
    },
    getQueryParams () {
      var param = Object.assign({}, this.queryParam, this.isorter)
      param.pageNum = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    handleTableChange (pagination, filters, sorter) {
      // TODO 筛选
      // if (Object.keys(sorter).length > 0) {
      //   this.isorter.column = sorter.field
      //   this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      // }
      this.ipagination = pagination
      this.loadData()
    },
    handleAdd () {
      this.$refs.modalForm.add()
      // this.$refs.modalForm.title = '新增'
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.appSsid = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk () {
      this.loadData()
    },
    handleChangeUpload(info) {
      //debugger;
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        if(info.file.response.code === 1000){
          //alert('handle success!');
          this.$message.success(`${info.file.name} file uploaded successfully`);
        } else {
          alert(info.file.response.msg);
        }
      } else if (info.file.status === 'error') {
        //this.$message.error(`${info.file.name} file upload failed.`);
        alert('system error!');
      }
    }



  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
