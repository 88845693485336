<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t('platformCollection.user_name')">
          <a-input placeholder="请输入用户名称" v-decorator="['userName', validatorRules.userName]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="手机号">
          <a-input placeholder="请输入手机号" v-decorator="['mobile', validatorRules.mobile]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="邮箱">
          <a-input placeholder="请输入邮箱" v-decorator="['email', validatorRules.email]"/>
        </a-form-item>

         <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="密码">
          <a-input placeholder="请输入密码" v-decorator="['password']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t('platformCollection.state')"
          hasFeedback>
          <a-switch checkedChildren="启用" unCheckedChildren="禁用" @change="onChose" v-model="visibleCheck"/>
        </a-form-item>

        <a-form-item
          label="用户角色"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol">
            <a-select
                mode="multiple"
                v-model="roleIds"
            >
                <a-select-option v-for="item in roleList" :key="item.id">
                    {{ item.roleName }}
                </a-select-option>
            </a-select>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { addEmployee, editEmployee } from '@/webpublicapi/system'
    import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'EmployeeModal',
  data () {
    return {
      title: '操作',
      visible: false,
      visibleCheck:true,
      model: {},
      appId: '',
      userId:'',
      status: 1,
      roleIds:[],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        userName: { rules: [{ required: true, message: '请输入用户名称!' }] },
        mobile: { rules: [{ required: true, message: '请输入手机!' }] },
        email: { rules: [{ required: true, message: '请输入邮箱!' }] },
        password: { rules: [{ required: true, message: '请输入密码!' }] }
      }
    }
  },
  created () {
    window.rr = this;
    this.getAppAllRoleList();
  },
  methods: {
    ...mapActions('system', ['getAppAllRoleList']),
    add (appId) {
      this.appId = appId
      this.edit({})
    },
    edit (record) {
      // this.getAllRoleList();
      if (record.id) {
        this.userId = record.id
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.roleIds = this.model.roleIds
      this.status = this.model.status
      this.status ==1?this.visibleCheck = true:this.visibleCheck = false
      this.visible = true
      console.dir(this.model)
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'userName','mobile','email'))
      })
    },
    onChose (checked) {
      if (checked) {
        this.status = 1
        this.visibleCheck = true
      } else {
        this.status = 2
        this.visibleCheck = false
      }
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          userName,mobile,email,password
          values.status = this.status
          values.roleIds = this.roleIds
          values.appId = this.appId
          values.userId = this.userId
          const formData = Object.assign(values)
          formData.status = this.status
          let obj
          if (!this.model.id) {
            obj = addEmployee(formData)
          } else {
            obj = editEmployee(formData)
          }
          obj.then((res) => {
            if (res.code === 1000) {
              that.$message.success(res.msg)
              that.$emit('ok')
            } else {
              that.$message.warning(res.msg)
            }
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  },
  computed:{
      ...mapGetters('system', ['appAllRoleList',]),
      roleList () {
          return this.appAllRoleList || [];
      }
  }
}
</script>
