<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'custQueryData')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'custQueryData')" v-model="queryParam.custQueryData"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'account')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'account')" v-model="queryParam.account"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'type')">
              <a-select v-model="queryParam.type" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in types" :value="item.dictValue">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'type_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'app')">
              <a-select v-model="queryParam.app" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in apps" :value="item.appSsid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="12" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'status')">
              <a-select v-model="queryParam.status" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in statuses" :value="item.dictValue">
<!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'status_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>



          <a-col :md="12" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'questionV1')">
              <a-select v-model="queryParam.questionV1" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in questionV1s" :value="item.dictValue">
<!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'questionV1_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

<!--          <a-col :md="6" :sm="8">-->
<!--            <a-form-item :label="$t(I18N_KEY + 'firstLevelQuestion')">-->
<!--              <a-select v-model="queryParam.firstLevelQuestion" :placeholder="$t('select')" @change="handleFirstLevelQuestionChange(queryParam.firstLevelQuestion)" style="width: 100%;">-->
<!--                <a-select-option v-for="item in firstLevelQuestions" :value="item.dictValue">-->
<!--                  {{ item[fieldName] }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <template v-if="toggleSearchStatus1">-->
<!--          <a-col :md="24" :sm="24">-->
<!--            <a-form-item :label="$t(I18N_KEY + 'secondLevelQuestion')">-->
<!--              <div class="secondLevelQuestion">-->
<!--                <a-select v-model="queryParam.secondLevelQuestion" :placeholder="$t('select')">&lt;!&ndash; style="width: 100%;" :dropdownMatchSelectWidth="false"  :getPopupContainer="()=>this.$refs.selectBox"&ndash;&gt;-->
<!--                  <a-select-option v-for="item in secondLevelQuestions" :value="item.dictValue">-->
<!--                    {{ item[fieldName] }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </div>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          </template>-->



          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'custAppeal')">
              <a-select v-model="queryParam.custAppeal" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in custAppeals" :value="item.dictValue">
<!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'custAppeal_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'orderNo')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'orderNo')" v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'complainChannel')">
              <a-select v-model="queryParam.complainChannel" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in complainChannels" :value="item.dictValue">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'complainChannel_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'level')">
              <a-select v-model="queryParam.level" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in levels" :value="item.dictValue">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'level_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'handleStatus')">
              <a-select v-model="queryParam.handleStatus" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in handleStatuses" :value="item.dictValue">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'handleStatus_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'settle')">
              <a-select v-model="queryParam.settle" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in settles" :value="item">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'settle_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'topStatus')">
              <a-select v-model="queryParam.topStatus" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in topStatuses" :value="item">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'topStatus_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'tag')">
              <a-select v-model="queryParam.tagQuery" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in modelTags" :value="item.dictValue">
                  {{ item[fieldName] }}
                  <!--                  {{ $t(I18N_KEY + 'tag_' + item) }}-->
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'orderType')">
              <a-select v-model="queryParam.orderType" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in orderTypes" :value="item">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'orderType_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'toFinance')">
              <a-select v-model="queryParam.toFinance" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in toFinances" :value="item">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'toFinance_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'days')">
              <a-select v-model="queryParam.days" :placeholder="$t('select')" @change="handleDaysChange(queryParam.days)" style="width: 100%;">
                <a-select-option v-for="item in days" :value="item">
<!--                  {{ item[fieldName] }}-->
                  {{$t(I18N_KEY + 'days_' + item)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <template v-if="toggleSearchStatus">
          <a-col :md="8" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'createdTime')">
              <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>

          </template>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" @click="downLoadData" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
<!--              <a-button type="primary" @click="create" icon="enter" style="margin-left: 8px">{{ $t(I18N_KEY + 'create') }}</a-button>-->
<!--              <a-button type="primary" @click="downLoadData('')" icon="download" style="margin-left: 8px">导出</a-button>-->
<!--              <a-button type="primary" @click="downLoadData2('')" icon="download2" style="margin-left: 8px">导出2</a-button>-->
            </span>
          </a-col>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1300 }"
        :rowClassName='rowClassName'
        bordered>
        <span slot="level" slot-scope="text, record, index">
<!--          <span v-if='text != undefined' :style="levelStyle(record)">{{$t(I18N_KEY + 'level_' + text)}}</span>-->
          <a-select v-model:value="record.level" :placeholder="$t('select')" :style="levelStyle(record)" v-if='record.viewCollectionSelectItem'>
            <a-select-option v-for="item in levels" :value="item.dictValue" >
              {{ $t(I18N_KEY + 'level_' + item.dictValue) }}
            </a-select-option>
          </a-select>
        </span>
        <span slot="handleStatus" slot-scope="text, record, index">
<!--          <span v-if='text != undefined' :style="handleStatusStyle(record)">{{$t(I18N_KEY + 'handleStatus_' + text)}}</span>-->
          <a-select v-model:value="record.handleStatus" :placeholder="$t('select')" :style="handleStatusStyle(record)" v-if='record.viewCollectionSelectItem'>
            <a-select-option v-for="item in handleStatuses" :value="item.dictValue" >
              {{ $t(I18N_KEY + 'handleStatus_' + item.dictValue) }}
            </a-select-option>
          </a-select>
        </span>
        <span slot="satisfaction" slot-scope="text, record, index">
<!--          <span v-if='text != undefined' :style="satisfactionStyle(record)">{{$t(I18N_KEY + 'satisfaction_' + text)}}</span>-->
          <a-select v-model:value="record.satisfaction" :placeholder="$t('select')" :style="satisfactionStyle(record)" v-if='record.viewCollectionSelectItem'>
            <a-select-option v-for="item in satisfactions" :value="item.dictValue" >
              {{ $t(I18N_KEY + 'satisfaction_' + item.dictValue) }}
            </a-select-option>
          </a-select>
        </span>
<!--        <template #type="text,record,index">{{$t(I18N_KEY + 'type_' + text)}}</template>-->
        <template #status="text,record,index">{{$t(I18N_KEY + 'status_' + text)}}</template>
<!--        <template #firstLevelQuestion="text,record,index">{{$t(I18N_KEY + 'firstLevelQuestion_' + text)}}</template>-->
<!--        <template #secondLevelQuestion="text,record,index">{{$t(I18N_KEY + 'secondLevelQuestion_'+ record.firstLevelQuestion+ '_' + text)}}</template>-->
        <template #questionV1="text,record,index"><span v-if='text != undefined'>{{$t(I18N_KEY + 'questionV1_' + text)}}</span></template>
        <template #backVisit="text,record,index"><span v-if='text != undefined'>{{$t(I18N_KEY + 'backVisit_' + text)}}</span></template>
        <template #connect="text,record,index"><span v-if='text != undefined'>{{$t(I18N_KEY + 'connect_' + text)}}</span></template>
        <template #orderType="text,record,index"><span v-if='text != undefined'>{{$t(I18N_KEY + 'orderType_' + text)}}</span></template>
        <template #custAppeal="text,record,index"><span v-if="text != undefined">{{$t(I18N_KEY + 'custAppeal_' + text)}}</span></template>
        <template #complainChannel="text,record,index"><span v-if='text != undefined'>{{$t(I18N_KEY + 'complainChannel_' + text)}}</span></template>
        <template #workFlowType="text,record,index"><span v-if='text != undefined'>{{$t(I18N_KEY + 'workFlowType_' + text)}}</span></template>
        <template #decision="text,record,index"><span v-if='text != undefined'>{{$t(I18N_KEY + 'decision_' + text)}}</span></template>
        <template #topStatus="text,record,index"><span v-if='text != undefined'>{{$t(I18N_KEY + 'topStatus_' + text)}}</span></template>
        <template #toFinance="text,record,index"><span v-if='text != undefined'>{{$t(I18N_KEY + 'toFinance_' + text)}}</span></template>
        <span slot="action" slot-scope="text, record">
<!--          <a @click="handleDetail(record)" v-if="!record.viewApprove">{{ $t('view') }}</a>-->
<!--          <a @click="handleDetail(record)" v-if="record.viewApprove">{{ $t('handle') }}</a>-->
          <a @click="updateRecord(record)" v-if='record.viewCollectionSelectItem'>{{ $t(I18N_KEY + 'save') }}</a>
          <a-divider v-if='record.viewCollectionSelectItem' type="vertical"/>
          <a @click="handleDetail(record)"><span v-if='record.viewApprove'>{{ $t('handle') }}</span><span v-else-if='!record.viewApprove'>{{ $t('view') }}</span></a>
<!--          <a-divider v-if="record.viewApprove" type="vertical"/>-->
<!--          <a v-if="record.viewApprove" @click="handle(record)">{{ $t('handle') }}</a>-->
<!--          <a-divider v-if="record.viewRepair" type="vertical"/>-->
<!--          <a-tooltip placement="bottomRight" :title="$t(I18N_KEY + 'repairNotice')">-->
<!--            <a v-if="record.viewRepair" @click="repair(record)">{{ $t(I18N_KEY + 'repair') }}</a>-->
<!--          </a-tooltip>-->
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          @change="handleTableChange" />
      </div>
    </div>
    <v-modal
      v-model="visible"
      :title="$t(I18N_KEY + 'workOrderDetail')">
      <div style="padding: 20px;">
        <work-order-detail v-if="visible"
                           :orderId="orderId"
                           :operate-types='operateTypes'
                           :view-approve='viewApprove'
                           :levels='levels'
                           :handle-statuses='handleStatuses'
                           :satisfactions='satisfactions'
                           :back-visits='backVisits'
                           :connects='connects'
                           :to-detail-view-collection-select-item='toDetailViewCollectionSelectItem'
                           :model-tags='modelTags'
                           @closeLoad='closeLoad'/>
      </div>
    </v-modal>
  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import { workOrderList, handleWorkOrder, workOrderDetail, baseData, repairWorkOrder,updateCollectionOrder} from '@/webpublicapi/custServiceWorkOrder';
import moment from 'moment';
import vModal from '../modules/Modal';
import WorkOrderDetail from './WorkOrderDetail';
import HandleModal from './modules/HandleModal';
import {mapGetters} from "vuex";
import WorkOrderCreate from '@/views/webpublic/custService/WorkOrderCreate.vue'
import exportFile from '@/webpublicapi/exportFile'

const I18N_KEY = 'custServiceWorkOrder.';
export default {
  name: 'CommonWorkOrderList',
  components: {
    WorkOrderCreate,
    vModal,
    WorkOrderDetail,
    HandleModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      toggleSearchStatus1:false,
      visible: false,
      orderId: undefined,
      viewCollectionItem: false,
      // fieldKey: 'dictCnText',
      types: [],// 工单类型
      statuses: [],// 工单状态
      operateTypes: [],// 工作流操作类型
      viewApprove: false,
      toDetailViewCollectionSelectItem: false,
      // firstLevelQuestions: [],// 一级问题
      // secondLevelQuestions: [],// 二级问题
      questionV1s: [],// 问题标签V1
      custAppeals: [],// 客户诉求
      complainChannels: [],// 客诉渠道
      useColumns: [],
      levels: [],
      handleStatuses: [],
      satisfactions: [],
      connects: [],
      modelTags: [],
      backVisits: [],
      // canPasses: [],// 是否转让
      apps: [],// 包列表
      days: ['all','yesterday','today','last7days','last1month','other'],
      settles: ['all','0','1'],
      orderTypes:['0','1'],
      toFinances: ['all','0','1'],
      topStatuses: [],
      country: '',
      fieldName: 'dictTextCn',
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "account": '',
        "custQueryData": '',
        "status": '',
        "createdTime": undefined,
        "days": '',
        "app": '',
        "type": '',
        // "firstLevelQuestion": '',
        // "secondLevelQuestion": '',
        "questionV1": '',
        "custAppeal": '',
        "pageType": 'private',
        "complainChannel": '',
        "orderNo": '',
        "level": '',
        "handleStatus": '',
        "settle": 'all',
        "topStatus": '',
        "tagQuery": '',
        "orderType": '',
        "toFinance": 'all',
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
    }
  },

  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          align: 'left',
          width: 150,
          dataIndex: 'workOrderNo'
        },
        // {
        //   title: this.$t(I18N_KEY + 'country'),
        //   align: 'left',
        //   dataIndex: 'country'
        // },
        {
          title: this.$t(I18N_KEY + 'account'),
          align: 'left',
          width: 120,
          dataIndex: 'account',
          customRender: (text, record) => {
            if(text === 'appUser') {
              return this.$t(I18N_KEY + 'appUser')
            }
            return text
          }
        },
        {
          title: this.$t(I18N_KEY + 'custMobile'),
          align: 'left',
          width: 120,
          dataIndex: 'custMobile'
        },
        {
          title: this.$t(I18N_KEY + 'userMobile'),
          align: 'left',
          width: 120,
          dataIndex: 'userMobile'
        },
        // {
        //   title: this.$t(I18N_KEY + 'type'),
        //   align: 'left',
        //   dataIndex: 'type',
        //   width: 80,
        //   scopedSlots: { customRender: 'type' }
        // },
        // {
        //   title: this.$t(I18N_KEY + 'questionV1'),
        //   align: 'left',
        //   dataIndex: 'questionV1',
        //   width: 200,
        //   scopedSlots: { customRender: 'questionV1' }
        // },
        {
          title: this.$t(I18N_KEY + 'tag'),
          align: 'left',
          width: 120,
          dataIndex: 'tag',
          customRender: (text, record) => {
            console.log(text)
            if(text != null && text != '') {
              var tags = text.split(",")
              var tagText = '';
              for(var index in tags) {
                var tag = tags[index]
                for(var x in this.modelTags) {
                  if(this.modelTags[x].dictValue == tag) {
                    if(index == 0) {
                      tagText = this.modelTags[x][this.fieldName]
                    } else {
                      tagText = tagText + ',' + this.modelTags[x][this.fieldName]
                    }
                  }
                }
              }
            }
            return tagText
          }
        },
        {
          title: this.$t(I18N_KEY + 'status'),
          align: 'left',
          dataIndex: 'status',
          width: 230,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: this.$t(I18N_KEY + 'toFinance'),// 暴力催收工单处理状态
          align: 'left',
          dataIndex: 'toFinance',
          width: 150,
          scopedSlots: { customRender: 'toFinance' }
        },
        {
          title: this.$t(I18N_KEY + 'level'),// 级别
          align: 'left',
          dataIndex: 'level',
          width: 250,
          scopedSlots: { customRender: 'level' }
        },
        {
          title: this.$t(I18N_KEY + 'handleStatus'),// 暴力催收工单处理状态
          align: 'left',
          dataIndex: 'handleStatus',
          width: 150,
          scopedSlots: { customRender: 'handleStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'satisfaction'),// 满意度
          align: 'left',
          dataIndex: 'satisfaction',
          width: 150,
          scopedSlots: { customRender: 'satisfaction' }
        },
        {
          title: this.$t(I18N_KEY + 'backVisit'),// 满意度
          align: 'left',
          dataIndex: 'backVisit',
          width: 150,
          scopedSlots: { customRender: 'backVisit' }
        },
        {
          title: this.$t(I18N_KEY + 'connect'),// 满意度
          align: 'left',
          dataIndex: 'connect',
          width: 150,
          scopedSlots: { customRender: 'connect' }
        },
        {
          title: this.$t(I18N_KEY + 'custEmail'),
          align: 'left',
          width: 150,
          dataIndex: 'custEmail'
        },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion'),
        //   align: 'left',
        //   dataIndex: 'firstLevelQuestion',
        //   width: 120,
        //   scopedSlots: { customRender: 'firstLevelQuestion' }
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion'),
        //   align: 'left',
        //   dataIndex: 'secondLevelQuestion',
        //   width: 200,
        //   scopedSlots: { customRender: 'secondLevelQuestion' }
        // },
        {
          title: this.$t(I18N_KEY + 'app'),
          align: 'left',
          width: 120,
          dataIndex: 'app'
        },
        {
          title: this.$t(I18N_KEY + 'name'),
          align: 'left',
          width: 150,
          dataIndex: 'name'
        },
        {
          title: this.$t(I18N_KEY + 'surname'),
          align: 'left',
          width: 150,
          dataIndex: 'surname'
        },
        {
          title: this.$t(I18N_KEY + 'complainChannel'),
          align: 'left',
          dataIndex: 'complainChannel',
          width: 120,
          scopedSlots: { customRender: 'complainChannel' }
        },
        {
          title: this.$t(I18N_KEY + 'custAppeal'),
          align: 'left',
          dataIndex: 'custAppeal',
          width: 120,
          scopedSlots: { customRender: 'custAppeal' }
        },
        {
          title: this.$t(I18N_KEY + 'collectionUser'),
          align: 'left',
          width: 120,
          dataIndex: 'collectionUser'
        },
        {
          title: this.$t(I18N_KEY + 'collectionMobile'),
          align: 'left',
          width: 120,
          dataIndex: 'collectionMobile'
        },
        {
          title: this.$t(I18N_KEY + 'collectionDate'),
          align: 'left',
          width: 120,
          dataIndex: 'collectionDate'
        },
        {
          title: this.$t(I18N_KEY + 'orderNo'),
          align: 'left',
          width: 150,
          dataIndex: 'orderNo'
        },
        {
          title: this.$t(I18N_KEY + 'orderType'),
          align: 'left',
          width: 120,
          dataIndex: 'orderType',
          scopedSlots: { customRender: 'orderType' }
        },
        {
          title: this.$t(I18N_KEY + 'custId'),
          align: 'left',
          width: 100,
          dataIndex: 'custId'
        },
        {
          title: this.$t(I18N_KEY + 'workFlowType'),
          align: 'left',
          width: 120,
          dataIndex: 'workFlowType',
          scopedSlots: { customRender: 'workFlowType' }
        },
        {
          title: this.$t(I18N_KEY + 'decision'),
          align: 'left',
          width: 120,
          dataIndex: 'decision',
          scopedSlots: { customRender: 'decision' }
        },
        {
          title: this.$t(I18N_KEY + 'baAmount'),
          align: 'left',
          width: 100,
          dataIndex: 'baAmount'
        },
        {
          title: this.$t(I18N_KEY + 'remark'),
          align: 'left',
          width: 300,
          dataIndex: 'remark'
        },
        {
          title: this.$t(I18N_KEY + 'topStatus'),
          align: 'left',
          width: 120,
          dataIndex: 'topStatus',
          scopedSlots: { customRender: 'topStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'createTime'),
          align: 'left',
          width: 160,
          dataIndex: 'createTime'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData(1);
    this.useColumns = this.baseColumns;
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadBaseData() {
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.types = res.data.types
          // this.canPasses = res.data.canPasses
          this.custAppeals = res.data.custAppeals
          this.statuses = res.data.statuses
          // this.operateTypes = res.data.operateTypes
          this.apps = res.data.apps
          this.complainChannels = res.data.complainChannels
          // this.firstLevelQuestions = res.data.firstLevelQuestions
          this.questionV1s = res.data.questionV1s
          this.levels = res.data.levels
          this.handleStatuses = res.data.handleStatuses
          this.satisfactions = res.data.satisfactions
          this.connects = res.data.connects
          this.backVisits = res.data.backVisits
          this.topStatuses = res.data.topStatuses
          this.modelTags = res.data.tags
          this.country = res.data.country
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      workOrderList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
          this.viewCollectionItem = res.data.viewCollectionItem
          this.isCsManage = res.data.isCsManage
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    // handleFirstLevelQuestionChange(firstLevelQuestion) {
    //   secondLevelQuestion(firstLevelQuestion).then((res) => {
    //     this.secondLevelQuestions = res.data.secondLevelQuestions;
    //     this.toggleSearchStatus1 = true;
    //   }).catch(e => {
    //     this.$message.error(e.message);
    //   })
    // },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.account = ''
      that.queryParam.custQueryData = ''
      that.queryParam.status = ''
      that.queryParam.createdTime = undefined
      that.queryParam.days = '';
      that.queryParam.app = ''
      that.queryParam.type = ''
      // that.queryParam.firstLevelQuestion = ''
      // that.queryParam.secondLevelQuestion = ''
      that.queryParam.questionV1 = ''
      that.queryParam.custAppeal = ''
      that.queryParam.level = ''
      that.queryParam.handleStatus = ''
      that.queryParam.complainChannel = ''
      that.queryParam.orderNo = ''
      this.toggleSearchStatus = false;
      this.toggleSearchStatus1 = false;
      this.queryParam.settle = 'all'
      this.queryParam.topStatus = ''
      this.queryParam.tagQuery = ''
      this.queryParam.orderType = ''
      this.queryParam.toFinance = 'all'
      // that.loadData(this.form.pageNum)
    },
    changeModal(visible) {
      this.visible = visible;
    },
    handleDetail(row) {
      this.orderId = row.id;
      this.operateTypes = row.operateTypes;
      this.viewApprove = row.viewApprove;
      this.toDetailViewCollectionSelectItem = row.viewCollectionSelectItem;
      this.changeModal(true);
    },
    handle(record){
      this.$refs.modalForm.open();
      this.$refs.modalForm.workOrderId=record.id;
      this.$refs.modalForm.operateTypes=record.operateTypes;
      this.$refs.modalForm.disableSubmit = false;
    },
    modalFormOk(data) {
      console.log(data)
      const form = {
        ...data,
      };
      handleWorkOrder(form).then(res => {
        if(res.code === 1000){
          this.$message.success('success');
        } else {
          this.$message.error(res.msg);
        }
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        this.$refs.modalForm.clear();
        this.loadData(1);
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    repair(record){
      console.log(record)
      const form = {
        id: record.id,
      };
      repairWorkOrder(form).then(res => {
        if(res.code === 1000){
          this.$message.success('success');
        } else {
          this.$message.error(res.msg);
        }
        this.loadData(1);
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    closeLoad() {
      this.visible = false
      this.loadData(1)
    },
    // handle1(record) {
    //   const self = this;
    //   this.$confirm({
    //     title: this.$t(I18N_KEY + 'inputOptRemark'),// 请输入操作备注
    //     content: h => {
    //       return <div>
    //         <div>
    //           Remark ：<input id='optRemark' type='text'/>
    //         </div>
    //       </div>
    //     },
    //     onOk() {
    //       self.loading = true;
    //       var optRemark = document.getElementById("optRemark").value;
    //
    //       if (!optRemark.trim()) {
    //         alert(this.$t(I18N_KEY + 'optRemarkMustNotNull'));// 操作备注不能为空
    //         self.loadData();
    //         return false;
    //       }
    //       handleWorkOrder({id: record.id, optRemark: optRemark}).then(res => {
    //         if (res.code === 1000) {
    //           self.loadData();
    //         } else {
    //           self.$message.error(res.msg);
    //           self.loadData();
    //         }
    //
    //       }).catch(e => {
    //         self.loading = false;
    //         self.$message.error(e.message);
    //       })
    //     },
    //   });
    // },
    handleDaysChange(select) {
      if (select === 'other') {
        this.toggleSearchStatus = true;
      } else {
        this.toggleSearchStatus = false;
      }
    },
    create(){
      this.visible1 = true;
    },
    test(){
      this.$router.push({
        path: '/webpublic/custService/Dict'
      })
    },
    // circleQuery: function (stores, field) {
    //   for (var item in stores) {
    //     if(stores[item].dictValue === field) {
    //       return stores[item][this.fieldName];
    //     }
    //   }
    // },
    updateRecord(record) {
      var form = {
        id: record.id,
        level: record.level,
        handleStatus: record.handleStatus,
        satisfaction: record.satisfaction,
        topStatus: record.topStatus,
      }
      this.loading = true
      updateCollectionOrder(form).then(res => {
        if (res.code === 1000) {
          this.$message.success('success');
          this.loading = false
          this.loadData(this.form.pageNum)
        } else {
          this.$message.error(res.msg);
          this.loading = false
          this.loadData(this.form.pageNum)
        }
      })
    },
    levelStyle(record) {
      var level = record.level
      switch (level) {
        case '1': return "width: 100%; color: rgb(248, 213, 184); font-weight: bold;";break;
        case '2': return "width: 100%; color: rgb(192, 66, 138); font-weight: bold;";break;
        case '3': return "width: 100%; color: rgb(129, 228, 189); font-weight: bold;";break;
        case '4': return "width: 100%; color: rgb(240, 86, 39); font-weight: bold;";break;
        case '5': return "width: 100%; color: rgb(52, 49, 72); font-weight: bold;";break;
        default: return "width: 100%;";break;
      }
    },
    handleStatusStyle(record) {
      var handleStatus = record.handleStatus
      switch (handleStatus) {
        case '0': return "width: 100%; color: rgb(129, 228, 189); font-weight: bold;";break;
        case '1': return "width: 100%; color: rgb(241, 199, 0); font-weight: bold;";break;
        default: return "width: 100%;";break;
      }
    },
    satisfactionStyle(record) {
      var satisfaction = record.satisfaction
      switch (satisfaction) {
        case '0': return "width: 100%; color: rgb(210, 207, 196); font-weight: bold;";break;
        case '1': return "width: 100%; color: rgb(129, 228, 189); font-weight: bold;";break;
        case '2': return "width: 100%; color: rgb(241, 199, 0); font-weight: bold;";break;
        default: return "width: 100%;";break;
      }
    },
    downLoadData () {
      const params = this.getQueryParams()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/custService/workOrders/list/export?exportType=common&account=' + this.checkParam(params.account)
          + '&custQueryData=' + this.checkParam(params.custQueryData)
          + '&startDate=' + this.checkParam(params.startDate)
          + '&endDate=' + this.checkParam(params.endDate)
          + '&days=' + this.checkParam(params.days)
          + '&app=' + this.checkParam(params.app)
          + '&type=' + this.checkParam(params.type)
          + '&custAppeal=' + this.checkParam(params.custAppeal)
          + '&questionV1=' + this.checkParam(params.questionV1)
          + '&pageType=' + this.checkParam(params.pageType)
          + '&orderType=' + this.checkParam(params.orderType)
          + '&toFinance=' + this.checkParam(params.toFinance)
          + '&orderNo=' + this.checkParam(params.orderNo)
          + '&complainChannel=' + this.checkParam(params.complainChannel)
          + '&level=' + this.checkParam(params.level)
          + '&handleStatus=' + this.checkParam(params.handleStatus)
          + '&settle=' + this.checkParam(params.settle)
          + '&topStatus=' + this.checkParam(params.topStatus)
          + '&tagQuery=' + this.checkParam(params.tagQuery)
          + '&status=' + this.checkParam(params.status),
        fileName: 'CustomerServiceWorkOrder-' + this.country + '.xlsx',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    checkParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return '';
      }
      return p;
    },
    rowClassName(record,index) {
      var topSort = record.topSort;
      if(topSort == '1') {
        return 'topColor';
      }
      return '';
    },
  },
  watch: {
    viewCollectionItem: function (newVal) {
      if(newVal) {
        // 采用催收字段
        this.useColumns = this.collectionColumns;
      } else {
        // 恢复原数组
        this.useColumns = this.baseColumns;
      }
    },
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'dictTextEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'dictTextCn';
        } else if (lang == 'es') {
          this.fieldName = 'dictTextEs';
        } else {
          this.fieldName = 'dictTextEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<!--<style>-->
<!--  ::v-deep .secondLevelQuestion .ant-select-selection-selected-value {-->
<!--    overflow: hidden;-->
<!--    text-overflow: ellipsis;-->
<!--    width: fit-content;-->
<!--  }-->
<style lang="less" scoped>
  @import "~@/assets/less/common.less";
</style>
<style>
.topColor {
  background-color: lightskyblue;
}
</style>