<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'account')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'account')" v-model="queryParam.account"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'dateLabel')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'dateLabel')" v-model="queryParam.dateLabel"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'reportType')">
              <a-select v-model="queryParam.type" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in reportTypes" :value="item">
<!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'reportType_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

<!--          <template v-if="toggleSearchStatus">-->
<!--            <a-col :md="8" :sm="12">-->
<!--              <a-form-item :label="$t(I18N_KEY + 'createdTime')">-->
<!--                <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--          </template>-->

          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <template>
            <a-popconfirm title="Are you sure？" ok-text="Yes" cancel-text="No" @confirm="createReport('')">
              <template #icon><question-circle-outlined style="color: red" /></template>
              <a-button type="primary" style="margin-left: 8px">{{ $t('createReport') }}</a-button>
            </a-popconfirm>
          </template>
              <a-button type="primary" @click="downLoadData" icon="reload" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
<!--              <a-button type="primary" @click="batchCreateReport('')" style="margin-left: 8px">批量生成报表</a-button>-->
              <!--              <a-button type="primary" @click="downLoadData2('')" icon="download2" style="margin-left: 8px">导出2</a-button>-->
            </span>
          </a-col>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1300 }"
        bordered>
        <template #type="text,record,index">{{$t(I18N_KEY + 'reportType_' + text)}}</template>
        <span slot="action" slot-scope="text, record">
<!--          <a @click="handleReCreate(record)">{{ $t('reCreate') }}</a>-->
          <template>
            <a-popconfirm title="Are you sure？" ok-text="Yes" cancel-text="No" @confirm="handleReCreate(record)">
              <template #icon><question-circle-outlined style="color: red" /></template>
              <a href="#">{{ $t('reCreate') }}</a>
            </a-popconfirm>
          </template>
          <a-divider type="vertical"/>
<!--          <a @click="handleDelete(record)">{{ $t('delete') }}</a>-->
          <template>
            <a-popconfirm title="Are you sure？" ok-text="Yes" cancel-text="No" @confirm="handleDelete(record)">
              <template #icon><question-circle-outlined style="color: red" /></template>
              <a href="#">{{ $t('delete') }}</a>
            </a-popconfirm>
          </template>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import {reportList, createReport, deleteReport} from '@/webpublicapi/custServiceWorkOrder';
import vModal from "@/views/webpublic/modules/Modal";
import {mapGetters} from "vuex";
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import _ from 'lodash';
import exportFile from '@/webpublicapi/exportFile'
// import { defineComponent } from 'vue';
const I18N_KEY = 'custServiceWorkOrder.';
export default {
  name: 'WorkOrderReportList',
  components: {
    vModal,
    QuestionCircleOutlined,
  },
  data () {
    return {
      I18N_KEY,
      // toggleSearchStatus: false,
      reportTypes: ['1', '2'],// 报表类型1-日报，2-周报
      // types: [
      //   {dictValue: '1', dictCnText: '日报', dictEnText: 'day', dictEsText: 'día'},
      //   {dictValue: '2', dictCnText: '周报', dictEnText: 'week', dictEsText: 'semana'},
      // ],// 报表类型
      // fieldName: 'dictCnText',
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "account": '',
        "type": '',
        "dateLabel": '',
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns() {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          align: 'left',
          width: 60,
          dataIndex: 'id'
        },
        {
          title: this.$t(I18N_KEY + 'account'),
          align: 'left',
          width: 120,
          dataIndex: 'account'
        },
        {
          title: this.$t(I18N_KEY + 'reportType'),
          align: 'left',
          width: 120,
          dataIndex: 'type',
          scopedSlots: {customRender: 'type'}
        },
        {
          title: this.$t(I18N_KEY + 'dateLabel'),
          align: 'left',
          width: 160,
          dataIndex: 'dateLabel'
        },
        {
          title: this.$t(I18N_KEY + 'totalCount'),
          align: 'left',
          width: 100,
          dataIndex: 'totalCount'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_1'),
          align: 'left',
          width: 100,
          dataIndex: 'complaintCount'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_2'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_1_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_3'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_1_1_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_4'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_1_2_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_5'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_1_3_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_6'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_1_4_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_7'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_1_5_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_8'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_2_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_9'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_2_1_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_10'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_2_2_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_11'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_3_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_12'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_3_1_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_13'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_4_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_14'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_4_1_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_15'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_5_count'
        },
        {
          title: this.$t(I18N_KEY + 'questionV1_16'),
          align: 'left',
          width: 100,
          dataIndex: 'complaint_5_1_count'
        },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_5_2_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_3'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_5_3_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_4'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_5_4_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_5'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_5_5_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_6'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_5_6_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_6'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_6_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_6_1'),
        //   align: 'left',
        //   width: 200,
        //   dataIndex: 'complaint_6_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_6_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_6_2_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_7'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_7_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_7_1'),
        //   align: 'left',
        //   width: 150,
        //   dataIndex: 'complaint_7_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_7_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_7_2_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_8'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_8_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_8_1'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_8_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_8_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'complaint_8_2_count'
        // },
        //
        //
        //
        // {
        //   title: this.$t(I18N_KEY + 'consult'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consultCount'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_1'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_1_1'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_1_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_1_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_1_2_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_1_3'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_1_3_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_1_4'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_1_4_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_1_5'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_1_5_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_2_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_2_1'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_2_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_2_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_2_2_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_3'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_3_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_3_1'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_3_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_4'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_4_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_4_1'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_4_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_5'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_5_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_1'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_5_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_5_2_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_3'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_5_3_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_4'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_5_4_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_5'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_5_5_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_5_6'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_5_6_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_6'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_6_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_6_1'),
        //   align: 'left',
        //   width: 200,
        //   dataIndex: 'consult_6_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_6_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_6_2_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_7'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_7_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_7_1'),
        //   align: 'left',
        //   width: 150,
        //   dataIndex: 'consult_7_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_7_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_7_2_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'firstLevelQuestion_8'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_8_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_8_1'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_8_1_count'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'secondLevelQuestion_8_2'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'consult_8_2_count'
        // },


        {
          title: this.$t(I18N_KEY + 'complainChannel_1'),
          align: 'left',
          width: 100,
          dataIndex: 'channelWhatappCount'
        },
        {
          title: this.$t(I18N_KEY + 'complainChannel_2'),
          align: 'left',
          width: 100,
          dataIndex: 'channelPhoneCount'
        },
        {
          title: this.$t(I18N_KEY + 'complainChannel_3'),
          align: 'left',
          width: 100,
          dataIndex: 'channelEmailCount'
        },
        {
          title: this.$t(I18N_KEY + 'complainChannel_4'),
          align: 'left',
          width: 100,
          dataIndex: 'channelCrisipCount'
        },
        {
          title: this.$t(I18N_KEY + 'complainChannel_5'),
          align: 'left',
          width: 100,
          dataIndex: 'channelRegulatoryCount'
        },
        {
          title: this.$t(I18N_KEY + 'complainChannel_6'),
          align: 'left',
          width: 100,
          dataIndex: 'channelPaymentCount'
        },
        {
          title: this.$t(I18N_KEY + 'complainChannel_7'),
          align: 'left',
          width: 100,
          dataIndex: 'channelCourtCount'
        },
        {
          title: this.$t(I18N_KEY + 'complainChannel_8'),
          align: 'left',
          width: 100,
          dataIndex: 'channelProcuratorateCount'
        },


        // {
        //   title: this.$t(I18N_KEY + 'remark'),
        //   align: 'left',
        //   width: 100,
        //   dataIndex: 'remark'
        // },
        {
          title: this.$t(I18N_KEY + 'createTime'),
          align: 'left',
          width: 160,
          dataIndex: 'createTime'
        },
        {
          title: this.$t(I18N_KEY + 'updateTime'),
          align: 'left',
          width: 160,
          dataIndex: 'updateTime'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 150,
          scopedSlots: {customRender: 'action'}
        }
      ]
    },
  },
  // watch: {
  //   languageStorage: {
  //     handler (lang) {
  //       if (lang == 'en-US') {
  //         this.fieldName = 'dictEnText';
  //       } else if (lang == 'zh-CN') {
  //         this.fieldName = 'dictCnText';
  //       } else if (lang == 'es') {
  //         this.fieldName = 'dictEsText';
  //       }
  //     },
  //     immediate: true,
  //   }
  // },
  created () {
    this.loadData(1);
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      reportList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    getQueryParams() {
      const data = {
        ...this.queryParam,
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    searchReset() {
      var that = this
      that.queryParam.account = ''
      that.queryParam.type = ''
      that.queryParam.dateLabel = ''
      // this.toggleSearchStatus = false;
      // that.loadData(this.form.pageNum)
    },
    createReport: _.debounce(function () {
      var params = this.getQueryParams();
      // params.methods = 'signle';
      this.doCreate(params);
    }, 2000),
    // batchCreateReport: _.debounce(function () {
    //   var params = this.getQueryParams();
    //   params.methods = 'batch';
    //   this.doCreate(params);
    // }, 10000),
    // createReport() {
    //   var params = this.getQueryParams();
    //   this.doCreate(params);
    // },
    handleReCreate(record) {
      const data = {
        account: record.account,
        type: record.type,
        dateLabel: record.dateLabel
      }
      var param = Object.assign({}, data)
      this.doCreate(filterObj(param))
    },
    doCreate(params) {
      createReport(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.$message.info("success")
          this.loadData('')
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    handleDelete(record) {
      deleteReport(record.id).then((res) => {
        if (res.code === 1000) {
          this.$message.info("success")
          this.loadData('')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    downLoadData () {
      const params = this.getQueryParams()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/custService/report/export?account=' + this.checkParam(params.account) + '&type=' + this.checkParam(params.type) + '&dateLabel=' + this.checkParam(params.dateLabel),
        fileName: 'CustomerServiceReport.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    checkParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return '';
      }
      return p;
    },
  },
}
</script>