<template>
  <div>
    <a-spin :spinning="loading">
      <a-form layout="inline" @keyup.enter.native="loadData">
        <a-form-item>
          <a-range-picker
            v-model="queryParam.date"
            :disabled-date="disabledDate"
            @calendarChange="handleChange"
            @blur="handleBlur"
            format="YYYY-MM-DD"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item>
          <a-select
            default-value="5"
            style="width: 190px"
            v-model="queryParam.normalConfigId"
            :options="[
              { value: '5', label: 't0' },
              { value: '6', label: 's1' },
              { value: '8', label: 's2' },
              { value: '9', label: 's3' },
              { value: '10', label: 's4' },
              { value: '11', label: 'm1' },
            ]"
          />
        </a-form-item>

        <a-form-item :label="$t('platformCollection.department')">
          <a-select style="width: 190px" v-model="queryParam.departmentId" @change="onChangeOne">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option v-for="item in departmentList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="$t('platformCollection.grouping')">
          <a-select style="width: 190px" v-model="queryParam.groupId" @change="onChangeTwo">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option v-for="item in groupList" :key="item.id">
              {{ item.groupName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('platformCollection.name_of_collector')">
          <a-select style="width: 190px" v-model="queryParam.userId">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option v-for="item in userList" :key="item.id">
              {{ item.userName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item class="table-page-search-submitButtons">
          <a-button type="primary" @click="loadData" icon="search">{{ $t('search') }}</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
    <div id="container"></div>
    <div class="tableBox" v-show="tableShow">
      <div class="buttonRow">
        <a-button type="primary" @click="downLoadData()">导出</a-button>
      </div>
      <a-table id="tablex" :columns="tablecolums" :data-source="tableData" bordered :scroll="{ x: 2000, y: 300 }" :pagination="false"></a-table>
    </div>
  </div>
</template>
<script>
import G2 from '@antv/g2'
// import echarts from "@/utils/echarts"
import { collectionCountByHour} from '@/api/collection'
import { getCollectionListDepartment } from '@/api/group'
import { getSysGroup, getColUserListByGroupId } from '@/api/platformCollection'
import exportFile from "@/api/exportFile";
import moment from 'moment'
import { filterObj } from '@/utils/util'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}
export default {
  data() {
    return {
      obj:{},
      tableShow:false,
      I18N_KEY,
      loading: false,
      queryParam: {
        date: [moment().add('days', -7), moment().add('days', -1)],
        normalConfigId: '5',
        departmentId: '',
        groupId: '',
        userId: '',
      },
      selected: null,
      chart: null,
      groupList: [],
      departmentList: [],
      userList: [],
      tableData: [
        {
          key: 'today',
          name: 'today',
        },
        {
          key: 'yesterday',
          name: 'yesterday',
        },
        {
          key: 'three',
          name: 'three',
        },
      ],
      tablecolums: [
       {
          title: '催收阶段-部门-组-人',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          fixed: 'left',
        }
      ],
    }
  },
  created() {
    getCollectionListDepartment().then((res) => {
      this.departmentList = res.data || []
    })
  },
  methods: {
    downLoadData () {
      const date = this.queryParam.date
      const file = {
        fileUrl:`/api/web/collectionReplaceTask/downLoadcollectionCountByHour?normalConfigId=${this.queryParam.normalConfigId}&dateStart=${this.transformTime(date[0])}&dateEnd=${this.transformTime(date[1])}&departmentId=${this.queryParam.departmentId}&groupId=${this.queryParam.groupId}&userId=${this.queryParam.userId}&fileName=downLoadcollectionCountByHourExcel`,
        fileName:"downLoadcollectionCountByHourExcel.xlsx"
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    tableMethod(){
      this.tablecolums=[
        {
          title: '催收阶段-部门-组-人',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          fixed:"left"
        }
      ],
      this.tableData=[
           {
          key: 'today',
          name: 'today',
        },
        {
          key: 'yesterday',
          name: 'yesterday',
        },
        {
          key: 'three',
          name: 'customize',
        },
      ],
      this.obj.data.forEach((item,index)=>{
        item.today.forEach((item2,index2)=>{
          let obj={
          title: item2.curHour,
          dataIndex: item2.curHour,
          key: item2.curHour,
        }
        this.tablecolums.push(obj)
        this.tableData[0][item2.curHour]=item2.backRate+"%"
        })
        item.yesterday.forEach((item3)=>{
            this.tableData[1][item3.curHour]=item3.backRate+"%"
        })
        item.three.forEach((item4)=>{
            this.tableData[2][item4.curHour]=item4.backRate+"%"
        })
      })
    },
    handleChange([start, end]) {
      if (start && !end) {
        this.selected = start
      } else {
        this.selected = null
      }
    },
    handleBlur() {
      this.selected = null
    },
    disabledDate(current) {
      if (!this.selected) return false
      return current.diff(this.selected, 'days') > 6 || current.diff(this.selected, 'days') < -6
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    loadData() {
      const date = this.queryParam.date
      if (!date[0] || !date[0]) return this.$message.warning('请选择起止日期')
      if (!this.queryParam.normalConfigId) return this.$message.warning('请选择阶段')
      this.loading = true
      if (!this.chart) {
        this.chart = new G2.Chart({
          container: 'container',
          forceFit: true,
          height: 500,
          padding: [32, 32, 32, 32],
        })
     
      } else {
        this.chart.clear()
      }

      collectionCountByHour({
        dateStart: this.transformTime(date[0]),
        dateEnd: this.transformTime(date[1]),
        normalConfigId: this.queryParam.normalConfigId,
        departmentId: this.queryParam.departmentId,
        groupId: this.queryParam.groupId,
        userId: this.queryParam.userId,
      }) 
        .then((res) => {
          const chart = this.chart
          chart.scale({
            curdate: {
              alias: '时段',
              sync: true,
            },
            backRate: {
              alias: '催回率',
              sync: true,
              formatter: (i) => `${i}%`,
            },
          })
          res.data.forEach((item, index) => {
            Object.keys(item).forEach((key, _index) => {
              const list = new Array(24).fill(null).map((_, i) => ({
                backRate: 0.0,
                curHour: `${i < 10 ? '0' : ''}${i}`,
                curdate: key,
              }))
              item[key].forEach((item) => {
                item['curdate'] = key
                ;(item['curHour'] = `${item['curHour'] < 10 ? '0' : ''}${item['curHour']}`),
                  (list[Number(item['curHour'])] = item)
              })
              const view = chart.view()
              !!index && view.axis(false)
              view.source(list)
              view
                .line()
                .position('curHour*backRate')
                .shape('dv')
                .color(['red', 'green', 'blue'][_index % 3])
                .tooltip('curdate*backRate*curHour', (curdate, backRate,curHour) => {
     
                  return {
                    title:"hour:"+curHour,
                    name: curdate==="three" ? 'customize' : curdate,
                    value: backRate,
                  }
                })
            })
          }, [])
          chart.render()
             this.obj=res
        this.tableShow=true
        this.tableMethod()
          this.loading = false
        })
        .catch((_) => (this.loading = false))
    },
    searchQuery() {},
    onChangeOne(value) {
      console.log(value)
      getSysGroup({ departmentId: value }).then((res) => {
        this.groupList = res.data || []
        this.queryParam.groupId = ''
      })
    },
    onChangeTwo(value) {
      getColUserListByGroupId({ groupId: value }).then((res) => {
        this.userList = res.data || []
        this.queryParam.userId = ''
      })
    },
  },
}
</script>
