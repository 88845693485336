<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
<!--          <a-col :md="6" :sm="8">-->
<!--            <a-form-item label="name">-->
<!--              <a-input :placeholder="$t('enter') + ' name'" v-model="form.name"></a-input>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <a-col :md="6" :sm="8">-->
<!--            <a-form-item label="前端页面">-->
<!--              <a-select v-model="form.pageId" :placeholder="$t('select')" style="width: 100%;" :allowClear='allowClear'>-->
<!--                <a-select-option v-for="item in pages" :value="item.value">-->
<!--                  {{ item.value + '-' +item[fieldName] }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <a-col :md="6" :sm="8">-->
<!--            <a-form-item label="状态">-->
<!--              <a-select v-model="form.status" :placeholder="$t('select')" style="width: 100%;" :allowClear='allowClear'>-->
<!--                <a-select-option v-for="item in statuses" :value="item.value">-->
<!--                  {{ item[fieldName] }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <a-col :md="6" :sm="8">-->
<!--            <a-form-item label="新老户">-->
<!--              <a-select v-model="form.userType" :placeholder="$t('select')" style="width: 100%;" :allowClear='allowClear'>-->
<!--                <a-select-option v-for="item in userTypes" :value="item.value">-->
<!--                  {{ item[fieldName] }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <span style="float: left; margin-top: 5px; display: block; margin-bottom: 24px;white-space: nowrap; overflow: hidden;" class="table-page-search-submitButtons">
              <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="searchQuery">{{ $t('search') }}</a-button>
<!--                  <a-button style="margin-left: 8px" @click="searchReset">{{ $t('reset') }}</a-button>-->
              </a-col>
          </span>
        </a-row>
      </a-form>
      <div class="table-operator"  style="margin-top: 5px">
        <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('add') }}</a-button>
      </div>
      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">{{ $t('edit') }}</a>
          <a-divider type="vertical" />
          <a-dropdown>
              <a class="ant-dropdown-link">{{ $t('more') }} <a-icon type="down" /></a>
              <a-menu slot="overlay">
                  <a-menu-item>
                      <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">
                          <a>{{ $t('delete') }}</a>
                      </a-popconfirm>
                  </a-menu-item>
              </a-menu>
          </a-dropdown>
        </span>
      </a-table>
<!--      <div style="margin-top: 15px; text-align: right;">-->
<!--        <v-pagination-->
<!--          v-if="total"-->
<!--          :page-num="form.pageNum"-->
<!--          :page-size="form.pageSize"-->
<!--          :total="total"-->
<!--          @change="handleTableChange" />-->
<!--      </div>-->
      <question-answer-page-switch-edit ref="modalForm" :pages='pages' :field-name='fieldName' :user-types='userTypes' :page-switch-types='pageSwitchTypes' @ok="modalFormOk"></question-answer-page-switch-edit>
    </div>
  </div>
</template>
<script>
import { questionAnsweraddPageSwitch, questionAnswerUpadtePageSwitch, questionAnswerDeletePageSwitch,questionAnswerBaseData} from '@/webpublicapi/operation';
import QuestionAnswerPageSwitchEdit from './modules/QuestionAnswerPageSwitchEdit'
import {mapGetters} from "vuex";
export default {
  components: {
    QuestionAnswerPageSwitchEdit,
  },
  data () {
    return {
      loading: false,
      total: 0,
      userTypes: [],
      statuses: [],
      pageSwitchTypes: [],
      pages: [],
      fieldName: 'textCn',
      allowClear: true,
      form: {
      },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    // 表头
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   width: 100,
        //   align:"center",
        // },
        // {
        //   title: 'name',
        //   align:"center",
        //   width: 200,
        //   dataIndex: 'name',
        // },
        {
          title: '前端页面',
          align:"center",
          dataIndex: 'page',
          customRender: (text, record, index) => {
            for (var item in this.pages) {
              if(this.pages[item].value === text) {
                return text+'-'+this.pages[item][this.fieldName]
              }
            }
            return text
          },
        },
        {
          title: '用户类型',
          dataIndex: 'userType',
          align:"center",
          customRender: (text, record, index) => {
            for (var item in this.userTypes) {
              if(this.userTypes[item].value === text) {
                return this.userTypes[item][this.fieldName]
              }
            }
            return text
          },
        },
        {
          title: '开关配置',
          dataIndex: 'pageSwitch',
          align:"center",
          customRender: (text, record, index) => {
            for (var item in this.pageSwitchTypes) {
              if(this.pageSwitchTypes[item].value === text) {
                return this.pageSwitchTypes[item][this.fieldName]
              }
            }
            return text
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 200,
          fixed: 'right',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }
      ]
    },
  },
  created () {
    this.loadBaseData();
  },
  methods: {
    loadBaseData() {
      this.loading = true;
      questionAnswerBaseData({}).then((res) => {
        if (res.code === 1000) {
          this.userTypes = res.data.userTypes;
          this.pages = res.data.pages;
          this.dataSource = res.data.pageSwitchs;
          this.pageSwitchTypes = res.data.pageSwitchTypes;
          this.loading = false;
        } else {
          this.loading = false;
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    searchQuery () {
      this.loadBaseData()
    },
    // handleTableChange (pageNum, pageSize) {
    //   this.form.pageNum = pageNum;
    //   this.form.pageSize = pageSize;
    //   this.loadData();
    // },
    modalFormOk(data) {
      this.createDict(data);
    },
    handleAdd () {
      this.opt = "1";
      this.userType = undefined;
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "1";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit (record) {
      this.opt = "2";
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "2";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    deleteCurrent (row) {
      this.loading = true;
      questionAnswerDeletePageSwitch(row.page, row.userType).then(res => {
        this.$message.success(this.$t('success'));
        this.loadBaseData()
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    createDict (data) {
      console.log(data)
      const form = {
        ...data,
      };
      switch (this.opt) {
        case "1":
          questionAnsweraddPageSwitch(form).then(res => {
            this.$refs.modalForm.closeLoading();
            this.$refs.modalForm.close();
            this.$message.success('success');
            this.loadBaseData();
          }).catch(e => {
            this.$refs.modalForm.closeLoading();
            this.$message.error(e.message);
          })
          break
        case "2":
          questionAnswerUpadtePageSwitch(form).then(res => {
            this.$refs.modalForm.closeLoading();
            this.$refs.modalForm.close();
            this.$message.success('success');
            this.loadBaseData();
          }).catch(e => {
            this.$refs.modalForm.closeLoading();
            this.$message.error(e.message);
          })
      }

    },
    // searchReset () {
    //   this.form = {
    //   }
    // },
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
