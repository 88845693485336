<template>
  <a-modal :title="modal.title" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @ok="submit"
           @cancel="hide" width="1000rpx">
    <div class="table-search-wrapper">
      <a-button type="primary" @click="handleAdd" style="margin-right: 8px;">添加配置</a-button>
      <a-button type="primary" @click="handleRelease" style="margin-right: 8px;">发布</a-button>
      <a-switch checked-children="新阈值" un-checked-children="原阈值" v-model="model.newValue"></a-switch>
    </div>
    <div>
      <a-table
        ref="table"
        size="middle"
        :columns="table.columns"
        :dataSource="dataSource"
        :loading="table.loading"
        :pagination="false"
        bordered
      >
        <a-card slot="expandedRowRender" slot-scope="text">
          <a-table
            size="small"
            :columns="table.innerColumns"
            :data-source="text.children"
            :pagination="false"
          >
            <a-switch slot="pick" slot-scope="text" :checked="text === 0"></a-switch>
          </a-table>
        </a-card>
        <div slot="pick" slot-scope="text,record">
          <a-switch v-if="!record.children || record.children.length < 2" :checked="text === 0"></a-switch>
        </div>
        <div slot="action" slot-scope="text, record">
          <a @click="handleEdit(record.origin)">编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDel(record.origin)">删除</a>
        </div>
      </a-table>
    </div>
    <rule-use-edit-modal ref="ruleUseEditModal" @success="loadData"></rule-use-edit-modal>
    <rule-use-add-modal ref="ruleUseAddModal" @success="loadData"></rule-use-add-modal>
  </a-modal>
</template>
<script>
import {banRiskFeatures, editRiskRuleAndGroup, releaseRiskRule, riskStrategyFeatures} from "@/api/risk";
import RuleUseEditModal from "@/views/risk/modules/RuleUseEditModal";
import RuleUseAddModal from "@/views/risk/modules/RuleUseAddModal";

export default {
  name: 'RuleUseModal',
  components: {RuleUseAddModal, RuleUseEditModal},
  data() {
    return {
      modal: {title: '决策列表', visible: false, confirmLoading: false},
      table: {
        dataSource: [],
        columns: [
          {
            title: '规则码',
            align: 'center',
            dataIndex: 'featuresName',
            customRender: (text, {children}) => children && children.length ? children.map(({featuresName}) => featuresName).join() : text
          },
          {
            title: '规则说明',
            align: 'center',
            dataIndex: 'featuresIntro',
            customRender: (text, {children}) => children && children.length ? '复合规则' : text
          },
          {
            title: '计算规则',
            align: 'center',
            dataIndex: 'rule',
          },
          {
            title: '执行顺序',
            align: 'center',
            dataIndex: 'sortNum',
          },
          {
            title: '启用',
            dataIndex: 'pick',
            align: "center",
            scopedSlots: {customRender: 'pick'}
          },
          {
            title: '编辑状态',
            align: "center",
            customRender: (text, {origin = []}) => origin.every(({newIsDel}) => newIsDel) ? '删除' : origin.every(({rule, sortNum, pick, andGroup, newRule, newSortNum, newPick, newIsDel, newAndGroup}) =>
              (newRule && rule !== newRule) || (newSortNum && sortNum !== newSortNum) || ([0, 1, '0', '1'].includes(newPick) && pick !== newPick) || (newAndGroup && andGroup !== newAndGroup)
            ) ? '变更' : ''
          },
          {
            title: '操作',
            dataIndex: 'action',
            align: 'center',
            scopedSlots: {customRender: 'action'}
          }
        ],
        innerColumns: [
          {
            title: '规则码',
            align: 'center',
            dataIndex: 'featuresName'
          },
          {
            title: '规则说明',
            align: 'center',
            dataIndex: 'featuresIntro'
          },
          {
            title: '计算规则',
            align: 'center',
            dataIndex: 'rule',
          },
          {
            title: '启用',
            dataIndex: 'pick',
            align: "center",
            scopedSlots: {customRender: 'pick'}
          },
        ],
        loading: false,
      },
      model: {id: undefined, newValue: true}
    }
  },
  computed: {
    dataSource() {
      const map = {}
      return this.table.dataSource.reduce((prev, {id, featuresName, featuresIntro, rule, sortNum, pick, andGroup, isDel, newRule, newSortNum, newPick, newAndGroup, newIsDel}, index) => {
        const item = {
          key: andGroup ? `group_${andGroup}` : `feature_${id}`,
          featuresName,
          featuresIntro,
          rule: this.model.newValue ? newRule || rule : rule,
          sortNum: this.model.newValue ? newSortNum || sortNum : sortNum,
          pick: this.model.newValue ? [0, 1].includes(newPick) ? newPick : pick : pick,
          andGroup: newAndGroup || andGroup,
          isDel: this.model.newValue ? newIsDel : isDel
        }, origin = {
          id,
          featuresName,
          featuresIntro,
          rule,
          sortNum,
          pick,
          andGroup,
          isDel,
          newRule,
          newSortNum,
          newPick,
          newAndGroup,
          newIsDel
        }
        andGroup = newAndGroup || andGroup
        if (andGroup && map[andGroup]) {
          map[andGroup].children.push({...item, key: id})
          map[andGroup].origin.push(origin)
        } else {
          if (!map[andGroup]) {
            map[andGroup] = {
              children: [{...item, key: id}],
              origin: [origin]
            }
            item.children = map[andGroup].children
            item.origin = map[andGroup].origin
          } else {
            item.origin = [origin]
          }
          prev.push(item)
        }
        return prev
      }, [])
    }
  },
  methods: {
    show(id) {
      this.modal.visible = true
      this.model = {id, newValue: true}
      this.table.dataSource = []
      this.loadData()
    },
    hide() {
      this.modal = {title: '', visible: false, confirmLoading: false}
      this.model = {id: undefined, newValue: true}
    },
    loadData() {
      this.table.loading = true
      riskStrategyFeatures(Object.assign({strategyId: this.model.id})).then(({code, data, msg}) => {
        if (code === 1000) {
          this.table.dataSource = data
        } else {
          this.table.dataSource = []
          this.$message.error(msg)
        }
        this.table.loading = false
      }).catch(e => {
        this.table.loading = false;
        this.$message.error(e.message);
      })
    },
    handleAdd() {
      this.$refs.ruleUseAddModal.show(this.model.id)
    },
    handleDel(origin) {
      editRiskRuleAndGroup(origin.map(({id, rule, sortNum, pick, newRule, newSortNum, newPick}) => ({
        id,
        strategyId: this.model.id,
        newRule: newRule || rule,
        newSortNum: newSortNum || sortNum,
        newPick: newPick || pick,
        newIsDel: 1
      }))).then(({code, msg}) => {
        if (code === 1000) {
          this.loadData()
        } else {
          return Promise.reject(Error(msg))
        }
      }).catch(e => {
        this.$message.error(e)
      })
    },
    handleRelease() {
      releaseRiskRule({strategyId: this.model.id}).then(({code, msg}) => {
        if (code === 1000) {
          this.loadData()
        } else {
          return Promise.reject(Error(msg))
        }
      }).catch(e => {
        this.$message.error(e)
      })
    },
    handleEdit(origin) {
      this.$refs.ruleUseEditModal.show(this.model.id, origin)
    },
    submit() {

    }
  }
}
</script>
<style scoped>
.table-search-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
</style>
