<template>
    <div>
        <a-spin :spinning="loading">
          <a-card
            :title="$t(I18N_KEY.ORDER + 'orderInfo')"
            type="inner"
            style="margin-top: 24px;">
            <detail-list>
              <detail-list-item :term="$t(I18N_KEY.ORDER + 'productName')" >{{ orderInfo.productName }}</detail-list-item>
              <detail-list-item :term="$t(I18N_KEY.ORDER + 'applyAmt')">{{orderInfo.applyAmt}}</detail-list-item>
              <detail-list-item :term="$t(I18N_KEY.ORDER + 'submissionTime')" >{{ orderInfo.submitTime }}</detail-list-item>
              <detail-list-item :term="$t(I18N_KEY.ORDER + 'applicationDeadline')" >{{ orderInfo.applyDuration }}</detail-list-item>

              <detail-list-item :term="$t(I18N_KEY.ORDER + 'orderNo')" >{{ orderInfo.orderNo }}</detail-list-item>
<!--              <detail-list-item :term="$t(I18N_KEY.ORDER + 'orderType')" >{{ $t(ORDER_TYPE_CN[orderInfo.orderType]) }}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.ORDER + 'paymentAmt')">{{orderInfo.paymentAmt}}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.ORDER + 'paymentTime')" v-if="orderInfo.settleStatus == 0">{{orderInfo.paymentTime}}</detail-list-item>-->
              <detail-list-item :term="$t(I18N_KEY.ORDER + 'extendAmoumt')" v-if="orderInfo.settleStatus == 0">{{orderInfo.extendAmoumt}}</detail-list-item>
              <detail-list-item :term="$t(I18N_KEY.ORDER + 'repaymentAmount')" v-if="orderInfo.settleStatus == 0">{{orderInfo.repaymentAmount}}</detail-list-item>
              <detail-list-item :term="$t(I18N_KEY.ORDER + 'settleAmt')" v-if="orderInfo.settleStatus == 0">{{orderInfo.settleAmt}}</detail-list-item>
<!--              <detail-list-item :term="$t(I18N_KEY.ORDER + 'lateFee')">{{orderInfo.lateFee }}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.ORDER + 'overdueDayC')">{{orderInfo.overdueDays }}</detail-list-item>-->
<!--              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'createTime')">{{ orderInfo.submitTime }}</detail-list-item>-->
              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'payoutId')">{{ orderInfo.payoutId }}</detail-list-item>
              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankName')"><Dict :dict-code="DICT_CODE.BANKLISTF" :value="bankCardInfo.bankAccountName"/></detail-list-item>
              <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankAccountNo')">{{ bankCardInfo.bankAccountNo }}</detail-list-item>
            </detail-list>
          </a-card>
            <a-card
              style="margin-top: 24px"
              :title="$t(I18N_KEY.CUSTOMER + 'customerBasicInfo')"
              type="inner">
              <detail-list>
                <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'names')">{{ basicInfo.names }}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'surnames')">{{ basicInfo.surnames }}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'curp')">{{ basicInfo.curp }}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankAccountNo')">{{ bankCardInfo.bankAccountNo }}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'collectType')">{{ COLLECT_TYPE[bankCardInfo.collectType] }}</detail-list-item>
                <detail-list-item :term="$t('birthday')">{{ basicInfo.birthday }}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'reborrowFlag')">{{ REBORROW_FLAG_EN[basicInfo.reborrowFlag] }}</detail-list-item>
                <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'createTime')">{{ basicInfo.createdTime }}</detail-list-item>
              </detail-list>
            </a-card>
            <a-card
                style="margin-top: 24px"
                :title="$t(I18N_KEY.CUSTOMER + 'customerLinkInfo')"
                type="inner">
                <a-table
                    :columns="linkColumns"
                    :dataSource="linkmanInfos"
                    :pagination="false"
                    bordered
                    ref="table"
                    size="middle"
                    :rowKey="(record) => record.index">
                    <span slot="contactType" slot-scope="text" :value="text" >{{ $t(CONTACT_TYPE[text]) }}</span>
                    <Dict slot="relationType" slot-scope="text" :dict-code="DICT_CODE.REALTIONSHIP" :value="text" />
                </a-table>
            </a-card>
            <a-card
                style="margin-top: 24px"
                :title="$t(I18N_KEY.CUSTOMER + 'customerImageInfo')"
                type="inner"
                v-viewer="viewOptions">
                <div v-for="(item, index) in imageInfos" :key="item.imgType">
                    <div class="file-box">
                        <div class="file-item">
                            <p class="file-label">{{ FILE_TYPE[item.imgType] }}</p>
                            <img class="file-preview" :src="item.imgUrl" preview="0">
                        </div>
                    </div>
                </div>
            </a-card>

        </a-spin>
    </div>
</template>
<script>
    import { DetailList } from '@/components';
    import { mapActions, mapGetters } from 'vuex';
    import {
      SEX,
      CONTACT_TYPE,
      ORDER_TYPE_CN,
      CURP_TYPE,
      COLLECT_TYPE,
      REBORROW_FLAG_EN
    } from '@/constants/status'
    import Dict from '../modules/Dict';
    import imageMixin from '@/mixins/image-mixin';
    const DetailListItem = DetailList.Item
    const I18N_KEY = {
        CUSTOMER: 'customer.',
        ORDER: 'order.',
    }

    export default {
        props: {
            orderId: {
                type: [String, Number],
                default: undefined,
            },
            custInfoId: {
                type: [String, Number],
                default: undefined,
            },
        },
        components: {
            DetailList,
            DetailListItem,
            Dict,
        },
        mixins: [imageMixin],
        data () {
            return {
                I18N_KEY,
                REBORROW_FLAG_EN,
                SEX,
                CONTACT_TYPE,
              ORDER_TYPE_CN,
              CURP_TYPE,
              COLLECT_TYPE,
                loading: true,
                currentTab: '1',
                linkColumns: [
                    {
                        title: this.$t(I18N_KEY.CUSTOMER + 'contactType'),
                        dataIndex: 'contactType',
                        key: 'contactType',
                        align:"center",
                        scopedSlots: { customRender: 'contactType' }
                    },
                    {
                        title: this.$t(I18N_KEY.CUSTOMER + 'contactRelationship'),
                        dataIndex: 'relationType',
                        key: 'relationType',
                        align:"center",
                        scopedSlots: { customRender: 'relationType' }
                    },
                    {
                        title: this.$t(I18N_KEY.CUSTOMER + 'contactName'),
                        dataIndex: 'contactName',
                        key: 'contactName',
                        align:"center"
                    },
                    {
                        title: this.$t(I18N_KEY.CUSTOMER + 'contactPhone'),
                        dataIndex: 'contactPhone',
                        key: 'contactPhone',
                        align:"center"
                    },
                ],
                FILE_TYPE: {
                    'aadhaar_front': this.$t(I18N_KEY.CUSTOMER + 'aadhaarFront'),
                    'aadhaar_back': this.$t(I18N_KEY.CUSTOMER + 'aadhaarBack'),
                    'pan_front': this.$t(I18N_KEY.CUSTOMER + 'panFront'),
                    'face_photo_list': this.$t(I18N_KEY.CUSTOMER + 'facePhotoList'),
                },
                repayPlanColumns: [
                    {
                        title: this.$t(I18N_KEY.ORDER + 'totalPeriod'),
                        dataIndex: 'totalPeriod',
                        align:"center",
                        customRender: (text, record, index) => {
                            record.index = index;
                            return text;
                        }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'currentPeriod'),
                        dataIndex: 'period',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'startingTime'),
                        dataIndex: 'beginDate',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'expireTime'),
                        dataIndex: 'endDate',
                        align: 'center',
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'totalRepayableAmount'),
                        dataIndex: 'totalAmt',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'principalPayable'),
                        dataIndex: 'capital',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'overdueDays'),
                        dataIndex: 'overdueDays',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'forfeitPenalty'),
                        dataIndex: 'lateFee',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'status'),
                        dataIndex: 'status',
                        align:"center",
                    },
                ],
                collectionColumns: [
                    {
                        title: this.$t(I18N_KEY.ORDER + 'collectionStaff'),
                        dataIndex: 'followUserName',
                        align:"center",
                        customRender: (text, record, index) => {
                            record.index = index;
                            return text;
                        }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'collectionTime'),
                        dataIndex: 'createTime',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'collectionTaskStatus'),
                        dataIndex: 'collectionTaskStatus',
                        align:"center",
                        scopedSlots: { customRender: 'collectionTaskStatus' }
                    }
                ],
                examineColumns: [
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalStaff'),
                        dataIndex: 'examineUserName',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalTime'),
                        dataIndex: 'examineTime',
                        align:"center",
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalTaskStatus'),
                        dataIndex: 'approveTaskStatus',
                        align:"center",
                        scopedSlots: { customRender: 'approveTaskStatus' }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalStatus'),
                        dataIndex: 'approveStatus',
                        align:"center",
                        scopedSlots: { customRender: 'approveStatus' }
                    },
                    {
                        title: this.$t(I18N_KEY.ORDER + 'approvalRemark'),
                        dataIndex: 'comment',
                        align:"center",
                    },
                ],
              repayRecordColumns: [
                {
                  title: this.$t(I18N_KEY.ORDER + 'payOrderNo'),
                  dataIndex: 'payOrderNo',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'payType'),
                  dataIndex: 'payType',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'payAmount'),
                  dataIndex: 'payAmount',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'payRecipt'),
                  dataIndex: 'payRecipt',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'description'),
                  dataIndex: 'description',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'errorDescription'),
                  dataIndex: 'errorDescription',
                  align:"center",
                },
                {
                  title: this.$t(I18N_KEY.ORDER + 'recordTime'),
                  dataIndex: 'recordTime',
                  align:"center",
                },
              ],

            }
        },
        computed: {
            ...mapGetters('order', [
                'orderDetail',
                'repayPlanList',
                'examineLogList',
                'repayRecordList',
            ]),
          ...mapGetters('platformCollection', [
            'allCollectionTaskList',
          ]),
            ...mapGetters('customer', [
                'customerDetail',
            ]),
            basicInfo () {
                return (this.customerDetail && this.customerDetail.basicInfo) || {};
            },
            detailInfo () {
                return (this.customerDetail && this.customerDetail.detailInfo) || {};
            },
            linkmanInfos () {
                return (this.customerDetail && this.customerDetail.linkmanInfos) || [];
            },
            aadhaarInfo () {
                return (this.customerDetail && this.customerDetail.aadhaarInfo) || {};
            },
            panInfo () {
                return (this.customerDetail && this.customerDetail.panInfo) || {};
            },
            imageInfos () {
                return (this.customerDetail && this.customerDetail.imageInfos) || [];
            },
            bankCardInfo () {
                return (this.customerDetail && this.customerDetail.bankCardInfo) || [];
            },
            orderInfo () {
                return (this.orderDetail || {}).orderInfo || {};
            },
            repayPlanSource () {
                return this.repayPlanList || [];
            },
            examineSource () {
                return this.examineLogList || [];
            },
            repayRecordSource () {
              return this.repayRecordList || [];
            },
            collectionSource () {
              return this.allCollectionTaskList || [];
            }
        },
        created () {
            this.requestDetail(this.orderId);
        },
        methods: {
            ...mapActions('order', [
                'getOrderDetail',
                'getRepayPlanList',
                'getExamineLogList',
                'getRepayRecordList',


            ]),
          ...mapActions('platformCollection', [
            'getAllCollectionTaskList',
          ]),
            ...mapActions('customer', [
                'getCustomerDetail',
            ]),
            changeTab (tab) {
                this.active = tab;
                this.$router.push({
                    name: this.$route.name,
                    query: {
                        tab,
                    }
                })
            },
            activeTab () {
                this.active = this.$route.query.tab ? this.$route.query.tab : '1';
            },
            onTabChange (key) {
                this.currentTab = key;
            },
            requestDetail (id) {
                this.getRepayPlanList({ orderId: id });
                this.getExamineLogList({ orderId: id });
                this.getRepayRecordList({ orderId: id });
                this.getAllCollectionTaskList({ orderId: id });
                Promise.all([
                    this.getOrderDetail({ id }),
                    this.getCustomerDetail({ id: this.custInfoId }),
                ]).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.$message.error(e.message);
                    this.loading = false;
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .description-list .content {
        font-weight: 700;
    }
    .file-box {
        float: left;
        width: 200px;
        height: 200px;
        margin-right: 10px;
        margin: 0 8px 8px 0;

        .file-item {
            width: 100%;
            height: 100%;
            position: relative;
            border: 1px solid #d9d9d9;
            border-radius: 4px;

            .file-preview {
                padding: 8px;
                width: 182px;
                height: 142px;
            }

            .file-label {
                margin: 0;
                text-align: center;
                border-bottom: 1px solid #d9d9d9;
                padding: 8px;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
</style>