<template>
    <div class="customerListBox">
        <!-- 查询区域 -->
        <div class="table-page-search-wrapper">
            <a-form layout="inline" @keyup.enter.native="searchQuery">
                <a-row :gutter="24">
                    <a-col :md="6" :sm="12">
                        <a-form-item :label="$t('name')">
                            <a-input
                                :placeholder="$t('enter') + ' ' + transformStringToLow($t('name'))"
                                v-model="queryParam.userName"/>
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="8">
                        <a-form-item :label="$t('phoneNo')">
                          <a-input
                            :placeholder="$t('enter') + ' ' + transformStringToLow($t('phoneNo'))"
                            v-model="queryParam.mobileNo"/>
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="8">
                        <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                            <a-button type="primary" @click="loadData(1)" icon="search">{{ $t('search') }}</a-button>
                            <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
                        </span>
                    </a-col>
                    <a-col :md="6" :sm="8">
                        <span style="float: right;">
                            <a-button type="primary" @click="createAccount" icon="plus">{{ $t('add') }}</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <a-table
            :columns="columns"
            :dataSource="dataSource"
            :pagination="false"
            :loading="loading"
            bordered
            ref="table"
            size="middle"
            rowKey="id">
            <span slot="status" slot-scope="text, record">
                <a-badge :status="text == 1 ? 'success' : 'default'" />
                <span>{{ $t(USER_STATUS[text]) }}</span>
            </span>
            <span slot="action" slot-scope="text, record">
                <a @click="handleDetail(record)">{{ $t('view') }}</a>
                <a-divider type="vertical"/>
                <a @click="editCurrentAccount(record)">{{ $t('edit') }}</a>
            </span>
        </a-table>
        <div style="margin-top: 15px; text-align: right;">
            <v-pagination
                v-if="total"
                :page-num="form.pageNum"
                :page-size="form.pageSize"
                :total="total"
                @change="handleTableChange" />
        </div>
        <a-modal
            :title="modalTitle"
            v-model="visible"
            @ok="submitAccount"
        >
            <a-form :form="basicForm">
                <a-form-item :label="$t(I18N_KEY + 'name')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-input v-decorator="['userName', validate.userName]" />
                </a-form-item>

                <a-form-item :label="$t(I18N_KEY + 'phone')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-input v-decorator="['mobile', validate.mobile]" />
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'mail')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-input v-decorator="['email']" />
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'password')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-input v-decorator="['password']" />
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'status')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-switch v-decorator="['status', { initialValue: true, valuePropName: 'checked' }]" />
                </a-form-item>
                <a-form-item :label="$t(I18N_KEY + 'role')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-select
                        mode="multiple"
                        v-decorator="['roleIds']"
                    >
                        <a-select-option v-for="item in roleList" :key="item.id">
                            {{ item.roleName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
        <v-modal
            v-model="detailVisible"
            :title="$t('account.accountDetail')">
            <div style="padding: 20px;">
                <account-detail
                    v-if="detailVisible"
                    ref="accountDetail"
                    :orderId="orderId"
                    :custInfoId="custInfoId"/>
            </div>
        </v-modal>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import vModal from '../modules/Modal';
    import AccountDetail from './AccountDetail';
    import Dict from '../modules/Dict';
    import DictSelect from '../modules/DictSelect';
    import pick from 'lodash.pick'

    const I18N_KEY = 'system.userList.';
    export default {
        components: {
            Dict,
            DictSelect,
            vModal,
            AccountDetail,
        },
        data () {
            return {
                I18N_KEY,
                loading: false,
                customerId: undefined,
                form: {
                    pageNum: 1,
                    pageSize: 10,
                },
                USER_STATUS: {
                    1: 'enable',
                    2: 'disable',
                },
                defaultQueryParam: {
                    userName: undefined,
                    mobileNo: undefined,
                },
                queryParam: {},
                basicForm: this.$form.createForm(this),
                validate: {
                    userName: {
                        validateTrigger: 'blur',
                        rules: [
                            { required: true, message: this.$t('enter') + ' ' + this.transformStringToLow(this.$t('name')) }
                        ]
                    },
                    mobile: {
                        validateTrigger: 'blur',
                        rules: [
                            { required: true, message: this.$t('enter') + ' ' + this.transformStringToLow(this.$t('phoneNo')) }
                        ]
                    },
                },
                visible: false,
                detailVisible: false,
                modalTitle: undefined,
                toggleSearchStatus: false,
            }
        },
        computed: {
            ...mapGetters('operation', [
                'accountList',
            ]),
            ...mapGetters('system', [
                'appAllRoleList',
            ]),
            // 表头
            columns () {
                return [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        key:'id',
                        width:60,
                        align:"center"
                    },
                    {
                        title: this.$t('name'),
                        align:"center",
                        dataIndex: 'userName'
                    },
                    {
                        title: this.$t('phoneNo'),
                        align:"center",
                        dataIndex: 'mobile'
                    },
                    {
                        title: this.$t('email'),
                        align:"center",
                        dataIndex: 'email'
                    },
                    {
                      title: this.$t('status'),
                      align:"center",
                      dataIndex: 'status',
                      scopedSlots: { customRender: 'status' }
                    },
                    {
                      title: this.$t('system.userList.time'),
                      align:"center",
                      dataIndex: 'createTime',
                    },
                    {
                        title: this.$t('action'),
                        dataIndex: 'action',
                        align:"center",
                        scopedSlots: { customRender: 'action' },
                    }]
            },
            dataSource(){
              return (this.accountList && this.accountList.rows) || [];
            },
            total () {
                return this.accountList && this.accountList.total || 0;
            },
            roleList () {
                return this.appAllRoleList || [];
            },
        },
        watch: {
        },
        created () {
            this.queryParam = { ...this.defaultQueryParam };
            this.getAppAllRoleList();
            this.loadData();
        },
        methods: {
            ...mapActions('operation', [
                'getAccountList',
                'addAccount',
                'editAccount',
                'deleteAccount',
            ]),
            ...mapActions('system', [
                'getAppAllRoleList',
            ]),
            handleTableChange (pageNum, pageSize) {
                this.form.pageNum = pageNum;
                this.form.pageSize = pageSize;
                this.loadData();
            },
            transformStringToLow (str) {
                return str && str.toLocaleLowerCase();
            },
            createAccount () {
                this.accountId = undefined;
                this.basicForm.resetFields();
                this.changeModal(true);
                this.modalTitle = this.$t('add');
            },
            editCurrentAccount (record) {
                this.accountId = record.id;
                this.basicForm.resetFields();
                this.changeModal(true);
                const temp = pick(record, 'userName', 'mobile', 'email', 'password', 'status', 'roleIds');
                const data = {
                    ...temp,
                    status: temp.status == 1,
                }
                this.$nextTick(() => {
                    this.basicForm.setFieldsValue(data)
                })
                this.modalTitle = this.$t('edit')
            },
            deleteCurrent (row) {
                this.$confirm({
                    title: '删除账户',
                    content: '是否删除当前账户',
                    onOk: () => {
                        this.loading = true;
                        this.deleteAccount({ id: row.id }).then(res => {
                            this.loadData(1);
                        }).catch(e => {
                            this.loading = false;
                            this.$message.error(e.message);
                        });
                    }
                });
            },
            changeModal (visible) {
                this.visible = visible;
            },
            submitAccount () {
                this.basicForm.validateFields((err, values) => {
                    if (!err) {
                        const requestField = this.accountId ? 'editAccount' : 'addAccount';
                        const data = {
                           ...values,
                           userId: this.accountId,
                        }
                        this.changeModal(false);
                        this.loading = true;
                        this[requestField](data).then(res => {
                            this.loadData();
                            this.$message.success(this.$t('success'));
                        }).catch(e => {
                            this.loading = false;
                            this.$message.error(e.message);
                        })
                    }
                })
            },
            searchReset () {
                this.form = {
                    pageNum: 1,
                    pageSize: 10,
                }
                this.queryParam = { ...this.defaultQueryParam };
                this.loadData(1);
            },
            handleDetail (row) {
                this.detailVisible = true;
                this.$nextTick(() => {
                    row.roles = row.roleIds.map(id => {
                        const role = this.appAllRoleList.find(item => item.id == id);
                        return role && role.roleName
                    }).filter(Boolean).join(',');
                    this.$refs.accountDetail.add(row);
                })
            },
            loadData (pageNum) {
                if(pageNum) { this.form.pageNum = pageNum; }
                const data = {
                    ...this.form,
                    ...this.queryParam,
                }
                this.loading = true;
                this.getAccountList(data).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }
        }
    }
</script>
<style scoped>
  @import '~@/assets/less/common.less';
</style>
