<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'orderNo')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderNo'))" v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('phoneNo')">
              <a-input
                :placeholder="$t('enter') + ' ' + transformStringToLow($t('phoneNo'))"
                v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
        <template v-if="toggleSearchStatus">
           <a-col :md="8" :sm="12">
             <a-form-item :label="$t('customer.AadhaarName')">
               <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t('customer.AadhaarName'))" v-model="queryParam.aadhaarName"></a-input>
             </a-form-item>
           </a-col>
           <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'loanStatus')">
              <dict-select
                v-model="queryParam.loanStatus"
                :dict-code="DICT_CODE.LOAN_STATUS"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')"
              />
            </a-form-item>
          </a-col>

           <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'approvalStatus')">
              <dict-select
                v-model="queryParam.approvalStatus"
                :dict-code="DICT_CODE.ORDER_STATUS"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'settlementStatus')">
              <dict-select
                v-model="queryParam.settleStatus"
                :dict-code="DICT_CODE.SETTLE_STATUS"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')"
              >
              </dict-select>
            </a-form-item>
          </a-col>
           <a-col :md="8" :sm="12">
             <a-form-item :label="$t(I18N_KEY + 'applicationTime')">
                <a-range-picker
                   v-model="queryParam.createdTime"
                   format="YYYY-MM-DD" />
             </a-form-item>
           </a-col>
          </template>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
               <a @click="handleToggleSearch" style="margin-left: 8px">
                     {{ $t(toggleSearchStatus ? 'hide' : 'show') }}
                    <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
                  </a>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>
        <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.SETTLE_STATUS" :value="text"/>
        <Dict slot="overdueStatus" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>
        <span slot="action" slot-scope="text, record">
          <!-- slot-scope="text, record" -->
          <!--<a @click="handleDetail(record)">{{ $t('view') }}</a>-->
          <a @click="handleEdit(record)">
            编辑
          </a>
          <a-divider type="vertical"/>
          <a-popconfirm title="确定清除吗?" @confirm="() =>handleClear(record.id)">
            <a>清数据</a>
          </a-popconfirm>
          <a-divider type="vertical"/>
          <a @click="downLoadData(record)" type="button" icon="download" style="margin-left: 8px">导json</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
    <v-modal
        v-model="visible"
        :title="$t(I18N_KEY + 'orderDetail')">
        <div style="padding: 20px;">
            <order-detail v-if="visible" :orderId="orderId" :custInfoId="custInfoId"/>
        </div>
    </v-modal>
    <test-order-modal ref="modalForm" @ok="modalFormOk"></test-order-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getPlatformAllOrderList,clearOrderForTest } from '@/api/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import vModal from '../modules/Modal';
import OrderDetail from '../order/OrderDetail';
import moment from 'moment';
import TestOrderModal from './modules/TestOrderModal';
import exportFile from '@/api/exportFile'


const I18N_KEY = 'order.';
export default {
  name: 'TestOrderList',
  components: {
    Dict,
    DictSelect,
    vModal,
    OrderDetail,
    TestOrderModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "orderNo":"",
        "mobileNo":"",
        "appssid":"",
        "aadhaarName":"",
        "createdTime":undefined,
        "loanStatus": '',
        "approvalStatus": '',
        "settleStatus": '',
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'orderNo'),
          align: 'left',
          dataIndex: 'orderNo'
        },
        {
          title: this.$t('phoneNo'),
          align: 'left',
          dataIndex: 'mobileNo'
        },
        {
          title: '盘号',
          align: 'left',
          dataIndex: 'appSsid'
        },
        {
          title: this.$t(I18N_KEY + 'applicationAmount'),
          align: 'left',
          dataIndex: 'applyAmt'
        },
        {
          title: this.$t(I18N_KEY + 'deductionFee'),
          align: 'left',
          dataIndex: 'deductionFee'
        },
        {
          title: this.$t(I18N_KEY + 'deadline'),
          align: 'left',
          dataIndex: 'duration'
        },
        {
          title: this.$t(I18N_KEY + 'loanStatus'),
          align: 'left',
          dataIndex: 'loanStatus',
          scopedSlots: { customRender: 'loanStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'approvalStatus'),
          align: 'left',
          dataIndex: 'approvalStatus',
          scopedSlots: { customRender: 'approvalStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'settlementStatus'),
          align: 'left',
          dataIndex: 'settleStatus',
          scopedSlots: { customRender: 'settleStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'overdueStatus'),
          align: 'left',
          dataIndex: 'overdueStatus',
          scopedSlots: { customRender: 'overdueStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'applicationTime'),
          align: 'left',
          dataIndex: 'createdTime'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    // this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      if(!this.queryParam.mobileNo.trim()){
        alert('手机号不能为空');
        self.loadData();
        return false;
      }
      if(!this.queryParam.mobileNo.startsWith("182") && !this.queryParam.mobileNo.startsWith("183")){
        alert('手机号必须为182或183开头');
        self.loadData();
        return false;
      }
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getPlatformAllOrderList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { createdTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          applyDateStart: createdTime && this.transformTime(createdTime[0]),
          applyDateEnd: createdTime && this.transformTime(createdTime[1]),
      }
      delete data.createdTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
       that.queryParam.aadhaarName = ''
       that.queryParam.createdTime = undefined;
      that.queryParam.loanStatus = ''
      that.queryParam.approvalStatus = ''
      that.queryParam.settleStatus = ''
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.id;
        this.custInfoId = row.custInfoId;
        this.changeModal(true);
    },
    handleEdit (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleClear(id){
      clearOrderForTest({id}).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.loadData()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    downLoadData (record) {
      const file = {
        fileUrl: '/api/web/order/test/getJsonData?orderId=' + record.id ,
        fileName: 'jsonForCustExcel.txt',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    // model回调
    modalFormOk () {
      this.loadData()
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
