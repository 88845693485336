<template>


  <a-modal title="新增和缺失的人" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @cancel="hide" :footer="false" width="900px">
    <a-form layout="inline" @keyup.enter.native="searchQuery">
      <a-row :gutter="12">
        <a-col :md="6" :sm="8">
          <a-form-item :label="$t('platformCollection.department')" >
            <a-select
              style="width: 200px"
              v-model="inQueryParam.departmentId">
              <a-select-option v-for="item in departmentList" :key="item.departmentId" >
                {{ item.departmentName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item :label="$t('platformCollection.grouping')">
            <a-select
              style="width: 200px"
              v-model="inQueryParam.groupId"
            >
              <a-select-option v-for="item in groupList" :key="item.groupId">
                {{ item.groupName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item label="用户账号" >
            <a-input v-model="inQueryParam.phone"/>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
        </a-col>
      </a-row>
    </a-form>

    <a-spin :spinning="modal.confirmLoading" @change="show">
      <a-tabs default-active-key="1"  >
        <a-tab-pane key="1" tab="缺失的">
          <a-table
            :dataSource="addList">
            <a-table-column
              title="用户ID"
              dataIndex="userId"
              key="userId"
              align="center"
            />
            <a-table-column
              title="用户账号"
              dataIndex="phone"
              key="phone"
              align="center"
            />
            <a-table-column
              title="用户名"
              dataIndex="userName"
              key="userName"
              align="center"
            />
            <a-table-column
              title="分组名"
              dataIndex="groupName"
              key="groupName"
              align="center"
            />
            <a-table-column
              title="部门"
              dataIndex="departmentName"
              key="departmentName"
              align="center"
            />
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="新增的" force-render>
          <a-table
            :dataSource="missingList">
            <a-table-column
              title="用户ID"
              dataIndex="userId"
              key="userId"
              align="center"
            />
            <a-table-column
              title="用户账号"
              dataIndex="phone"
              key="phone"
              align="center"
            />
            <a-table-column
              title="用户名"
              dataIndex="userName"
              key="userName"
              align="center"
            />
            <a-table-column
              title="分组名"
              dataIndex="groupName"
              key="groupName"
              align="center"
            />
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </a-modal>
</template>
<script>
  import {getCollectUserDetails, getSysGroup} from "@/api/platformCollection";
  import {getCollectionListDepartment} from "@/api/group";
  export default {
    name: 'CollectionDiffUser',
    data() {
      return {
        modal: {
          visible: false,
          confirmLoading: false,
        },

        model: {
          isEnable:0,
          limitNum:'',
          normalConfigId:''

        },
        inQueryParam: {
          departmentId:'',
          groupId:'',
          phone:''
        },
        groupList: [],
        departmentList: [],
        departmentId:'',
        phone:'',
        groupId:'',
        promise:undefined,

        list:{
          add:[],
          missing:[],
        },
      }
    },
    computed:{
      addList(){
        return this.list.add.filter(item => (this.groupId === '' || item.groupId === this.groupId) && (this.departmentId === '' || item.departmentId == this.departmentId) && (this.phone === '' || item.phone === this.phone));
      },
      missingList(){
        return this.list.missing.filter(item => (this.groupId === '' || item.groupId === this.groupId) && (this.departmentId === '' || item.departmentId == this.departmentId) && (this.phone === '' || item.phone === this.phone));
      }
    },
    methods: {
      show(value,record) {
        this.inQueryParam = {
          departmentId:'',
          groupId:'',
          phone: ''
        }
        this.searchQuery();
        this.list = {
          add:[],
          missing:[],
        }
        this.model = {...value,...record,}
        this.modal.visible = true
        this.modal.confirmLoading = true
        getCollectUserDetails(Object.assign({}, this.model)).then((res)=>{
          if(res.code === 1000){
            this.list = res.data || this.list;
            this.groupList = res.data.groupList
            this.departmentList = res.data.departmentList
          }else{
            this.total = 0
            this.list = {
              add:[],
              missing:[],
            }
            this.$message.error(res.msg)
          }
          this.loading = false
          this.modal.confirmLoading = false
        }).catch(e => {
          this.loading = false;
          this.modal.confirmLoading = false
          this.$message.error(e.message);
        })
      },
      searchQuery () {
        this.departmentId =  this.inQueryParam.departmentId
        this.groupId =  this.inQueryParam.groupId
        this.phone =  this.inQueryParam.phone
      },


      // 重置字典类型搜索框的内容
      searchReset () {
        this.inQueryParam.departmentId=''
        this.inQueryParam.groupId=''
        this.inQueryParam.phone=''
      },
      hide() {
        this.modal.visible = false
      }
    }
  }
</script>