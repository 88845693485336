import { render, staticRenderFns } from "./RecycleModal.vue?vue&type=template&id=681cd248&scoped=true&"
import script from "./RecycleModal.vue?vue&type=script&lang=js&"
export * from "./RecycleModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681cd248",
  null
  
)

export default component.exports